// @flow
import React from 'react';
import { ICONS } from '../../../../../../../../../images/paths';
import CompoundKeyword from '../CompoundKeyword/CompoundKeyword';

type Props = {
  channel: string,
  video: string,
  handleClick: () => void,
};

const YouTubeKeyword = ({ channel, video, handleClick }: Props) => {
  return (
    <CompoundKeyword
      title={channel}
      description={video}
      icon={ICONS.youtubeChannel}
      iconClass={'youtube'}
      handleClick={handleClick}
    />
  );
};

export default YouTubeKeyword;
