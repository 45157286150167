// @flow

import { css } from 'emotion';
import styled from 'react-emotion';

export const BREAKPOINTS = {
  d1720: 1720,
  d1600: 1600,
  d1500: 1500,
  d1440: 1440,
  d1400: 1400,
  d1300: 1300,
  d1260: 1260,
  d1110: 1110,
  d1180: 1180,
  d1070: 1070,
  d1020: 1020,
  d1000: 1000,
  d900: 900,
  d850: 850,
  d770: 770,
  d600: 600,
  d450: 450,
};

export const minQuery = (breakpoint: number) => `@media (min-width: ${breakpoint}px)`;

export const maxQuery = (breakpoint: number) => `@media (max-width: ${breakpoint}px)`;

const rangeQuery = (minBreakpoint: number, maxBreakpoint: number) =>
  `@media (min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px)`;

export const MEDIA_BREAKPOINTS = {
  max1720: maxQuery(BREAKPOINTS.d1720 - 1),
  min1720: minQuery(BREAKPOINTS.d1720),
  max1600: maxQuery(BREAKPOINTS.d1600 - 1),
  max1500: maxQuery(BREAKPOINTS.d1500 - 1),
  min1440: minQuery(BREAKPOINTS.d1440),
  maxRange1440: rangeQuery(BREAKPOINTS.d1260, BREAKPOINTS.d1440 - 1),
  max1440: maxQuery(BREAKPOINTS.d1440 - 1),
  max1400: maxQuery(BREAKPOINTS.d1400 - 1),
  max1300: maxQuery(BREAKPOINTS.d1300 - 1),
  max1260: maxQuery(BREAKPOINTS.d1260 - 1),
  min1260: minQuery(BREAKPOINTS.d1260),
  maxRange1260: rangeQuery(BREAKPOINTS.d1180, BREAKPOINTS.d1260 - 1),
  max1180: maxQuery(BREAKPOINTS.d1180 - 1),
  min1180: minQuery(BREAKPOINTS.d1180),
  maxRange1180: rangeQuery(BREAKPOINTS.d1000, BREAKPOINTS.d1180 - 1),
  max1100: maxQuery(BREAKPOINTS.d1100 - 1),
  max1070: maxQuery(BREAKPOINTS.d1070 - 1),
  max1020: maxQuery(BREAKPOINTS.d1020 - 1),
  min1000: minQuery(BREAKPOINTS.d1000),
  max1000: maxQuery(BREAKPOINTS.d1000 - 1),
  max900: maxQuery(BREAKPOINTS.d900 - 1),
  max850: maxQuery(BREAKPOINTS.d850 - 1),
  max770: maxQuery(BREAKPOINTS.d770 - 1),
  min770: minQuery(BREAKPOINTS.d770),
  max600: maxQuery(BREAKPOINTS.d600 - 1),
  min600: minQuery(BREAKPOINTS.d600),
  max450: maxQuery(BREAKPOINTS.d450 - 1),
};

export const LANDSCAPE_DEVICE = `@media screen and (orientation:landscape)`;

export const MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT = `${LANDSCAPE_DEVICE} and (max-width: ${BREAKPOINTS.d770 -
  1}px), (max-width: ${BREAKPOINTS.d600 - 1}px)`;

export const LARGE_DESKTOP_BREAKPOINT = MEDIA_BREAKPOINTS.min1000;

export const SMALL_DESKTOP_BREAKPOINT = MEDIA_BREAKPOINTS.max1000;

export const SMALL_DEVICE_BREAKPOINT = MEDIA_BREAKPOINTS.max770;

export const MOBILE_DEVICE_BREAKPOINT = MEDIA_BREAKPOINTS.max600;

export const LARGE_DEVICE_BREAKPOINT = MEDIA_BREAKPOINTS.min770;

export const NON_HOVER_DEVICE = '@media (hover: none)';

export const hideOnSmallDesktopDeviceCss = css`
  ${MEDIA_BREAKPOINTS.max1000} {
    display: none;
  }
`;

export const showOnSmallDesktopDeviceCss = css`
  ${MEDIA_BREAKPOINTS.min1000} {
    display: none;
  }
`;

export const showOnSmallDesktopDeviceOnlyCss = css`
  display: none;
  ${MEDIA_BREAKPOINTS.maxRange1180} {
    display: block;
  }
`;

export const hideOnLargeDeviceCss = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

export const HideOnLargeDevice = styled('div')`
  ${hideOnLargeDeviceCss};
`;

export const HideOnSmallDesktop = styled('div')`
  ${hideOnSmallDesktopDeviceCss};
`;

export const ShowOnSmallDesktopOnly = styled('div')`
  ${showOnSmallDesktopDeviceOnlyCss};
`;

export const ShowOnSmallDesktop = styled('div')`
  ${showOnSmallDesktopDeviceCss};
`;

export const hideOnSmallDeviceCss = css`
  ${MEDIA_BREAKPOINTS.max770} {
    display: none;
  }
`;

export const HideOnSmallDevice = styled('div')`
  ${hideOnSmallDeviceCss};
`;

export const showOnSmallDeviceCss = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

export const ShowOnSmallDevice = styled('div')`
  ${showOnSmallDeviceCss};
`;

export const hideOnMobileCss = css`
  ${MEDIA_BREAKPOINTS.max600} {
    display: none;
  }
`;

export const showOnMobileCss = css`
  ${MEDIA_BREAKPOINTS.min600} {
    display: none;
  }
`;
