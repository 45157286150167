// @flow

export const ERROR_CODES = {
  renewTokens: 'renewTokens',
};

export class AuthenticationError extends Error {
  constructor(message, code = '') {
    super(message);
    this.code = code;
  }
}

export class YouTubeChannelAlreadyConnectedError extends Error {}

export class YouTubeChannelDuplicateRequestError extends Error {}

export class YouTubeChannelNoAvaliableChannelError extends Error {}

export class YouTubeChannelUndefinedYouTubeDataError extends Error {}
