// @flow

export const LOCALES = {
  english: {
    code: 'en',
    path: '/en',
  },
  japanese: {
    code: 'ja',
    path: '/ja',
  },
  chinese: {
    code: 'cn',
    path: '/cn',
  },
};
