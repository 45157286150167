// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type DownloadCountMenuGraphMdl = {
  data: {
    daily_download_limit_title: {
      text: string,
    },
    download_counter: {
      text: html,
    },
    upgrade_prompt_title: {
      text: string,
    },
    upgrade_prompt_description: {
      text: string,
    },
    download_limit_reached: {
      text: string,
    },
    downloads_label: {
      text: string,
    },
  },
};

export const getDownloadCountMenuTitle = (copy: DownloadCountMenuGraphMdl): string => {
  return get(copy, 'data.daily_download_limit_title.text', '') || '';
};

export const getDownloadCountMenuCounter = (copy: DownloadCountMenuGraphMdl): string => {
  return get(copy, 'data.download_counter.html', '') || '';
};

export const getDownloadCountMenuUpgradeTitle = (copy: DownloadCountMenuGraphMdl): string => {
  return get(copy, 'data.upgrade_prompt_title.text', '') || '';
};

export const getDownloadCountMenuUpgradeDescription = (copy: DownloadCountMenuGraphMdl): string => {
  return get(copy, 'data.upgrade_prompt_description.text', '') || '';
};

export const getDownloadCountMenuDownloadLimitReached = (
  copy: DownloadCountMenuGraphMdl
): string => {
  return get(copy, 'data.download_limit_reached.text', '') || '';
};

export const getDownloadCountMenuDownloadsLabel = (copy: DownloadCountMenuGraphMdl): string => {
  return get(copy, 'data.downloads_label.text', '') || '';
};

export const query = graphql`
  fragment DownloadCountMenu on PrismicDownloadCountMenu {
    data {
      daily_download_limit_title {
        text
      }
      download_counter {
        html
      }
      upgrade_prompt_title {
        text
      }
      upgrade_prompt_description {
        text
      }
      download_limit_reached {
        text
      }
      downloads_label {
        text
      }
    }
  }
`;
