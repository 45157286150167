// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ProfilePageGraphMdl = {
  data: {
    header: {
      text: string,
    },
    upload_button: {
      text: string,
    },
    upload_instruction: {
      text: string,
    },
    update_instruction: {
      text: string,
    },
    update_generic_error_message: {
      text: string,
    },
    first_name: {
      text: string,
    },
    last_name: {
      text: string,
    },
    email_address: {
      text: string,
    },
    country: {
      text: string,
    },
    phone_number: {
      text: string,
    },
    company: {
      text: string,
    },
    save_button: {
      text: string,
    },
    changePasswordHeading: {
      text: string,
    },
    password_instruction: {
      text: string,
    },
    password_reset_success_message: {
      html: string,
    },
    password_reset_button: {
      text: string,
    },
  },
};

export const getProfilePagePasswordResetSuccessMessage = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.password_reset_success_message.html', '') || '';
};

export const getProfilePagePasswordResetButton = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.password_reset_button.text', '') || '';
};

export const getProfilePageUpdateGenericError = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.update_generic_error_message.text', '') || '';
};

export const getProfilePagePasswordInstructions = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.password_instruction.text', '') || '';
};

export const getProfilePageChangePasswordHeading = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.changePasswordHeading.text', '') || '';
};

export const getProfilePageHeading = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.header.text', '') || '';
};

export const getProfilePageUploadButton = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.upload_button.text', '') || '';
};

export const getProfilePageUploadInstructions = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.upload_instruction.text', '') || '';
};

export const getProfilePageUpdateInstructions = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.update_instruction.text', '') || '';
};

export const getProfilePageFirstNameLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.first_name.text', '') || '';
};

export const getProfilePageLastNameLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.last_name.text', '') || '';
};

export const getProfilePageEmailAddressLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.email_address.text', '') || '';
};

export const getProfilePageCountryLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.country.text', '') || '';
};

export const getProfilePagePhoneNumberLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.phone_number.text', '') || '';
};

export const getProfilePageCompanyLabel = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.company.text', '') || '';
};

export const getProfilePageSaveButton = (copy: ProfilePageGraphMdl): string => {
  return get(copy, 'data.save_button.text', '') || '';
};

export const query = graphql`
  fragment ProfilePage on PrismicProfilePage {
    data {
      header {
        text
      }
      upload_button {
        text
      }
      upload_instruction {
        text
      }
      update_instruction {
        text
      }

      update_generic_error_message {
        text
      }
      first_name {
        text
      }
      last_name {
        text
      }
      email_address {
        text
      }
      country {
        text
      }
      phone_number {
        text
      }
      company {
        text
      }
      save_button {
        text
      }
      changePasswordHeading: header2 {
        text
      }
      password_instruction {
        text
      }
      password_reset_success_message {
        html
      }
      password_reset_button {
        text
      }
    }
  }
`;
