/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { darkColor } from '../../../../styles/config/colors';
import { buttonTextCss } from '../../../../styles/typography/typography';
import TagButton from './TagButton';
import type { SongTagFiltersMdl, FacetFilterDataMdl } from '../../../../utils/tagFilters';
import { getMiscShowLessButton, getMiscShowMoreButton } from '../../../../cms/miscCopy';
import { useMiscCopy } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';

const StyledFilterValuesContainer = styled('div')`
  padding: 0px 30px 23px 30px;
`;

const StyledFilterSectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & h6 {
    margin-bottom: 10px;
    ${buttonTextCss}
    color: ${transparentize(0.6, darkColor)};
    text-transform: uppercase;
  }
`;

const StyledButtonsContainer = styled('div')`
  display flex;
  flex-wrap: wrap;
  align-items: center;

  & h6:hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${transparentize(0.3, darkColor)};
  }
`;

type Props = {
  isExpanded: boolean,
  facetsMap: SongTagFiltersMdl,
  handleFilterToggle: (isSelected: boolean, filterKey: string, filterValue: string) => void,
};

type SectionProps = {
  facetData: FacetFilterDataMdl,
  isMobile?: boolean,
  handleFilterToggle: (isSelected: boolean, filterKey: string, filterValue: string) => void,
};

export const TagFilterSection = ({
  facetData,
  handleFilterToggle,
  isMobile = false,
}: SectionProps) => {
  const miscCopy = useMiscCopy();
  const [showAll, setShowAll] = useState();
  const { facetKey, label, filters } = facetData;

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const moreTagsAvailable = filters.length > 10;
  const tagsShown = showAll || isMobile ? filters.length : 10;
  const showMoreLessToggleButton = moreTagsAvailable && !isMobile;

  const sortedFilters = filters
    .sort((a, b) => {
      return b.count - a.count;
    })
    .sort((a, b) => {
      const orderA = a.active ? 1 : 0;
      const orderB = b.active ? 1 : 0;
      return orderB - orderA;
    });

  const filterButtons = sortedFilters.slice(0, tagsShown).map(option => {
    return (
      <TagButton
        key={option.label}
        facetKey={facetKey}
        label={option.label}
        count={option.count}
        handleFilterToggle={handleFilterToggle}
        isSelected={option.active}
        isDisabled={option.disabled}
      />
    );
  });

  return (
    <StyledFilterSectionWrapper>
      <h6>{label}</h6>
      <StyledButtonsContainer>
        {filterButtons}
        {showMoreLessToggleButton && (
          <h6 onClick={handleShowAll}>
            {showAll ? getMiscShowLessButton(miscCopy) : getMiscShowMoreButton(miscCopy)}
          </h6>
        )}
      </StyledButtonsContainer>
    </StyledFilterSectionWrapper>
  );
};

const GroupedTagSections = ({ isExpanded, facetsMap, handleFilterToggle }: Props) => {
  return (
    <>
      {isExpanded && (
        <StyledFilterValuesContainer>
          {facetsMap.map(facetData => {
            return (
              <TagFilterSection
                key={facetData.facetKey}
                facetData={facetData}
                handleFilterToggle={handleFilterToggle}
              />
            );
          })}
        </StyledFilterValuesContainer>
      )}
    </>
  );
};

export default GroupedTagSections;
