// @flow
import React, { useContext, useState } from 'react';

export type ExpandableSongsContextState = {
  expanded: string,
  setExpanded: any => void,
  viewingDetails: boolean,
  setViewingDetails: any => void,
};

export const ExpandableSongsContext = React.createContext();

export const useExpandableSongsContext = (): ExpandableSongsContextState => {
  return useContext(ExpandableSongsContext);
};

type Props = {
  children: any,
};

const ExpandableSongsWrapper = ({ children }: Props) => {
  const [expanded, setExpanded] = useState();
  const [viewingDetails, setViewingDetails] = useState(false);

  return (
    <ExpandableSongsContext.Provider
      value={{ expanded, setExpanded, viewingDetails, setViewingDetails }}
    >
      {children}
    </ExpandableSongsContext.Provider>
  );
};

export default ExpandableSongsWrapper;
