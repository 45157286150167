import React from 'react';
import {
  getArtistProfileCoverArt,
  getArtistProfileCatchCopy,
  getArtistProfileDescriptionMarkup,
  getArtistProfileName,
  getlinkToMv,
  getArtistProfileMushimegane,
  getArtistProfileWholeBodyImage,
} from '../../../cms/artistProfile';
import { getMiscCopyCloseButton } from '../../../cms/miscCopy';
import { BUTTON_WIDTHS } from '../../../components/Button/Button';
import {
  usePosArtistProfile,
  useMiscCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  ClassicModalLayoutPos,
  ClassicModalContentLayoutPos,
} from '../ClassicModalLayout/ClassicModalLayout';

type Props = {
  className?: String,
  artistSlug: string,
  onClose: () => void,
};

const PosArtistModal = ({ artistSlug, onClose }: Props) => {
  const [artistProfile] = usePosArtistProfile(artistSlug);

  const name = getArtistProfileName(artistProfile);
  const catchcopy = getArtistProfileCatchCopy(artistProfile);
  const description = getArtistProfileDescriptionMarkup(artistProfile);
  const bannerImage = getArtistProfileCoverArt(artistProfile);
  const linkToMv = getlinkToMv(artistProfile);
  const mushimegane = getArtistProfileMushimegane(artistProfile);
  const wholeBodyImage = getArtistProfileWholeBodyImage(artistProfile);
  const miscCopy = useMiscCopy();

  return (
    <ClassicModalLayoutPos onClose={onClose} label={name} bannerImage={bannerImage}>
      <ClassicModalContentLayoutPos
        heading={name}
        catchcopy={catchcopy}
        description={description}
        mushimegane={mushimegane}
        wholeBodyImage={wholeBodyImage}
        linkToMv={linkToMv}
      />
    </ClassicModalLayoutPos>
  );
};

export default PosArtistModal;
