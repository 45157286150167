// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useUserProfileContext } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../../../../../components/LoadingDisplay/LoadingDisplay';
import ExpandableSongsWrapper from '../../../../../../../song/components/ExpandableSongCard/components/ExpandableSongsWrapper/ExpandableSongsWrapper';
import AudioPlayerWrapper from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import {
  LARGE_DEVICE_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import {
  headerHeight,
  mobileHeaderHeight,
} from '../../../../../../../components/SiteHeader/SiteHeader';
import BookmarksNoResults from '../BookmarksNoResults/BookmarksNoResults';
import SavedCollections from '../SavedCollections/SavedCollections';
import BookmarkSongList from '../BookmarksSongList/BookmarkSongList';
import {
  useBookmarksManagerContext,
  type BookmarkSongWrapper,
} from '../BookmarksManager/BookmarksManager';

const Container = styled('div')`
  width: 100%;

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: -${126 + headerHeight}px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: unset;
  }
`;

const Wrapper = styled('div')`
  flex: 1;

  ${LARGE_DEVICE_BREAKPOINT} {
    padding: 0;
    margin-top: 112px;
    width: 100%;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    padding-left: 0;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    margin-top: 36px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

const ScreenContainer = styled('div')`
  display: flex;

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: block;
    margin-top: ${headerHeight + 20}px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    min-height: 100vh;
    display: block;
    margin-top: ${mobileHeaderHeight + 20}px;
  }
`;

const BookMarksResults = styled('div')``;

const hiddenBookmarksTab = css`
  display: none;
`;

// ブックマークページ
const ProfileBookmarks = () => {
  const { bookmarksLoaded } = useUserProfileContext();
  const {
    bookmarks,
    viewingBookmarks,
    loadingFilterResults,
    loading,
    loaded,
    noBookmarkedCollections,
  } = useBookmarksManagerContext();

  const bookmarkSongs = bookmarks
    .filter((bookmark: BookmarkSongWrapper) => bookmark.song)
    .map((bookmark: BookmarkSongWrapper) => bookmark.song);

  if (loadingFilterResults || !loaded) {
    if (loadingFilterResults || loading || !bookmarksLoaded) {
      return (
        <LoadingContainer>
          <LoadingDisplay />
        </LoadingContainer>
      );
    }
  }

  const noBookmarkedSongs = bookmarks.length === 0;
  const noBookmarks = noBookmarkedCollections && noBookmarkedSongs;

  return (
    <ExpandableSongsWrapper>
      <AudioPlayerWrapper>
        <ScreenContainer>
          <Wrapper>
            {noBookmarks ? (
              <BookmarksNoResults />
            ) : (
              <Container>
                <BookMarksResults>
                  <SavedCollections />
                  <section
                    className={cx({ [hiddenBookmarksTab]: viewingBookmarks === 'collections' })}
                  >
                    <BookmarkSongList results={bookmarkSongs} />
                  </section>
                </BookMarksResults>
              </Container>
            )}
          </Wrapper>
        </ScreenContainer>
      </AudioPlayerWrapper>
    </ExpandableSongsWrapper>
  );
};

export default ProfileBookmarks;
