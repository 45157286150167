// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useRecoilValue } from 'recoil';
import GlobalSongInfo from '../GlobalSongInfo/GlobalSongInfo';
import { useGlobalAudioPlaybackContext } from '../GlobalAudioPlayback/GlobalAudioPlayback';
import ActionMenuButton from '../GlobalPlayerActions/components/ActionMenuButton/ActionMenuButton';
import CloseIcon from '../../../../../assets/inline-assets/close.svg';
import SongActionButton from '../../../FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import SongProgressSlider from './components/SongProgressSlider';
import TrackNavIcon from '../../../../../assets/inline-assets/track-nav-icon.svg';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { formatSeconds } from '../../../../../utils/time';
import {
  getSongArtwork,
  getSongDurationFormatted,
  getSongMicroparts,
} from '../../../../../api/algolia/song';
import { Duration } from '../GlobalPlayerActions/GlobalPlayerActions';
import {
  nextSongEnabledSelector,
  previousSongEnabledSelector,
  songPlayingSelector,
} from '../../../../../store/globalPlayer';
import { PlayPauseButtonControl } from '../../../FullDisplaySongCard/components/PlayPauseButton/PlayPauseButton';

const DockedPlayerWrapper = styled('div')`
  position: sticky;
  bottom: 0;
  z-index: 200;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DockedPlayer = styled('div')`
  width: 100%;
  border-radius: 5px;
  background-color: #dcdad8;
  display: flex;
  flex-direction: column;
`;

const ProgressBarContainer = styled('div')`
  width: 100%;
  background-color: #a4a09e;
`;

const PlayerSongCard = styled('div')`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SongDataContainer = styled('div')`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const PlaybackControls = styled('div')`
  width: 20%;
  display: flex;
  align-items: center;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 15%;
  }
`;

const Control = styled('div')`
  margin: 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${MOBILE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const ActionControls = styled('div')`
  width: 35%;
  display: flex;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 20%;
  }
`;

const SongInfoContainer = styled('div')`
  width: 45%;
  display: flex;
  flex-direction: column;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 65%;
  }
`;

const previousTrackIconClass = css`
  transform: rotate(180deg);
`;

const disabledTrackIconClass = css`
  opacity: 0.5;
`;

const MobileGlobalSongPlayer = () => {
  const {
    playing,
    onPlayToggle,
    tryingToPlay,
    loadError,
    progress,
  } = useGlobalAudioPlaybackContext();
  const nextSongEnabled = useRecoilValue(nextSongEnabledSelector);
  const previousSongEnabled = useRecoilValue(previousSongEnabledSelector);
  const songPlaying = useRecoilValue(songPlayingSelector);
  const { closeGlobalPlayer, nextSong, previousSong } = useGlobalPlayerContext();

  const songDuration = getSongDurationFormatted(songPlaying);
  const songArtwork = getSongArtwork(songPlaying);
  const loopsAvailable = getSongMicroparts(songPlaying).length > 0;

  const handleOnPlayToggle = () => {
    onPlayToggle();
  };

  const handleNextSongNav = () => {
    if (!nextSongEnabled) return;
    nextSong();
  };

  const handlePreviousSongNav = () => {
    if (!previousSongEnabled) return;
    previousSong();
  };

  return (
    <DockedPlayerWrapper>
      <DockedPlayer>
        <PlayerSongCard>
          <ProgressBarContainer>
            <SongProgressSlider />
          </ProgressBarContainer>
          <SongDataContainer>
            <PlaybackControls>
              <Control
                className={cx({
                  [previousTrackIconClass]: true,
                  [disabledTrackIconClass]: !previousSongEnabled,
                })}
                onClick={handlePreviousSongNav}
              >
                <TrackNavIcon />
              </Control>
              <PlayPauseButtonControl
                playing={playing}
                onClick={handleOnPlayToggle}
                tryingToPlay={tryingToPlay}
                cantPlay={loadError}
                backgroundImage={songArtwork}
              />
              <Control
                className={cx({
                  [disabledTrackIconClass]: !nextSongEnabled,
                })}
                onClick={handleNextSongNav}
              >
                <TrackNavIcon />
              </Control>
            </PlaybackControls>
            <SongInfoContainer>
              <GlobalSongInfo />
            </SongInfoContainer>
            <ActionControls>
              <Duration>{` ${formatSeconds(progress)} / ${songDuration}`}</Duration>
              <ActionMenuButton loopsAvailable={loopsAvailable} />
              <SongActionButton
                condensed={false}
                icon={<CloseIcon />}
                label=""
                onClick={closeGlobalPlayer}
              />
            </ActionControls>
          </SongDataContainer>
        </PlayerSongCard>
      </DockedPlayer>
    </DockedPlayerWrapper>
  );
};

export default MobileGlobalSongPlayer;
