// @flow
import React, { useContext, useState } from 'react';

export type SongViewContextState = {
  viewingDetails: boolean,
  setViewingDetails: any => void,
};

export const SongViewContext = React.createContext();

export const useCollectionSongViewContext = (): SongViewContextState => {
  return useContext(SongViewContext);
};

type Props = {
  children: any,
};

const CollectionViewWrapper = ({ children }: Props) => {
  const [viewingDetails, setViewingDetails] = useState(false);

  return (
    <SongViewContext.Provider value={{ viewingDetails, setViewingDetails }}>
      {children}
    </SongViewContext.Provider>
  );
};

export default CollectionViewWrapper;
