import { get } from 'lodash';
import { LOCALES } from '../../../locales';

export type ReferralCampaignMDL = {
  name: string,
  ja_name: string,
  bannerCopy: string,
};

export type ReferralEntitlementsMDL = {
  type: string,
  trialDays: number,
};

export type ReferralCouponDataMDL = {
  id: string,
  coupon: {
    expirySeconds: number,
    status: string,
    campaign: ReferralCampaignMDL,
    entitlements: Array<ReferralEntitlementsMDL>,
  },
};

export const REFERRAL_TYPES = {
  trialExtension: 'trialExtension',
};

export const getReferralCouponId = (referralCoupon: ReferralCouponDataMDL): string => {
  return get(referralCoupon, 'id', '');
};

export const getReferralCouponExpiry = (referralCoupon: ReferralCouponDataMDL): number => {
  return get(referralCoupon, 'expirySeconds', 0);
};

export const getReferralCouponStatus = (referralCoupon: ReferralCouponDataMDL): string => {
  return get(referralCoupon, 'status', '');
};

export const getReferralCouponName = (
  referralCoupon: ReferralCouponDataMDL,
  locale: string
): string => {
  if (locale === LOCALES.japanese.code) {
    return get(referralCoupon, 'coupon.campaign.ja_name', '');
  }
  return get(referralCoupon, 'coupon.campaign.name', '');
};

export const getReferralCouponBannerCopy = (referralCoupon: ReferralCouponDataMDL): string => {
  return get(referralCoupon, 'coupon.campaign.bannerCopy', '');
};

export const getReferralCouponEntitlements = (
  referralCoupon: ReferralCouponDataMDL
): Array<ReferralEntitlementsMDL> => {
  return get(referralCoupon, 'coupon.entitlements', '');
};

export const getReferralCouponType = (referralCoupon: ReferralCouponDataMDL): string => {
  return get(referralCoupon, 'coupon.entitlements[0].type', '');
};

export const getReferralCouponTrialDays = (referralCoupon: ReferralCouponDataMDL): number => {
  return get(referralCoupon, 'coupon.entitlements[0].trialDays', 0);
};

export const getReferralEligibility = (couponType: string, isFreeTrialElegible: boolean) => {
  if (couponType === REFERRAL_TYPES.trialExtension) {
    return isFreeTrialElegible;
  }

  return false;
};

export const getFreeTrialLength = (referralCoupon: ReferralCouponDataMDL): string => {
  if (referralCoupon) {
    const referralCouponType = getReferralCouponType(referralCoupon);
    if (referralCouponType === REFERRAL_TYPES.trialExtension) {
      return `${getReferralCouponTrialDays(referralCoupon)}`;
    }
  }

  return '14';
};

export const replaceReferralPlaceholders = (
  bannerCopy: string,
  influencerName: string,
  trialLength: number
): string => {
  return bannerCopy
    .replace('$INFLUENCER_NAME$', influencerName)
    .replace('$TRIAL_LENGTH$', trialLength);
};
