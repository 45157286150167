// @flow
import React, { useState, useEffect } from 'react';
import ls from 'local-storage';
import { css } from 'emotion';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import { PlainTextButton } from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import {
  useMiscCopy,
  useSignInModalCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getMiscCopySignUp } from '../../../cms/miscCopy';
import { BANNERS } from '../../../images/paths';
import { getSignInModalHeading, getSignInAgainPrompt } from '../../../cms/signinModal';
import SignInForm from '../../../components/SignInForm/SignInForm';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';

export const signInModalBannerClass = css`
  background-color: #d0a895;
  background-image: url(${BANNERS.signin});
`;

export const SignInModalBody = styled('div')`
  margin-top: 43px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 13px;
  }
`;

const TokenErrorContainer = styled('div')`
  margin-top: 13px;
  margin-right: 120px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-right: 10px;
  }
`;

type Props = {
  onClose: () => void,
};

const SignInModal = ({ onClose }: Props) => {
  const localePath = useLocalePath();
  const miscCopy = useMiscCopy();
  const copy = useSignInModalCopy();
  const [isRenewTokenError, setIsRenewTokenError] = useState(false);
  const handleBeforeAuth = () => {
    storeCurrentLocationAsAuthDestination();
  };
  const handleClose = () => {
    onClose();
  };
  const handleSignIn = () => {
    onClose();
  };

  useEffect(() => {
    if (ls.get('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN')) {
      setIsRenewTokenError(true);
    }
  }, []);

  useEffect(() => {
    ls.set('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN', false);
  }, []);

  return (
    <ClassicModalLayout
      onClose={handleClose}
      label={getSignInModalHeading(copy)}
      dataCy="signInModal"
    >
      <ClassicModalContentLayout
        heading={getSignInModalHeading(copy)}
        headerOption={
          <PlainTextButton
            uppercase
            onClick={handleSignIn}
            to={ROUTES.signup.navigatePath({ localePath })}
            dataCy="modalSignUpLink"
          >
            {getMiscCopySignUp(miscCopy)}
          </PlainTextButton>
        }
      >
        {isRenewTokenError ? (
          <TokenErrorContainer>{parse(getSignInAgainPrompt(copy))}</TokenErrorContainer>
        ) : (
          ''
        )}
        <SignInModalBody>
          <SignInForm onForgot={onClose} beforeAuth={handleBeforeAuth} />
        </SignInModalBody>
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default SignInModal;
