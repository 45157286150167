// @flow
import { useState, useEffect } from 'react';
import { getFirestoreUserRef, getUserProfile } from './user';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { getProfileRecentCollections } from './profile';
import type { FBUserMdl } from './user';

export const addFirestoreRecentCollection = (
  userId: string,
  newCollectionSlug: string,
  recentCollections: Array<string>
) => {
  const userRef = getFirestoreUserRef(userId);

  const filteredRecentCollections = recentCollections.filter(
    collection => collection !== newCollectionSlug
  );
  const updatedRecentCollections = [newCollectionSlug].concat(filteredRecentCollections);

  if (updatedRecentCollections.length > 24) {
    updatedRecentCollections.splice(24);
  }

  return userRef.update({
    [`profile.recentCollections`]: updatedRecentCollections,
  });
};

export const useRecentCollections = (collectionSlug: string, recentCollections: Array<string>) => {
  const userId = useUserId();

  const saveRecentCollections = () => {
    return addFirestoreRecentCollection(userId, collectionSlug, recentCollections);
  };

  return [saveRecentCollections];
};

export const useWatchUserRecentCollections = () => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [recentCollections, setRecentCollections] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getFirestoreUserRef(userId).onSnapshot(data => {
      if (data.exists) {
        const user: FBUserMdl = data.data();
        const profile = getUserProfile(user);
        const updatedSavedCollections = getProfileRecentCollections(profile);
        setRecentCollections(updatedSavedCollections);
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [recentCollections, loaded];
};
