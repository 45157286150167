// @flow
import React from 'react';
import { Link } from '@reach/router';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { darkColor, lightColor } from '../../styles/config/colors';
import {
  buttonTextCss,
  monoFontCss,
  monoNavCss,
  smallHeadingActiveStateCss,
  smallHeadingHoverStateCss,
  smallMonoCondensedCss,
} from '../../styles/typography/typography';
import SpinnerIcon from '../SpinnerIcon/SpinnerIcon';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { smallHeadingCss } from '../../styles/typography/components/Heading/Heading';

export const buttonResetCss = css`
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const lightButtonCss = css`
  background: ${darkColor};
  color: ${lightColor};

  &:disabled {
    color: ${transparentize(0.8, lightColor)};
  }
`;

export const darkButtonCss = css`
  background: ${transparentize(0.95, darkColor)};
  color: ${darkColor};
`;

export const interactiveTextButtonCss = css`
  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }

  &:disabled {
    text-decoration: none;
  }

  &:active {
    color: ${transparentize(0.2, darkColor)};
  }
`;

export const SmallMonoTextButton = styled('button')`
  ${buttonResetCss};
  ${smallMonoCondensedCss};
  ${interactiveTextButtonCss};
  text-transform: uppercase;
`;

export const textButtonCss = css`
  ${smallHeadingCss};
  ${interactiveTextButtonCss};
  background: none;
  color: ${darkColor};
  text-transform: uppercase;
  width: auto;
  min-width: 0;
  padding: 0;
  display: inline-flex;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

export const BUTTON_THEMES = {
  light: 'light',
  dark: 'dark',
  text: 'text',
};

const themeHandler = props => {
  if (props.theme) {
    if (props.theme === BUTTON_THEMES.light) {
      return lightButtonCss;
    }
    if (props.theme === BUTTON_THEMES.dark) {
      return darkButtonCss;
    }
    if (props.theme === BUTTON_THEMES.text) {
      return textButtonCss;
    }
  }
  return '';
};

export const shadowCss = css`
  box-shadow: 0 5px 15px 0 rgba(255, 255, 255, 0.1);
`;

export const interactiveButtonCss = css`
  &:hover,
  &:focus {
    ${shadowCss};
    outline: none;
  }
  &:active {
    box-shadow: none;
  }
`;

export const rawButtonCss = props => css`
  ${buttonResetCss};
  ${buttonTextCss};
  ${interactiveButtonCss};
  border-radius: 3px;
  text-transform: uppercase;
  display: flex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  position: relative;
  user-select: none;
`;

export const extraVerticalPaddingButtonClass = css`
  padding: 12px 15px;
`;

export const lessVerticalPaddingButtonClass = css`
  padding: 5px 15px;
`;

export const shorterButtonClass = css`
  ${lessVerticalPaddingButtonClass};
  font-size: 10px;
  letter-spacing: 1.67px;
  line-height: 20px;
`;

const smallWidthCss = css`
  min-width: 100px;
`;

const compactWidthCss = css`
  min-width: 120px;
`;

const mediumWidthCss = css`
  min-width: 200px;
`;

const largeWidthCss = css`
  min-width: 250px;
`;

export const fullWidthCss = css`
  min-width: 0;
  width: 100%;
`;

const autoWidthCss = css`
  min-width: 0;
  width: auto;
`;

export const mobileSmallerButtonClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 11px;
  }
`;

export const BUTTON_WIDTHS = {
  small: 'small',
  compact: 'compact',
  medium: 'medium',
  large: 'large',
  full: 'full',
  auto: 'auto',
};

const widthHandler = props => css`
  ${props.width === BUTTON_WIDTHS.small ? smallWidthCss : ''};
  ${props.width === BUTTON_WIDTHS.compact ? compactWidthCss : ''};
  ${props.width === BUTTON_WIDTHS.medium ? mediumWidthCss : ''};
  ${props.width === BUTTON_WIDTHS.large ? largeWidthCss : ''};
  ${props.width === BUTTON_WIDTHS.full ? fullWidthCss : ''};
  ${props.width === BUTTON_WIDTHS.auto ? autoWidthCss : ''};

  ${SMALL_DEVICE_BREAKPOINT} {
    ${props.mobileWidth === BUTTON_WIDTHS.full ? fullWidthCss : ''};
  }
`;

const hasIconClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled('div')`
  margin-right: 9px;
  position: relative;
  top: 1px;
`;

const RawButton = styled('button')``;

const spinnerVisibleClass = css`
  visibility: hidden;
`;

type Props = {
  children: any,
  icon?: any,
  to?: string,
  href?: string,
  target?: string,
  type?: string,
  loading?: boolean,
  spinnerInverse?: boolean,
};

const Button = ({
  children,
  icon = null,
  className = '',
  type = 'button',
  theme = BUTTON_THEMES.light,
  width = BUTTON_WIDTHS.medium,
  to = '',
  href = '',
  target = '',
  loading = false,
  spinnerInverse = false,
  ...otherProps
}: Props) => {
  const sharedProps = {
    className: cx(
      rawButtonCss(otherProps),
      themeHandler({
        theme,
      }),
      widthHandler({
        width,
        ...otherProps,
      }),
      className
    ),
    ...otherProps,
  };

  const Element = to ? Link : href ? 'a' : RawButton;

  if (!to) {
    if (href) {
      sharedProps.href = href;
      if (target) {
        sharedProps.target = target;
      }
    } else {
      sharedProps.type = type;
    }
  } else {
    sharedProps.to = to;
  }

  return (
    <Element {...sharedProps}>
      <span
        className={cx({
          [spinnerVisibleClass]: loading,
          [hasIconClass]: !!icon,
        })}
      >
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {children}
      </span>
      {loading && <SpinnerIcon inverse={spinnerInverse} />}
    </Element>
  );
};

export default Button;

export const DarkButton = (props: any) => (
  <Button {...props} theme={BUTTON_THEMES.dark} spinnerInverse />
);

export const LightButton = (props: any) => (
  <Button {...props} theme={BUTTON_THEMES.light} spinnerInverse />
);

export const TextButton = (props: any) => (
  <Button {...props} theme={BUTTON_THEMES.text} width={BUTTON_WIDTHS.auto} />
);

export const CompactButton = (props: any) => <Button {...props} width={BUTTON_WIDTHS.compact} />;

export const DarkCompactButton = (props: any) => (
  <CompactButton {...props} theme={BUTTON_THEMES.dark} spinnerInverse />
);

export const plainTextButtonCss = (props: any) => css`
  ${monoNavCss};
  text-decoration: none;
  ${props && props.uppercase ? 'text-transform: uppercase;' : ''};
  ${props && props.thin ? 'font-weight: 400;' : ''};

  &:focus,
  &:hover {
    ${smallHeadingHoverStateCss};
    outline: none;
  }

  &:active {
    ${smallHeadingActiveStateCss};
  }
`;

const RawPlainTextButton = styled('button')`
  ${buttonResetCss};
  ${plainTextButtonCss};
`;

export const PlainTextButton = ({ to, className = '', dataCy, ...props }: any) => {
  if (to) {
    return (
      <Link
        to={to}
        className={cx(plainTextButtonCss(props), className)}
        data-cy={dataCy}
        {...props}
      />
    );
  }
  return <RawPlainTextButton className={className} {...props} data-cy={dataCy} />;
};

const tinyTextButtonCss = css`
  ${monoFontCss};
  ${interactiveTextButtonCss};
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 2px;
  width: auto;
  height: auto;
  padding: 0;
`;

export const TinyTextButton = styled('button')`
  ${buttonResetCss};
  ${tinyTextButtonCss};
`;
