// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ChangeIntervalSubscriptionGraphMdl = {
  data: {
    modal_title: {
      text: string,
    },
    page_instruction: {
      html: string,
    },
  },
};

export const getChangeIntervalSubscriptionModalTitle = (copy: ChangeIntervalSubscriptionGraphMdl): string => {
  return get(copy, 'data.modal_title.text', 'Plan') || '';
};

export const getChangeIntervalSubscriptionModalPageInstruction = (
  copy: ChangeIntervalSubscriptionGraphMdl
): string => {
  return get(copy, 'data.page_instruction.html', 'plan') || '';
};

export const query = graphql`
  fragment ChangeIntervalSubscriptionModal on PrismicIntervalChangeSubscriptionModal {
    data {
      modal_title {
        text
      }
      page_instruction {
        html
      }
    }
  }
`;
