// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  LARGE_DEVICE_BREAKPOINT,
  maxQuery,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { spacing } from '../../../styles/config/spacing';

const Container = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    min-height: 100vh;
    display: flex;
    position: relative;
  }
`;

const responsiveWidth = `calc(100vw - ${500 + 60 + 60}px)`;

const SideArtWrapper = styled('div')`
  min-height: 100vh;
  max-width: 500px;
  width: 100%;
  background-color: #000000;
  position: relative;

  ${maxQuery(1120 - 1)} {
    max-width: ${responsiveWidth};
  }

  ${maxQuery(860 - 1)} {
    display: none;
  }
`;

export const SideArt = styled('div')`
  min-height: 100vh;
  width: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${props => props.background};
  background-image: url(${props => props.image});

  ${maxQuery(1120 - 1)} {
    width: ${responsiveWidth};
  }
`;

const Content = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.jumbo}px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 93px;
    margin-bottom: 30px;
  }
`;

type Props = {
  children: any,
  sideArt?: any,
};

const SideCollageLayout = ({ children, sideArt }: Props) => (
  <Container>
    <SideArtWrapper>{sideArt}</SideArtWrapper>
    <Content>{children}</Content>
  </Container>
);

export default SideCollageLayout;
