// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackMicropartsAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import MicropartsIcon from '../../../../../../../assets/inline-assets/microparts-icon.svg';
import MicropartsActiveIcon from '../../../../../../../assets/inline-assets/microparts-active-icon.svg';

type Props = {
  active?: boolean,
};

const MicroPartsButton = ({ active = false, ...props }: Props) => {
  const trackCopy = useTrackCopy();
  return (
    <SongActionButton
      icon={active ? <MicropartsActiveIcon /> : <MicropartsIcon />}
      label={getTrackMicropartsAction(trackCopy)}
      {...props}
    />
  );
};

export default MicroPartsButton;
