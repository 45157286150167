// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import { transparentize } from 'polished';
import { buttonResetCss } from '../../../Button/Button';
import { monoNavCss } from '../../../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { darkColor, lightColor, brightColor } from '../../../../styles/config/colors';
import DownloadIcon from '../../../../assets/inline-assets/download-icon.svg';
import { useUserProfileContext } from '../../../../user/components/UserProfileWrapper/UserProfileWrapper';

const downloadCountOpen = 'downloadCountOpen';

const Container = styled('button')`
  ${buttonResetCss};
  ${monoNavCss};
  border-radius: 3px;
  width: 39px;
  height: 39px;
  background-color: ${darkColor};
  border: 0.5px solid ${transparentize(0.9, lightColor)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: ${props => (props.image ? `url('${props.image}')` : '')};
  background-size: cover;
  background-position: center;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    display none;
  }

  &.${downloadCountOpen} {
    background-color: #25272c;
    border: 0.5px solid ${transparentize(1, lightColor)};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  }

  &:focus {
    outline: none;
  }
`;

const DownloadCount = styled('div')`
  background-color: ${brightColor};
  font-family: Roboto Mono Regular, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 1px;
  width: 13px;
  height: 13px;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  justify-content: center;
  letter-spacing: 0;
  font-weight: 400;

  p {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-left: 2px;
  }
`;

type Props = {
  image?: string,
  isDownloadCountOpen?: boolean,
};

const DownloadCounter = ({ image, isDownloadCountOpen, ...props }: Props) => {
  const { remainingDailyDownloads } = useUserProfileContext();

  return (
    <Container
      {...props}
      className={cx({ [downloadCountOpen]: isDownloadCountOpen })}
      type="button"
    >
      <DownloadIcon />
      <DownloadCount>
        <p>{remainingDailyDownloads}</p>
      </DownloadCount>
    </Container>
  );
};

export default DownloadCounter;
