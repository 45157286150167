import { get } from 'lodash';
// import { VISIBILITY_FILTERS } from '../ResultsFilter/filters';

export const FILTER_TYPES = {
  filterRange: 'filterRange',
  filterSelect: 'filterSelect',
  filterBoolean: 'filterBoolean',
  filterInternalHidden: 'filterHidden',
};

export const FILTER_MENU_TYPES = {
  'audio.duration': 'filterRange',
  bpm: 'filterRange',
  'keywords.manual.edit-style': 'filterSelect',
  'keywords.manual.energy': 'filterSelect',
  'keywords.manual.genre': 'filterSelect',
  'keywords.manual.instruments': 'filterSelect',
  'keywords.manual.location': 'filterSelect',
  'keywords.manual.mood': 'filterSelect',
  'keywords.manual.movement': 'filterSelect',
  'keywords.manual.video-theme': 'filterSelect',
  'keywords.manual.type': 'filterSelect',
  'keywords.manual.feature': 'filterSelect',
  isPart: 'filterBoolean',
  mixType: 'filterBoolean',
};

export const INTERNAL_FILTER_MENU_TYPES = {
  hidden: FILTER_TYPES.filterInternalHidden,
};

export const getFilterMenuType = (facetKey: string): string => {
  return get(FILTER_MENU_TYPES, facetKey, '')
    ? get(FILTER_MENU_TYPES, facetKey, '')
    : get(INTERNAL_FILTER_MENU_TYPES, facetKey, '');
};
