// @flow
import { SUBSCRIPTIONS } from '../user/subscriptions/data';

export const isCreatorSubscription = (planCode: string): boolean => {
  return SUBSCRIPTIONS.creator.plan_codes.includes(planCode);
};

export const isCreatorProSubscription = (planCode: string): boolean => {
  return SUBSCRIPTIONS.creatorPro.plan_codes.includes(planCode);
};

export const isBusinessSubscription = (planCode: string): boolean => {
  return SUBSCRIPTIONS.business.plan_codes.includes(planCode);
};

export const isEnterpriseSubscription = (planCode: string): boolean => {
  return SUBSCRIPTIONS.enterprise.plan_codes.includes(planCode);
};

export const isEnterpriseBasicSubscription = (planCode: string): boolean => {
  return SUBSCRIPTIONS.enterpriseBasic.plan_codes.includes(planCode);
};

export const planCodeToCurrentPlanKey = (planCode: string): string => {
  const planKey = Object.keys(SUBSCRIPTIONS).filter(subscriptionKey => {
    return SUBSCRIPTIONS[subscriptionKey].plan_codes.includes(planCode);
  });
  return planKey[0] || Object.keys(SUBSCRIPTIONS)[0];
};

export const planKeyToPlanCode = (planKey: string): string => {
  const planCode = SUBSCRIPTIONS[planKey].key;
  return planCode || SUBSCRIPTIONS.creatorPro.key;
};
