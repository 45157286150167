// @flow
import React from 'react';
import { css } from 'emotion';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';

type Props = {
  queryText: string,
};

const searchTextLinkCss = css`
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

function TextLink({ text }) {
  const localePath = useLocalePath();
  return (
    <a
      className={searchTextLinkCss}
      href={`${localePath}/music/search?q=${text.replaceAll('&', '%26')}`}
    >
      {text}
    </a>
  );
}

/**
 * カテゴリで楽曲検索ページにテキストリンクで遷移させるコンポーネント
 *
 * @param {string} queryText
 * テキストリンクに表示するクエリのテキスト。クエリの検索にも使用。
 *
 * @returns
 * queryTextをテキストリンクで表示。queryTextの内容で楽曲検索ページに遷移させる。
 */
function SearchLink({ queryText }: Props) {
  const queryLink = (text: string) => {
    const splitedQuery = text.split(',');
    const queryLength = splitedQuery.length;

    return splitedQuery.map((queryText, index) => {
      return (
        <span key={`${queryText} ${index}`}>
          <TextLink text={queryText} />
          {queryLength - 1 === index ? '' : ', '}
        </span>
      );
    });
  };

  return <>{queryLink(queryText)}</>;
}

export default SearchLink;
