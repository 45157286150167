// @flow

export const BANNERS = {
  share: '/svgs/patterns/PatternShare.svg',
  cancel: '/svgs/patterns/PatternCancel.svg',
  youtube: '/svgs/patterns/PatternYouTube.svg',
  signup: '/svgs/patterns/PatternSignUp.svg',
  signin: '/svgs/patterns/PatternSignIn.svg',
  join: '/svgs/banner-join.svg',
  business: '/svgs/patterns/PatternBusiness.svg',
  creator: '/svgs/patterns/PatternCreator.svg',
  creatorPro: '/svgs/patterns/PatternCreatorPro.svg',
  enterprise: '/svgs/patterns/PatternEnterprise.svg',
  enterpriseTier: '/svgs/patterns/PatternEnterpriseTier.svg',
  dailyDownloads: '/svgs/patterns/DailyDownload.svg',
  freeTrial: '/svgs/patterns/FreeTrial.svg',
  referral: '/svgs/patterns/Referral.svg',
  referralLeft: '/svgs/patterns/ReferralLeft.svg',
  referralRight: '/svgs/patterns/ReferralRight.svg',
  referralModal: '/svgs/patterns/ReferralModal.svg',
  referralMobile: '/svgs/patterns/ReferralMobile.svg',
  // businessBig: '/svgs/banner-business-big.svg',
  // creatorBig: '/svgs/banner-creator-big.svg',
  // creatorProBig: '/svgs/banner-creatorPro-big.svg',
};

export const COLLAGES = {
  notFound: '/svgs/collage-404.svg',
  signIn: '/svgs/patterns/SideBar-SignIn.svg',
  signUp: '/svgs/patterns/SideBar-SignUp.svg',
};

export const GIFS = {
  loader: '/gifs/Loader.gif',
  loader2x: '/gifs/Loader@2x.gif',
  downloading: '/gifs/DownloadLoading@2x.gif',
  downloading2x: '/gifs/DownloadLoading@4x.gif',
  evokeOctLoading: '/gifs/EvokeOctLoading.gif',
};

export const ICONS = {
  apple: '/svgs/apple.svg',
  facebook: '/svgs/facebook.svg',
  instagram: '/svgs/instagram.svg',
  medium: '/svgs/medium.svg',
  regionEnglish: '/svgs/RegionEnglish.svg',
  regionJapan: '/svgs/RegionJapan.svg',
  regionChina: '/svgs/RegionChina.svg',
  twitter: '/svgs/twitter.svg',
  x: '/svgs/x.svg',
  youtube: '/svgs/youtube.svg',
  pokekara: '/svgs/pokekara.svg',
  shop: '/svgs/shop_icon.svg',
  sonota: '/svgs/sonota.svg',
  broadcast: '/svgs/icon-broadcast.svg',
  agency: '/svgs/icon-agency.svg',
  youtubeChannel: '/svgs/icon-youtube.svg',
  plus: '/svgs/icon-plus.svg',
  card: '/svgs/icon-card.svg',
  allowlistApproved: '/svgs/allowlistApproved.svg',
  allowlistPending: '/svgs/allowlistWaiting.svg',
  allowlistDenied: '/svgs/allowlistError.svg',
  keyboardShortcuts: '/svgs/keyboard-shortcuts.svg',
  keyboardLeftArrow: '/svgs/keyboard-left-arrow.svg',
  keyboardRightArrow: '/svgs/keyboard-right-arrow.svg',
  similarSongs: '/svgs/similarSongs.svg',
  exclusionIcon: '/svgs/exclusion-icon.svg',
  tiktok: '/svgs/tiktok.svg',
  bsazl: '/svgs/bsazl-logo.svg',
  roland: '/svgs/roland-logo.svg',
  tokyofm: '/svgs/tokyofm-logo.svg',
  masterCard: '/svgs/master-card-logo.svg',
  jcbCard: '/svgs/jcb-card-logo.svg',
  dinersClub: '/svgs/diners-club.svg',
};

export const IMAGES = {
  chromeExtension: '/images/chromeExtensionPromo.png',
  safariExtension: '/images/safariExtensionPromo.png',
  logo_4: '/images/logo_4.png',
  logo_5: '/images/logo_5.png',
  candeo: '/images/CANDEO-logo.png',
  visa: '/images/visa-logo.png',
  americanExpress: '/images/american-express-logo.jpg',
};
