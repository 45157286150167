// @flow
import React, { useEffect } from 'react';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import ProfileBookmarks from './components/ProfileBookmarks/ProfileBookmarks';
import BookmarksManager from './components/BookmarksManager/BookmarksManager';

type Props = {
  path: string,
};

const ProfileBookmarksView = ({ path }: Props) => {
  const { closeGlobalPlayer } = useGlobalPlayerContext();

  useEffect(() => {
    closeGlobalPlayer();
  }, []);

  return (
    <BookmarksManager>
      <PageComponent path={path}>
        <ProfileBookmarks />
      </PageComponent>
    </BookmarksManager>
  );
};

export default ProfileBookmarksView;
