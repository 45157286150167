import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { getMiscDoneButton, getMiscTagFiltersModalTitle } from '../../../../cms/miscCopy';
import { DarkCompactButton } from '../../../../components/Button/Button';
import { useMiscCopy } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import Modal, {
  fullSizeModalCss,
  fullSizeModalOverlayCss,
} from '../../../../modals/components/Modal/Modal';
import { darkColor } from '../../../../styles/config/colors';
import { boldFontWeightCss } from '../../../../styles/typography/typography';
import { TagFilterSection } from './GroupedTagSections';
import type { SongTagFiltersMdl } from '../../../../utils/tagFilters';

const Container = styled('div')`
  margin: 10px;
  width: 95%;
  height: 98%;
  border-radius: 3px;
  background-color: #dad8d3;
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
`;

const ModalNav = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid ${transparentize(0.9, darkColor)};
  padding: 10px 5px 10px 15px;
`;

const BackButtonContainer = styled('div')`
  display: flex;
  align-items: ceter;
  width: 20%;
  padding-left: 4px;
`;

const Title = styled('div')`
  ${boldFontWeightCss}
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const DoneButtonContainer = styled('div')`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
`;

const DoneButton = styled(DarkCompactButton)`
  min-width: 60px;
  height: 29px;
  color: ${darkColor};
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.67px;
  line-height: 0px;
  text-align: center;
`;

const FiltersContainer = styled('div')`
  padding: 10px 15px;
  height: 90%;
  overflow-y: scroll;
`;

type Props = {
  onClose: () => void,
  facetsMap: SongTagFiltersMdl,
  handleFilterToggle: (isSelected: boolean, filterKey: string, filterValue: string) => void,
};

const FiltersModal = ({ onClose, facetsMap, handleFilterToggle }: Props) => {
  const miscCopy = useMiscCopy();

  return (
    <Modal
      open
      onClose={onClose}
      className={fullSizeModalCss}
      overlayClassName={fullSizeModalOverlayCss}
      shouldReturnFocusAfterClose={false}
    >
      <Container>
        <ModalNav>
          <BackButtonContainer></BackButtonContainer>
          <Title>
            <h6>{getMiscTagFiltersModalTitle(miscCopy)}</h6>
          </Title>
          <DoneButtonContainer>
            <DoneButton onClick={onClose}>{getMiscDoneButton(miscCopy)}</DoneButton>
          </DoneButtonContainer>
        </ModalNav>
        <FiltersContainer>
          {facetsMap.map(facetData => {
            return (
              <TagFilterSection
                key={facetData.facetKey}
                facetData={facetData}
                handleFilterToggle={handleFilterToggle}
                isMobile
              />
            );
          })}
        </FiltersContainer>
      </Container>
    </Modal>
  );
};

export default FiltersModal;
