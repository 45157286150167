// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRecoilValue } from 'recoil';
import { Link } from '@reach/router';
import { spacing } from '../../styles/config/spacing';
import {
  hideOnLargeDeviceCss,
  HideOnSmallDesktop,
  hideOnSmallDesktopDeviceCss,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../styles/responsive';
import { mediumFontSizeCss, monoFontCss } from '../../styles/typography/typography';
import SiteNav from './components/SiteNav/SiteNav';
import HeaderOptions from './components/HeaderOptions/HeaderOptions';
import MobileMenuButton from './components/MobileMenuButton/MobileMenuButton';
import MobileMenu from './components/MobileMenu/MobileMenu';
import { useModalsContext } from '../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import ReferralConditionsModal from './components/ReferralConditionsModal/ReferralConditionsModal';
import ReferralBanner from './components/ReferralBanner/ReferralBanner';
import { isSmallDeviceSize } from '../../utils/device';
import { useLocalePath } from '../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../routing/routes';
import { searchActiveAtom } from '../../store/siteHeaderSearch';
import { IMAGES } from '../../images/paths';

export const headerHeight = 76;
export const mobileHeaderHeight = 56;

const Header = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  transition: all 300ms ease;
`;

const scrolledNavClass = css`
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.2);
`;

const minWidthCss = css`
  flex: 1;
`;

const HeaderNav = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: ${headerHeight}px;
  padding: 0 ${spacing.medium}px;

  ${MEDIA_BREAKPOINTS.max1000} {
    justify-content: space-between;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    height: ${mobileHeaderHeight}px;
    padding: 0 ${spacing.small}px;
  }
`;

const SideStuff = styled('div')`
  ${minWidthCss};
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    margin-left: 2px;
  }

  a,
  img {
    display: block;
  }

  img {
    ${SMALL_DEVICE_BREAKPOINT} {
      width: 40px;
      height: 40px;
    }
  }
`;

const Name = styled('div')`
  ${mediumFontSizeCss};

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    display: none;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 14px;
    letter-spacing: 0.44px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }

  span {
    ${monoFontCss};
    opacity: 0.44;
  }
`;

const NavWrapper = styled('div')`
  ${hideOnSmallDesktopDeviceCss};
`;

const Options = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${MEDIA_BREAKPOINTS.min1000} {
    ${minWidthCss};
  }
`;

const MobileOptions = styled('div')`
  display: none;
  ${MEDIA_BREAKPOINTS.max1000} {
    display: flex;
    align-items: center;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    &.mobileSearchActivePadding {
      width: 100%;
    }
  }
`;

type Props = {
  hideNav?: boolean,
  headerOptions?: any,
  removeSiteName?: boolean,
  hideSiteNameOnDesktop?: boolean,
  showHeaderOptionsOnMobile?: boolean,
  mobileHeaderOptions?: any,
  hideReferralSignUpSignIn?: boolean,
};

const SiteHeader = ({
  headerOptions = <HeaderOptions />,
  hideNav = false,
  removeSiteName = false,
  hideSiteNameOnDesktop = false,
  showHeaderOptionsOnMobile = false,
  mobileHeaderOptions = null,
  hideReferralSignUpSignIn = false,
}: Props) => {
  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    showReferralConditions,
    setShowReferralConditions,
  } = useModalsContext();
  const [hasScrolled, setHasScrolled] = useState(false);
  const { referralCoupon, showReferralBanner, setShowReferralBanner } = useSubscriptionsContext();

  const searchActive = useRecoilValue(searchActiveAtom);
  const localePath = useLocalePath();

  const siteNameImageSource = IMAGES.logo_5 || '';

  const viewingArtist = (() => {
    if (window) {
      return window.location.pathname.includes('/artists/');
    }
    return false;
  })();

  useScrollPosition(
    ({ currPos }) => {
      if (!isSmallDeviceSize() || viewingArtist) {
        const isShow = currPos.y < -5;
        if (isShow !== hasScrolled) setHasScrolled(isShow);
      }
    },
    [hasScrolled]
  );

  const handleShowReferralConditions = () => {
    setShowReferralConditions(true);
  };

  const onCloseReferralConditions = () => {
    setShowReferralConditions(false);
  };

  return (
    <React.Fragment>
      <Header
        className={cx({
          [scrolledNavClass]: hasScrolled,
        })}
      >
        {referralCoupon && !hideReferralSignUpSignIn && showReferralBanner && (
          <ReferralBanner
            referralCoupon={referralCoupon}
            showReferralBanner={showReferralBanner}
            handleShowReferralConditions={handleShowReferralConditions}
            setShowReferralBanner={setShowReferralBanner}
          />
        )}
        <HeaderNav
          className={cx({
            mobileSearchActivePadding: isSmallDeviceSize() && searchActive,
          })}
        >
          <SideStuff>
            <Link to={ROUTES.music.navigatePath({ localePath })}>
              <LogoWrapper>
                <img width="50" height="50" src={IMAGES.logo_4} />
              </LogoWrapper>
            </Link>
            {!removeSiteName && (
              <Link to={ROUTES.music.navigatePath({ localePath })}>
                <Name
                  className={cx({
                    [hideOnLargeDeviceCss]: hideSiteNameOnDesktop,
                  })}
                >
                  <img height="50" src={siteNameImageSource} />
                </Name>
              </Link>
            )}
          </SideStuff>
          <NavWrapper>{!hideNav && <SiteNav />}</NavWrapper>
          <Options
            className={cx({
              mobileSearchActivePadding: isSmallDeviceSize() && searchActive,
            })}
          >
            {showHeaderOptionsOnMobile && headerOptions ? (
              headerOptions
            ) : (
              <React.Fragment>
                <HideOnSmallDesktop>{headerOptions}</HideOnSmallDesktop>
                <MobileOptions
                  className={cx({
                    mobileSearchActivePadding: isSmallDeviceSize() && searchActive,
                  })}
                >
                  {mobileHeaderOptions}
                  <>
                    <MobileMenuButton
                      onClick={() => {
                        setMobileMenuOpen(true);
                      }}
                    />
                  </>
                </MobileOptions>
              </React.Fragment>
            )}
          </Options>
        </HeaderNav>
        {showReferralConditions && (
          <ReferralConditionsModal
            onClose={onCloseReferralConditions}
            referralCoupon={referralCoupon}
          />
        )}
      </Header>
      {mobileMenuOpen && (
        <MobileMenu
          onClose={() => {
            setMobileMenuOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SiteHeader;
