// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import { lightColor, darkColor } from '../../../../../../../styles/config/colors';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';

const parentClass = 'songActionButton';

const Button = styled('button')`
  ${buttonResetCss};
  display: block;
  width: 40px;
  height: 40px;
  //border-radius: 50%;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    z-index: 5;
  }
`;

const IconContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    path {
      fill: ${transparentize(0.8, darkColor)};
      stroke: ${transparentize(0.8, darkColor)};
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: ${transparentize(0.95, darkColor)};
    opacity: 0;
    transform: scale(0.75);
    transition: all 200ms ease-in;
  }
`;

const Label = styled('div')`
  position: absolute;
  top: 100%;
  left: -55px;
  text-align: left;
  background-color: #fff;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 150px;
  text-align: center;
  height: 36px;
  padding: 4px 2px;
  line-height: 14px;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease-in;

  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }

  .${parentClass}:hover &,
  .${parentClass}:active &,
  .${parentClass}:focus & {
    pointer-events: unset;
    opacity: 1;
  }
`;

const condensedLabelClass = css`
  padding-top: 1px;
`;

const topLabelCss = css`
  background-color: ${lightColor};
  top: -50%;
`;

type Props = {
  label: string,
  icon: any,
  condensed?: boolean,
  topLabel?: boolean,
  inModal?: Boolean,
};

const SongActionButton = ({
  label,
  icon,
  condensed = false,
  topLabel = false,
  inModal = false,
  ...props
}: Props) => (
  <Button className={parentClass} {...props}>
    <IconContainer>{icon}</IconContainer>
    <Label
      style={{ left: inModal ? '-200%' : '' }}
      className={cx({
        [condensedLabelClass]: condensed,
        [topLabelCss]: topLabel,
      })}
    >
      {label}
    </Label>
  </Button>
);

export default SongActionButton;
