// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type TermsPageGraphMdl = {
  data: {
    terms_of_service_page_header: {
      text: string,
    },
    terms_of_service_body: {
      html: string,
    },
    business_license_link: {
      html: string,
    },
    creator_license_link: {
      html: string,
    },
    creator_pro_license_link: {
      html: string,
    },
    enterprise_license_link: {
      html: string,
    },
    single_track_limited_media_license_link: {
      html: string,
    },
    single_track_all_media_license_link: {
      html: string,
    },
    exclusive_rights_track_license_link: {
      html: string,
    },
  },
};

export const getTermsPageHeading = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.terms_of_service_page_header.text', '') || '';
};

export const getTermsPageBody = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.terms_of_service_body.html', '') || '';
};

export const getBusinessMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.business_license_link.html', '') || '';
};

export const getCreatorMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.creator_license_link.html', '') || '';
};

export const getCreatorProMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.creator_pro_license_link.html', '') || '';
};

export const getEnterpriseMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.enterprise_license_link.html', '') || '';
};

export const getSingleTrackLimitedMediaMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.single_track_limited_media_license_link.html', '') || '';
};

export const getSingleTrackAllMediaMusicLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.single_track_all_media_license_link.html', '') || '';
};

export const getExclusiveRightsTrackLicenseLink = (copy: TermsPageGraphMdl): string => {
  return get(copy, 'data.exclusive_rights_track_license_link.html', '') || '';
};

export const query = graphql`
  fragment TermsPage on PrismicTermsOfServicePage {
    data {
      terms_of_service_page_header {
        text
      }
      terms_of_service_body {
        html
      }
      business_license_link {
        html
      }
      creator_license_link {
        html
      }
      creator_pro_license_link {
        html
      }
      enterprise_license_link {
        html
      }
      single_track_limited_media_license_link {
        html
      }
      single_track_all_media_license_link {
        html
      }
      exclusive_rights_track_license_link {
        html
      }
    }
  }
`;
