// @flow
import axios from 'axios';
import download from 'downloadjs';
import firebase from 'firebase/app';
import { auth0ApiHandler } from '../auth/auth0';
import { useGenericApiState } from '../hooks';
import { AuthenticationError } from '../../errors/errors';
import type { ChannelMdl, UserSubscriptionMdl, ChannelYouTubeMdl } from './user/subscriptions';
import {
  getSubscriptionChannel,
  getSubscriptionChannelId,
  getSubscriptionYouTubeData,
  getSubscriptionPlanKey,
} from './user/subscriptions';
import type { InvoiceDownloadMdl, InvoicesResponse } from './user/payment';
import type { DownloadContextState } from '../../components/DownloadContextWrapper/DownloadContextWrapper';
import { analyticsLogApiDuration } from '../../analytics';
import { SUBSCRIPTIONS, SUBSCRIPTION_HINTS } from '../../user/subscriptions/data';

export const authenticateFirebaseUser = (token: string): Promise<any> => {
  return firebase.auth().signInWithCustomToken(token);
};

export type FirebaseTokenResponse = {
  firebaseToken: string,
};

const defaultAxiosConfig = {
  baseURL: process.env.GATSBY_FIREBASE_API_DOMAIN,
};

export type ChannelsCheckResponseMdl = {
  connectable: boolean,
};

class FirebaseApiHandler {
  api = axios.create(defaultAxiosConfig);

  constructor() {
    this.api.interceptors.request.use(request => {
      try {
        request.ts = performance.now();
      } catch (error) {
        console.error(error);
      }
      return request;
    });

    // Add a response interceptor
    this.api.interceptors.response.use(
      function(response) {
        try {
          const duration = Number(performance.now() - response.config.ts);
          analyticsLogApiDuration(response.config.url, duration);
        } catch (error) {
          console.error(error);
        }

        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function(error) {
        if (error.response) {
          if (error.response.status === 401) {
            throw new AuthenticationError(error, '401');
          }
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        throw error;
      }
    );
  }

  handleResponseData = (response?: { data: any }) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  };

  refreshAuthToken = async (): Promise<string> => {
    console.log('Firebase Token Refresh running...');
    const token = await auth0ApiHandler.getAccessToken();
    return token;
  };

  // [20230305_緊急対応]下記はほんとの原因がわかるまではコメントアウトしておく。
  // refreshAuthToken = (authToken?: string): Promise<any> => {
  //   console.log('Firebase Token Refresh running...');
  //   if (authToken) {
  //     this.setAuthToken(authToken);
  //     return Promise.resolve();
  //   }
  //   return auth0ApiHandler.getAccessToken().then((token: string) => {
  //     this.setAuthToken(token);
  //   });
  // };

  // [20230305_緊急対応]下記はほんとの原因がわかるまではコメントアウトしておく。
  setAuthToken = (authToken: string) => {
    Object.assign(this.api.defaults, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  getFirebaseToken = async ({
    authToken = '',
  }: {
    authToken: string,
  }): Promise<FirebaseTokenResponse> => {
    console.log('Get Firebase Token running...');
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post('/me/firebaseToken', { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  getKeywordSuggestions = (keywords: Array<string>, locale: string) => {
    let url = `/suggest-keywords?limit=5`;
    url += `&lang=${locale}`;
    if (keywords && keywords.length > 0) {
      url += `&word=${keywords.join(',')}`;
    }
    return this.api.get(url).then(this.handleResponseData);
  };

  getValidYouTubeTags = (
    categoryQuery: string,
    tagQuery: string,
    channelTitle: string,
    localeQuery: string
  ): Promise<any> => {
    const url = `/validateSearchQuery?${localeQuery}${tagQuery ? `&tags=${tagQuery}` : ''}${
      categoryQuery ? `&categories=${categoryQuery}` : ''
    }${channelTitle ? `&channelTitle=${channelTitle}` : ''}`;
    return this.api.get(url).then(this.handleResponseData);
  };

  fetchInvoices = async (): Promise<InvoicesResponse> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/invoices`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  fetchInvoicePdf = async (invoiceId: string): Promise<InvoiceDownloadMdl> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/invoices/${invoiceId}/download`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  fetchUpdatePaymentCardPage = async () => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/updateCard`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  fetchPaymentSource = async () => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/paymentSource`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  fetchCustomOrderReceipts = async () => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/customOrderReceipts`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  removeScheduledChanges = async (channelId: string) => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/channels/${channelId}/subscription/removeScheduledChanges`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  setChannelPlan = async (
    channelId: string,
    planKey: string,
    interval: string | null,
    locale: string,
    couponCode?: string
  ): Promise<any> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/me/channels/${channelId}/subscription`, {
        plan: planKey,
        interval,
        locale,
        couponCode,
        userInfo: token,
      })
      .then(this.handleResponseData);
    return result;
  };

  isYouTubeChannelConnected = async (
    channelId: string,
    subscriptionHint: string
  ): Promise<ChannelsCheckResponseMdl> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/channels/check`, {
        type: 'youtube',
        subscriptionHint,
        connectionId: channelId,
        userInfo: token,
      })
      .then(this.handleResponseData);
    return result;
  };

  getRedirectUrl = async (url: string) => {
    try {
      if (process.env.GATSBY_REPLACE_API_URL) {
        url = url.replace(
          'https://staging-api.evokemusic.ai/stream',
          'https://staging-api.evokemusic.ai/getStream'
        );
      } else {
        url = url.replace(
          'https://api.evokemusic.ai/stream',
          'https://api.evokemusic.ai/getStream'
        );
      }
      const response = await axios.get(url, this.api.defaults);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  downloadSong = async (
    songID: string,
    downloadURL: string,
    downloadContext: DownloadContextState,
    fileName: string = ''
  ) => {
    if (process.env.GATSBY_REPLACE_API_URL) {
      downloadURL = downloadURL.replace(
        'https://api.evokemusic.ai',
        'https://staging-api.evokemusic.ai'
      );
    }

    const { location = '', keywords = [], youtubeVideoTitle = '' } = downloadContext;

    if (location) {
      downloadURL += `&location=${location}`;
    }

    if (youtubeVideoTitle) {
      downloadURL += `&context=${youtubeVideoTitle}`;
    }

    if (keywords && keywords.length > 0) {
      keywords.forEach((keyword: string) => {
        downloadURL += `&context=${keyword}`;
      });
    }

    if (fileName) {
      downloadURL += `&sa=${fileName}`;
    }

    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    downloadURL += `&userInfo=${token}`;

    return axios
      .get(downloadURL, this.api.defaults)
      .then(response => {
        return response.data;
      })
      .then(response => {
        if (response && response.signedUrl) {
          try {
            if (navigator.userAgent.match('CriOS')) {
              window.open(response.signedUrl, '_blank');
            } else {
              window.location.href = response.signedUrl;
            }
          } catch (error) {
            console.error(error);
            window.location.href = response.signedUrl;
          }
          return response.signedUrl;
        }
        return Promise.reject();
      });
  };

  fetchChargebeeSubscription = async (subscriptionId: string): Promise<any> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const result = await this.api
      .post(`/chargebee/subscriptions/${subscriptionId}`, { userInfo: token })
      .then(this.handleResponseData);
    return result;
  };

  updateUserChannels = async (
    subscriptions: Array<UserSubscriptionMdl>
  ): Promise<{
    userChannels: Array<ChannelMdl>,
  }> => {
    const dataToSend = subscriptions.map(subscription => {
      const channel = getSubscriptionChannel(subscription);
      let subscriptionPlan;

      // If channel is null, then this is a new subscription and we will just temporarily used a free subscriptionHint for the backend to use
      if (subscription.channel === null) {
        subscriptionPlan = SUBSCRIPTIONS.creator.key;
      } else {
        subscriptionPlan = getSubscriptionPlanKey(subscription);
      }

      if (!channel) {
        return {
          youtube: null,
          facebook: null,
          instagram: null,
        };
      }

      const channelId = getSubscriptionChannelId(subscription);
      const youtube = getSubscriptionYouTubeData(subscription);
      const facebook = null;
      const instagram = null;
      const subscriptionHint = SUBSCRIPTIONS.creator.plan_codes.includes(subscriptionPlan)
        ? SUBSCRIPTION_HINTS.free
        : SUBSCRIPTION_HINTS.paid;

      const data = {
        youtube,
        facebook,
        instagram,
        subscriptionHint,
      };

      if (channelId) {
        data.id = channelId;
      }

      return data;
    });
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const bodyParam = { userInfo: token, dataToSend };
    const result = await this.api.post(`/me/channels`, bodyParam).then(this.handleResponseData);
    return result;
  };

  updateExtraChannels = async (
    subscriptionKey: string,
    extraChannels: Array<ChannelYouTubeMdl>
  ): Promise<any> => {
    // [20230305_緊急対応]auto0のJWTは直接パラメータとして送ることで、ESPv2のJWT検証を回避する。
    const token = await this.refreshAuthToken();
    const dataToSend = { userInfo: token, extraChannels };
    const result = await this.api
      .post(`/me/channels/${subscriptionKey}/extraChannels`, dataToSend)
      .then(this.handleResponseData);
    return result;
  };

  fetchReferralCouponData = (referralCode: string): Promise<any> => {
    return this.api.get(`/coupon/${referralCode}`).then(this.handleResponseData);
  };
}

export const firebaseApiHandler = new FirebaseApiHandler();

export const useIsYouTubeChannelConnected = () => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = (
    channelId: string,
    subscriptionHint: string
  ): Promise<ChannelsCheckResponseMdl> => {
    return firebaseApiHandler
      .isYouTubeChannelConnected(channelId, subscriptionHint)
      .catch(apiErrorHandler);
  };
  return [fetch];
};

export const useFetchInvoices = () => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = async (): Promise<InvoicesResponse> => {
    if (busy) return Promise.reject();
    setBusy(true);
    const result = await firebaseApiHandler
      .fetchInvoices()
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
    return result;
  };
  return [fetch, busy, error];
};

export const useCustomOrderReceipts = () => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = async (): Promise<InvoicesResponse> => {
    if (busy) return Promise.reject();
    setBusy(true);
    const result = await firebaseApiHandler
      .fetchCustomOrderReceipts()
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
    return result;
  };
  return [fetch, busy, error];
};

export const useFetchInvoicePdf = (invoiceId: string) => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = async (): Promise<InvoiceDownloadMdl> => {
    if (busy) return Promise.reject();
    setBusy(true);
    const result = await firebaseApiHandler
      .fetchInvoicePdf(invoiceId)
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
    return result;
  };

  return [fetch, busy, error];
};

export const useFetchPaymentSource = () => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = async (): Promise<any> => {
    if (busy) return Promise.reject();
    setBusy(true);
    const result = await firebaseApiHandler
      .fetchPaymentSource()
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
    return result;
  };
  return [fetch, busy, error];
};

export const useFetchUpdatePaymentCard = () => {
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();
  const fetch = async (): Promise<any> => {
    if (busy) return Promise.reject();
    setBusy(true);
    const result = await firebaseApiHandler
      .fetchUpdatePaymentCardPage()
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
    return result;
  };
  return [fetch, busy, error];
};
