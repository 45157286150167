// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';
import type { SingleTrackAllMediaLicensePageGraphMdl } from './allMediaTrackLicensePage';
import type { ExclusiveRightsTrackLicensePageGraphMdl } from './exclusiveRightsTrackLicensePage';

export type SingleTrackLimitedMediaLicensePageGraphMdl = {
  data: {
    license_header: {
      text: string,
    },
    license_body: {
      html: string,
    },
  },
};

export const getCustomTrackLicensePageHeading = (
  copy:
    | SingleTrackLimitedMediaLicensePageGraphMdl
    | SingleTrackAllMediaLicensePageGraphMdl
    | ExclusiveRightsTrackLicensePageGraphMdl
): string => {
  return get(copy, 'data.license_header.text', '') || '';
};

export const getCustomTrackLicensePageBody = (
  copy:
    | SingleTrackLimitedMediaLicensePageGraphMdl
    | SingleTrackAllMediaLicensePageGraphMdl
    | ExclusiveRightsTrackLicensePageGraphMdl
): string => {
  return get(copy, 'data.license_body.html', '') || '';
};

export const query = graphql`
  fragment CustomTrackLicensePage on PrismicCustomTrackLicensePage {
    data {
      license_header {
        text
      }
      license_body {
        html
      }
    }
  }
`;
