// @flow

import type { AlgoliaSongMdl } from './song';
import type { AlgoliaKeyword } from './magicKeywords';
import { getAlgoliaIndex, getAlgoliaKeywordsIndex } from './client';
import { isAlgoliaAnalyticsEnabled } from '../../utils/env';
import { getAlgoliaMaxSearchResults } from '../../utils/algolia';
import { getAppliedFiltersExclusiveList, getAppliedFiltersInclusiveList } from './data';
import {
  FILTER_TYPES,
  FILTER_MENU_TYPES,
} from '../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';

export type AlgoliaFacetResults = {
  'audio.duration': {
    [string]: number,
  },
  bpm: {
    [string]: number,
  },
  'keywords.manual.edit-style': {
    [string]: number,
  },
  'keywords.manual.energy': {
    [string]: number,
  },
  'keywords.manual.genre': {
    [string]: number,
  },
  'keywords.manual.instruments': {
    [string]: number,
  },
  'keywords.manual.location': {
    [string]: number,
  },
  'keywords.manual.mood': {
    [string]: number,
  },
  'keywords.manual.movement': {
    [string]: number,
  },
  'keywords.manual.video-theme': {
    [string]: number,
  },
  'keywords.manual.type': {
    [string]: number,
  },
  'keywords.manual.feature': {
    [string]: number,
  },
  isPart: {
    [boolean]: number,
  },
  mixType: {
    [string]: number,
  },
};

export type AlgoliaFacetStats = {
  'audio.duration': {
    avg: number,
    max: number,
    min: number,
    sum: number,
  },
  bpm: {
    [string]: number,
  },
};

export type AlgoliaSearchResults = {
  hits: Array<AlgoliaSongMdl>,
  nbHits: number,
  nbPages: number,
  facets: AlgoliaFacetResults,
  facets_stats: AlgoliaFacetStats,
};

export type AlgoliaKeywordSearchResults = {
  hits: Array<AlgoliaKeyword>,
  nbHits: number,
  nbPages: number,
  facets: AlgoliaFacetResults,
  facets_stats: AlgoliaFacetStats,
};

export type AppliedAlgoliaFilters = {
  'audio.duration'?: {
    max: number,
    min: number,
  },
  bpm?: {
    max: number,
    min: Number,
  },
  'keywords.manual.edit-style'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.energy'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.genre'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.instruments'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.location'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.mood'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.movement'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.video-theme'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.type'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  'keywords.manual.feature'?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  isPart?: {
    included: Array<string>,
    excluded: Array<String>,
  },
  mixType?: {
    included: Array<string>,
    excluded: Array<string>,
  },
};

export const getAlgoliaTagsByCategory = (tagCategory: string, locale: string) => {
  const indexToUse = getAlgoliaKeywordsIndex(locale);
  const filters = `keywordType:${tagCategory}`;
  return indexToUse
    .search({
      analytics: isAlgoliaAnalyticsEnabled(),
      query: '',
      hitsPerPage: 1000,
      facetFilters: [],
      facets: [],
      filters,
      getRankingInfo: false,
    })
    .then((content: AlgoliaKeywordSearchResults) => {
      return content;
    });
};

export const searchAlgolia = (
  searchTerm: string,
  locale: string,
  facetFilters: Array<string> = [],
  filters: string = ''
): Promise<AlgoliaSearchResults> => {
  const indexToUse = getAlgoliaIndex(locale);
  const maxSearchResults = getAlgoliaMaxSearchResults();
  return indexToUse
    .search({
      analytics: isAlgoliaAnalyticsEnabled(),
      query: searchTerm,
      hitsPerPage: maxSearchResults,
      facetFilters,
      facets: [
        'audio.duration',
        'bpm',
        'keywords.manual.edit-style',
        'keywords.manual.energy',
        'keywords.manual.genre',
        'keywords.manual.instruments',
        'keywords.manual.location',
        'keywords.manual.mood',
        'keywords.manual.movement',
        'keywords.manual.video-theme',
        'keywords.manual.type',
        'keywords.manual.feature',
        'isPart',
        'mixType',
      ],
      filters,
      getRankingInfo: true,
    })
    .then((content: AlgoliaSearchResults) => {
      return content;
    });
};

export const searchPreviewAlgolia = (
  searchTerm: string,
  locale: string,
  facetFilters: Array<string> = [],
  filters: string = ''
): Promise<AlgoliaSearchResults> => {
  const indexToUse = getAlgoliaIndex(locale);
  const maxSearchResults = 50;
  return indexToUse
    .search({
      analytics: isAlgoliaAnalyticsEnabled(),
      query: searchTerm,
      hitsPerPage: maxSearchResults,
      facetFilters,
      filters,
      getRankingInfo: true,
    })
    .then((content: AlgoliaSearchResults) => {
      return content;
    });
};

export const searchSimilarAlgolia = (
  searchTerm: string,
  locale: string,
  facetFilters: Array<any> = [],
  filters: string = ''
) => {
  const indexToUse = getAlgoliaIndex(locale);
  return indexToUse
    .search({
      analytics: isAlgoliaAnalyticsEnabled(),
      similarQuery: searchTerm,
      hitsPerPage: 20,
      facetFilters,
      facets: [
        'audio.duration',
        'bpm',
        'keywords.manual.edit-style',
        'keywords.manual.energy',
        'keywords.manual.genre',
        'keywords.manual.instruments',
        'keywords.manual.location',
        'keywords.manual.mood',
        'keywords.manual.movement',
        'keywords.manual.video-theme',
        'keywords.manual.type',
        'keywords.manual.feature',
        'isPart',
        'mixType',
      ],
      filters,
      getRankingInfo: true,
    })
    .then((content: AlgoliaSearchResults) => {
      return content;
    });
};

export const getTracksFromAlgolia = (
  objectIds: Array<string>,
  locale: string
): Promise<Array<any>> => {
  const index = getAlgoliaIndex(locale);
  return index.getObjects(objectIds);
};

const getInclusiveFacetSelectFilterQuery = (facetKey, includedFilters) => {
  let inclusiveFilterQuery = '';
  includedFilters.forEach(includedFilter => {
    if (!inclusiveFilterQuery) {
      inclusiveFilterQuery = inclusiveFilterQuery.concat(`${facetKey}:"${includedFilter}"`);
    } else {
      inclusiveFilterQuery = inclusiveFilterQuery.concat(` OR ${facetKey}:"${includedFilter}"`);
    }
  });

  inclusiveFilterQuery = '( '.concat(inclusiveFilterQuery).concat(' )');
  return inclusiveFilterQuery;
};

const getExclusiveFacetSelectFilterQuery = (facetKey, excludedFilters) => {
  let exclusiveFilterQuery = '';
  excludedFilters.forEach(excludedFilter => {
    if (!exclusiveFilterQuery) {
      exclusiveFilterQuery = exclusiveFilterQuery.concat(`NOT ${facetKey}:"${excludedFilter}"`);
    } else {
      exclusiveFilterQuery = exclusiveFilterQuery.concat(
        ` AND NOT ${facetKey}:"${excludedFilter}"`
      );
    }
  });
  return exclusiveFilterQuery;
};

export const getSelectFilterQuery = (appliedFilters: any): any => {
  if (!appliedFilters) {
    return '';
  }
  let filterQuery = '';

  Object.keys(appliedFilters).forEach(appliedFilter => {
    if (FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect) {
      const filtersToInclude = getAppliedFiltersInclusiveList(appliedFilters, appliedFilter);
      const filtersToExclude = getAppliedFiltersExclusiveList(appliedFilters, appliedFilter);

      if (filtersToInclude.length > 0) {
        if (filterQuery) {
          filterQuery = filterQuery.concat(' AND ');
        }
        const inclusiveQuery = getInclusiveFacetSelectFilterQuery(appliedFilter, filtersToInclude);
        filterQuery = filterQuery.concat(inclusiveQuery);
      }

      if (filtersToExclude.length > 0) {
        if (filterQuery) {
          filterQuery = filterQuery.concat(' AND ');
        }
        const exclusiveQuery = getExclusiveFacetSelectFilterQuery(appliedFilter, filtersToExclude);
        filterQuery = filterQuery.concat(exclusiveQuery);
      }
    }
  });
  return filterQuery;
};

export const getRangeFilterQuery = (appliedFilters: any) => {
  const appliedRangeFilters = Object.keys(appliedFilters).filter(appliedFilter => {
    return FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange;
  });
  let rangeFilterQuery = '';
  appliedRangeFilters.forEach(appliedRangeFilter => {
    if (rangeFilterQuery) {
      rangeFilterQuery = rangeFilterQuery.concat(' AND ');
    }
    rangeFilterQuery = rangeFilterQuery.concat(
      `${appliedRangeFilter}:${appliedFilters[appliedRangeFilter].min} TO ${appliedFilters[appliedRangeFilter].max}`
    );
  });
  return rangeFilterQuery;
};

export const getBooleanFilterQuery = (appliedFilters: any) => {
  const appliedBooleanFilters = Object.keys(appliedFilters).filter(appliedFilter => {
    return FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean;
  });
  let booleanFilterQuery = '';
  appliedBooleanFilters.forEach(appliedBooleanFilter => {
    const filtersToInclude = getAppliedFiltersInclusiveList(appliedFilters, appliedBooleanFilter);
    const filtersToExclude = getAppliedFiltersExclusiveList(appliedFilters, appliedBooleanFilter);

    if (filtersToInclude.length > 0) {
      if (booleanFilterQuery) {
        booleanFilterQuery = booleanFilterQuery.concat(' AND ');
      }
      const inclusiveQuery = getInclusiveFacetSelectFilterQuery(
        appliedBooleanFilter,
        filtersToInclude
      );
      booleanFilterQuery = booleanFilterQuery.concat(inclusiveQuery);
    }

    if (filtersToExclude.length > 0) {
      if (booleanFilterQuery) {
        booleanFilterQuery = booleanFilterQuery.concat(' AND ');
      }
      const exclusiveQuery = getExclusiveFacetSelectFilterQuery(
        appliedBooleanFilter,
        filtersToExclude
      );
      booleanFilterQuery = booleanFilterQuery.concat(exclusiveQuery);
    }
  });
  return booleanFilterQuery;
};

export const getFilterQuery = (appliedFilters: any) => {
  let filterQuery = '';
  const selectQuery = getSelectFilterQuery(appliedFilters);
  filterQuery = filterQuery.concat(selectQuery);

  const rangeQuery = getRangeFilterQuery(appliedFilters);
  filterQuery = filterQuery && rangeQuery ? filterQuery.concat(` AND `) : filterQuery.concat('');
  filterQuery = rangeQuery ? filterQuery.concat(`${rangeQuery}`) : filterQuery.concat('');

  const booleanQuery = getBooleanFilterQuery(appliedFilters);
  filterQuery = filterQuery && booleanQuery ? filterQuery.concat(` AND `) : filterQuery.concat('');
  filterQuery = booleanQuery ? filterQuery.concat(`${booleanQuery}`) : filterQuery.concat('');
  return filterQuery;
};
