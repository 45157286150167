// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import { get } from 'lodash';
import { useSetRecoilState } from 'recoil';
import DownloadButton from '../FullSongCard/components/SongActions/components/DownloadButton/DownloadButton';
import DisabledDownloadButton from '../FullSongCard/components/SongActions/components/DisabledDownloadButton/DisabledDownloadButton';
import PlayPauseButton from '../FullSongCard/components/SongActions/components/PlayPauseButton/PlayPauseButton';
import { useDownloadSong } from '../../hooks';
import AudioBars from '../AudioBars/AudioBars';
import {
  LARGE_DESKTOP_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { monoFontCss } from '../../../styles/typography/typography';
import { buttonResetCss, interactiveTextButtonCss } from '../../../components/Button/Button';
import { darkColor } from '../../../styles/config/colors';
import BookmarkButton from '../FullSongCard/components/SongActions/components/BookmarkButton/BookmarkButton';
import { USER_PERMISSIONS } from '../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useFreeTierRestrictionToast } from '../../../toasts/hooks';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadedSongsContext } from '../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import {
  getSongDurationFormatted,
  getSongID,
  getSongName,
  getSongArtist,
  getSongArtistSlug,
  getSongArtwork,
  getSongShareSlug,
  getSongBPM,
  getSongGenres,
  getSongVideoTheme,
  getSongEnergy,
  getSongMood,
  getSongEditStyle,
  getSongMicroparts,
  getSongMixType,
  isSongStem,
} from '../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { useDownloadContext } from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useAudioPlaybackContext } from '../AudioPlayback/AudioPlayback';
import { useAudioPlayerContext } from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { useUserHiddenTracks } from '../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  conversionSourceAtom,
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../store/mixpanelAnalytics';
import ArtistName from '../FullDisplaySongCard/components/ArtistName/ArtistName';
import { useNavigate } from '../../../routing/hooks';
import { ROUTES } from '../../../routing/routes';
import SimilarSongsButton from '../FullSongCard/components/SongActions/components/SimilarSongsButton/SimilarSongsButton';
import SearchLink from '../../../components/Link/components/SearchLink';
import ViewPackButton from '../FullSongCard/components/SongActions/components/ViewPackButton/ViewPackButton';
import ShareButton from '../FullSongCard/components/SongActions/components/ShareButton/ShareButton';
import HideSongButton from '../FullSongCard/components/SongActions/components/HideSongButton/HideSongButton';
import { useHideTrack, useUnhideTrack } from '../../../api/firebase/user/hiddenTracks';
import ViewLoopsButton from '../FullSongCard/components/SongActions/components/ViewLoopsButton/ViewLoopsButton';
import { formatSeconds } from '../../../utils/time';
import DownloadMIDIButton from '../FullSongCard/components/SongActions/components/DownloadMIDIButton/DownloadMIDIButton';

const Container = styled('div')`
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #dad8d3;

  &:hover {
    box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    background: #dad8d3;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const LeftWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${LARGE_DESKTOP_BREAKPOINT} {
    min-width: 300px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 1;
    flex: 1;
    margin-right: 5px;
  }
`;

const MiddleWrapper = styled('div')`
  margin-right: 25px;
  margin-left: 25px;
  height: 60px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    width: 40%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px 20px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 4;
    width: 100%;
    margin: 0;
    height: 20px;
    margin-top: 10px;
  }
`;

const PlayWrapper = styled('div')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    margin: 0 20px;
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
  }
`;

const Info = styled('div')`
  width: 200px;

  ${MEDIA_BREAKPOINTS.max1000} {
    width: 500px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    width: 450px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 350px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 140px;
  }
`;

const ControlsWrapper = styled('div')`
  display: flex;
  margin-left: auto;

  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
    justify-content: flex-end;
    min-width: 120px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 3;
  }
`;

const DetailsWrapper = styled('div')`
  display: flex;

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
    padding-top: 15px;
  }
`;

const DetailsContainer = styled('div')``;

const DetailValue = styled('div')`
  padding-right: 10px;
`;

const Control = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const SongTypeWrapper = styled('div')`
  margin-left: -3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SongSubtitle = styled('button')`
  ${buttonResetCss};
  ${monoFontCss};
  ${interactiveTextButtonCss};
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 2px;
  color: ${transparentize(0.4, darkColor)};
  text-transform: uppercase;

  &.nonInteractive {
    text-decoration: none;
    cursor: default;
  }
`;

const dimmedSectionCss = css`
  opacity: 0.5;
`;

const disabledSongName = css`
  &:hover {
    text-decoration: none;
    cursor: default;
  }
`;

const similarSongsButtonCss = css`
  ${MEDIA_BREAKPOINTS.max1260} {
    display: none;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
    display: block;
  }
`;

const detailsContainer1Css = css`
  margin: auto 5px;
  width: 65px;

  ${MEDIA_BREAKPOINTS.max1180} {
    display: none;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: block;
  }
`;

const detailsContainer2Css = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  width: 70%;

  ${MEDIA_BREAKPOINTS.max1720} {
    width: 55%;
  }

  ${MEDIA_BREAKPOINTS.max1440} {
    display: none;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: flex;
    width: 100%;
  }
`;

const hiddenMidiDownload = css`
  display: none;
`;

type Props = {
  song: AlgoliaSongMdl,
  onExpand: () => void,
  neverExpanded?: boolean,
};

const CondensedSongCard = ({ song, onExpand, neverExpanded = false }: Props) => {
  const { progress } = useAudioPlaybackContext();
  const { playingSongID } = useAudioPlayerContext();

  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(song);
  const [handleHideTrack, hideTrackBusy] = useHideTrack(song);
  const [handleUnhideTrack, unhideTrackBusy] = useUnhideTrack(song);

  const downloadedContext = useDownloadedSongsContext() ? useDownloadedSongsContext() : {};
  const {
    location = '',
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const showSignUp = useShowSignUp();
  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };
  const navigate = useNavigate();
  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const freeTierRestrictionToast = useFreeTierRestrictionToast('parts', toastUtilities);

  const handleDisabledClick = () => {
    freeTierRestrictionToast();
  };

  const isPackPage = location === 'pack';
  const shareSlug = getSongShareSlug(song);

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    if (isPackPage) return;
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));
  };

  const previousDownloadSongs = get(downloadedContext, 'downloadedSongIDs', []);
  const previousDownloadMidis = get(downloadedContext, 'downloadedMidis', []);
  const previouslyDownloadedSong = previousDownloadSongs.includes(getSongID(song));
  const previouslyDownloadedMidi = previousDownloadMidis.includes(getSongID(song));

  const userHiddenSongs = useUserHiddenTracks();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const downloadButton = (() => {
    if (USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return (
        <DownloadButton
          condensed
          onClick={() => downloadSong()}
          previouslyDownloaded={previouslyDownloadedSong}
          busy={downloadBusy}
        />
      );
    }
    return <DisabledDownloadButton onClick={handleDisabledClick} />;
  })();

  const midiDownloadButton = (() => {
    return (
      <DownloadMIDIButton
        condensed
        onClick={() => downloadSong('MIDI')}
        previouslyDownloaded={previouslyDownloadedMidi}
        busy={downloadMidiBusy}
      />
    );
  })();

  const songActive = getSongID(song) === playingSongID;
  const songProgress = songActive ? progress : getSongDurationFormatted(song);

  const songArtwork = getSongArtwork(song);
  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  const loopsAvailable = getSongMicroparts(song).length > 0;

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  const subTitle = isSongStem(song) ? getSongMixType(song).replace('and', '&') : '';
  const songTime = songActive
    ? `${formatSeconds(songProgress)}`
    : `${getSongDurationFormatted(song)}`;
  const bpm = getSongBPM(song);
  const genre = getSongGenres(song).join(', ');
  const videoTheme = getSongVideoTheme(song).join(', ');
  const energy = getSongEnergy(song).join(', ');
  const mood = getSongMood(song).join(', ');
  const editStyle = getSongEditStyle(song).join(', ');

  const disableMidi = song.audio.downloadMidi === undefined;

  return (
    <Container>
      <LeftWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <PlayWrapper>
          <PlayPauseButton backgroundImage={songArtwork} />
        </PlayWrapper>
        <Info>
          <SongTypeWrapper
            onClick={handleNavigatePack}
            className={cx({ [disabledSongName]: isPackPage })}
          >
            {getSongName(song)}
          </SongTypeWrapper>
          <SongSubtitle className={cx({ nonInteractive: true })}>
            <ArtistName name={artistName} slug={artistslug} />
            {subTitle}
          </SongSubtitle>
        </Info>
      </LeftWrapper>
      <MiddleWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <AudioBars condensed />
      </MiddleWrapper>
      <Control className={cx(similarSongsButtonCss)}>
        <SimilarSongsButton />
      </Control>
      <DetailsWrapper>
        <DetailsContainer className={cx(detailsContainer1Css)}>
          <p>{songTime}</p>
          <p>{bpm}BPM</p>
        </DetailsContainer>
        <DetailsContainer className={cx(detailsContainer2Css)}>
          <DetailValue>
            <SearchLink queryText={genre} />
          </DetailValue>
          <DetailValue>
            <SearchLink queryText={videoTheme} />
          </DetailValue>
          <DetailValue>
            <SearchLink queryText={energy} />
          </DetailValue>
          <DetailValue>
            <SearchLink queryText={mood} />
          </DetailValue>
          <DetailValue>
            <SearchLink queryText={editStyle} />
          </DetailValue>
        </DetailsContainer>
      </DetailsWrapper>
      <ControlsWrapper>
        <Control className={cx({ [hiddenMidiDownload]: disableMidi })}>
          {midiDownloadButton}
        </Control>
        <Control
          className={cx({
            [dimmedSectionCss]: songIsHidden,
          })}
        >
          <BookmarkButton condensed />
        </Control>
        <Control
          className={cx({
            [dimmedSectionCss]: songIsHidden,
          })}
        >
          {downloadButton}
        </Control>
        <Control>
          <ViewPackButton />
        </Control>
        {loopsAvailable && (
          <Control>
            <ViewLoopsButton />
          </Control>
        )}
        <Control>
          <ShareButton />
        </Control>
        <Control>
          <HideSongButton
            isHidden={songIsHidden}
            onClick={handleToggleHidden}
            busy={hideTrackBusy || unhideTrackBusy}
          />
        </Control>
      </ControlsWrapper>
    </Container>
  );
};

export default CondensedSongCard;
