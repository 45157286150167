// @flow
import React from 'react';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';
import ResetPassword from './components/ResetPassword/ResetPassword';
import PageComponent from '../../../../components/PageComponent/PageComponent';

type Props = {
  uri: string,
};

const ProfileView = ({ uri }: Props) => {
  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer responsiveFullWidth>
        <ProfileDetails />
        <ResetPassword />
      </CenteredMediumContainer>
    </PageComponent>
  );
};

export default ProfileView;
