// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import {
  monoNavCss,
  regularFontWeightCss,
} from '../../../../../../../../../styles/typography/typography';
import { lightColor } from '../../../../../../../../../styles/config/colors';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import DownArrowIcon from '../../../../../../../../../assets/inline-assets/down-arrow-icon.svg';
import { interactiveButtonCss } from '../../../../../../../../../components/Button/Button';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { padNumber } from '../../../../../../../../../utils/numbers';

const textCss = css`
  ${monoNavCss};
  ${regularFontWeightCss};
`;

export const Container = styled('div')`
  ${textCss};
  display: flex;
  ${SMALL_DEVICE_BREAKPOINT} {
    position: relative;
    top: 10px;
  }
`;

export const SelectWrapper = styled('div')`
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    right: 6px;
    display: block;
    pointer-events: none;
    opacity: 0.4;
  }
`;

const color = `#27292d`;

export const Select = styled('select')`
  ${interactiveButtonCss};
  font: inherit;
  color: inherit;
  ${textCss};
  margin: 0;
  border: 0;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align: left;
  //padding: 0 7px 0 6px;
  padding: 0;
  background-color: ${color};
  border-right: 2px solid ${color};
  border-left: 7px solid ${color};
  min-width: 60px;
  box-sizing: border-box;
`;

const Total = styled('div')`
  &::before {
    content: '';
    width: 20px;
    display: inline-block;
    border-top: 1px solid ${lightColor};
    margin: 0 9px;
    position: relative;
    top: -4px;
  }
`;

type Props = {};

const ResultsMainPagination = () => {
  const { filteredSongsCount, selectedSongIndex, goToSongAtIndex } = useResultsManagerContext();
  const handleChange = (event: any) => {
    goToSongAtIndex(parseInt(event.target.value, 10));
  };
  return (
    <Container>
      <SelectWrapper>
        <Select value={selectedSongIndex} onChange={handleChange}>
          {Array.from({ length: filteredSongsCount }).map((item, index) => (
            <option value={index} key={index.toString()}>
              {padNumber(index + 1)}
            </option>
          ))}
        </Select>
        <DownArrowIcon />
      </SelectWrapper>
      <Total>{padNumber(filteredSongsCount)}</Total>
    </Container>
  );
};

export default ResultsMainPagination;
