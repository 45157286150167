// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import Modal, { ModalContainer, wideModalCss, wideModalOverlayCss } from '../Modal/Modal';
import { useLocale } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { fetchDirectSongAndFamily } from '../../../api/algolia/direct';
import {
  getSongID,
  getSongMixType,
  getSongName,
  getSongShareSlug,
  isSongFullMix,
} from '../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import AudioPlayerWrapper from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import AudioCardWrapper from '../../../song/components/AudioCardWrapper/AudioCardWrapper';
import LoopsModalsHeaderSong from './components/LoopsModalHeaderSong/LoopsModalHeaderSong';
import DownloadContextWrapper from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import Loops from './components/Loops/Loops';
import { monoNavCss } from '../../../styles/typography/typography';
import { darkColor, lightColor } from '../../../styles/config/colors';
import { PLAYER_TYPES } from '../../../song/data';
import LoadingDisplay from '../../../components/LoadingDisplay/LoadingDisplay';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { isMobileDeviceSize } from '../../../utils/device';

type Props = {
  shareSlug: string,
  contextData: any,
  pauseGlobalPlayback: () => void,
  onClose: () => void,
};

const Content = styled('div')`
  height: 100%;
  max-height: 700px;
  width: 800px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 70vh;
    max-height: 600px;
    width: 700px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 90vw;
    max-width: 600px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    height: -webkit-fill-available;
    max-height: unset;
  }
`;

const Header = styled('div')`
  width 100%;
  height: 180px;
  background-color: rgba(240,240,235,0.03);

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding-top: 20px;
    height: 200px;
  }
`;

const Body = styled('div')`
  width 100%;
`;

const TabNavigation = styled('ul')`
  display: flex;
  justify-content: center;
  margin: 19px 0px 16px 0px;

  ${SMALL_DEVICE_BREAKPOINT} {
    overflow-x: scroll;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 15px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: grey;
    }
  }
`;

const Tab = styled('li')`
  ${monoNavCss};
  text-transform: uppercase;
  margin: 0px 15px;
  font-size: 11px;
  color: ${transparentize(0.5, lightColor)};
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    white-space: nowrap;
    width: fit-content;
  }
`;

const LoopsContainer = styled('div')`
  padding: 10px 0px 10px 0px;
  overflow-y: scroll;
  height: 100%;
  max-height: 450px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    height: 64vh;
    max-height: unset;
  }
`;

const widerCssModal = css`
  ${wideModalCss};
  max-width: 800px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: 700px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: 600px;
    width: 90vw;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    min-height: 100%;
    border-radius: 5px;
  }
`;

const activeTab = css`
  color: ${darkColor};
`;

const LoadingContainer = styled('div')`
  width: 100%;
  height: 250px;
  margin: auto;
`;

const LoopsModal = ({ shareSlug, contextData, pauseGlobalPlayback, onClose }: Props) => {
  const [songs, setSongs] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [loading, setLoading] = useState(false);
  const locale = useLocale();

  useEffect(() => {
    setLoading(true);
    fetchDirectSongAndFamily(locale, shareSlug)
      .then(response => {
        setSongs(response);
        const songIndex = response.findIndex((song: AlgoliaSongMdl) => {
          return getSongShareSlug(song) === shareSlug;
        });
        setSelectedIndex(songIndex);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shareSlug]);

  const handleChangeSong = (newIndex: number) => {
    setSelectedIndex(newIndex);
  };

  const fullMixTitle =
    selectedIndex !== undefined
      ? getSongName(
          songs.filter(song => {
            return isSongFullMix(song);
          })[0]
        )
      : '';
  return (
    <DownloadContextWrapper {...contextData}>
      <Modal
        className={widerCssModal}
        overlayClassName={wideModalOverlayCss}
        onClose={onClose}
        fullSized={isMobileDeviceSize()}
      >
        <ModalContainer fullSized={isMobileDeviceSize()}>
          <AudioPlayerWrapper>
            <Content>
              <Header>
                {selectedIndex !== undefined ? (
                  <>
                    {songs.map((song, index) => {
                      return (
                        <AudioCardWrapper
                          autoload
                          song={song}
                          mainAudioCard={false}
                          playerType={PLAYER_TYPES.loops_main}
                          pauseGlobalPlayback={() => {
                            pauseGlobalPlayback();
                          }}
                        >
                          <LoopsModalsHeaderSong
                            closeModal={onClose}
                            songTitle={fullMixTitle}
                            activeSong={selectedIndex === index}
                          />
                        </AudioCardWrapper>
                      );
                    })}
                  </>
                ) : null}
              </Header>
              <Body>
                {selectedIndex !== undefined ? (
                  <>
                    <TabNavigation>
                      {songs.map((song, index) => {
                        return (
                          <Tab
                            onClick={() => {
                              handleChangeSong(index);
                            }}
                            className={cx({ [activeTab]: selectedIndex === index })}
                          >
                            {getSongMixType(song)}
                          </Tab>
                        );
                      })}
                    </TabNavigation>
                    <AudioCardWrapper
                      autoload
                      song={songs[selectedIndex]}
                      key={getSongID(songs[selectedIndex])}
                      mainAudioCard={false}
                      playerType={PLAYER_TYPES.loops_loop}
                      pauseGlobalPlayback={() => {
                        pauseGlobalPlayback();
                      }}
                    >
                      <LoopsContainer>
                        <Loops songTitle={fullMixTitle} />
                      </LoopsContainer>
                    </AudioCardWrapper>
                  </>
                ) : (
                  <LoadingContainer>
                    <LoadingDisplay />
                  </LoadingContainer>
                )}
              </Body>
            </Content>
          </AudioPlayerWrapper>
        </ModalContainer>
      </Modal>
    </DownloadContextWrapper>
  );
};

export default LoopsModal;
