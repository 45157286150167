// @flow
import React from 'react';
import { keyframes } from 'emotion';
import styled from 'react-emotion';
import { lightColor } from '../../../../styles/config/colors';

const rotatingKeyframes = keyframes`
    0% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(-135deg);
    }
`;

const LoadingBlock = styled('div')`
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  position: relative;
  overflow: hidden;
  animation: ${rotatingKeyframes} 1800ms cubic-bezier(0, 0, 0.26, 0.87) infinite;
  border: 1px solid ${lightColor};
  background-color: #25272c;
  display: flex;
  justify-content: center;
`;

const growKeyframes = keyframes`
    0%, 5% {
      transform: translateY(100%);
      opacity: 1;
    }
    25% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
        opacity: 1;
    }
    80%,
    100% {
      transform: translateY(0);
      opacity: 0;
    }
`;

const coloursKeyframes = keyframes`
    100%,
    0%,
   19%{
        background-color: #01a29a;
    }
    20%, 39%  {
        background-color: #d9356d;
    }
    40%, 59% {
        background-color: #7891e0;
    }
    60%, 79% {
        background-color: #F0F0EB;
    }
    80%, 99% {
        background-color: #f4c730;
    }
`;

const Grower = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #01a29a;
  animation: ${growKeyframes} 1800ms cubic-bezier(0.4, 0, 1, 1) infinite,
    ${coloursKeyframes} ${1800 * 5}ms infinite;
`;

type Props = {};

const SmallLoadingAnimation = () => (
  <LoadingBlock>
    <Grower />
  </LoadingBlock>
);

export default SmallLoadingAnimation;
