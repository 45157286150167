// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../utils/subscriptions';

export type PricingPlanMdl = {
  uid: string,
  data: {
    name: {
      text: string,
    },
    brief_description: {
      text: string,
    },
    raw_price: number,
    summary_subtitle: {
      text: string,
    },
    summary_term: {
      text: string,
    },
    dynamic_subtitle: {
      text: string,
    },
    subtitle: {
      text: string,
    },
    price: {
      text: string,
    },
    price_label: {
      text: string,
    },
    description: {
      html: string,
    },
    plan_exclusions: {
      html: string,
    },
    requires_payment_details: 'true' | 'false',
    music_license_link: {
      html: string,
    },
    free_trial_banner: {
      text: string,
    },
    monthly_price: {
      text: string,
    },
    monthly_price_subtitle: {
      html: string,
    },
    quarterly_price: {
      text: string,
    },
    quarterly_price_subtitle: {
      html: string,
    },
    biannually_price: {
      text: string,
    },
    biannually_price_subtitle: {
      html: string,
    },
    annually_price: {
      text: string,
    },
    annually_price_subtitle: {
      html: string,
    },
    monthly_billing_subtext: {
      text: string,
    },
    plan_form_link: {
      text: string,
    },
    available_intervals: Array<{
      interval: string,
    }>,
  },
};

export type PricingPlanPricingDetails = {
  monthly: {
    price: string,
    price_subtitle: string,
  },
  quarterly: {
    price: string,
    price_subtitle: string,
  },
  biannual: {
    price: string,
    price_subtitle: string,
  },
  annual: {
    price: string,
    price_subtitle: string,
  },
};

export const getPricingPlanRawPrice = (plan: PricingPlanMdl): number => {
  return get(plan, 'data.raw_price', '') || '';
};

export const getPricingPlanBriefDescription = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.brief_description.text', '') || '';
};

export const getPricingPlanSummarySubtitle = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.summary_subtitle.text', '') || '';
};

export const getPricingPlanSummaryTerm = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.summary_term.text', '') || '';
};

export const getPricingPlanRequiresPaymentDetails = (plan: PricingPlanMdl): boolean => {
  const value = get(plan, 'data.requires_payment_details', false);
  return value === 'true';
};

export const getPricingPlanDynamicSubtitle = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.dynamic_subtitle.text', '') || '';
};

export const getPricingPlanSubtitle = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.subtitle.text', '') || '';
};

export const getPricingPlanName = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.name.text', '') || '';
};

export const getPricingPlanPrice = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.price.text', '') || '';
};

export const getPricingPlanPriceLabel = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.price_label.text', '') || '';
};

export const getPricingPlanDescription = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.description.html', '') || '';
};

export const getPricingPlanExclusions = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.plan_exclusions.html', '') || '';
};

export const getPricingPlanConfirmationDescription = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.confirmation_description.html', '') || '';
};

export const getPricingPlanMonthlyBillingSubtext = (plan: PricingPlanMdl | null): string => {
  return get(plan, 'data.monthly_billing_subtext.text', '') || '';
};

export const getPricingPlanAnnuallyPriceSubtext = (plan: PricingPlanMdl | null): string => {
  return get(plan, 'data.annually_price_subtitle.text', '') || '';
};

export const getPricingPlan = (plans: Array<PricingPlanMdl>, uid: string): PricingPlanMdl => {
  if (isCreatorSubscription(uid)) {
    return plans.find(plan => {
      return plan.uid === 'free1';
    });
  }

  if (isCreatorProSubscription(uid)) {
    return plans.find(plan => {
      return plan.uid === 'pro1';
    });
  }

  if (isBusinessSubscription(uid)) {
    return plans.find(plan => {
      return plan.uid === 'business1';
    });
  }

  if (isEnterpriseSubscription(uid)) {
    return plans.find(plan => {
      return plan.uid === 'enterprise1';
    });
  }

  if (isEnterpriseBasicSubscription(uid)) {
    return plans.find(plan => {
      return plan.uid === 'enterprise2_basic';
    });
  }

  return plans.find(plan => {
    return plan.uid === 'free1';
  });
};

export const getMusicLicenseLink = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.music_license_link.html', '') || '';
};

export const getFreeTrialBanner = (plan: PricingPlanMdl): string => {
  return get(plan, 'data.free_trial_banner.text', '');
};

export const getPricingPlanFormLink = (plan: PricingPlanMdl | null): string => {
  return get(plan, 'data.plan_form_link.text', '') || '';
};

export const getPricingPlanAvailableIntervals = (plan: PricingPlanMdl | null): string[] => {
  const planIntervals = get(plan, 'data.available_intervals', []) || [];
  return planIntervals.map(planInterval => planInterval.interval);
};

export const query = graphql`
  fragment PricingPlan on PrismicPricingPlan {
    uid
    data {
      name {
        text
      }
      brief_description {
        text
      }
      raw_price
      summary_subtitle {
        text
      }
      summary_term {
        text
      }
      dynamic_subtitle {
        text
      }
      subtitle {
        text
      }
      price {
        text
      }
      price_label {
        text
      }
      description {
        html
      }
      plan_exclusions {
        html
      }
      requires_payment_details
      music_license_link {
        html
      }
      free_trial_banner {
        text
      }
      available_intervals {
        interval
      }
      monthly_price {
        text
      }
      monthly_price_subtitle {
        html
      }
      quarterly_price {
        text
      }
      quarterly_price_subtitle {
        html
      }
      biannually_price {
        text
      }
      biannually_price_subtitle {
        html
      }
      annually_price {
        text
      }
      annually_price_subtitle {
        html
      }
      monthly_billing_subtext {
        text
      }
      plan_form_link {
        text
      }
    }
  }
`;
