// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackHideAction, getTrackUnhideAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import HideIcon from '../../../../../../../assets/inline-assets/hide-icon.svg';
import HiddenIcon from '../../../../../../../assets/inline-assets/hidden-icon.svg';

type Props = {
  isHidden?: boolean,
};

const HideSongButton = ({ isHidden = false, ...props }: Props) => {
  const trackCopy = useTrackCopy();
  return (
    <SongActionButton
      icon={isHidden ? <HideIcon /> : <HiddenIcon />}
      label={isHidden ? getTrackUnhideAction(trackCopy) : getTrackHideAction(trackCopy)}
      {...props}
    />
  );
};

export default HideSongButton;
