// @flow
import React from 'react';
import styled from 'react-emotion';
import { SmallHeading } from '../../styles/typography/components/Heading/Heading';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';

const Container = styled('div')``;

const Header = styled('header')`
  display: flex;
  align-items: flex-end;
  margin-bottom: 7px;

  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: center;
  }
`;

const HeadingOption = styled('div')`
  line-height: 20px;
  margin-left: 8px;
`;

type Props = {
  heading: string,
  headingClass?: string,
  headingOption?: any,
  children: any,
};

const CommonSection = ({
  heading,
  className = '',
  headingClass = '',
  headingOption = null,
  children,
}: Props) => (
  <Container className={className}>
    <Header className={headingClass}>
      <SmallHeading>{heading}</SmallHeading>
      {headingOption && <HeadingOption>{headingOption}</HeadingOption>}
    </Header>
    {children && children}
  </Container>
);

export default CommonSection;
