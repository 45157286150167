// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { bodyTextCss, plainTextCss } from '../../typography';

const Container = styled('div')``;

const sharedCss = css`
  ul {
    list-style-type: disc;
    padding-left: 20px;

    > li {
      padding-left: 15px;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;

const defaultClass = css`
  ${sharedCss};
  ${bodyTextCss};

  p {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const smallerClass = css`
  ${sharedCss};
  ${plainTextCss};

  p {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;

export const COPY_STYLE = {
  default: 'default',
  smaller: 'smaller',
};

type Props = {
  children: any,
  copyStyle?: string,
};

const GenericCopy = ({
  className = '',
  children,
  copyStyle = COPY_STYLE.default,
  ...props
}: Props) => (
  <Container
    className={cx(
      {
        [defaultClass]: copyStyle === COPY_STYLE.default,
        [smallerClass]: copyStyle === COPY_STYLE.smaller,
      },
      className
    )}
    {...props}
  >
    {children}
  </Container>
);

export default GenericCopy;

export const SmallerGenericCopy = props => (
  <GenericCopy {...props} copyStyle={COPY_STYLE.smaller} />
);
