// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ErrorScreensGraphMdl = {
  data: {
    generic_error_heading: {
      text: string,
    },
    generic_error_description: {
      html: string,
    },
    auth_error_heading: {
      text: string,
    },
    auth_error_description: {
      html: string,
    },
  },
};

export const getAuthErrorHeading = (copy: ErrorScreensGraphMdl): string => {
  return get(copy, 'data.auth_error_heading.text', '') || '';
};

export const getAuthErrorDescription = (copy: ErrorScreensGraphMdl): string => {
  return get(copy, 'data.auth_error_description.html', '') || '';
};

export const getGenericErrorHeading = (copy: ErrorScreensGraphMdl): string => {
  return get(copy, 'data.generic_error_heading.text', '') || '';
};

export const getGenericErrorDescription = (copy: ErrorScreensGraphMdl): string => {
  return get(copy, 'data.generic_error_description.html', '') || '';
};

export const query = graphql`
  fragment ErrorScreens on PrismicErrorScreens {
    data {
      generic_error_heading {
        text
      }
      generic_error_description {
        html
      }
      auth_error_heading {
        text
      }
      auth_error_description {
        html
      }
    }
  }
`;
