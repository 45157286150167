// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ChromeExtensionGraphMdl = {
  data: {
    download_url: {
      text: string,
    },
    safari_download_url: {
      text: string,
    },
    download_text: {
      text: string,
    },
    footer_download_text: {
      text: string,
    },
    safari_label: {
      text: string,
    },
    chrome_label: {
      text: string,
    },
    extension_modal_title: {
      text: string,
    },
    extension_modal_description: {
      text: string,
    },
    extension_modal_link: {
      text: string,
    },
  },
};

export const getExtensionDownloadUrl = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.download_url.text', '') || '';
};

export const getSafariExtensionDownloadUrl = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.safari_download_url.text', '') || '';
};

export const getExtensionDownloadText = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.download_text.text', '') || '';
};

export const getExtensionFooterDownloadText = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.footer_download_text.text', '') || '';
};

export const getExtensionChromeLabel = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.chrome_label.text', '') || '';
};

export const getExtensionSafariLabel = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.safari_label.text', '') || '';
};

export const getExtensionModalTitle = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.extension_modal_title.text', '') || '';
};

export const getExtensionModalDescription = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.extension_modal_description.text', '') || '';
};

export const getExtensionModalLink = (copy: ChromeExtensionGraphMdl): string => {
  return get(copy, 'data.extension_modal_link.text', '') || '';
};

export const query = graphql`
  fragment ChromeExtension on PrismicChromeExtension {
    data {
      download_url {
        text
      }
      safari_download_url {
        text
      }
      download_text {
        text
      }
      footer_download_text {
        text
      }
      chrome_label {
        text
      }
      safari_label {
        text
      }
      extension_modal_title {
        text
      }
      extension_modal_description {
        text
      }
      extension_modal_link {
        text
      }
    }
  }
`;
