// @flow
import React from 'react';
import styled from 'react-emotion';
import type { CouponMdl } from '../../../../../../api/firebase/user/user';
import { useCouponsArray } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCoupon,
  getCouponDiscountEndsMessage,
  getCouponHideMessageWhenPlanCancelledOrDowngraded,
} from '../../../../../../cms/coupon';
import { getCouponExpirationDate, getCouponId } from '../../../../../../api/firebase/user/user';

const Container = styled('div')`
  opacity: 0.4;
`;

type Props = {
  cancelOrDowngradePending: boolean,
  coupons: Array<CouponMdl>,
};

const FooterCoupon = ({ cancelOrDowngradePending, coupons }: Props) => {
  const cmsCoupons = useCouponsArray();
  return (
    <Container>
      {coupons
        .filter((coupon: CouponMdl) => {
          const couponCopy = getCoupon(cmsCoupons, getCouponId(coupon));
          const hide =
            cancelOrDowngradePending && getCouponHideMessageWhenPlanCancelledOrDowngraded(couponCopy);
          return !hide;
        })
        .map((coupon: CouponMdl) => {
          const couponCopy = getCoupon(cmsCoupons, getCouponId(coupon));
          return getCouponDiscountEndsMessage(couponCopy, getCouponExpirationDate(coupon));
        })
        .join(', ')}
    </Container>
  );
};

export default FooterCoupon;
