// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useSetRecoilState } from 'recoil';
import { getAlgoliaTagsByCategory } from '../../../../../../../api/algolia/search';
import { capitalize } from '../../../../../../../utils/text';
import { groupAlgoliaTags, getGroupColumns } from '../../../../../../../utils/algolia';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import {
  useMiscCopy,
  useMusicPage,
} from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getMusicPageAllTagsListHeading } from '../../../../../../../cms/musicPage';
import {
  getMiscCopyGenres,
  getMiscCopyInstruments,
  getMiscCopyMoods,
  getMiscCopySoundEffects,
} from '../../../../../../../cms/miscCopy';
import type { MiscCopyMdl } from '../../../../../../../cms/miscCopy';
import {
  isLargeMobileDeviceSize,
  isMobileDeviceSize,
  isSmallDeviceSize,
} from '../../../../../../../utils/device';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { searchTriggerAtom } from '../../../../../../../store/mixpanelAnalytics';

type Props = {
  tagCategory: string,
  searchPathParams?: string,
};

const TagListContainer = styled('div')`
  margin: 0px auto;

  & h3 {
    color: #0f0f14;
    font-family: Roboto;
    font-size: 19px;
    letter-spacing: 0.24px;
    line-height: 23px;
    margin-bottom: 20px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    padding: 0px 25px;
  }
`;

const AllTagsContainer = styled('div')`
  display: flex;
  flex-direction: row;

  ${SMALL_DESKTOP_BREAKPOINT} {
    justify-content: space-between;
  }
`;

const TagColumn = styled('div')`
  width: 25%;
  padding-left: 10px;

  &:first-of-type {
    padding-left: 0px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 30%;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 24%;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 50%;
  }
`;

const TagGroup = styled('div')`
  margin-bottom: 35px;
`;

const GroupCategory = styled('h4')`
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TagItem = styled('li')`
  opacity: 0.6;
  margin-bottom: 8px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TAG_CATEGORIES = {
  genre: 'genre',
  mood: 'mood',
  instruments: 'instruments',
  type: 'type',
};

const getTagCategoryLabel = (tagCategory: string, miscCopy: MiscCopyMdl): string => {
  if (tagCategory === TAG_CATEGORIES.mood) {
    return getMiscCopyMoods(miscCopy);
  }
  if (tagCategory === TAG_CATEGORIES.genre) {
    return getMiscCopyGenres(miscCopy);
  }
  if (tagCategory === TAG_CATEGORIES.instruments) {
    return getMiscCopyInstruments(miscCopy);
  }
  if (tagCategory === TAG_CATEGORIES.type) {
    return getMiscCopySoundEffects(miscCopy);
  }
  return '';
};

const getTagColumnQuantity = () => {
  if (isMobileDeviceSize()) {
    return 2;
  }

  if (isSmallDeviceSize()) {
    return 3;
  }

  if (isLargeMobileDeviceSize()) {
    return 3;
  }

  return 4;
};

const TagList = ({ tagCategory, searchPathParams = '' }: Props) => {
  const [tagChunks, setTagChunks] = useState([]);
  const navigate = useNavigate();
  const locale = useLocale();
  const musicPage = useMusicPage();
  const miscCopy = useMiscCopy();
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);

  const handleRetrieveAlgoliaTags = async () => {
    const algoliaTags = await getAlgoliaTagsByCategory(tagCategory, locale);
    return algoliaTags;
  };

  const navigateTag = (tag: string, searchPathParams) => {
    const pathParams = {
      keywords: [tag.toLowerCase()],
    };

    setResultsSearchTriggerLocation('tagSearch');
    const path = searchPathParams
      ? `${ROUTES.musicSearch.navigatePath(pathParams)}${searchPathParams}`
      : ROUTES.musicSearch.navigatePath(pathParams);
    navigate(path);
  };

  useEffect(() => {
    handleRetrieveAlgoliaTags().then(result => {
      const algoliaTags = result.hits;
      const groupedTags = groupAlgoliaTags(algoliaTags);
      if (Object.keys(groupedTags).length === 0) {
        setTagChunks([]);
        return;
      }
      const totalTags = Object.keys(groupedTags)
        .map(groupKey => groupedTags[groupKey].length)
        .reduce((a, b) => a + b);
      const rows = getTagColumnQuantity();
      const columnMax = totalTags / rows;
      const groupColumns = getGroupColumns(groupedTags, columnMax);
      setTagChunks(groupColumns);
    });
  }, []);

  const tagCategoryLabel = getTagCategoryLabel(tagCategory, miscCopy);
  const listTitle = getMusicPageAllTagsListHeading(musicPage).replace(
    '$TAG_CATEGORY$',
    tagCategoryLabel
  );

  return (
    <TagListContainer>
      <h3>{listTitle}</h3>
      {tagChunks.length > 1 && (
        <AllTagsContainer>
          {tagChunks.map((tagChunk, index) => {
            return (
              <TagColumn key={index}>
                {tagChunk.map(group => {
                  return (
                    <TagGroup>
                      {Object.keys(group).map(groupKey => {
                        return (
                          <>
                            <GroupCategory
                              onClick={() => {
                                navigateTag(groupKey, searchPathParams);
                              }}
                            >
                              {groupKey}
                            </GroupCategory>
                            <ul>
                              {group[groupKey].sort().map(tag => {
                                return (
                                  <TagItem
                                    key={tag}
                                    onClick={() => {
                                      navigateTag(tag, searchPathParams);
                                    }}
                                  >
                                    {capitalize(tag)}
                                  </TagItem>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })}
                    </TagGroup>
                  );
                })}
              </TagColumn>
            );
          })}
        </AllTagsContainer>
      )}
    </TagListContainer>
  );
};

export default TagList;
