// @flow
import React from 'react';
import { GIFS } from '../../images/paths';

type Props = {};

const DownloadingIcon = (props: Props) => (
  <img
    src={GIFS.downloading}
    srcSet={`${GIFS.downloading2x} 2x`}
    alt=""
    width="20"
    height="20"
    {...props}
  />
);

export default DownloadingIcon;
