// @flow
import { get } from 'lodash';
import ls from 'local-storage';

export const KEY_AUTH_DESTINATION = 'AUTH_DESTINATION';
export const KEY_TEMPORARY_AUTH_DESTINATION = 'TEMPORARY_AUTH_DESTINATION';
export const KEY_REDIRECTED = 'REDIRECTED';

export const setRedirectedTimestamp = () => {
  ls.set(KEY_REDIRECTED, Date.now());
};

export const clearAuthDestination = () => {
  ls.remove(KEY_AUTH_DESTINATION);
};

export const storeAuthDestination = ({ destination }: { destination: string }) => {
  ls.set(KEY_AUTH_DESTINATION, {
    destination,
  });
};

export const storeCurrentLocationAsAuthDestination = () => {
  const currentLocation = `${window.location.pathname}${window.location.search}`;
  storeAuthDestination({ destination: currentLocation });
};

export const storeTemporaryLoginDestination = () => {
  try {
    ls.set(KEY_TEMPORARY_AUTH_DESTINATION, ls.get(KEY_AUTH_DESTINATION));
  } catch (error) {
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureMessage('Something went wrong when storing temporary login destination');
    Sentry.captureException(error);
    console.error(error);
  }
};

export const getAuthDestination = (): string => {
  const authDestination = ls.get(KEY_AUTH_DESTINATION);
  const destination = get(authDestination, 'destination', '');
  return destination || '';
};

export const getAuthTemporaryDestination = (): string | null => {
  const authDestination = ls.get(KEY_TEMPORARY_AUTH_DESTINATION);
  if (authDestination) {
    const destination = get(authDestination, 'destination', '');
    if (destination) return destination;
  }
  return null;
};
