// @flow
import React from 'react';
import { ErrorLayout } from '../ErrorView/components/GenericError/GenericError';
import { GenericAuthErrorInfo, GenericErrorInfo } from '../ErrorInfo/ErrorInfo';

export const ERROR_PATH_CODES = {
  auth: {
    code: 'auth',
    component: <GenericAuthErrorInfo />,
  },
};

type Props = {
  code: string,
};

const ErrorCodeHandler = ({ code }: Props) => {
  const matchedError = ERROR_PATH_CODES[code];
  const errorComponent = matchedError ? matchedError.component : <GenericErrorInfo />;
  return <ErrorLayout>{errorComponent}</ErrorLayout>;
};

export default ErrorCodeHandler;
