// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type BookmarksPageGraphMdl = {
  data: {
    filter: {
      text: string,
    },
    filter_action: {
      text: string,
    },
    order: {
      text: string,
    },
    order_action: {
      text: string,
    },
    order_action1: {
      text: string,
    },
    order_action2: {
      text: string,
    },
    order_action3: {
      text: string,
    },
    show: {
      text: string,
    },
    clear_keywords: {
      text: string,
    },
    keywords: {
      text: string,
    },
    no_results_heading: {
      text: string,
    },
    no_results_message: {
      html: string,
    },
    search_tips_heading: {
      text: string,
    },
    search_tips_instructions: {
      html: string,
    },
    no_bookmarks_heading: {
      text: string,
    },
    no_bookmarks_message: {
      html: string,
    },
    back_action: {
      text: string,
    },
  },
};

export const getBookmarksNoBookmarksHeading = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.no_bookmarks_heading.text', '') || '';
};

export const getBookmarksNoBookmarksMessage = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.no_bookmarks_message.html', '') || ''; // sometimes graphql returned null, so needed to ensure '' is returned as a fallback
};

export const getBookmarksSearchTipsHeading = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.search_tips_heading.text', '') || '';
};

export const getBookmarksSearchTipsInstructions = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.search_tips_instructions.html', '') || '';
};

export const getBookmarksNoResultsHeading = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.no_results_heading.text', '') || '';
};

export const getBookmarksNoResultsMessage = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.no_results_message.html', '') || '';
};

export const getBookmarksPageKeywords = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.keywords.text', '') || '';
};

export const getBookmarksPageFilter = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.filter.text', '') || '';
};

export const getBookmarksPageFilterAction = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.filter_action.text', '') || '';
};

export const getBookmarksPageOrder = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.order.text', '') || '';
};

export const getBookmarksPageOrderRecent = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.order_action.text', '') || '';
};

export const getBookmarksPageOrderOldest = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.order_action1.text', '') || '';
};

export const getBookmarksPageOrderLongest = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.order_action2.text', '') || '';
};

export const getBookmarksPageOrderShortest = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.order_action3.text', '') || '';
};

export const getBookmarksPageShow = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.show.text', '') || '';
};

export const getBookmarksPageClearKeywords = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.clear_keywords.text', '') || '';
};

export const getBookmarksPageBackAction = (copy: BookmarksPageGraphMdl): string => {
  return get(copy, 'data.back_action.text', '') || '';
};

export const query = graphql`
  fragment BookmarksPage on PrismicBookmarksPage {
    data {
      filter {
        text
      }
      filter_action {
        text
      }
      order {
        text
      }
      order_action {
        text
      }
      order_action1 {
        text
      }
      order_action2 {
        text
      }
      order_action3 {
        text
      }
      show {
        text
      }
      clear_keywords {
        text
      }
      keywords {
        text
      }
      no_results_heading {
        text
      }
      no_results_message {
        html
      }
      search_tips_heading {
        text
      }
      search_tips_instructions {
        html
      }
      no_bookmarks_heading {
        text
      }
      no_bookmarks_message {
        html
      }
      back_action {
        text
      }
    }
  }
`;
