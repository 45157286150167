// @flow
import React from 'react';
import styled from 'react-emotion';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { flatten } from 'lodash';
import IntervalButton from './IntervalButton';
import {
  BILLING_CYCLES,
  getAvailableBillingCycles,
} from '../../../../../../user/subscriptions/data';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../styles/responsive';
import { isMobileDeviceSize } from '../../../../../../utils/device';
import { usePricingPagePlans } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPagePlanAvailableIntervals } from '../../../../../../cms/pricingPagePlan';

type Props = {
  handleIntervalChange: string => void,
  handleChangeNextPreviousInterval: (string, boolean) => void,
  selectedInterval: string,
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 141px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 60px;
    padding: 0px 16px;
  }

  & .menu-item-wrapper:focus {
    outline: none;
  }
`;

// Commented out as it is not in use
// const SwipeContainer = styled('div')`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
// `;

const menuWidthStyle = {
  width: '200%',
};

const IntervalNavigation = ({
  handleIntervalChange,
  selectedInterval,
  handleChangeNextPreviousInterval,
}: Props) => {
  const pricingPlans = usePricingPagePlans();
  const availableIntervals = pricingPlans.map(plan => getPricingPagePlanAvailableIntervals(plan));
  const availableIntervalsSet = new Set(flatten(availableIntervals));
  const uniqueAvailableIntervals = [...availableIntervalsSet];

  const availableBillingCycles = getAvailableBillingCycles(uniqueAvailableIntervals);
  const intervalOptions = Object.keys(availableBillingCycles).map(billingCycle => {
    return (
      <IntervalButton
        key={billingCycle}
        handleIntervalChange={handleIntervalChange}
        isActiveInterval={selectedInterval === BILLING_CYCLES[billingCycle].cycle}
        intervalKey={BILLING_CYCLES[billingCycle].cycle}
      />
    );
  });

  return (
    <>
      {uniqueAvailableIntervals.length > 1 && (
        <Container>
          {isMobileDeviceSize() ? (
            <ScrollMenu
              alignCenter={false}
              innerWrapperStyle={menuWidthStyle}
              data={intervalOptions}
              onTouchStart={event => event.stopPropagation()}
              onTouchEnd={event => event.stopPropagation()}
              onTouchMove={event => event.stopPropagation()}
            />
          ) : (
            <>
              {Object.keys(availableBillingCycles).map(billingCycle => {
                return (
                  <IntervalButton
                    key={billingCycle}
                    handleIntervalChange={handleIntervalChange}
                    isActiveInterval={selectedInterval === BILLING_CYCLES[billingCycle].cycle}
                    intervalKey={BILLING_CYCLES[billingCycle].cycle}
                  />
                );
              })}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default IntervalNavigation;
