// @flow
import React from 'react';
import ErrorView from '../../../errors/components/ErrorView/ErrorView';
import NotFoundError from '../../../errors/components/ErrorView/components/NotFoundError/NotFoundError';
import PageComponent from '../../components/PageComponent/PageComponent';

type Props = {};

const NotFoundScreen = () => {
  return (
    <PageComponent path="/404">
      <ErrorView>
        <NotFoundError />
      </ErrorView>
    </PageComponent>
  );
};

export default NotFoundScreen;
