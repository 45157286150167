// @flow
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { css } from 'emotion';
import { navigate } from '@reach/router';
import { Formik } from 'formik';
import ls from 'local-storage';
import { useQueryParam, StringParam } from 'use-query-params';
import { useRecoilValue } from 'recoil';
import {
  useSignUpEnterprisePage,
  useSignInUpErrors,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCreateAccountButton,
  getCreateAccountFormHeading,
  getEmailLabel,
  getFirstNameLabel,
  getLastNameLabel,
  getPasswordLabel,
  getSignUpAccountInclusions,
  getSignUpAccountInclusionsLabel,
  getTermsAndConditionsLabel,
} from '../../../../../cms/signup';
import {
  hideOnSmallDeviceCss,
  LANDSCAPE_DEVICE,
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import {
  shadedContainerCss,
  shadedContainerShadowCss,
  styledListCss,
} from '../../../../../styles/shared';
import {
  monoNavCss,
  smallMonoCss,
  smallPlainTextCss,
} from '../../../../../styles/typography/typography';
import {
  FormHeading,
  FormInput,
  FormInputContainer,
  FormInputLabel,
  FormSection,
} from '../../../../../components/forms/general';
import { HiddenCheckbox } from '../../../../../components/forms/inputs/SquareCheckbox';
import DisplayErrorMessage from '../../../../../components/DisplayErrorMessage/DisplayErrorMessage';
import Auth0ErrorHandler from '../../../../../components/SignInForm/components/Auth0ErrorHandler/Auth0ErrorHandler';
import Button, { BUTTON_WIDTHS } from '../../../../../components/Button/Button';
import CheckMarkIcon from '../../../../../assets/inline-assets/checkmark-icon.svg';
import { lightColor } from '../../../../../styles/config/colors';
import { useSignUpEnterpriseFormSchema } from './schema';
import { useLoginViaAuth0, useSignUpViaAuth0 } from '../../../../../api/auth/account';
import { useLocale, useLocalePath } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../../routes';
import {
  useIsAuthenticated,
  useAuthContext,
} from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  storeAuthDestination,
  storeTemporaryLoginDestination,
} from '../../../../../auth/destination';
import {
  getInvalidPasswordError,
  getEmptyInputsError,
  getUncheckedTermsOfServiceError,
  getValidEmailRequiredError,
  getEmojiRestrictedError,
} from '../../../../../cms/signInUpErrors';
import { conversionSourceAtom } from '../../../../../store/mixpanelAnalytics';
import { hasEmoji } from '../../../../../utils/emoji';

export const usePlanQuery = () => {
  return useQueryParam('plan', StringParam);
};

export const useBillingQuery = () => {
  return useQueryParam('billing', StringParam);
};

const Container = styled('div')`
  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0 20px;

    ${LANDSCAPE_DEVICE} {
      padding: 0;
    }
  }
`;

const InfoSection = styled('section')`
  ${hideOnSmallDeviceCss};
  ${shadedContainerCss};
  ${shadedContainerShadowCss};
  padding: 24px 30px 30px 30px;

  h4 {
    ${monoNavCss};
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  ul {
    ${smallPlainTextCss};
    ${styledListCss};
  }
`;

const TermsContainer = styled('div')`
  margin: 60px 0;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin: 52px 0;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const TermsLabel = styled('label')`
  ${smallMonoCss};
`;

const TermsBox = styled('div')`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid rgba(240, 240, 235, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input:focus + &,
  &:focus,
  &:hover {
    border: 1px solid rgba(240, 240, 235, 0.2);
  }

  svg {
    display: block;
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};

    path {
      stroke: ${lightColor};
    }
  }
`;

const splitInputsContainerClass = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    > div {
      margin-top: 25px;
    }
  }
`;

const SignUpEnterpriseFormView = () => {
  const [signUpViaAuth0] = useSignUpViaAuth0();
  const [login] = useLoginViaAuth0();
  const [busy, setBusy] = useState(false);
  const signUpEnterprisePage = useSignUpEnterprisePage();
  const schema = useSignUpEnterpriseFormSchema();
  const locale = useLocale();
  const localePath = useLocalePath();
  const isAuthenticated = useIsAuthenticated();
  const { signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [notification, setNotification] = useState(false);
  const errorsCopy = useSignInUpErrors();
  const [preAttachedPlan] = usePlanQuery();
  const [preAttachedBilling] = useBillingQuery();
  const conversionSource = useRecoilValue(conversionSourceAtom);

  const getDestination = (): string => {
    const chosenPlan = preAttachedPlan || '';
    const selectedInterval = preAttachedBilling || '';
    return ROUTES.signupEnterpriseSubscriptions.navigatePath({
      localePath,
      chosenPlan,
      selectedInterval,
    });
  };

  useEffect(() => {
    const isAuthFromSignIn = ls.get('AUTH_FROM_LOGIN');
    const isAuthFromSignUp = ls.get('AUTH_FROM_SIGNUP');

    if (isAuthenticated) {
      if (isAuthFromSignIn) {
        if (!isAuthFromSignUp) {
          navigate(ROUTES.music.navigatePath({ localePath }));
        } else {
          ls.set('AUTH_FROM_SIGNUP', false);
          navigate(getDestination());
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // 原則使用しない。サインアップ時にサブスク登録を行わなかった場合に、プロフィール画面でも
    // エンタープライズ ベーシックを表示できるようにするために、ローカルストレージに保存する。
    ls.set('SIGNUP_ENTERPRISE_BASIC_SUBSCRIPTION', true);

    if (!signUpSourceData.signUpSource) {
      setSignUpSourceData({ signUpSource: 'Direct', signUpCampaign: '' });
    }
    // アンマウント時に削除する。
    return () => ls.remove('SIGNUP_ENTERPRISE_BASIC_SUBSCRIPTION');
  }, []);

  const onSubmit = async (
    { email, password, firstName, lastName },
    { setSubmitting, setErrors }
  ) => {
    if (hasEmoji(firstName) || hasEmoji(lastName)) {
      return;
    }

    if (busy) return;
    setBusy(true);
    setSubmitting(true);

    const handleError = error => {
      console.error(error);
      setBusy(false);
      setSubmitting(false);
      setErrors({
        auth0: error,
      });
    };

    try {
      await signUpViaAuth0(email, password, firstName, lastName, locale);
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when signing up via auth0');
      Sentry.captureException(error);
      handleError(error);
      return;
    }
    try {
      storeTemporaryLoginDestination();
      storeAuthDestination({ destination: getDestination() });
      ls.set('NEW_ACCOUNT_CREATED', true);
      ls.set('NEW_ACCOUNT_DATA', {
        email,
        firstName,
        lastName,
        signUpLocale: locale,
        signUpMethod: 'Email',
        signUpSourceData,
      });
      ls.set('CONVERSION_SOURCE', conversionSource);
      // 問い合わせ中のためコメントアウト
      // ls.set('SHOW_EXTENSION_PROMO', true);
      setSignUpSourceData({ signUpSource: '', signUpCampaign: '' });
      await login(email, password);
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when logging in after signing up');
      Sentry.captureException(error);
      handleError(error);
    }
  };
  return (
    <Container>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          terms: false,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formProps => {
          const {
            values,
            dirty,
            errors,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          const { firstName, lastName, password, email, terms } = values;
          const isEmpty = !firstName || !lastName || !password || !email;
          return (
            <form onSubmit={handleSubmit} novalidate>
              <FormHeading>{getCreateAccountFormHeading(signUpEnterprisePage)}</FormHeading>
              <InfoSection>
                <h4>{getSignUpAccountInclusionsLabel(signUpEnterprisePage)}</h4>
                {parse(getSignUpAccountInclusions(signUpEnterprisePage))}
              </InfoSection>
              {notification && isEmpty && (
                <DisplayErrorMessage>{getEmptyInputsError(errorsCopy)}</DisplayErrorMessage>
              )}
              {notification && !!email && errors.email && (
                <DisplayErrorMessage>{getValidEmailRequiredError(errorsCopy)}</DisplayErrorMessage>
              )}
              {notification && !isEmpty && password && password.length < 8 && (
                <DisplayErrorMessage>{getInvalidPasswordError(errorsCopy)}</DisplayErrorMessage>
              )}
              {notification && (hasEmoji(firstName) || hasEmoji(lastName)) && (
                <DisplayErrorMessage>{getEmojiRestrictedError(errorsCopy)}</DisplayErrorMessage>
              )}
              <FormSection className={splitInputsContainerClass}>
                <FormInputContainer>
                  <FormInput
                    id="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={firstName}
                    type="text"
                    placeholder={getFirstNameLabel(signUpEnterprisePage)}
                  />
                  <FormInputLabel visible={!!firstName}>
                    {getFirstNameLabel(signUpEnterprisePage)}
                  </FormInputLabel>
                </FormInputContainer>
                <FormInputContainer>
                  <FormInput
                    id="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={lastName}
                    type="text"
                    placeholder={getLastNameLabel(signUpEnterprisePage)}
                  />
                  <FormInputLabel visible={!!lastName}>
                    {getLastNameLabel(signUpEnterprisePage)}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={email}
                    type="email"
                    placeholder={getEmailLabel(signUpEnterprisePage)}
                  />
                  <FormInputLabel visible={!!email}>
                    {getEmailLabel(signUpEnterprisePage)}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={password}
                    type="password"
                    placeholder={getPasswordLabel(signUpEnterprisePage)}
                  />
                  <FormInputLabel visible={!!password}>
                    {getPasswordLabel(signUpEnterprisePage)}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <TermsContainer>
                {notification && errors.terms && (
                  <DisplayErrorMessage>
                    {getUncheckedTermsOfServiceError(errorsCopy)}
                  </DisplayErrorMessage>
                )}
                <TermsLabel>
                  <HiddenCheckbox
                    id="terms"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={terms}
                    type="checkbox"
                  />
                  <TermsBox checked={terms}>
                    <CheckMarkIcon />
                  </TermsBox>
                  {parse(getTermsAndConditionsLabel(signUpEnterprisePage))}
                </TermsLabel>
              </TermsContainer>
              {errors && errors.auth0 && (
                <DisplayErrorMessage>
                  <Auth0ErrorHandler error={errors.auth0} />
                </DisplayErrorMessage>
              )}
              <div>
                <Button
                  onClick={() => setNotification(true)}
                  type="submit"
                  loading={busy}
                  mobileWidth={BUTTON_WIDTHS.full}
                >
                  {getCreateAccountButton(signUpEnterprisePage)}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SignUpEnterpriseFormView;
