// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import {
  getCuratedCollectionId,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../../../../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';
import { searchAlgolia } from '../../../../../../api/algolia/search';
import { useLocale } from '../../../../../components/LocaleWrapper/LocaleWrapper';
import { lightColor } from '../../../../../../styles/config/colors';
import { ROUTES } from '../../../../../routes';
import { useNavigate } from '../../../../../hooks';
import type { AlgoliaSearchResults } from '../../../../../../api/algolia/search';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { createSongIDFilterQuery } from '../../../../../../utils/algolia';
import SongList from '../../../../../../layouts/components/SongList/SongList';

type Props = {
  collection: CuratedCollectionGraphMdl,
};

const ExpandedCollection = ({ collection }: Props) => {
  const [expandedSongs, setExpandedSongs] = useState([]);
  const locale = useLocale();
  const navigate = useNavigate();
  const { handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();

  useEffect(() => {
    const songIDs = getCuratedCollectionSongs(collection);

    const searchFilter = createSongIDFilterQuery(songIDs);

    if (songIDs.length > 0) {
      searchAlgolia('', locale, [], searchFilter).then((response: AlgoliaSearchResults) => {
        setExpandedSongs(response.hits);
      });
    }
  }, []);

  const handleNavigatePlaylist = () => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'expandedCollections', '');
    navigate(
      ROUTES.collection.navigatePath({ collectionSlug: getCuratedCollectionId(collection) })
    );
  };

  return (
    <SongList
      title={getCuratedCollectionTitle(collection)}
      songs={expandedSongs}
      onViewMore={() => handleNavigatePlaylist()}
      showAll={false}
      listType="collection"
      listTitle={getCuratedCollectionTitle(collection)}
      listSlug={getCuratedCollectionId(collection)}
      listKeywords={[]}
      sectionOrigin="expandedCollection"
    />
  );
};

export default ExpandedCollection;
