import { atom } from 'recoil';

export const searchTriggerAtom = atom({
  key: 'searchTrigger',
  default: 'results',
});

export const searchEditModeAtom = atom({
  key: 'searchEditMode',
  default: false,
});

export const directTriggerAtom = atom({
  key: 'directTrigger',
  default: 'direct',
});

export const directSearchKeywordsAtom = atom({
  key: 'directSearchKeywords',
  default: [],
});

export const directSearchMixpanelKeywordsAtom = atom({
  key: 'directSearchMixpanelKeywords',
  default: [],
});

export const directSearchMagicKeywordsAtom = atom({
  key: 'directSearchMagicKeywords',
  default: [],
});

export const directSearchYouTubeKeywordsAtom = atom({
  key: 'directSearchYouTubeKeywords',
  default: [],
});

export const conversionSourceAtom = atom({
  key: 'conversionSource',
  default: 'subscriptions',
});
export const colelctionKeywordsSearchedAtom = atom({
  key: 'colelctionKeywordsSearched',
  default: [],
});

export const colelctionMixpanelKeywordsSearchedAtom = atom({
  key: 'colelctionMixpanelKeywordsSearched',
  default: [],
});

export const collectionMagicKeywordsSearchedAtom = atom({
  key: 'collectionMagicKeywordsSearched',
  default: [],
});

export const collectionYoutubeKeywordsSearchedAtom = atom({
  key: 'collectionYoutubeKeywordsSearched',
  default: [],
});

export const collectionYoutubeVideoTitleSearchedAtom = atom({
  key: 'collectionYoutubeVideoTitleSearched',
  default: '',
});

export const collectionSectionOriginAtom = atom({
  key: 'collectionSectionOrigin',
  default: '',
});

export const relatedCollectionSearchedAtom = atom({
  key: 'relatedCollectionSearched',
  default: '',
});
