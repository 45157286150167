// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ArtistProfileGraphMdl = {
  uid: string,
  type: string,
  data: {
    name: {
      text: string,
    },
    cover_art: {
      url: string,
    },
    cover_art_position: number | null,
    thumbnail: {
      url: string,
    },
    catch_copy: {
      html: string,
      text: string,
    },
    description: {
      html: string,
      text: string,
    },
    whole_body_image: {
      url: string,
    },
    link_to_mv: {
      url: string,
    },
    mushimegane: {
      url: string,
    },
    youtube: {
      link_to_youtube: {
        url: string,
      },
    },
    twitter: {
      link_to_twitter: {
        url: string,
      },
    },
    instagram: {
      link_to_instagram: {
        url: string,
      },
    },
    pokekara: {
      link_to_pokekara: {
        url: string,
      },
    },
    pos_artist: {
      html: string,
      text: string,
    },
  },
};

export const getArtistProfileId = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'uid', '') || '';
};

export const getArtistProfileDocumentType = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'type', '') || '';
};

export const getArtistProfileName = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.name.text', '') || '';
};

export const getArtistProfileCoverArt = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.cover_art.url', '') || '';
};

export const getArtistProfileCoverArtPosition = (collection: ArtistProfileGraphMdl): number => {
  return get(collection, 'data.cover_art_position', 50) || 50;
};

export const getArtistProfileThumbnail = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.thumbnail.url', '') || '';
};

export const getArtistProfileCatchCopy = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.catch_copy.text', '') || '';
};

export const getArtistProfileDescription = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.description.text', '') || '';
};

export const getArtistProfileDescriptionMarkup = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.description.html', '') || '';
};

export const getArtistProfileWholeBodyImage = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.whole_body_image.url', '') || '';
};

export const getlinkToMv = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.link_to_mv.url', '') || '';
};

export const getArtistProfileMushimegane = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.mushimegane.url', '') || '';
};

export const getLinktoYouTube = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.youtube[0].link_to_youtube.url', '') || '';
};

export const getLinktoTwitter = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.twitter[0].link_to_twitter.url', '') || '';
};

export const getLinktoInstagram = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.instagram[0].link_to_instagram.url', '') || '';
};

export const getLinktoPokekara = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.pokekara[0].link_to_pokekara.url', '') || '';
};

export const getPos = (collection: ArtistProfileGraphMdl): string => {
  return get(collection, 'data.pos_artist.text', '') || '';
};

export const getArtistProfile = (
  id: string,
  artists: Array<ArtistProfileGraphMdl>
): ArtistProfileGraphMdl | null => {
  const filteredArtists = artists.filter(artist => getArtistProfileId(artist) === id);

  if (filteredArtists.length > 0) {
    return filteredArtists[0];
  }
  return null;
};

export const query = graphql`
  fragment PosArtistProfile on PrismicPosArtistProfile {
    uid
    type
    data {
      name {
        text
      }
      cover_art {
        url
      }
      thumbnail {
        url
      }
      catch_copy {
        html
        text
      }
      description {
        html
        text
      }
      whole_body_image {
        url
      }
      link_to_mv {
        url
      }
      youtube {
        link_to_youtube {
          url
        }
      }
      mushimegane {
        url
      }
      twitter {
        link_to_twitter {
          url
        }
      }
      instagram {
        link_to_instagram {
          url
        }
      }
      pokekara {
        link_to_pokekara {
          url
        }
      }
      pos_artist {
        html
        text
      }
    }
  }
  fragment ArtistProfile on PrismicArtistProfile {
    uid
    type
    data {
      name {
        text
      }
      cover_art {
        url
      }
      thumbnail {
        url
      }
      description {
        html
        text
      }
    }
  }
`;
