// @flow
import React from 'react';
import { getKeywordsFromSongDetails, getSongID } from '../../../../../../../../api/algolia/song';
import SimilarSongsIcon from '../../../../../../../../assets/inline-assets/similar-songs-icon.svg';
import {
  getTrackFindSimilarAction,
  getTrackNoSimilarSearchLabel,
} from '../../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useNavigate } from '../../../../../../../../routing/hooks';
import { ROUTES } from '../../../../../../../../routing/routes';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';

const SimilarSearchInlineButton = () => {
  const song = useSong();
  const trackCopy = useTrackCopy();
  const navigate = useNavigate();
  const { userRole } = useSubscriptionsContext();
  const { showSimilarSearchRestrictedModal } = useModalsContext();

  const similarityKeywords = getKeywordsFromSongDetails(song);
  const similarSongId = getSongID(song);

  const similaritySearchAvailable = similarityKeywords.length > 0;

  const handleSimilarSearch = () => {
    if (!similaritySearchAvailable) {
      return;
    }

    if (!USER_PERMISSIONS[userRole].similarSearch) {
      showSimilarSearchRestrictedModal();
      return;
    }

    navigate(ROUTES.similarSearch.navigatePath({ songId: similarSongId }));
  };

  const label = similaritySearchAvailable
    ? getTrackFindSimilarAction(trackCopy)
    : getTrackNoSimilarSearchLabel(trackCopy);

  return (
    <InlineButton
      label={label}
      icon={<SimilarSongsIcon />}
      action={handleSimilarSearch}
      disabledButton={!similaritySearchAvailable}
    />
  );
};

export default SimilarSearchInlineButton;
