// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelSimilarSongSearch,
  getSongMixpanelAnalyticsDimensions,
  sendMixpanelPageVisit,
} from '../../../../../analytics/mixpanel';
import { getTracksFromAlgolia, searchSimilarAlgolia } from '../../../../../api/algolia/search';
import {
  getKeywordsFromSongDetails,
  getSimilarBpmRange,
  getSongID,
  getSongBPM,
  isSongSfx,
} from '../../../../../api/algolia/song';
import { useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import LoadingDisplay from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { useLocale } from '../../../../components/LocaleWrapper/LocaleWrapper';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';
import SimilarResultsSongList from './components/SimilarResultsSongList/SimilarResultsSongList';

type Props = {
  path: string,
  songId: string,
};

const LoadingWrapper = styled('div')`
  height: 30vh;
`;

const SimilarSearchView = ({ path, songId }: Props) => {
  const [originSong, setOriginSong] = useState([]);
  const [similarSongResults, setSimilarSongResults] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const locale = useLocale();
  const userId = useUserId();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  useEffect(() => {
    const pageVisitProperties = {};
    if (songId) {
      pageVisitProperties['Origin Song ID'] = songId;
    }
    sendMixpanelPageVisit(mixpanel, moengage, path, pageVisitProperties);
  }, [songId]);

  useEffect(() => {
    setLoaded(false);
    setLoading(true);
    getTracksFromAlgolia([songId], locale).then(response => {
      const originSongResult = response.results[0];

      if (!originSongResult) {
        setOriginSong([]);
      } else {
        setOriginSong([originSongResult]);
      }
    });
  }, [songId]);

  useEffect(() => {
    if (originSong.length === 0) return;

    const { minBpm, maxBpm } = getSimilarBpmRange(originSong[0]);
    const similarityKeywords = getKeywordsFromSongDetails(originSong[0]);
    const similarSong = getSongID(originSong[0]);

    const bpmFilterQuery = isSongSfx(originSong[0]) ? '' : `bpm: ${minBpm} TO ${maxBpm}`;
    const songIdFilterQuery = `NOT objectID: ${similarSong}`;

    const filterQuery = bpmFilterQuery
      ? `${bpmFilterQuery} AND ${songIdFilterQuery}`
      : songIdFilterQuery;

    searchSimilarAlgolia(similarityKeywords.join(', '), locale, [], filterQuery)
      .then(response => {
        const similarSongs = response.hits;
        setSimilarSongResults(similarSongs);
        setSearchKeywords(similarityKeywords);

        analyticsMixpanelSimilarSongSearch(
          mixpanel,
          moengage,
          getSongMixpanelAnalyticsDimensions(originSong[0]),
          similarityKeywords,
          maxBpm,
          minBpm,
          userId
        );
      })
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      });
  }, [originSong]);

  return (
    <BrowseLayout nav={<></>} hideNav wideLayout>
      <>
        {loaded && !loading ? (
          <>
            {originSong.length > 0 && (
              <SimilarResultsSongList
                originSong={originSong[0]}
                similarSongs={similarSongResults}
                similarityKeywords={searchKeywords}
              />
            )}
          </>
        ) : (
          <LoadingWrapper>
            <LoadingDisplay />
          </LoadingWrapper>
        )}
      </>
    </BrowseLayout>
  );
};

export default SimilarSearchView;
