// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import {
  LARGE_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { headerHeight, mobileHeaderHeight } from '../../../../../components/SiteHeader/SiteHeader';
import MusicNav from '../MusicNav/MusicNav';
import { useResultsManagerContext } from '../../views/SearchView/components/ResultsManager/ResultsManager';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import SiteHeaderKeywordSearch from '../../../../../components/SiteHeader/components/SiteNav/components/SiteHeaderKeywordSearch/SiteHeaderKeywordSearch';
import { spacing } from '../../../../../styles/config/spacing';

type Props = {
  children: any,
  nav?: any,
  featured?: any,
  hideNav?: boolean,
  wideLayout?: boolean,
  path?: string,
  songId?: string,
};

export const BrowseViewContainer = styled('div')`
  min-height: 80vh;
  ${LARGE_DEVICE_BREAKPOINT} {
    padding-top: ${76}px;
    margin-bottom: 150px;
    width: 100%;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${18 + mobileHeaderHeight}px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

export const SearchContainer = styled('div')`
  width: 100%;
`;

export const FeaturedContent = styled('div')`
  width: 100%;

  & li.slide {
    background-color: unset !important;
  }
`;

export const BrowseViewMainContainer = styled('div')`
  display: flex;
  padding: 0 50px;
  width: 100%;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 35px;
    flex-direction: column;
    padding: 0px 30px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px;
  }
`;

export const BrowseMain = styled('div')`
  width: 100%;
  padding: 10px 10px;

  &.hideNavLayout {
    &.wideLayoutCss {
      width: 100%;
      margin: 0px auto;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 10px 0px;

    &.hideNavLayout {
      width: 100%;
    }
  }
`;

const bodyPaddingClass = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    padding-top: ${headerHeight * 2.5}px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: ${mobileHeaderHeight}px;

    &.bannerPadding {
      padding-top: ${mobileHeaderHeight + 40}px;
    }
  }
`;

const HeaderSearch = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 ${spacing.medium}px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px 30px;

    &.mobileSearchActivePadding {
      padding: 0px;
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px;
  }
`;

const BrowseLayout = ({
  children,
  nav = <MusicNav />,
  featured = <></>,
  hideNav = false,
  wideLayout = false,
  path = '',
  songId = '',
}: Props) => {
  const { noResults } = useResultsManagerContext()
    ? useResultsManagerContext()
    : { noResults: false };

  const { referralCoupon, showReferralBanner } = useSubscriptionsContext();

  return (
    <PageComponent path={path} songId={songId}>
      <BrowseViewContainer
        className={cx({
          [bodyPaddingClass]: referralCoupon && showReferralBanner,
          bannerPadding: referralCoupon && showReferralBanner,
        })}
      >
        <HeaderSearch>
          <SiteHeaderKeywordSearch />
        </HeaderSearch>
        {nav}
        <FeaturedContent>{featured}</FeaturedContent>
        <BrowseViewMainContainer>
          <BrowseMain
            className={cx({ hideNavLayout: hideNav || noResults, wideLayoutCss: wideLayout })}
          >
            {children}
          </BrowseMain>
        </BrowseViewMainContainer>
      </BrowseViewContainer>
    </PageComponent>
  );
};

export default BrowseLayout;
