// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import { useSetRecoilState } from 'recoil';
import { useAudioPlaybackContext } from '../AudioPlayback/AudioPlayback';
import { useDownloadMicropart } from '../../hooks';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS, USER_ROLES } from '../../../user/subscriptions/data';
import { useFreeTierRestrictionToast } from '../../../toasts/hooks';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { useSong } from '../SongContextWrapper/SongContextWrapper';
import DownloadButton from '../FullSongCard/components/SongActions/components/DownloadButton/DownloadButton';
import DisabledDownloadButton from '../FullSongCard/components/SongActions/components/DisabledDownloadButton/DisabledDownloadButton';
import { RawPlayPauseButton } from '../FullSongCard/components/SongActions/components/PlayPauseButton/PlayPauseButton';
import MicropartBars from '../FullSongCard/components/MobileMicroparts/components/Micropart/component/MicropartBars/MicropartBars';
import { formatSeconds } from '../../../utils/time';
import {
  getSongArtist,
  getSongArtistSlug,
  getSongMixType,
  isSongFullMix,
} from '../../../api/algolia/song';
import { useAudioPlayerContext } from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import {
  analyticsMixpanelPlayMicropart,
  useMixpanelSongAnalyticsDimensions,
} from '../../../analytics/mixpanel';
import { useDownloadContext } from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  LARGE_DESKTOP_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { darkColor } from '../../../styles/config/colors';
import { buttonResetCss, interactiveTextButtonCss } from '../../../components/Button/Button';
import { monoFontCss } from '../../../styles/typography/typography';
import { conversionSourceAtom } from '../../../store/mixpanelAnalytics';
import ArtistName from '../FullDisplaySongCard/components/ArtistName/ArtistName';

export const ListItem = styled('div')`
  margin-bottom: 5px;
  height: 60px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-bottom: 5px;
    height: 100px;
  }
`;

const Container = styled('div')`
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #dad8d3;

  &:hover {
    box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    flex-wrap: wrap;
    padding: 10px 10px;
  }
`;

const LeftWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${LARGE_DESKTOP_BREAKPOINT} {
    width: 45%;
    min-width: 300px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 1;
    flex: 1;
    margin-right: 5px;
  }
`;

const MiddleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
  height: 50px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    width: 40%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px 20px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 3;
    width: 100%;
    margin: 0;
    height: 20px;
    margin-top: 10px;
  }
`;

const PlayWrapper = styled('div')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    margin: 0 20px;
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
  }
`;

const SongSubtitle = styled('button')`
  ${buttonResetCss};
  ${monoFontCss};
  ${interactiveTextButtonCss};
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 2px;
  color: ${transparentize(0.4, darkColor)};
  text-transform: uppercase;

  &.nonInteractive {
    text-decoration: none;
    cursor: default;
  }
`;

const Info = styled('div')`
  min-width: 110px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    min-width: 200px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    width: 220px;
  }

  ${MEDIA_BREAKPOINTS.max900} {
    width: 250px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    width: 220px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 350px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 230px;
  }
`;

const ControlsWrapper = styled('div')`
  display: flex;

  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
    justify-content: flex-end;
    width: 15%;
    min-width: 120px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
  }
`;

const Control = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const SongTypeWrapper = styled('div')`
  margin-left: -3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const dimmedSectionCss = css`
  opacity: 0.5;
`;

type Props = {
  songTitle: string,
  micropartIndex: number,
  showLoop?: boolean,
};

const CondensedLoopSongCard = ({ songTitle, micropartIndex, showLoop = true }: Props) => {
  const song = useSong();
  const [download, downloadBusy] = useDownloadMicropart(song, micropartIndex);
  const {
    playing,
    microPartIndex: selectedMicroPartIndex,
    onMicropartSelect,
    onPlayToggle,
    microparts,
    duration,
    swappingLoop,
    setSwappingLoop,
  } = useAudioPlaybackContext();

  const {
    loopsPlaying,
    setAnalyticsSentMicropartPosition,
    setAnalyticsSentMicropartSongId,
  } = useAudioPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const userId = useUserId();

  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const { setSignUpSourceData, isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const isPlaying =
    (playing && selectedMicroPartIndex === micropartIndex) ||
    (swappingLoop && selectedMicroPartIndex === micropartIndex);

  const playMicropartAnalytics = () => {
    analyticsMixpanelPlayMicropart(
      mixpanel,
      moengage,
      micropartIndex,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
    setAnalyticsSentMicropartSongId(mixpanelSongDimensions['Song ID']);
    setAnalyticsSentMicropartPosition(micropartIndex);
  };

  const handleToggle = () => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (selectedMicroPartIndex === micropartIndex) {
      if (!loopsPlaying) {
        setSwappingLoop(true);
        playMicropartAnalytics();
        onMicropartSelect(micropartIndex);
      } else {
        onPlayToggle();
      }
    } else {
      setSwappingLoop(true);
      playMicropartAnalytics();
      onMicropartSelect(micropartIndex);
    }
  };

  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };
  const freeTierRestrictionToast = useFreeTierRestrictionToast('microparts', toastUtilities);

  const handleDisabledClick = () => {
    if (USER_ROLES.noSubscriptions === userRole) {
      setSignUpSourceData({ signUpSource: 'Microparts', signUpCampaign: '' });
      showSignUp();
    } else {
      freeTierRestrictionToast();
    }
  };

  const downloadButton = (() => {
    console.log('userRole: ', userRole);
    if (
      USER_PERMISSIONS[userRole].canDownloadMicroparts ||
      USER_PERMISSIONS[userRole].canDownloadSfx
    ) {
      return <DownloadButton onClick={download} busy={downloadBusy} />;
    }
    return <DisabledDownloadButton onClick={handleDisabledClick} />;
  })();
  const [beginning, end] = microparts[micropartIndex];
  const width = end - beginning;
  const partDuration = width * duration;
  const durationLabel = formatSeconds(partDuration);

  const fullTitle = isSongFullMix(song)
    ? `${songTitle}_${micropartIndex + 1}`
    : `${songTitle}_${getSongMixType(song).toLocaleLowerCase()}_${micropartIndex + 1}`.replace(
        / /g,
        '_'
      );

  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  const songIsHidden = false;

  return showLoop ? (
    <ListItem>
      <Container>
        <LeftWrapper
          className={cx({
            [dimmedSectionCss]: songIsHidden,
          })}
        >
          <PlayWrapper>
            <RawPlayPauseButton playing={isPlaying} onClick={handleToggle} noOutline />
          </PlayWrapper>
          <Info>
            <SongTypeWrapper>{fullTitle}</SongTypeWrapper>
            <SongSubtitle className={cx({ nonInteractive: true })}>
              <ArtistName name={artistName} slug={artistslug} />
              {durationLabel}
            </SongSubtitle>
          </Info>
        </LeftWrapper>
        <MiddleWrapper
          className={cx({
            [dimmedSectionCss]: songIsHidden,
          })}
        >
          <MicropartBars micropartIndex={micropartIndex} alwaysShowMicroparts />
        </MiddleWrapper>
        <ControlsWrapper>
          <Control
            className={cx({
              [dimmedSectionCss]: songIsHidden,
            })}
          >
            {downloadButton}
          </Control>
        </ControlsWrapper>
      </Container>
    </ListItem>
  ) : (
    <></>
  );
};

export default CondensedLoopSongCard;
