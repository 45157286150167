// @flow
import React from 'react';
import ErrorView from '../ErrorView/ErrorView';
import ErrorInfo from '../ErrorInfo/ErrorInfo';
import { ErrorLayout } from '../ErrorView/components/GenericError/GenericError';
import ModalsWrapper from '../../../modals/components/ModalsWrapper/ModalsWrapper';

type Props = {
  children: any,
};

type State = {
  error: any,
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <ModalsWrapper>
          <ErrorView>
            <ErrorLayout>
              <ErrorInfo heading="Something went wrong." />
            </ErrorLayout>
          </ErrorView>
        </ModalsWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
