// @flow
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useSignInFormSchema } from './schema';
import { useLoginViaAuth0 } from '../../../../api/auth/account';

type Props = {
  onSubmit?: any => void,
  onError?: any => void,
  beforeAuth?: () => void,
};

const SignInFormFunctionality = ({ onSubmit, onError, beforeAuth, ...otherProps }: Props) => {
  const schema = useSignInFormSchema();
  const [login, loginBusy] = useLoginViaAuth0();
  const handleSubmit = ({ email, password }, { setSubmitting, setErrors }) => {
    if (loginBusy) return;
    setSubmitting(true);
    if (beforeAuth) {
      beforeAuth();
    }
    login(email, password)
      .then(response => {
        console.log('login response', response);
        setSubmitting(false);
        if (onSubmit) {
          onSubmit(response);
        }
      })
      .catch(error => {
        console.error(error);
        setErrors({
          auth0: error,
        });
        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      {...otherProps}
    />
  );
};

export default SignInFormFunctionality;
