// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import {
  getTrackDownloadMidiAction,
  getTrackReDownloadMidiAction,
} from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import DownloadMIDIIcon from '../../../../../../../assets/inline-assets/download-midi-icon.svg';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';
import DownloadedMIDIIcon from '../../../../../../../assets/inline-assets/downloaded-midi-icon.svg';

type Props = {
  busy?: boolean,
  condensed?: boolean,
  previouslyDownloaded?: boolean,
  topLabel?: boolean,
};

const DownloadMIDIButton = ({
  busy = false,
  condensed = false,
  previouslyDownloaded = false,
  topLabel = false,
  ...props
}: Props) => {
  const trackCopy = useTrackCopy();
  const icon = (() => {
    if (busy) {
      return <DownloadingIcon />;
    }

    if (previouslyDownloaded) {
      return <DownloadedMIDIIcon />;
    }

    return <DownloadMIDIIcon />;
  })();

  const label = (() => {
    if (previouslyDownloaded) {
      return getTrackReDownloadMidiAction(trackCopy);
    }

    return getTrackDownloadMidiAction(trackCopy);
  })();

  return (
    <SongActionButton
      condensed={condensed}
      icon={icon}
      label={label}
      topLabel={topLabel}
      {...props}
    />
  );
};

export default DownloadMIDIButton;
