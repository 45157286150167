// @flow
import React from 'react';
import styled from 'react-emotion';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';

type Props = {
  heading?: string,
  ctaButton?: string,
  headerContent?: string,
};

const Container = styled('div')`
  text-align: center;
  margin: 0px auto;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px 20px;
  }
`;

const Heading = styled('h1')`
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 58px;
  text-align: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 32px;
    letter-spacing: 0.5px;
    line-height: 35px;
  }
`;

const HeaderContent = styled('p')`
  font-size: 15px;
  font-weight: 500;
`;

const ButtonContainer = styled('div')`
  margin-top: 60px;
`;

const PageIntroSection = ({ heading = '', ctaButton = '', headerContent = '' }: Props) => {
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {headerContent && <HeaderContent>{headerContent}</HeaderContent>}
      {ctaButton && <ButtonContainer>{ctaButton}</ButtonContainer>}
    </Container>
  );
};

export default PageIntroSection;
