// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackBookmarkAction, getTrackUnbookmarkAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import BookmarkActiveIcon from '../../../../../../../assets/inline-assets/bookmark-active-icon.svg';
import BookmarkIcon from '../../../../../../../assets/inline-assets/bookmark-icon.svg';
import { useSong } from '../../../../../SongContextWrapper/SongContextWrapper';
import { useBookmark, useRemoveBookmark } from '../../../../../../../api/firebase/user/bookmarks';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';
import DownloadIcon from '../../../../../../../assets/inline-assets/download-icon.svg';
import { useIsSongBookmarked } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { getSongID } from '../../../../../../../api/algolia/song';

type Props = {
  condensed?: boolean,
};

const BookmarkButton = ({ condensed = false }: Props) => {
  const song = useSong();
  const trackCopy = useTrackCopy();
  const icon = <BookmarkIcon />;

  const label = getTrackBookmarkAction(trackCopy);

  /*
  const [bookmark, bookmarkBusy] = useBookmark(song);
  const [removeBookmark, removeBookmarkBusy] = useRemoveBookmark(song);
  const isBookmarked = useIsSongBookmarked(getSongID(song));

  const busy = bookmarkBusy || removeBookmarkBusy;
  const icon = isBookmarked ? <BookmarkActiveIcon /> : <BookmarkIcon />;

  const label = isBookmarked
    ? getTrackUnbookmarkAction(trackCopy)
    : getTrackBookmarkAction(trackCopy);

  const action = () => {
    if (busy) return;
    if (isBookmarked) {
      removeBookmark();
    } else {
      bookmark();
    }
  };
  */

  return (
    <SongActionButton
      condensed={condensed}
      icon={icon}
      label={label}
      //  onClick={action}
    />
  );
};

export default BookmarkButton;
