// @flow
import React from 'react';
import styled from 'react-emotion';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import PageComponent from '../../components/PageComponent/PageComponent';
import PricingView from './components/PricingView/PricingView';
import {
  MEDIA_BREAKPOINTS,
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { headerHeight, mobileHeaderHeight } from '../../../components/SiteHeader/SiteHeader';

export const PricingScreenContainer = styled('div')`
  display: flex;
  flex-direction: column;
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-bottom: 150px;
    width: 100%;
    padding: 0 140px;
    padding-top: ${126 + headerHeight}px;
  }

  ${MEDIA_BREAKPOINTS} {
    padding: 0 10px;
    padding-top: ${126 + headerHeight}px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: 0px;
    margin-top: ${18 + mobileHeaderHeight}px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

const Content = () => (
  <LayoutWrapper>
    <PricingScreenContainer>
      <PricingView />
    </PricingScreenContainer>
  </LayoutWrapper>
);

type Props = {
  path: string,
};

// サブスクリプションページ
const PricingScreen = ({ path }: Props) => {
  // Chrome Extensionの問い合わせ中のためコメントアウト
  // const { showExtensionShowcaseModal } = useModalsContext();

  // useEffect(() => {
  //   const showExtensionModal = ls.get('SHOW_EXTENSION_PROMO');

  //   if (showExtensionModal) {
  //     showExtensionShowcaseModal();
  //   }

  //   ls.remove('SHOW_EXTENSION_PROMO');
  // }, []);

  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default PricingScreen;
