// @flow
import React, { useContext } from 'react';
import {
  getDownloadMicropart,
  getDownloadHistoryObjectId,
  useWatchUserDownloadHistory,
  getDownloadFileType,
} from '../../api/firebase/user/downloads';

export type DownloadedSongsContextState = {
  downloadedSongIDs: Array<string>,
  downloadedMidis: Array<string>,
  downloadsBusy: boolean,
};

export const DownloadedSongsContext = React.createContext<any>();

export const useDownloadedSongsContext = (): DownloadedSongsContextState => {
  return useContext(DownloadedSongsContext);
};

type Props = {
  children: any,
};

const DownloadedSongsManager = ({ children }: Props) => {
  const [downloads, downloadsBusy] = useWatchUserDownloadHistory();
  const downloadedSongIDs = downloads
    .filter(download => {
      const isDownloadMicropart = !!getDownloadMicropart(download);
      return !isDownloadMicropart;
    })
    .filter(download => {
      return getDownloadFileType(download) === 'wav';
    })
    .map(filteredDownload => {
      const songId = getDownloadHistoryObjectId(filteredDownload);
      return songId;
    });

  const downloadedMidis = downloads
    .filter(download => {
      const isDownloadMicropart = !!getDownloadMicropart(download);
      return !isDownloadMicropart;
    })
    .filter(download => {
      return getDownloadFileType(download) === 'midi';
    })
    .map(filteredDownload => {
      const songId = getDownloadHistoryObjectId(filteredDownload);
      return songId;
    });

  return (
    <DownloadedSongsContext.Provider
      value={{
        downloadedSongIDs,
        downloadedMidis,
        downloadsBusy,
      }}
    >
      {children}
    </DownloadedSongsContext.Provider>
  );
};

export default DownloadedSongsManager;
