// @flow

import * as Yup from 'yup';
import { useSignInUpErrors } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getEmailRequiredError, getValidEmailRequiredError } from '../../../cms/signInUpErrors';

export const useForgotPasswordFormSchema = () => {
  const errors = useSignInUpErrors();
  return Yup.object().shape({
    email: Yup.string()
      .email(getValidEmailRequiredError(errors))
      .required(getEmailRequiredError(errors)),
  });
};
