import React, { useState, useRef } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useClickAway } from 'react-use';
import { SMALL_DEVICE_BREAKPOINT, SMALL_DESKTOP_BREAKPOINT } from '../../../../styles/responsive';
import { isLargeMobileDeviceSize } from '../../../../utils/device';
import PosSnsModal from '../../../../modals/components/PosSnsModal/PosSnsModal';
import { useTrackCopy } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { ICONS } from '../../../../images/paths';

type Props = {
  condensed?: boolean,
  dimmedIcon: boolean,
  hidePackActions?: boolean,
  loopsAvailable?: boolean,
};

const BrowseLinkContainer = styled('div')`
  position: relative;
`;

const TooltipMenu = styled('div')`
  position: absolute;
  width: 200px;
  display: none;

  border: solid 1px #dcdad8;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.25);
  z-index: 200;

  & ul {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: auto;
    padding: 10px;
    & li {
      & img {
        width: 20px;
        height: 20px;
        display: flex;
        flex-flow: column;
        margin: 5px auto;
      }
      &: hover {
        cursor: pointer;
        background-color: rgba(15, 15, 20, 0.05);
      }
    }
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    right: 5%;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const showTooltip = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSnsButton = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-size: 15px;
`;

const SnsButton = ({ snsName, snsIcon, snsLink }) => {
  const onClickLink = () => {
    window.open(`${snsLink}`, '_blank');
  };
  return (
    <li>
      <StyledSnsButton onClick={onClickLink}>
        <div>{snsName}</div>
        <div>
          <img src={snsIcon} />
        </div>
      </StyledSnsButton>
    </li>
  );
};

const PosSnsSonotaButton = ({ linktoInstagram, linktoPokekara }) => {
  const [hover, setHover] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const trackPage = useTrackCopy();
  const ref = useRef(null);

  const handleHover = () => {
    if (isLargeMobileDeviceSize()) return;
    setHover(!hover);
  };

  useClickAway(ref, () => {
    setHover(false);
  });

  const resetHoverState = () => {
    setHover(false);
  };

  const handleClick = () => {
    if (!isLargeMobileDeviceSize()) {
      setHover(!hover);
      return;
    } else {
      setShowMobileModal(true);
    }
  };

  const handleCloseModal = () => {
    if (!isLargeMobileDeviceSize()) return;
    setShowMobileModal(false);
  };

  return (
    <div ref={ref}>
      <BrowseLinkContainer onClick={handleClick}>
        <img src={ICONS.sonota} />
        <TooltipMenu
          className={cx({
            [showTooltip]: hover,
          })}
        >
          <ul>
            {linktoInstagram !== '' ? (
              <SnsButton
                snsName={'Instagram'}
                snsIcon={ICONS.instagram}
                snsLink={linktoInstagram}
              />
            ) : (
              ''
            )}
            {linktoPokekara !== '' ? (
              <SnsButton snsName={'pokekara'} snsIcon={ICONS.pokekara} snsLink={linktoPokekara} />
            ) : (
              ''
            )}
          </ul>
        </TooltipMenu>
        {showMobileModal && (
          <PosSnsModal
            onClose={handleCloseModal}
            linktoInstagram={linktoInstagram}
            linktoPokekara={linktoPokekara}
          />
        )}
      </BrowseLinkContainer>
    </div>
  );
};

export default PosSnsSonotaButton;
