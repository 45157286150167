// @flow
import React from 'react';
import styled from 'react-emotion';
import { Link } from '@reach/router';
import { css } from 'emotion';
import { ErrorMessage } from 'formik';
import { MediumContainer } from '../../../../../styles/layout';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  useMiscCopy,
  useSignInPage,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getSignInPagePageHeading, getSignInPageSignUpButton } from '../../../../../cms/signin';
import { useModalsContext } from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import SignInFormMainOptions from '../../../../../components/SignInForm/components/SignInFormMainOptions/SignInFormMainOptions';
import SignInFormFunctionality from '../../../../../components/SignInForm/components/SignInFormFunctionality/SignInFormFunctionality';
import { HideOnLargeDevice, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { useLocalePath } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../../routes';
import { interactiveLinkCss, monoNavCss } from '../../../../../styles/typography/typography';
import { getMiscCopyEmailAddress, getMiscCopyPassword } from '../../../../../cms/miscCopy';
import DisplayErrorMessage from '../../../../../components/DisplayErrorMessage/DisplayErrorMessage';
import Auth0ErrorHandler from '../../../../../components/SignInForm/components/Auth0ErrorHandler/Auth0ErrorHandler';
import {
  FormInput,
  FormInputContainer,
  FormInputLabel,
  FormSection,
} from '../../../../../components/forms/general';

const Header = styled('header')`
  margin-bottom: 58px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 54px;
    display: flex;
    justify-content: space-between;

    a {
      position: relative;
      top: 10px;
    }
  }
`;

const signUpLinkClass = css`
  ${interactiveLinkCss};
  ${monoNavCss};
  text-transform: uppercase;
  text-decoration: none;
`;

const submitSectionClass = css`
  margin-top: 70px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 36px;
  }
`;

type Props = {};

const SignInScreenForm = () => {
  const localePath = useLocalePath();
  const miscCopy = useMiscCopy();
  const signInPage = useSignInPage();
  const { showForgotPasswordModal } = useModalsContext();
  return (
    <MediumContainer responsive>
      <SignInFormFunctionality>
        {formProps => {
          const {
            errors,
            values,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;
          const handleForgot = () => {
            showForgotPasswordModal(values.email);
          };
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Header>
                <LargeHeading>{getSignInPagePageHeading(signInPage)}</LargeHeading>
                <HideOnLargeDevice>
                  <Link className={signUpLinkClass} to={ROUTES.signup.navigatePath({ localePath })}>
                    {getSignInPageSignUpButton(signInPage)}
                  </Link>
                </HideOnLargeDevice>
              </Header>
              <section>
                {errors && errors.auth0 && (
                  <DisplayErrorMessage dataCy="authError">
                    <Auth0ErrorHandler error={errors.auth0} />
                  </DisplayErrorMessage>
                )}
                <ErrorMessage name="email" component={DisplayErrorMessage} />
                <ErrorMessage name="password" component={DisplayErrorMessage} />
              </section>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    placeholder={getMiscCopyEmailAddress(miscCopy)}
                    data-cy="usernameInput"
                  />
                  <FormInputLabel visible={!!values.email}>
                    {getMiscCopyEmailAddress(miscCopy)}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <FormSection>
                <FormInputContainer>
                  <FormInput
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    type="password"
                    placeholder={getMiscCopyPassword(miscCopy)}
                    data-cy="passwordInput"
                  />
                  <FormInputLabel visible={!!values.password}>
                    {getMiscCopyPassword(miscCopy)}
                  </FormInputLabel>
                </FormInputContainer>
              </FormSection>
              <SignInFormMainOptions
                className={submitSectionClass}
                isSubmitting={isSubmitting}
                onForgot={handleForgot}
              />
            </form>
          );
        }}
      </SignInFormFunctionality>
    </MediumContainer>
  );
};

export default SignInScreenForm;
