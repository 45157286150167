/* eslint-disable no-underscore-dangle */
// @flow
import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { ROUTES } from '../../routes';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import BrowseView from './views/BrowseView/BrowseView';
import GenresView from './views/GenresView/GenresView';
import MoodsView from './views/MoodsView/MoodsView';
import InstrumentsView from './views/InstrumentsView/InstrumentsView';
import CollectionsView from './views/CollectionsView/CollectionsView';
import CollectionView from './views/CollectionsView/components/Collection/Collections';
import SearchView from './views/SearchView/SearchView';
import SimilarSearchView from './views/SimilarSearchView/SimilarSearchView';
import PackView from './views/PackView/PackView';
import { useGlobalPlayerContext } from '../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import ArtistView from './views/ArtistView/ArtistView';
import PosArtistView from './views/PosArtistView/PosArtistView';
import SoundEffectsView from './views/SoundEffectsView/SoundEffectsView';

const Content = () => (
  <LayoutWrapper>
    <Router>
      <BrowseView path={ROUTES.music.musicPath} />
      <GenresView path={ROUTES.genres.musicPath} />
      <MoodsView path={ROUTES.moods.musicPath} />
      <InstrumentsView path={ROUTES.instruments.musicPath} />
      <SoundEffectsView path={ROUTES.sfx.musicPath} />
      <CollectionsView path={ROUTES.collections.musicPath} />
      <CollectionView path={ROUTES.collection.musicPath} />
      <SearchView path={ROUTES.musicSearch.musicPath} />
      <SimilarSearchView path={ROUTES.similarSearch.musicPath} />
      <PackView path={ROUTES.musicPack.musicPath} />
      <ArtistView path={ROUTES.artist.musicPath} />
      <PosArtistView path={ROUTES.posartist.musicPath} />
    </Router>
  </LayoutWrapper>
);

// トップページ
const MusicScreen = () => {
  // Chrome Extensionの問い合わせ中のためコメントアウト
  // const { showExtensionShowcaseModal } = useModalsContext();
  const { closeGlobalPlayer } = useGlobalPlayerContext();

  useEffect(() => {
    // const showExtensionModal = ls.get('SHOW_EXTENSION_PROMO');
    // if (showExtensionModal) {
    //   showExtensionShowcaseModal();
    // }
    // ls.remove('SHOW_EXTENSION_PROMO');

    closeGlobalPlayer();
  }, []);

  return <Content />;
};

export default MusicScreen;
