// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { Router } from '@reach/router';
import AuthRequiredWrapper from '../../../auth/components/AuthRequiredWrapper/AuthRequiredWrapper';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import {
  LARGE_DEVICE_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { headerHeight, mobileHeaderHeight } from '../../../components/SiteHeader/SiteHeader';
import ProfileNav from './components/ProfileNav/ProfileNav';
import ProfileView from './views/ProfileView/ProfileView';
import { ROUTES } from '../../routes';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../components/LoadingDisplay/LoadingDisplay';
import { useUserProfileContext } from '../../../user/components/UserProfileWrapper/UserProfileWrapper';
import ProfileSubscriptionsView from './views/ProfileSubscriptionsView/ProfileSubscriptionsView';
import ProfileBillingView from './views/ProfileBillingView/ProfileBillingView';
import ProfileEmailSettingsView from './views/ProfileEmailSettingsView/ProfileEmailSettingsView';
import ProfileDownloadHistoryView from './views/ProfileDownloadHistoryView/ProfileDownloadHistoryView';
import ProfileBookmarksView from './views/ProfileBookmarksView/ProfileBookmarksView';

export const ProfileScreenContainer = styled('div')`
  display: flex;
  padding: 0 30px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0 20px;
    min-height: 100vh;
  }
`;

const Aside = styled('aside')`
  flex: 1;
  min-height: 100vh;

  ${MEDIA_BREAKPOINTS.max1000} {
    display: none;
  }
`;

const MenuWrapper = styled('div')`
  position: fixed;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const ProfileMain = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: ${126 + headerHeight}px;
    margin-bottom: 150px;
    width: 100%;
    padding-left: 220px;
  }

  ${MEDIA_BREAKPOINTS.max1440} {
    padding-left: 180px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    padding-left: 0;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${18 + mobileHeaderHeight}px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

const Content = () => {
  const [loaded, setLoaded] = useState(false);
  const { fetchLatestProfile } = useUserProfileContext();
  useEffect(() => {
    fetchLatestProfile()
      .then(() => {
        setLoaded(true);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureException(error);
        console.error(error);
      });
  }, []);
  return (
    <LayoutWrapper>
      <ProfileScreenContainer>
        <Aside>
          <MenuWrapper>
            <nav>
              <ProfileNav />
            </nav>
          </MenuWrapper>
        </Aside>
        {loaded ? (
          <ProfileMain>
            <Router>
              <ProfileView path={ROUTES.profile.profilePath} />
              <ProfileBillingView path={ROUTES.billing.profilePath} />
              <ProfileEmailSettingsView path={ROUTES.email.profilePath} />
              <ProfileSubscriptionsView path={ROUTES.subscriptions.profilePath} />
              <ProfileDownloadHistoryView path={ROUTES.downloads.profilePath} />
              <ProfileBookmarksView path={ROUTES.bookmarks.profilePath} />
            </Router>
          </ProfileMain>
        ) : (
          <LoadingContainer>
            <LoadingDisplay />
          </LoadingContainer>
        )}
      </ProfileScreenContainer>
    </LayoutWrapper>
  );
};

const ProfileScreen = () => {
  return (
    <AuthRequiredWrapper>
      <Content />
    </AuthRequiredWrapper>
  );
};

export default ProfileScreen;
