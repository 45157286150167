// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import LoopSongCard from '../LoopSongCard/LoopSongCard';
import { getParsedMicroparts } from '../../../../../api/algolia/song';
import { useSong } from '../../../../../song/components/SongContextWrapper/SongContextWrapper';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import CondensedLoopSongCard from '../../../../../song/components/CondensedLoopSongCard/CondensedLoopSongCard';

const Container = styled('div')`
  ${MOBILE_DEVICE_BREAKPOINT} {
    padding-bottom: ${props => (props.pack ? '0px' : '80px')};
  }
`;

const LoopWrapper = styled('div')`
  height: 60px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    height: 110px;
  }
`;

type Props = {
  songTitle: string,
  condensed?: boolean,
  loopStartingPosition?: number,
  visibleLoops?: number,
  loopsFilter: string,
  pack?: boolean,
};

const Loops = ({
  songTitle,
  condensed = false,
  loopStartingPosition = 0,
  visibleLoops = 0,
  loopsFilter,
  pack = false,
}: Props) => {
  const song = useSong();
  const loops = getParsedMicroparts(song);
  return (
    <Container pack={pack}>
      {loops.map((micropart, micropartIndex) => {
        return (
          <React.Fragment key={micropartIndex.toString()}>
            {condensed ? (
              <CondensedLoopSongCard
                key={micropartIndex.toString()}
                micropartIndex={micropartIndex}
                songTitle={songTitle}
                showLoop={loopStartingPosition + micropartIndex < visibleLoops}
              />
            ) : (
              <LoopWrapper key={micropartIndex.toString()}>
                <LoopSongCard
                  micropartIndex={micropartIndex}
                  songTitle={songTitle}
                  inModal={true}
                />{' '}
              </LoopWrapper>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default Loops;
