// @flow
import React from 'react';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import { usePrivacyPolicyPageCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import InfoScreen from '../TermsScreen/components/InfoScreen/InfoScreen';
import {
  getPrivacyPolicyPageBody,
  getPrivacyPolicyPageHeading,
} from '../../../cms/privacyPolicyPage';
import PageComponent from '../../components/PageComponent/PageComponent';

const Content = () => {
  const copy = usePrivacyPolicyPageCopy();
  return (
    <LayoutWrapper bodyPadding>
      <InfoScreen
        heading={getPrivacyPolicyPageHeading(copy)}
        body={getPrivacyPolicyPageBody(copy)}
      />
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

const PrivacyScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default PrivacyScreen;
