// @flow
import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { getFirestoreUserRef, getUserProfile } from './user';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import type { FBUserMdl } from './user';
import { getProfileSavedCollections } from './profile';
import { useGenericApiState } from '../../hooks';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';

export type SavedCollectionMdl = {
  collectionSlug: string,
  timestamp: any,
};

export type SavedCollections = {
  [string]: SavedCollectionMdl,
};

export const addFirestoreUserSavedCollection = (userId: string, collectionSlug: string) => {
  const userRef = getFirestoreUserRef(userId);

  return userRef.update({
    [`profile.savedCollections.${collectionSlug}`]: {
      collectionSlug,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    },
  });
};

export const removeFirestoreUserSavedCollection = (
  userId: string,
  collectionSlug: string
): Promise<any> => {
  const userRef = getFirestoreUserRef(userId);

  return userRef.update({
    [`profile.savedCollections.${collectionSlug}`]: firebase.firestore.FieldValue.delete(),
  });
};

export const useSaveCollection = (collectionSlug: string) => {
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  // const dimensions = useSongAnalyticsDimensions();
  // const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  // const { mixpanel } = useAnalyticsMixpanelContext();
  const showSignUp = useShowSignUp();
  const userId = useUserId();
  // const downloadData = useDownloadContext();
  // const { userRole } = useSubscriptionsContext();

  const saveCollection = () => {
    if (busy) return Promise.reject();
    setBusy(true);
    if (!isAuthenticated) {
      if (signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Saved Collections', signUpCampaign: '' });
      }
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }

    /*
    analyticsMixpanelBookmarkTrack(
      mixpanel,
      mixpanelSongDimensions,
      downloadData,
      userRole,
      userId
    );
    */

    return addFirestoreUserSavedCollection(userId, collectionSlug).finally(() => {
      setBusy(false);
    });
  };

  return [saveCollection, busy];
};

export const useRemoveSavedCollection = (collectionSlug: string) => {
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  // const dimensions = useSongAnalyticsDimensions();
  const showSignUp = useShowSignUp();
  const userId = useUserId();

  const remove = () => {
    if (busy) return Promise.reject();
    setBusy(true);
    if (!isAuthenticated) {
      if (signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Bookmark', signUpCampaign: '' });
      }
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }
    return removeFirestoreUserSavedCollection(userId, collectionSlug).finally(() => {
      setBusy(false);
    });
  };

  return [remove, busy];
};

export const useWatchUserSavedCollections = () => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [savedCollections, setSavedCollections] = useState({});

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getFirestoreUserRef(userId).onSnapshot(data => {
      if (data.exists) {
        const user: FBUserMdl = data.data();
        const profile = getUserProfile(user);
        const updatedSavedCollections = getProfileSavedCollections(profile);
        setSavedCollections(updatedSavedCollections);
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [savedCollections, loaded];
};
