// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionTrialValidUntilDate,
  getSubscriptionPlanKey,
  getSubscriptionChannelId,
  getSubscriptionExtraChannels,
  getSeatInterval,
} from '../../../../api/firebase/user/subscriptions';
import {
  useMiscCopy,
  usePricingPlans,
  useChangeFreeTrialSubscriptionModalCopy,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPlan, getPricingPlanName } from '../../../../cms/pricingPlan';
import Button, { DarkButton } from '../../../../components/Button/Button';
import {
  getChangeFreeTrialSubscriptionModalUpgradeTitle,
  getChangeFreeTrialSubscriptionModalUpgradeInstructions,
  getChangeFreeTrialSubscriptionModalDowngradeTitle,
  getChangeFreeTrialSubscriptionModalDowngradeInstructions,
  getChangeFreeTrialSubscriptionModalIntervalTitle,
  getChangeFreeTrialSubscriptionModalIntervalInstructions,
  getChangeFreeTrialSubscriptionModalCancelTitle,
  getChangeFreeTrialSubscriptionModalCancelInstructions,
  getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyTitle,
  getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyInstructions,
} from '../../../../cms/changeFreeTrialSubscriptionModal';
import {
  getMiscCopyCancelButton,
  getMiscCopyUpgradeButton,
  getMiscCopyDowngradeButton,
  getMiscCopyChange,
  getMiscCopyConfirmButton,
  getMiscCopyCloseButton,
  getMiscCopyContinue,
} from '../../../../cms/miscCopy';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { ACTIONS } from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import { SUBSCRIPTIONS } from '../../data';
import { getLocalisedBillingCycle } from '../DowngradeSubscriptionModal/DowngradeSubscriptionModal';
import { getSubscriptionsPagePromotionalDefaultInterval } from '../../../../cms/subscriptionsPage';

export const SubscriptionModalFooter = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;

    > button {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    flex-direction: column;

    > button {
      &:first-child {
        order: 2;
        margin-top: 10px;
      }

      &:last-child {
        order: 1;
      }
    }
  }
`;

type Props = {
  onClose: () => void,
  subscription: UserSubscriptionMdl,
  actionState: string,
  newPlanKey: string,
  newPlanInterval: string,
};

const ChangeFreeTrialSubscriptionModal = ({
  onClose,
  subscription,
  actionState,
  newPlanKey,
  newPlanInterval,
}: Props) => {
  const [busy, setBusy] = useState(false);
  const miscCopy = useMiscCopy();
  const freeTrialChangeModalCopy = useChangeFreeTrialSubscriptionModalCopy();
  const pricingPlans = usePricingPlans();
  const {
    removeSubscription,
    billingCycle,
    setBillingCycle,
    selectedPlanNumber,
    upgradeSubscription,
    showDowngradeModal,
  } = useSubscriptionsContext();

  const subscriptionsPage = useSubscriptionsPage();
  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const titles = {
    upgrade: getChangeFreeTrialSubscriptionModalUpgradeTitle(freeTrialChangeModalCopy),
    downgrade: getChangeFreeTrialSubscriptionModalDowngradeTitle(freeTrialChangeModalCopy),
    change: getChangeFreeTrialSubscriptionModalIntervalTitle(freeTrialChangeModalCopy),
    cancel: getChangeFreeTrialSubscriptionModalCancelTitle(freeTrialChangeModalCopy),
    upgrade_cancelled_trial: getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyTitle(
      freeTrialChangeModalCopy
    ),
  };

  const instructions = {
    upgrade: getChangeFreeTrialSubscriptionModalUpgradeInstructions(freeTrialChangeModalCopy),
    downgrade: getChangeFreeTrialSubscriptionModalDowngradeInstructions(freeTrialChangeModalCopy),
    change: getChangeFreeTrialSubscriptionModalIntervalInstructions(freeTrialChangeModalCopy),
    cancel: getChangeFreeTrialSubscriptionModalCancelInstructions(freeTrialChangeModalCopy),
    upgrade_cancelled_trial: getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyInstructions(
      freeTrialChangeModalCopy
    ),
  };

  const actions = {
    close: getMiscCopyCancelButton(miscCopy),
    upgrade: getMiscCopyUpgradeButton(miscCopy),
    downgrade: getMiscCopyDowngradeButton(miscCopy),
    change: getMiscCopyChange(miscCopy),
    cancel: getMiscCopyConfirmButton(miscCopy),
    continue: getMiscCopyContinue(miscCopy),
  };

  const plan1Name = getPricingPlanName(
    getPricingPlan(pricingPlans, getSubscriptionPlanKey(subscription))
  );
  const plan2Name = getPricingPlanName(getPricingPlan(pricingPlans, newPlanKey));

  const date = getSubscriptionTrialValidUntilDate(subscription);

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  // Note in all of the below, we use the functionality of upgrading a subscriptions because we need any
  // free trials moving to a paid tier to use the same functionallity on the back end as an upgrade
  // (triggered immediately and open CB checkout)

  const handleCancellation = () => {
    if (busy) return;
    setBusy(true);
    removeSubscription(subscription.key, true)
      .then(() => {
        handleClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when removing subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleUpgrade = () => {
    if (busy) return;
    setBusy(true);
    upgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, newPlanInterval)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when upgrading subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleDowngrade = () => {
    if (busy) return;
    setBusy(true);

    if (
      getSubscriptionExtraChannels(subscription).length > 0 &&
      SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription))
    ) {
      const subscriptionKey = subscription.key;
      showDowngradeModal(subscriptionKey, newPlanKey);
      onClose();
      setBusy(false);
      return;
    }

    upgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, newPlanInterval)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage('Something went wrong when downgrading subscription on free trial');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleChangeInterval = () => {
    if (busy) return;
    setBusy(true);
    upgradeSubscription(
      getSubscriptionChannelId(subscription),
      subscription.seat.plan,
      billingCycle
    )
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        Sentry.captureMessage(
          'Something went wrong when changing subscription interval on free trial'
        );
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleAction = () => {
    if (actionState === ACTIONS.upgrade) {
      handleUpgrade();
      return;
    }
    if (actionState === ACTIONS.downgrade) {
      handleDowngrade();
      return;
    }
    if (actionState === ACTIONS.change) {
      handleChangeInterval();
      return;
    }
    handleCancellation();
  };

  const getPlanTitleText = (): string => {
    if (actionState === ACTIONS.upgrade) {
      return titles.upgrade;
    }
    if (actionState === ACTIONS.downgrade) {
      return titles.downgrade;
    }
    if (actionState === ACTIONS.change) {
      return titles.change;
    }
    if (actionState === ACTIONS.cancel) {
      return titles.cancel;
    }
    return titles.upgrade_cancelled_trial;
  };

  const getPlanInstructionsText = (): string => {
    if (actionState === ACTIONS.upgrade) {
      return instructions.upgrade;
    }
    if (actionState === ACTIONS.downgrade) {
      return instructions.downgrade;
    }
    if (actionState === ACTIONS.change) {
      return instructions.change;
    }
    if (actionState === ACTIONS.cancel) {
      return instructions.cancel;
    }
    return instructions.upgrade_cancelled_trial;
  };

  const getPlanActionText = (): string => {
    if (actionState === ACTIONS.upgrade) {
      return actions.upgrade;
    }
    if (
      actionState === ACTIONS.downgrade &&
      getSubscriptionExtraChannels(subscription).length > 0 &&
      SUBSCRIPTIONS.business.plan_codes.includes(getSubscriptionPlanKey(subscription))
    ) {
      return actions.continue;
    }
    if (actionState === ACTIONS.downgrade) {
      return actions.downgrade;
    }
    if (actionState === ACTIONS.change) {
      return actions.change;
    }
    return actions.cancel;
  };
  const titleText = getPlanTitleText().replace('$PLAN_NUMBER$', `${selectedPlanNumber}`);

  const instructionsText = getPlanInstructionsText()
    .replace('$PLAN1$', plan1Name)
    .replace('$INTERVAL1$', getLocalisedBillingCycle(getSeatInterval(subscription)))
    .replace('$PLAN2$', plan2Name)
    .replace('$INTERVAL2$', getLocalisedBillingCycle(billingCycle))
    .replace('$PLAN2$', plan2Name)
    .replace('$DATE$', date);

  return (
    <ClassicModalLayout onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={titleText}
        description={instructionsText}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>
              {actionState !== ACTIONS.upgrade_cancelled_trial
                ? getMiscCopyCancelButton(miscCopy)
                : getMiscCopyCloseButton(miscCopy)}
            </DarkButton>
            {actionState !== ACTIONS.upgrade_cancelled_trial && (
              <Button loading={busy} onClick={handleAction}>
                {getPlanActionText()}
              </Button>
            )}
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default ChangeFreeTrialSubscriptionModal;
