// @flow
import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { getFirestoreUserData, getUserProfile, getFirestoreUserRef } from './user';
import type { AlgoliaSongMdl } from '../../algolia/song';
import {
  getSongID,
  getSongEditStyle,
  getSongEnergy,
  getSongGenres,
  getSongInstruments,
  getSongLocation,
  getSongMood,
  getSongMovement,
  getSongVideoTheme,
  getSongMixType,
} from '../../algolia/song';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { getProfileHiddenTracks } from './profile';
import { useGenericApiState } from '../../hooks';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import {
  analyticsMixpanelHideTrack,
  getSongMixpanelAnalyticsDimensions,
} from '../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { useDownloadContext } from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import type { FBUserMdl } from './user';

export type HiddenTrackMdl = {
  songId: string,
  'edit-style': Array<string>,
  energy: Array<string>,
  genre: Array<string>,
  instruments: Array<string>,
  location: Array<string>,
  mood: Array<string>,
  movement: Array<string>,
  'video-theme': Array<string>,
  mixType: string,
};

export type HiddenTracks = {
  [string]: HiddenTrackMdl,
};

export const hideTrack = (song: AlgoliaSongMdl, userId: string) => {
  const userRef = getFirestoreUserRef(userId);
  const songId = getSongID(song);
  return userRef.update({
    [`profile.hiddenTracks.${songId}`]: {
      songId: getSongID(song),
      'edit-style': getSongEditStyle(song),
      energy: getSongEnergy(song),
      genre: getSongGenres(song),
      instruments: getSongInstruments(song),
      location: getSongLocation(song),
      mood: getSongMood(song),
      movement: getSongMovement(song),
      'video-theme': getSongVideoTheme(song),
      mixType: getSongMixType(song),
    },
  });
};

export const unhideTrack = (song: AlgoliaSongMdl, userId: string) => {
  const userRef = getFirestoreUserRef(userId);
  const songId = getSongID(song);
  return userRef.update({
    [`profile.hiddenTracks.${songId}`]: firebase.firestore.FieldValue.delete(),
  });
};

export const useHideTrack = (song: AlgoliaSongMdl) => {
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  const showSignUp = useShowSignUp();
  const userId = useUserId();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();

  const handleHideTrack = () => {
    if (busy) return Promise.reject();

    setBusy(true);
    if (!isAuthenticated) {
      if (signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Hide Track', signUpCampaign: '' });
      }
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }

    return getFirestoreUserData(userId)
      .then(() => {
        const mixpanelSongDimensions = getSongMixpanelAnalyticsDimensions(song);
        analyticsMixpanelHideTrack(
          mixpanel,
          moengage,
          mixpanelSongDimensions,
          downloadContext,
          userRole,
          userId
        );

        return hideTrack(song, userId);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return [handleHideTrack, busy];
};

export const useUnhideTrack = (song: AlgoliaSongMdl) => {
  const { isAuthenticated } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  const showSignUp = useShowSignUp();
  const userId = useUserId();

  const handleUnhideTrack = () => {
    if (busy) return Promise.reject();

    setBusy(true);
    if (!isAuthenticated) {
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }

    return getFirestoreUserData(userId)
      .then(() => {
        return unhideTrack(song, userId);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return [handleUnhideTrack, busy];
};

export const useWatchUserHiddenTracks = () => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [hiddenTracks, setHiddenTracks] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getFirestoreUserRef(userId).onSnapshot(data => {
      if (data.exists) {
        const user: FBUserMdl = data.data();
        const profile = getUserProfile(user);
        const updatedHiddenTracks = getProfileHiddenTracks(profile);
        setHiddenTracks(updatedHiddenTracks);
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [hiddenTracks, loaded];
};

export const fetchUserHiddenTracks = (uid: string): Promise<number> => {
  const ref = getFirestoreUserRef(uid);
  return ref.get().then(querySnapshot => {
    const downloadCount = querySnapshot.docs.length;
    return downloadCount;
  });
};
