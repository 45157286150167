// @flow
import React from 'react';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { css } from 'emotion';
import { MediumHeading } from '../../../styles/typography/components/Heading/Heading';
import Button, {
  BUTTON_WIDTHS,
  DarkButton,
  shorterButtonClass,
} from '../../../components/Button/Button';
import MessageModal from '../MessageModal/MessageModal';
import {
  useLeavePageModalCopy,
  useMiscCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getLeaveFilteredResultsPageModalHeading,
  getLeaveFilteredResultsPageModalInstructions,
} from '../../../cms/leavePageModal';
import { SmallerGenericCopy } from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { getMiscCopyRemove, getMiscCopyKeep } from '../../../cms/miscCopy';
import { ROUTES } from '../../../routing/routes';
import { useNavigate } from '../../../routing/hooks';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelNewSearch } from '../../../analytics/mixpanel';
import { useResultsManagerContext } from '../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';

const Container = styled('div')``;

const Options = styled('div')`
  display: flex;
  margin-top: 17px;
`;

const Option = styled('div')`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const buttonClass = css`
  ${shorterButtonClass};
`;

const Description = styled('div')`
  margin-top: 13px;
`;

type Props = {
  newKeywords: Array<string>,
  confirmKeywordRemoval?: {
    setKeywordsQuery: (Array<string>) => void,
    setMagicQuery: (Array<string>) => void,
    handleClearSongFilters: string => void,
    isMagicKeyword: boolean,
    isYouTubeKeyword?: boolean,
    handleRemoveYouTubeKeyword?: () => void,
  },
  onClose: () => void,
};

const LeaveResultFiltersModal = ({
  onClose,
  newKeywords,
  confirmKeywordRemoval = {
    setKeywordsQuery: () => {},
    setMagicQuery: () => {},
    handleClearSongFilters: () => {},
    isMagicKeyword: false,
    isYouTubeKeyword: false,
    handleRemoveYouTubeKeyword: () => {},
  },
}: Props) => {
  const navigate = useNavigate();
  const copy = useLeavePageModalCopy();
  const miscCopy = useMiscCopy();
  // const { mixpanel } = useAnalyticsMixpanelContext();
  const handleRemoveKeyword = () => {
    // analyticsMixpanelNewSearch(mixpanel, 0, 0, 0);
    const {
      setKeywordsQuery,
      setMagicQuery,
      handleClearSongFilters,
      isMagicKeyword,
      isYouTubeKeyword,
      handleRemoveYouTubeKeyword,
    } = confirmKeywordRemoval;
    if (isYouTubeKeyword) {
      handleRemoveYouTubeKeyword();
      handleClearSongFilters('removeKeyword');
    }
    if (isMagicKeyword) {
      setMagicQuery(newKeywords);
      handleClearSongFilters('removeKeyword');
    } else {
      setKeywordsQuery(newKeywords);
      handleClearSongFilters('removeKeyword');
    }
    onClose();
  };
  return (
    <MessageModal onClose={onClose}>
      <Container>
        <div>
          <MediumHeading>{getLeaveFilteredResultsPageModalHeading(copy)}</MediumHeading>
          <Description>
            <SmallerGenericCopy>
              {parse(getLeaveFilteredResultsPageModalInstructions(copy))}
            </SmallerGenericCopy>
          </Description>
        </div>
        <Options>
          <Option>
            <DarkButton width={BUTTON_WIDTHS.small} className={buttonClass} onClick={onClose}>
              {getMiscCopyKeep(miscCopy)}
            </DarkButton>
          </Option>
          <Option>
            <Button
              width={BUTTON_WIDTHS.small}
              className={buttonClass}
              onClick={handleRemoveKeyword}
            >
              {getMiscCopyRemove(miscCopy)}
            </Button>
          </Option>
        </Options>
      </Container>
    </MessageModal>
  );
};

export default LeaveResultFiltersModal;
