// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackViewLoopsAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import MicropartsIcon from '../../../../../../../assets/inline-assets/microparts-icon.svg';
import { useSongContext } from '../../../../../SongContextWrapper/SongContextWrapper';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewMicroparts,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../analytics/mixpanel';
import { useDownloadContext } from '../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { getSongShareSlug } from '../../../../../../../api/algolia/song';

type Props = {
  condensed?: boolean,
};

const ViewLoopsButton = ({ condensed = false }: Props) => {
  const { song } = useSongContext();
  const { showLoopsModal } = useModalsContext();
  const { pauseGlobalPlayback } = useGlobalPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();
  const trackCopy = useTrackCopy();

  const handleOpenLoopsModal = () => {
    const songFamily = getSongShareSlug(song);
    showLoopsModal(songFamily, downloadContext, () => pauseGlobalPlayback());
    analyticsMixpanelViewMicroparts(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  };

  return (
    <SongActionButton
      icon={<MicropartsIcon />}
      label={getTrackViewLoopsAction(trackCopy)}
      onClick={handleOpenLoopsModal}
      condensed={condensed}
    />
  );
};

export default ViewLoopsButton;
