// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import CloseIcon from '../../../../../../../../../assets/inline-assets/close.svg';
import { isSmallDeviceSize } from '../../../../../../../../../utils/device';

type Props = {
  title: string,
  description: string,
  icon: string,
  iconClass?: string,
  handleClick: () => void,
};

const CompoundKeywordWrapper = styled('div')`
  display: flex;
  font-size: 16px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  color: white;
  border: 2px solid #dad8d3;
  border-radius: 3px;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
  width: fit-content;
  &:hover {
    background: rgba(15, 15, 20, 0.05);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    font-size: 15px;
    line-height: 25px;
    padding: 0px 10px 0px 10px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    flex-direction: column;
  }
`;

const KeywordAside = styled('div')`
  border-right: 2px solid #dad8d3;
  padding-right: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;

  & div {
    margin-right: 10px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    justify-content: center;
    border-right: none;
    margin: 3px 0px 0px 0px;
    padding: 0px;
  }
`;

const IconClose = styled('div')`
  transform: scale(0.6, 0.6) translateY(8px) translateX(-5px);
  width: 14px;
  height: 14px;
`;

const IconCloseMobile = styled('div')`
  transform: scale(0.6, 0.6) translateY(10px) translateX(-5px);
  padding-left: 12px;
  width: 7px;
  height: 7px;
  ${SMALL_DESKTOP_BREAKPOINT} {
    transform: scale(0.6, 0.6) translateY(9px) translateX(-5px);
  }
`;

const similarityKeyword = css`
  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 5px 20px;
  }
`;

const similarityIconStyle = css`
  margin-right: 4px !important;
  height: 14px;
  & img {
    transform: scale(0.75, 0.75) translateY(4px) translateX(-7px);
  }
`;

const KeywordDescription = styled('div')`
  display: flex;
  flex-direction: row;
`;

const CompoundKeyword = ({ title, description, icon, iconClass = '', handleClick }: Props) => {
  const [iconState, setIconState] = React.useState(
    <div className={cx({ [similarityIconStyle]: iconClass === 'similar' })}>
      <img src={icon} alt={`${iconClass} icon`}></img>
    </div>
  );
  const mouseEnter = () => {
    if (isSmallDeviceSize()) return;
    setIconState(
      <IconClose>
        <CloseIcon />
      </IconClose>
    );
  };
  const mouseLeave = () => {
    if (isSmallDeviceSize()) return;
    setIconState(
      <div className={cx({ [similarityIconStyle]: iconClass === 'similar' })}>
        <img src={icon} alt={`${iconClass} icon`}></img>
      </div>
    );
  };
  return (
    <CompoundKeywordWrapper
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={handleClick}
      className={cx({ [similarityKeyword]: iconClass === 'similar' })}
    >
      <KeywordAside>
        {iconState}
        <span>{title}</span>
      </KeywordAside>
      <KeywordDescription>
        <span>{description}</span>
        {isSmallDeviceSize() && (
          <IconCloseMobile>
            <CloseIcon />
          </IconCloseMobile>
        )}
      </KeywordDescription>
    </CompoundKeywordWrapper>
  );
};

export default CompoundKeyword;
