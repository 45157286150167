// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type DowngradeSubscriptionGraphMdl = {
  data: {
    modal_title: {
      text: string,
    },
    page_instruction: {
      html: string,
    },
    youtube_instruction: {
      html: string,
    },
    youtube_instructions_free_trial: {
      html: string,
    },
  },
};

export const getDowngradeSubscriptionModalTitle = (copy: DowngradeSubscriptionGraphMdl): string => {
  return get(copy, 'data.modal_title.text', '') || '';
};

export const getDowngradeSubscriptionModalPageInstruction = (
  copy: DowngradeSubscriptionGraphMdl
): string => {
  return get(copy, 'data.page_instruction.html', '') || '';
};

export const getDowngradeSubscriptionModalYoutubeInstructions = (
  copy: DowngradeSubscriptionGraphMdl
): string => {
  return get(copy, 'data.youtube_instruction.html', '') || '';
};

export const getDowngradeSubscriptionModalYoutubeInstructionsFreeTrial = (
  copy: DowngradeSubscriptionGraphMdl
): string => {
  return get(copy, 'data.youtube_instruction_free_trial.html', '') || '';
};

export const query = graphql`
  fragment DowngradeSubscriptionModal on PrismicDowngradeSubscriptionModal {
    data {
      modal_title {
        text
      }
      page_instruction {
        html
      }
      youtube_instruction {
        html
      }
      youtube_instruction_free_trial {
        html
      }
    }
  }
`;
