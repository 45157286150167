// @flow
import React from 'react';
import styled from 'react-emotion';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';

type Props = {
  children: any,
  heading?: string,
};

const Container = styled('div')`
  width: 740px;
  text-align: center;
  margin: 140px auto 100px auto;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    max-width: 600px;
    padding: 0px 0px;
  }
`;

const FaqsList = styled('ul')`
  text-align: left;
`;

export const Heading = styled('h1')`
  margin-bottom: 50px;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 47px;
  text-align: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 43px;
    font-size: 30px;
    letter-spacing: 0.5px;
    line-height: 35px;
  }
`;

const FaqsSection = ({ children, heading = '' }: Props) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      <FaqsList>{children}</FaqsList>
    </Container>
  );
};

export default FaqsSection;
