// @flow
import React from 'react';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import Modal, {
  fullSizeModalCss,
  fullSizeModalOverlayCss,
} from '../../../../modals/components/Modal/Modal';
import { darkColor } from '../../../../styles/config/colors';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
} from '../../../../styles/responsive';
import { ModalCloseButton } from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import CloseIcon from '../../../../assets/inline-assets/close.svg';
import AuthRestricted from '../../../../auth/components/AuthRestricted/AuthRestricted';
import { monoFontCss, smallMonoBoldCss } from '../../../../styles/typography/typography';
import MobileUserProfile from './components/MobileUserProfile/MobileUserProfile';
import { buttonResetCss, DarkButton, LightButton } from '../../../Button/Button';
import {
  useMiscCopy,
  useSiteHeader,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getHeaderSignInButton, getHeaderSignUpButton } from '../../../../cms/header';
import SiteNavLinks from '../../../../routing/components/SiteNavLinks/SiteNavLinks';
import ProfileNavLinks from '../../../../routing/components/ProfileNavLinks/ProfileNavLinks';
import { getMiscCopySignOut } from '../../../../cms/miscCopy';
import { useNavigate } from '../../../../routing/hooks';
import { ROUTES } from '../../../../routing/routes';
import { useAuthContext } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import MobileMenuDownloadCounter from './components/MobileMenuDownloadCounter/MobileMenuDownloadCounter';
import MobileMenuTrialCounter from './components/MobileMenuTrialCounter/MobileMenuTrialCounter';
import {
  useSubscriptionsContext,
  useFreeTrialSubscriptions,
} from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_ROLES } from '../../../../user/subscriptions/data';
import {
  businessRoleCss,
  enterpriseRoleCss,
  guestRoleCss,
  personalRoleCss,
} from '../ProfileMenu/ProfileMenu';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ${MEDIA_BREAKPOINTS.min600} {
    padding: 0px 0px 58px 0px;

    & > div,
    & > section,
    & > nav {
      padding: 0px 40px;
    }
  }

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 0px 0px 40px 0px;

    & > div,
    & > section,
    & > nav {
      padding: 0px 40px;
    }
  }
`;

const closeButtonClass = css`
  position: fixed;
  top: 20px;
  right: 23px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    right: 18px;
  }
`;

const SignedOutOptions = styled('section')`
  margin-top: 30px;
`;

const linkClass = css`
  ${monoFontCss};
  font-size: 18px;
  letter-spacing: 0.9px;
  text-decoration: none;
  display: block;
  padding: 5px 5px;

  &:active {
    text-decoration: underline;
  }
`;

const LinkButton = styled('button')`
  ${buttonResetCss};
  ${linkClass};
`;

const UserTierWrapper = styled('div')`
  height: 56px;
  width: 100%;
  margin-bottom: 21px;
  background-image-position: center;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${smallMonoBoldCss};
  font-size: 15px;
  color: ${darkColor};
`;

const CounterWrapper = styled('div')`
  &:first-of-type {
    border-top: 1px solid ${transparentize(0.9, darkColor)};
  }

  &.downloadCountWrapper,
  &.trialCounterWrapper {
    padding: 0px;
  }
`;

const NavLinks = styled('nav')`
  margin-top: 14px;
  padding-top: 13px;
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    border-top: none;
    margin-top: 18px;
    padding-top: 0px;
  }

  li {
    &:first-of-type {
      border-top: 3px solid ${transparentize(0.9, '#4d4d4d')};
      margin-top: 0px;
      padding-top: 6px;
    }
    margin-top: 6px;
  }

  &.bannerPresent li:first-of-type {
    border-top: none;
  }

  a {
    ${linkClass};
  }
`;

const navButtonClass = css`
  width: 100%;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const mobileMenuBannerLayout = css`
  color: #fff;

  svg g {
    stroke: #fff;
  }
`;

type Props = {
  onClose: () => void,
};

const MobileMenu = ({ onClose }: Props) => {
  const { logout } = useAuthContext();
  const siteHeader = useSiteHeader();
  const miscCopy = useMiscCopy();
  const navigate = useNavigate();
  const { userRole } = useSubscriptionsContext();
  const freeTrialSubscriptions = useFreeTrialSubscriptions();
  const { t } = useTranslation();

  const handleSignIn = () => {
    navigate(ROUTES.signin.navigatePath({}));
    onClose();
  };

  const handleSignUp = () => {
    navigate(ROUTES.signup.navigatePath({}));
    onClose();
  };

  const handleSignOut = () => {
    onClose();
    logout();
  };

  const getPlanName = () => {
    if (userRole === USER_ROLES.creator) {
      return t('general.planCode.member', '');
    }

    if (userRole === USER_ROLES.creatorPro) {
      return t('general.planCode.loyalMember', '');
    }

    if (userRole === USER_ROLES.business) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (userRole === USER_ROLES.enterprise) {
      return t('general.planCode.enterprise', '');
    }

    return t('general.planCode.member', '');
  };

  return (
    <Modal
      open
      onClose={onClose}
      className={fullSizeModalCss}
      overlayClassName={fullSizeModalOverlayCss}
    >
      <Container>
        <UserTierWrapper
          className={cx(
            {
              [guestRoleCss]:
                userRole === USER_ROLES.creator || userRole === USER_ROLES.noSubscriptions,
              [personalRoleCss]: userRole === USER_ROLES.creatorPro,
              [businessRoleCss]: userRole === USER_ROLES.business,
              [enterpriseRoleCss]: userRole === USER_ROLES.enterprise,
            },
            mobileMenuBannerLayout
          )}
        >
          <h6>{getPlanName()}</h6>
          <ModalCloseButton className={closeButtonClass} onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
        </UserTierWrapper>
        <AuthRestricted>
          <MobileUserProfile />
        </AuthRestricted>
        {userRole === USER_ROLES.creator ? (
          <AuthRestricted>
            <CounterWrapper className="downloadCountWrapper">
              <MobileMenuDownloadCounter />
            </CounterWrapper>
          </AuthRestricted>
        ) : (
          ''
        )}
        {freeTrialSubscriptions.length > 0 && (
          <AuthRestricted>
            <CounterWrapper className="trialCounterWrapper">
              <MobileMenuTrialCounter onClose={onClose} />
            </CounterWrapper>
          </AuthRestricted>
        )}
        <NavLinks
          className={cx({
            bannerPresent: userRole === USER_ROLES.creator || freeTrialSubscriptions.length > 0,
          })}
        >
          <ul>
            <SiteNavLinks>
              {links => {
                return links.map((Link, index) => {
                  return (
                    <li key={index.toString()}>
                      <Link onClick={onClose} />
                    </li>
                  );
                });
              }}
            </SiteNavLinks>
          </ul>
        </NavLinks>
        <AuthRestricted>
          <NavLinks>
            <ul>
              <ProfileNavLinks>
                {links => {
                  return links.map((Link, index) => {
                    return (
                      <li key={index.toString()}>
                        <Link onClick={onClose} />
                      </li>
                    );
                  });
                }}
              </ProfileNavLinks>
            </ul>
          </NavLinks>
        </AuthRestricted>
        <AuthRestricted>
          <NavLinks>
            <ul>
              <li>
                <LinkButton onClick={handleSignOut}>{getMiscCopySignOut(miscCopy)}</LinkButton>
              </li>
            </ul>
          </NavLinks>
        </AuthRestricted>
        <AuthRestricted authOnly={false}>
          <SignedOutOptions>
            <LightButton className={navButtonClass} onClick={handleSignIn}>
              {getHeaderSignInButton(siteHeader)}
            </LightButton>
            <DarkButton className={navButtonClass} onClick={handleSignUp}>
              {getHeaderSignUpButton(siteHeader)}
            </DarkButton>
          </SignedOutOptions>
        </AuthRestricted>
      </Container>
    </Modal>
  );
};

export default MobileMenu;
