// @flow
import React, { useContext, useState, useEffect } from 'react';
import ls from 'local-storage';
import SignUpModal from '../SignUpModal/SignUpModal';
import SignInModal from '../SignInModal/SignInModal';
import ForgotPasswordModal from '../ForgotPasswordModal/ForgotPasswordModal';
import LeaveResultsModal from '../LeaveResultsModal/LeaveResultsModal';
import LeaveResultFiltersModal from '../LeaveResultFiltersModal/LeaveResultFiltersModal';
import SubscriptionsRequiredModal from '../SubscriptionsRequiredModal/SubscriptionsRequiredModal';
import PermissionRestrictedModal from '../PremissionRestrictedModal/PermissionRestrictedModal';
import ExtensionShowcaseModal from '../ExtensionShowcaseModal/ExtensionShowcaseModal';
import KeyboardShortcutsModal from '../KeyboardShortcutsModal/KeyboardShortcutsModal';
import LoopsModal from '../LoopsModal/LoopsModal';
import ArtistModal from '../ArtistModal/ArtistModal';
import PosArtistModal from '../PosArtistModal/PosArtistModal';
import PosSnsModal from '../PosSnsModal/PosSnsModal';

export type ModalsContextState = {
  setSignUpOpen: boolean => void,
  signUpOpen: boolean,
  setSignInOpen: boolean => void,
  signInOpen: boolean,
  showForgotPasswordModal: string => void,
  showLeaveResultsModal: () => void,
  showLeaveResultFiltersModal: (
    Array<string>,
    {
      setKeywordsQuery: (Array<string>) => void,
      setMagicQuery: (Array<string>) => void,
      handleClearSongFilters: string => void,
      isMagicKeyword: boolean,
      isYouTubeKeyword?: boolean,
      handleRemoveYouTubeKeyword?: () => void,
    }
  ) => void,
  showSubscriptionRequired: () => void,
  showDownloadCounter: boolean,
  setShowDownloadCounter: boolean => void,
  showTrialCounter: boolean,
  setShowTrialCounter: boolean => void,
  showMobileResultsFiltersModal: () => void,
  hideMobileResultsFiltersModal: () => void,
  mobileResultsFiltersOpen: boolean,
  mobileMenuOpen: boolean,
  setMobileMenuOpen: boolean => void,
  showReferralConditions: boolean,
  setShowReferralConditions: boolean => void,
  sortingRestrictedOpen: boolean,
  showSortingRestrictedModal: () => void,
  showSimilarSearchRestrictedModal: () => void,
  showCustomOrderRestrictedModal: () => void,
  showExtensionShowcaseModal: () => void,
  showKeyboardShortcutsModal: () => void,
  keyboardShortcutsOpen: boolean,
  setVisibleKeyboardShortcutsOpen: boolean => void,
  showLoopsModal: (
    songFamily: string,
    downloadContext: any,
    pauseGlobalPlayback: () => void
  ) => void,
  showCollectionTagSearchModal: (tag: string) => void,
  showArtistModal: (artistSlug: string) => void,
};

export const ModalsContext = React.createContext();

export const useModalsContext = (): ModalsContextState => {
  return useContext(ModalsContext);
};

export const useShowSignUp = () => {
  const { setSignUpOpen } = useModalsContext();

  const showSignUp = () => {
    setSignUpOpen(true);
  };

  return showSignUp;
};

type Props = {
  children: any,
};

const ModalsWrapper = ({ children }: Props) => {
  const [subscriptionRequiredOpen, setSubscriptionRequiredOpen] = useState(false);
  const [leaveResultsOpen, setLeaveResultsOpen] = useState(false);
  const [leaveResultFiltersOpen, setLeaveResultFiltersOpen] = useState(false);
  const [leaveFilteredResultsKeywords, setLeaveFilteredResultsKeywords] = useState([]);
  const [leaveFilteredResultsAction, setLeaveFilteredResultsAction] = useState();
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordInitialEmail, setForgotPasswordInitialEmail] = useState('');
  const [showDownloadCounter, setShowDownloadCounter] = useState(false);
  const [showTrialCounter, setShowTrialCounter] = useState(false);
  const [mobileResultsFiltersOpen, setMobileResultsFiltersOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showReferralConditions, setShowReferralConditions] = useState(false);
  const [sortingRestrictedOpen, setSortingRestrictedOpen] = useState(false);
  const [similarSearchRestrictedOpen, setSimilarSearchRestrictedOpen] = useState(false);
  const [customOrderRestrictedOpen, setCustomOrderRestrictedOpen] = useState(false);
  const [extensionShowcaseOpen, setExtensionShowcaseOpen] = useState(false);
  const [keyboardShortcutsOpen, setVisibleKeyboardShortcutsOpen] = useState(false);
  const [loopsModalOpen, setLoopsModalOpen] = useState(false);
  const [loopsModalShareSlug, setLoopsModalShareSlug] = useState('');
  const [loopsModalData, setLoopsModalData] = useState({});
  const [loopsModalAction, setLoopsModalAction] = useState({ action: () => {} });
  const [collectionTagSearchModalOpen, setCollectionTagSearchModalOpen] = useState(false);
  const [collectionTagSearchModalQuery, setCollectionTagSearchModalQuery] = useState('');
  const [artistModalOpen, setArtistModalOpen] = useState(false);
  const [posArtistModalOpen, setPosArtistModalOpen] = useState(false);
  const [posSnsModalOpen, setPosSnsModalOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState('');

  useEffect(() => {
    const tokenRenewErrorModalOpen = ls.get('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN');

    if (tokenRenewErrorModalOpen === true) {
      setSignInOpen(true);
    }
  }, []);

  const showForgotPasswordModal = (email: string = '') => {
    setForgotPasswordInitialEmail(email);
    setForgotPasswordOpen(true);
  };
  const showLeaveResultsModal = () => {
    setLeaveResultsOpen(true);
  };
  const showLeaveResultFiltersModal = (
    newKeywords: Array<string>,
    confirmKeywordRemoval: {
      setKeywordsQuery: (Array<string>) => void,
      setMagicQuery: (Array<string>) => void,
      handleClearSongFilters: string => void,
      isMagicKeyword: boolean,
      isYouTubeKeyword?: boolean,
      handleRemoveYouTubeKeyword?: () => void,
    }
  ) => {
    setLeaveFilteredResultsAction(confirmKeywordRemoval);
    setLeaveFilteredResultsKeywords(newKeywords);
    setLeaveResultFiltersOpen(true);
  };
  const showSubscriptionRequired = () => {
    setSubscriptionRequiredOpen(true);
  };
  const showMobileResultsFiltersModal = () => {
    setMobileResultsFiltersOpen(true);
  };
  const hideMobileResultsFiltersModal = () => {
    setMobileResultsFiltersOpen(false);
  };
  const showSortingRestrictedModal = () => {
    setSortingRestrictedOpen(true);
  };
  const showSimilarSearchRestrictedModal = () => {
    setSimilarSearchRestrictedOpen(true);
  };
  const showCustomOrderRestrictedModal = () => {
    setCustomOrderRestrictedOpen(true);
  };
  const showExtensionShowcaseModal = () => {
    setExtensionShowcaseOpen(true);
  };
  const showKeyboardShortcutsModal = () => {
    setVisibleKeyboardShortcutsOpen(true);
  };
  const showLoopsModal = (
    songFamily: string,
    contextData: any,
    pauseGlobalPlayback: () => void
  ) => {
    if (!songFamily) return;
    setLoopsModalShareSlug(songFamily);
    setLoopsModalData(contextData);
    setLoopsModalOpen(true);
    setLoopsModalAction({ action: pauseGlobalPlayback });
  };

  const showCollectionTagSearchModal = (tag: string) => {
    setCollectionTagSearchModalQuery(tag);
    setCollectionTagSearchModalOpen(true);
  };

  const showArtistModal = (artistSlug: string) => {
    setSelectedArtist(artistSlug);
    setArtistModalOpen(true);
  };

  const showPosArtistModal = (artistSlug: string) => {
    setSelectedArtist(artistSlug);
    setPosArtistModalOpen(true);
  };

  const showPosSnsModal = (artistSlug: string) => {
    setSelectedArtist(artistSlug);
    setPosSnsModalOpen(true);
  };

  const hideArtistModal = () => {
    setSelectedArtist('');
    setArtistModalOpen(false);
  };

  const hidePosArtistModal = () => {
    setSelectedArtist('');
    setPosArtistModalOpen(false);
  };

  const hidePosSnsModal = () => {
    setSelectedArtist('');
    setPosSnsModalOpen(false);
  };

  return (
    <ModalsContext.Provider
      value={{
        showLeaveResultsModal,
        showLeaveResultFiltersModal,
        signUpOpen,
        setSignUpOpen,
        signInOpen,
        setSignInOpen,
        showForgotPasswordModal,
        showSubscriptionRequired,
        showDownloadCounter,
        setShowDownloadCounter,
        showTrialCounter,
        setShowTrialCounter,
        showMobileResultsFiltersModal,
        hideMobileResultsFiltersModal,
        mobileResultsFiltersOpen,
        mobileMenuOpen,
        setMobileMenuOpen,
        showReferralConditions,
        setShowReferralConditions,
        showSortingRestrictedModal,
        showSimilarSearchRestrictedModal,
        showCustomOrderRestrictedModal,
        showExtensionShowcaseModal,
        showKeyboardShortcutsModal,
        keyboardShortcutsOpen,
        setVisibleKeyboardShortcutsOpen,
        showLoopsModal,
        showCollectionTagSearchModal,
        showArtistModal,
        showPosArtistModal,
        showPosSnsModal,
      }}
    >
      {children}
      {signInOpen && (
        <SignInModal
          onClose={() => {
            setSignInOpen(false);
          }}
        />
      )}
      {signUpOpen && (
        <SignUpModal
          onClose={() => {
            setSignUpOpen(false);
          }}
        />
      )}
      {forgotPasswordOpen && (
        <ForgotPasswordModal
          initialEmail={forgotPasswordInitialEmail}
          onClose={() => {
            setForgotPasswordOpen(false);
          }}
        />
      )}
      {leaveResultsOpen && (
        <LeaveResultsModal
          onClose={() => {
            setLeaveResultsOpen(false);
          }}
        />
      )}
      {leaveResultFiltersOpen && (
        <LeaveResultFiltersModal
          newKeywords={leaveFilteredResultsKeywords}
          confirmKeywordRemoval={leaveFilteredResultsAction}
          onClose={() => {
            setLeaveFilteredResultsAction();
            setLeaveFilteredResultsKeywords([]);
            setLeaveResultFiltersOpen(false);
          }}
        />
      )}
      {subscriptionRequiredOpen && (
        <SubscriptionsRequiredModal
          onClose={() => {
            setSubscriptionRequiredOpen(false);
          }}
        />
      )}
      {sortingRestrictedOpen && (
        <PermissionRestrictedModal
          onClose={() => {
            setSortingRestrictedOpen(false);
          }}
          feature="popularitySorting"
        />
      )}
      {similarSearchRestrictedOpen && (
        <PermissionRestrictedModal
          onClose={() => {
            setSimilarSearchRestrictedOpen(false);
          }}
          feature="similarSearch"
        />
      )}
      {customOrderRestrictedOpen && (
        <PermissionRestrictedModal
          onClose={() => {
            setCustomOrderRestrictedOpen(false);
          }}
          feature="customOrder"
        />
      )}
      {extensionShowcaseOpen && (
        <ExtensionShowcaseModal
          onClose={() => {
            setExtensionShowcaseOpen(false);
          }}
        />
      )}
      {keyboardShortcutsOpen && (
        <KeyboardShortcutsModal
          onClose={() => {
            setVisibleKeyboardShortcutsOpen(false);
          }}
        />
      )}
      {loopsModalOpen && (
        <LoopsModal
          shareSlug={loopsModalShareSlug}
          contextData={loopsModalData}
          pauseGlobalPlayback={() => {
            loopsModalAction.action();
          }}
          onClose={() => {
            setLoopsModalOpen(false);
            setLoopsModalShareSlug('');
            setLoopsModalData({});
            setLoopsModalAction(() => {});
          }}
        />
      )}
      {artistModalOpen && <ArtistModal artistSlug={selectedArtist} onClose={hideArtistModal} />}
      {posArtistModalOpen && (
        <PosArtistModal artistSlug={selectedArtist} onClose={hidePosArtistModal} />
      )}
      {posSnsModalOpen && <PosSnsModal artistSlug={selectedArtist} onClose={hidePosSnsModal} />}
    </ModalsContext.Provider>
  );
};

export default ModalsWrapper;
