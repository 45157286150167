// @flow
import React, { useEffect } from 'react';
import { useNavigate } from '../../../routing/hooks';
import { ROUTES } from '../../../routing/routes';
import { useIsNotAuthenticated, useAuthContext } from '../AuthWrapper/AuthWrapper';
import {
  useQueryParamUtmMedium,
  useQueryParamUtmCampaign,
} from '../../../routing/components/PageComponent/PageComponent';

type Props = {
  children: any,
};

const AuthRequiredWrapper = ({ children }: Props) => {
  const navigate = useNavigate();
  const notAuthenticated = useIsNotAuthenticated();
  const [utmMedium] = useQueryParamUtmMedium();
  const [utmCampaign] = useQueryParamUtmCampaign();
  const { setSignUpSourceData } = useAuthContext();

  useEffect(() => {
    if (notAuthenticated) {
      const signUpSourceData = {};
      if (utmMedium === 'landing-page') {
        signUpSourceData.signUpSource = 'Landing Page';
      }
      if (utmCampaign && utmMedium === 'landing-page') {
        signUpSourceData.signUpCampaign = utmCampaign;
      }
      setSignUpSourceData(signUpSourceData);
      navigate(ROUTES.signup.navigatePath({}));
    }
  }, [notAuthenticated]);
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthRequiredWrapper;
