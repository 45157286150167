// @flow
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { REFERRAL_TYPES } from '../api/firebase/referral/referral';

export type ReferralBannersGraphMdl = {
  data: {
    trialextension_signup_banner: {
      string: string,
    },
    trialextension_cta_banner: {
      text: string,
    },
    trialextension_cta_action: {
      text: string,
    },
    trialextension_toast_message: {
      html: string,
    },
    trialextension_dismiss_toast_message: {
      html: string,
    },
    trialextension_terms_modal_heading: {
      text: string,
    },
    trialextension_terms_modal_description: {
      html: string,
    },
  },
};

export const referralTypeToPrismicKey = (referralType: string): string => {
  const PrismicKeys = {
    [REFERRAL_TYPES.trialExtension]: 'trialextension',
  };
  return PrismicKeys[referralType];
};

export const getReferralSignUpBanner = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return get(copy, `data.${referralTypeToPrismicKey(referralType)}_signup_banner.html`, '') || '';
};

export const getReferralCtaBanner = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return get(copy, `data.${referralTypeToPrismicKey(referralType)}_cta_banner.text`, '') || '';
};

export const getReferralCtaAction = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return get(copy, `data.${referralTypeToPrismicKey(referralType)}_cta_action.text`, '') || '';
};

export const getReferralToastMessage = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return get(copy, `data.${referralTypeToPrismicKey(referralType)}_toast_message.html`, '') || '';
};

export const getReferralDismissToastMessage = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return (
    get(copy, `data.${referralTypeToPrismicKey(referralType)}_dismiss_toast_message.html`, '') || ''
  );
};

export const getReferralTermsModalHeading = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return (
    get(copy, `data.${referralTypeToPrismicKey(referralType)}_terms_modal_heading.text`, '') || ''
  );
};

export const getReferralTermsModalDescription = (
  copy: ReferralBannersGraphMdl,
  referralType: string
): string => {
  return (
    get(copy, `data.${referralTypeToPrismicKey(referralType)}_terms_modal_description.html`, '') ||
    ''
  );
};

export const query = graphql`
  fragment ReferralBanners on PrismicReferralBanners {
    data {
      trialextension_signup_banner {
        html
      }
      trialextension_cta_banner {
        text
      }
      trialextension_cta_action {
        text
      }
      trialextension_toast_message {
        html
      }
      trialextension_dismiss_toast_message {
        html
      }
      trialextension_terms_modal_heading {
        text
      }
      trialextension_terms_modal_description {
        html
      }
    }
  }
`;
