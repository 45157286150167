// @flow
import React from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { BREAKPOINTS } from '../../styles/responsive';

type Props = {
  children: any,
};

export const useIsSmallDevice = (): boolean => {
  const [width, height] = useWindowSize(
    BREAKPOINTS.d770 /* initialWidth when there is no window */,
    720 /* initialHeight when there is no window */,
    { fps: 30 }
  );
  return width < BREAKPOINTS.d770;
  // const { innerWidth, outerWidth } = useWindowSize();
};

export const SmallDeviceOnly = ({ children }: Props) => {
  const isSmallDevice = useIsSmallDevice();
  return isSmallDevice ? children : null;
};

export const LargeDeviceOnly = ({ children }: Props) => {
  const isSmallDevice = useIsSmallDevice();
  return !isSmallDevice ? children : null;
};
