// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SubscriptionRequiredModalGraphMdl = {
  data: {
    heading: {
      text: string,
    },
    instructions: {
      html: string,
    },
    add_subscription_button: {
      text: string,
    },
  },
};

export const getSubscriptionRequiredModalHeading = (
  copy: SubscriptionRequiredModalGraphMdl
): string => {
  return get(copy, 'data.heading.text', '') || '';
};

export const getSubscriptionRequiredModalInstructions = (
  copy: SubscriptionRequiredModalGraphMdl
): string => {
  return get(copy, 'data.instructions.html', '') || '';
};

export const getSubscriptionRequiredModalAddButton = (
  copy: SubscriptionRequiredModalGraphMdl
): string => {
  return get(copy, 'data.add_subscription_button.text', '') || '';
};

export const query = graphql`
  fragment SubscriptionRequiredModal on PrismicSubscriptionRequiredModal {
    data {
      heading {
        text
      }
      instructions {
        html
      }
      add_subscription_button {
        text
      }
    }
  }
`;
