// @flow
import { transparentize } from 'polished';
import React from 'react';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import {
  getSongArtist,
  getSongArtistSlug,
  getSongDurationFormatted,
  getSongID,
  getSongMixType,
  isSongFullMix,
} from '../../../../../api/algolia/song';
import { useHideTrack, useUnhideTrack } from '../../../../../api/firebase/user/hiddenTracks';
import { getTrackDownloadAction, getTrackDownloadMidiAction } from '../../../../../cms/track';
import DownloadingIcon from '../../../../../components/DownloadingIcon/DownloadingIcon';
import {
  useMiscCopy,
  useTrackCopy,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useAudioPlaybackContext } from '../../../../../song/components/AudioPlayback/AudioPlayback';
import BookmarkButton from '../../../../../song/components/FullSongCard/components/SongActions/components/BookmarkButton/BookmarkButton';
import HideSongButton from '../../../../../song/components/FullSongCard/components/SongActions/components/HideSongButton/HideSongButton';
import PlayPauseButton from '../../../../../song/components/FullSongCard/components/SongActions/components/PlayPauseButton/PlayPauseButton';
import ShareButton from '../../../../../song/components/FullSongCard/components/SongActions/components/ShareButton/ShareButton';
import SimilarSongsButton from '../../../../../song/components/FullSongCard/components/SongActions/components/SimilarSongsButton/SimilarSongsButton';
import ViewPackButton from '../../../../../song/components/FullSongCard/components/SongActions/components/ViewPackButton/ViewPackButton';
import { useSong } from '../../../../../song/components/SongContextWrapper/SongContextWrapper';
import { useDownloadSong } from '../../../../../song/hooks';
import { darkColor, lightColor } from '../../../../../styles/config/colors';
import { monoNavCss } from '../../../../../styles/typography/typography';
import { useUserHiddenTracks } from '../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { formatSeconds } from '../../../../../utils/time';
import CloseIcon from '../../../../../assets/inline-assets/close.svg';
import SongActionButton from '../../../../../song/components/FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import { getMiscCopyCloseButton } from '../../../../../cms/miscCopy';
import {
  hideOnMobileCss,
  hideOnSmallDeviceCss,
  MOBILE_DEVICE_BREAKPOINT,
  showOnMobileCss,
  showOnSmallDeviceCss,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import ArtistName from '../../../../../song/components/FullDisplaySongCard/components/ArtistName/ArtistName';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 15px;
    position: relative;
  }
`;

const SongCard = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const SongPlayback = styled('div')`
  display: flex;
  align-items: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 90%;
    max-width: 90%;
  }
`;

const SongInfo = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 75%;
  }
`;

const SongTitle = styled('h4')`
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 25px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 340px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: 250px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    max-width: 350px;
  }
`;

const SongSubtitle = styled('div')`
  color: rgba(15, 15, 20, 0.6);
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 13px;
`;

const Actions = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;
`;

const SongActions = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Control = styled('div')`
  margin-left: 10px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-left: 5px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    &:first-of-type {
      margin-left: 0px;
    }
  }
`;

const CloseAction = styled('div')`
  border-left: 1px solid ${transparentize(0.9, lightColor)};
  padding: 0px 0px 0px 10px;
  margin: 0px 0px 0px 15px;
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  & svg {
    transform: scale(0.8);
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    border-left: unset;
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0px;
    margin: 0px;
    align-items: flex-start;

    & svg {
      transform: scale(0.9);
    }
  }
`;

const DownloadActions = styled('div')`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 10px;
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
  }
`;

const DownloadButton = styled('button')`
  ${monoNavCss};
  background-color: none;
  border: 1px solid #4b4c50;
  border-radius: 3px;
  text-transform: uppercase;
  color: ${darkColor};
  background-color: unset;
  padding: 8px 15px;
  width: 250px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 240px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    margin-top: 10px;
  }
`;

const hiddenMidiDownload = css`
  display: none;
`;

type Props = {
  songTitle: string,
  activeSong: boolean,
  closeModal: () => void,
};

const LoopsModalsHeaderSong = ({ songTitle, activeSong, closeModal }: Props) => {
  const song = useSong();
  const { progress } = useAudioPlaybackContext();
  const songDuration = getSongDurationFormatted(song);
  const userHiddenSongs = useUserHiddenTracks();
  const [handleHideTrack, hideTrackBusy] = useHideTrack(song);
  const [handleUnhideTrack, unhideTrackBusy] = useUnhideTrack(song);
  const trackCopy = useTrackCopy();
  const miscCopy = useMiscCopy();
  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(song);

  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  const downloadLabel = `${getTrackDownloadAction(trackCopy)} ${getSongMixType(song)}`;
  const downloadMidiLabel = `${getTrackDownloadMidiAction(trackCopy)} ${getSongMixType(song)}`;
  const fullTitle = isSongFullMix(song)
    ? songTitle
    : `${songTitle}_${getSongMixType(song).toLocaleLowerCase()}`;

  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  const disableMidi = song.audio.downloadMidi === undefined;

  return (
    <>
      {activeSong && (
        <Container>
          <CloseAction onClick={closeModal} className={showOnMobileCss}>
            <SongActionButton icon={<CloseIcon />} label={getMiscCopyCloseButton(miscCopy)} />
          </CloseAction>
          <SongCard>
            <SongPlayback>
              <PlayPauseButton />
              <SongInfo>
                <SongTitle>{fullTitle.replace(/ /g, '_')}</SongTitle>
                <SongSubtitle>
                  <ArtistName name={artistName} slug={artistslug} />
                  {` ${formatSeconds(progress)} / ${songDuration}`}
                </SongSubtitle>
              </SongInfo>
            </SongPlayback>
            <Actions>
              <SongActions className={hideOnSmallDeviceCss}>
                <Control>
                  <ViewPackButton closeModal={closeModal} />
                </Control>
                <Control>
                  <BookmarkButton />
                </Control>
                <Control>
                  <SimilarSongsButton onClose={closeModal} />
                </Control>
                <Control>
                  <ShareButton />
                </Control>
                <Control>
                  <HideSongButton
                    isHidden={songIsHidden}
                    onClick={handleToggleHidden}
                    busy={hideTrackBusy || unhideTrackBusy}
                  />
                </Control>
              </SongActions>
              <CloseAction onClick={closeModal} className={hideOnMobileCss}>
                <SongActionButton icon={<CloseIcon />} label={getMiscCopyCloseButton(miscCopy)} />
              </CloseAction>
            </Actions>
          </SongCard>
          <DownloadActions>
            <SongActions className={showOnSmallDeviceCss}>
              <Control>
                <ViewPackButton />
              </Control>
              <Control>
                <BookmarkButton />
              </Control>
              <Control>
                <SimilarSongsButton onClose={closeModal} />
              </Control>
              <Control>
                <ShareButton />
              </Control>
              <Control>
                <HideSongButton
                  isHidden={songIsHidden}
                  onClick={handleToggleHidden}
                  busy={hideTrackBusy || unhideTrackBusy}
                />
              </Control>
            </SongActions>
            <DownloadButton onClick={() => downloadSong()}>
              {downloadBusy ? <DownloadingIcon /> : downloadLabel}
            </DownloadButton>
            <DownloadButton
              className={cx({ [hiddenMidiDownload]: disableMidi })}
              onClick={() => downloadSong('MIDI')}
            >
              {downloadMidiBusy ? <DownloadingIcon /> : downloadMidiLabel}
            </DownloadButton>
          </DownloadActions>
        </Container>
      )}
    </>
  );
};

export default LoopsModalsHeaderSong;
