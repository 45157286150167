// @flow
import { useState, useEffect } from 'react';
import { get, chunk } from 'lodash';
import type { AlgoliaSongMdl } from '../../algolia/song';
import { getSongID } from '../../algolia/song';
import { getFirestoreUserRef } from './user';
import { getTracksFromAlgolia } from '../../algolia/search';
import { getYesterdayDate } from '../../../utils/date';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';

export const getUserDownloadsRef = (uid: string) => {
  return getFirestoreUserRef(uid).collection('history.downloads');
};

export const getUserDownloadsCountRef = (uid: string) => {
  const yesterdayDate = getYesterdayDate();
  return getFirestoreUserRef(uid)
    .collection('history.downloads')
    .where('timestamp', '>=', yesterdayDate);
};

export type DownloadHistoryMdl = {
  context?: Array<string>,
  location?: string,
  data: {
    partId: number,
    partNumber: any,
    songId: string,
  },
  timestamp: {
    seconds: number,
  },
  track?: AlgoliaSongMdl,
};

export type DownloadHistoryWrapperMdl = {
  id: string,
  data: DownloadHistoryMdl,
};

export const getDownloadHistoryTrack = (
  download: DownloadHistoryWrapperMdl
): AlgoliaSongMdl | null => {
  return get(download, 'data.track', null);
};

export const getDownloadHistoryTimestamp = (download: DownloadHistoryWrapperMdl): number => {
  return download.data.timestamp.seconds * 1000;
};

export const getDownloadHistoryObjectId = (download: DownloadHistoryWrapperMdl): string => {
  return `${download.data.data.songId}_${download.data.data.partId}`;
};

export const getDownloadHistoryKeywords = (download: DownloadHistoryWrapperMdl): Array<string> => {
  return get(download, 'data.context', []);
};

export const getDownloadHistoryLocation = (download: DownloadHistoryWrapperMdl): string => {
  return get(download, 'data.location', '');
};

export const getDownloadMicropart = (download: DownloadHistoryWrapperMdl): number => {
  return get(download, 'data.data.partNumber', null);
};

export const getDownloadFileType = (download: DownloadHistoryWrapperMdl): number => {
  return get(download, 'data.data.fileType', '');
};

export const getUserDownloads = (uid: string): Promise<Array<DownloadHistoryWrapperMdl>> => {
  const ref = getUserDownloadsRef(uid);
  return ref.get().then(querySnapshot => {
    const results = [];
    querySnapshot.forEach(doc => {
      results.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return results;
  });
};

export const getUserDownloadCount = (uid: string): Promise<number> => {
  const ref = getUserDownloadsCountRef(uid);
  return ref.get().then(querySnapshot => {
    const downloadCount = querySnapshot.docs.length;
    return downloadCount;
  });
};

const getDownloadHistoryTrackIds = (
  downloadHistory: Array<DownloadHistoryWrapperMdl>
): Array<string> => {
  const trackIds = {};
  downloadHistory.forEach((download: DownloadHistoryWrapperMdl) => {
    trackIds[getDownloadHistoryObjectId(download)] = true;
  });
  return Object.keys(trackIds);
};

export const getUserDownloadsWithTracks = async (uid: string, locale: string) => {
  const downloads = await getUserDownloads(uid);
  const trackIds = getDownloadHistoryTrackIds(downloads);

  const chunkedSongs = chunk(trackIds, 1000);

  const searchPromises = chunkedSongs.map(songsChunk => {
    return getTracksFromAlgolia(songsChunk, locale);
  });

  const trackResponses = await Promise.all(searchPromises);
  let tracks = [];
  trackResponses.forEach(trackGroup => {
    tracks = tracks.concat(trackGroup.results);
  });

  return downloads.map(download => {
    const trackId = getDownloadHistoryObjectId(download);
    const track = tracks.find((findTrack: AlgoliaSongMdl) => {
      return getSongID(findTrack) === trackId;
    });
    return {
      ...download,
      data: {
        ...download.data,
        track,
      },
    };
  });
};

export const useWatchUserDownloadHistory = () => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [downloads, setDownloads] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getUserDownloadsRef(userId).onSnapshot(querySnapshot => {
      const downloadData = [];
      querySnapshot.forEach(doc => {
        downloadData.push({ id: doc.id, data: doc.data() });
      });
      setDownloads(downloadData);
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [downloads, loaded];
};
