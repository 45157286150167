// @flow
import React from 'react';
import { useMiscCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import SelectedKeywords from './components/SelectedKeywords/SelectedKeywords';
import SuggestedKeywords from './components/SuggestedKeywords/SuggestedKeywords';

type Props = {};

const KeywordsAside = () => {
  return (
    <div>
      <SuggestedKeywords />
    </div>
  );
};

export default KeywordsAside;
