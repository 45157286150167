import React from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { cx } from 'emotion';
import ls from 'local-storage';
import { referralBgColor } from '../../../../styles/config/colors';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import {
  getReferralCouponName,
  getReferralCouponType,
  replaceReferralPlaceholders,
  getReferralCouponTrialDays,
} from '../../../../api/firebase/referral/referral';
import {
  getReferralCtaBanner,
  getReferralSignUpBanner,
  getReferralCtaAction,
} from '../../../../cms/referralBanners';
import { useReferralBanners } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useIsAuthenticated } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import { BANNERS } from '../../../../images/paths';
import type { ReferralCouponDataMDL } from '../../../../api/firebase/referral/referral';
import CloseIcon from '../../../../assets/inline-assets/close.svg';
import InfoIcon from '../../../../assets/inline-assets/info-icon.svg';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { ROUTES } from '../../../../routing/routes';
import { useNavigate } from '../../../../routing/hooks';
import { SUBSCRIPTIONS } from '../../../../user/subscriptions/data';
import { useDismissReferralBannerToast } from '../../../../toasts/hooks';

type Props = {
  referralCoupon: ReferralCouponDataMDL,
  showReferralBanner: boolean,
  handleShowReferralConditions: () => void,
  setShowReferralBanner: boolean => void,
};

const HeaderBanner = styled('div')`
  height: 100px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  transition: height 0.5s ease-in;
  background-color: ${referralBgColor};
  background-image: url('${BANNERS.referral}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & p {
    display: inline;
  }

  & a {
    text-decoration: underline;
    font-weight: 500;
  }

  & > svg {
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-image: url(${BANNERS.referralMobile});
    padding: 0px 15px;

    & > svg {
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
  }

  &.openBanner {
    height: 100px;
  }
`;

const HeaderCopy = styled('div')`
  margin: 0px 20%;

  & svg {
    position: relative;
    left: 15px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin: 20px;
    width: 80%;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.17px;
    line-height: 24px;
    text-align: center;

    & svg {
      position: relative;
      left: 10px;
    }
  }
`;

const BannerLink = styled('span')`
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ReferralBanner = ({
  referralCoupon,
  showReferralBanner,
  handleShowReferralConditions,
  setShowReferralBanner,
}: Props) => {
  const locale = useLocale();
  const navigate = useNavigate();
  const authenticated = useIsAuthenticated();
  const bannerCopy = useReferralBanners();
  const referralCouponType = getReferralCouponType(referralCoupon);
  const referralCouponName = getReferralCouponName(referralCoupon, locale);
  const referralCouponTrialDays = getReferralCouponTrialDays(referralCoupon);
  const { selectPlan, setReferralCoupon } = useSubscriptionsContext();
  const dismissToast = useDismissReferralBannerToast(referralCouponType, referralCouponName);

  const handleCtaNavigate = () => {
    navigate(ROUTES.subscriptions.navigatePath({}));
    selectPlan(SUBSCRIPTIONS.creatorPro.key);
  };

  const handleDismiss = () => {
    setReferralCoupon(null);
    setShowReferralBanner(false);
    ls.remove('REFERRAL_COUPON_DATA');
    dismissToast();
  };

  return (
    <>
      <HeaderBanner
        className={cx({
          ['openBanner']: showReferralBanner,
        })}
      >
        <HeaderCopy
          className={cx({
            ['openBanner']: showReferralBanner,
          })}
        >
          <div>
            {authenticated ? (
              <>
                {replaceReferralPlaceholders(
                  getReferralCtaBanner(bannerCopy, referralCouponType),
                  referralCouponName,
                  referralCouponTrialDays
                )}
                <BannerLink onClick={handleCtaNavigate}>
                  {getReferralCtaAction(bannerCopy, referralCouponType)}
                </BannerLink>
              </>
            ) : (
              <>
                {parse(
                  replaceReferralPlaceholders(
                    getReferralSignUpBanner(bannerCopy, referralCouponType),
                    referralCouponName,
                    referralCouponTrialDays
                  )
                )}
              </>
            )}
            <InfoIcon onClick={handleShowReferralConditions} />
          </div>
        </HeaderCopy>
        <CloseIcon onClick={handleDismiss} />
      </HeaderBanner>
    </>
  );
};

export default ReferralBanner;
