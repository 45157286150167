// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type PricingPageFaqGraphMdl = {
  faq_title: {
    text: string,
  },
  faq_answer: {
    html: string,
  },
};

export type PricingPageGraphMdl = {
  data: {
    heading: {
      text: string,
    },
    introduction: {
      html: string,
    },
    standard_action_button: {
      text: string,
    },
    standard_action_button: {
      text: string,
    },
    faqs_title: {
      text: string,
    },
    pricing_faqs: Array<PricingPageFaqGraphMdl>,
    agency_heading: {
      text: string,
    },
    agency_subheading: {
      text: string,
    },
    agency_intro: {
      text: string,
    },
    agency_characteristics: {
      html: string,
    },
    show_promotional_labels: boolean,
    promotional_valid_plans: {
      text: string,
    },
    promotional_valid_intervals: {
      text: string,
    },
    promotional_default_interval: {
      text: string,
    },
    promotional_label_new_plan: boolean,
  },
};

export const getPricingPageHeading = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.heading.text', '');
};

export const getPricingPageIntroduction = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.introduction.html', '');
};

export const getPricingPageStandardActionButton = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.standard_action_button.text', '');
};

export const getPricingPageTrialActionButton = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.trial_action_button.text', '');
};

export const getPricingPageFaqsSectionTitle = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.faqs_title.text', '');
};

export const getPricingPageFaqs = (copy: PricingPageGraphMdl): Array<PricingPageFaqGraphMdl> => {
  return get(copy, 'data.pricing_faqs', []);
};

export const getPricingPageFaqTitle = (copy: PricingPageFaqGraphMdl): string => {
  return get(copy, 'faq_title.text', '');
};

export const getPricingPageFaqAnswer = (copy: PricingPageFaqGraphMdl): string => {
  return get(copy, 'faq_answer.html', '');
};

export const getPricingPageAgencyHeading = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.agency_heading.text', '');
};

export const getPricingPageAgencySubheading = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.agency_subheading.text', '');
};

export const getPricingPageAgencyIntro = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.agency_intro.text', '');
};

export const getPricingPageAgencyCharacteristics = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.agency_characteristics.html', '');
};

export const getPricingPageShowPromotionalLabels = (copy: PricingPageGraphMdl): boolean => {
  return get(copy, 'data.show_promotional_labels', false);
};

export const getPricingPagePromotionalValidPlans = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.promotional_valid_plans.text', '');
};

export const getPricingPagePromotionalValidIntervals = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.promotional_valid_intervals.text', '');
};

export const getPricingPagePromotionalDefaultInterval = (copy: PricingPageGraphMdl): string => {
  if (!getPricingPageShowPromotionalLabels(copy)) return '';
  return get(copy, 'data.promotional_default_interval.text', '');
};

export const isPromotionAvailable = (
  copy: PricingPageGraphMdl,
  planCode: string,
  planInterval: string
): boolean => {
  const showPromotionalLabel = getPricingPageShowPromotionalLabels(copy);

  if (!showPromotionalLabel) {
    return false;
  }

  const validPromotionalPlans = getPricingPagePromotionalValidPlans(copy).split(', ');
  const validPromotionalIntervals = getPricingPagePromotionalValidIntervals(copy).split(', ');

  const planIsPromotionAllowed = validPromotionalPlans.includes(planCode);
  const intervalIsPromotionAllowed = validPromotionalIntervals.includes(planInterval);

  return planIsPromotionAllowed && intervalIsPromotionAllowed;
};

export const getPricingPagePromotionalLabelNewPlan = (copy: PricingPageGraphMdl): string => {
  return get(copy, 'data.promotional_label_new_plan.html', '');
};

export const query = graphql`
  fragment PricingPage on PrismicPricingPage {
    data {
      heading {
        text
      }
      introduction {
        html
      }
      standard_action_button {
        text
      }
      trial_action_button {
        text
      }
      faqs_title {
        text
      }
      pricing_faqs {
        faq_title {
          text
        }
        faq_answer {
          html
        }
      }
      agency_heading {
        text
      }
      agency_subheading {
        text
      }
      agency_intro {
        text
      }
      agency_characteristics {
        html
      }
      show_promotional_labels
      promotional_valid_plans {
        text
      }
      promotional_valid_intervals {
        text
      }
      promotional_default_interval {
        text
      }
      promotional_label_new_plan {
        html
      }
    }
  }
`;
