// @flow
import React from 'react';
import styled from 'react-emotion';
import BrowseSongsManager from '../../../../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import ExpandableDisplaySongCard from '../../../../../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import {
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { getSongID, isSongFullMix, isSongSfx } from '../../../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import SearchResultsMessageLayout from '../../../SearchView/components/SearchResults/components/SearchResultsMessageLayout/SearchResultsMessageLayout';
import SimilarSongsIcon from '../../../../../../../assets/inline-assets/similar-songs-icon.svg';
import { getResultsPageSimilarSongsHeading } from '../../../../../../../cms/resultsPage';
import {
  useNoResultsPageCopy,
  useResultsPage,
} from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getNoResultsSimilarSearchEmptySearchHeader,
  getNoResultsSimilarSearchEmptySearchMessage,
} from '../../../../../../../cms/noResultsPage';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import TabbedSongList from '../../../../../../../layouts/components/TabbedSongList/TabbedSongList';
import { SONG_TAB_TYPES } from '../../../../../../../utils/songTabs';
import ExpandableSongCard from '../../../../../../../song/components/ExpandableSongCard/ExpandableSongCard';
import DirectSongViewWrapper from '../../../../../DirectScreen/components/DirectSongViewWrapper/DirectSongViewWrapper';
import AudioPlayerWrapper from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { PLAYER_TYPES } from '../../../../../../../song/data';

const SongsListContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MainSongContainer = styled('div')`
  margin-bottom: 50px;
  width: 100%;
`;

type Props = {
  originSong: AlgoliaSongMdl,
  similarSongs: Array<AlgoliaSongMdl>,
  similarityKeywords: Array<string>,
};

const SimilarResultsSongList = ({ originSong, similarSongs, similarityKeywords }: Props) => {
  const noResultsPage = useNoResultsPageCopy();
  const { isPaidCustomer } = useSubscriptionsContext();
  const defaultTab = isSongSfx(originSong) ? SONG_TAB_TYPES.sfx : SONG_TAB_TYPES.full_mixes;
  const isInlinePlayer = isSongSfx(originSong);

  return (
    <>
      {similarSongs.length > 0 ? (
        <SongsListContainer>
          <BrowseSongsManager
            songs={[originSong]}
            songListType="similar"
            songListTitle="Similar Songs"
            songListSlug={getSongID(originSong)}
            songlistKeywords={[]}
            queueRestricted={!isPaidCustomer}
            restrictionType="paidSubscription"
            keywords={similarityKeywords}
            mixpanelKeywords={similarityKeywords}
          >
            <MainSongContainer>
              {isInlinePlayer ? (
                <AudioPlayerWrapper>
                  <DirectSongViewWrapper>
                    <ExpandableSongCard
                      song={originSong}
                      alwaysExpanded
                      hidePackActions
                      playerType={PLAYER_TYPES.sfx}
                    />
                  </DirectSongViewWrapper>
                </AudioPlayerWrapper>
              ) : (
                <ExpandableDisplaySongCard song={originSong} songPosition={0} alwaysExpanded />
              )}
            </MainSongContainer>
          </BrowseSongsManager>
          <TabbedSongList
            songs={similarSongs}
            defaultTab={defaultTab}
            songListType="similar"
            songListTitle="Similar Songs"
            songListSlug={getSongID(originSong)}
            songlistKeywords={[]}
            queueRestricted={!isPaidCustomer}
            restrictionType="paidSubscription"
            keywords={similarityKeywords}
            mixpanelKeywords={similarityKeywords}
          />
        </SongsListContainer>
      ) : (
        <SearchResultsMessageLayout
          title={getNoResultsSimilarSearchEmptySearchHeader(noResultsPage)}
          description={getNoResultsSimilarSearchEmptySearchMessage(noResultsPage)}
          icon={<SimilarSongsIcon />}
        />
      )}
    </>
  );
};

export default SimilarResultsSongList;
