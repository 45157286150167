// @flow
import React from 'react';
import parse from 'html-react-parser';
import { cx } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { useSetRecoilState } from 'recoil';
import {
  smallMediumTextCss,
  smallPlainTextMonoCss,
} from '../../../../../../styles/typography/typography';
import { lightColor, creatorBgColor, darkColor } from '../../../../../../styles/config/colors';
import { CompactButton } from '../../../../../Button/Button';
import { BANNERS } from '../../../../../../images/paths';
import { useUserProfileContext } from '../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  useDownloadCountMenu,
  useMiscCopy,
  useTrackCopy,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getDownloadCountMenuTitle,
  getDownloadCountMenuCounter,
  getDownloadCountMenuUpgradeTitle,
  getDownloadCountMenuUpgradeDescription,
  getDownloadCountMenuDownloadLimitReached,
  getDownloadCountMenuDownloadsLabel,
} from '../../../../../../cms/downloadCountMenu';
import { getMiscCopyUpgradeNowButton } from '../../../../../../cms/miscCopy';
import { getTrackDownloadAction } from '../../../../../../cms/track';
import { useSubscriptionsContext } from '../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../../../../../user/subscriptions/data';
import { conversionSourceAtom } from '../../../../../../store/mixpanelAnalytics';

const countLimitReached = 'countLimitReached';

const Container = styled('section')`
  background: ${lightColor}
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 13px;
`;

const DownloadCountTitle = styled('h6')`
  ${smallMediumTextCss};
  margin: 0px 40px 3px 40px;
  padding-top: 12px;
  border-top: 1px solid ${transparentize(0.9, darkColor)};
`;

const RemainingDownloadsInfo = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
  padding: 0px 40px 0px 40px;
`;

const CountInformation = styled('p')`
  width: 195px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 10px;

  & span {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CountNumberWrapper = styled('div')`
  width: 50px;
  height: 50px;
  background-color: ${transparentize(0.9, darkColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 2px;
  margin-left: 10px;
`;

const CountNumber = styled('h6')`
  ${smallPlainTextMonoCss};
  font-size: 28px;
  font-weight: 300;

  &.${countLimitReached} {
    opacity: 0.3;
  }
`;

const UpgradePromptWrapper = styled('div')`
  background-color: ${creatorBgColor};
  background-image: url('${BANNERS.dailyDownloads}');
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const UpgradePromptInfo = styled('div')`
  padding: 18px 40px 0px 40px;
`;

const UpgradePrompTitle = styled('h6')`
  ${smallMediumTextCss};
  margin-bottom: 10px;
`;

const UpgradePromptDescription = styled('p')`
  font-size: 12px;
  line-height: 20px;
`;

const UpgradePromptButtonWrapper = styled('div')`
  padding: 15px 40px 20px 40px;
  display: flex;
  justify-content: center;

  & a {
    width: 100%;
  }
`;
const UpgradePromptButton = styled(CompactButton)`
  width: 100%;
`;

const parseDownloadCount = (countParagraph: string, number: number, downloadsLabel: string) => {
  const count = number.toString();
  const trackCopy = useTrackCopy();
  const label = number !== 1 ? downloadsLabel : getTrackDownloadAction(trackCopy);
  return countParagraph.replace('$DOWNLOADS_COUNT$', `<span>${count} ${label}</span>`);
};

const MobileMenuDownloadCounter = () => {
  const { remainingDailyDownloads } = useUserProfileContext();
  const downloadCountMenuCopy = useDownloadCountMenu();
  const miscCopy = useMiscCopy();
  const { selectPlan, subscriptions, openChangeSubscriptionModal } = useSubscriptionsContext();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const handleClick = () => {
    setConversionSource('download_limit');
    if (subscriptions.length === 0) {
      selectPlan(SUBSCRIPTIONS.creatorPro.key);
    } else {
      const defaultSubscriptionKey = subscriptions[0].key;
      openChangeSubscriptionModal(defaultSubscriptionKey);
    }
  };

  return (
    <Container>
      <DownloadCountTitle>{getDownloadCountMenuTitle(downloadCountMenuCopy)}</DownloadCountTitle>
      <RemainingDownloadsInfo>
        <CountInformation>
          {remainingDailyDownloads > 0
            ? parse(
                parseDownloadCount(
                  getDownloadCountMenuCounter(downloadCountMenuCopy),
                  remainingDailyDownloads,
                  getDownloadCountMenuDownloadsLabel(downloadCountMenuCopy)
                )
              )
            : getDownloadCountMenuDownloadLimitReached(downloadCountMenuCopy)}
        </CountInformation>
        <CountNumberWrapper>
          <CountNumber
            className={cx({
              [countLimitReached]: remainingDailyDownloads === 0,
            })}
          >
            {remainingDailyDownloads}
          </CountNumber>
        </CountNumberWrapper>
      </RemainingDownloadsInfo>
      <UpgradePromptWrapper>
        <UpgradePromptInfo>
          <UpgradePrompTitle>
            {getDownloadCountMenuUpgradeTitle(downloadCountMenuCopy)}
          </UpgradePrompTitle>
          <UpgradePromptDescription>
            {getDownloadCountMenuUpgradeDescription(downloadCountMenuCopy)}{' '}
          </UpgradePromptDescription>
        </UpgradePromptInfo>
        <UpgradePromptButtonWrapper>
          <UpgradePromptButton onClick={handleClick}>
            {getMiscCopyUpgradeNowButton(miscCopy)}
          </UpgradePromptButton>
        </UpgradePromptButtonWrapper>
      </UpgradePromptWrapper>
    </Container>
  );
};

export default MobileMenuDownloadCounter;
