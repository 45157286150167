import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  songPositionAtom,
  magicKeywordsSearchedAtom,
  mixpanelKeywordsSearchedAtom,
  playbackListSlugAtom,
  playbackListTypeAtom,
  relatedSearchedAtom,
  sectionOriginAtom,
  songPlayingSelector,
  youtubeKeywordsSearchedAtom,
  youtubeVideoTitleSearchedAtom,
  artistSlugAtom,
} from '../../../store/globalPlayer';
import GlobalAudioCardWrapper from './components/GlobalAudioCardWrapper/GlobalAudioCardWrapper';
import { isSmallDeviceSize } from '../../../utils/device';
import DesktopGlobalSongPlayer from './components/DesktopGlobalSongPlayer/DesktopGlobalSongPlayer';
import MobileGlobalSongPlayer from './components/MobileGlobalSongPlayer/MobileGlobalSongPlayer';
import DownloadContextWrapper from '../../../components/DownloadContextWrapper/DownloadContextWrapper';

const GlobalSongPlayer = React.memo(() => {
  const songPlaying = useRecoilValue(songPlayingSelector);
  const songPosition = useRecoilValue(songPositionAtom);
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const mixpanelKeywordsSearched = useRecoilValue(mixpanelKeywordsSearchedAtom);
  const magicKeywordsSearched = useRecoilValue(magicKeywordsSearchedAtom);
  const youtubeKeywordsSearched = useRecoilValue(youtubeKeywordsSearchedAtom);
  const youtubeVideoTitleSearched = useRecoilValue(youtubeVideoTitleSearchedAtom);
  const sectionOrigin = useRecoilValue(sectionOriginAtom);
  const relatedSearched = useRecoilValue(relatedSearchedAtom);
  const artistSlug = useRecoilValue(artistSlugAtom);

  return (
    <>
      {songPlaying !== undefined && (
        <DownloadContextWrapper
          location="global"
          mixpanelKeywords={mixpanelKeywordsSearched}
          magicKeywords={magicKeywordsSearched}
          youtubeKeywords={youtubeKeywordsSearched}
          youtubeVideoTitle={youtubeVideoTitleSearched}
          sectionOrigin={sectionOrigin}
          relatedCollection={relatedSearched}
          curatedCollection={playbackListType === 'collection' ? playbackListSlug : ''}
          artistProfile={artistSlug}
        >
          <GlobalAudioCardWrapper
            song={songPlaying}
            playbackListSlug={playbackListSlug}
            songPosition={songPosition}
            autoload
            autoplay
          >
            {isSmallDeviceSize() ? <MobileGlobalSongPlayer /> : <DesktopGlobalSongPlayer />}
          </GlobalAudioCardWrapper>
        </DownloadContextWrapper>
      )}
    </>
  );
});

export default GlobalSongPlayer;
