// @flow
import React, { useEffect, useState, useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { GlobalHotKeys } from 'react-hotkeys';
import { Howler } from 'howler';
import { useRecoilValue } from 'recoil';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import {
  getParsedMicroparts,
  getSongDuration,
  getSongID,
  getSongMicropartDownloadURL,
  getSongStreamUrl,
} from '../../../../../api/algolia/song';
import { useMicroPartsContext } from '../../../MicroPartsWrapper/MicroPartsWrapper';
import { useGlobalSongAudioPlayback } from '../hooks/hooks';
import { findMicropartIndex } from '../../../AudioBars/AudioBars';
import { useAuthContext, useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  useModalsContext,
  useShowSignUp,
} from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { firebaseApiHandler } from '../../../../../api/firebase/api';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../user/subscriptions/data';
import {
  analyticsMixpanelPlayTrack,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { getPlayerKeyboardShortcuts } from '../../../../../utils/keyboardShortcuts';
import { useKeyboardShortcutContext } from '../../../../../components/KeyboardShortcutWrapper/KeyboardShortcutWrapper';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import {
  activeGlobalPlaybackAtom,
  globalPlayConfigurationAtom,
  nextSongEnabledSelector,
  songStartTimeAtom,
} from '../../../../../store/globalPlayer';

export type AudioPlaybackContextState = {
  tryingToPlay: boolean,
  loadError: boolean,
  canPlay: boolean,
  progress: number,
  played: boolean,
  onSeek: number => void,
  viewingMicroparts: boolean,
  microPartIndex: number,
  onMicropartSelect: number => void,
  duration: number,
  onPlayToggle: () => void,
  songID: string,
  toggleMicroparts: () => void,
  getAudioProgress: () => number,
  playing: boolean,
  microparts: Array<[number, number]>,
  onDownloadMicropart: number => void,
  audio: any,
  audioHandler: any,
};

export const useSongSlugQuery = () => {
  return useQueryParam('slug', StringParam);
};

export const GlobalAudioPlaybackContext = React.createContext<any>();

export const useGlobalAudioPlaybackContext = (): AudioPlaybackContextState => {
  return useContext(GlobalAudioPlaybackContext);
};

type Props = {
  children: any,
  song: AlgoliaSongMdl,
  autoplay?: boolean,
  autoload?: boolean,
};

const GlobalAudioPlayback = ({ children, song, autoplay = false, autoload = false }: Props) => {
  let unmounted = false;

  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const downloadContext = useDownloadContext();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const { showSubscriptionRequired } = useModalsContext();

  const songID = getSongID(song);
  const microparts = getParsedMicroparts(song);
  const [duration, setDuration] = useState(getSongDuration(song));
  const [canPlay, setCanPlay] = useState(false);
  const [tryingToPlay, setTryingToPlay] = useState(autoplay && !canPlay);
  const [progress, setProgress] = useState(0);
  const [microPartIndex, setMicroPartIndex] = useState(0);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const { setViewingMicroparts, viewingMicroparts } = useMicroPartsContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const { muted, handleMute, volume, handleVolumeChange } = useKeyboardShortcutContext();

  const nextSongEnabled = useRecoilValue(nextSongEnabledSelector);
  const songStartTime = useRecoilValue(songStartTimeAtom);
  const activeGlobalPlayback = useRecoilValue(activeGlobalPlaybackAtom);
  const globalPlayConfiguration = useRecoilValue(globalPlayConfigurationAtom);
  const {
    nextSong,
    handleUpdateSongProgress,
    handleResetStartTime,
    handleUpdateGlobalPlayingState,
    playGlobalPlayback,
  } = useGlobalPlayerContext();

  const [audio, audioHandler, initialized] = useGlobalSongAudioPlayback(
    songID,
    getSongStreamUrl(song),
    microparts,
    duration,
    autoplay,
    autoload,
    onPlay,
    onPause,
    onStop,
    true,
    () => {},
    handleLooping,
    () => {
      return 'mainResults';
    }
  );

  const handleSetTryingToPlay = () => {
    if (!canPlay) {
      setTryingToPlay(true);
    }
  };

  const onPlay = () => {
    setPlayed(true);
    setPlaying(true);
    setCanPlay(true);
    setTryingToPlay(false);
  };

  const onPause = () => {
    setPlaying(false);
    setTryingToPlay(false);
  };

  const onStop = () => {
    setPlaying(false);
    setTryingToPlay(false);
    setProgress(0);
  };

  const handleSetProgress = (percent: number) => {
    const newTime = Math.floor(percent * duration);
    audioHandler.seek(newTime, null, true);
    setProgress(newTime);
  };

  const handleLooping = () => {
    if (nextSongEnabled) {
      handleSetProgress(0);
      handleResetStartTime();
      nextSong();
    } else {
      audioHandler.stop();
    }
  };

  const handleDownloadMicropart = (index: number): Promise<any> => {
    const downloadURL = getSongMicropartDownloadURL(song, index);

    if (isAuthenticated) {
      if (!hasSubscription) {
        showSubscriptionRequired();
        return Promise.resolve();
      }

      if (!USER_PERMISSIONS[userRole].canDownloadMicroparts) {
        console.log('Sorry you are not allowed to download microparts');
        return Promise.reject();
      }
      return firebaseApiHandler
        .downloadSong(songID, downloadURL, downloadContext)
        .finally(() => {});
    }
    showSignUp();
    return Promise.reject();
  };

  const handlePlay = () => {
    handleSetTryingToPlay();
    if (audio) {
      audioHandler.play();
    }
  };

  const handleCanPlay = () => {
    if (unmounted) return;
    setCanPlay(true);
    if (audio) {
      const audioDuration = audio.duration();
      if (audioDuration) {
        setDuration(audioDuration);
      }
      if (songStartTime !== 0) {
        onPlay();
        playGlobalPlayback();
        handleUpdateGlobalPlayingState(true, false, loadError);
        handleSeek(songStartTime, () => {}, true);
      }
    }
  };

  const handleListen = () => {
    if (unmounted) return;
    if (audio) {
      const currentTime = audioHandler.getProgress();

      if (typeof currentTime !== 'number') {
        setTimeout(() => {
          handleListen();
        }, 10);
        return;
      }
      setProgress(currentTime);
      handleUpdateSongProgress(currentTime);
    }
  };

  const handlePause = () => {
    setTryingToPlay(false);
    if (audio) {
      audioHandler.pause();
    }
  };

  const handleStop = () => {
    setTryingToPlay(false);
    if (audio) {
      audioHandler.stop();
    }
  };

  const getAudioProgress = () => {
    return progress;
  };

  const handlePlayToggle = event => {
    if (event) {
      event.preventDefault();
    }
    if (playing) {
      handlePause();
    } else {
      if (!activeGlobalPlayback) {
        playGlobalPlayback();
      }
      handlePlay();
    }
  };

  const handleSeek = (percent: number, callback = () => {}, overrideCanPlay = false) => {
    if (unmounted) return;
    if (!canPlay && !overrideCanPlay) return;
    if (!audio) return;

    const newTime = Math.floor(percent * duration);
    if (!activeGlobalPlayback) {
      playGlobalPlayback();
    }
    audioHandler.seek(newTime, null, true, overrideCanPlay);
    setProgress(newTime);

    if (callback) {
      setTimeout(() => {
        callback();
      }, 1000);
    }
  };

  const handleFastForward = () => {
    if (unmounted) return;
    if (!canPlay) return;
    if (!audio) return;

    const fastForwardsProgress = audioHandler.getProgress() + 10;
    const newTime = Math.min(fastForwardsProgress, duration);
    audioHandler.seek(newTime, null, true);
    setProgress(newTime);
  };

  const handleFastBackward = () => {
    if (unmounted) return;
    if (!canPlay) return;
    if (!audio) return;

    const fastBackwardProgress = audioHandler.getProgress() - 10;
    const newTime = Math.max(fastBackwardProgress, 0);
    audioHandler.seek(newTime, null, true);
    setProgress(newTime);
  };

  const handleToggleMicroparts = () => {
    const nowViewingMicroparts = !viewingMicroparts;
    setViewingMicroparts(nowViewingMicroparts);

    if (nowViewingMicroparts) {
      const rawProgress = progress / duration;
      const currentMicroPartIndex = findMicropartIndex(rawProgress, microparts);
      setMicroPartIndex(currentMicroPartIndex);
      audioHandler.switchToMicroparts(currentMicroPartIndex);
    } else {
      audioHandler.switchToFullSong();
    }
  };

  const handleSelectMicropart = (index: number) => {
    if (viewingMicroparts && index === microPartIndex) {
      return;
    }
    audioHandler.playMicropart(index);
    setMicroPartIndex(index);
  };

  const handlePlayError = (id, error) => {
    console.log('play error', error);
    setTryingToPlay(false);
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureMessage('Something went wrong when playing audio');
    Sentry.captureException(error);
    // todo - handle
  };

  const handleLoadError = (id, error) => {
    console.log('load error', error);
    setTryingToPlay(false);
    setLoadError(true);
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureMessage('Something went wrong when loading audio');
    Sentry.captureException(error);
    // todo - handle
  };

  const muteSongs = () => {
    Howler.mute(!muted);
    handleMute();
  };

  const changeVolume = (isIncrease: boolean) => {
    let newVolume = 1;
    if (isIncrease) {
      newVolume = Math.min(volume + 0.1, 1);
    } else {
      newVolume = Math.max(volume - 0.1, 0);
    }
    Howler.volume(newVolume);
    handleVolumeChange(newVolume);
  };

  const handleVolumeIncrease = () => {
    changeVolume(true);
  };

  const handleVolumeDecrease = () => {
    changeVolume(false);
  };

  const keyboardShortcutHandlers = (() => {
    return {
      decrease_volume: handleVolumeDecrease,
      increase_volume: handleVolumeIncrease,
      mute: muteSongs,
      play_pause: handlePlayToggle,
      fast_forward: handleFastForward,
      fast_backward: handleFastBackward,
    };
  })();

  useEffect(() => {
    if (audioHandler && audioHandler !== undefined) {
      /**
       * /src/song/components/GlobalSongPlayer/components/player/player.jsのonPlayCallback、onPauseCallback、onStopCallback、handleLoopingを定義
       */
      audioHandler.onPlayCallback = onPlay;
      audioHandler.onPauseCallback = onPause;
      audioHandler.onStopCallback = onStop;
      audioHandler.handleLooping = handleLooping;
    }
  }, [initialized]);

  useEffect(() => {
    if (!initialized) return;
    return () => {
      unmounted = true;
    };
  }, [songID, initialized]);

  useEffect(() => {
    if (!initialized) return;
    handlePlay();
  }, [songID, initialized]);

  useEffect(() => {
    if (!initialized) return;
    analyticsMixpanelPlayTrack(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  }, [songID, initialized]);

  useEffect(() => {
    if (!initialized) return;
    let listening;
    handleUpdateGlobalPlayingState(playing, tryingToPlay, loadError);
    handleListen();
    if (playing) {
      listening = setInterval(handleListen, 250);
    }
    return () => {
      if (listening) {
        clearInterval(listening);
      }
    };
  }, [playing, initialized]);

  useEffect(() => {
    if (!initialized) return;
    handleUpdateGlobalPlayingState(playing, tryingToPlay, loadError);
  }, [playing, tryingToPlay, loadError, initialized]);

  useEffect(() => {
    if (initialized) return;
    console.log('setting up audio...');
    if (audio) {
      audio.once('load', handleCanPlay);
      audio.once('playerror', handlePlayError);
      audio.once('loaderror', handleLoadError);
    }
    return () => {
      if (audio) {
        console.log(`deregistering audio...`);
        audio.off();
      }
    };
  }, [!!audio]);

  useEffect(() => {
    if (playing || !activeGlobalPlayback) {
      handleSeek(songStartTime);
    }
    if (songStartTime) {
      playGlobalPlayback();
    }
  }, [songStartTime]);

  useEffect(() => {
    if (playing && !globalPlayConfiguration) {
      handlePause();
    }

    if (!playing && globalPlayConfiguration) {
      handlePlay();
    }
  }, [globalPlayConfiguration]);

  useEffect(() => {
    if (!activeGlobalPlayback) {
      handlePause();
    } else {
      handlePlay();
    }
  }, [activeGlobalPlayback]);

  return (
    <GlobalAudioPlaybackContext.Provider
      value={{
        tryingToPlay,
        loadError,
        canPlay,
        progress,
        played,
        onSeek: handleSeek,
        viewingMicroparts,
        microPartIndex,
        onMicropartSelect: handleSelectMicropart,
        duration,
        onPlayToggle: handlePlayToggle,
        songID,
        toggleMicroparts: handleToggleMicroparts,
        getAudioProgress,
        playing,
        microparts,
        onDownloadMicropart: handleDownloadMicropart,
        audio,
        audioHandler,
      }}
    >
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      {children}
    </GlobalAudioPlaybackContext.Provider>
  );
};

export default GlobalAudioPlayback;
