import {
  getSongArtist,
  getSongArtistSlug,
  getSongBPM,
  getSongEditStyle,
  getSongEnergy,
  getSongFamily,
  getSongGenres,
  getSongID,
  getSongInstruments,
  getSongLocation,
  getSongMixType,
  getSongMood,
  getSongMovement,
  getSongShareSlug,
  getSongVideoTheme,
} from '../api/algolia/song';
import {
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
  getGroupedBooleanFilters,
} from '../api/algolia/data';
import { useSong } from '../song/components/SongContextWrapper/SongContextWrapper';
import { PATHS, ROUTES } from '../routing/routes';
import { SUBSCRIPTIONS, INTERVAL_MONTHLY_DURATION } from '../user/subscriptions/data';
import type { SignUpSourceData } from '../auth/components/AuthWrapper/AuthWrapper';
import { formatSeconds } from '../utils/time';
import { capitalize } from '../utils/text';
import {
  FILTER_TYPES,
  FILTER_MENU_TYPES,
} from '../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import { isGroupApplied } from '../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/components/FilterBooleanMenu/FilterBooleanMenu';
import { base64DecodeURLValue } from '../utils/encode';
import {
  getMagicQueryReplacementKeyword,
  getOppositeSyntax,
  getMagicQueryKeyword,
} from '../api/algolia/magicKeywords';
import { LOCALES } from '../locales';
import {
  sendMoEngageEvent,
  analyticsMoEngageCreateAccount,
  updateMoEngageCustomUserProps,
} from './moEngage';

// New Mixpanel implementation
const MIXPANEL_CATEGORIES = {
  account: 'Account',
  find_music: 'Find Music',
  general: 'General',
  track_actions: 'Track Actions',
  custom_orders: 'Custom Orders',
};

type MixpanelExtraPageVisitProperties = {
  referralCode?: string,
  'UTM Medium'?: string,
  'UTM Campaign'?: string,
  'UTM Source'?: string,
};

type MixpanelEventValue = {
  action?: string,
  label?: string,
  value?: number,
};

type AnalyticsMixpanelSongDimensions = {
  'Song ID': string,
  'Track Type': string,
  Family: string,
  Slug: string,
  Mood: string,
  'Video Theme': string,
  'Edit Style': string,
  Movement: string,
  Location: string,
  Energy: string,
  Genre: string,
  Instruments: string,
  BPM: string,
};

export const domainLocaleToMixpanelLocale = (locale: string): string => {
  if (locale === LOCALES.japanese.code) {
    return 'Japanese';
  }
  if (locale === LOCALES.chinese.code) {
    return 'Chinese';
  }

  return 'English';
};

export const getSongMixpanelAnalyticsDimensions = (
  song: AlgoliaSongMdl
): AnalyticsMixpanelSongDimensions => {
  return {
    'Song ID': getSongID(song),
    'Track Type': getSongMixType(song),
    Family: getSongFamily(song),
    Slug: getSongShareSlug(song),
    Mood: getSongMood(song),
    'Video Theme': getSongVideoTheme(song),
    'Edit Style': getSongEditStyle(song),
    Movement: getSongMovement(song),
    Location: getSongLocation(song),
    Energy: getSongEnergy(song),
    Genre: getSongGenres(song),
    Instruments: getSongInstruments(song),
    BPM: getSongBPM(song),
    'Song Artist': getSongArtistSlug(song),
  };
};

export const isKeywordAdvancedSyntaxInstance = (keyword: string): boolean => {
  const firstCharacter = keyword[0];
  return firstCharacter === '-';
};

export const doesKeywordListContainAdvancedSyntax = (keywordList: Array<string>): boolean => {
  let keywordListContainsAdvancedSyntax = false;
  keywordList.forEach(keyword => {
    const doesKeywordContainAdvancedSyntax = isKeywordAdvancedSyntaxInstance(keyword);
    if (doesKeywordContainAdvancedSyntax) {
      keywordListContainsAdvancedSyntax = true;
      return true;
    }
  });

  return keywordListContainsAdvancedSyntax;
};

export const useMixpanelSongAnalyticsDimensions = (): AnalyticsSongDimensions => {
  const song = useSong();
  return getSongMixpanelAnalyticsDimensions(song);
};

const planKeyToPlanName = (planKey: string): string => {
  if (SUBSCRIPTIONS.creator.plan_codes.includes(planKey)) {
    return 'Creator';
  }

  if (SUBSCRIPTIONS.creatorPro.plan_codes.includes(planKey)) {
    return 'Creator Pro';
  }

  if (SUBSCRIPTIONS.business.plan_codes.includes(planKey)) {
    return 'Business';
  }

  return 'Creator';
};

const pathToMixpanelPage = (path: string): string => {
  const pathMap = {
    [PATHS.landing]: 'Landing Page',
    [PATHS.find]: 'Find',
    [`/music`]: 'Browse Featured',
    [ROUTES.genres.musicPath]: 'Browse Genres',
    [ROUTES.moods.musicPath]: 'Browse Moods',
    [ROUTES.instruments.musicPath]: 'Browse Instruments',
    [ROUTES.sfx.musicPath]: 'Browse Sound Effects',
    [ROUTES.collections.musicPath]: 'Browse Collections',
    [ROUTES.collection.musicPath]: 'Collection',
    [ROUTES.artists.musicPath]: 'Browse Artists',
    [ROUTES.artist.musicPath]: 'Artist',
    [ROUTES.musicSearch.musicPath]: 'Search Music',
    [ROUTES.similarSearch.musicPath]: 'Similar Search',
    [ROUTES.musicPack.musicPath]: 'Pack',
    [PATHS.bookmarks]: 'Bookmarks',
    [PATHS.bookmarksFilter]: 'Bookmarks Filter',
    [PATHS.results]: 'Results',
    [PATHS.signup]: 'Sign Up',
    [PATHS.signupSubscriptions]: 'Sign Up Subscriptions',
    [PATHS.signupPaypal]: 'Sign Up Paypal',
    [PATHS.signupCompleted]: 'Sign Up Completed Page',
    [PATHS.signin]: 'Sign In',
    [PATHS.error]: 'Error Page',
    [`/404`]: 'Page Not Found',
    [PATHS.directSong]: 'Direct',
    [`/profile`]: 'Profile',
    [PATHS.subscriptions]: 'Profile Subscriptions',
    [PATHS.billing]: 'Profile Billing',
    [PATHS.revenue]: 'Profile Revenue',
    [PATHS.downloads]: 'Download History',
    [PATHS.email]: 'Email Preferences',
    [PATHS.terms]: 'Terms & Conditions',
    [PATHS.creatorLicense]: 'Creator Music License',
    [PATHS.creatorProLicense]: 'Creator Pro Music License',
    [PATHS.businessLicense]: 'Business Music License',
    [PATHS.enterpriseLicense]: 'Enterprise Music License',
    [PATHS.customTrackLicense]: 'Custom Track License',
    [PATHS.privacy]: 'Privacy Policy',
    [PATHS.referral]: 'Referral',
    [PATHS.chromeExtension]: 'YouTube Extension',
    [PATHS.pricing]: 'Pricing',
    [PATHS.customOrder]: 'Custom Order',
    [PATHS.limitedMediaTrackLicense]: 'Limited Media Track License',
    [PATHS.allMediaTrackLicense]: 'All Media Track License',
    [PATHS.exclusiveRightsTrackLicense]: 'Exclusive Rights Track License',
  };

  return pathMap[path];
};

const planIntervalToPlanBilling = (interval: string | null): string => {
  const intervalMap = {
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    biannual: 'Biannual',
    annual: 'Annual',
    free: 'Free',
  };

  if (!interval) {
    return intervalMap.free;
  }

  return intervalMap[interval];
};

const locationToMixpanelLocation = (location: string): string => {
  const locationMap = {
    search: 'Find',
    bookmarks: 'Bookmark',
    downloads: 'Download History',
    direct: 'Direct',
    results: 'Results',
    collections: 'Curated Collections',
    artist: 'Artist Profile',
    collectionTag: 'Collection Tag',
    related_collections: 'Related Collections',
    pack_collections: 'Pack Collections',
    artist_collections: 'Artist Collections',
    collectionResults: 'Collection Results',
    recentCollections: 'Recent Collections',
    similar: 'Similar Search',
    global: 'Global Player',
    featuredPage: 'Featured Page',
    genresPage: 'Genres Page',
    moodsPage: 'Moods Page',
    instrumentsPage: 'Instruments Page',
    sfxPage: 'Sound Effects Page',
    pack: 'Pack Page',
  };

  if (!location) {
    return locationMap.search;
  }
  return locationMap[location];
};

export const userRoleToMixpanelRole = (userRole: string): string => {
  const userRoleMap = {
    enterpriseUser: 'Enterprise',
    businessUser: 'Business',
    creatorProUser: 'Creator Pro',
    creatorUser: 'Creator',
    noSubscriptionsUser: 'No Subscriptions',
    guestUser: 'Guest',
  };

  if (!userRole) {
    return userRoleMap.creatorUser;
  }

  return userRoleMap[userRole];
};

const getMixpanelFilterCategory = (filterCategory: string) => {
  const filterMap = {
    'audio.duration': 'Length',
    bpm: 'BPM',
    'keywords.manual.edit-style': 'Edit Style',
    'keywords.manual.energy': 'Energy',
    'keywords.manual.genre': 'Genre',
    'keywords.manual.instruments': 'Instruments',
    'keywords.manual.location': 'Location',
    'keywords.manual.mood': 'Mood',
    'keywords.manual.movement': 'Movement',
    'keywords.manual.video-theme': 'Video Theme',
    'keywords.manual.type': 'SFX Type',
    'keywords.manual.feature': 'SFX Feature',
    isPart: 'Type',
    mixType: 'Type',
  };
  return filterMap[filterCategory];
};

export const getMixpanelBooleanCategoryValues = (booleanCategory: string, value: string) => {
  const booleanValuesMap = {
    isPart: {
      true: 'Parts',
      false: 'Full Mixes',
    },
  };
  return booleanValuesMap[booleanCategory][value];
};

export const getMixpanelRangeValue = (
  filterCategory: string,
  minValue: number,
  maxValue: number
): string => {
  if (filterCategory === 'audio.duration') {
    return `${formatSeconds(minValue / 1000)}-${formatSeconds(maxValue / 1000)}`;
  }
  return `${minValue}-${maxValue}`;
};

const formatMixpanelRangeMaxMin = (filterCategory: string, value: number) => {
  if (filterCategory === 'audio.duration') {
    return Math.round(value / 1000);
  }
  return value;
};

const getMixpanelSearchEventOrigin = (origin: string): string => {
  const originMap = {
    search: 'Find',
    tagSearch: 'Search Tag',
    collectionTag: 'Collection Tag',
    results: 'Results',
    filters: 'Filters',
    removeKeyword: 'Remove Keyword',
    extension: 'YouTube Extension',
    similar: 'Similar Songs',
    browse: 'Browse',
  };

  if (!origin) {
    return originMap.search;
  }

  return originMap[origin];
};

const getMixpanelActionEventOrigin = (origin: string): string => {
  const originMap = {
    search: 'Search',
    results: 'Search',
    relatedCollection: 'Related Collection',
    packCollection: 'Pack Collection',
    expandedCollection: 'Expanded Collection',
    featuredCollection: 'Collection Carousel',
    newestCollection: 'Newest Collections',
    allCollections: 'All Collections',
    collection: 'Collection',
    collectionResults: 'Collection Results',
    recentCollections: 'Recent Collections',
    artistCollection: ' Artist Collection',
    featuredPage: 'Featured Page',
    featuredArtist: 'Featured Artist',
    genresPage: 'Genres Page',
    moodsPage: 'Moods Page',
    instrumentsPage: 'Instruments Page',
    sfxPage: 'Sound Effects Page',
    bookmarks: 'Bookmarks',
    savedCollections: 'Bookmarked Collections',
    downloads: 'Download History',
    pack: 'Pack Page',
  };

  if (!origin) {
    return originMap.search;
  }

  return originMap[origin];
};

const getMixpanelConversionSource = (source: string): string => {
  const upgradeMap = {
    subscriptions: 'Subscriptions',
    download: 'Download Song',
    download_limit: 'Download Limit',
    download_micropart: 'Download Loop',
    filters_type: 'Filters - Type',
    sorting_popularity: 'Sorting - Popularity',
    similar_search: 'Similar Search',
    custom_order: 'Custom Order',
  };

  if (!source) {
    return upgradeMap.subscriptions;
  }

  return upgradeMap[source];
};

const getTrackTypelabel = (trackType: string): string => {
  const trackTypeMap = {
    'copyright-limited-media': 'Standard Track - Limited Media',
    'copyright-all-media': 'Standard Track - All Media',
    'exclusive-ownership': 'Exclusive Rights Track',
  };

  if (!trackType) {
    return trackTypeMap['copyright-limited-media'];
  }

  return trackTypeMap[trackType];
};

const MIXTYPE_MAP = {
  fullMixes: ['Full Mix', 'フルミックス'],
  allParts: ['All Parts', 'すべて'],
  karaoke: ['Karaoke', 'カラオケ'],
  drumBass: ['Drum and Bass', 'ドラム＆ベース'],
  melody: ['Melody', 'メロディ'],
  harmony: ['Harmony', 'ハーモニー'],
  bass: ['Bass', 'ベース'],
  rhythm: ['Rhythm', 'リズム'],
};

const transformLocaliseFilterLabel = (localisedLabel: string) => {
  if (MIXTYPE_MAP.fullMixes.includes(localisedLabel)) {
    return 'Full Mixes';
  }
  if (MIXTYPE_MAP.allParts.includes(localisedLabel)) {
    return 'All Parts';
  }
  if (MIXTYPE_MAP.karaoke.includes(localisedLabel)) {
    return 'Karaoke';
  }
  if (MIXTYPE_MAP.drumBass.includes(localisedLabel)) {
    return 'Drum and Bass';
  }
  if (MIXTYPE_MAP.melody.includes(localisedLabel)) {
    return 'Melody';
  }
  if (MIXTYPE_MAP.harmony.includes(localisedLabel)) {
    return 'Harmony';
  }
  if (MIXTYPE_MAP.bass.includes(localisedLabel)) {
    return 'Bass';
  }
  if (MIXTYPE_MAP.rhythm.includes(localisedLabel)) {
    return 'Rhythm';
  }

  return capitalize(localisedLabel);
};

const getAppliedFiltersCount = (appliedFilters: any): number => {
  let count = 0;

  if (!Object.keys(appliedFilters).length) {
    return 0;
  }

  const selectFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect
  );
  const rangeFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange
  );
  const booleanFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean
  );

  selectFilters.forEach(selectFilter => {
    count =
      count +
      getAppliedFiltersInclusiveList(appliedFilters, selectFilter).length +
      getAppliedFiltersExclusiveList(appliedFilters, selectFilter).length;
  });

  rangeFilters.forEach(rangeFilter => {
    if (appliedFilters[rangeFilter].min && appliedFilters[rangeFilter].max) {
      count += 1;
    }
  });

  booleanFilters.forEach(booleanFilter => {
    if (appliedFilters[booleanFilter]) {
      count += 1;
    }
  });

  return count;
};

const getMixpanelFilterValues = (appliedFilters: any, locale: string): Array<string> => {
  const filterValues = [];

  if (!Object.keys(appliedFilters).length) {
    return [];
  }

  const selectFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect
  );
  const rangeFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange
  );
  const booleanFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean
  );

  selectFilters.forEach(selectFilter => {
    const selectCategory = getMixpanelFilterCategory(selectFilter);
    const inclusiveList = getAppliedFiltersInclusiveList(appliedFilters, selectFilter);
    const exclusiveList = getAppliedFiltersExclusiveList(appliedFilters, selectFilter);

    inclusiveList.forEach(inclusiveFilter => {
      filterValues.push(`${selectCategory}: +${capitalize(inclusiveFilter)}`);
    });

    exclusiveList.forEach(exclusiveFilter => {
      filterValues.push(`${selectCategory}: -${capitalize(exclusiveFilter)}`);
    });
  });

  rangeFilters.forEach(rangeFilter => {
    const minValue = appliedFilters[rangeFilter].min;
    const maxValue = appliedFilters[rangeFilter].max;
    if (minValue && maxValue) {
      const rangeCategory = getMixpanelFilterCategory(rangeFilter);
      const rangeValue = getMixpanelRangeValue(rangeFilter, minValue, maxValue);
      filterValues.push(`${rangeCategory}: +${rangeValue}`);
    }
  });

  booleanFilters.forEach(booleanFilter => {
    if (appliedFilters[booleanFilter]) {
      const booleanCategory = getMixpanelFilterCategory(booleanFilter);
      const groupedFiltersList = getGroupedBooleanFilters(locale, booleanFilter);
      const groupLabel = Object.keys(groupedFiltersList)[0];
      const groupedValues = groupedFiltersList[groupLabel];
      const inclusiveList = getAppliedFiltersInclusiveList(appliedFilters, booleanFilter);
      const exclusiveList = getAppliedFiltersExclusiveList(appliedFilters, booleanFilter);

      const isGroupIncluded = isGroupApplied(inclusiveList, groupedValues);
      const isGroupExcluded = isGroupApplied(exclusiveList, groupedValues);

      const groupedIncludedFilters = isGroupIncluded
        ? inclusiveList.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
        : inclusiveList;
      const groupedExcludedFilters = isGroupExcluded
        ? exclusiveList.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
        : exclusiveList;

      groupedIncludedFilters.forEach(inclusiveFilter => {
        filterValues.push(`${booleanCategory}: +${transformLocaliseFilterLabel(inclusiveFilter)}`);
      });

      groupedExcludedFilters.forEach(exclusiveFilter => {
        filterValues.push(`${booleanCategory}: -${transformLocaliseFilterLabel(exclusiveFilter)}`);
      });
    }
  });

  return filterValues;
};

const getMixpanelFilterCombinationUsed = (appliedFilters: any): Array<string> => {
  const filtersUsed = [];

  if (!Object.keys(appliedFilters).length) {
    return '';
  }

  const selectFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect
  );
  const rangeFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange
  );
  const booleanFilters = Object.keys(appliedFilters).filter(
    appliedFilter => FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean
  );

  selectFilters.forEach(selectFilter => {
    const selectCategory = getMixpanelFilterCategory(selectFilter);
    const inclusiveList = [].concat(getAppliedFiltersInclusiveList(appliedFilters, selectFilter));
    const sortedInclusive = `${inclusiveList.length !== 0 ? '+' : ''}${inclusiveList
      .sort()
      .join(', +')}`;

    const exclusiveList = [].concat(getAppliedFiltersExclusiveList(appliedFilters, selectFilter));
    const sortedExclusive = `${
      inclusiveList.length !== 0 && exclusiveList.length !== 0 ? ', ' : ''
    }${exclusiveList.length !== 0 ? '-' : ''}${exclusiveList.sort().join(', -')}`;

    const filterCategoryValue = `${selectCategory}: ${sortedInclusive}${sortedExclusive}`;

    filtersUsed.push(filterCategoryValue);
  });

  rangeFilters.forEach(rangeFilter => {
    const minValue = appliedFilters[rangeFilter].min;
    const maxValue = appliedFilters[rangeFilter].max;
    if (minValue && maxValue) {
      const rangeCategory = getMixpanelFilterCategory(rangeFilter);
      const rangeValue = getMixpanelRangeValue(rangeFilter, minValue, maxValue);
      filtersUsed.push(`${rangeCategory}: +${rangeValue}`);
    }
  });

  booleanFilters.forEach(booleanFilter => {
    if (appliedFilters[booleanFilter]) {
      const booleanCategory = getMixpanelFilterCategory(booleanFilter);
      const inclusiveList = getAppliedFiltersInclusiveList(appliedFilters, booleanFilter);
      const sortedInclusive = `${inclusiveList.length !== 0 ? '+' : ''}${inclusiveList
        .sort()
        .join(', +')}`;

      const filterCategoryValue = `${booleanCategory}: ${sortedInclusive}`;

      filtersUsed.push(filterCategoryValue);
    }
  });
  const sortedFiltersUsed = [].concat(filtersUsed).sort();
  return sortedFiltersUsed.join('; ');
};

export const getMixpanelPartsBreakdown = (allParts: Array<AlgoliaSongMdl> | null) => {
  const countBreakdown = {
    '# of Karaoke': 0,
    '# of Drum and Bass': 0,
    '# of Melody': 0,
    '# of Harmony': 0,
    '# of Bass': 0,
    '# of Rhythm': 0,
  };

  if (allParts === null) {
    return countBreakdown;
  }

  allParts.forEach(part => {
    if (MIXTYPE_MAP.karaoke.includes(getSongMixType(part))) {
      countBreakdown['# of Karaoke'] += 1;
    } else if (MIXTYPE_MAP.drumBass.includes(getSongMixType(part))) {
      countBreakdown['# of Drum and Bass'] += 1;
    } else if (MIXTYPE_MAP.melody.includes(getSongMixType(part))) {
      countBreakdown['# of Melody'] += 1;
    } else if (MIXTYPE_MAP.harmony.includes(getSongMixType(part))) {
      countBreakdown['# of Harmony'] += 1;
    } else if (MIXTYPE_MAP.bass.includes(getSongMixType(part))) {
      countBreakdown['# of Bass'] += 1;
    } else if (MIXTYPE_MAP.rhythm.includes(getSongMixType(part))) {
      countBreakdown['# of Rhythm'] += 1;
    }
  });

  return countBreakdown;
};

export const getKeywordType = (keywordType: string) => {
  const KEYWORD_TYPE_MAP = {
    regular: 'Regular',
    magic: 'Magic Keyword',
    youtube: 'YouTube Keyword',
  };

  if (!keywordType) {
    return KEYWORD_TYPE_MAP.regular;
  }
  return KEYWORD_TYPE_MAP[keywordType];
};

export const getMixpanelCombinedKeywordsList = (
  keywordsQuery: Array<string>,
  magicKeywordsQuery: Array<string>,
  youtubeKeywordsQuery: Array<string>
): Array<string> => {
  const magicKeywordReplacements = magicKeywordsQuery.map(magicQuery => {
    return getMagicQueryReplacementKeyword(magicQuery);
  });

  const magicKeywords = magicKeywordsQuery.map(magicQuery => {
    return getMagicQueryKeyword(magicQuery);
  });

  const filteredYouTubeKeywords = youtubeKeywordsQuery
    .map(youtubeKeyword => base64DecodeURLValue(youtubeKeyword, true))
    .filter(decodedYouTubeKeyword => {
      const oppositeSyntax = getOppositeSyntax(decodedYouTubeKeyword);
      return (
        !magicKeywordReplacements.includes(decodedYouTubeKeyword) ||
        !magicKeywordReplacements.includes(oppositeSyntax) ||
        !keywordsQuery.includes(decodedYouTubeKeyword) ||
        !keywordsQuery.includes(oppositeSyntax)
      );
    });

  const combinedKeywords = []
    .concat(keywordsQuery)
    .concat(magicKeywords)
    .concat(filteredYouTubeKeywords);

  const combinedKeywordsSet = new Set(combinedKeywords);
  const uniqueCombinedKeywords = [...combinedKeywordsSet];
  return uniqueCombinedKeywords;
};

export const getFreeTrialChangeType = (
  newPlanKey,
  previousPlanKey,
  newInterval,
  previousInterval
): string => {
  if (newPlanKey === previousPlanKey && newInterval === previousInterval) {
    return 'End Early';
  }

  if (newPlanKey === previousPlanKey && newInterval !== previousInterval) {
    const isIntervalUpgrade =
      INTERVAL_MONTHLY_DURATION[newInterval] > INTERVAL_MONTHLY_DURATION[previousInterval];

    if (isIntervalUpgrade) {
      return 'Upgrade';
    }
    return 'Downgrade';
  }

  if (
    newPlanKey !== previousPlanKey &&
    SUBSCRIPTIONS.creatorPro.plan_codes.includes(previousPlanKey) &&
    SUBSCRIPTIONS.business.plan_codes.includes(newPlanKey)
  ) {
    return 'Upgrade';
  }
  return 'Downgrade';
};

export const getDownloadData = (downloadData: any) => {
  const dataToSend = {};

  if (downloadData.magicKeywords.length > 0) {
    const magicKeywordsSet = new Set(
      downloadData.magicKeywords.map(magicKeyword => getMagicQueryKeyword(magicKeyword))
    );
    const uniqueMagicKeywords = [...magicKeywordsSet];
    dataToSend['Magic Keywords Used'] = uniqueMagicKeywords;
  }

  if (downloadData.youtubeKeywords.length > 0) {
    dataToSend['YouTube Keywords Used'] = downloadData.youtubeKeywords
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  if (downloadData.curatedCollection) {
    dataToSend['Curated Collection ID'] = downloadData.curatedCollection;
  }

  if (downloadData.artistProfile) {
    dataToSend['Artist Slug'] = downloadData.artistProfile;
  }

  if (downloadData.sectionOrigin) {
    dataToSend['Action Origin'] = getMixpanelActionEventOrigin(downloadData.sectionOrigin);
  }

  if (downloadData.relatedCollection) {
    dataToSend['Related Collection Origin'] = downloadData.relatedCollection;
  }

  return dataToSend;
};

// Identifying and setting user data

export const mixpanelIdentifyUser = (mixpanel: any, userId: string) => {
  mixpanel.identify(userId);
};

export const mixpanelAliasUserId = (mixpanel: any, userId: string) => {
  mixpanel.alias(userId);
};

export const mixpanelAliasUserEmail = (mixpanel: any, userId: string, userEmail: string) => {
  mixpanel.alias(userEmail, userId);
};

// Update User Date of Last Event
export const updateMixpanelLastEventDate = (
  mixpanel: any,
  moengage: any,
  userId: string,
  keyName: string
) => {
  const updateData = {
    [keyName]: new Date().toISOString(),
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update User Profile
export const updateMixpanelProfile = (mixpanel: any, userId: string, profileData: any) => {
  const updateData = {
    $avatar: profileData.avatar,
    Company: profileData.company,
    Country: profileData.country,
    $first_name: profileData.firstName,
    $last_name: profileData.lastName,
    Phone: profileData.phoneNumber,
    'Last Profile Updated': new Date().toISOString(),
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);
};

export const updateMixpanelProfileCreateAccount = (
  mixpanel: any,
  userId: string,
  email: string,
  firstName: string,
  lastName: string,
  signUpMethod: string,
  locale: string
) => {
  const updateData = {
    // Initial User Data
    'Auth0 ID': userId,
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
    Locale: domainLocaleToMixpanelLocale(locale),
    'Sign Up Method': signUpMethod,
    'Created Date': new Date().toISOString(),
    'Last Profile Updated': new Date().toISOString(),
    'New Music News': true,
    'Evoke Updates': true,

    // Initial Search Data
    '# of Filters Applied': 0,
    '# of Searches': 0,
    '# of Similar Searches': 0,
    'YouTube Extension Used': 'FALSE',
    '# of Previews': 0,

    // Track Data
    '# of Bookmarks': 0,
    '# of Bookmarked Collections': 0,
    '# of Downloads': 0,
    '# of Hidden Tracks': 0,
    '# of Plays': 0,
    '# of Shares': 0,

    // Subscription Data
    '# Subscribed': 0,
    '# YouTube Channels': 0,
    '# of Custom Orders': 0,
    'Active Subscriptions': [],
    'Active Subscription Codes': [],
    'Free Trial Eligiblity': true,
    'On Trial?': false,
    'Subscription Tier': 'No Subscriptions',
    'YouTube Channels': [],
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);
};

// Update Add Subscription Profile
export const updateMixpanelProfileAddSubscription = (
  mixpanel: any,
  moengage: any,
  userId: string,
  subscriptionsData: any
) => {
  const updateData = {
    'Subscription Tier': userRoleToMixpanelRole(subscriptionsData.subscriptionTier),
    'Active Subscriptions': subscriptionsData.subscriptionPlans.map(subscriptionPlan =>
      planKeyToPlanName(subscriptionPlan)
    ),
    'Active Subscription Codes': subscriptionsData.subscriptionPlans,
    '# Subscribed': subscriptionsData.numberSubscribed,
    'Last Subscription Added': new Date().toISOString(),
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Upgrade Subscription Profile
export const updateMixpanelUpgradeSubscriptionProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  subscriptionsData: any
) => {
  const updateData = {
    'Subscription Tier': userRoleToMixpanelRole(subscriptionsData.subscriptionTier),
    'Active Subscriptions': subscriptionsData.subscriptionPlans.map(subscriptionPlan =>
      planKeyToPlanName(subscriptionPlan)
    ),
    'Active Subscription Codes': subscriptionsData.subscriptionPlans,
    'Last Subscription Added': new Date().toISOString(),
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Schedule Downgrade Subscription Profile
export const updateMixpanelScheduledDowngradeProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  nextDowngradeSubscriptionType: string | null,
  nextDowngradeSubscriptionDate: string | null
) => {
  const updateData = {
    'Subscription To Downgrade': nextDowngradeSubscriptionType
      ? planKeyToPlanName(nextDowngradeSubscriptionType)
      : null,
    'Upcoming Downgrade Date': nextDowngradeSubscriptionDate
      ? new Date(nextDowngradeSubscriptionDate).toISOString()
      : null,
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);
};

// Update Downgrade Subscription Profile
export const updateMixpanelDowngradeSubscriptionProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  subscriptionsData: any
) => {
  const updateData = {
    'Subscription Tier': userRoleToMixpanelRole(subscriptionsData.subscriptionTier),
    'Active Subscriptions': subscriptionsData.subscriptionPlans.map(subscriptionPlan =>
      planKeyToPlanName(subscriptionPlan)
    ),
    'Active Subscription Codes': subscriptionsData.subscriptionPlans,
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Paypal Email
export const updateMixpanelPaypalEmail = (
  mixpanel: any,
  moengage: any,
  userId: string,
  paypalEmail: string
) => {
  const updateData = {
    'Paypal Email': paypalEmail,
  };
  mixpanel.people.set(updateData);
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Email Preferences
export const updateMixpanelEmailPreferences = (
  mixpanel: any,
  moengage: any,
  userId: string,
  emailPreferences: any
) => {
  const updateData = {
    'New Music News': emailPreferences.emailSettings.newmusic,
    'Evoke Updates': emailPreferences.emailSettings.updates,
  };
  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update YouTube Connection Profile Data
export const updateMixpanelYouTubeConnectionProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  youtubeChannels: Array<string>
) => {
  const updateData = {
    '# YouTube Channels': youtubeChannels.length,
    'YouTube Channels': youtubeChannels,
    'Last YouTube Added': new Date().toISOString(),
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

export const updateMixpanelYouTubeDisconnectionProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  youtubeChannels: Array<string>
) => {
  const updateData = {
    '# YouTube Channels': youtubeChannels.length,
    'YouTube Channels': youtubeChannels,
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Cancel Subscription Profile Data
export const updateMixpanelCancelSubscriptionProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  subscriptionsData: any
) => {
  const updateData = {
    'Subscription Tier': userRoleToMixpanelRole(subscriptionsData.subscriptionTier),
    'Active Subscriptions': subscriptionsData.subscriptionPlans.map(subscriptionPlan =>
      planKeyToPlanName(subscriptionPlan)
    ),
    'Active Subscription Codes': subscriptionsData.subscriptionPlans,
    '# Subscribed': subscriptionsData.numberSubscribed,
    'Last Cancellation Date': new Date().toISOString(),
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Update Scheduled Cancellation Profile Data
export const updateMixpanelScheduledCancellationProfileData = (
  mixpanel: any,
  moengage: any,
  userId: string,
  nextCancellationSubscriptionType: string | null,
  nextCancellationSubscriptionDate: string | null
) => {
  const updateData = {
    'Subscription To Cancel': nextCancellationSubscriptionType
      ? planKeyToPlanName(nextCancellationSubscriptionType)
      : null,
    'Upcoming Cancellation Date': nextCancellationSubscriptionDate
      ? new Date(nextCancellationSubscriptionDate).toISOString()
      : null,
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);
};

export const updateMixpanelExtensionUsed = (mixpanel: any, moengage: any, userId: string) => {
  const updateData = {
    'YouTube Extension Used': 'TRUE',
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

// Increment Count
export const incrementMixpanelProperty = (mixpanel, propertyName) => {
  mixpanel.people.increment(propertyName);
};

export const updateMixpanelTopKeywordsProfileData = (
  mixpanel: any,
  moengage: any,
  topKeywords: Array<string>,
  userId: string
) => {
  const updateData = {
    'Top Keywords': topKeywords,
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

export const updateMixpanelFreeTrialEligibility = (
  mixpanel: any,
  moengage: any,
  freeTrailEligibility: boolean,
  userId: string
) => {
  const updateData = {
    'Free Trial Eligiblity': freeTrailEligibility,
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);

  updateMoEngageCustomUserProps(moengage, updateData);
};

export const syncMixpanelLocale = (mixpanel: any, userId: string, locale: string) => {
  const updateData = {
    Locale: domainLocaleToMixpanelLocale(locale),
  };

  mixpanel.people.set({ ...updateData });
  mixpanelIdentifyUser(mixpanel, userId);
};

// The 4 functions below represent each of the Mixpanel event category detailed in the Mixpanel specifications ( as well as above in MIXPANEL_CATEGORIES)
export const sendMixpanelPageVisit = (
  mixpanel: any,
  moengage: any,
  path: string,
  extraProperties?: MixpanelExtraPageVisitProperties
) => {
  const updateData = {
    Page: pathToMixpanelPage(path),
    ...extraProperties,
  };
  mixpanel.track('Page Visit', { ...updateData, Category: MIXPANEL_CATEGORIES.general });

  sendMoEngageEvent(moengage, 'Page Visit', updateData);
};

export const sendMixpanelAccountEvent = (
  mixpanel: any,
  eventName: string,
  values: MixpanelEventValue
) => {
  mixpanel.track(eventName, { Category: MIXPANEL_CATEGORIES.account, ...values });
};

export const sendMixpanelFindMusicEvent = (
  mixpanel: any,
  eventName: string,
  values: MixpanelEventValue
) => {
  mixpanel.track(eventName, { Category: MIXPANEL_CATEGORIES.find_music, ...values });
};

export const sendMixpanelTrackActionEvents = (
  mixpanel: any,
  eventName: string,
  values: MixpanelEventValue
) => {
  mixpanel.track(eventName, { Category: MIXPANEL_CATEGORIES.track_actions, ...values });
};

export const sendMixpanelCustomOrdersEvent = (mixpanel: any, eventName: string, values: any) => {
  mixpanel.track(eventName, { Category: MIXPANEL_CATEGORIES.custom_orders, ...values });
};

// Add Keyword
export const analyticsMixpanelAddKeyword = (
  mixpanel: any,
  moengage: any,
  keyword: string,
  keywordType: string,
  replacementKeywords: Array<string>,
  isSuggestedKeyword: boolean
  // isSynonymKeyword: boolean,
  // keywordCategory: string
) => {
  const eventData = {
    Keyword: keyword,
    'Keyword Type': getKeywordType(keywordType),
    'Suggested Keyword': isSuggestedKeyword,
    // 'Synonym Keyword': isSynonymKeyword,
    // 'Keyword Category': keywordCategory,
    'Advanced Syntax': isKeywordAdvancedSyntaxInstance(keyword),
  };
  const eventName = 'Add Keyword';

  if (keywordType === 'magic') {
    eventData['Magic Keyword Replacements'] = replacementKeywords;
  }
  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// Search Keywords
export const analyticsMixpanelSearchKeywords = (
  mixpanel: any,
  moengage: any,
  keywordsSearched: Array<string>,
  magicKeywordsSearched: Array<string>,
  youtubeKeywordsSearched: Array<string>,
  similarSongId: string,
  numberOfAllResults: numnber,
  numberOfFullMixes: number,
  numberOfParts: numnber,
  partsCountBreakdown: any,
  isUpdatedKeywords: boolean,
  origin: string,
  appliedFilters: any,
  subscriptionTier: string,
  userId: string,
  locale: string
) => {
  const sortedKeywords = [].concat(keywordsSearched.map(keyword => keyword.toLowerCase())).sort();
  const dataToSend = {
    'Keywords Searched': keywordsSearched,
    'Keyword Combination Used': sortedKeywords.join(', '),
    '# of Keywords Used': keywordsSearched.length,
    '# of All Results': numberOfAllResults,
    '# of Full Mixes': numberOfFullMixes,
    '# of Parts': numberOfParts,
    ...partsCountBreakdown,
    'Filters Applied': getMixpanelFilterValues(appliedFilters, locale),
    'Filter Combination Used': getMixpanelFilterCombinationUsed(appliedFilters),
    '# of Filters Used': getAppliedFiltersCount(appliedFilters),
    'Advanced Syntax': doesKeywordListContainAdvancedSyntax(keywordsSearched),
    'Updated Keywords': isUpdatedKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    'Search Origin': getMixpanelSearchEventOrigin(origin),
  };
  const eventName = 'Search Keywords';

  if (magicKeywordsSearched.length > 0) {
    dataToSend['Magic Keywords Used'] = magicKeywordsSearched;
  }

  if (youtubeKeywordsSearched.length) {
    dataToSend['YouTube Keywords Used'] = youtubeKeywordsSearched
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  sendMixpanelFindMusicEvent(mixpanel, eventName, dataToSend);
  sendMoEngageEvent(moengage, eventName, dataToSend);

  mixpanel.people.increment('# of Searches');
  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Search');
  if (getMixpanelSearchEventOrigin(origin) === 'YouTube Extension' && userId) {
    updateMixpanelExtensionUsed(mixpanel, moengage, userId);
  }
};

// Inexact Search Results
export const analyticsMixpanelInexactSearch = (
  mixpanel: any,
  moengage: any,
  keywordsSearched: Array<string>
) => {
  const sortedKeywords = [].concat(keywordsSearched).sort();
  const eventName = 'Inexact Search';
  const eventData = {
    'Keywords Searched': sortedKeywords,
    'Keyword Combination Used': sortedKeywords.join(', '),
  };

  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// Remove Keyword
export const analyticsMixpanelRemoveKeyword = (
  mixpanel: any,
  moengage: any,
  keyword: string,
  keywordType: string,
  replacementKeywords: Array<string>
  // isSynonymKeyword: boolean,
  // keywordCategory: string
) => {
  const eventData = {
    Keyword: keyword,
    'Keyword Type': getKeywordType(keywordType),
    // 'Synonym Keyword': isSynonymKeyword,
    // 'Keyword Category': keywordCategory,
  };
  const eventName = 'Remove Keyword';

  if (keywordType === 'magic') {
    eventData['Magic Keyword Replacements'] = replacementKeywords;
  }

  if (keywordType === 'youtube') {
    eventData['YouTube Keyword Replacements'] = replacementKeywords;
  }

  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// New Search
export const analyticsMixpanelNewSearch = (
  mixpanel: any,
  moengage: any
  // numberOfKeywordEdits: number,
  // numberOfResultsViewed: number,
  // searchDuration: number
) => {
  const eventName = 'New Search';
  sendMixpanelFindMusicEvent(mixpanel, eventName, {
    // '# of Keyword Edits': numberOfKeywordEdits,
    // '# of Results Viewed': numberOfResultsViewed,
    // 'Search Duration': searchDuration,
  });
  sendMoEngageEvent(moengage, eventName, {});
};

// Apply Filter

export const analyticsMixpanelApplyFilter = (
  mixpanel: any,
  moengage: any,
  filterCategory: string,
  filterApplied: string | null,
  filterRangeMin: number | null,
  filterRangeMax: number | null,
  keywords: Array<string>,
  magicKeywordsSearched: Array<string>,
  youtubeKeywordsSearched: Array<string>,
  syntax: string,
  isSuggestion: boolean,
  searchTerm: string,
  subscriptionTier: string,
  userId: string
) => {
  const eventName = 'Apply Filter';
  const sortedKeywords = [].concat(keywords.map(keyword => keyword.toLowerCase())).sort();
  const dataToSend = {
    'Filter Category': getMixpanelFilterCategory(filterCategory),
    'Keywords Searched': keywords,
    'Keyword Combination Used': sortedKeywords.join(', '),
    Syntax: capitalize(syntax),
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    'Search Used': isSuggestion,
  };

  if (magicKeywordsSearched.length > 0) {
    dataToSend['Magic Keywords Used'] = magicKeywordsSearched;
  }

  if (youtubeKeywordsSearched.length) {
    dataToSend['YouTube Keywords Used'] = youtubeKeywordsSearched
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  if (filterApplied) {
    dataToSend['Filter Applied'] = transformLocaliseFilterLabel(filterApplied);
  }

  if (filterRangeMin) {
    dataToSend['Filter Range Min'] = formatMixpanelRangeMaxMin(filterCategory, filterRangeMin);
  }

  if (filterRangeMax) {
    dataToSend['Filter Range Max'] = formatMixpanelRangeMaxMin(filterCategory, filterRangeMax);
  }

  if (searchTerm) {
    dataToSend['Search Term'] = capitalize(searchTerm);
  }

  sendMixpanelFindMusicEvent(mixpanel, eventName, dataToSend);
  sendMoEngageEvent(moengage, eventName, dataToSend);

  incrementMixpanelProperty(mixpanel, '# of Filters Applied');
  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Filter Applied');
};

// Apply Tag Filter

export const analyticsMixpanelApplyTagFilter = (
  mixpanel: any,
  moengage: any,
  filterCategory: string,
  filterApplied: string,
  collectionSlug: string
) => {
  const eventName = 'Apply Tag Filter';
  const dataToSend = {
    'Filter Category': getMixpanelFilterCategory(filterCategory),
    Filter: capitalize(filterApplied),
    'Collection Slug': collectionSlug,
  };

  sendMixpanelFindMusicEvent(mixpanel, eventName, {
    ...dataToSend,
  });
  sendMoEngageEvent(moengage, eventName, dataToSend);
};

// Remove Filter

export const analyticsMixpanelRemoveFilter = (
  mixpanel: any,
  moengage: any,
  filterCategory: string,
  filterApplied: string,
  keywords: Array<string>,
  magicKeywordsSearched: Array<string>,
  youtubeKeywordsSearched: Array<string>,
  syntax: string,
  subscriptionTier: string
) => {
  const eventName = 'Remove Filter';
  const dataToSend = {
    'Filter Category': getMixpanelFilterCategory(filterCategory),
    'Filter Removed': transformLocaliseFilterLabel(filterApplied),
    'Keywords Searched': keywords,
    Syntax: capitalize(syntax),
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
  };

  if (magicKeywordsSearched.length > 0) {
    dataToSend['Magic Keywords Used'] = magicKeywordsSearched;
  }

  if (youtubeKeywordsSearched.length) {
    dataToSend['YouTube Keywords Used'] = youtubeKeywordsSearched
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  sendMixpanelFindMusicEvent(mixpanel, eventName, dataToSend);
  sendMoEngageEvent(moengage, eventName, dataToSend);
};

// No Filter Results

export const analyticsMixpanelNoFilterResults = (
  mixpanel: any,
  moengage: any,
  filterCategory: string,
  searchTerm: string
) => {
  const eventName = 'No Filter Results';
  const eventData = {
    'Search Term': searchTerm,
    'Filter Category': getMixpanelFilterCategory(filterCategory),
  };
  sendMixpanelFindMusicEvent(mixpanel, 'No Filter Results', eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

export const analyticsMixpanelYouTubeExtensionSearch = (
  mixpanel: any,
  moengage: any,
  channelName: string,
  videoTitle: string,
  videoTags: Array<string>,
  videoURL: string,
  videoCategory: string,
  resultTags: Array<string>,
  srcubbedTags: Array<string>
) => {
  const eventName = 'YouTube Extension Search';

  const sortedTags = [].concat(videoTags).sort();
  const sortedScrubbedTags = [].concat(srcubbedTags).sort();
  const sortedResultTags = [].concat(resultTags).sort();

  const eventData = {
    'Date Of Search': new Date().toISOString(),
    'Channel Name': channelName,
    'Video Title': videoTitle,
    'Video Tags': sortedTags,
    'Video Category': videoCategory,
    'Video URL': videoURL,
    'Resulting Tags': sortedResultTags,
    'Removed Tags': sortedScrubbedTags,
  };

  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

export const analyticsMixpanelSimilarSongSearch = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  keywords: Array<string>,
  BpmMax: string,
  BpmMin: string,
  userId: string
) => {
  const eventName = 'Search Similar Song';
  const sortedKeywords = [].concat(keywords.map(keyword => keyword.toLowerCase())).sort();
  const eventData = {
    ...dimensions,
    'Keywords Searched': keywords,
    'Keyword Combination Used': sortedKeywords.join(', '),
    'BPM Range': `${BpmMin}-${BpmMax}`,
    'BPM Max': Number(BpmMax),
    'BPM Min': Number(BpmMin),
  };

  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  incrementMixpanelProperty(mixpanel, '# of Similar Searches');
  mixpanelIdentifyUser(mixpanel, userId);
};

export const analyticsMixpanelPreviewSongs = (
  mixpanel: any,
  moengage: any,
  previewType: string,
  previewName: string,
  previewOrigin: string,
  userId: string
) => {
  const eventName = 'Preview Songs';
  const eventData = {
    'Preview Type': previewType,
    'Preview Name': previewName,
    'Preview Origin': locationToMixpanelLocation(previewOrigin),
  };

  sendMixpanelFindMusicEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  incrementMixpanelProperty(mixpanel, '# of Previews');
  mixpanelIdentifyUser(mixpanel, userId);
};

// Account events

// Create Account

export const analyticsMixpanelCreateAccount = (
  mixpanel: any,
  moengage: any,
  email: string,
  firstName: string,
  lastName: string,
  signUpMethod: string,
  signUpSourceData: SignUpSourceData,
  locale: string,
  userId: string
) => {
  const eventData = {
    Email: email,
    'First Name': firstName,
    'Last Name': lastName,
    'Sign Up Method': signUpMethod,
    'Sign Up Source': signUpSourceData.signUpSource ? signUpSourceData.signUpSource : null,
    'Sign Up Campaign': signUpSourceData.signUpCampaign ? signUpSourceData.signUpCampaign : null,
  };
  sendMixpanelAccountEvent(mixpanel, 'Create Account', eventData);
  updateMixpanelProfileCreateAccount(
    mixpanel,
    userId,
    email,
    firstName,
    lastName,
    signUpMethod,
    locale
  );
  analyticsMoEngageCreateAccount(
    moengage,
    userId,
    firstName,
    lastName,
    email,
    signUpMethod,
    locale,
    eventData
  );
};

// Sign In
export const analyticsMixpanelAccountSignIn = (
  mixpanel: any,
  moengage: any,
  signInMethod: string
) => {
  const eventName = 'Sign In';
  const eventData = { 'Sign In Method': signInMethod };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// Example of Account Event: Add Subscription event
// To do mixpanel: the function below also needs to capture the coupon added if there were any.
export const analyticsMixpanelAccountAddPlan = (
  mixpanel: any,
  moengage: any,
  planKey: string,
  billingCycle: string | null,
  coupons: Array<string>,
  source: string,
  userId: string,
  subscriptionsData: any
) => {
  const eventData = {
    Coupons: coupons,
    'Subscription Type': planKeyToPlanName(planKey),
    'Subscription Code': planKey,
    'Billing Cycle': planIntervalToPlanBilling(billingCycle),
    'Plan Source': getMixpanelConversionSource(source),
  };
  const eventName = 'Add Subscription';
  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
  updateMixpanelProfileAddSubscription(mixpanel, moengage, userId, subscriptionsData);
};

// Upgrade Subscription
export const analyticsMixpanelAccountUpgradePlan = (
  mixpanel: any,
  moengage: any,
  newPlanKey: string,
  previousPlanKey: string,
  newBillingCycle: string,
  previousBillingCycle: string,
  coupons: Array<string>,
  conversionSource: string,
  userId: string,
  subscriptionsData: any
) => {
  const eventName = 'Upgrade Subscription';
  const eventData = {
    'New Subscription': planKeyToPlanName(newPlanKey),
    'New Subscription Code': newPlanKey,
    'Previous Subscription': planKeyToPlanName(previousPlanKey),
    'Previous Subscription Code': previousPlanKey,
    'New Billing Cycle': planIntervalToPlanBilling(newBillingCycle),
    'Previous Billing Cycle': planIntervalToPlanBilling(previousBillingCycle),
    Coupons: coupons,
    'Upgrade Source': getMixpanelConversionSource(conversionSource),
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelUpgradeSubscriptionProfileData(mixpanel, moengage, userId, subscriptionsData);
};

// Scheduled Downgrade
export const analyticsMixpanelAccountScheduleDowngrade = (
  mixpanel: any,
  moengage: any,
  newPlanKey: string,
  previousPlanKey: string,
  newBillingCycle: string,
  previousBillingCycle: string,
  userId: string,
  nextDowngradeSubscriptionType: string | null,
  nextDowngradeSubscriptionDate: string | null
) => {
  const eventName = 'Scheduled Downgrade';
  const eventData = {
    'New Subscription': planKeyToPlanName(newPlanKey),
    'New Subscription Code': newPlanKey,
    'Current Subscription': planKeyToPlanName(previousPlanKey),
    'Current Subscription Code': previousPlanKey,
    'New Billing Cycle': planIntervalToPlanBilling(newBillingCycle),
    'Current Billing Cycle': planIntervalToPlanBilling(previousBillingCycle),
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelScheduledDowngradeProfileData(
    mixpanel,
    moengage,
    userId,
    nextDowngradeSubscriptionType,
    nextDowngradeSubscriptionDate
  );
};

// Undo Downgrade
export const analyticsMixpanelAccountUndoDowngrade = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  userId: string,
  nextDowngradeSubscriptionType: string | null,
  nextDowngradeSubscriptionDate: string | null
) => {
  const eventName = 'Undo Downgrade';
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelScheduledDowngradeProfileData(
    mixpanel,
    moengage,
    userId,
    nextDowngradeSubscriptionType,
    nextDowngradeSubscriptionDate
  );
};

// Downgrade Subscription
export const analyticsMixpanelAccountDowngradePlan = (
  mixpanel: any,
  moengage: any,
  newPlanKey: string,
  previousPlanKey: string,
  newBillingCycle: string,
  previousBillingCycle: string,
  userId: string,
  subscriptionsData: any
) => {
  const eventName = 'Downgrade Subscription';
  const eventData = {
    'New Subscription': planKeyToPlanName(newPlanKey),
    'New Subscription Code': newPlanKey,
    'Previous Subscription': planKeyToPlanName(previousPlanKey),
    'Previous Subscription Code': previousPlanKey,
    'New Billing Cycle': planIntervalToPlanBilling(newBillingCycle),
    'Previous Billing Cycle': planIntervalToPlanBilling(previousBillingCycle),
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelDowngradeSubscriptionProfileData(mixpanel, moengage, userId, subscriptionsData);
};

// Cancel Subscription

export const analyticsMixpanelAccountCancelSubscription = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  userId: string,
  subscriptionsData: any
) => {
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
  };
  const eventName = 'Cancel Subscription';

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelCancelSubscriptionProfileData(mixpanel, moengage, userId, subscriptionsData);
};

// Schedule Cancellation
export const analyticsMixpanelAccountScheduleCancellation = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  subscriptionOnTrial: boolean,
  userId: string,
  nextCancellationSubscriptionType: string | null,
  nextCancellationSubscriptionDate: string | null
) => {
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
    'Subscription On Trial': subscriptionOnTrial,
  };
  const eventName = 'Schedule Cancellation';

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelScheduledCancellationProfileData(
    mixpanel,
    moengage,
    userId,
    nextCancellationSubscriptionType,
    nextCancellationSubscriptionDate
  );
};

// Undo Cancellation
export const analyticsMixpanelAccountUndoCancellation = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  subscriptionOnTrial: boolean,
  userId: string,
  nextCancellationSubscriptionType: string | null,
  nextCancellationSubscriptionDate: string | null
) => {
  const eventName = 'Undo Cancellation';
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
    'Subscription On Trial': subscriptionOnTrial,
  };
  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelScheduledCancellationProfileData(
    mixpanel,
    moengage,
    userId,
    nextCancellationSubscriptionType,
    nextCancellationSubscriptionDate
  );
};

// Add Paypal Email

// Connect YouTube Channel
export const analyticsMixpanelAccountYouTubeConnection = (
  mixpanel: any,
  moengage: any,
  connectionMethod: string,
  subscriptionType: string,
  userId: string,
  youtubeChannels: Array<string>
) => {
  const eventName = 'Connect YouTube Channel';
  const eventData = {
    'Channel Method': connectionMethod,
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
  };
  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelYouTubeConnectionProfileData(mixpanel, moengage, userId, youtubeChannels);
};

// Disconnect YouTube Channel
export const analyticsMixpanelAccountYouTubeDisconnection = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  disconnectionMethod: string,
  userId: string,
  youtubeChannels: Array<string>,
  triggerEvent?: boolean = false
) => {
  if (triggerEvent) {
    const eventName = 'Disconnect YouTube Channel';
    const eventData = {
      'Subscription Type': planKeyToPlanName(subscriptionType),
      'Subscription Code': subscriptionType,
      'Disconnection Method': disconnectionMethod,
    };

    sendMixpanelAccountEvent(mixpanel, eventName, eventData);
    sendMoEngageEvent(moengage, eventName, eventData);
  }
  updateMixpanelYouTubeDisconnectionProfileData(mixpanel, moengage, userId, youtubeChannels);
};

// End Trial Click
export const analyticsMixpanelAccountEndTrialClick = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  isYouTubeConnected: boolean,
  remainingTrialDays: number
) => {
  const eventName = 'End Trial Click';
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
    'YouTube Connected': isYouTubeConnected,
    'Days Remaining on Trial': remainingTrialDays,
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// End Trial
export const analyticsMixpanelAccountEndTrial = (
  mixpanel: any,
  moengage: any,
  subscriptionType: string,
  isYouTubeConnected: boolean,
  remainingTrialDays: number,
  endTrialMethod: string,
  userId: string
) => {
  const eventName = 'End Trial';
  const eventData = {
    'Subscription Type': planKeyToPlanName(subscriptionType),
    'Subscription Code': subscriptionType,
    'YouTube Connected': isYouTubeConnected,
    'Days Remaining on Trial': remainingTrialDays,
    'End Trial Method': endTrialMethod,
  };

  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Trial End Date');
};

// Enterprise Get in Contact
export const analyticsMixpanelEnterpriseContactRequest = (
  mixpanel: any,
  moengage: any,
  email: string,
  firstName: string,
  lastName: string,
  page: string,
  subscriptionTier: string
) => {
  const eventName = 'Enterprise Contact Form Open';
  const eventData = {
    Email: email,
    Name: `${firstName} ${lastName}`,
    Source: page,
  };
  sendMixpanelAccountEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};

// Play Track
export const analyticsMixpanelPlayTrack = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  userId: string
) => {
  const eventName = 'Play Track';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Play');
  incrementMixpanelProperty(mixpanel, '# of Plays');
};

export const analyticsMixpanelPlayMicropart = (
  mixpanel: any,
  moengage: any,
  micropartPosition: string,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  userId: string
) => {
  const eventName = 'Play Loop';
  const dataToSend = {
    'Micropart #': micropartPosition,
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Play');
  incrementMixpanelProperty(mixpanel, '# of Plays');
};

// View Microparts
export const analyticsMixpanelViewMicroparts = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  page: string,
  collectionSlug: string,
  userId: string
) => {
  const eventName = 'View Loops';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);
  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Viewed Microparts');
};

// Example of Track Actions Event: Bookmark Track Event
// To do mixpanel: add the following data: Last Bookmark
export const analyticsMixpanelBookmarkTrack = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  userId: string
) => {
  const eventName = 'Bookmark Track';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, {
    ...data,
  });
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Bookmark');
  incrementMixpanelProperty(mixpanel, '# of Bookmarks');
};

export const analyticsMixpanelHideTrack = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  collectionSlug: string,
  userId: string
) => {
  const eventName = 'Hide Track';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };
  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Hide');
  incrementMixpanelProperty(mixpanel, '# of Hidden Tracks');
};

// Download Track
export const analyticsMixpanelDownloadTrack = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  downloadQuotaReached: boolean | null,
  userId: string
) => {
  const eventName = 'Download Track';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
    'Download Limit Reached': downloadQuotaReached !== null ? downloadQuotaReached : null,
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Download');
  incrementMixpanelProperty(mixpanel, '# of Downloads');
};

// Download Micropart
export const analyticsMixpanelDownloadMicropart = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  userId: string,
  micropartNumber: number
) => {
  const eventName = 'Download Loop';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
    'Loop #': micropartNumber,
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Download');
  incrementMixpanelProperty(mixpanel, '# of Downloads');
};

// Share Track
export const analyticsMixpanelSongShare = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string,
  destination: string,
  startPosition: boolean,
  userId: string
) => {
  const eventName = 'Share Track';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
    Destination: destination,
    'Start Position': startPosition,
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Share');
  incrementMixpanelProperty(mixpanel, '# of Shares');
};

// View Track Details
export const analyticsMixpanelViewTrackDetails = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  downloadData: any,
  subscriptionTier: string
) => {
  const eventName = 'View Track Details';
  const dataToSend = {
    ...dimensions,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);
};

// View Parts
export const analyticsMixpanelViewParts = (
  mixpanel: any,
  moengage: any,
  dimensions: AnalyticsMixpanelSongDimensions,
  keywordsSearched: Array<string>,
  magicKeywordsUsed: Array<string>,
  youtubeKeywordsUsed: Array<string>,
  subscriptionTier: string,
  page: string,
  partTypes: Array<string>
) => {
  const eventName = 'View Pack';
  const formattedPartsIncluded = partTypes.map(part => {
    return transformLocaliseFilterLabel(part);
  });

  const dataToSend = {
    ...dimensions,
    'Keywords Searched': keywordsSearched,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Origin: locationToMixpanelLocation(page),
    'Stems Included': formattedPartsIncluded,
  };

  if (magicKeywordsUsed.length > 0) {
    const magicKeywordsSet = new Set(
      magicKeywordsUsed.map(magicKeyword => getMagicQueryKeyword(magicKeyword))
    );
    const uniqueMagicKeywords = [...magicKeywordsSet];
    dataToSend['Magic Keywords Used'] = uniqueMagicKeywords;
  }

  if (youtubeKeywordsUsed.length) {
    dataToSend['YouTube Keywords Used'] = youtubeKeywordsUsed
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  sendMixpanelTrackActionEvents(mixpanel, eventName, dataToSend);
  sendMoEngageEvent(moengage, eventName, dataToSend);
};

export const analyticsMixpanelNavigatePlaylist = (
  mixpanel: any,
  moengage: any,
  playlistType: string,
  collectionId: string,
  similarSongId: string,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  packShareSlug: string,
  artistSlug: string
) => {
  const eventName = 'Open Playlist';
  const dataToSend = {
    'Playlist Type': playlistType,
  };

  if (collectionId) {
    dataToSend['Playlist Collection Id'] = collectionId;
  }

  if (similarSongId) {
    dataToSend['Playlist Similar Id'] = similarSongId;
  }

  if (packShareSlug) {
    dataToSend['Pack Share Slug'] = packShareSlug;
  }

  if (artistSlug) {
    dataToSend['Artist Slug'] = artistSlug;
  }

  if (keywords.length > 0) {
    dataToSend['Keywords Searched'] = keywords;
  }

  if (magicKeywords.length > 0) {
    const magicKeywordsSet = new Set(
      magicKeywords.map(magicKeyword => getMagicQueryKeyword(magicKeyword))
    );
    const uniqueMagicKeywords = [...magicKeywordsSet];
    dataToSend['Magic Keywords Used'] = uniqueMagicKeywords;
  }

  if (youtubeKeywords.length) {
    dataToSend['YouTube Keywords Used'] = youtubeKeywords
      .map(youtubeKeyword => {
        return base64DecodeURLValue(youtubeKeyword, true).toLowerCase();
      })
      .sort();
  }

  sendMixpanelTrackActionEvents(mixpanel, eventName, dataToSend);
  sendMoEngageEvent(moengage, eventName, dataToSend);
};

export const analyticsMixpanelBookmarkCollection = (
  mixpanel: any,
  moengage: any,
  userId: string,
  collectionSlug: string,
  downloadData: any,
  subscriptionTier: string
) => {
  const eventName = 'Bookmark Collection';
  const dataToSend = {
    'Curated Collection ID': collectionSlug,
    'Keywords Searched': downloadData.mixpanelKeywords,
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    Page: locationToMixpanelLocation(downloadData.location),
  };

  const optionalDownloadData = getDownloadData(downloadData);

  const data = {
    ...dataToSend,
    ...optionalDownloadData,
  };

  sendMixpanelTrackActionEvents(mixpanel, eventName, data);
  sendMoEngageEvent(moengage, eventName, data);

  updateMixpanelLastEventDate(mixpanel, moengage, userId, 'Last Bookmarked Collection');
  incrementMixpanelProperty(mixpanel, '# of Bookmarked Collections');
};

export const analyticsMixpanelOrderTrackStart = (
  mixpanel: any,
  moengage: any,
  trackType: string,
  subscriptionTier: string,
  currencyCode: string
) => {
  const eventName = 'Order Track Start';
  const eventData = {
    'Track Type': getTrackTypelabel(trackType),
    'Subscription Tier': userRoleToMixpanelRole(subscriptionTier),
    'Currency Code': currencyCode,
  };
  sendMixpanelCustomOrdersEvent(mixpanel, eventName, eventData);
  sendMoEngageEvent(moengage, eventName, eventData);
};
