// @flow
import React from 'react';
import { BILLING_CYCLES, getAvailableBillingCycles } from '../../../data';
import { useTranslation } from 'react-i18next';
import styled, { css, cx } from 'react-emotion';
import Button from '../../../../../components/Button/Button';
import { transparentize } from 'polished';
import { darkColor, lightColor } from '../../../../../styles/config/colors';
import { LANDSCAPE_DEVICE, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { getIntervalCycle } from '../../../../../api/firebase/user/subscriptions';
import { isEnterpriseSubscription } from '../../../../../utils/subscriptions';
import { SPLIT_PAYMENT_EN_URL, SPLIT_PAYMENT_URL } from '../../../../../utils/const';
import { useLocale } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';

const BillingCycleButton = styled(Button)`
  margin-top: 5px;
  background-color: ${transparentize(1, '#4d4d4d')};
  color: ${darkColor};
  border: 1px solid ${transparentize(0.9, darkColor)};
  min-width: 170px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 5px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    &:nth-child(odd) {
      order: 1;
    }
    &:nth-child(even) {
      order: 2;
    }
    ${LANDSCAPE_DEVICE} {
      min-width: 150px;
    }
  }
`;

const activeBillingCycleButton = css`
  background-color: #4d4d4d;
  color: ${lightColor};
  ${SMALL_DEVICE_BREAKPOINT} {
    min-width: 120px;
  }
`;

const disabledBillingCycleButton = css`
  background-color: ${transparentize(1, '#4d4d4d')};
  border: 1px solid ${transparentize(0.95, darkColor)};

  & span {
    color: ${transparentize(0.8, darkColor)};
  }
`;

const BillingCyclesNav = ({
  selectedPlan,
  subscriptionPlanKey,
  billingCycle,
  subscriptionInterval,
  availableIntervals,
  selectOption,
  action,
  subscriptionModalType,
}) => {
  const availableBillingCycles = getAvailableBillingCycles(availableIntervals);
  const { t } = useTranslation();
  const locale = useLocale();

  const shouldButtonBeDisabled = (
    selectedPlan,
    subscriptionPlanKey,
    currentSubscriptionInterval,
    intervalButton
  ) => {
    return currentSubscriptionInterval === intervalButton && selectedPlan === subscriptionPlanKey
      ? true
      : false;
  };

  const handleSplitPayment = plan => {
    if (isEnterpriseSubscription(plan)) {
      // handleEnterpriseClick();
    } else {
      window.location.href = locale === 'ja' ? SPLIT_PAYMENT_URL : SPLIT_PAYMENT_EN_URL;
    }
  };

  const handlePaymentUpAndDowngrade = (selectOption, plan) => {
    if (selectOption === 'oneTimePayment') {
      action();
    }

    if (selectOption === 'splitPayment') {
      handleSplitPayment(plan);
    }
  };

  const getAddSubscriptionAnnuallyActionButtonText = (plan: string, actionState: string) => {
    if (isEnterpriseSubscription(plan)) {
      return t('modal.addSubscription.button.getInContact', '');
    }

    if (actionState === 'downgrade') {
      return t('modal.addSubscription.button.downgrade.oneTimePayment', '');
    }

    if (actionState === 'upgrade') {
      return t('modal.addSubscription.button.upgrade.oneTimePayment', '');
    }

    return t('modal.addSubscription.button.add.oneTimePayment', '');
  };

  const getAddSubscriptionMonthlyActionButtonText = (plan: string, actionState: string) => {
    if (isEnterpriseSubscription(plan)) {
      return t('modal.addSubscription.button.getInContact', '');
    }

    if (actionState === 'downgrade') {
      return t('modal.addSubscription.button.downgrade.splitPayment', '');
    }

    if (actionState === 'upgrade') {
      return t('modal.addSubscription.button.upgrade.splitPayment', '');
    }

    return t('modal.addSubscription.button.add.splitPayment', '');
  };

  const getAddSubscriptionAnnuallyOrMonthlyActionButtonText = (selectOption, plan, actionState) => {
    if (selectOption === 'oneTimePayment') {
      return getAddSubscriptionAnnuallyActionButtonText(plan, actionState);
    }

    if (selectOption === 'splitPayment') {
      return getAddSubscriptionMonthlyActionButtonText(plan, actionState);
    }
  };

  return (
    <>
      {Object.keys(availableBillingCycles).map(interval => {
        return (
          <BillingCycleButton
            key={getIntervalCycle(BILLING_CYCLES, interval)}
            onClick={() => handlePaymentUpAndDowngrade(selectOption, selectedPlan)}
            className={cx({
              [activeBillingCycleButton]:
                getIntervalCycle(BILLING_CYCLES, interval) === billingCycle,
              [disabledBillingCycleButton]: shouldButtonBeDisabled(
                selectedPlan,
                subscriptionPlanKey,
                subscriptionInterval,
                BILLING_CYCLES[interval].cycle
              ),
            })}
            disabled={shouldButtonBeDisabled(
              selectedPlan,
              subscriptionPlanKey,
              subscriptionInterval,
              BILLING_CYCLES[interval].cycle
            )}
          >
            {getAddSubscriptionAnnuallyOrMonthlyActionButtonText(
              selectOption,
              selectedPlan,
              subscriptionModalType
            )}
          </BillingCycleButton>
        );
      })}
    </>
  );
};

export default BillingCyclesNav;
