// @flow
import React from 'react';
import styled from 'react-emotion';
import { getDateString } from '../../../../../../../../../utils/date';
import {
  getBillingPageActionBilled,
  getBillingPageActionSubscriptionAdded,
  getBillingPageDebitText,
  getBillingPageActionCustomOrder,
} from '../../../../../../../../../cms/billingPage';
import { getCurrencyString } from '../../../../../../../../../utils/currency';
import type { InvoiceMdl } from '../../../../../../../../../api/firebase/user/payment';
import { useBillingPageCopy } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useFetchInvoicePdf } from '../../../../../../../../../api/firebase/api';
import {
  openInvoiceDownload,
  getInvoiceType,
  getInvoiceReceiptUrl,
} from '../../../../../../../../../api/firebase/user/payment';
import SpinnerIcon from '../../../../../../../../../components/SpinnerIcon/SpinnerIcon';
import { spinnerClass } from '../../../../../../../../../user/subscriptions/components/AddedSubscription/components/Account/Account';
import {
  buttonResetCss,
  interactiveTextButtonCss,
} from '../../../../../../../../../components/Button/Button';

const DownloadButton = styled('button')`
  ${buttonResetCss};
  ${interactiveTextButtonCss};
`;

type Props = {
  invoice: InvoiceMdl,
};

const Invoice = ({ invoice }: Props) => {
  const [fetchPdf, fetching] = useFetchInvoicePdf(invoice.id);
  const copy = useBillingPageCopy();
  const dateText = getDateString(invoice.paidDate * 1000);
  const actionText = (() => {
    if (getInvoiceType(invoice) === 'custom_order') {
      return getBillingPageActionCustomOrder(copy);
    } else {
      return invoice.isFirstInvoiceForSubscription
        ? getBillingPageActionSubscriptionAdded(copy)
        : getBillingPageActionBilled(copy);
    }
  })();
  const amount = getCurrencyString(invoice.amount, invoice.currency.toUpperCase());
  const debitText = getBillingPageDebitText(copy).replace('$AMOUNT$', amount);

  const handleViewPdf = () => {
    if (fetching) return;

    if (getInvoiceType(invoice) === 'subscription') {
      fetchPdf().then(response => {
        openInvoiceDownload(response);
      });
    } else {
      const downloadUrl = getInvoiceReceiptUrl(invoice);
      if (downloadUrl) {
        window.open(downloadUrl, '_blank');
      }
    }
  };

  return (
    <tr key={invoice.id}>
      <td>{dateText}</td>
      <td>{actionText}</td>
      <td>
        <DownloadButton onClick={handleViewPdf}>
          {fetching ? <SpinnerIcon inverse containerClassName={spinnerClass} /> : debitText}
        </DownloadButton>
      </td>
    </tr>
  );
};

export default Invoice;
