// @flow
import React, { useEffect } from 'react';
import styled from 'react-emotion';
import LoadingDisplay from '../../../components/LoadingDisplay/LoadingDisplay';
import { ROUTES } from '../../routes';
import { getAuthDestination, getAuthTemporaryDestination } from '../../../auth/destination';
import { handleRedirect } from '../CallbackScreen/CallbackScreen';
import { useNavigate } from '../../hooks';
import PageComponent from '../../components/PageComponent/PageComponent';

const Container = styled('div')`
  height: 100vh;
`;

type Props = {
  path: string,
};

const SignUpCompletedScreen = ({ path }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion', { send_to: 'AW-673687837/Ir0gCPKv-78BEJ3SnsEC' });
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Failed to log Google Tag Manager Event');
      Sentry.captureException(error);
      console.error(error);
    }
    const timer = setTimeout(() => {
      const destination = getAuthTemporaryDestination() || getAuthDestination();
      if (destination) {
        handleRedirect(destination);
      } else {
        navigate(ROUTES.music.navigatePath({}));
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <PageComponent path={path}>
      <Container>
        <LoadingDisplay />
      </Container>
    </PageComponent>
  );
};

export default SignUpCompletedScreen;
