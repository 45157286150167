// @flow
import React, { useContext, useEffect, useState } from 'react';
import {
  useSearchQuery,
  useMagicSearchQuery,
  useYouTubeSearchQuery,
  useYouTubeVideoQuery,
  useYouTubeVideoIdQuery,
  useYouTubeChannelQuery,
  useYouTubeChannelIdQuery,
} from '../../../../../ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';
import {
  analyticsKeywordsAdd,
  analyticsKeywordsEditAdd,
  analyticsKeywordsEditClear,
  analyticsKeywordsEditRemove,
} from '../../../../../../../analytics/events';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelAddKeyword,
  analyticsMixpanelRemoveKeyword,
} from '../../../../../../../analytics/mixpanel';
import type { AlgoliaKeyword } from '../../../../../../../api/algolia/magicKeywords';
import {
  getAlgoliaKeyword,
  getMergedQueryKeywordValues,
  getMergedVisibleKeywordValues,
  convertMagicQueryToAlgoliaKeywords,
  getAlgoliaReplacementKeywords,
} from '../../../../../../../api/algolia/magicKeywords';
import { base64DecodeURLValue } from '../../../../../../../utils/encode';

export type KeywordsManagerContextState = {
  keywords: Array<string>,
  magicKeywords: Array<AlgoliaKeyword>,
  mergedQueryKeywords: Array<string>,
  mergedUIKeywords: Array<string>,
  paramKeywords: Array<string>,
  paramMagicKeywords: Array<string>,
  paramYouTubeKeywords: Array<string>,
  paramYouTubeVideoTitle: string,
  paramYouTubeVideoId: string,
  paramYouTubeChannelTitle: string,
  paramYouTubeChannelId: string,
  clearKeywords: () => void,
  setKeywords: (Array<string>) => void,
  addKeyword: (string, boolean) => void,
  addMagicKeywords: (AlgoliaKeyword | null, boolean) => void,
  handleRemoveKeyword: string => void,
  editMode: boolean,
  youtubeKeywords: Array<string>,
  removeYouTubeKeyword: () => void,
  youtubeChannelTitle: string,
  youtubeChannelId: string,
  youtubeVideoTitle: string,
  youtubeVideoId: string,
};

export const KeywordsManagerContext = React.createContext();

export const useKeywordsManagerContext = (): KeywordsManagerContextState => {
  return useContext(KeywordsManagerContext);
};

type Props = {
  children: any,
};

const KeywordsManager = ({ children }: Props) => {
  const [paramKeywords] = useSearchQuery();
  const [paramMagicKeywords] = useMagicSearchQuery();
  const [paramYouTubeKeywords] = useYouTubeSearchQuery();
  const [paramYouTubeVideoTitle] = useYouTubeVideoQuery();
  const [paramYouTubeVideoId] = useYouTubeVideoIdQuery();
  const [paramYouTubeChannelTitle] = useYouTubeChannelQuery();
  const [paramYouTubeChannelId] = useYouTubeChannelIdQuery();
  const [keywords, setKeywords] = useState(
    paramKeywords && paramKeywords.length > 0 ? paramKeywords : []
  );
  const [magicKeywords, setMagicKeywords] = useState(
    paramMagicKeywords && paramMagicKeywords.length > 0
      ? convertMagicQueryToAlgoliaKeywords(paramMagicKeywords)
      : []
  );
  const [youtubeKeywords, setYouTubeKeywords] = useState(
    paramYouTubeKeywords && paramYouTubeKeywords.length > 0
      ? paramYouTubeKeywords.map(paramYtKeyword => {
          return base64DecodeURLValue(paramYtKeyword, true);
        })
      : []
  );
  const [youtubeVideoTitle, setYouTubeVideoTitle] = useState(paramYouTubeVideoTitle || '');
  const [youtubeVideoId, setYouTubeVideoId] = useState(paramYouTubeVideoId || '');
  const [youtubeChannelTitle, setYouTubeChannelTitle] = useState(paramYouTubeChannelTitle || '');
  const [youtubeChannelId, setYouTubeChannelId] = useState(paramYouTubeChannelId || '');

  const [mergedQueryKeywords, setMergedQueryKeywords] = useState(
    keywords.length > 0 && magicKeywords.length > 0
      ? getMergedQueryKeywordValues(keywords, magicKeywords)
      : []
  );
  const [mergedUIKeywords, setMergedUIKeywords] = useState(
    keywords.length > 0 && magicKeywords.length > 0
      ? getMergedVisibleKeywordValues(keywords, magicKeywords)
      : []
  );

  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const editMode =
    (paramKeywords && paramKeywords.length > 0) ||
    (paramMagicKeywords && paramMagicKeywords.length > 0) ||
    (paramYouTubeKeywords && paramYouTubeKeywords.length > 0);

  const isKeywordAlreadyAdded = (keywordToAdd: string) => {
    return keywords.map(keyword => keyword.toLowerCase()).includes(keywordToAdd.toLowerCase());
  };

  const isMagicKeywordAlreadyAdded = (magicKeywordToAdd: AlgoliaKeyword): boolean => {
    return magicKeywords
      .map(magicKeyword => getAlgoliaKeyword(magicKeyword))
      .includes(getAlgoliaKeyword(magicKeywordToAdd));
  };

  const clearKeywords = () => {
    if (editMode) {
      analyticsKeywordsEditClear(mergedUIKeywords);
    }
    mergedUIKeywords.forEach(keyword => {
      const isMagicKeyword = !!keywords.includes(keyword);
      const keywordType = isMagicKeyword ? 'magic' : 'regular';
      const keywordReplacements = isMagicKeyword
        ? magicKeywords
            .filter(magicKeyword => {
              return getAlgoliaKeyword(magicKeyword) === keyword;
            })
            .map(filteredMagicKeyword => {
              return getAlgoliaReplacementKeywords(filteredMagicKeyword) === keyword;
            })
        : [];
      analyticsMixpanelRemoveKeyword(
        mixpanel,
        moengage,
        keyword,
        keywordType,
        keywordReplacements,
        false,
        'category'
      );
    });

    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      youtubeVideoTitle,
      'youtube',
      youtubeKeywords,
      false,
      'category'
    );

    setKeywords([]);
    setMagicKeywords([]);
    setYouTubeKeywords([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');
  };

  const handleSetKeywords = (newKeywords: Array<string>) => {
    setKeywords(newKeywords.map(keyword => keyword.toLowerCase()));
  };

  const addKeyword = (keyword: string, isSuggestion: boolean) => {
    if (keyword === '') return;
    if (isKeywordAlreadyAdded(keyword)) return;
    analyticsMixpanelAddKeyword(
      mixpanel,
      moengage,
      keyword,
      'regular',
      [],
      isSuggestion,
      'TBD',
      'TBD'
    );
    analyticsKeywordsAdd(keyword);
    const updatedKeywords = keywords.slice();
    setKeywords(updatedKeywords.concat([keyword.toLowerCase()]));
    if (editMode) {
      analyticsKeywordsEditAdd(keyword);
    }
  };

  const addMagicKeywords = (magicKeyword: AlgoliaKeyword | null, isSuggestion: boolean) => {
    if (!magicKeyword) return;
    if (isMagicKeywordAlreadyAdded(magicKeyword)) return;
    analyticsMixpanelAddKeyword(
      mixpanel,
      moengage,
      getAlgoliaKeyword(magicKeyword),
      'magic',
      getAlgoliaReplacementKeywords(magicKeyword),
      isSuggestion,
      'TBD',
      'TBD'
    );
    analyticsKeywordsAdd(getAlgoliaKeyword(magicKeyword));
    const updatedMagicKeywords = magicKeywords.slice();
    const mergedKeywords = updatedMagicKeywords.concat([magicKeyword]);
    setMagicKeywords(mergedKeywords);
    if (editMode) {
      analyticsKeywordsEditAdd(getAlgoliaKeyword(magicKeyword));
    }
  };

  const removeKeyword = (keyword: string) => {
    const keywordIndex = keywords.indexOf(keyword);
    if (keywordIndex >= 0) {
      const updatedKeywords = keywords.slice();
      updatedKeywords.splice(keywordIndex, 1);
      setKeywords(updatedKeywords);
    }
    if (editMode) {
      analyticsKeywordsEditRemove(keyword);
    }
    analyticsMixpanelRemoveKeyword(mixpanel, moengage, keyword, 'regular', [], false, 'category');
  };

  const removeMagicKeyword = (keyword: string) => {
    const updatedMagicKeywords = magicKeywords.filter(magicKeyword => {
      return getAlgoliaKeyword(magicKeyword) !== keyword;
    });
    const removedKeyword = magicKeywords.filter(magicKeyword => {
      return getAlgoliaKeyword(magicKeyword) === keyword;
    });
    setMagicKeywords(updatedMagicKeywords);

    if (editMode) {
      analyticsKeywordsEditRemove(keyword);
    }
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      keyword,
      'magic',
      getAlgoliaReplacementKeywords(removedKeyword[0]),
      false,
      'category'
    );
  };

  const handleRemoveKeyword = (keyword: string) => {
    const isKeywordToRemoveMagic = !mergedQueryKeywords.includes(keyword);

    if (isKeywordToRemoveMagic) {
      removeMagicKeyword(keyword);
    } else {
      removeKeyword(keyword);
    }
  };

  const removeYouTubeKeyword = () => {
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      youtubeVideoTitle,
      'youtube',
      youtubeKeywords,
      false,
      'category'
    );

    setYouTubeKeywords([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');
  };

  useEffect(() => {
    if (keywords.length === 0 && magicKeywords.length === 0) {
      setMergedQueryKeywords([]);
      setMergedUIKeywords([]);
      return;
    }

    const newMergedQueryKeywords = getMergedQueryKeywordValues(keywords, magicKeywords);
    const newMergedUIKeywords = getMergedVisibleKeywordValues(keywords, magicKeywords);
    setMergedQueryKeywords(newMergedQueryKeywords);
    setMergedUIKeywords(newMergedUIKeywords);
  }, [keywords, magicKeywords]);

  return (
    <KeywordsManagerContext.Provider
      value={{
        keywords,
        magicKeywords,
        mergedQueryKeywords,
        mergedUIKeywords,
        paramKeywords,
        paramMagicKeywords,
        clearKeywords,
        setKeywords: handleSetKeywords,
        addKeyword,
        addMagicKeywords,
        handleRemoveKeyword,
        editMode,
        youtubeKeywords,
        youtubeVideoTitle,
        youtubeVideoId,
        youtubeChannelTitle,
        youtubeChannelId,
        removeYouTubeKeyword,
        paramYouTubeKeywords,
        paramYouTubeVideoTitle,
        paramYouTubeVideoId,
        paramYouTubeChannelTitle,
        paramYouTubeChannelId,
      }}
    >
      {children}
    </KeywordsManagerContext.Provider>
  );
};

export default KeywordsManager;
