// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SiteHeaderMdl = {
  data: {
    site_version: {
      text: string,
    },
    default_site_name: {
      text: string,
    },
    // marketplace_name: {
    //   text: string,
    // },
    home_nav: {
      text: string,
    },
    find_nav: {
      text: string,
    },
    bookmarks_nav: {
      text: string,
    },
    browse_nav: {
      text: string,
    },
    sfx_nav: {
      text: string,
    },
    search_nav: {
      text: string,
    },
    search_input_placeholder: {
      text: string,
    },
    help_nav: {
      text: string,
    },
    pricing_nav: {
      text: string,
    },
    custom_order_nav: {
      text: string,
    },
    help_url: {
      url: string,
    },
    sign_in_button: {
      text: string,
    },
    sign_up_button: {
      text: string,
    },
  },
};

export const getSiteVersion = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.site_version.text', '') || '';
};

export const getDefaultSiteName = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.default_site_name.text', '') || '';
};

export const getMarketplaceName = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.marketplace_name.text', '') || '';
};

export const getHomeNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.home_nav.text', '') || '';
};

export const getFindNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.find_nav.text', '') || '';
};

export const getBookmarksNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.bookmarks_nav.text', '') || '';
};

export const getBrowseNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.browse_nav.text', '') || '';
};

export const getSfxNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.sfx_nav.text', '') || '';
};

export const getSearchNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.search_nav.text', '') || '';
};

export const getSearchInputPlaceholder = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.search_input_placeholder.text', '') || '';
};

export const getHelpNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.help_nav.text', '') || '';
};

export const getPricingNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.pricing_nav.text', '') || '';
};

export const getCustomOrderNav = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.custom_order_nav.text', '') || '';
};

export const getHelpNavUrl = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.help_url.url', '') || '';
};

export const getHeaderSignInButton = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.sign_in_button.text', '') || '';
};

export const getHeaderSignUpButton = (siteHeader: SiteHeaderMdl): string => {
  return get(siteHeader, 'data.sign_up_button.text', '') || '';
};

export const query = graphql`
  fragment Header on PrismicSiteHeader {
    data {
      site_version {
        text
      }
      default_site_name {
        text
      }
      home_nav {
        text
      }
      find_nav {
        text
      }
      bookmarks_nav {
        text
      }
      browse_nav {
        text
      }
      sfx_nav {
        text
      }
      search_nav {
        text
      }
      search_input_placeholder {
        text
      }
      help_nav {
        text
      }
      pricing_nav {
        text
      }
      custom_order_nav {
        text
      }
      help_url {
        url
      }
      sign_in_button {
        text
      }
      sign_up_button {
        text
      }
    }
  }
`;
