// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { transparentize } from 'polished';
import { useRecoilValue } from 'recoil';
import PreviewPlayPauseButton from '../../../../../../song/components/FullDisplaySongCard/components/PreviewPlayPauseButton/PreviewPlayPauseButton';
import { useGlobalPlayerContext } from '../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { lightColor } from '../../../../../../styles/config/colors';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
} from '../../../../../../store/globalPlayer';
import { useAuthContext } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useShowSignUp } from '../../../../../../modals/components/ModalsWrapper/ModalsWrapper';

type Props = {
  title: string,
  slug: string,
  bgImage: string,
  listType: string,
  previewTile: any,
  navigateTile: any,
  oversizedTile?: boolean,
};

const TileContainer = styled('div')`
  height: 100%;
  width: 100%;
  min-height: 160px;
  &:hover {
    text-decoration: underline;
  }
  padding: 15px;
  &:first-of-type {
    margin-left: 0px;
    ${MOBILE_DEVICE_BREAKPOINT} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const TileLink = styled('h3')`
  color: #0f0f24;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.19px;
  line-height: 18px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TileImageWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-image: url(${props => props.bgImage});
  background-position: center;
  border-radius: 5px;

  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }

  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const oversizedTileCss = css`
  ${MOBILE_DEVICE_BREAKPOINT} {
  }
`;

const OverlayButtonContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & button {
    display: none;
  }
  @supports not (aspect-ratio: 1 / 1) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  &:hover,
  &.playingFeatured {
    background-color: ${transparentize(0.4, lightColor)};

    & button {
      display: flex;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-color: ${transparentize(1, lightColor)};

    & button {
      display: none;
    }

    &:hover {
      background-color: ${transparentize(1, lightColor)};

      & button {
        display: none;
      }
    }

    &.playingFeatured {
      background-color: ${transparentize(0.4, lightColor)};

      & button {
        display: flex;
      }
    }
  }
`;

const PreviewTile = ({
  title,
  slug,
  bgImage,
  listType,
  previewTile,
  navigateTile,
  oversizedTile = false,
}: Props) => {
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const globalTryingToPlay = useRecoilValue(globalTryingToPlayAtom);
  const globalLoadError = useRecoilValue(globalLoadErrorAtom);
  const { handleToggleGlobalPlayback } = useGlobalPlayerContext();
  const tilePlaying = playbackListType === listType && playbackListSlug === slug.toLowerCase();
  const isPlaying = tilePlaying && globalPlaying;
  const tryingToPlay = tilePlaying && globalTryingToPlay;
  const loadError = tilePlaying && globalLoadError;

  const handlePlayPauseToggle = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (tilePlaying) {
      handleToggleGlobalPlayback();
      return;
    }
    previewTile();
  };

  return (
    <TileContainer>
      <TileImageWrapper
        bgImage={bgImage}
        className={cx({ [oversizedTileCss]: oversizedTile })}
        onClick={() => {
          navigateTile();
        }}
      >
        <OverlayButtonContainer className={cx({ playingFeatured: tilePlaying })}>
          <PreviewPlayPauseButton
            playing={isPlaying}
            tryingToPlay={tryingToPlay}
            loadError={loadError}
            handleOnPlayToggle={handlePlayPauseToggle}
          />
        </OverlayButtonContainer>
      </TileImageWrapper>
      <TileLink
        onClick={() => {
          navigateTile();
        }}
      >
        {title}
      </TileLink>
    </TileContainer>
  );
};

export default PreviewTile;
