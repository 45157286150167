// @flow
import React, { useEffect } from 'react';
import DownloadHistoryManager from './components/DownloadHistoryManager/DownloadHistoryManager';
import DownloadHistory from './components/DownloadHistory/DownloadHistory';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';

type Props = {
  uri: string,
};

const ProfileDownloadHistoryView = ({ uri }: Props) => {
  const { closeGlobalPlayer } = useGlobalPlayerContext();

  useEffect(() => {
    closeGlobalPlayer();
  }, []);

  return (
    <PageComponent path={uri}>
      <DownloadHistoryManager>
        <DownloadHistory />
      </DownloadHistoryManager>
    </PageComponent>
  );
};

export default ProfileDownloadHistoryView;
