// @flow
import { CURRENCY_CODES } from '../user/subscriptions/data';

export const getCurrencyString = (amount: number, currency: string): string => {
  const lang = navigator.language;
  const internationalNumber = new Intl.NumberFormat(lang, { style: 'currency', currency });

  if (currency === CURRENCY_CODES.en) {
    return internationalNumber.format(amount / 100);
  }

  return internationalNumber.format(amount / 1);
};

export const getUSDCurrencyString = (amount: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    amount / 100
  );
};
