// @flow
import React from 'react';
import styled from 'react-emotion';
import { useTranslation } from 'react-i18next';

const RadioButtonGroup = styled('div')`
  display: flex;
  margin: 0 auto 20px auto;
  text-align: left;
  padding-left: 20px;
  width: 80%;
  justify-content: unset;

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
  }

  span {
    padding-top: 5px;
    margin-left: -5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`;

type PaymentMethodRadioButtonProps = {
  selectOption: string,
  handleOptionChange: (event: any) => void,
};

const PaymentMethodRadioButton = ({
  selectOption = 'oneTimePayment',
  handleOptionChange,
}: PaymentMethodRadioButtonProps) => {
  const { t } = useTranslation();

  return (
    <RadioButtonGroup className="radio-button-group">
      <label>
        <input
          type="radio"
          value="splitPayment"
          checked={selectOption === 'splitPayment'}
          onChange={handleOptionChange}
        />
        <span>{t('general.radioButton.splitPayment', '')}</span>
      </label>

      <label>
        <input
          type="radio"
          value="oneTimePayment"
          checked={selectOption === 'oneTimePayment'}
          onChange={handleOptionChange}
        />
        <span>{t('general.radioButton.oneTimePayment', '')}</span>
      </label>
    </RadioButtonGroup>
  );
};

export default PaymentMethodRadioButton;
