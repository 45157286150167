// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  monoSmallPlainTextCss,
  smallMediumTextCss,
} from '../../../../../../styles/typography/typography';
import ProfileIcon from '../../../../../ProfileIcon/ProfileIcon';
import {
  useUserEmail,
  useUserName,
} from '../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';

const Profile = styled('section')`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  margin-left: -2px;
`;

const ProfileInfo = styled('div')`
  margin-left: 20px;

  h3 {
    ${smallMediumTextCss};
  }

  p {
    ${monoSmallPlainTextCss};
    line-height: 20px;
  }
`;

type Props = {};

const MobileUserProfile = () => {
  const name = useUserName();
  const email = useUserEmail();
  return (
    <Profile>
      <ProfileIcon />
      <ProfileInfo>
        <h3>{name}</h3>
        <p>{email}</p>
      </ProfileInfo>
    </Profile>
  );
};

export default MobileUserProfile;
