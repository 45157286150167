// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type EditKeywordsPageGraphMdl = {
  data: {
    title: {
      text: string,
    },
    instruction: {
      html: string,
    },
    button: {
      text: string,
    },
  },
};

export const getEditKeywordsPageTitle = (copy: EditKeywordsPageGraphMdl): string => {
  return get(copy, 'data.title.text', '') || '';
};

export const getEditKeywordsPageInstruction = (copy: EditKeywordsPageGraphMdl): string => {
  return get(copy, 'data.instruction.html', '') || '';
};

export const getEditKeywordsPageButton = (copy: EditKeywordsPageGraphMdl): string => {
  return get(copy, 'data.button.text', '') || '';
};

export const query = graphql`
  fragment EditKeywordsPage on PrismicEditKeywordsPage {
    data {
      title {
        text
      }
      instruction {
        html
      }
      button {
        text
      }
    }
  }
`;
