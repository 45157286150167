// @flow
import React from 'react';
import {
  useSignInModalCopy,
  useSignInUpErrors,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getSignInModalErrorMessage } from '../../../../cms/signinModal';
import {
  getGenericError,
  getInvalidPasswordError,
  getInvalidSignUpError,
  getSignInAccessDeniedError,
} from '../../../../cms/signInUpErrors';

type Props = {
  error: any,
};

const Auth0ErrorHandler = ({ error }: Props) => {
  const errors = useSignInUpErrors();
  if (error) {
    const { code, description } = error;
    if (code) {
      switch (code) {
        case 'invalid_signup':
          return getInvalidSignUpError(errors);
        case 'access_denied':
          return getSignInAccessDeniedError(errors);
        case 'invalid_password':
          return getInvalidPasswordError(errors);
        default:
          break;
      }
    }
    if ((description && typeof description === 'string') || description instanceof String) {
      return description;
    }
    if (code) {
      return code;
    }
  }
  return getGenericError(errors);
};

export default Auth0ErrorHandler;
