import React from 'react';
import {
  getArtistProfileCoverArt,
  getArtistProfileDescriptionMarkup,
  getArtistProfileName,
} from '../../../cms/artistProfile';
import { getMiscCopyCloseButton } from '../../../cms/miscCopy';
import { BUTTON_WIDTHS } from '../../../components/Button/Button';
import {
  useArtistProfile,
  useMiscCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';

type Props = {
  artistSlug: string,
  onClose: () => void,
};

const ArtistModal = ({ artistSlug, onClose }: Props) => {
  const [artistProfile] = useArtistProfile(artistSlug);

  const title = getArtistProfileName(artistProfile);
  const description = getArtistProfileDescriptionMarkup(artistProfile);
  const bannerImage = getArtistProfileCoverArt(artistProfile);
  const miscCopy = useMiscCopy();

  return (
    <ClassicModalLayout onClose={onClose} label={title} bannerImage={bannerImage}>
      <ClassicModalContentLayout heading={title} description={description} />
    </ClassicModalLayout>
  );
};

export default ArtistModal;
