// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import SiteHeader, { headerHeight, mobileHeaderHeight } from '../SiteHeader/SiteHeader';
import SiteFooter from '../SiteFooter/SiteFooter';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { useSubscriptionsContext } from '../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import GlobalSongPlayer from '../../song/components/GlobalSongPlayer/GlobalSongPlayer';

const Body = styled('main')`
  ${LARGE_DEVICE_BREAKPOINT} {
    height: auto;
  }
`;

export const bodyPaddingClass = css`
  padding-top: ${headerHeight}px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: ${mobileHeaderHeight}px;

    &.bannerPadding {
      padding-top: ${mobileHeaderHeight + 40}px;
    }
  }
`;

type Props = {
  children?: any,
  header?: any,
  footer?: any,
  dockedPlayer?: any,
  bodyPadding?: boolean,
};

const LayoutWrapper = ({
  children,
  bodyPadding = false,
  header = <SiteHeader />,
  footer = <SiteFooter />,
  dockedPlayer = <GlobalSongPlayer />,
}: Props) => {
  const { referralCoupon, showReferralBanner } = useSubscriptionsContext();
  return (
    <div>
      {header}
      <Body
        className={cx({
          [bodyPaddingClass]: bodyPadding || (referralCoupon && showReferralBanner),
          bannerPadding: referralCoupon && showReferralBanner,
        })}
      >
        {children}
        {dockedPlayer}
      </Body>
      {footer}
    </div>
  );
};

export default LayoutWrapper;
