// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SearchBookmarksGraphMdl = {
  data: {
    title: {
      text: string,
    },
    button_text: {
      text: string,
    },
    search_placeholder: {
      text: string,
    },
  },
};

export const getSearchBookmarksTitle = (copy: SearchBookmarksGraphMdl): string => {
  return get(copy, 'data.title.text', '') || '';
};

export const getSearchBookmarksButtonText = (copy: SearchBookmarksGraphMdl): string => {
  return get(copy, 'data.button_text.text', '') || '';
};

export const getSearchBookmarksSearchPlaceholder = (copy: SearchBookmarksGraphMdl): string => {
  return get(copy, 'data.search_placeholder.text', '') || '';
};

export const query = graphql`
  fragment SearchBookmarks on PrismicSearchBookmarks {
    data {
      title {
        text
      }
      button_text {
        text
      }
      search_placeholder {
        text
      }
    }
  }
`;
