// @flow
import React from 'react';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import {
  TextButton,
  lessVerticalPaddingButtonClass,
} from '../../../../../../../components/Button/Button';
import { lightColor } from '../../../../../../../styles/config/colors';
import SearchIcon from '../../../../../../../assets/inline-assets/search-icon.svg';
import { getResultsPageNewSearchButton } from '../../../../../../../cms/resultsPage';
import { useResultsPage } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { MEDIA_BREAKPOINTS } from '../../../../../../../styles/responsive';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import MobileResultsFiltersModal from '../../../../../../../modals/components/MobileResultsFiltersModal/MobileResultsFiltersModal';
import ResultsFilters from './components/ResultsFilters/ResultsFilters';
import ResultsKeywords from './components/ResultsKeywords/ResultsKeywords';
import { isSmallDeviceSize } from '../../../../../../../utils/device';

const inactiveSearchContainer = css`
  opacity: 0.5;

  &:hover,
  &:active,
  &:focus,
  &focus-visible,
  & button:hover {
    text-decoration: none;
    cursor: default;
    color: ${lightColor};
  }
`;

const ResultsAsideContainer = styled('div')`
  width: 100%;
`;

const SearchContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 155px;
  ${MEDIA_BREAKPOINTS.max1000} {
    display: none;
  }

  svg {
    margin-right: 9.9px;
    position: relative;
    top: 2px;
    path,
    circle {
      stroke: ${lightColor};
    }
  }
`;

export const newSearchClass = css`
  ${lessVerticalPaddingButtonClass};
  font-size: 12px;
  line-height: 20px;
  min-width: 117px;
  padding: 10px 20px;
  border: 1px solid ${transparentize(0.8, lightColor)};
  border-radius: 3px;
`;

export const newSearchTopAlign = css`
  align-items: flex-start;
  margin-top: 20vh;
`;

const MainContentContainer = styled('div')`
  display: flex;
  width: 100%;
  ${MEDIA_BREAKPOINTS.max1000} {
    display: none;
  }
`;

type Props = {
  filterMenuIsActive: boolean,
  setFilterMenuActive: boolean => void,
};
const TextContainer = styled('span')`
  white-space: nowrap;
`;
const ResultsAside = ({ filterMenuIsActive, setFilterMenuActive }: Props) => {
  const {
    showLeaveResultsModal,
    mobileResultsFiltersOpen,
    hideMobileResultsFiltersModal,
    showLeaveResultFiltersModal,
  } = useModalsContext();
  const {
    loading,
    resultFacets,
    appliedFilters,
    currentSongData,
    resultFacetStats,
    handleClearSongFilters,
    filteredSongsCount,
    similarSongQuery,
  } = useResultsManagerContext();
  const resultsPage = useResultsPage();

  const handleNewSearch = () => {
    if (!filterMenuIsActive) {
      showLeaveResultsModal();
    }
  };
  const handleClearFilters = (newKeywords: Array<string>, confirmKeywordRemoval) => {
    showLeaveResultFiltersModal(newKeywords, confirmKeywordRemoval);
  };

  return (
    <ResultsAsideContainer>
      <>
        {filteredSongsCount > 0 && (
          <>
            {!loading && (
              <SearchContainer
                className={cx({
                  [inactiveSearchContainer]: filterMenuIsActive,
                  [newSearchTopAlign]: similarSongQuery,
                })}
              >
                <TextButton className={newSearchClass} onClick={handleNewSearch}>
                  <SearchIcon />
                  <TextContainer>{getResultsPageNewSearchButton(resultsPage)}</TextContainer>
                </TextButton>
              </SearchContainer>
            )}
            <MainContentContainer>
              {resultFacets && !similarSongQuery && (
                <ResultsFilters setFilterMenuActive={setFilterMenuActive} />
              )}
            </MainContentContainer>
          </>
        )}
      </>
      {mobileResultsFiltersOpen && !similarSongQuery && (
        <MobileResultsFiltersModal
          onClose={() => {
            hideMobileResultsFiltersModal();
          }}
          resultFacets={resultFacets}
          appliedFilters={appliedFilters}
          currentSongData={currentSongData}
          resultFacetStats={resultFacetStats}
          handleClearSongFilters={handleClearSongFilters}
        />
      )}
      {isSmallDeviceSize() && filteredSongsCount > 0 && (
        <ResultsKeywords
          onClear={handleNewSearch}
          onClearFilters={handleClearFilters}
          editEnabled
        />
      )}
    </ResultsAsideContainer>
  );
};

export default ResultsAside;
