// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import SongActionButton from '../SongActionButton/SongActionButton';
import {
  getTrackNoSimilarSearchLabel,
  getTrackSimilarSongsAction,
} from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useSong } from '../../../../../SongContextWrapper/SongContextWrapper';
import { getKeywordsFromSongDetails, getSongID } from '../../../../../../../api/algolia/song';
import SimilarSongsIcon from '../../../../../../../assets/inline-assets/similar-songs-icon.svg';
import { ROUTES } from '../../../../../../../routing/routes';
import { useNavigate } from '../../../../../../../routing/hooks';
import { getPlayerKeyboardShortcuts } from '../../../../../../../utils/keyboardShortcuts';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../user/subscriptions/data';
import {
  useModalsContext,
  useShowSignUp,
} from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useAuthContext } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';

type Props = {
  condensed?: boolean,
  shortcutEnabled?: boolean,
  topLabel?: boolean,
  onClose?: () => void,
};

const SimilarSongsButton = ({
  condensed = false,
  shortcutEnabled = false,
  topLabel = false,
  onClose = () => {},
}: Props) => {
  const song = useSong();
  const navigate = useNavigate();
  const { userRole } = useSubscriptionsContext();
  const { showSubscriptionRequired } = useModalsContext();
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();

  const similarityKeywords = getKeywordsFromSongDetails(song);
  const similarSongId = getSongID(song);

  const similaritySearchAvailable = similarityKeywords.length > 0;

  const handleClick = () => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (!similaritySearchAvailable) {
      return;
    }

    if (!USER_PERMISSIONS[userRole].similarSearch) {
      showSubscriptionRequired();
      return;
    }
    if (onClose) {
      onClose();
    }
    navigate(ROUTES.similarSearch.navigatePath({ songId: similarSongId }));
  };

  const trackCopy = useTrackCopy();

  const keyboardShortcutHandlers = (() => {
    return {
      similar_search: handleClick,
    };
  })();

  const label = similaritySearchAvailable
    ? getTrackSimilarSongsAction(trackCopy)
    : getTrackNoSimilarSearchLabel(trackCopy);

  return (
    <>
      {shortcutEnabled && (
        <GlobalHotKeys
          keyMap={getPlayerKeyboardShortcuts('results')}
          handlers={keyboardShortcutHandlers}
          allowChanges
        />
      )}
      <SongActionButton
        icon={<SimilarSongsIcon />}
        label={label}
        onClick={handleClick}
        condensed={condensed}
        disabledIcon={!similaritySearchAvailable}
        topLabel={topLabel}
      />
    </>
  );
};

export default SimilarSongsButton;
