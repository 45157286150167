// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type TrackGraphMdl = {
  data: {
    view_all_parts_action: {
      text: string,
    },
    load_details_action: {
      text: string,
    },
    microparts_action: {
      text: string,
    },
    bookmark_action: {
      text: string,
    },
    download_action: {
      text: string,
    },
    download_wav_action: {
      text: string,
    },
    download_pack_action: {
      text: string,
    },
    download_midi_action: {
      text: string,
    },
    redownload_action: {
      text: string,
    },
    redownload_wav_action: {
      text: string,
    },
    redownload_midi_action: {
      text: string,
    },
    share_action: {
      text: string,
    },
    minimize_action: {
      text: string,
    },
    hide_action: {
      text: string,
    },
    unhide_action: {
      text: string,
    },
    similar_songs_acton: {
      text: string,
    },
    playlist_action: {
      text: string,
    },
    similar_search_label: {
      text: string,
    },
    no_similar_search_label: {
      text: string,
    },
    more_options_action: {
      text: string,
    },
    pack_action: {
      text: string,
    },
    find_similar_action: {
      text: string,
    },
    view_loops_action: {
      text: string,
    },
  },
};

export const getTrackViewAllPartsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.view_all_parts_action.text', '') || '';
};

export const getTrackLoadDetailsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.load_details_action.text', '') || '';
};

export const getTrackMicropartsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.microparts_action.text', '') || '';
};

export const getTrackBookmarkAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.bookmark_action.text', '') || '';
};

export const getTrackUnbookmarkAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.unbookmark_action.text', '') || '';
};

export const getTrackDownloadAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.download_action.text', '') || '';
};

export const getTrackReDownloadAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.redownload_action.text', '') || '';
};

export const getTrackDownloadWavAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.download_wav_action.text', '') || '';
};

export const getTrackDownloadPackAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.download_pack_action.text', '') || '';
};

export const getTrackDownloadMidiAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.download_midi_action.text', '') || '';
};

export const getTrackReDownloadWavAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.redownload_wav_action.text', '') || '';
};

export const getTrackReDownloadMidiAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.redownload_midi_action.text', '') || '';
};

export const getTrackShareAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.share_action.text', '') || '';
};

export const getTrackMinimizeAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.minimize_action.text', '') || '';
};

export const getTrackHideAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.hide_action.text', '') || '';
};

export const getTrackUnhideAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.unhide_action.text', '') || '';
};

export const getTrackSimilarSongsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.similar_songs_action.text', '') || '';
};

export const getTrackPlaylistAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.playlist_action.text', '') || '';
};

export const getTrackSimilarSearchLabel = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.similar_search_label.text', '') || '';
};

export const getTrackNoSimilarSearchLabel = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.no_similar_search_label.text', '') || '';
};

export const getTrackMoreOptionsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.more_options_action.text', '') || '';
};

export const getTrackPackAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.pack_action.text', '') || '';
};

export const getTrackFindSimilarAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.find_similar_action.text', '') || '';
};

export const getTrackViewLoopsAction = (copy: TrackGraphMdl): string => {
  return get(copy, 'data.view_loops_action.text', '') || '';
};

export const query = graphql`
  fragment Track on PrismicTrack {
    data {
      view_all_parts_action {
        text
      }
      load_details_action {
        text
      }
      microparts_action {
        text
      }
      bookmark_action {
        text
      }
      unbookmark_action {
        text
      }
      download_action {
        text
      }
      download_wav_action {
        text
      }
      download_pack_action {
        text
      }
      download_midi_action {
        text
      }
      redownload_action {
        text
      }
      redownload_wav_action {
        text
      }
      redownload_midi_action {
        text
      }
      share_action {
        text
      }
      minimize_action {
        text
      }
      hide_action {
        text
      }
      unhide_action {
        text
      }
      similar_songs_action {
        text
      }
      playlist_action {
        text
      }
      similar_search_label {
        text
      }
      no_similar_search_label {
        text
      }
      more_options_action {
        text
      }
      pack_action {
        text
      }
      find_similar_action {
        text
      }
      view_loops_action {
        text
      }
    }
  }
`;
