// @flow
import { useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';
import { GlobalAudioPlaybackHandler } from '../player/player';
import type { MicropartsMdl } from '../../../AudioBars/AudioBars';
import { firebaseApiHandler } from '../../../../../api/firebase/api';

const generateSprite = (microparts: MicropartsMdl, duration: number) => {
  const sprite = {
    __default: [0, duration * 1000, true],
  };
  microparts.forEach(([start, end], index) => {
    const startOffset = start * duration * 1000;
    const partDuration = (end - start) * duration * 1000;
    sprite[`${index.toString()}`] = [startOffset, partDuration, true];
  });
  return sprite;
};

export const useGlobalSongAudioPlayback = (
  songId: string,
  streamURL: string,
  microparts: MicropartsMdl,
  duration: number,
  autoplay: boolean,
  autoload: boolean,
  onPlay: () => void,
  onPause: () => void,
  onStop: () => void,
  previousPlayingState: boolean,
  handlePreviousPlayingState: () => void,
  handleLooping: () => void,
  handleGetPlayerType: () => string
) => {
  const [initialized, setInitialized] = useState(false);
  const audioRef = useRef(null);
  const audioHandlerRef = useRef(null);

  // iOS17以降のブラウザで楽曲の再生がされなくなった。リダイレクトURLが問題と思われる。
  // よってモバイルの場合、Algoliaから取得したURLをこの時点でリダイレクト後のURLに置き換える。
  useEffect(() => {
    const initializeAudio = async () => {
      let songStreamURL = streamURL;
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        songStreamURL = await firebaseApiHandler.getRedirectUrl(streamURL);
      }
      if (audioRef.current === null) {
        const sprite = generateSprite(microparts, duration);
        audioRef.current = new Howl({
          html5: true,
          autoplay,
          loop: true,
          preload: autoload,
          src: songStreamURL,
          format: ['mp3'],
          sprite,
        });
      }

      if (audioHandlerRef.current === null) {
        audioHandlerRef.current = new GlobalAudioPlaybackHandler(
          audioRef.current,
          onPlay,
          onPause,
          onStop,
          previousPlayingState,
          handlePreviousPlayingState,
          handleLooping,
          handleGetPlayerType
        );
      }

      setInitialized(true);
    };

    initializeAudio();

    return () => {
      if (audioHandlerRef.current) {
        audioHandlerRef.current.cleanup();
      }
      if (audioRef.current) {
        console.log('unloading...');
        audioRef.current.unload();
        console.log('unloaded', audioRef.current);
      }
    };
  }, []);

  return [audioRef.current, audioHandlerRef.current, initialized];
};
