// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { ErrorMessage, Formik } from 'formik';
import { LargeHeading } from '../../../../../../../styles/typography/components/Heading/Heading';
import { useProfilePageCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getProfilePageCompanyLabel,
  getProfilePageCountryLabel,
  getProfilePageFirstNameLabel,
  getProfilePageHeading,
  getProfilePageLastNameLabel,
  getProfilePagePhoneNumberLabel,
  getProfilePageSaveButton,
  getProfilePageUpdateGenericError,
} from '../../../../../../../cms/profilePage';
import {
  FormInput,
  FormInputContainer,
  FormInputLabel,
  FormSection,
} from '../../../../../../../components/forms/general';
import {
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import {
  getProfileAvatar,
  getProfileCompany,
  getProfileCountry,
  getProfileFirstName,
  getProfileLastName,
  getProfilePhoneNumber,
} from '../../../../../../../api/firebase/user/profile';
import {
  useUserProfileContext,
  useUserProfileDetails,
} from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import DisplayErrorMessage, {
  DisplayErrorMessageTopMargin,
} from '../../../../../../../components/DisplayErrorMessage/DisplayErrorMessage';
import Button, { BUTTON_WIDTHS } from '../../../../../../../components/Button/Button';
import { useProfileDetailsFormSchema } from './schema';
import ProfileImageUpload from './components/ProfileImageUpload/ProfileImageUpload';
import { useFirestoreUpdateUserProfileData } from '../../../../../../../api/firebase/user/user';
import { analyticsAccountAddCompany } from '../../../../../../../analytics/events';
import {
  useProfileChangesSavedToast,
  useEmojiRestrictedToast,
} from '../../../../../../../toasts/hooks';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { updateMixpanelProfile } from '../../../../../../../analytics/mixpanel';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { hasEmoji } from '../../../../../../../utils/emoji';
import { updateMoEngageProfileDetails } from '../../../../../../../analytics/moEngage';

const NamesSection = styled(FormSection)`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    > div {
      margin-top: 25px;
    }
  }
`;

const SaveButtonWrapper = styled('div')`
  margin-top: 30px;
`;

const formValueKeys = {
  avatar: 'avatar',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  country: 'country',
  phoneNumber: 'phoneNumber',
  company: 'company',
};

type Props = {};

const ProfileDetails = () => {
  const savedChangesToast = useProfileChangesSavedToast();
  const copy = useProfilePageCopy();
  const profile = useUserProfileDetails();
  const schema = useProfileDetailsFormSchema();
  const { updateUserProfileData } = useUserProfileContext();
  const [avatar, setAvatar] = useState(getProfileAvatar(profile));
  const [updateProfile, updatingProfile] = useFirestoreUpdateUserProfileData();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();
  const emojiRestrictedToast = useEmojiRestrictedToast();

  const onSubmit = (values, { setSubmitting, setErrors, setStatus }) => {
    const { firstName, lastName } = values;

    if (hasEmoji(firstName) || hasEmoji(lastName)) {
      emojiRestrictedToast();
      setSubmitting(false);
      return;
    }

    if (updatingProfile) return;
    const updateValues = {
      ...values,
      avatar,
    };
    updateProfile(updateValues)
      .then(() => {
        updateUserProfileData(updateValues);
        updateMixpanelProfile(mixpanel, userId, updateValues);
        updateMoEngageProfileDetails(moengage, userId, updateValues);
        savedChangesToast();

        if (updateValues.company && !getProfileCompany(profile)) {
          analyticsAccountAddCompany(updateValues.company);
        }
      })
      .catch(e => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when updating user profile');
        Sentry.captureException(e);
        console.error(e);
        setStatus({
          firestore: true,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  if (!profile) return null;
  const firstNameLabel = getProfilePageFirstNameLabel(copy);
  const lastNameLabel = getProfilePageLastNameLabel(copy);
  const countryLabel = getProfilePageCountryLabel(copy);
  const phoneNumberLabel = getProfilePagePhoneNumberLabel(copy);
  const companyLabel = getProfilePageCompanyLabel(copy);
  return (
    <section>
      <LargeHeading>{getProfilePageHeading(copy)}</LargeHeading>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          [formValueKeys.firstName]: getProfileFirstName(profile),
          [formValueKeys.lastName]: getProfileLastName(profile),
          [formValueKeys.country]: getProfileCountry(profile),
          [formValueKeys.phoneNumber]: getProfilePhoneNumber(profile),
          [formValueKeys.company]: getProfileCompany(profile),
        }}
        validationSchema={schema}
      >
        {formProps => {
          const {
            errors,
            status,
            values,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          return (
            <React.Fragment>
              <ProfileImageUpload image={avatar} setImage={setAvatar} />
              <form onSubmit={handleSubmit}>
                <NamesSection>
                  <FormInputContainer>
                    <FormInput
                      id={formValueKeys.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[formValueKeys.firstName]}
                      type="text"
                      placeholder={firstNameLabel}
                    />
                    <FormInputLabel visible={!!values[formValueKeys.firstName]}>
                      {firstNameLabel}
                    </FormInputLabel>
                    <ErrorMessage
                      name={formValueKeys.firstName}
                      component={DisplayErrorMessageTopMargin}
                    />
                  </FormInputContainer>
                  <FormInputContainer>
                    <FormInput
                      id={formValueKeys.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[formValueKeys.lastName]}
                      type="text"
                      placeholder={lastNameLabel}
                    />
                    <FormInputLabel visible={!!values[formValueKeys.lastName]}>
                      {lastNameLabel}
                    </FormInputLabel>
                    <ErrorMessage
                      name={formValueKeys.lastName}
                      component={DisplayErrorMessageTopMargin}
                    />
                  </FormInputContainer>
                </NamesSection>
                {/* <FormSection> */}
                {/*  <FormInputContainer> */}
                {/*    <FormInput */}
                {/*      id={formValueKeys.email} */}
                {/*      onChange={handleChange} */}
                {/*      onBlur={handleBlur} */}
                {/*      value={values[formValueKeys.email]} */}
                {/*      type="email" */}
                {/*      placeholder="Email Address" */}
                {/*    /> */}
                {/*    <FormInputLabel visible={!!values[formValueKeys.email]}> */}
                {/*      Email Address */}
                {/*    </FormInputLabel> */}
                {/*    <ErrorMessage */}
                {/*      name={formValueKeys.email} */}
                {/*      component={DisplayErrorMessageTopMargin} */}
                {/*    /> */}
                {/*  </FormInputContainer> */}
                {/* </FormSection> */}
                <FormSection>
                  <FormInputContainer>
                    <FormInput
                      id={formValueKeys.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[formValueKeys.country]}
                      type="text"
                      placeholder={countryLabel}
                    />
                    <FormInputLabel visible={!!values[formValueKeys.country]}>
                      {countryLabel}
                    </FormInputLabel>
                  </FormInputContainer>
                </FormSection>
                <FormSection>
                  <FormInputContainer>
                    <FormInput
                      id={formValueKeys.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[formValueKeys.phoneNumber]}
                      type="tel"
                      placeholder={phoneNumberLabel}
                    />
                    <FormInputLabel visible={!!values[formValueKeys.phoneNumber]}>
                      {phoneNumberLabel}
                    </FormInputLabel>
                  </FormInputContainer>
                </FormSection>
                <FormSection>
                  <FormInputContainer>
                    <FormInput
                      id={formValueKeys.company}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[formValueKeys.company]}
                      type="text"
                      placeholder={companyLabel}
                    />
                    <FormInputLabel visible={!!values[formValueKeys.company]}>
                      {companyLabel}
                    </FormInputLabel>
                  </FormInputContainer>
                </FormSection>
                <SaveButtonWrapper>
                  <Button type="submit" loading={isSubmitting} mobileWidth={BUTTON_WIDTHS.full}>
                    {getProfilePageSaveButton(copy)}
                  </Button>
                  {status && status.firestore && (
                    <DisplayErrorMessage>
                      {getProfilePageUpdateGenericError(copy)}
                    </DisplayErrorMessage>
                  )}
                </SaveButtonWrapper>
              </form>
            </React.Fragment>
          );
        }}
      </Formik>
    </section>
  );
};

export default ProfileDetails;
