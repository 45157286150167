// @flow
import React from 'react';
import SideCollageLayout, {
  SideArt,
} from '../../../../../layouts/components/SideCollageLayout/SideCollageLayout';
import { COLLAGES } from '../../../../../images/paths';
import { GenericErrorInfo } from '../../../ErrorInfo/ErrorInfo';

export const ErrorLayout = ({ children, ...props }: any) => {
  return (
    <SideCollageLayout
      sideArt={<SideArt background="#4A9495" image={COLLAGES.notFound} />}
      {...props}
    >
      {children}
    </SideCollageLayout>
  );
};

type Props = {};

const GenericError = () => (
  <ErrorLayout>
    <GenericErrorInfo />
  </ErrorLayout>
);

export default GenericError;
