// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SignInModalGraphMdl = {
  data: {
    sign_in_header: {
      text: string,
    },
    sign_up_button: {
      text: string,
    },
    email_address_placeholder: {
      text: string,
    },
    password_placeholder: {
      text: string,
    },
    sign_in_button: {
      text: string,
    },
    forgot_password_link: {
      text: string,
    },
    or_divider: {
      text: string,
    },
    continue_with_facebook_button: {
      text: string,
    },
    continue_with_google_button: {
      text: string,
    },
    error_message: {
      text: string,
    },
    sign_in_again_prompt: {
      html: string,
    },
  },
};

export const getSignInModalHeading = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.sign_in_header.text', '') || '';
};

export const getSignInModalSignUpButton = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.sign_up_button.text', '') || '';
};

export const getSignInModalEmailPlaceholder = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.email_address_placeholder.text', '') || '';
};

export const getSignInModalPasswordPlaceholder = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.password_placeholder.text', '') || '';
};

export const getSignInModalSignInButton = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.sign_in_button.text', '') || '';
};

export const getSignInModalForgotPassword = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.forgot_password_link.text', '') || '';
};

export const getSignInModalErrorMessage = (copy: SignInModalGraphMdl): string => {
  return get(copy, 'data.error_message.text', '') || '';
};

export const getSignInAgainPrompt = (page: SignInModalGraphMdl): string => {
  return get(page, 'data.sign_in_again_prompt.html', '') || '';
};

export const query = graphql`
  fragment SignInModal on PrismicSignInModal {
    data {
      sign_in_header {
        text
      }
      sign_up_button {
        text
      }
      email_address_placeholder {
        text
      }
      password_placeholder {
        text
      }
      sign_in_button {
        text
      }
      forgot_password_link {
        text
      }
      or_divider {
        text
      }
      continue_with_facebook_button {
        text
      }
      continue_with_google_button {
        text
      }
      error_message {
        text
      }
      sign_in_again_prompt {
        html
      }
    }
  }
`;
