// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import onClickOutside from 'react-onclickoutside';
import {
  bodyTextCss,
  smallMediumTextCss,
  smallPlainTextMonoCss,
  monoNavCss,
} from '../../../../styles/typography/typography';
import { buttonResetCss } from '../../../Button/Button';
import { useMiscCopy } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  useUserEmail,
  useUserName,
} from '../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useAuthContext } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import { getMiscCopySignOut } from '../../../../cms/miscCopy';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import ProfileNavLinks from '../../../../routing/components/ProfileNavLinks/ProfileNavLinks';
import AuthRestricted from '../../../../auth/components/AuthRestricted/AuthRestricted';
import SiteNavLinks from '../../../../routing/components/SiteNavLinks/SiteNavLinks';
import {
  MOBILE_DEVICE_BREAKPOINT,
  showOnSmallDesktopDeviceCss,
} from '../../../../styles/responsive';
import { SignInButton, SignUpButton } from '../HeaderOptions/HeaderOptions';
import { BANNERS } from '../../../../images/paths';
import { USER_ROLES } from '../../../../user/subscriptions/data';
import {
  creatorBgColor,
  creatorProBgColor,
  businessBgColor,
  enterpriseBgColor,
  personalBgColor,
  evokeBorderColor,
  guestBgColor,
} from '../../../../styles/config/colors';
import { useTranslation } from 'react-i18next';

const Container = styled('section')`
  background-color: #fff;
  border: solid ${evokeBorderColor};
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 300px;
  overflow: hidden;
`;

const Header = styled('header')`
  padding: 13px 15px;
`;

const RoleBanner = styled('div')`
  background-size: cover;
  text-align: center;
  ${monoNavCss};
  text-transform: uppercase;
  padding: 4px;
  border-radius: 3px;
  color: #fff;
`;

export const creatorRoleCss = css`
  background-color: ${creatorBgColor};
  background-image: url(${BANNERS.creator});
`;

export const creatorProRoleCss = css`
  background-color: ${creatorProBgColor};
  background-image: url(${BANNERS.creatorPro});
`;

export const guestRoleCss = css`
  background-color: ${guestBgColor};
`;

export const personalRoleCss = css`
  background-color: ${personalBgColor};
`;

export const businessRoleCss = css`
  background-color: ${businessBgColor};
`;

export const enterpriseRoleCss = css`
  background-color: ${enterpriseBgColor};

  ${MOBILE_DEVICE_BREAKPOINT} {
    background-size: 140%;
  }
`;

const Name = styled('p')`
  ${smallMediumTextCss};
`;

const Email = styled('p')`
  ${smallPlainTextMonoCss};
`;

const Divider = styled('hr')`
  margin: 0;
  border: 0;
  border-bottom: 2px solid rgba(77, 77, 77, 0.1);
  margin: 0 15px;
`;

const navLinkClass = css`
  ${bodyTextCss};
  display: block;
  padding: 0 5px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover {
    background: rgba(240, 240, 235, 0.05);
  }

  &:focus {
    outline: none;
  }
`;

const NavLinks = styled('ul')`
  padding: 10px 15px;

  li {
    &:not(:first-child) {
      margin-top: 5px;
    }
  }

  a {
    ${navLinkClass};
  }
`;

const NavButton = styled('button')`
  ${buttonResetCss};
  ${navLinkClass};
  width: 100%;
  text-align: left;
  padding: 0px 15px;
`;

const signoutClass = css`
  margin-top: 12px;
  margin-bottom: 15px;
`;

const SignedOutOptions = styled('div')`
  ${showOnSmallDesktopDeviceCss};
  display: flex;
  margin-top: 15px;

  > button {
    flex: 1;
    min-width: 0;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }
`;

type Props = {
  onClose: () => void,
};

const ProfileMenu = ({ onClose }: Props) => {
  const miscCopy = useMiscCopy();
  const { logout } = useAuthContext();
  const name = useUserName();
  const email = useUserEmail();
  const handleSignOut = () => {
    onClose();
    logout();
  };
  const { userRole } = useSubscriptionsContext();
  const { t } = useTranslation();

  ProfileMenu.handleClickOutside = () => {
    onClose();
  };

  const getPlanName = () => {
    if (userRole === USER_ROLES.creator) {
      return t('general.planCode.member', '');
    }

    if (userRole === USER_ROLES.creatorPro) {
      return t('general.planCode.loyalMember', '');
    }

    if (userRole === USER_ROLES.business) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (userRole === USER_ROLES.enterprise) {
      return t('general.planCode.enterprise', '');
    }

    return t('general.planCode.member', '');
  };

  return (
    <Container>
      <AuthRestricted authOnly={false}>
        <SignedOutOptions className={showOnSmallDesktopDeviceCss}>
          <SignInButton />
          <SignUpButton />
        </SignedOutOptions>
      </AuthRestricted>
      <AuthRestricted>
        <RoleBanner
          className={cx({
            [guestRoleCss]:
              userRole === USER_ROLES.creator || USER_ROLES.noSubscriptions === userRole,
            [personalRoleCss]: userRole === USER_ROLES.creatorPro,
            [businessRoleCss]: userRole === USER_ROLES.business,
            [enterpriseRoleCss]: userRole === USER_ROLES.enterprise,
          })}
        >
          {getPlanName()}
        </RoleBanner>
        <Header>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </Header>
        <Divider />
      </AuthRestricted>
      <nav className={showOnSmallDesktopDeviceCss}>
        <NavLinks>
          <SiteNavLinks>
            {links => {
              return links.map((Link, index) => {
                return (
                  <li key={index.toString()}>
                    <Link className={navLinkClass} onClick={onClose} />
                  </li>
                );
              });
            }}
          </SiteNavLinks>
        </NavLinks>
      </nav>
      <AuthRestricted>
        <nav>
          <NavLinks>
            <ProfileNavLinks>
              {links => {
                return links.map((Link, index) => {
                  return (
                    <li key={index.toString()}>
                      <Link className={navLinkClass} onClick={onClose} />
                    </li>
                  );
                });
              }}
            </ProfileNavLinks>
          </NavLinks>
          <Divider />
          <NavButton type="button" className={signoutClass} onClick={handleSignOut}>
            {getMiscCopySignOut(miscCopy)}
          </NavButton>
        </nav>
      </AuthRestricted>
    </Container>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => ProfileMenu.handleClickOutside,
};

export default onClickOutside(ProfileMenu, clickOutsideConfig);
