// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';
import type { CollectionsPageGraphMdl } from './collectionsPage';

export type CuratedCollectionRelatedCollectionDocumentGraphMdl = {
  uid: string,
  data: {
    collection_image: {
      url: string,
    },
    collection_title: {
      text: string,
    },
  },
};

export type CuratedCollectionRelatedCollectionGraphMdl = {
  collection_related_list: {
    document: Array<CuratedCollectionRelatedCollectionDocumentGraphMdl>,
  },
};

// TODO ADD TO PRISMIC THE UID OF THE RELATED LIST FOR NAVIGATION PURPOSES.

export type CuratedCollectionGraphMdl = {
  uid: string,
  type: string,
  data: {
    collection_title: {
      text: string,
    },
    collection_image: {
      alt: string,
      url: string,
    },
    collection_tags: {
      text: string,
    },
    collection_description: {
      html: string,
    },
    collection_video_url: string,
    collection_video_embed: string,
    collection_songs: {
      text: string,
    },
    collection_related_list: Array<CuratedCollectionRelatedCollectionGraphMdl>,
    creator_site_collection: boolean,
    hide_on_whats_new: boolean,
  },
  featured_image: {
    url: string,
    alt: String,
  },
};

export const getCuratedCollectionId = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'uid', '') || '';
};

export const getCuratedCollectionDocumentType = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'type', '') || '';
};

export const getCuratedCollectionTitle = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_title.text', '') || '';
};

export const getCuratedCollectionImage = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_image.url', '') || '';
};
export const getCuratedCollectionImageAltText = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_image.alt', '') || '';
};

export const getCuratedCollectionTags = (collection: CuratedCollectionGraphMdl): Array<string> => {
  const tagString = get(collection, 'data.collection_tags.text', '') || '';
  return tagString
    .split(',')
    .filter(tag => tag)
    .map(tag => tag.trim());
};

export const getCuratedCollectionDescription = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_description.html', '') || '';
};

export const getCuratedCollectionVideoUrl = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_video_url', '') || '';
};

export const getCuratedCollectionVideoEmbed = (collection: CuratedCollectionGraphMdl): string => {
  return get(collection, 'data.collection_video_embed', '') || '';
};

export const getCuratedCollectionSongs = (collection: CuratedCollectionGraphMdl): Array<string> => {
  const songsString = get(collection, 'data.collection_songs.text', '') || '';
  return songsString.split(', ').filter(song => song);
};

export const getCuratedCollectionRelatedCollections = (
  collection: CuratedCollectionGraphMdl
): Array<CuratedCollectionRelatedCollectionGraphMdl> => {
  return get(collection, 'data.collection_related_list', '') || '';
};

export const getCuratedCollectionRelatedCollectionDocument = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl | CollectionsPageGraphMdl
): CuratedCollectionRelatedCollectionDocumentGraphMdl => {
  const relatedDocuments = get(relatedCollection, 'related_collection.document', '');
  return relatedDocuments[0];
};

export const getCuratedCollectionRelatedCollectionDocumentSlug = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl | CollectionsPageGraphMdl
): string => {
  const relatedDocument = getCuratedCollectionRelatedCollectionDocument(relatedCollection);
  return get(relatedDocument, 'uid', '');
};

export const getCuratedCollectionRelatedCollectionDocumentImage = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl
): string => {
  const relatedDocument = getCuratedCollectionRelatedCollectionDocument(relatedCollection);
  return get(relatedDocument, 'data.collection_image.url', '');
};

export const getCuratedCollectionRelatedCollectionDocumentTitle = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl
): string => {
  const relatedDocument = getCuratedCollectionRelatedCollectionDocument(relatedCollection);
  return get(relatedDocument, 'data.collection_title.text', '');
};

export const getCuratedCollectionRelatedCollectionSongs = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl
): string => {
  const relatedDocument = getCuratedCollectionRelatedCollectionDocument(relatedCollection);
  return get(relatedDocument, 'data.collection_songs.text', '');
};

export const getCuratedCollectionRelatedDisplayInCreator = (
  relatedCollection: CuratedCollectionRelatedCollectionGraphMdl
): boolean => {
  const relatedDocument = getCuratedCollectionRelatedCollectionDocument(relatedCollection);
  return get(relatedDocument, 'data.creator_site_collection', null);
};

export const getCuratedCollectionLastPublicationDate = (
  curatedCollection: CuratedCollectionGraphMdl
): string => {
  return (
    get(curatedCollection, 'last_publication_date', new Date().toJSON()) || new Date().toJSON()
  );
};

export const getCuratedCollection = (
  id: string,
  collections: Array<CuratedCollectionGraphMdl>
): CuratedCollectionGraphMdl | null => {
  const filteredCollections = collections.filter(
    collection => getCuratedCollectionId(collection) === id
  );

  if (filteredCollections.length > 0) {
    return filteredCollections[0];
  }
  return null;
};

export const sortCollectionsAlphabetically = (
  collections: Array<CuratedCollectionGraphMdl>
): Array<CuratedCollectionGraphMdl> => {
  return collections.sort((a, b) => {
    if (getCuratedCollectionTitle(a) < getCuratedCollectionTitle(b)) {
      return -1;
    }
    if (getCuratedCollectionTitle(a) > getCuratedCollectionTitle(b)) {
      return 1;
    }
    return 0;
  });
};

export const getRecentlyUpdatedCollections = (
  collections: Array<CuratedCollectionGraphMdl>
): Array<CuratedCollectionGraphMdl> => {
  const filteredByHideOnWhatsNew = collections.filter(collection => {
    return !collection.data.hide_on_whats_new || collection.data.hide_on_whats_new === null;
  });
  const sortedByDate = filteredByHideOnWhatsNew.sort((a, b) => {
    return (
      new Date(getCuratedCollectionLastPublicationDate(b)) -
      new Date(getCuratedCollectionLastPublicationDate(a))
    );
  });
  return sortedByDate;
};

export const getCuratedCollectionIsCreatorCollection = (
  collection: CuratedCollectionGraphMdl
): boolean => {
  return get(collection, 'data.creator_site_collection', null);
};

export const query = graphql`
  fragment CuratedCollection on PrismicCuratedCollection {
    uid
    type
    last_publication_date
    data {
      collection_title {
        text
      }
      collection_image {
        url
        alt
      }
      collection_tags {
        text
      }
      collection_description {
        html
      }
      collection_video_url
      collection_video_embed
      collection_songs {
        text
      }
      collection_related_list {
        related_collection {
          document {
            uid
            data {
              collection_image {
                url
              }
              collection_title {
                text
              }
              collection_songs {
                text
              }
              creator_site_collection
            }
          }
        }
      }
      creator_site_collection
      hide_on_whats_new
    }
  }
`;
