// @flow
import { get } from 'lodash';
import { firestore } from '../firestore';
import type { AllocatedSeatMdl, CouponMdl, FBUserMdl, SubscriptionStatus } from './user';
import { getFirestoreUserData, SUBSCRIPTION_STATUSES, PLAN_INTERVAL_PRICES } from './user';
import { getDateString } from '../../../utils/date';
import { BILLING_CYCLES, CURRENCY_CODES } from '../../../user/subscriptions/data';
import { useMiscCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getMiscCopyMonthlyButton,
  getMiscCopyQuarterlyButton,
  getMiscCopyBiannuallyButton,
  getMiscCopyAnnuallyButton,
} from '../../../cms/miscCopy';

export type ChannelYouTubeMdl = {
  icon: string,
  id: string,
  link: string,
  name: string,
};

export type ChannelMdl = {
  active: boolean,
  id?: string,
  owner: string,
  parentChannelId?: string,
  subscriptionId?: string,
  youtube?: ChannelYouTubeMdl,
  extraChannels?: Array<ChannelYouTubeMdl>,
  updatedAt?: Date,
  createdAt?: Date,
};

export const getChannelId = (channel: ChannelMdl): string => {
  return get(channel, 'id', '');
};

export const getChannelParentChannel = (channel: ChannelMdl): string => {
  return get(channel, 'parentChannelId', '');
};

export const getChannelSubscriptionId = (channel: ChannelMdl): string => {
  return get(channel, 'subscriptionId', '');
};

export const getChannelCreatedAt = (channel: ChannelMdl): number => {
  return get(channel, 'createdAt.seconds', '');
};

const channelsRef = firestore.collection('channels');

export const getUserAllocatedSeats = (user: FBUserMdl): Array<AllocatedSeatMdl> => {
  return get(user, 'account.seats.allocatedSeats', []);
};

export const getUserFreeTrialEligibility = (user: FBUserMdl): boolean => {
  // TO DO FREE TRIAL: once the data is properly set on database, the  default value should be revised
  return get(user, 'account.isUserFreeTrialEligible', false);
};

export const fetchFirestoreUserAccountCurrentSeats = (
  uid: string
): Promise<Array<AllocatedSeatMdl>> => {
  return getFirestoreUserData(uid)
    .then((data: FBUserMdl) => {
      return getUserAllocatedSeats(data);
    })
    .catch(error => {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when fetching firestore user account current seats'
      );
      Sentry.captureException(error);
      console.error('fetchFirestoreUserAccountCurrentSeats', error);
      return Promise.reject(error);
    });
};

export type CurrentChannelsResponseMdl = {
  [string]: ChannelMdl,
};

export const fetchFirestoreUserCurrentChannels = (
  uid: string
): Promise<CurrentChannelsResponseMdl> => {
  const userChannelsRef = channelsRef.where('owner', '==', uid).where('active', '==', true);
  return userChannelsRef
    .get()
    .then(querySnapshot => {
      const results = {};
      querySnapshot.forEach(doc => {
        results[doc.id] = doc.data();
      });
      return results;
    })
    .catch(error => {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when fetching firestore user current channels');
      Sentry.captureException(error);
      console.error('firestoreFetchUserCurrentChannels', error);
      return Promise.reject(error);
    });
};

export const fetchFirestoreUserExtraChannels = (uid: string): Promise<Array<ChannelMdl>> => {
  const userChannelsRef = channelsRef
    .where('owner', '==', uid)
    .where('active', '==', true)
    .orderBy('parentChannelId');
  return userChannelsRef
    .get()
    .then(querySnapshot => {
      const results = [];
      querySnapshot.forEach(doc => {
        const result = {
          id: doc.id,
          ...doc.data(),
        };

        results.push(result);
      });
      return results;
    })
    .catch(error => {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when fetching firestore user extra channels');
      Sentry.captureException(error);
      console.error('fetchFirestoreUserExtraChannels', error);
      return Promise.reject(error);
    });
};

export type UserSubscriptionMdl = {
  key: string,
  seat: AllocatedSeatMdl,
  channel: ChannelMdl,
  extraChannels?: Array<ChannelMdl>,
};

export const getSubscriptionChannelYouTube = (
  subscription: UserSubscriptionMdl
): ChannelYouTubeMdl | null => {
  return get(subscription, 'channel.youtube', null);
};

export const getSeatPlanKey = (seat: AllocatedSeatMdl): string => {
  return seat.plan;
};

export const getSeatMonthlyTotalCents = (seat: AllocatedSeatMdl): number => {
  const { monthlyTotalCents = 0 } = seat;
  return monthlyTotalCents;
};

export const getSeatChannelId = (seat: AllocatedSeatMdl): string => {
  return seat.channelId;
};

export const getSeatCurrencyCode = (seat: AllocatedSeatMdl): string => {
  return get(seat, 'currency', CURRENCY_CODES.default);
};

export const getSubscriptionSeat = (subscription: UserSubscriptionMdl): AllocatedSeatMdl => {
  return subscription.seat;
};

export const getSubscriptionPlanKey = (subscription: UserSubscriptionMdl): string => {
  return getSeatPlanKey(getSubscriptionSeat(subscription));
};

export const getSubscriptionChannel = (subscription: UserSubscriptionMdl): ChannelMdl => {
  return subscription.channel;
};

export const getYouTubeChannelConnection = (channel: ChannelMdl) => {
  return get(channel, 'youtube', null);
};

export const getChannelYouTubeId = (youtube: ChannelYouTubeMdl | null): string => {
  if (youtube === null) {
    return '';
  }
  return get(youtube, 'id', '');
};

export const getChannelYouTubeName = (youtube: ChannelYouTubeMdl): string => {
  return get(youtube, 'name', '');
};

export const getYoutubeIdFromSubscription = (subscription: UserSubscriptionMdl): string => {
  return getChannelYouTubeId(getSubscriptionChannelYouTube(subscription));
};

export const getSubscriptionExtraChannels = (
  subscription: UserSubscriptionMdl
): Array<ChannelMdl> => {
  return get(subscription, 'extraChannels', []);
};

export const getSubscriptionCoupons = (subscription: UserSubscriptionMdl): Array<CouponMdl> => {
  return get(subscription, 'seat.coupons', []);
};

export const getSubscriptionChannelId = (subscription: UserSubscriptionMdl): string => {
  return getSeatChannelId(getSubscriptionSeat(subscription));
};

export const getSubscriptionCurrencyCode = (subscription: UserSubscriptionMdl): string => {
  return getSeatCurrencyCode(getSubscriptionSeat(subscription));
};

export const doesChannelHaveYouTubeChannelConnected = (channel: ChannelMdl): boolean => {
  return !!get(channel, 'youtube', null);
};

export const getChannelYouTubeChannelName = (channel: ChannelMdl): string => {
  return get(channel, 'youtube.name', '');
};

export const getChannelExtraYouTubeChannels = (channel: ChannelMdl): Array<ChannelYouTubeMdl> => {
  return get(channel, 'extraChannels', '');
};

export const getSeatInterval = (subscription: UserSubscriptionMdl): string => {
  return get(subscription, 'seat.interval', 'monthly');
};

export const getSubscriptionConnectedYouTubeChannelName = (
  subscription: UserSubscriptionMdl
): string => {
  return getChannelYouTubeChannelName(getSubscriptionChannel(subscription));
};

//  Retrieve Firestore data and create UserSubscriptionMdl to be used as data for all user subscription transactions

export const fetchFirestoreUserSubscriptions = (
  uid: string
): Promise<Array<UserSubscriptionMdl>> => {
  const currentSeatsPromise = fetchFirestoreUserAccountCurrentSeats(uid);
  const activeChannelsPromise = fetchFirestoreUserCurrentChannels(uid);
  const activeExtraChannelsPromise = fetchFirestoreUserExtraChannels(uid);

  return Promise.all([currentSeatsPromise, activeChannelsPromise, activeExtraChannelsPromise]).then(
    ([currentSeats, activeChannels, activeExtraChannels]: [
      Array<AllocatedSeatMdl>,
      CurrentChannelsResponseMdl,
      Array<ChannelMdl>
    ]) => {
      return currentSeats.map((seat: AllocatedSeatMdl) => {
        const channel = get(activeChannels, `${seat.channelId}`, null);

        //  Check if the current seat also has any extra channels attached to the primary channel
        const seatExtraChannels = activeExtraChannels.filter((activeExtraChannel: ChannelMdl) => {
          const currentExtraChannelId = getChannelParentChannel(activeExtraChannel);
          const primaryChannelId = getSeatChannelId(seat);
          return currentExtraChannelId === primaryChannelId;
        });

        //  sort extra channels on ascending order by their updated at value - ordered by the latest updated one at the end
        const sortedSeatExtraChannels = seatExtraChannels.sort((channelA, channelB) => {
          const dateA = new Date(getChannelCreatedAt(channelA) * 1000);
          const dateB = new Date(getChannelCreatedAt(channelB) * 1000);
          return dateA - dateB;
        });

        const extraChannels = channel ? sortedSeatExtraChannels : [];

        return {
          key: getSeatChannelId(seat),
          seat,
          channel,
          extraChannels,
        };
      });
    }
  );
};

export const doesSubscriptionHaveYouTubeChannelConnected = (
  subscription: UserSubscriptionMdl
): boolean => {
  return doesChannelHaveYouTubeChannelConnected(getSubscriptionChannel(subscription));
};

export const getSubscriptionYouTubeData = (subscription: UserSubscriptionMdl) => {
  if (!doesSubscriptionHaveYouTubeChannelConnected(subscription)) {
    return null;
  }
  const channel = getSubscriptionChannel(subscription);
  if (!channel) {
    return null;
  }
  return channel.youtube ? channel.youtube : null;
};

export const getSeatStatus = (seat: AllocatedSeatMdl): SubscriptionStatus => {
  return get(seat, 'status', '');
};

export const getSubscriptionStatus = (subscription: UserSubscriptionMdl): SubscriptionStatus => {
  const seat = getSubscriptionSeat(subscription);
  return getSeatStatus(seat);
};

export const isSubscriptionCancelOrDowngradePending = (
  subscription: UserSubscriptionMdl
): boolean => {
  const status = getSubscriptionStatus(subscription);
  return (
    status === SUBSCRIPTION_STATUSES.cancel_pending ||
    status === SUBSCRIPTION_STATUSES.downgrade_to_free_pending
  );
};

export const getSubscriptionNextBillingDate = (subscription: UserSubscriptionMdl): string => {
  const nextBillingDateSeconds = get(subscription, 'seat.nextBillingDateSeconds');
  if (!nextBillingDateSeconds) {
    // todo - log error
    return ``;
  }
  return getDateString(nextBillingDateSeconds * 1000);
};

export const getSubscriptionValidUntilDate = (subscription: UserSubscriptionMdl): string => {
  const validUntilSeconds = get(subscription, 'seat.validUntilSeconds', 0);
  return getDateString(validUntilSeconds * 1000);
};

export const getSubscriptionTrialValidUntilDate = (subscription: UserSubscriptionMdl): string => {
  const validUntilSeconds = get(subscription, 'seat.trialPlanValidUntilSeconds', 0);
  return getDateString(validUntilSeconds * 1000);
};

export const getIntervalCycle = (intervals: any, interval: string) => {
  return get(intervals, `${interval}.cycle`, '');
};

export const getNextAvailableInterval = (intervals, defaultInterval: string): string => {
  if (intervals.annually.cycle === defaultInterval) {
    return get(intervals, `monthly.cycle`, '');
  }
  if (intervals.biannually.cycle === defaultInterval) {
    return get(intervals, `monthly.cycle`, '');
  }
  if (intervals.quarterly.cycle === defaultInterval) {
    return get(intervals, `monthly.cycle`, '');
  }
  return get(intervals, `annually.cycle`, '');
};

export const getIntervalLabel = (interval: string): string => {
  const miscCopy = useMiscCopy();

  if (interval === BILLING_CYCLES.annually.label) {
    return getMiscCopyAnnuallyButton(miscCopy);
  }
  if (interval === BILLING_CYCLES.biannually.label) {
    return getMiscCopyBiannuallyButton(miscCopy);
  }
  if (interval === BILLING_CYCLES.quarterly.label) {
    return getMiscCopyQuarterlyButton(miscCopy);
  }
  return getMiscCopyMonthlyButton(miscCopy);
};

export const getPlanIntervalPrice = (planKey: string, interval: string, locale: string) => {
  return get(PLAN_INTERVAL_PRICES, `[${locale}][${planKey}][${interval}]`, 0);
};
