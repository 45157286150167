// @flow
import React from 'react';
import styled from 'react-emotion';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import InfoScreen from './components/InfoScreen/InfoScreen';
import {
  useMiscCopy,
  useSignUpPage,
  useTermsPageCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getTermsPageBody, getTermsPageHeading } from '../../../cms/termsPage';
import PageComponent from '../../components/PageComponent/PageComponent';
import { LOCALES } from '../../../locales';
import { ROUTES } from '../../routes';
import { getLocalisedCurrentPath } from '../../../components/SiteFooter/SiteFooter';
import { getMiscEnglishLabel, getMiscJapaneseLabel } from '../../../cms/miscCopy';
import MusicLicenseLink from './components/MusicLicenseLink/MusicLicenseLink';
import { getPricingPlans } from '../../../cms/signup';
import { SUBSCRIPTIONS } from '../../../user/subscriptions/data';

const LocaleLinks = styled('div')`
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;

  & a {
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const Content = () => {
  const plans = [
    SUBSCRIPTIONS.creatorPro.key,
    SUBSCRIPTIONS.business.key,
    SUBSCRIPTIONS.enterprise.key,
  ];

  const signUpPage = useSignUpPage();
  const subscriptionPricingPlans = getPricingPlans(signUpPage).filter(pricingPlan =>
    plans.includes(pricingPlan)
  );

  const copy = useTermsPageCopy();
  const miscCopy = useMiscCopy();

  return (
    <LayoutWrapper bodyPadding>
      <InfoScreen heading={getTermsPageHeading(copy)} body={getTermsPageBody(copy)}>
        <LocaleLinks>
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, '')}>
            {getMiscEnglishLabel(miscCopy)}
          </a>
          &nbsp;/&nbsp;
          <a href={getLocalisedCurrentPath(ROUTES.terms.path, LOCALES.japanese.path)}>
            {getMiscJapaneseLabel(miscCopy)}
          </a>
        </LocaleLinks>

        {subscriptionPricingPlans.map(uid => {
          return <MusicLicenseLink key={uid} planCode={uid} />;
        })}

        {/* TODO: 20230523 復活させる可能性があるため、コメントアウトで対応。 */}
        {/* <MusicLicenseLink>
          {parse(getSingleTrackLimitedMediaMusicLicenseLink(copy))}
        </MusicLicenseLink>
        <MusicLicenseLink>{parse(getSingleTrackAllMediaMusicLicenseLink(copy))}</MusicLicenseLink>
        <MusicLicenseLink>{parse(getExclusiveRightsTrackLicenseLink(copy))}</MusicLicenseLink> */}
      </InfoScreen>
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

const TermsScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default TermsScreen;
