// @flow
import React, { useContext, useEffect, useState } from 'react';
import ls from 'local-storage';
import firebase from 'firebase/app';
import { auth0ApiHandler } from '../../../api/auth/auth0';
import { ROUTES } from '../../../routing/routes';
import { ERROR_PATH_CODES } from '../../../errors/components/ErrorCodeHandler/ErrorCodeHandler';
import { analyticsSetUser } from '../../../analytics';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';

export const KEY_USER_ID = 'USER_ID';

export type SignUpSourceData = {
  signUpSource: string,
  signUpCampaign?: string,
};

export type AuthContextState = {
  userID: string,
  checkingAuthentication: boolean,
  checkedAuthentication: boolean,
  isAuthenticated: boolean,
  notAuthenticated: boolean,
  handleAuthenticated: () => void,
  handleUserSignedIn: () => void,
  logout: () => void,
  tokenRenewFailedLogout: () => void,
  signUpSourceData: SignUpSourceData,
  setSignUpSourceData: SignUpSourceData => void,
};

export const AuthContext = React.createContext<AuthContextState>({});

export const useAuthContext = (): AuthContextState => {
  return useContext(AuthContext);
};

export const useIsAuthenticated = (): boolean => {
  return useAuthContext().isAuthenticated;
};

export const useIsNotAuthenticated = (): boolean => {
  return useAuthContext().notAuthenticated;
};

export const useUserId = (): string => {
  return useAuthContext().userID;
};

const getInitUserId = (): string => {
  return ls.get(KEY_USER_ID) || '';
};

type Props = {
  children: any,
};

const AuthWrapper = ({ children }: Props) => {
  const [checkingAuthentication, setCheckingAuthentication] = useState(true);
  const [checkedAuthentication, setCheckedAuthentication] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(auth0ApiHandler.initAuth());
  const [userID, setUserID] = useState(isAuthenticated ? getInitUserId() : '');
  const { moengage } = useAnalyticsMixpanelContext();
  const [signUpSourceData, setSignUpSourceData] = useState({
    signUpSource: '',
    signUpCampaign: '',
  });

  const clearStoredAuth = () => {
    setIsAuthenticated(false);
    auth0ApiHandler.reset();
    if (moengage) {
      moengage.destroySession();
    }
    ls.remove(KEY_USER_ID);
    ls.remove('AUTH_FROM_SIGNUP');
    ls.remove('AUTH_FROM_LOGIN');
    ls.remove('INITIAL_SUBSCRIPTIONS_COUNT');
  };

  const handleSetUserID = (uid: string) => {
    setUserID(uid);
    ls.set(KEY_USER_ID, uid);
  };

  const handleAuthenticated = () => {
    setIsAuthenticated(true);
  };

  const authenticateFirebase = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        handleSetUserID(user.uid);
      } else {
        console.warn(`User is not signed in.`);
        clearStoredAuth();
        window.location.href = ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code });
      }
    });
  };

  const handleUserSignedIn = (): Promise<any> => {
    handleAuthenticated();
    return authenticateFirebase();
  };

  const logout = () => {
    clearStoredAuth();
    auth0ApiHandler.logout();
  };

  const tokenRenewFailedLogout = (): Promise<any> => {
    clearStoredAuth();
    ls.set('KEY_TOKEN_RENEW_ERROR', true);
    ls.set('KEY_TOKEN_RENEW_ERROR_REDIRECT', window.location.href);
    auth0ApiHandler.logout();
  };

  useEffect(() => {
    const isTokenRenewError = ls.get('KEY_TOKEN_RENEW_ERROR');
    const tokenRenewErrorRedirect = ls.get('KEY_TOKEN_RENEW_ERROR_REDIRECT');

    if (isTokenRenewError) {
      console.log(isTokenRenewError);
      const recordTokenRenewError = tokenRenewErrorRedirect;
      ls.set('KEY_TOKEN_RENEW_ERROR', '');
      ls.set('KEY_TOKEN_RENEW_ERROR_REDIRECT', false);
      ls.set('KEY_TOKEN_RENEW_ERROR_MODAL_OPEN', true);
      window.location.href = recordTokenRenewError;
    } else {
      ls.set('KEY_TOKEN_RENEW_ERROR', '');
      ls.set('KEY_TOKEN_RENEW_ERROR_REDIRECT', false);
    }
  }, []);

  useEffect(() => {
    analyticsSetUser(userID);
    try {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.configureScope(scope => {
        scope.setUser({ id: userID });
      });
    } catch (error) {
      console.error(error);
    }
  }, [userID]);

  useEffect(() => {
    if (auth0ApiHandler.initAuth()) {
      setIsAuthenticated(true);
      authenticateFirebase();
    } else {
      setIsAuthenticated(false);
    }
    setCheckedAuthentication(true);
    setCheckingAuthentication(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setSignUpSourceData({ signUpSource: '', signUpCampaign: '' });
    }
  }, [isAuthenticated]);

  const notAuthenticated = checkedAuthentication && !isAuthenticated;

  return (
    <AuthContext.Provider
      value={{
        userID,
        checkingAuthentication,
        checkedAuthentication,
        isAuthenticated,
        notAuthenticated,
        handleAuthenticated,
        handleUserSignedIn,
        logout,
        tokenRenewFailedLogout,
        signUpSourceData,
        setSignUpSourceData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
