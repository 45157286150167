// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  useSignUpPage,
  useSubscriptionsPage,
} from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { LargeHeading } from '../../../../../../../styles/typography/components/Heading/Heading';
import {
  getMoreFeatureAgencyContact,
  getMoreFeatureAgencyDescription,
  getMoreFeatureAgencyTitle,
  getMoreFeatureBroadcastContact,
  getMoreFeatureBroadcastDescription,
  getMoreFeatureBroadcastTitle,
  getMoreFeaturesDescription,
  getMoreFeaturesHeading,
} from '../../../../../../../cms/signup';
import {
  getManualPlansTitle,
  getManualPlansInstructions,
  getPlanBroadcastTitle,
  getPlanBroadcastMessage,
  getPlanAgencyTitle,
  getPlanAgencyMessage,
  getContactMessage,
} from '../../../../../../../cms/subscriptionsPage';
import { GeneralDescription } from '../../../../../../../styles/shared';
import FeatureCard from '../FeatureCard/FeatureCard';
import { ICONS } from '../../../../../../../images/paths';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';

const Section = styled('section')`
  margin-top: 108px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 52px;
  }
`;

const FeatureCards = styled('ul')`
  margin-top: 25px;

  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {};

const FeaturesSection = (props): Props => {
  const signUpPage = useSignUpPage();
  const subscriptionsPage = useSubscriptionsPage();
  const { page } = props;

  const isSignUpPage = page === 'signup/subscriptions' ? true : false;

  return (
    <Section {...props}>
      <LargeHeading>
        {isSignUpPage ? getMoreFeaturesHeading(signUpPage) : getManualPlansTitle(subscriptionsPage)}
      </LargeHeading>
      <GeneralDescription>
        {isSignUpPage
          ? getMoreFeaturesDescription(signUpPage)
          : getManualPlansInstructions(subscriptionsPage)}
      </GeneralDescription>
      <FeatureCards>
        {/* <li> */}
        {/*  <FeatureCard */}
        {/*    title={getMoreFeatureBroadcastTitle(signUpPage)} */}
        {/*    description={getMoreFeatureBroadcastDescription(signUpPage)} */}
        {/*    contact={getMoreFeatureBroadcastContact(signUpPage)} */}
        {/*    icon={<img src={ICONS.broadcast} alt={getMoreFeatureBroadcastTitle(signUpPage)} />} */}
        {/*  /> */}
        {/* </li> */}
        <li>
          <FeatureCard
            title={
              isSignUpPage
                ? getMoreFeatureAgencyTitle(signUpPage)
                : getPlanAgencyTitle(subscriptionsPage)
            }
            description={
              isSignUpPage
                ? getMoreFeatureAgencyDescription(signUpPage)
                : getPlanAgencyMessage(subscriptionsPage)
            }
            contact={
              isSignUpPage
                ? getMoreFeatureAgencyContact(signUpPage)
                : getContactMessage(subscriptionsPage)
            }
            icon={
              <img
                src={ICONS.agency}
                alt={
                  isSignUpPage
                    ? getMoreFeatureAgencyTitle(signUpPage)
                    : getPlanAgencyTitle(subscriptionsPage)
                }
              />
            }
          />
        </li>
      </FeatureCards>
    </Section>
  );
};

export default FeaturesSection;
