import { transparentize } from 'polished';
import React from 'react';
import styled from 'react-emotion';
import { getMiscCopyCancelButton } from '../../../../../../../../cms/miscCopy';
import { BUTTON_WIDTHS, DarkButton } from '../../../../../../../../components/Button/Button';
import { useMiscCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import Modal, {
  ModalContainer,
  slimModalCss,
  slimModalOverlayCss,
} from '../../../../../../../../modals/components/Modal/Modal';
import { lightColor } from '../../../../../../../../styles/config/colors';
import { MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT } from '../../../../../../../../styles/responsive';
import BrowseLinkInlineButton from './BrowseLinkInlineButton';
import BookmarkInlineButton from './BookmarkInlineButton';
import HideTrackInlineButton from './HideTrackInlineButton';
import ShareInlineButton from './ShareInlineButton';
import SimilarSearchInlineButton from './SimilarSearchInlineButton';
import ViewLoopsInlineButton from './ViewLoopsInlineButton';
import ViewPackInlineButton from './ViewPackInlineButton';
import DownloadInlineButton from './DownloadInlineButton';
import GlobalShareInlineButton from '../../../../../../GlobalSongPlayer/components/GlobalPlayerActions/components/ActionMenuButton/components/GlobalShareInlineButton';
import MIDIDownloadInlineButton from './MIDIDownloadInlineButton';

type Props = {
  showAllActions: boolean,
  isGlobalPlayer?: boolean,
  onClose: () => void,
  hidePackActions?: boolean,
  loopsAvailable?: boolean,
};

const Body = styled('div')`
  padding: 50px 0px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 20px 0px;
  }
`;

const ActionsList = styled('ul')`
  width: 100%;
  margin: 5px 0px;

  li {
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 20px;
    padding: 3px 0px;
    border-bottom: 1px solid ${transparentize(0.9, lightColor)};

    &:last-of-type {
      border-bottom: none;
    }
  }
`;

const CancelButtonWrapper = styled('div')`
  margin: 15px 20px 0px 20px;
`;

const MoreSongActionsModal = ({
  showAllActions = false,
  onClose,
  isGlobalPlayer = false,
  hidePackActions = false,
  loopsAvailable = true,
}: Props) => {
  const handleClose = event => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };

  const miscCopy = useMiscCopy();
  return (
    <Modal className={slimModalCss} overlayClassName={slimModalOverlayCss} onClose={onClose}>
      <ModalContainer>
        <Body>
          <ActionsList>
            {showAllActions && (
              <>
                <MIDIDownloadInlineButton />
                <BookmarkInlineButton />
                <DownloadInlineButton />
                <BrowseLinkInlineButton hideTooltip={onClose} />
              </>
            )}
            {!hidePackActions && (
              <>
                <ViewPackInlineButton />
                {loopsAvailable && <ViewLoopsInlineButton hideTooltip={onClose} />}
              </>
            )}
            <SimilarSearchInlineButton />
            {isGlobalPlayer ? (
              <GlobalShareInlineButton hideTooltip={onClose} />
            ) : (
              <ShareInlineButton hideTooltip={onClose} />
            )}
            <HideTrackInlineButton />
          </ActionsList>
          <CancelButtonWrapper>
            <DarkButton onClick={handleClose} width={BUTTON_WIDTHS.full}>
              {getMiscCopyCancelButton(miscCopy)}
            </DarkButton>
          </CancelButtonWrapper>
        </Body>
      </ModalContainer>
    </Modal>
  );
};

export default MoreSongActionsModal;
