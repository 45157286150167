/* eslint-disable prettier/prettier */
// @flow
import {
  BOOKMARKS_ORDER_VALUES,
  type BookmarkSongWrapper,
} from './components/BookmarksManager/BookmarksManager';
import type { GroupedSongs } from '../../../ProfileScreen/views/ProfileDownloadHistoryView/components/DownloadHistory/DownloadHistory';
import { getSongDuration, isSongFullMix, isSongStem } from '../../../../../api/algolia/song';
import {
  getBookmarkKeywords,
  getBookmarkTimestamp,
} from '../../../../../api/firebase/user/bookmarks';
import { getDateString } from '../../../../../utils/date';
import { VISIBILITY_FILTERS } from '../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilter/filters';

export const getSortedBookmarks = (
  bookmarks: Array<BookmarkSongWrapper>,
  bookmarksOrder: string
): Array<BookmarkSongWrapper> => {
  if (bookmarksOrder === BOOKMARKS_ORDER_VALUES.longest) {
    return bookmarks.sort((bookmarkA: BookmarkSongWrapper, bookmarkB: BookmarkSongWrapper) => {
      return getSongDuration(bookmarkB.song) - getSongDuration(bookmarkA.song);
    });
  }

  if (bookmarksOrder === BOOKMARKS_ORDER_VALUES.shortest) {
    return bookmarks.sort((bookmarkA: BookmarkSongWrapper, bookmarkB: BookmarkSongWrapper) => {
      return getSongDuration(bookmarkA.song) - getSongDuration(bookmarkB.song);
    });
  }

  const bookmarksSortedByDate = bookmarks.sort(
    (bookmarkA: BookmarkSongWrapper, bookmarkB: BookmarkSongWrapper) => {
      return getBookmarkTimestamp(bookmarkB.bookmark) - getBookmarkTimestamp(bookmarkA.bookmark);
    }
  );

  if (bookmarksOrder === BOOKMARKS_ORDER_VALUES.oldest) {
    bookmarksSortedByDate.reverse();
  }

  return bookmarksSortedByDate;
};
const sortByLength = (bookmarks: Array<BookmarkSongWrapper>) => {
  const groups = [];

  bookmarks.forEach((bookmark: BookmarkSongWrapper) => {
    const date = getBookmarkTimestamp(bookmark.bookmark);
    const dateString = getDateString(getBookmarkTimestamp(bookmark.bookmark));
    const keywords = getBookmarkKeywords(bookmark.bookmark);
    const keywordsKey = keywords.join('::');
    const { song } = bookmark;
    const previousGroup = groups.length > 0 ? groups[groups.length - 1] : null;
    if (
      previousGroup &&
      previousGroup.dateKey === dateString &&
      previousGroup.keywordsKey === keywordsKey
    ) {
      const updatedGroups = {
        ...previousGroup,
        songs: previousGroup.songs.concat([song]),
      };
      groups.splice(groups.length - 1, 1, updatedGroups);
    } else {
      groups.push({
        date,
        dateKey: dateString,
        keywords,
        keywordsKey,
        songs: [song],
      });
    }
  });

  return groups.map(group => {
    return {
      date: group.date,
      keywords: group.keywords,
      tracks: group.songs,
    };
  });
};
const getFilteredBookmarks = (
  bookmarks: Array<BookmarkSongWrapper>,
  bookmarksFilter: string
): Array<BookmarkSongWrapper> => {
  if (bookmarksFilter === VISIBILITY_FILTERS.all) {
    return bookmarks;
  }
  return bookmarks.filter((bookmark: BookmarkSongWrapper) => {
    if (bookmarksFilter === VISIBILITY_FILTERS.fullMixes) {
      return isSongFullMix(bookmark.song);
    }
    return isSongStem(bookmark.song);
  });
};
export const getGroupedBookmarks = (
  bookmarks: Array<BookmarkSongWrapper>,
  bookmarksOrder: string,
  bookmarksFilter: string
): Array<GroupedSongs> => {
  const filteredBookmarks = getFilteredBookmarks(bookmarks, bookmarksFilter);

  const sortedBookmarks = getSortedBookmarks(filteredBookmarks, bookmarksOrder);

  if (
    bookmarksOrder === BOOKMARKS_ORDER_VALUES.shortest ||
    bookmarksOrder === BOOKMARKS_ORDER_VALUES.longest
  ) {
    return sortByLength(sortedBookmarks);
  }

  const dateOrder = [];
  let groups = {};

  sortedBookmarks.forEach((bookmark: BookmarkSongWrapper) => {
    const date = getBookmarkTimestamp(bookmark.bookmark);
    const dateString = getDateString(getBookmarkTimestamp(bookmark.bookmark));
    const keywords = getBookmarkKeywords(bookmark.bookmark);
    const keywordsKey = keywords.join('::');
    const { song } = bookmark;
    if (groups[dateString]) {
      let group = groups[dateString];
      if (group[keywordsKey]) {
        group = {
          ...group,
          [keywordsKey]: {
            ...group[keywordsKey],
            tracks: group[keywordsKey].tracks.concat([song]),
          },
        };
        groups = {
          ...groups,
          [dateString]: group,
        };
      } else {
        group[keywordsKey] = {
          date,
          keywords,
          tracks: [song],
        };
      }
    } else {
      groups[dateString] = {
        [keywordsKey]: {
          date,
          keywords,
          tracks: [song],
        },
      };
      dateOrder.push(dateString);
    }
  });

  const finalGroups: Array<GroupedSongs> = [];
  dateOrder.forEach((dateKey: string) => {
    const groupCollection = groups[dateKey];
    Object.keys(groupCollection).forEach(groupKey => {
      const group = groupCollection[groupKey];
      finalGroups.push(group);
    });
  });

  return finalGroups;
};

export const getUpdatedSortedBookmarks = (
  bookmarks: Array<BookmarkSongWrapper>,
  sortingMethod: string
): Array<BookmarkSongWrapper> => {
  if (['newest', 'oldest'].includes(sortingMethod)) {
    const bookmarksSortedByDate = bookmarks.sort(
      (bookmarkA: BookmarkSongWrapper, bookmarkB: BookmarkSongWrapper) => {
        return getBookmarkTimestamp(bookmarkB.bookmark) - getBookmarkTimestamp(bookmarkA.bookmark);
      }
    );

    if (sortingMethod === 'oldest') {
      return bookmarksSortedByDate.reverse();
    }

    return bookmarksSortedByDate;
  }
  return bookmarks;
};

export const getBookmarksByType = (
  bookmarks: Array<AlgoliaSongMdl>,
  songType: string
): Array<AlgoliaSongMdl> => {
  if (songType === 'fullMixes') {
    return bookmarks.filter(bookmark => isSongFullMix(bookmark));
  }

  if (songType === 'stems') {
    return bookmarks.filter(bookmark => isSongStem(bookmark));
  }

  return bookmarks;
};

export const getBookmarksCountByType = (
  bookmarks: Array<AlgoliaSongMdl>,
  songType: string
): number => {
  if (songType === 'fullMixes') {
    return bookmarks.filter(bookmark => isSongFullMix(bookmark)).length;
  }

  if (songType === 'stems') {
    return bookmarks.filter(bookmark => isSongStem(bookmark)).length;
  }

  return bookmarks.length;
};
