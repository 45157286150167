// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ChangeFreeTrialSubscriptionModalGraphMdl = {
  data: {
    free_trial_upgrade_title: {
      text: string,
    },
    free_trial_upgrade_instructions: {
      text: html,
    },
    free_trial_downgrade_title: {
      text: string,
    },
    free_trial_downgrade_instructions: {
      text: html,
    },
    free_trial_interval_title: {
      text: string,
    },
    free_trial_interval_instructions: {
      text: html,
    },
    free_trial_cancel_title: {
      text: string,
    },
    free_trial_cancel_instructions: {
      text: html,
    },
    free_trial_upgrade_cancelled_plan_title: {
      text: string,
    },
    free_trial_upgrade_cancelled_plan_instructions: {
      text: html,
    },
  },
};

export const getChangeFreeTrialSubscriptionModalUpgradeTitle = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_upgrade_title.text', '') || '';
};

export const getChangeFreeTrialSubscriptionModalUpgradeInstructions = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_upgrade_instructions.html', '') || '';
};

export const getChangeFreeTrialSubscriptionModalDowngradeTitle = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_downgrade_title.text', '') || '';
};

export const getChangeFreeTrialSubscriptionModalDowngradeInstructions = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_downgrade_instructions.html', '') || '';
};

export const getChangeFreeTrialSubscriptionModalIntervalTitle = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_interval_title.text', '') || '';
};

export const getChangeFreeTrialSubscriptionModalIntervalInstructions = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_interval_instructions.html', '') || '';
};

export const getChangeFreeTrialSubscriptionModalCancelTitle = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_cancel_titl.text', '') || '';
};

export const getChangeFreeTrialSubscriptionModalCancelInstructions = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_cancel_instructions.html', '') || '';
};

export const getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyTitle = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_upgrade_cancelled_plan_title.text', '') || '';
};

export const getChangeFreeTrialSubscriptionModalEndCancelledTrialEarlyInstructions = (
  copy: ChangeFreeTrialSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.free_trial_upgrade_cancelled_plan_instructions.html', '') || '';
};

export const query = graphql`
  fragment ChangeFreeTrialSubscriptionModal on PrismicChangeFreeTrialSubscriptionModal {
    data {
      free_trial_upgrade_title {
        text
      }
      free_trial_upgrade_instructions {
        html
      }
      free_trial_downgrade_title {
        text
      }
      free_trial_downgrade_instructions {
        html
      }
      free_trial_interval_title {
        text
      }
      free_trial_interval_instructions {
        html
      }
      free_trial_cancel_title {
        text
      }
      free_trial_cancel_instructions {
        html
      }
      free_trial_upgrade_cancelled_plan_title {
        text
      }
      free_trial_upgrade_cancelled_plan_instructions {
        html
      }
    }
  }
`;
