// @flow
import React from 'react';
import {
  getMusicPageFeaturedSfx,
  getMusicPageFeaturedSfxSectionTitle,
} from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import FeaturedTagSection from './FeaturedTagSection';

type Props = {
  viewAll?: boolean,
  viewAllLink?: boolean,
  oversizedTile?: boolean,
  page: string,
  rows: number,
};

const FeaturedSfx = ({
  viewAll = false,
  viewAllLink = false,
  oversizedTile = false,
  page,
  rows,
}: Props) => {
  const musicPage = useMusicPage();
  const sectionTitle = getMusicPageFeaturedSfxSectionTitle(musicPage);
  const tags = getMusicPageFeaturedSfx(musicPage);

  return (
    <FeaturedTagSection
      tagCategory="type"
      title={sectionTitle}
      tags={tags}
      viewAll={viewAll}
      viewAllLink={viewAllLink}
      oversizedTile={oversizedTile}
      page={page}
      rows={rows}
      filterSongType={'sfx'}
      searchPathParams={'&viewing=sfx'}
    />
  );
};

export default FeaturedSfx;
