// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackBookmarkAction, getTrackUnbookmarkAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import BookmarkActiveIcon from '../../../../../../../assets/inline-assets/bookmark-active-icon.svg';
import BookmarkIcon from '../../../../../../../assets/inline-assets/bookmark-icon.svg';
import { useSong } from '../../../../../SongContextWrapper/SongContextWrapper';
import { useBookmark, useRemoveBookmark } from '../../../../../../../api/firebase/user/bookmarks';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';
import { useIsSongBookmarked } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { getSongID } from '../../../../../../../api/algolia/song';
import { getPlayerKeyboardShortcuts } from '../../../../../../../utils/keyboardShortcuts';
import { PLAYER_TYPES } from '../../../../../../data';
import { useKeyboardShortcutRestricted } from '../../../../../../../toasts/hooks';
import { useAuthContext } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  useShowSignUp,
  useModalsContext,
} from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { USER_PERMISSIONS } from '../../../../../../../user/subscriptions/data';

type Props = {
  condensed?: boolean,
  playerType?: string,
  shortcutEnabled?: boolean,
  topLabel?: boolean,
};

const BookmarkButton = ({
  condensed = false,
  playerType = '',
  shortcutEnabled = false,
  topLabel = false,
}: Props) => {
  const song = useSong();
  const [bookmark, bookmarkBusy] = useBookmark(song);
  const [removeBookmark, removeBookmarkBusy] = useRemoveBookmark(song);
  const isBookmarked = useIsSongBookmarked(getSongID(song));
  const trackCopy = useTrackCopy();

  const restrictedShortcutToast = useKeyboardShortcutRestricted();

  const busy = bookmarkBusy || removeBookmarkBusy;
  const icon = isBookmarked ? <BookmarkActiveIcon /> : <BookmarkIcon />;

  const { isAuthenticated } = useAuthContext();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const canBookmarkSongs = hasSubscription && USER_PERMISSIONS[userRole].canBookmarkSongs;
  const showSignUp = useShowSignUp();
  const { showSubscriptionRequired } = useModalsContext();

  const label = isBookmarked
    ? getTrackUnbookmarkAction(trackCopy)
    : getTrackBookmarkAction(trackCopy);

  const action = () => {
    if (isAuthenticated) {
      if (busy) return;

      // サブスク無しの場合はサブスク登録のポップアップ表示。
      if (!canBookmarkSongs) {
        showSubscriptionRequired();
        return;
      }

      if (isBookmarked) {
        removeBookmark();
      } else {
        bookmark();
      }
      return;
    }

    // 未認証(アカウント登録していない)の場合はサインアップのポップアップ表示。
    showSignUp();
    return;
  };

  const keyboardShortcutHandlers = (() => {
    if (true) {
      return {
        bookmark_song: action,
      };
    }
    return {
      bookmark_song: restrictedShortcutToast,
    };
  })();

  return (
    <>
      {shortcutEnabled && (
        <GlobalHotKeys
          keyMap={getPlayerKeyboardShortcuts('results')}
          handlers={keyboardShortcutHandlers}
          allowChanges
        />
      )}
      <SongActionButton
        condensed={condensed}
        icon={busy ? <DownloadingIcon /> : icon}
        label={label}
        onClick={action}
        topLabel={topLabel}
      />
    </>
  );
};

export default BookmarkButton;
