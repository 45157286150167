// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import type { ChannelMdl } from '../../../../../../api/firebase/user/subscriptions';
import {
  getChannelCreatedAt,
  getChannelYouTubeChannelName,
  getChannelYouTubeId,
  getYouTubeChannelConnection,
} from '../../../../../../api/firebase/user/subscriptions';
import { useMiscCopy } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getMiscCopyDisconnectButton } from '../../../../../../cms/miscCopy';
import SpinnerIcon from '../../../../../../components/SpinnerIcon/SpinnerIcon';
import {
  Name,
  Icon,
  Info,
  Button,
  Container as containerFromAccount,
  spinnerClass,
  ContainerFlex,
  DisplayError,
  AllowlistUnsuccessfulMessage,
} from '../Account/Account';
import {
  getAllowListCurrentSate,
  ALLOWLIST_STATUSES,
  getAllowListCurrentStateForceSuccessfulIfPending,
} from '../../../../../../api/firebase/user/allowlist';
import type { AllowlistDisplayData } from '../../../../../../api/firebase/user/allowlist';
import AllowlistBadge from '../AllowlistBadge/AllowlistBadge';

const socialAccountClassName = 'socialAccount';
const socialAccountConnectedClassName = 'socialAccount--connected';
const socialAccountHoverClassName = 'socialAccount--hover';

const Container = styled(containerFromAccount)`
  margin-top: 10px;
`;

type Props = {
  channels: Array<ChannelMdl>,
  icon: any,
  onDisconnect?: () => void,
  subscriptionKey: string,
  extraAllowlistData: { [string]: AllowlistDisplayData },
  onTrial: boolean,
};

type ExtraChannelProps = {
  channel: ChannelMdl,
  icon: any,
  onDisconnect?: (Array<ChannelMdl>, string, string) => void,
  subscriptionKey: string,
  extraChannels: Array<ChannelMdl>,
  extraAllowlistData: AllowlistDisplayData,
  onTrial: boolean,
};

const ExtraYouTubeAccount = ({
  channel,
  icon,
  onDisconnect = () => {},
  subscriptionKey,
  extraChannels,
  extraAllowlistData,
  onTrial,
}: ExtraChannelProps) => {
  const miscCopy = useMiscCopy();
  const [accountHover, setAccountHover] = useState(false);
  const [extraYoutubeBusy, setExtraYouTubeBusy] = useState(false);

  const youtubeChannelId = getChannelYouTubeId(getYouTubeChannelConnection(channel));

  const handleHover = () => {
    setAccountHover(true);
  };

  const handleHoverOut = () => {
    setAccountHover(false);
  };
  const handleDisconnectExtraChannel = async () => {
    setExtraYouTubeBusy(true);
    await onDisconnect(extraChannels, subscriptionKey, youtubeChannelId);
  };

  return (
    <>
      <Container
        className={cx(socialAccountClassName, socialAccountConnectedClassName)}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverOut}
      >
        <ContainerFlex>
          <Info>
            <Icon>
              <img src={icon} alt="account icon" />
            </Icon>
            <Name className={cx({ [socialAccountHoverClassName]: accountHover })}>
              {getChannelYouTubeChannelName(channel)}
              <AllowlistBadge
                allowlistSate={getAllowListCurrentStateForceSuccessfulIfPending(extraAllowlistData)}
                onTrial={onTrial}
              />
            </Name>
          </Info>
          <div>
            {extraYoutubeBusy ? (
              <SpinnerIcon inverse containerClassName={spinnerClass} />
            ) : (
              <Button onClick={handleDisconnectExtraChannel}>
                {getMiscCopyDisconnectButton(miscCopy)}
              </Button>
            )}
          </div>
        </ContainerFlex>
        {getAllowListCurrentSate(extraAllowlistData) === ALLOWLIST_STATUSES.denied && !onTrial && (
          <DisplayError>
            <AllowlistUnsuccessfulMessage youtubeName={getChannelYouTubeChannelName(channel)} />
          </DisplayError>
        )}
      </Container>
    </>
  );
};

const ExtraYouTubeAccounts = ({
  channels,
  icon,
  onDisconnect,
  subscriptionKey,
  extraAllowlistData,
  onTrial,
}: Props) => {
  return (
    <div>
      {channels
        .sort((channelA, channelB) => {
          const dateA = getChannelCreatedAt(channelA)
            ? new Date(getChannelCreatedAt(channelA) * 1000)
            : new Date(channelA.createdAt._seconds * 1000);
          const dateB = getChannelCreatedAt(channelB)
            ? new Date(getChannelCreatedAt(channelB) * 1000)
            : new Date(channelB.createdAt._seconds * 1000);
          return dateA - dateB;
        })

        .map(channel => {
          const youtubeChannelId = getChannelYouTubeId(getYouTubeChannelConnection(channel));
          return (
            <div key={youtubeChannelId}>
              <ExtraYouTubeAccount
                extraChannels={channels}
                channel={channel}
                subscriptionKey={subscriptionKey}
                icon={icon}
                onDisconnect={onDisconnect}
                extraAllowlistData={extraAllowlistData[youtubeChannelId]}
                onTrial={onTrial}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ExtraYouTubeAccounts;
