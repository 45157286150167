// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import { darkColor } from '../../../../../../../styles/config/colors';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { smallMonoCss } from '../../../../../../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';

const parentClass = 'songActionButton';

const Button = styled('button')`
  ${buttonResetCss};
  display: block;
  width: 40px;
  height: 40px;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    z-index: 5;
  }
`;

const IconContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: ${transparentize(0.95, darkColor)};
    opacity: 0;
    transform: scale(0.75);
    transition: all 200ms ease-in;

    .${parentClass}:hover &,
    .${parentClass}:active & {
      opacity: 1;
      transform: scale(1);
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }

    .${parentClass}:focus-visible & {
      opacity: 1;
      transform: scale(1);
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }

    .${parentClass}:active & {
      transform: scale(0.85);
      transition-duration: 200ms;
    }
  }
`;

const Label = styled('div')`
  ${smallMonoCss};
  width: 105px;
  position: absolute;
  top: 100%;
  padding-top: 5px;
  left: -31px;
  right: -31px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  display: none;
  transition: all 200ms ease-in;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: 0;
    margin-top: -2px;
  }

  .${parentClass}:hover &,
  .${parentClass}:active & {
    display: block;
    pointer-events: unset;
    opacity: 1;
  }

  .${parentClass}:focus-visible & {
    pointer-events: unset;
    opacity: 1;
  }
`;

const condensedLabelClass = css`
  padding: 1px 5px 0px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const disabledIconButtonClass = css`
  cursor: default !important;
`;

const disabledIconClass = css`
  opacity: 0.5;
`;

const dimmedSectionCss = css`
  opacity: 0.5;
`;

const topLabelCss = css`
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(255, 255, 255, 0.45);
  top: -50%;
`;

const rightLabelCss = css`
  right: 0px;
  left: unset;
`;

type Props = {
  label: string,
  icon: any,
  condensed?: boolean,
  disabledIcon?: boolean,
  dimmedIcon?: boolean,
  topLabel?: boolean,
  rightLabel?: boolean,
};

const SongActionButton = ({
  label,
  icon,
  condensed = false,
  disabledIcon = false,
  dimmedIcon = false,
  topLabel = false,
  rightLabel = false,
  ...props
}: Props) => (
  <Button className={cx(parentClass, { [disabledIconButtonClass]: disabledIcon })} {...props}>
    <IconContainer
      className={cx({
        [disabledIconClass]: disabledIcon,
        [dimmedSectionCss]: dimmedIcon,
      })}
    >
      {icon}
    </IconContainer>
    <Label
      className={cx({
        [condensedLabelClass]: condensed,
        [topLabelCss]: topLabel,
        [rightLabelCss]: rightLabel,
      })}
    >
      {label}
    </Label>
  </Button>
);

export default SongActionButton;
