// @flow
import React, { useEffect, useState, useContext } from 'react';
import { chunk } from 'lodash';
import {
  useUserBookmarks,
  useUserSavedCollections,
} from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { getTracksFromAlgolia } from '../../../../../../../api/algolia/search';
import { getSongID, type AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import type { UserBookmarkMdl } from '../../../../../../../api/firebase/user/bookmarks';
import { getCuratedCollectionId } from '../../../../../../../cms/curatedCollection';
import { useViewingQuery } from '../../../../../MusicScreen/views/SearchView/components/ResultsManager/ResultsManager';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import { getSortedBookmarks } from '../../filter';
import { useCuratedCollections } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';

export type BookmarkSongWrapper = {
  bookmark: UserBookmarkMdl,
  song: AlgoliaSongMdl,
};

export type NextGroupedSongData = {
  nextGroupedSongId: string | null,
  nextExpandKey: string | null,
};

export type BookmarksManagerContextState = {
  loading: boolean,
  loaded: boolean,
  bookmarks: Array<BookmarkSongWrapper>,
  viewingBookmarks: string,
  loadingFilterResults: Boolean,
  noBookmarks: boolean,
  noVisibleResults: boolean,
  sortingMethod: string,
  handleSort: (event: any) => void,
  noBookmarkedCollections: number,
};

export const BookmarksManagerContext = React.createContext<any>();

export const BOOKMARKS_ORDER_VALUES = {
  recent: 'recent',
  oldest: 'oldest',
  longest: 'longest',
  shortest: 'shortest',
};

export const useBookmarksManagerContext = (): BookmarksManagerContextState => {
  return useContext(BookmarksManagerContext);
};

const BookmarksManager = ({ children }: Props) => {
  const locale = useLocale();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [songs, setSongs] = useState({});
  const [viewingParam] = useViewingQuery();
  const [viewingBookmarks, setViewingBookmarks] = useState('all');
  const userBookmarks = useUserBookmarks();
  const userBookmarksKeys = Object.keys(userBookmarks);
  const navigate = useNavigate();

  const allCollections = useCuratedCollections();
  const userSavedCollections = useUserSavedCollections();
  const savedCollectionIds = Object.keys(userSavedCollections);
  const noBookmarkedCollections =
    allCollections.filter(collection => {
      return savedCollectionIds.includes(getCuratedCollectionId(collection));
    }).length === 0;

  const handleBookmarksTab = (songType: string) => {
    if (songType === 'collections') {
      navigate(ROUTES.bookmarks.navigatePath({ section: songType }));
    } else {
      setViewingBookmarks('all');
    }
  };

  useEffect(() => {
    if (userBookmarksKeys.length === 0) return;
    setLoading(true);
    const songsToFetch = [];
    userBookmarksKeys.forEach(songId => {
      if (!songs[songId]) {
        songsToFetch.push(songId);
      }
    });
    const chunkedSongs = chunk(songsToFetch, 1000);

    const promises = chunkedSongs.map(songsChunk => {
      return getTracksFromAlgolia(songsChunk, locale);
    });

    Promise.all(promises)
      .then(response => {
        let mergedSongs = [];
        response.forEach(chunk => {
          mergedSongs = mergedSongs.concat(chunk.results);
        });

        const fetchedSongs = {};
        mergedSongs.forEach((song: AlgoliaSongMdl) => {
          fetchedSongs[getSongID(song)] = song;
        });
        setSongs({
          ...songs,
          ...fetchedSongs,
        });
        setLoaded(true);
      })
      .finally(() => {
        setLoading(true);
      });
  }, [userBookmarksKeys.join(',')]);

  const getBookmarks = () => {
    const sortedBookmarks = getSortedBookmarks(
      userBookmarksKeys
        .map((bookmarkKey: string) => {
          const bookmark: UserBookmarkMdl = userBookmarks[bookmarkKey];
          if (!bookmark || !songs[bookmark.songId]) return null;
          return {
            bookmark,
            song: songs[bookmark.songId],
          };
        })
        .filter(bookmark => {
          return !!bookmark;
        }),
      BOOKMARKS_ORDER_VALUES.recent
    );

    return sortedBookmarks;
  };

  const bookmarks = getBookmarks();

  useEffect(() => {
    if (viewingParam === 'collections') {
      setViewingBookmarks(viewingParam);
    } else {
      setViewingBookmarks('fullMixes');
    }
  }, [viewingParam]);

  return (
    <BookmarksManagerContext.Provider
      value={{
        loaded,
        loading,
        bookmarks,
        viewingBookmarks,
        handleBookmarksTab,
        noBookmarkedCollections,
      }}
    >
      {children}
    </BookmarksManagerContext.Provider>
  );
};

export default BookmarksManager;
