// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';
import type { CuratedCollectionRelatedCollectionDocumentGraphMdl } from './curatedCollection';

type TagFilterCategory = {
  filter_category: string,
};

export type CollectionsPageGraphMdl = {
  save_collection_action: {
    text: string,
  },
  remove_saved_collection_action: {
    text: string,
  },
  related_collection_title: {
    text: string,
  },
  pack_collections_title: {
    text: string,
  },
  back_to_browse_button: {
    text: string,
  },
  creator_tag_filter_categories: Array<TagFilterCategory>,
  common_related_collections: Array<CuratedCollectionRelatedCollectionDocumentGraphMdl>,
};

export const getCollectionsPageCommonRelatedCollections = (
  collection: CollectionsPageGraphMdl
): Array<CuratedCollectionRelatedCollectionDocumentGraphMdl> => {
  return get(collection, 'data.common_related_collections', []) || [];
};

export const getCollectionsPageSaveAction = (copy: CollectionsPageGraphMdl): string => {
  return get(copy, 'data.save_collection_action.text', '') || '';
};

export const getCollectionsPageRelatedCollectionTitle = (copy: CollectionsPageGraphMdl): string => {
  return get(copy, 'data.related_collection_title.text', '') || '';
};

export const getPackCollectionsTitle = (copy: CollectionsPageGraphMdl): string => {
  return get(copy, 'data.pack_collections_title.text', '') || '';
};

export const getCollectionsPageBackToBrowseButton = (copy: CollectionsPageGraphMdl): string => {
  return get(copy, 'data.back_to_browse_button.text', '') || '';
};

export const getCollectionsPageRemoveSavedCollectionAction = (
  copy: CollectionsPageGraphMdl
): string => {
  return get(copy, 'data.remove_saved_collection_action.text', '') || '';
};

export const getCollectionsPageTagFilterCategories = (
  copy: CollectionsPageGraphMdl
): Array<string> => {
  const categories = get(copy, 'data.creator_tag_filter_categories', '') || '';
  return categories.map(cat => get(cat, 'filter_category', '') || '');
};

export const query = graphql`
  fragment CollectionsPage on PrismicCollectionsPage {
    data {
      save_collection_action {
        text
      }
      remove_saved_collection_action {
        text
      }
      related_collection_title {
        text
      }
      pack_collections_title {
        text
      }
      back_to_browse_button {
        text
      }
      creator_tag_filter_categories {
        filter_category
      }
      common_related_collections {
        related_collection {
          document {
            uid
            data {
              collection_image {
                url
              }
              collection_title {
                text
              }
              collection_songs {
                text
              }
            }
          }
        }
      }
    }
  }
`;
