// @flow
import React from 'react';
import { css } from 'emotion';
import { ICONS } from '../../../../../../images/paths';
import { useAllowlistStatusesCopy } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { ALLOWLIST_STATUSES } from '../../../../../../api/firebase/user/allowlist';
import {
  LargeDeviceOnly,
  SmallDeviceOnly,
} from '../../../../../../components/responsive/SmallDeviceOnly';
import {
  SMALL_DEVICE_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import {
  getAllowlistingSuccessfulStatusBadge,
  getAllowlistingInProgressStatusBadge,
  getAllowlistingUnsuccessfulStatusBadge,
} from '../../../../../../cms/allowlistingStatuses';

type Props = {
  allowlistSate: string,
  onTrial: boolean,
};

const getAllowlistBadgeIcon = (allowlistState: string, onTrial: boolean) => {
  if (onTrial) {
    return ICONS.allowlistPending;
  }

  if (allowlistState === ALLOWLIST_STATUSES.allowlisted) {
    return ICONS.allowlistApproved;
  }

  if (allowlistState === ALLOWLIST_STATUSES.denied) {
    return ICONS.allowlistDenied;
  }

  return ICONS.allowlistPending;
};

const getAllowlistBadgeText = (copy, allowlistState: string, onTrial: boolean) => {
  if (onTrial) {
    return getAllowlistingInProgressStatusBadge(copy);
  }

  if (allowlistState === ALLOWLIST_STATUSES.allowlisted) {
    return getAllowlistingSuccessfulStatusBadge(copy);
  }

  if (allowlistState === ALLOWLIST_STATUSES.denied) {
    return getAllowlistingUnsuccessfulStatusBadge(copy);
  }

  return getAllowlistingInProgressStatusBadge(copy);
};

export const whitListContainer = css`
  padding-left: 5px;
  bacground: black;
  white-space: nowrap;
  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }
`;
export const tooltip = css`
  cursor: pointer;
  ${LARGE_DEVICE_BREAKPOINT} {
    display: inline;
    white-space: nowrap;
    position: absolute;
    & :hover:after {
      background: #000;
      border-radius: 4px;
      color: #fff;
      content: attr(titleAllowlist);
      margin-left: 10px;
      padding: 8px 10px;
    }
  }
`;

export const smallSizeAllowlistContainer = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: none;
  }
  width: fit-content;
  display: block;
  font-size: 10px;
  margin: 3px 0px 0px 0px;
  padding: 2px 6px;
  background: #000;
  border-radius: 4px;
`;
export const imgAllowlistStyle = css`
  display: inline;
  position: relative;
  top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 2px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-right: 3px;
  }
`;
export const smalSizeAllowlistText = css`
  display: inline;
  white-space: nowrap;
`;

const AllowlistBadge = ({ allowlistSate, onTrial }: Props) => {
  const copy = useAllowlistStatusesCopy();
  const badgeIcon = getAllowlistBadgeIcon(allowlistSate, onTrial);
  const badgeText = getAllowlistBadgeText(copy, allowlistSate, onTrial);

  return (
    <>
      {allowlistSate && (
        <>
          <SmallDeviceOnly>
            <span className={smallSizeAllowlistContainer}>
              <img className={imgAllowlistStyle} src={badgeIcon} alt={badgeText} />
              <div className={smalSizeAllowlistText}>{badgeText}</div>
            </span>
          </SmallDeviceOnly>
          <LargeDeviceOnly>
            <span className={whitListContainer}>
              <div className={tooltip} titleAllowlist={badgeText}>
                <img src={badgeIcon} alt={badgeText} />
              </div>
            </span>
          </LargeDeviceOnly>
        </>
      )}
    </>
  );
};

export default AllowlistBadge;
