// @flow
import auth0 from 'auth0-js';
import ls from 'local-storage';
import { AuthenticationError, ERROR_CODES } from '../../errors/errors';

export const KEY_AUTH_RESULT = 'AUTH_RESULT';
export const KEY_ACCESS_TOKEN_EXPIRES = 'ACCESS_TOKEN_EXPIRES';

export const webAuth = new auth0.WebAuth({
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  redirectUri: `${process.env.GATSBY_AUTH_ROOT_URL}/callback`,
  clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email',
  overrides: {
    __tenant: process.env.GATSBY_AUTH0_TENANT,
    __token_issuer: process.env.GATSBY_AUTH0_AUTHORIZATION_SERVER_ISSUER,
  },
});

export type Auth0AuthResult = {
  accessToken: string,
  expiresIn: number,
  state: string,
};

export const getAuth0AuthResultToken = (authResult: Auth0AuthResult): string => {
  return authResult.accessToken;
};

export class Auth0ApiHandler {
  authResult: Auth0AuthResult;

  expiry: number;

  reset = () => {
    this.authResult = null;
    this.expiry = null;
    ls.remove(KEY_ACCESS_TOKEN_EXPIRES);
    ls.remove(KEY_AUTH_RESULT);
  };

  isAccessTokenValid = (): boolean => {
    const { authResult } = this;
    if (!authResult) return false;
    const token = getAuth0AuthResultToken(authResult);
    if (!token) return false;
    return Date.now() < this.expiry;
  };

  setAuthResult = (authResult: Auth0AuthResult, store: any, updateExpiry: boolean = true) => {
    this.authResult = authResult;
    if (updateExpiry) {
      const expiry = Date.now() + authResult.expiresIn * 1000;
      this.expiry = expiry;
      if (store) {
        ls.set(KEY_ACCESS_TOKEN_EXPIRES, expiry);
      }
    }
    if (store) {
      ls.set(KEY_AUTH_RESULT, authResult);
      ls.set('AUTH_FROM_LOGIN', true);
    }
  };

  getAccessToken = async (): Promise<string> => {
    if (!this.isAccessTokenValid()) {
      try {
        await this.renewToken();
      } catch (error) {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when renewing auth0 tokens');
        Sentry.captureException(error);
        console.error(error);
        throw new AuthenticationError('Failed to renew tokens', ERROR_CODES.renewTokens);
      }
    }
    return getAuth0AuthResultToken(this.authResult);
  };

  renewToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (error, authResult: Auth0AuthResult) => {
        if (error) {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Check session failed.');
          Sentry.captureException(error);
          console.error(error);
          return reject(error);
        }
        this.setAuthResult(authResult, true, true);
        return resolve(getAuth0AuthResultToken(authResult));
      });
    });
  };

  parseHash = (hash: string): Promise<Auth0AuthResult> => {
    return new Promise((resolve, reject) => {
      webAuth.parseHash({ hash }, (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          resolve(authResult);
        }
      });
    });
  };

  initAuth = (): boolean => {
    const storedAuthResult = ls.get(KEY_AUTH_RESULT);
    if (storedAuthResult) {
      this.setAuthResult(storedAuthResult, false, false);
      const storedAccessTokenExpires = ls.get(KEY_ACCESS_TOKEN_EXPIRES);
      if (storedAccessTokenExpires) {
        this.expiry = storedAccessTokenExpires;
      }
      return true;
    }
    return false;
  };

  logout = () => {
    // const localisedRedirect = window.location.href.includes('/ja/') ? '/ja/music' : '/music';
    const localisedRedirect = window.location.href.includes('/ja/')
      ? '/ja/music'
      : window.location.href.includes('/cn/')
      ? '/cn/music'
      : '/music';
    webAuth.logout({
      returnTo: `${window.location.origin}${localisedRedirect}`,
      clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
    });
  };
}

export const auth0ApiHandler = new Auth0ApiHandler();
