// @flow
import React from 'react';
import styled from 'react-emotion';
import { getSongID } from '../../../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import {
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import ExpandableDisplaySongCard from '../../../../../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import { useDownloadsManagerContext } from '../DownloadHistoryManager/DownloadHistoryManager';

const Header = styled('header')`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Mono';
  font-size: 13px;

  & h4:first-of-type {
    align-self: flex-start;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 5px;
  }
`;

const SecondHeading = styled('h4')`
  max-width: 80%;
  text-transform: capitalize;

  ${MOBILE_DEVICE_BREAKPOINT} {
    max-width: 70%;
  }
`;

const SongsList = styled('div')`
  margin-top: 20px;
  margin-bottom: 0px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    padding-left: 10px;
    padding-right: 10px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    overflow-x: hidden;
  }
`;

const ListItem = styled('div')`
  position: relative;
  margin-bottom: 5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }
`;

type Props = {
  groupKey: string,
  firstHeading: string,
  secondHeading: string,
  tracks: Array<AlgoliaSongMdl>,
};

const isGroupEmpty = (tracks: Array<AlgoliaSongMdl>) => {
  let isEmpty = true;
  tracks.forEach(track => {
    if (track !== null) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

const GroupOfTracks = ({ groupKey, firstHeading, secondHeading, tracks }: Props) => {
  const { getGroupedSongIndex, groupedDownloads } = useDownloadsManagerContext();
  const isEmptyGroup = isGroupEmpty(tracks);
  return (
    <section>
      {!isEmptyGroup && (
        <>
          <Header>
            <h4>{firstHeading}</h4>
            <SecondHeading>{secondHeading}</SecondHeading>
          </Header>
          <section>
            <SongsList>
              {tracks.map((song: AlgoliaSongMdl, index) => {
                if (!song) {
                  // todo - handle?
                  return null;
                }
                const expandKey = `${groupKey}::${getSongID(song)}`;
                const playbackIndex = getGroupedSongIndex(
                  getSongID(song),
                  expandKey,
                  groupedDownloads
                );
                return (
                  <ListItem key={getSongID(song)}>
                    <ExpandableDisplaySongCard
                      song={song}
                      songPosition={playbackIndex}
                      checkSongPosition
                    />
                  </ListItem>
                );
              })}
            </SongsList>
          </section>
        </>
      )}
    </section>
  );
};

export default GroupOfTracks;
