// @flow
import React, { useContext } from 'react';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { getSongID, getSongShareUrl } from '../../../api/algolia/song';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useAudioPlaybackContext } from '../AudioPlayback/AudioPlayback';

export type ShareContextState = {
  songID: string,
  shareURL: string,
  song: AlgoliaSongMdl,
  getAudioProgress: () => number,
};

export const ShareContext = React.createContext({
  songID: '',
  shareURL: '',
  song: null,
  getAudioProgress: () => 0,
});

type Props = {
  children: any,
  song: AlgoliaSongMdl,
};

const ShareContextWrapper = ({ children, song }: Props) => {
  const localePath = useLocalePath();
  const { getAudioProgress } = useAudioPlaybackContext();
  return (
    <ShareContext.Provider
      value={{
        song,
        songID: getSongID(song),
        shareURL: getSongShareUrl(song, localePath),
        getAudioProgress,
      }}
    >
      {children}
    </ShareContext.Provider>
  );
};

export default ShareContextWrapper;

export const useShareContext = (): ShareContextState => {
  return useContext(ShareContext);
};

export const useShareSongID = (): string => {
  return useShareContext().songID;
};

export const useShareSongShareURL = (): string => {
  return useShareContext().shareURL;
};
