// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { RESULTS_SORTING_METHODS } from '../routing/screens/ResultsScreen/components/ResultsView/components/data';

export type ResultsPageMdl = {
  data: {
    new_search_button: {
      text: string,
    },
    filters_title: {
      text: string,
    },
    all_filter: {
      text: string,
    },
    full_mixes_filter: {
      text: string,
    },
    parts_filter: {
      text: string,
    },
    stems_filter: {
      text: string,
    },
    loops_filter: {
      text: string,
    },
    one_shot_filter: {
      text: string,
    },
    sfx_filter: {
      text: string,
    },
    view_all_parts_button: {
      text: string,
    },
    load_details_button: {
      text: string,
    },
    hide_details_button: {
      text: string,
    },
    next_track_button_label: {
      text: string,
    },
    previous_track_button_label: {
      text: string,
    },
    generic_error_message: {
      html: string,
    },
    filter_header: {
      text: string,
    },
    top_filter_results_header: {
      text: string,
    },
    current_track_filter_header: {
      text: string,
    },
    search_filter_header: {
      text: string,
    },
    no_filter_search_results: {
      html: string,
    },
    include_filter_label: {
      text: string,
    },
    exclude_filter_label: {
      text: string,
    },
    filter_option_included_toast: {
      text: string,
    },
    filter_option_excluded_toast: {
      text: string,
    },
    filter_range_included_toast: {
      text: string,
    },
    filter_type_included_toast: {
      text: string,
    },
    filter_removed_toast: {
      text: string,
    },
    filters_cleared_toast: {
      text: string,
    },
    sort_newest_label: {
      text: string,
    },
    sort_most_relevant_label: {
      text: string,
    },
    sort_least_relevant_label: {
      text: string,
    },
    sort_oldest_label: {
      text: string,
    },
    sort_alphabetical_label: {
      text: string,
    },
    hidden_filter_label: {
      text: string,
    },
    show_hidden_songs_filter_label: {
      text: string,
    },
    similar_keyword_label: {
      text: string,
    },
    similar_songs_heading: {
      text: string,
    },
    back_to_results_action: {
      text: string,
    },
  },
};

export const getResultsPageGenericErrorMessage = (page: ResultsPageMdl): string => {
  return get(page, 'data.generic_error_message.html', '') || '';
};

export const getResultsPageNextTrackButtonLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.next_track_button_label.text', '') || '';
};

export const getResultsPagePreviousTrackButtonLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.previous_track_button_label.text', '') || '';
};

export const getResultsPageNewSearchButton = (page: ResultsPageMdl): string => {
  return get(page, 'data.new_search_button.text', '') || '';
};

export const getResultsPageFiltersTitle = (page: ResultsPageMdl): string => {
  return get(page, 'data.filters_title.text', '') || '';
};

export const getResultsPageAllFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.all_filter.text', '') || '';
};

export const getResultsPageFullMixesFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.full_mixes_filter.text', '') || '';
};

export const getResultsPagePartsFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.parts_filter.text', '') || '';
};

export const getResultsPageStemsFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.stems_filter.text', '') || '';
};

export const getResultsPageLoopsFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.loops_filter.text', '') || '';
};

export const getResultsPageOneShotsFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.one_shot_filter.text', '') || '';
};

export const getResultsPageSfxFilter = (page: ResultsPageMdl): string => {
  return get(page, 'data.sfx_filter.text', '') || '';
};

export const getResultsPageViewAllPartsButton = (page: ResultsPageMdl): string => {
  return get(page, 'data.view_all_parts_button.text', '') || '';
};

export const getResultsPageLoadDetailsButton = (page: ResultsPageMdl): string => {
  return get(page, 'data.load_details_button.text', '') || '';
};

export const getResultsPageHideDetailsButton = (page: ResultsPageMdl): string => {
  return get(page, 'data.hide_details_button.text', '') || '';
};

export const getResultsPageFilterHeader = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_header.text', '') || '';
};

export const getResultsPageTopFilterResultsHeader = (page: ResultsPageMdl): string => {
  return get(page, 'data.top_filter_results_header.text', '') || '';
};

export const getResultsPageCurrentTrackFilterHeader = (page: ResultsPageMdl): string => {
  return get(page, 'data.current_track_filter_header.text', '') || '';
};

export const getResultsPageSearchFilterHeader = (page: ResultsPageMdl): string => {
  return get(page, 'data.search_filter_header.text', '') || '';
};

export const getResultsPageNoFilterSearchResults = (page: ResultsPageMdl): string => {
  return get(page, 'data.no_filter_search_results.html', '') || '';
};

export const getResultsPageIncludeFilterLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.include_filter_label.text', '') || '';
};

export const getResultsPageExcludeFilterLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.exclude_filter_label.text', '') || '';
};

export const getResultsPageFilterToastOptionIncluded = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_option_included_toast.text', '') || '';
};

export const getResultsPageFilterToastOptionExcluded = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_option_excluded_toast.text', '') || '';
};

export const getResultsPageFilterToastRangeIncluded = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_range_included_toast.text', '') || '';
};

export const getResultsPageFilterToastTypeIncluded = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_type_included_toast.text', '') || '';
};

export const getResultsPageFilterToastFilterRemoved = (page: ResultsPageMdl): string => {
  return get(page, 'data.filter_removed_toast.text', '') || '';
};

export const getResultsPageFilterToastFilterCleared = (page: ResultsPageMdl): string => {
  return get(page, 'data.filters_cleared_toast.text', '') || '';
};

export const getResultsSortNewestLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.sort_newest_label.text', '') || '';
};

export const getResultsSortMostRelevantLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.sort_most_relevant_label.text', '') || '';
};

export const getResultsSortLeastRelevantLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.sort_least_relevant_label.text', '') || '';
};

export const getResultsSortOldestLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.sort_oldest_label.text', '') || '';
};

export const getResultsSortAlphabeticalLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.sort_alphabetical_label.text', '') || '';
};

export const getSortLabel = (selectedSortMethod: string, page: ResultsPageMdl): string => {
  if (selectedSortMethod === RESULTS_SORTING_METHODS.newest) {
    return getResultsSortNewestLabel(page);
  }
  if (selectedSortMethod === RESULTS_SORTING_METHODS.popularity) {
    return getResultsSortMostRelevantLabel(page);
  }
  if (selectedSortMethod === RESULTS_SORTING_METHODS.oldest) {
    return getResultsSortOldestLabel(page);
  }
  if (selectedSortMethod === RESULTS_SORTING_METHODS.popularity_ascending) {
    return getResultsSortLeastRelevantLabel(page);
  }
  return '';
};

export const getResultsHiddenFilterLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.hidden_filter_label.text', '') || '';
};

export const getInternalFilterLabel = (filterKey: string, page: ResultsPageMdl) => {
  if (filterKey === 'hidden') {
    return getResultsHiddenFilterLabel(page);
  }

  return '';
};

export const getResultsPageShowHiddenTracksFilterLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.show_hidden_songs_filter_label.text', '') || '';
};

export const getResultsPageSimilarKeywordLabel = (page: ResultsPageMdl): string => {
  return get(page, 'data.similar_keyword_label.text', '') || '';
};

export const getResultsPageSimilarSongsHeading = (page: ResultsPageMdl): string => {
  return get(page, 'data.similar_songs_heading.text', '') || '';
};

export const getResultsPageBackToResultsAction = (page: ResultsPageMdl): string => {
  return get(page, 'data.back_to_results_action.text', '') || '';
};

export const query = graphql`
  fragment ResultsPage on PrismicResultsPage {
    data {
      new_search_button {
        text
      }
      filters_title {
        text
      }
      all_filter {
        text
      }
      full_mixes_filter {
        text
      }
      parts_filter {
        text
      }
      stems_filter {
        text
      }
      loops_filter {
        text
      }
      one_shot_filter {
        text
      }
      sfx_filter {
        text
      }
      view_all_parts_button {
        text
      }
      load_details_button {
        text
      }
      hide_details_button {
        text
      }
      next_track_button_label {
        text
      }
      previous_track_button_label {
        text
      }
      generic_error_message {
        html
      }
      filter_header {
        text
      }
      top_filter_results_header {
        text
      }
      current_track_filter_header {
        text
      }
      search_filter_header {
        text
      }
      no_filter_search_results {
        html
      }
      include_filter_label {
        text
      }
      exclude_filter_label {
        text
      }
      filter_option_included_toast {
        text
      }
      filter_option_excluded_toast {
        text
      }
      filter_range_included_toast {
        text
      }
      filter_type_included_toast {
        text
      }
      filter_removed_toast {
        text
      }
      filters_cleared_toast {
        text
      }
      sort_newest_label {
        text
      }
      sort_most_relevant_label {
        text
      }
      sort_least_relevant_label {
        text
      }
      sort_oldest_label {
        text
      }
      sort_alphabetical_label {
        text
      }
      hidden_filter_label {
        text
      }
      show_hidden_songs_filter_label {
        text
      }
      similar_keyword_label {
        text
      }
      similar_songs_heading {
        text
      }
      back_to_results_action {
        text
      }
    }
  }
`;
