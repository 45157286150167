// @flow
import React from 'react';
import { useAnalyticsMixpanelContext } from '../../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewMicroparts,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../../analytics/mixpanel';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import MicropartsIcon from '../../../../../../../../assets/inline-assets/microparts-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { getTrackViewLoopsAction } from '../../../../../../../../cms/track';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useSongContext } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';

type Props = {
  hideTooltip: () => void,
};

const ViewLoopsInlineButton = ({ hideTooltip }: Props) => {
  const trackCopy = useTrackCopy();
  const { song } = useSongContext();
  const { showLoopsModal } = useModalsContext();
  const { pauseGlobalPlayback } = useGlobalPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  const handleOpenLoopsModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const songFamily = getSongShareSlug(song);
    if (hideTooltip) {
      hideTooltip();
    }
    showLoopsModal(songFamily, downloadContext, () => pauseGlobalPlayback());
    analyticsMixpanelViewMicroparts(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  };
  return (
    <InlineButton
      label={getTrackViewLoopsAction(trackCopy)}
      icon={<MicropartsIcon />}
      action={handleOpenLoopsModal}
    />
  );
};

export default ViewLoopsInlineButton;
