// @flow
import React from 'react';
import FeaturedGenres from '../BrowseView/components/FeaturedGenres';
import TagList from './components/TagList/TagList';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';

type Props = {
  path: string,
};

const GenresView = ({ path }: Props) => {
  return (
    <BrowseLayout path={path}>
      <FeaturedGenres viewAll oversizedTile page="genresPage" rows={2} />
      <TagList tagCategory="genre" />
    </BrowseLayout>
  );
};

export default GenresView;
