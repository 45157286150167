/* eslint-disable no-underscore-dangle */
// @flow
import React from 'react';
import {
  getMiscCopyCollections,
  getMiscCopyGenre,
  getMiscCopyInstruments,
  getMiscCopyMood,
  getMiscCopySoundEffects,
} from '../../../cms/miscCopy';
import { useMiscCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { ROUTES } from '../../routes';
import { useLocalePath } from '../LocaleWrapper/LocaleWrapper';
import { MusicNavLink } from '../SiteNavLinks/SiteNavLinks';

type Props = {
  children: any,
};

const MusicNavLinks = ({ children }: Props) => {
  const localePath = useLocalePath();
  const copy = useMiscCopy();

  const links = [
    props => (
      <MusicNavLink to={ROUTES.genres.navigatePath({ localePath })} {...props}>
        {getMiscCopyGenre(copy)}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.moods.navigatePath({ localePath })} {...props}>
        {getMiscCopyMood(copy)}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.instruments.navigatePath({ localePath })} {...props}>
        {getMiscCopyInstruments(copy)}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.collections.navigatePath({ localePath })} {...props}>
        {getMiscCopyCollections(copy)}
      </MusicNavLink>
    ),
    props => (
      <MusicNavLink to={ROUTES.sfx.navigatePath({ localePath })} {...props}>
        {getMiscCopySoundEffects(copy)}
      </MusicNavLink>
    ),
  ];
  return children(links);
};

export default MusicNavLinks;
