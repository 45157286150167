// @flow
import React, { useContext, useState } from 'react';

export type AudioPlayerContextState = {
  playingSongID: string,
  setPlayingSongID: string => void,
  previousPlayingState: boolean,
  setPreviousPlayingState: boolean => void,
  playingSongExpandKey: string,
  setPlayingSongExpandKey: (string | null) => void,
  analyticsSentSongId: string,
  setAnalyticsSentSongId: string => void,
  analyticsSentMicropartSongId: string,
  setAnalyticsSentMicropartSongId: string => void,
  analyticsSentMicropartPosition: number | null,
  setAnalyticsSentMicropartPosition: (number | null) => void,
  loopsPlaying: boolean,
  setLoopsPlaying: (state: boolean) => void,
};

export const AudioPlayerContext = React.createContext<any>();

export const useAudioPlayerContext = (): AudioPlayerContextState => {
  return useContext(AudioPlayerContext);
};

type Props = {
  children: any,
};

const AudioPlayerWrapper = ({ children }: Props) => {
  const [playingSongID, setPlayingSongID] = useState('');
  const [previousPlayingState, setPreviousPlayingState] = useState(true);
  const [playingSongExpandKey, setPlayingSongExpandKey] = useState('');
  const [analyticsSentSongId, setAnalyticsSentSongId] = useState('');
  const [analyticsSentMicropartSongId, setAnalyticsSentMicropartSongId] = useState('');
  const [analyticsSentMicropartPosition, setAnalyticsSentMicropartPosition] = useState(null);
  const [loopsPlaying, setLoopsPlaying] = useState(false);
  return (
    <AudioPlayerContext.Provider
      value={{
        playingSongID,
        setPlayingSongID,
        previousPlayingState,
        setPreviousPlayingState,
        playingSongExpandKey,
        setPlayingSongExpandKey,
        analyticsSentSongId,
        setAnalyticsSentSongId,
        analyticsSentMicropartSongId,
        setAnalyticsSentMicropartSongId,
        analyticsSentMicropartPosition,
        setAnalyticsSentMicropartPosition,
        loopsPlaying,
        setLoopsPlaying,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerWrapper;
