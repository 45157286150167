// @flow
import React from 'react';
import { get } from 'lodash';
import { useSetRecoilState } from 'recoil';
import {
  getTrackDownloadAction,
  getTrackDownloadMidiAction,
  getTrackReDownloadAction,
} from '../../../../../../../../cms/track';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useDownloadedSongsContext } from '../../../../../../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import {
  useMiscCopy,
  useTrackCopy,
} from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useShowSignUp } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useFreeTierRestrictionToast } from '../../../../../../../../toasts/hooks';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useDownloadSong } from '../../../../../../../hooks';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { getSongID } from '../../../../../../../../api/algolia/song';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import DownloadedMIDIIcon from '../../../../../../../../assets/inline-assets/downloaded-midi-icon.svg';
import DownloadMIDIIcon from '../../../../../../../../assets/inline-assets/download-midi-icon.svg';
import DownloadingIcon from '../../../../../../../../components/DownloadingIcon/DownloadingIcon';
import {
  getMiscCopyDownloadableMicropartsTooltip,
  getMiscCopyDownloadablePartsTooltip,
} from '../../../../../../../../cms/miscCopy';
import { conversionSourceAtom } from '../../../../../../../../store/mixpanelAnalytics';

const MIDIDownloadInlineButton = () => {
  const trackCopy = useTrackCopy();
  const miscCopy = useMiscCopy();
  const song = useSong();
  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(song);
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const downloadedContext = useDownloadedSongsContext() ? useDownloadedSongsContext() : {};
  const { location = '' } = useDownloadContext();
  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const showSignUp = useShowSignUp();
  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };

  const freeTierRestrictionToast = useFreeTierRestrictionToast('parts', toastUtilities);

  const previousDownloadMidis = get(downloadedContext, 'downloadedMidis', []);
  const isDownloadHistoryView = location === 'downloads';
  const previouslyDownloaded =
    previousDownloadMidis.includes(getSongID(song)) || isDownloadHistoryView;

  const handleDisabledClick = () => {
    freeTierRestrictionToast();
  };

  const icon = (() => {
    if (downloadMidiBusy) {
      return <DownloadingIcon />;
    }

    if (previouslyDownloaded) {
      return <DownloadedMIDIIcon />;
    }

    return <DownloadMIDIIcon />;
  })();

  const label = (() => {
    if (!USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return song.isPart
        ? getMiscCopyDownloadablePartsTooltip(miscCopy)
        : getMiscCopyDownloadableMicropartsTooltip(miscCopy);
    }

    if (previouslyDownloaded) {
      return getTrackReDownloadAction(trackCopy);
    }

    return getTrackDownloadMidiAction(trackCopy);
  })();

  const action = event => {
    if (!USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      handleDisabledClick();
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (downloadMidiBusy) {
      return;
    }

    downloadSong('MIDI');
  };

  return <InlineButton label={label} icon={icon} action={action} />;
};

export default MIDIDownloadInlineButton;
