// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx } from 'emotion';
import { transparentize } from 'polished';
import { useRecoilValue } from 'recoil';
import PreviewPlayPauseButton from '../../../../../../song/components/FullDisplaySongCard/components/PreviewPlayPauseButton/PreviewPlayPauseButton';
import { useGlobalPlayerContext } from '../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { lightColor } from '../../../../../../styles/config/colors';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import { spacing } from '../../../../../../styles/config/spacing';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
} from '../../../../../../store/globalPlayer';
import { useAuthContext } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useShowSignUp } from '../../../../../../modals/components/ModalsWrapper/ModalsWrapper';

type Props = {
  title: string,
  slug: string,
  bgImage: string,
  listType: string,
  previewTile: any,
  navigateTile: any,
  oversizedTile?: boolean,
};

const TileContainer = styled('div')`
  margin: 16px 5px;

  &:hover {
    text-decoration: underline;
  }

  &:first-of-type {
    margin-left: 0px;
    ${MOBILE_DEVICE_BREAKPOINT} {
      margin-left: ${spacing.small}px;
    }
  }

  &:last-of-type {
    margin-left: 0px;
    ${MOBILE_DEVICE_BREAKPOINT} {
      box-sizing: content-box;
      border-right: ${spacing.small}px solid transparent;
    }
  }

  ${MEDIA_BREAKPOINTS.min1720} {
    width: 220px;
  }

  ${MEDIA_BREAKPOINTS.max1720} {
    width: 185px;
  }

  ${MEDIA_BREAKPOINTS.max1440} {
    width: 155px;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    width: 145px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 150px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin: 5px 7px;
  }
`;

const TileLink = styled('h3')`
  color: #0f0f14;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.19px;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TileImageWrapper = styled('div')`
  height: 130px;
  width: 130px;
  border-radius: 5px;
  background-color: #848484;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  bakcground-position: center;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  ${MEDIA_BREAKPOINTS.max1720} {
    height: 120px;
    width: 120px;
  }

  ${MEDIA_BREAKPOINTS.max1440} {
    height: 100px;
    width: 100px;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    height: 90px;
    width: 90px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    height: 120px;
    width: 120px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    height: 110px;
    width: 110px;
  }

  ${MEDIA_BREAKPOINTS.max600} {
    height: 160px;
    width: 160px;
  }
`;

const OverlayButtonContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    display: none;
  }

  &:hover,
  &.playingFeatured {
    background-color: ${transparentize(0.4, lightColor)};

    & button {
      display: flex;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-color: ${transparentize(1, lightColor)};

    & button {
      display: none;
    }

    &:hover {
      background-color: ${transparentize(1, lightColor)};

      & button {
        display: none;
      }
    }

    &.playingFeatured {
      background-color: ${transparentize(0.4, lightColor)};

      & button {
        display: flex;
      }
    }
  }
`;

const PreviewGridTile = ({ title, slug, bgImage, listType, previewTile, navigateTile }: Props) => {
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();

  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const globalTryingToPlay = useRecoilValue(globalTryingToPlayAtom);
  const globalLoadError = useRecoilValue(globalLoadErrorAtom);
  const { handleToggleGlobalPlayback } = useGlobalPlayerContext();
  const tilePlaying = playbackListType === listType && playbackListSlug === slug.toLowerCase();
  const isPlaying = tilePlaying && globalPlaying;
  const tryingToPlay = tilePlaying && globalTryingToPlay;
  const loadError = tilePlaying && globalLoadError;

  const handlePlayPauseToggle = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (tilePlaying) {
      handleToggleGlobalPlayback();
      return;
    }
    previewTile();
  };

  return (
    <TileContainer>
      <TileImageWrapper
        bgImage={bgImage}
        onClick={() => {
          navigateTile();
        }}
      >
        <OverlayButtonContainer className={cx({ playingFeatured: tilePlaying })}>
          <PreviewPlayPauseButton
            playing={isPlaying}
            tryingToPlay={tryingToPlay}
            loadError={loadError}
            handleOnPlayToggle={handlePlayPauseToggle}
          />
        </OverlayButtonContainer>
      </TileImageWrapper>
      <TileLink
        onClick={() => {
          navigateTile();
        }}
      >
        {title}
      </TileLink>
    </TileContainer>
  );
};

export default PreviewGridTile;
