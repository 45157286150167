// @flow
import React from 'react';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import { useBusinessLicensePageCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import PageComponent from '../../components/PageComponent/PageComponent';
import LicenseScreen from '../LicenseScreen/LicenseScreen';

const Content = () => {
  const copy = useBusinessLicensePageCopy();
  return (
    <LayoutWrapper bodyPadding>
      <LicenseScreen planName="loyalMemberPlus" />
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

const BusinessLicenseScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default BusinessLicenseScreen;
