// @flow
import React from 'react';
import { Link } from '@reach/router';
import { useSiteHeader } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useLocalePath } from '../LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../routes';
import { getHelpNavUrl } from '../../../cms/header';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useTranslation } from 'react-i18next';

export const activeLinkClass = 'active';
export const activeLinkBoldClass = 'activeBold';

type Props = {
  children: any,
};

const musicNavLinkCss = {
  padding: '15px 15px 20px 15px',
};

export const SiteNavLink = ({ matchPartial = false, activeDisabled = false, ...props }: any) => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className:
          (isCurrent && !activeDisabled) || (matchPartial && isPartiallyCurrent && !activeDisabled)
            ? activeLinkClass
            : '',
      };
    }}
  />
);

export const MusicNavLink = ({ matchPartial = false, ...props }: any) => (
  <Link
    {...props}
    style={musicNavLinkCss}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent || (matchPartial && isPartiallyCurrent) ? activeLinkBoldClass : '',
      };
    }}
  />
);

const SiteNavLinks = ({ children }: Props) => {
  const siteHeader = useSiteHeader();
  const localePath = useLocalePath();
  const { isPaidCustomer } = useSubscriptionsContext();
  const { t } = useTranslation();

  const { pathname } = window.location;
  const browse = props => (
    <SiteNavLink
      to={ROUTES.music.navigatePath({ localePath })}
      {...props}
      matchPartial={
        pathname.includes('/music') &&
        !pathname.includes('/pack') &&
        !pathname.includes('/similar') &&
        !pathname.includes('search') &&
        !pathname.includes('/sfx')
      }
    >
      {t('general.siteHeader.browse', '')}
    </SiteNavLink>
  );

  const help = props => (
    <a href={getHelpNavUrl(siteHeader)} target="_blank" rel="noopener noreferrer" {...props}>
      {t('general.siteHeader.help', '')}
    </a>
  );

  const pricing = props => (
    <SiteNavLink to={ROUTES.pricing.navigatePath({ localePath })} matchPartial {...props}>
      {t('general.siteHeader.pricing', '')}
    </SiteNavLink>
  );

  const freeUserLinks = !isPaidCustomer ? [pricing] : [];

  // const links = [browse, sfx, search].concat([...freeUserLinks]).concat([customOrder, help]);
  const links = [browse].concat([...freeUserLinks]).concat([help]);

  return children(links);
};

export default SiteNavLinks;
