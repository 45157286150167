// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import Autosuggest from 'react-autosuggest';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { transparentize } from 'polished';
import { bodyTextCss } from '../../../../../../../../../styles/typography/typography';
import { darkColor } from '../../../../../../../../../styles/config/colors';
import FilterMenuOption from '../FilterMenuOption/FilterMenuOption';
import SearchIcon from '../../../../../../../../../assets/inline-assets/search-icon.svg';
import CloseIcon from '../../../../../../../../../assets/inline-assets/close.svg';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { isFilterExcluded, isFilterIncluded } from '../../../../../../../../../api/algolia/data';
import { useResultsPage } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getResultsPageNoFilterSearchResults } from '../../../../../../../../../cms/resultsPage';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import { useAnalyticsMixpanelContext } from '../../../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelNoFilterResults } from '../../../../../../../../../analytics/mixpanel';
import type { AlgoliaKeyword } from '../../../../../../../../../api/algolia/magicKeywords';
import { isReplacableKeyword } from '../../../../../../../../../api/algolia/magicKeywords';
import { getCountAfterHiddenSongs } from '../../../../../../../../../utils/hiddenSongs';

type Props = {
  placeholder: string,
  hits: Array<AlgoliaKeyword>,
  currentRefinement: string,
  refine: string => void,
  handleSearch: string => void,
  selectedFilter: string,
};

const Form = styled('form')`
  width: 100%;
  height: 40px;
  margin-bottom: 18px;
  background-color: transparent;
  border: 0.5px solid ${transparentize(0.9, darkColor)};
  border-radius: 3px;
  display: flex;
`;

const SearchIconContainer = styled('div')`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 20px;
  }

  svg {
    path,
    circle {
      stroke: ${transparentize(0.6, darkColor)};
    }

    &.activeSearch {
      path,
      circle {
        stroke: ${darkColor};
      }
    }
  }
`;

const CloseIconContainer = styled('div')`
  width: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  visibility: hidden;

  svg {
    transform: scale(0.5);
  }
`;

const NoResultsContainer = styled('div')`
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 23px;
  text-align: center;
  color: ${transparentize(0.6, darkColor)};
  margin-top: 20px;
  padding: 0 2px;

  & p {
    margin-bottom: 15px;
  }
`;

const SuggestionsContainer = styled('ul')``;

const activeCloseIcon = css`
  visibility: visible;
`;

const activeSearchIcon = css`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    path,
    circle {
      stroke: ${darkColor};
    }
  }
`;

const inputClass = css`
  width: 80%;
  height: 100%;
  background-color: transparent;
  border: none;
  ${bodyTextCss}
  color: ${darkColor};
  padding-left: 10px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    padding-left: 8px;
  }
  
  &::placeholder {
    color: ${transparentize(0.6, darkColor)};
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
  return <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>;
};

const getSuggestionValue = suggestion => suggestion;

const FilterAutocomplete = ({
  placeholder,
  hits,
  currentRefinement,
  refine,
  handleSearch,
  selectedFilter,
}: Props) => {
  const resultsPage = useResultsPage();
  const { resultFacets, appliedFilters, hiddenTracksMap } = useResultsManagerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [sentNoResultsTerm, setSentNoResultsTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = () => {};
  const onSuggestionsClearRequested = () => {};
  const onSuggestionHighlighted = () => {};
  const onSuggestionSelected = () => {};

  const clearSuggestions = () => {
    refine('');
    handleSearch('');
    setSuggestions([]);
  };

  const inputProps = {
    placeholder,
    value: currentRefinement,
    onChange: event => {
      refine(event.currentTarget.value);
      handleSearch(event.currentTarget.value);
    },
    onSuggestionsClearRequested,
  };

  const renderSuggestion = (suggestion, renderItemData) => {
    const selectedFacet = get(resultFacets, selectedFilter, {});
    const facetCount = get(selectedFacet, suggestion, 0);
    const finalCount = getCountAfterHiddenSongs(
      selectedFilter,
      suggestion,
      hiddenTracksMap,
      facetCount
    );
    return (
      <FilterMenuOption
        key={suggestion}
        filterKey={selectedFilter}
        filterLabel={suggestion}
        filterCount={finalCount}
        isIncluded={isFilterIncluded(appliedFilters, selectedFilter, suggestion)}
        isExcluded={isFilterExcluded(appliedFilters, selectedFilter, suggestion)}
        isSuggestion
        searchTerm={renderItemData.query}
      >
        {suggestion}
      </FilterMenuOption>
    );
  };

  const renderInputComponent = ({
    className,
    value,
    onSuggestionsClearRequested,
    ...inputProps
  }) => {
    return (
      <>
        <Form>
          <SearchIconContainer className={cx({ [activeSearchIcon]: value })}>
            <SearchIcon />
          </SearchIconContainer>
          <input className={cx(className, inputClass)} value={value} {...inputProps} />
          <CloseIconContainer className={cx({ [activeCloseIcon]: value })}>
            <CloseIcon
              onClick={() => {
                clearSuggestions();
              }}
            />
          </CloseIconContainer>{' '}
        </Form>
        {value && hits.length === 0 ? (
          <NoResultsContainer>
            {parse(getResultsPageNoFilterSearchResults(resultsPage))}
          </NoResultsContainer>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (currentRefinement && hits.length === 0 && currentRefinement !== sentNoResultsTerm) {
      setSentNoResultsTerm(currentRefinement);
      analyticsMixpanelNoFilterResults(mixpanel, moengage, selectedFilter, currentRefinement);
    }
    setSuggestions(
      currentRefinement
        ? hits
            .filter(suggestedFilter => {
              return !isReplacableKeyword(suggestedFilter);
            })
            .map(({ keyword }) => keyword)
        : []
    );
  }, [hits]);

  return (
    <Autosuggest
      suggestions={suggestions}
      inputProps={inputProps}
      alwaysRenderSuggestions
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionHighlighted={onSuggestionHighlighted}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default connectAutoComplete(FilterAutocomplete);
