// @flow
import React from 'react';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { Location } from '@reach/router';
import {
  // getFooterAppleSocialLink,
  getFooterContactUs,
  getFooterFacebookLink,
  getFooterInstagramLink,
  // getFooterMediumLink,
  getFooterPress,
  getFooterPrivacyPolicy,
  getFooterTermsOfService,
  getFooterTikTokLink,
  getFooterTwitterLink,
  getFooterYouTubeLink,
} from '../../cms/footer';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { LOCALES } from '../../locales';
import { getLocalisedPath } from '../../routing/routes';
import {
  // useChromeExtensionCopy,
  useSiteFooter,
} from '../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { interactiveLinkCss, smallMonoCss } from '../../styles/typography/typography';
import { ICONS } from '../../images/paths';
// import {
//   getExtensionDownloadUrl,
//   getSafariExtensionDownloadUrl,
//   getExtensionFooterDownloadText,
//   getExtensionChromeLabel,
//   getExtensionSafariLabel,
// } from '../../cms/chromeExtension';
// import { isDesktopSafariBrowser } from '../../utils/browser';
import KeyboardShortcutsFooter from './components/KeyboardShortcutsFooter/KeyboardShortcutsFooter';
import { evokeBorderColor } from '../../styles/config/colors';

const Footer = styled('footer')`
  display: flex;
  padding: 0 30px;
  background-color: #fff;
  position: relative;
  z-index: 50;
  border-top: solid ${evokeBorderColor};

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
`;

const RightSection = styled('section')`
  display: flex;
  ${LARGE_DEVICE_BREAKPOINT} {
    align-items: center;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 17px;
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLinks = styled('div')`
  ${smallMonoCss};
  display: flex;
  align-items: center;

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-right: 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 25px;
    flex-wrap: wrap;
    justify-content: center;
  }

  a {
    ${interactiveLinkCss};
    text-decoration: none;
  }
`;

const LinkWrapper = styled('div')`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const Locales = styled('div')`
  display: flex;
  align-items: center;
`;

const Locale = styled('div')`
  &:not(:first-of-type) {
    margin-left: 15px;
  }

  img {
    display: block;
  }
`;

const SocialIcons = styled('div')`
  display: flex;
  align-items: center;
`;

const SocialLink = styled('a')`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-left: 10px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-left: 11px;
    }
  }

  img {
    display: block;
  }
`;

export const getLocalisedCurrentPath = (currentPath: string, localePath: string) => {
  const slash = '/';
  const localePathIncludingSlash = localePath + slash;
  const chinesePathIncludingSlash = LOCALES.chinese.path + slash;
  const japanesePathIncludingSlash = LOCALES.japanese.path + slash;
  const englishPathIncludingSlash = LOCALES.english.path + slash;

  if (currentPath.includes(chinesePathIncludingSlash)) {
    const newPath = currentPath.replace(chinesePathIncludingSlash, localePathIncludingSlash);
    return newPath || slash;
  }

  if (currentPath.includes(japanesePathIncludingSlash)) {
    const newPath = currentPath.replace(japanesePathIncludingSlash, localePathIncludingSlash);
    return newPath || slash;
  }

  if (currentPath.includes(englishPathIncludingSlash)) {
    if (localePath === LOCALES.japanese.path) {
      return currentPath.replace(englishPathIncludingSlash, localePathIncludingSlash);
    }

    return currentPath;
  }

  const newPath = getLocalisedPath(localePath, currentPath);
  return newPath;
};

const SiteFooter = () => {
  const siteFooter = useSiteFooter();

  // Chrome Extensionsに問い合わせのためコメントアウト
  // const chromeExtension = useChromeExtensionCopy();

  // const browser = isDesktopSafariBrowser()
  //   ? getExtensionSafariLabel(chromeExtension)
  //   : getExtensionChromeLabel(chromeExtension);
  // const downloadURL = isDesktopSafariBrowser()
  //   ? getSafariExtensionDownloadUrl(chromeExtension)
  //   : getExtensionDownloadUrl(chromeExtension);

  return (
    <React.Fragment>
      <Footer>
        <SocialIcons>
          <SocialLink
            href={getFooterFacebookLink(siteFooter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ICONS.facebook} alt="Facebook" />
          </SocialLink>
          <SocialLink
            href={getFooterTwitterLink(siteFooter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ICONS.x} alt="X" />
          </SocialLink>
          <SocialLink
            href={getFooterYouTubeLink(siteFooter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ICONS.youtube} alt="Youtube" />
          </SocialLink>
          <SocialLink
            href={getFooterInstagramLink(siteFooter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ICONS.instagram} alt="Instagram" />
          </SocialLink>
          <SocialLink
            href={getFooterTikTokLink(siteFooter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img width="20px" height="20px" src={ICONS.tiktok} alt="TikTok" />
          </SocialLink>
        </SocialIcons>
        <RightSection>
          <FooterLinks>
            {/* Chrome Extensionsに問い合わせのためコメントアウト */}
            {/* <LinkWrapper>
              <a href={downloadURL} target="_blank" rel="noopener noreferrer">
                {getExtensionFooterDownloadText(chromeExtension).replace('$VERSION$', browser)}
              </a>
            </LinkWrapper> */}
            <LinkWrapper>{parse(getFooterPress(siteFooter))}</LinkWrapper>
            <LinkWrapper>{parse(getFooterContactUs(siteFooter))}</LinkWrapper>
            <LinkWrapper>{parse(getFooterTermsOfService(siteFooter))}</LinkWrapper>
            <LinkWrapper>{parse(getFooterPrivacyPolicy(siteFooter))}</LinkWrapper>
          </FooterLinks>
          <Location>
            {({ location }) => {
              const { pathname = '' } = location;
              return (
                <Locales>
                  <KeyboardShortcutsFooter />
                  <Locale>
                    <a href={getLocalisedCurrentPath(pathname, '')}>
                      <img src={ICONS.regionEnglish} alt="English" width={24} height={24} />
                    </a>
                  </Locale>
                  <Locale>
                    <a href={getLocalisedCurrentPath(pathname, LOCALES.japanese.path)}>
                      <img src={ICONS.regionJapan} alt="Japanese" width={24} height={24} />
                    </a>
                  </Locale>
                  <Locale>
                    <a href={getLocalisedCurrentPath(pathname, LOCALES.chinese.path)}>
                      <img src={ICONS.regionChina} alt="Chinese" width={24} height={24} />
                    </a>
                  </Locale>
                </Locales>
              );
            }}
          </Location>
        </RightSection>
      </Footer>
    </React.Fragment>
  );
};

export default SiteFooter;
