import React from 'react';
import styled from 'react-emotion';
import { getSearchInputPlaceholder } from '../../../../../../cms/header';
import { useSiteHeader } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import KeywordsSearchInput from './components/KeywordsSearchInput';

const SearchBarContainer = styled('div')`
  height: 50px;
  width: 640px;
  border-radius: 5px;
  background-color: #dcdad8;
  display: flex;
  align-items: center;
  z-index: 250;

  ${MEDIA_BREAKPOINTS.max1260} {
    width: 550px;
  }

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 500px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 600px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 97%;
    height: 46px;
  }
`;

const SiteHeaderKeywordSearch = () => {
  const copy = useSiteHeader();
  const placeholder = getSearchInputPlaceholder(copy);

  const handleSearch = () => {
    console.log('initiate search');
  };

  return (
    <SearchBarContainer>
      <KeywordsSearchInput placeholder={placeholder} handleSearch={handleSearch} />
    </SearchBarContainer>
  );
};

export default SiteHeaderKeywordSearch;
