// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { getShortcutKeys } from '../utils/keyboardShortcuts';

export type KeyboardShortcutKeyGraphMdl = {
  shortcut_title: {
    text: string,
  },
  shortcut_keys: {
    text: string,
  },
  shortcut_icon: {
    text: string,
  },
};

export type KeyboardShortcutsGraphMdl = {
  data: {
    keyboard_shortcuts_modal_title: {
      text: string,
    },
    keyboard_shortcuts: Array<KeyboardShortcutKeyGraphMdl>,
    keyboard_shortcut_footer_popup_title: {
      text: string,
    },
    keyboard_shortcut_footer_popup_description: {
      html: string,
    },
    keyboard_shortcut_modal_keys: {
      text: string,
    },
  },
};

export const getKeyboardShortcutsModalTitle = (copy: KeyboardShortcutsGraphMdl): string => {
  return get(copy, 'data.keyboard_shortcuts_modal_title.text', '') || '';
};

export const getKeyboardShortcutsList = (
  copy: KeyboardShortcutsGraphMdl
): Array<KeyboardShortcutKeyGraphMdl> => {
  return get(copy, 'data.keyboard_shortcuts', []) || [];
};

export const getKeyboardShortcutTitle = (copy: KeyboardShortcutKeyGraphMdl): string => {
  return get(copy, 'shortcut_title.text', '') || '';
};

export const getKeyboardShortcutKeys = (copy: KeyboardShortcutKeyGraphMdl): Array<string> => {
  const shortcutKeysCombination = get(copy, 'shortcut_keys.text', '') || '';
  return getShortcutKeys(shortcutKeysCombination);
};

export const getKeyboardShortcutIcon = (copy: KeyboardShortcutKeyGraphMdl): string => {
  return get(copy, 'shortcut_icon.text', '') || '';
};

export const getKeyboardShortcutsFooterPopupTitle = (copy: KeyboardShortcutsGraphMdl): string => {
  return get(copy, 'data.keyboard_shortcut_footer_popup_title.text', '') || '';
};

export const getKeyboardShortcutsFooterPopupDescription = (
  copy: KeyboardShortcutsGraphMdl
): string => {
  return get(copy, 'data.keyboard_shortcut_footer_popup_description.html', '') || '';
};

export const getKeyboardShortcutsModalKeys = (copy: KeyboardShortcutsGraphMdl): Array<string> => {
  const shortcutKeysCombination = get(copy, 'data.keyboard_shortcut_modal_keys.text', '') || '';
  return getShortcutKeys(shortcutKeysCombination);
};

export const query = graphql`
  fragment KeyboardShortcuts on PrismicKeyboardShortcuts {
    data {
      keyboard_shortcuts_modal_title {
        text
      }
      keyboard_shortcuts {
        shortcut_title {
          text
        }
        shortcut_keys {
          text
        }
        shortcut_icon {
          text
        }
      }
      keyboard_shortcut_footer_popup_title {
        text
      }
      keyboard_shortcut_footer_popup_description {
        html
      }
      keyboard_shortcut_modal_keys {
        text
      }
    }
  }
`;
