// @flow
import React from 'react';
import styled from 'react-emotion';
import { useRecoilValue } from 'recoil';
import BrowseSongsManager from '../../../../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import ExpandableDisplaySongCard from '../../../../../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import { isSongSfx } from '../../../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
} from '../../../../../../../store/mixpanelAnalytics';
import ExpandableSongCard from '../../../../../../../song/components/ExpandableSongCard/ExpandableSongCard';
import AudioPlayerWrapper from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import DirectSongViewWrapper from '../../../../../DirectScreen/components/DirectSongViewWrapper/DirectSongViewWrapper';
import { PLAYER_TYPES } from '../../../../../../../song/data';

const SongsListContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MainSongContainer = styled('div')`
  margin-bottom: 50px;
  width: 100%;
`;

export const ListItem = styled('div')`
  position: relative;
  margin-bottom: 5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }
`;

type Props = {
  songs: Array<AlgoliaSongMdl>,
  shareSlug: string,
  songTitle: string,
};

const GlobalPlayerPackSongs = ({ songs, shareSlug, songTitle }: Props) => {
  const directSearchKeywords = useRecoilValue(directSearchKeywordsAtom);
  const directSearchMixpanelKeywords = useRecoilValue(directSearchMixpanelKeywordsAtom);
  const directSearchMagicKeywords = useRecoilValue(directSearchMagicKeywordsAtom);
  const directSearchYouTubeKeywords = useRecoilValue(directSearchYouTubeKeywordsAtom);

  const isInlinePlayer = songs[0] ? isSongSfx(songs[0]) : false;
  return (
    <>
      {songs.length > 0 && (
        <BrowseSongsManager
          songs={songs}
          songListType="pack"
          songListTitle="Pack"
          songListSlug={shareSlug}
          songlistKeywords={[]}
          keywords={directSearchKeywords}
          mixpanelKeywords={directSearchMixpanelKeywords}
          magicKeywords={directSearchMagicKeywords}
          youtubeKeywords={directSearchYouTubeKeywords}
          sectionOrigin="pack"
        >
          <SongsListContainer>
            <MainSongContainer>
              {isInlinePlayer ? (
                <AudioPlayerWrapper>
                  <DirectSongViewWrapper>
                    <ExpandableSongCard
                      song={songs[0]}
                      alwaysExpanded
                      hidePackActions
                      playerType={PLAYER_TYPES.sfx}
                    />
                  </DirectSongViewWrapper>
                </AudioPlayerWrapper>
              ) : (
                <ExpandableDisplaySongCard
                  song={songs[0]}
                  songPosition={0}
                  alwaysExpanded
                  fullSongTitle={songTitle}
                  hidePackActions
                />
              )}
            </MainSongContainer>
          </SongsListContainer>
        </BrowseSongsManager>
      )}
    </>
  );
};

export default GlobalPlayerPackSongs;
