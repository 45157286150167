// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { buttonResetCss } from '../Button/Button';
import { monoNavCss } from '../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import {
  useUserAcronym,
  useUserAvatar,
} from '../../user/components/UserProfileWrapper/UserProfileWrapper';

const Container = styled('button')`
  ${buttonResetCss};
  ${monoNavCss};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: rgba(15, 15, 20, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: ${props => (props.image ? `url('${props.image}')` : '')};
  background-size: cover;
  background-position: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 50px;
    height: 50px;
  }

  div:focus > &,
  &:hover,
  &:focus {
    &:not(:active) {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &:focus {
    outline: none;
  }
`;

const largeIconClass = css`
  width: 100px;
  height: 100px;
  font-size: 30px;
  letter-spacing: 5px;
  line-height: 50px;
`;

type Props = {
  image?: string,
};

const ProfileIcon = ({ image, dataCy, ...props }: Props) => {
  const userAvatar = useUserAvatar();
  const acronym = useUserAcronym();
  const avatar = image || userAvatar;
  return (
    <Container {...props} image={avatar} type="button" data-cy={dataCy}>
      {!avatar && acronym}
    </Container>
  );
};

export default ProfileIcon;

export const LargeProfileIcon = ({ className = '', ...props }) => (
  <ProfileIcon className={cx(largeIconClass, className)} {...props} />
);
