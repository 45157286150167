// @flow
import React from 'react';
import DisabledSongActionButton from '../DisabledSongActionButton/DisabledSongActionButton';
import DisabledDownloadIcon from '../../../../../../../assets/inline-assets/download-disabled.svg';
import { useMiscCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useSong } from '../../../../../SongContextWrapper/SongContextWrapper';
import {
  getMiscCopyDownloadablePartsTooltip,
  getMiscCopyDownloadableMicropartsTooltip,
} from '../../../../../../../cms/miscCopy';

type Props = {
  busy?: boolean,
  condensed?: boolean,
  topLabel?: boolean,
  inModal?: boolean;
};

const DisabledDownloadButton = ({
  busy = false,
  condensed = false,
  topLabel = false,
  inModal,
  ...props
}: Props) => {
  const miscCopy = useMiscCopy();
  const song = useSong();
  const label = song.isPart
    ? getMiscCopyDownloadablePartsTooltip(miscCopy)
    : getMiscCopyDownloadableMicropartsTooltip(miscCopy);
  return (
    <DisabledSongActionButton
      condensed={condensed}
      icon={<DisabledDownloadIcon />}
      label={label}
      topLabel={topLabel}
      inModal={inModal}
      {...props}
    />
  );
};

export default DisabledDownloadButton;
