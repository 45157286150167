// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type LeavePageModalGraphMdl = {
  data: {
    leave_page_header: {
      text: string,
    },
    leave_page_instruction: {
      html: string,
    },
    leave_filtered_results_page_header: {
      text: string,
    },
    leave_filtered_results_page_instructions: {
      html: string,
    },
  },
};

export const getLeavePageModalHeading = (copy: LeavePageModalGraphMdl): string => {
  return get(copy, 'data.leave_page_header.text', '') || '';
};

export const getLeavePageModalInstructions = (copy: LeavePageModalGraphMdl): string => {
  return get(copy, 'data.leave_page_instruction.html', '') || '';
};

export const getLeaveFilteredResultsPageModalHeading = (copy: LeavePageModalGraphMdl): string => {
  return get(copy, 'data.leave_filtered_results_page_header.text', '') || '';
};

export const getLeaveFilteredResultsPageModalInstructions = (
  copy: LeavePageModalGraphMdl
): string => {
  return get(copy, 'data.leave_filtered_results_page_instructions.html', '') || '';
};

export const query = graphql`
  fragment LeavePageModal on PrismicLeavePageModal {
    data {
      leave_page_header {
        text
      }
      leave_page_instruction {
        html
      }
      leave_filtered_results_page_header {
        text
      }
      leave_filtered_results_page_instructions {
        html
      }
    }
  }
`;
