// @flow
import { pickBy, get } from 'lodash';
import { FACET_KEYS, getFilterLabel } from '../api/algolia/data';
import type { AlgoliaFacetResults } from '../api/algolia/search';
import type { MiscCopyMdl } from '../cms/miscCopy';

export type FilterOptionMdl = {
  label: string,
  count: number,
  active: boolean,
  disabled: boolean,
};

export type FacetFilterDataMdl = {
  facetKey: string,
  label: string,
  filters: Array<FilterOptionMdl>,
};

type SelectedFiltersMdl = {
  [facetKey: string]: Array<string>,
};

export type SongTagFiltersMdl = Array<FacetFilterDataMdl>;

// Filter out any result facets that will not be used
const getFilteredFacets = (
  availableFacets: AlgoliaFacetResults,
  filterCategories: Array<string>
) => {
  const facetsToInclude = filterCategories.map(filter => FACET_KEYS[filter]);
  const filteredFacets = pickBy(availableFacets, (value, key) => {
    return facetsToInclude.includes(key);
  });
  return filteredFacets;
};

// Map the count and labael for each filter option within a facet category
const filterToTagFilterOptionMap = (
  facet: FacetFilterDataMdl,
  filter: string,
  active: boolean
): FilterOptionMdl => {
  const optionCount = facet[filter] || 0;
  return {
    label: filter,
    count: optionCount,
    active,
    disabled: optionCount === 0,
  };
};

// Map all data into FacetFilterMdl
const resultFacetToTagFilterMap = (
  currentFacets: AlgoliaFacetResults,
  allFacets: AlgoliaFacetResults,
  facetKey: string,
  selectedFacets: SelectedFiltersMdl,
  miscCopy: MiscCopyMdl
): FacetFilterDataMdl => {
  const label = getFilterLabel(facetKey, miscCopy, false);
  const selectedTags = selectedFacets[facetKey] || [];

  const allAvailableFilters = Object.keys(allFacets[facetKey]).map(af => {
    const isActive = selectedTags.includes(af);
    return filterToTagFilterOptionMap(currentFacets[facetKey], af, isActive);
  });

  return {
    facetKey,
    label,
    filters: allAvailableFilters,
  };
};

const sortFacetMap = (
  facetMap: SongTagFiltersMdl,
  filterCategories: Array<string>
): SongTagFiltersMdl => {
  const facetKeys = filterCategories.map(filter => FACET_KEYS[filter]);
  return facetMap.sort((a, b) => {
    const indexA = facetKeys.indexOf(a.facetKey);
    const indexB = facetKeys.indexOf(b.facetKey);
    return indexA - indexB;
  });
};

export const getTagFiltersData = (
  availableFacets: AlgoliaFacetResults,
  completeFacets: AlgoliaFacetResults,
  filterCategories: Array<string>,
  selectedFacets: SelectedFiltersMdl,
  miscCopy: MiscCopyMdl
): SongTagFiltersMdl => {
  const currentFilteredFacets = getFilteredFacets(availableFacets, filterCategories);
  const allFilteredFacets = getFilteredFacets(completeFacets, filterCategories);

  const facetMap = Object.keys(currentFilteredFacets).map(facetKey => {
    const facetData = resultFacetToTagFilterMap(
      currentFilteredFacets,
      allFilteredFacets,
      facetKey,
      selectedFacets,
      miscCopy
    );
    return facetData;
  });

  return sortFacetMap(facetMap, filterCategories);
};

const getUpdatedFacetData = (
  filterKey: string,
  filterValue: string,
  currentValues: Array<string>
) => {
  return {
    [filterKey]: currentValues.concat([filterValue]),
  };
};

const getNewFacetData = (filterKey: string, filterValue: string) => {
  return {
    [filterKey]: [filterValue],
  };
};

export const getUpdatedActiveFacetFilters = (
  filterKey: string,
  filterValue: string,
  selectedFilters: SelectedFiltersMdl
) => {
  const appliedFilter = get(selectedFilters, filterKey, null) || null;
  const updatedAppliedFilters = appliedFilter
    ? getUpdatedFacetData(filterKey, filterValue, appliedFilter)
    : getNewFacetData(filterKey, filterValue);

  const update = {
    ...selectedFilters,
    ...updatedAppliedFilters,
  };

  return update;
};

export const getUpdatedRemovedFacetFilters = (
  filterKey: string,
  filterValue: string,
  selectedFilters: SelectedFiltersMdl
) => {
  const appliedFilters = get(selectedFilters, filterKey, []) || [];
  const updatedFilers = appliedFilters.filter(af => af !== filterValue);

  if (updatedFilers.length > 0) {
    const updatedFacetFilters = { ...selectedFilters, [filterKey]: updatedFilers };
    return updatedFacetFilters;
  } else {
    const updatedFacetFilters = { ...selectedFilters };
    delete updatedFacetFilters[filterKey];
    return updatedFacetFilters;
  }
};

export const getTagFilterQuery = (selectedFilters: SelectedFiltersMdl): string => {
  let filterQuery = '';
  let currentFilterQuery = '';

  Object.keys(selectedFilters).forEach(sf => {
    const values = selectedFilters[sf];

    values.forEach(v => {
      if (!currentFilterQuery) {
        currentFilterQuery = `${sf}:"${v}"`;
      } else {
        currentFilterQuery = currentFilterQuery.concat(` AND ${sf}:"${v}"`);
      }
    });

    if (!filterQuery) {
      filterQuery = `(${currentFilterQuery})`;
    } else {
      filterQuery = filterQuery.concat(` AND (${currentFilterQuery})`);
    }

    currentFilterQuery = '';
  });

  return filterQuery;
};
