// @flow

export const getEnvShareUrl = (): string => {
  return process.env.GATSBY_SHARE_URL;
};

export const isDevMode = (): boolean => {
  const { GATSBY_DEV_MODE } = process.env;
  // eslint-disable-next-line eqeqeq
  return GATSBY_DEV_MODE && (GATSBY_DEV_MODE == 'true' || GATSBY_DEV_MODE == true);
};
export const isProdEnv = (): boolean => {
  const { GATSBY_ENVIRONMENT } = process.env;
  return GATSBY_ENVIRONMENT === 'production';
};
export const isStagingEnv = (): boolean => {
  const { GATSBY_ENVIRONMENT } = process.env;
  return GATSBY_ENVIRONMENT === 'staging';
};

export const isFacebookPixelEnabled = (): boolean => {
  const { GATSBY_DISABLE_FB_PIXEL } = process.env;
  return !GATSBY_DISABLE_FB_PIXEL;
};

export const isSniplyPixelEnabled = (): boolean => {
  const { GATSBY_DISABLE_SNIPLY_PIXEL } = process.env;
  return !GATSBY_DISABLE_SNIPLY_PIXEL;
};

export const isAlgoliaAnalyticsEnabled = (): boolean => {
  const { GATSBY_ENABLE_ALGOLIA_ANALYTICS } = process.env;
  return !!GATSBY_ENABLE_ALGOLIA_ANALYTICS;
};

export const isSearchIndexSwitcherEnabled = (): boolean => {
  const { GATSBY_SEARCH_INDEX_SWITCHER_ENABLED } = process.env;
  return !!GATSBY_SEARCH_INDEX_SWITCHER_ENABLED;
};

export const isYahooAnalyticsEnabled = (): boolean => {
  const { GATSBY_YAHOO_ANALYTICS_ENABLED } = process.env;
  return !!GATSBY_YAHOO_ANALYTICS_ENABLED;
};
