// @flow
import React, { useEffect } from 'react';
import { firebaseApiHandler } from '../../../api/firebase/api';
import { useAuthContext } from '../AuthWrapper/AuthWrapper';
import { useGenericApiState } from '../../../api/hooks';

type Props = {
  children: any,
};

const AuthRenewWrapper = ({ children }: Props) => {
  const { isAuthenticated } = useAuthContext();
  const [busy, setBusy, error, setError, apiErrorHandler] = useGenericApiState();

  useEffect(() => {
    if (isAuthenticated) {
      firebaseApiHandler.refreshAuthToken().catch(apiErrorHandler);
    }
  }, [isAuthenticated]);

  return <>{children}</>;
};

export default AuthRenewWrapper;
