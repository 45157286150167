// @flow
import React from 'react';
import styled from 'react-emotion';
import { useBookmarksPageCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import NoResultsLayout from '../../../../../ResultsScreen/components/ResultsView/components/NoResults/components/NoResultsLayout/NoResultsLayout';
import {
  getBookmarksNoBookmarksHeading,
  getBookmarksNoBookmarksMessage,
  getBookmarksNoResultsHeading,
  getBookmarksNoResultsMessage,
  getBookmarksSearchTipsHeading,
  getBookmarksSearchTipsInstructions,
} from '../../../../../../../cms/bookmarksPage';
import {
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { useBookmarksManagerContext } from '../BookmarksManager/BookmarksManager';

const Container = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: -112px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: -70px;
  }
`;

const BookmarksNoResults = () => {
  const { noBookmarks } = useBookmarksManagerContext();
  const copy = useBookmarksPageCopy();
  if (noBookmarks) {
    return (
      <Container>
        <NoResultsLayout
          heading={getBookmarksNoBookmarksHeading(copy)}
          description={getBookmarksNoBookmarksMessage(copy).replace(
            '$ICON$',
            '<span id="bookmark-icon"></span>'
          )}
        />
      </Container>
    );
  }
  return (
    <Container>
      <NoResultsLayout
        heading={getBookmarksNoResultsHeading(copy)}
        description={getBookmarksNoResultsMessage(copy)}
        subheading={getBookmarksSearchTipsHeading(copy)}
        subDescription={getBookmarksSearchTipsInstructions(copy)}
      />
    </Container>
  );
};

export default BookmarksNoResults;
