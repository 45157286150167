// @flow

import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../styles/responsive';
import { RawPlayPauseButton } from '../../../../song/components/FullDisplaySongCard/components/PreviewPlayPauseButton/PreviewPlayPauseButton';
import { lightColor } from '../../../../styles/config/colors';
import type { CarouselItemMdl } from '../../../../utils/featuredContent';
import VerifiedIcon from '../../../../assets/inline-assets/verified-icon.svg';
import { useShowSignUp } from '../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useAuthContext } from '../../../../auth/components/AuthWrapper/AuthWrapper';

const StyledCarouselItemContainer = styled('div')`
  height: 400px;
  background-size: cover;
  background-image: url(${props => props.backgroundImg});
  background-position: center;
  border-radius: 5px;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: #fff;

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 300px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 250px;
    background-image: url(${props => props.mobileBackgroundImg});
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    height: 210px;
  }
`;

const StyledItemContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

const StyledOverlay = styled('div')`
  height: 100%;
  width: 100%;
  background-color: ${transparentize(0.8, '#000000')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }

  & svg {
    path {
      fill: ${lightColor};
    }
  }

  & button::before {
    border: 1px solid ${lightColor};
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-color: ${transparentize(0.8, '#000000')};

    & button {
      display: none;
    }

    &:hover {
      background-color: ${transparentize(0.8, '#000000')};

      & button {
        display: none;
      }
    }

    &.playingFeatured {
      background-color: ${transparentize(0.4, lightColor)};

      & button {
        display: flex;
      }
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    justify-content: center;
    align-items: center;
    background-color: ${transparentize(0.8, '#000000')};

    & button {
      transform: scale(1.5);
    }
  }
`;

const StyledFeaturedLink = styled('h2')`
  font-family: Roboto;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 49px;
  margin-left: 20px;

  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }

  & svg {
    position: relative;
    top: 5px;
    left: 5px;
  }
`;

const StyledMobileFeaturedLink = styled('h2')`
  display: none;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    padding-left: 10px;
    display: block;

    & svg {
      transform: scale(0.6);
      position: relative;
      top: 9px;
    }
  }
`;

type Props = {
  itemData: CarouselItemMdl,
  isVerified?: boolean,
  playing: boolean,
  tryingToPlay: boolean,
  cantPlay: boolean,
  handleClick: () => void,
  handlePlay: () => void,
};

const FullScreenCarouselItem = ({
  itemData,
  isVerified = false,
  playing,
  tryingToPlay,
  cantPlay,
  handleClick,
  handlePlay,
}: Props) => {
  const { isAuthenticated } = useAuthContext();
  const { title, featuredImage, featuredMobileImage, mainImage } = itemData;

  const desktopImage = featuredImage || mainImage;
  const mobileImage = featuredMobileImage || mainImage;
  const showSignUp = useShowSignUp();

  const handlePlayClick = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    handlePlay();
  };

  return (
    <>
      <StyledCarouselItemContainer
        backgroundImg={desktopImage}
        mobileBackgroundImg={mobileImage}
        onClick={handleClick}
      >
        <StyledItemContent>
          <StyledOverlay>
            <RawPlayPauseButton
              playing={playing}
              onClick={handlePlayClick}
              tryingToPlay={tryingToPlay}
              cantPlay={cantPlay}
            />
            <StyledFeaturedLink>
              {title}
              {isVerified && <VerifiedIcon />}
            </StyledFeaturedLink>
          </StyledOverlay>
        </StyledItemContent>
      </StyledCarouselItemContainer>
      <StyledMobileFeaturedLink onClick={handleClick}>
        {title}
        {isVerified && <VerifiedIcon />}
      </StyledMobileFeaturedLink>
    </>
  );
};

export default FullScreenCarouselItem;
