// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { cx, css } from 'emotion';
import { bodyTextCss, interactiveLinkCss } from '../../../../../styles/typography/typography';
import { darkColor } from '../../../../../styles/config/colors';
import { activeLinkClass } from '../../../../components/SiteNavLinks/SiteNavLinks';
import { buttonResetCss } from '../../../../../components/Button/Button';
import { getMiscCopySignOut } from '../../../../../cms/miscCopy';
import ProfileNavLinks from '../../../../components/ProfileNavLinks/ProfileNavLinks';
import { useMiscCopy } from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useAuthContext } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import { MEDIA_BREAKPOINTS } from '../../../../../styles/responsive';
import { useLocale } from '../../../../components/LocaleWrapper/LocaleWrapper';

const linkClass = css`
  ${bodyTextCss};
  color: ${transparentize(0.6, darkColor)};
  text-decoration: none;

  &.${activeLinkClass} {
    color: ${darkColor};
  }

  &:focus {
    outline: none;
  }

  ${interactiveLinkCss};
`;

const Nav = styled('nav')`
  a {
    ${linkClass};
  }

  li {
    &:not(:first-child) {
      margin-top: 5px;
      ${MEDIA_BREAKPOINTS.max1440} {
        max-width: 150px;
      }
      ${MEDIA_BREAKPOINTS.max1180} {
        max-width: 200px;
      }
    }
  }
`;

const SignOutWrapper = styled('div')`
  margin-top: 25px;
`;

const SignOutButton = styled('button')`
  ${buttonResetCss};
  ${linkClass};
`;

const japaneseLinkWidth = css`
  max-width: 170px;

  ${MEDIA_BREAKPOINTS.max1180} {
    max-width: 140px;
  }
`;

const ProfileNav = () => {
  const miscCopy = useMiscCopy();
  const { logout } = useAuthContext();
  const locale = useLocale();
  const handleSignOut = () => {
    logout();
  };
  return (
    <Nav
      className={cx({
        [japaneseLinkWidth]: locale === 'ja',
      })}
    >
      <ul>
        <ProfileNavLinks>
          {links => {
            return links.map((Link, key) => {
              return (
                <li key={key}>
                  <Link />
                </li>
              );
            });
          }}
        </ProfileNavLinks>
      </ul>
      <SignOutWrapper>
        <SignOutButton type="button" onClick={handleSignOut}>
          {getMiscCopySignOut(miscCopy)}
        </SignOutButton>
      </SignOutWrapper>
    </Nav>
  );
};

export default ProfileNav;
