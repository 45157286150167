// @flow

export type DropdownOptionMap = {
  value: string,
  label: string,
  allowed: boolean,
};

export const getLoopsDropdownFilterOptions = (
  allLabel: string,
  loopTypes: Array<string>
): Array<DropdownOptionMap> => {
  const options = [
    {
      value: 'all',
      label: allLabel,
      allowed: true,
    },
  ];

  loopTypes.forEach(loopType => {
    const newOption = {
      value: loopType,
      label: loopType,
      allowed: true,
    };
    options.push(newOption);
  });

  return options;
};

export const filterUniqueFilterOptions = (allLabel: string, loopTypes: Array<string>) => {
  const uniqueTypesSet = new Set(loopTypes);
  const uniqueLoopTypes = [...uniqueTypesSet];
  const allOptions = getLoopsDropdownFilterOptions(allLabel, uniqueLoopTypes);
  return allOptions;
};
