// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { GlobalHotKeys } from 'react-hotkeys';
import { useRecoilValue } from 'recoil';
import SongActionButton from '../../../../../FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import {
  useFindMusicPage,
  useMiscCopy,
  useProfileMenuCopy,
  useResultsPage,
  useSiteHeader,
} from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import PlaylistIcon from '../../../../../../../assets/inline-assets/playlist-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { capitalize } from '../../../../../../../utils/text';
import { getFindMusicSearchButton } from '../../../../../../../cms/findMusic';
import { getResultsPageSimilarSongsHeading } from '../../../../../../../cms/resultsPage';
import { useKeyboardShortcutRestricted } from '../../../../../../../toasts/hooks';
import { getPlayerKeyboardShortcuts } from '../../../../../../../utils/keyboardShortcuts';
import { getBookmarksNav } from '../../../../../../../cms/header';
import { getProfileMenuDownloadHistory } from '../../../../../../../cms/profileMenu';
import { getMiscCopyPack } from '../../../../../../../cms/miscCopy';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  playbackListNameAtom,
} from '../../../../../../../store/globalPlayer';

const BrowseLinkContainer = styled('div')`
  position: relative;
`;

const BrowseLinkButton = () => {
  const [hover, setHover] = useState(false);
  const findPage = useFindMusicPage();
  const resultsPage = useResultsPage();
  const siteHeader = useSiteHeader();
  const profileMenu = useProfileMenuCopy();
  const miscCopy = useMiscCopy();
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListName = useRecoilValue(playbackListNameAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const { navigateToPlaybackList } = useGlobalPlayerContext();

  const restrictedShortcutToast = useKeyboardShortcutRestricted();

  const handleHover = () => {
    setHover(!hover);
  };

  const linkLabel = (() => {
    if (playbackListType === 'search') {
      return getFindMusicSearchButton(findPage);
    }

    if (playbackListType === 'similar') {
      return getResultsPageSimilarSongsHeading(resultsPage);
    }

    if (playbackListType === 'collection' || playbackListType === 'artist') {
      return playbackListName;
    }

    if (playbackListType === 'bookmarks') {
      return getBookmarksNav(siteHeader);
    }

    if (playbackListType === 'downloads') {
      return getProfileMenuDownloadHistory(profileMenu);
    }

    if (playbackListType === 'pack') {
      return getMiscCopyPack(miscCopy);
    }

    return capitalize(playbackListSlug);
  })();

  const keyboardShortcutHandlers = (() => {
    if (true) {
      return {
        navigate_playlist: navigateToPlaybackList,
      };
    }
    return {
      navigate_playlist: restrictedShortcutToast,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <BrowseLinkContainer onMouseEnter={handleHover} onMouseLeave={handleHover}>
        <SongActionButton
          condensed
          icon={<PlaylistIcon />}
          label={`♪ ${linkLabel}`}
          onClick={navigateToPlaybackList}
          topLabel
        />
      </BrowseLinkContainer>
    </>
  );
};

export default BrowseLinkButton;
