// @flow
import React from 'react';
import PricingHeader from './components/PricingHeader';
import PricingPlans from './components/PricingPlans';
import PricingFaqs from './components/PricingFaqs';
import PricingPlanTable from './components/PricingPlanTable';
import PricingCreditCards from './components/PricingCreditCards';
import PricingCorpLogos from './components/PricingCorpLogos';

const PricingView = () => {
  return (
    <>
      <PricingHeader />
      <PricingPlans />
      <PricingCreditCards />
      <PricingCorpLogos />
      <PricingPlanTable />
      <PricingFaqs />
    </>
  );
};

export default PricingView;
