// @flow
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { getFeaturedTag, getFeaturedTagImage } from '../../../../../../cms/musicPage';
import { useNavigate } from '../../../../../hooks';
import { useGlobalPlayerContext } from '../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { ROUTES } from '../../../../../routes';
import PreviewTile from './PreviewTile';
import PreviewTileRow from './PreviewTileRow';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { capitalize } from '../../../../../../utils/text';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { searchTriggerAtom } from '../../../../../../store/mixpanelAnalytics';

type Props = {
  tagCategory: string,
  title: string,
  tags: any,
  viewAll?: boolean,
  viewAllLink?: boolean,
  oversizedTile?: boolean,
  page: string,
  rows: number,
  filterSongType?: string,
  searchPathParams?: string,
};

const FeaturedTagSection = ({
  tagCategory,
  title,
  tags,
  viewAll = false,
  viewAllLink = false,
  oversizedTile = false,
  page,
  rows,
  filterSongType = '',
  searchPathParams = '',
}: Props) => {
  const navigate = useNavigate();
  const { searchAlgoliaTag, handleUpdateGlobalAnalyticsData } = useGlobalPlayerContext();
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const fullViewLink = (() => {
    if (!viewAllLink) return '';

    if (tagCategory === 'genre') {
      return ROUTES.genres.navigatePath({});
    }

    if (tagCategory === 'mood') {
      return ROUTES.moods.navigatePath({});
    }

    if (tagCategory === 'instruments') {
      return ROUTES.instruments.navigatePath({});
    }

    return ROUTES.sfx.navigatePath({});
  })();

  const handleSearchTag = (keyword: string, searchPathParams: string) => {
    setResultsSearchTriggerLocation('tagSearch');
    const path = `${ROUTES.musicSearch.navigatePath({ keywords: [keyword] })}${searchPathParams}`;
    navigate(path);
  };

  const previewTagSongs = (previewTag: string, songType: string) => {
    handleUpdateGlobalAnalyticsData([previewTag], [], [], '', page, '', '');
    searchAlgoliaTag(previewTag, tagCategory, songType);
  };

  return (
    <PreviewTileRow title={title} viewAllLink={fullViewLink} showAllTiles={viewAll} rows={rows}>
      {tags.map((tag, index) => {
        const previewTag = getFeaturedTag(tag, tagCategory);
        return (
          <PreviewTile
            index={index}
            parentLength={tags.length}
            key={previewTag}
            title={previewTag}
            slug={previewTag}
            bgImage={getFeaturedTagImage(tag, tagCategory)}
            listType={tagCategory}
            oversizedTile={oversizedTile}
            previewTile={() => {
              analyticsMixpanelPreviewSongs(
                mixpanel,
                moengage,
                capitalize(tagCategory),
                previewTag,
                page,
                userId
              );
              previewTagSongs(previewTag, filterSongType);
            }}
            navigateTile={() => {
              handleSearchTag(previewTag.toLowerCase(), searchPathParams);
            }}
          />
        );
      })}
    </PreviewTileRow>
  );
};

export default FeaturedTagSection;
