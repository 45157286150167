// @flow
import React from 'react';
import styled from 'react-emotion';
import { darkColor } from '../../../styles/config/colors';
import CheckMarkIcon from '../../../assets/inline-assets/checkmark-icon.svg';

export const HiddenCheckbox = styled('input')`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus {
    outline: none;
  }
`;

const Checkbox = styled('div')`
  width: 20px;
  height: 20px;
  border: 1px solid rgba(15, 15, 20, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input:focus + &,
  &:focus,
  &:hover {
    border: 1px solid rgba(15, 15, 20, 0.2);
  }

  svg {
    display: block;
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};

    path {
      stroke: ${darkColor};
    }
  }
`;

const SquareCheckbox = ({ className = '', checked, ...props }) => (
  <React.Fragment>
    <HiddenCheckbox {...props} checked={checked} type="checkbox" />
    <Checkbox className={className} checked={checked}>
      <CheckMarkIcon />
    </Checkbox>
  </React.Fragment>
);

export default SquareCheckbox;
