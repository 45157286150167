// @flow
import ReactGA from 'react-ga';
import React from 'react';
import { globalHistory, Router } from '@reach/router';
import EmbedScreen from '../../../../routing/screens/EmbedScreen/EmbedScreen';
import LocaleWrapper from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { PATHS } from '../../../../routing/routes';
import { LOCALES } from '../../../../locales';

type Props = {
  shareSlug: string,
};

globalHistory.listen(({ location }) => {
  ReactGA.pageview(location.pathname);
});

const EmbedAppContent = ({ shareSlug }: Props) => {
  return (
    <React.Fragment>
      <Router basepath="/">
        <LocaleWrapper locale={LOCALES.english.code} path="/" localePath="">
          <EmbedScreen path={PATHS.embedSong} key={PATHS.embedSong} shareSlug={shareSlug} />
        </LocaleWrapper>
        <LocaleWrapper
          locale={LOCALES.english.code}
          path={`/${LOCALES.english.code}`}
          localePath={`/${LOCALES.english.code}`}
        >
          <EmbedScreen path={PATHS.embedSong} key={PATHS.embedSong} shareSlug={shareSlug} />
        </LocaleWrapper>
        <LocaleWrapper
          locale={LOCALES.japanese.code}
          path={`/${LOCALES.japanese.code}`}
          localePath={`/${LOCALES.japanese.code}`}
        >
          <EmbedScreen path={PATHS.embedSong} key={PATHS.embedSong} shareSlug={shareSlug} />
        </LocaleWrapper>
        <LocaleWrapper
          locale={LOCALES.chinese.code}
          path={`/${LOCALES.chinese.code}`}
          localePath={`/${LOCALES.chinese.code}`}
        >
          <EmbedScreen path={PATHS.embedSong} key={PATHS.embedSong} shareSlug={shareSlug} />
        </LocaleWrapper>
      </Router>
    </React.Fragment>
  );
};

export default EmbedAppContent;
