// @flow
import React from 'react';
import styled from 'react-emotion';
import { useTranslation } from 'react-i18next';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isEnterpriseSubscription,
} from '../../../../../utils/subscriptions';
import { ROUTES } from '../../../../routes';
import { getLocalisedCurrentPath } from '../../../../../components/SiteFooter/SiteFooter';
import { transparentize } from 'polished';
import { lightColor } from '../../../../../styles/config/colors';
import { useLocalePath } from '../../../../components/LocaleWrapper/LocaleWrapper';

const Container = styled('div')`
  padding: 5px 0px;
  border-top: 1px solid ${transparentize(0.9, lightColor)};

  &:last-of-type {
    border-bottom: 1px solid ${transparentize(0.9, lightColor)};
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type MusicLicenseLinkProps = {
  planCode: String,
};

const MusicLicenseLink = ({ planCode }: MusicLicenseLinkProps) => {
  const { t } = useTranslation();
  const localePath = useLocalePath();

  const getLicenseLink = (planCode: string) => {
    if (isCreatorProSubscription(planCode)) {
      return (
        <Container>
          <a href={getLocalisedCurrentPath(ROUTES.creatorProLicense.path, localePath)}>
            {t('terms.musicLicenseLinkText.loyalMember', '')}
          </a>
        </Container>
      );
    }

    if (isBusinessSubscription(planCode)) {
      return (
        <Container>
          <a href={getLocalisedCurrentPath(ROUTES.businessLicense.path, localePath)}>
            {t('terms.musicLicenseLinkText.loyalMemberPlus', '')}
          </a>
        </Container>
      );
    }

    if (isEnterpriseSubscription(planCode)) {
      return (
        <Container>
          <a href={getLocalisedCurrentPath(ROUTES.enterpriseLicense.path, localePath)}>
            {t('terms.musicLicenseLinkText.enterprise', '')}
          </a>
        </Container>
      );
    }

    return '';
  };

  return <>{getLicenseLink(planCode)}</>;
};

export default MusicLicenseLink;
