// @flow
import React from 'react';
import styled from 'react-emotion';
import Modal, { ModalContainer, notiModalCss, notiModalOverlayCss } from '../Modal/Modal';
import { mediumHeadingCss, smallPlainTextCss } from '../../../styles/typography/typography';
import { ModalCloseButton, ModalCloseWrapper } from '../ClassicModalLayout/ClassicModalLayout';
import CloseIcon from '../../../assets/inline-assets/close.svg';
import { MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT } from '../../../styles/responsive';

const Body = styled('section')`
  ${smallPlainTextCss};
  padding: 28px 30px 30px 30px;
  position: relative;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 20px;
  }

  h2,
  h3 {
    ${mediumHeadingCss};
    margin-bottom: 13px;
  }

  p {
    max-width: 350px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {
  children: any,
  onClose?: () => void,
};

const MessageModal = ({ children, onClose }: Props) => (
  <Modal onClose={onClose} open className={notiModalCss} overlayClassName={notiModalOverlayCss}>
    <ModalContainer>
      <Body>
        {children}
        {onClose && (
          <ModalCloseWrapper>
            <ModalCloseButton onClick={onClose}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalCloseWrapper>
        )}
      </Body>
    </ModalContainer>
  </Modal>
);

export default MessageModal;
