// @flow
import React from 'react';
import { usePricingPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getPricingPageFaqs,
  getPricingPageFaqsSectionTitle,
  getPricingPageFaqTitle,
  getPricingPageFaqAnswer,
} from '../../../../../../cms/pricingPage';
import FaqsSection from '../../../../../../layouts/components/FaqsSection/FaqsSection';
import FaqItem from '../../../../../../layouts/components/FaqsSection/components/FaqItem/FaqItem';

const PricingFaqs = () => {
  const copy = usePricingPage();
  const faqsHeading = getPricingPageFaqsSectionTitle(copy);
  const faqs = getPricingPageFaqs(copy);
  return (
    <FaqsSection heading={faqsHeading}>
      {faqs.map(faq => {
        return (
          <FaqItem
            key={getPricingPageFaqTitle(faq)}
            question={getPricingPageFaqTitle(faq)}
            answer={getPricingPageFaqAnswer(faq)}
          />
        );
      })}
    </FaqsSection>
  );
};

export default PricingFaqs;
