// @flow
import React from 'react';

type Props = {
  children: any,
};

const ErrorPopupsWrapper = ({ children }: Props) => <React.Fragment>{children}</React.Fragment>;

export default ErrorPopupsWrapper;
