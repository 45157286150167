// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type NotFoundGraphMdl = {
  data: {
    _404_error_header: {
      text: string,
    },
    _404_error_message: {
      html: string,
    },
    home_button: {
      text: string,
    },
  },
};

export const getNotFoundHeading = (copy: NotFoundGraphMdl): string => {
  return get(copy, 'data._404_error_header.text', '') || '';
};

export const getNotFoundMessage = (copy: NotFoundGraphMdl): string => {
  return get(copy, 'data._404_error_message.html', '') || '';
};

export const getNotFoundHomeButton = (copy: NotFoundGraphMdl): string => {
  return get(copy, 'data.home_button.text', '') || '';
};

export const query = graphql`
  fragment NotFoundPage on Prismic404ErrorPage {
    data {
      _404_error_header {
        text
      }
      _404_error_message {
        html
      }
      home_button {
        text
      }
    }
  }
`;
