// @flow
import React from 'react';
import { GIFS } from '../../../../images/paths';

type Props = {};

const LoadingIcon = (props: Props) => (
  <img src={GIFS.evokeOctLoading} alt="Loading..." width="42" height="42" {...props} />
);

export default LoadingIcon;
