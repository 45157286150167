// @flow
import { get } from 'lodash';

export const KEYBOARD_SHORTCUTS = {
  results: {
    decrease_volume: ['Subtract', '-', 'shift+-'],
    increase_volume: ['Add', '+', '=', 'shift++'],
    mute: 'm',
    next_song: 'right',
    previous_song: 'left',
    // song_down: 'down',
    // song_up: 'up',
    play_pause: 'space',
    fast_forward: 'c',
    fast_backward: 'x',
    toggle_microparts: 'l',
    view_pack: 'p',
    download_song: 'd',
    bookmark_song: 'b',
    hide_song: 'h',
    share_song: 's',
    similar_search: 'a',
    edit_search: '/',
    close_player: 'backspace',
    navigate_playlist: 'n',
    open_shortcuts_map: 'shift+k',
  },
  direct: {
    decrease_volume: ['Subtract', '-', 'shift+-'],
    increase_volume: ['Add', '+', '=', 'shift++'],
    mute: 'm',
    open_shortcuts_map: 'shift+k',
  },
  mobileResults: {},
  downloadBookmark: {
    decrease_volume: ['Subtract', '-', 'shift+-'],
    increase_volume: ['Add', '+', '=', 'shift++'],
    mute: 'm',
    open_shortcuts_map: 'shift+k',
  },
};

export const getPlayerKeyboardShortcuts = (playerType: string) => {
  return get(KEYBOARD_SHORTCUTS, playerType, KEYBOARD_SHORTCUTS.results);
};

export const getShortcutKeys = (shortcutKeysCombination: string): Array<string> => {
  if (!shortcutKeysCombination) return [];

  const keyList = shortcutKeysCombination.split(' ~ ');
  return keyList;
};
