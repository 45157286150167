// @flow
import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { StringParam, useQueryParam } from 'use-query-params';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  getSubscriptionsHeading,
  getSubscriptionsInstructions,
} from '../../../../../cms/subscriptionsPage';
import { useSubscriptionsPage } from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import { GeneralDescription } from '../../../../../styles/shared';
import SubscriptionsHandler from '../../../../../user/subscriptions/components/SubscriptionsHandler/SubscriptionsHandler';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { SubscriptionsSection } from '../../../SignUpScreen/views/SignUpSubscriptionsView/SignUpSubscriptionsView';
import { useModalsContext } from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import { useEnterpriseFormCompleteToast } from '../../../../../toasts/hooks';
import { getSubscriptionPlanKey } from '../../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS } from '../../../../../user/subscriptions/data';

type Props = {
  uri: string,
};

export const useEnterpriseQuery = () => {
  return useQueryParam('enterprise', StringParam);
};

const ProfileSubscriptionsView = ({ uri }: Props) => {
  const copy = useSubscriptionsPage();
  const { loaded, subscriptions, fetchUserSubscriptions } = useSubscriptionsContext();
  const { setShowDownloadCounter } = useModalsContext();
  const [enterpriseQuery, setEnterpriseQuery] = useEnterpriseQuery();
  const toast = useEnterpriseFormCompleteToast();
  const hasEnterpriseBasicPlan =
    subscriptions.filter(
      element => getSubscriptionPlanKey(element) === SUBSCRIPTIONS.enterpriseBasic.key
    ).length >= 1;

  useEffect(() => {
    fetchUserSubscriptions();
    setShowDownloadCounter(false);
  }, []);

  useEffect(() => {
    if (enterpriseQuery === 'complete') {
      toast();
      setTimeout(() => {
        setEnterpriseQuery();
      }, 2000);
    }
  }, [enterpriseQuery]);

  if (!loaded) {
    return (
      <LoadingContainer fixed>
        <LoadingDisplay />
      </LoadingContainer>
    );
  }
  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer responsiveFullWidth>
        <header>
          <LargeHeading>{getSubscriptionsHeading(copy)}</LargeHeading>
          {!hasEnterpriseBasicPlan && (
            <GeneralDescription>{parse(getSubscriptionsInstructions(copy))}</GeneralDescription>
          )}
        </header>
        <SubscriptionsSection>
          <SubscriptionsHandler />
        </SubscriptionsSection>
      </CenteredMediumContainer>
    </PageComponent>
  );
};

export default ProfileSubscriptionsView;
