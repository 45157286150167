/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';

type Props = {
  label: string,
  icon: any,
  action: () => void,
  disabledButton?: boolean,
};

const IconWrapper = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

const disabledInlineButton = css`
  opacity: 0.5;
  cursor: default !important;
`;

const InlineButton = ({ label, icon, action, disabledButton = false }: Props) => {
  return (
    <li onMouseDown={action} className={cx({ [disabledInlineButton]: disabledButton })}>
      <span>{label}</span>
      <IconWrapper>{icon}</IconWrapper>
    </li>
  );
};

export default InlineButton;
