// @flow
import React, { useState, useRef } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useClickAway } from 'react-use';
import SongActionButton from '../../../../../FullSongCard/components/SongActions/components/SongActionButton/SongActionButton';
import MoreOptionsIcon from '../../../../../../../assets/inline-assets/options-icon.svg';
import ViewPackInlineButton from './components/ViewPackInlineButton';
import SimilarSearchInlineButton from './components/SimilarSearchInlineButton';
import ViewLoopsInlineButton from './components/ViewLoopsInlineButton';
import ShareInlineButton from './components/GlobalShareInlineButton';
import HideTrackInlineButton from './components/HideTrackInlineButton';
import {
  ShowOnSmallDesktopOnly,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { isLargeMobileDeviceSize } from '../../../../../../../utils/device';
import MoreSongActionsModal from '../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/MoreSongActionsModal';
import BookmarkInlineButton from '../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/BookmarkInlineButton';
import DownloadInlineButton from '../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/DownloadInlineButton';
import BrowseLinkInlineButton from '../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/BrowseLinkInlineButton';
import { getTrackMoreOptionsAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import MIDIDownloadInlineButton from '../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/MIDIDownloadInlineButton';

type Props = {
  condensed?: boolean,
  loopsAvailable?: boolean,
};

const BrowseLinkContainer = styled('div')`
  position: relative;
`;

const TooltipMenu = styled('div')`
  position: absolute;
  width: 190px;
  bottom: 100%;
  right: 0;
  display: none;

  border-radius: 5px;
  background-color: #d0ceca;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.25);
  z-index: 10;

  & ul {
    width: 100%;
    margin: 5px 0px;

    & li {
      height: 28px;
      padding: 0px 12px;
      color: #0f0f14;
      font-family: Roboto;
      font-size: 13px;
      letter-spacing: 0.43px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &: hover {
        cursor: pointer;
        background-color: rgba(15, 15, 20, 0.05);
      }
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: none;
  }
`;

const showTooltip = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionMenuButton = ({ condensed = false, loopsAvailable = true }: Props) => {
  const [hover, setHover] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const trackPage = useTrackCopy();
  const ref = useRef(null);

  const resetHoverState = () => {
    setHover(false);
  };

  useClickAway(ref, () => {
    setHover(false);
  });

  const handleClick = () => {
    if (!isLargeMobileDeviceSize()) {
      setHover(!hover);
      return;
    }
    setShowMobileModal(true);
  };

  const handleCloseModal = () => {
    if (!isLargeMobileDeviceSize()) return;
    setShowMobileModal(false);
  };

  return (
    <div ref={ref}>
      <BrowseLinkContainer onClick={handleClick}>
        <SongActionButton
          condensed={condensed}
          icon={<MoreOptionsIcon />}
          label={getTrackMoreOptionsAction(trackPage)}
          topLabel
        />
        <TooltipMenu
          className={cx({
            [showTooltip]: hover,
          })}
        >
          <ul>
            <ShowOnSmallDesktopOnly>
              <MIDIDownloadInlineButton />
              <BookmarkInlineButton />
              <DownloadInlineButton />
              <SimilarSearchInlineButton />
              <BrowseLinkInlineButton hideTooltip={resetHoverState} />
            </ShowOnSmallDesktopOnly>
            <ViewPackInlineButton />
            {loopsAvailable && <ViewLoopsInlineButton />}
            <ShareInlineButton hideTooltip={resetHoverState} shortcutEnabled />
            <HideTrackInlineButton shortcutEnabled />
          </ul>
        </TooltipMenu>
        {showMobileModal && (
          <MoreSongActionsModal
            onClose={handleCloseModal}
            showAllActions
            isGlobalPlayer
            loopsAvailable={loopsAvailable}
          />
        )}
      </BrowseLinkContainer>
    </div>
  );
};

export default ActionMenuButton;
