// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type PrivacyPolicyPageGraphMdl = {
  data: {
    privacy_policy_header: {
      text: string,
    },
    privacy_policy_body: {
      html: string,
    },
  },
};

export const getPrivacyPolicyPageHeading = (copy: PrivacyPolicyPageGraphMdl): string => {
  return get(copy, 'data.privacy_policy_header.text', '') || '';
};

export const getPrivacyPolicyPageBody = (copy: PrivacyPolicyPageGraphMdl): string => {
  return get(copy, 'data.privacy_policy_body.html', '') || '';
};

export const query = graphql`
  fragment PrivacyPolicy on PrismicPrivacyPolicyPage {
    data {
      privacy_policy_header {
        text
      }
      privacy_policy_body {
        html
      }
    }
  }
`;
