// @flow
import React from 'react';
import { useIsAuthenticated } from '../AuthWrapper/AuthWrapper';

type Props = {
  children: any,
  authOnly?: boolean,
};

const AuthRestricted = ({ children, authOnly = true }: Props) => {
  const authenticated = useIsAuthenticated();

  if (authOnly) {
    if (!authenticated) {
      return null;
    }
  } else if (authenticated) {
    return null;
  }

  return children;
};

export default AuthRestricted;
