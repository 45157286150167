// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { transparentize } from 'polished';
import { TextButton } from '../../../../../components/Button/Button';
import {
  getResultsPageHideDetailsButton,
  getResultsPageLoadDetailsButton,
} from '../../../../../cms/resultsPage';
import {
  useMiscCopy,
  useResultsPage,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { lightColor } from '../../../../../styles/config/colors';
import { bodyTextCss, largeTextCss } from '../../../../../styles/typography/typography';
import { SmallHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  getMiscCopyBPM,
  getMiscCopyEditStyle,
  getMiscCopyEnergy,
  getMiscCopyGenre,
  getMiscCopyInstruments,
  getMiscCopyLocation,
  getMiscCopyMood,
  getMiscCopyMovement,
  getMiscCopyVideoTheme,
  getMiscCopyPopularity,
} from '../../../../../cms/miscCopy';
import {
  getSongBPM,
  getSongEditStyle,
  getSongEnergy,
  getSongGenres,
  getSongInstruments,
  getSongLocation,
  getSongMood,
  getSongMovement,
  getSongVideoTheme,
  getSongPopularity,
} from '../../../../../api/algolia/song';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import { analyticsSongsDetails, useSongAnalyticsDimensions } from '../../../../../analytics/events';
import { useResultsManagerContext } from '../../../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';
import { useExpandableSongsContext } from '../../../ExpandableSongCard/components/ExpandableSongsWrapper/ExpandableSongsWrapper';
import { useSongViewContext } from '../../../../../routing/screens/DirectScreen/components/DirectSongViewWrapper/DirectSongViewWrapper';
import { useCollectionSongViewContext } from '../../../../../routing/screens/CollectionScreen/components/CollectionViewWrapper';
import { useEmbedSongViewContext } from '../../../../../routing/screens/EmbedScreen/components/EmbedSongViewWrapper/EmbedSongViewWrapper';
import { useIsSmallDevice } from '../../../../../components/responsive/SmallDeviceOnly';
import {
  useMixpanelSongAnalyticsDimensions,
  analyticsMixpanelViewTrackDetails,
} from '../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import SearchLink from '../../../../../components/Link/components/SearchLink';

const Container = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 9px;
  }
`;

const ToggleContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const toggleButtonClass = css`
  margin: 0 auto;
  display: inline-block;
  padding: 5px;
  width: auto;
  margin-top: -5px;
  margin-bottom: -5px;
`;

const DetailsCard = styled('div')`
  border: 1px solid rgba(240, 240, 235, 0.05);
  border-radius: 5px;
  padding: 24px 30px;
  margin-top: 45px;
  margin-bottom: 45px;
  width: 100%;
  max-width: 800px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 19px;
    margin-bottom: 20px;
    padding: 14px 10px 16px 20px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 28px;

  ${SMALL_DEVICE_BREAKPOINT} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 11px;
    grid-row-gap: 10px;

    & > div {
      padding-right: 10px;
    }
  }
`;

const Label = styled(SmallHeading)`
  color: ${transparentize(0.6, lightColor)};

  ${LARGE_DEVICE_BREAKPOINT} {
    margin-bottom: 9px;
  }
`;

const Value = styled('div')`
  ${bodyTextCss};
  text-transform: capitalize;
`;

const LargeValue = styled('div')`
  text-transform: capitalize;

  ${LARGE_DEVICE_BREAKPOINT} {
    ${largeTextCss};
  }
`;

const instrumentsClass = css`
  grid-column: span 2;
`;

const Header = styled(Grid)`
  ${LARGE_DEVICE_BREAKPOINT} {
    border-bottom: 1px solid ${transparentize(0.9, lightColor)};
    margin-bottom: 24px;
    padding-bottom: 23px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 11px;
  }
`;

const Details = () => {
  const copy = useMiscCopy();
  const resultsPage = useResultsPage();
  const song = useSong();
  const dimensions = useSongAnalyticsDimensions();
  const isSmallDevice = useIsSmallDevice();
  const { viewingDetails, setViewingDetails } = useResultsManagerContext()
    ? useResultsManagerContext()
    : useExpandableSongsContext()
    ? useExpandableSongsContext()
    : useSongViewContext()
    ? useSongViewContext()
    : useCollectionSongViewContext()
    ? useCollectionSongViewContext()
    : useEmbedSongViewContext();

  const [viewingDetailsMobile, setViewingDetailsMobile] = useState(true);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();

  const handleDesktopToggle = () => {
    if (!viewingDetails) {
      analyticsSongsDetails(dimensions);
      analyticsMixpanelViewTrackDetails(
        mixpanel,
        moengage,
        mixpanelSongDimensions,
        downloadContext,
        userRole
      );
    }
    setViewingDetails(!viewingDetails);
  };

  const handleMobileToggle = () => {
    if (!viewingDetailsMobile) {
      analyticsSongsDetails(dimensions);
      analyticsMixpanelViewTrackDetails(
        mixpanel,
        moengage,
        mixpanelSongDimensions,
        downloadContext,
        userRole
      );
    }
    setViewingDetailsMobile(!viewingDetailsMobile);
  };

  const mood = getSongMood(song).join(', ');
  const energy = getSongEnergy(song).join(', ');
  const location = getSongLocation(song).join(', ');
  const movement = getSongMovement(song).join(', ');
  const videoTheme = getSongVideoTheme(song).join(', ');
  const editStyle = getSongEditStyle(song).join(', ');
  const instruments = getSongInstruments(song).join(', ');

  return (
    <Container>
      <ToggleContainer>
        <TextButton
          className={toggleButtonClass}
          onClick={isSmallDevice ? handleMobileToggle : handleDesktopToggle}
        >
          {(isSmallDevice
          ? viewingDetailsMobile
          : viewingDetails)
            ? getResultsPageHideDetailsButton(resultsPage)
            : getResultsPageLoadDetailsButton(resultsPage)}
        </TextButton>
      </ToggleContainer>
      {(isSmallDevice ? viewingDetailsMobile : viewingDetails) && (
        <DetailsCard>
          <Header>
            <div>
              <Label>{getMiscCopyBPM(copy)}</Label>
              <LargeValue>{getSongBPM(song)}</LargeValue>
            </div>
            <div>
              <Label>{getMiscCopyGenre(copy)}</Label>
              <LargeValue>{getSongGenres(song).join(', ')}</LargeValue>
            </div>
            <div>
              <Label>{getMiscCopyPopularity(copy)}</Label>
              <LargeValue>{getSongPopularity(song)}</LargeValue>
            </div>
          </Header>
          <Grid>
            <div>
              <Label>{getMiscCopyMood(copy)}</Label>
              <Value>
                <SearchLink queryText={mood} />
              </Value>
            </div>
            <div>
              <Label>{getMiscCopyEnergy(copy)}</Label>
              <Value>
                <SearchLink queryText={energy} />
              </Value>
            </div>
            <div>
              <Label>{getMiscCopyLocation(copy)}</Label>
              <Value>
                <SearchLink queryText={location} />
              </Value>
            </div>
            <div>
              <Label>{getMiscCopyMovement(copy)}</Label>
              <Value>
                <SearchLink queryText={movement} />
              </Value>
            </div>
            <div>
              <Label>{getMiscCopyVideoTheme(copy)}</Label>
              <Value>
                <SearchLink queryText={videoTheme} />
              </Value>
            </div>
            <div>
              <Label>{getMiscCopyEditStyle(copy)}</Label>
              <Value>
                <SearchLink queryText={editStyle} />
              </Value>
            </div>
            <div className={instrumentsClass}>
              <Label>{getMiscCopyInstruments(copy)}</Label>
              <Value>
                <SearchLink queryText={instruments} />
              </Value>
            </div>
          </Grid>
        </DetailsCard>
      )}
    </Container>
  );
};

export default Details;
