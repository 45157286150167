// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { transparentize } from 'polished';
import { useSetRecoilState } from 'recoil';
import {
  getMiscCopyEditButton,
  getMiscShowLessButton,
  getMiscShowMoreButton,
} from '../../../../../../../../../cms/miscCopy';
import { useMiscCopy } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { lightColor } from '../../../../../../../../../styles/config/colors';
import { TextButton } from '../../../../../../../../../components/Button/Button';
import { clearButtonClass } from '../../../../../../../FindScreen/components/KeywordsSearchView/components/KeywordsAside/components/SelectedKeywords/SelectedKeywords';
import CloseIcon from '../../../../../../../../../assets/inline-assets/close.svg';
import MinusIcon from '../../../../../../../../../assets/inline-assets/minus-icon.svg';
import PlusIcon from '../../../../../../../../../assets/inline-assets/plus-icon.svg';
import { suggestionFocusedCss } from '../../../../../../../../../styles/shared';
import {
  LARGE_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import { ROUTES } from '../../../../../../../../routes';
import { useNavigate } from '../../../../../../../../hooks';
import { useAnalyticsMixpanelContext } from '../../../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelRemoveKeyword } from '../../../../../../../../../analytics/mixpanel';
import { isSmallDeviceSize } from '../../../../../../../../../utils/device';
import { getInitialKeywordLimit } from '../../../../../../../../../utils/keywordLimit';
import {
  getMagicQueryKeyword,
  getVisibleResultsKeywords,
  getMagicQueryReplacementKeyword,
} from '../../../../../../../../../api/algolia/magicKeywords';
import YouTubeKeyword from '../YouTubeKeyword/YouTubeKeyword';
import SimilarityKeyword from '../SimilarityKeyword/SimilarityKeyword';
import { base64DecodeURLValue } from '../../../../../../../../../utils/encode';
import { searchTriggerAtom } from '../../../../../../../../../store/mixpanelAnalytics';

const KeywordWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  .ResultsKeywords-button {
    border: 2px solid #25272c;
    margin-top: 5px;

    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

export const noResultsKeywordClass = css`
  ${suggestionFocusedCss};

  ${LARGE_DESKTOP_BREAKPOINT} {
    transform: translateX(-20px);

    &:hover,
    &:active,
    &:focus {
      transform: translateX(-20px);
    }
  }
`;

export const resultsKeywordsHeadingClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: unset;
  }
`;
const Button = styled('div')`
  display: flex;
  font-size: 16px;
  margin-left: 5px;
  text-align: center;
  color: white;
  border-radius: 3px;
  outline: none;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    background: rgba(240, 240, 235, 0.05);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    font-size: 15px;
    line-height: 25px;
    padding: 0px 10px 0px 10px;
  }
`;

const Icon = styled('div')`
  padding-right: 5px;
  position: relative;
  bottom: 2px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    bottom: 1px;
  }
`;
const IconClose = styled('div')`
  transform: scale(0.5, 0.5) translateY(15px) translateX(-5px);
  width: 7px;
  height: 7px;
`;
const IconCloseMobile = styled('div')`
  transform: scale(0.6, 0.6) translateY(10px) translateX(-5px);
  padding-left: 12px;
  width: 7px;
  height: 7px;
  ${SMALL_DESKTOP_BREAKPOINT} {
    transform: scale(0.6, 0.6) translateY(9px) translateX(-5px);
  }
`;
const MinusIconWrapper = styled('div')`
  transform: translateY(-3px);
`;

const TruncateButton = styled('span')`
  text-align: center;
  display: flex;
  justify-contnet: center;
  align-items: center;
  margin: 5px 0px 0px 5px;
  border-radius: 2px;
  background-color: ${transparentize(0.9, lightColor)};
  padding: 0px 5px;
  width- fit-content;
  border-radius: 2px;
  cursor: pointer;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin: 10px 0px 0px 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const EditButtonWrapper = styled('div')`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 10px;
  }
`;

const YoutubeEditButtonWrapper = styled(EditButtonWrapper)`
  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 0px;
  }
`;

const CompoundKeywordWrapper = styled('div')`
  display: flex;
  justify-content: center;

  ${MOBILE_DEVICE_BREAKPOINT} {
    flex-direction: column;
    align-items: center;
  }
`;

type Props = {
  onClear?: () => void,
  onClearFilters?: (
    Array<string>,
    {
      setQuery: (Array<string>) => void,
      setMagicQuery: (Array<string>) => void,
      handleClearSongFilters: string => void,
      isMagicKeyword: boolean,
      isYouTubeKeyword?: boolean,
    }
  ) => void,
  editEnabled?: boolean,
};

type InteractiveKeywordProps = {
  children: string,
  onClick: () => void,
  icon: React.Node,
};

const defineFirstLetter = (text: string) => {
  if (text[0] === '-') {
    return (
      <MinusIconWrapper>
        <MinusIcon />
      </MinusIconWrapper>
    );
  }
  return <PlusIcon />;
};
export const deleteMinusFromString = (str: string) => {
  if (str[0] === '-') {
    return str.slice(1);
  }
  return str;
};
export const InteractiveKeywordNewUi = (props: InteractiveKeywordProps): React.Node => {
  const { children, onClick } = props;
  const [changeIcon, setchangeIcon] = React.useState(defineFirstLetter(children));

  const mouseEnter = () => {
    if (isSmallDeviceSize()) return;
    setchangeIcon(
      <IconClose>
        <CloseIcon />
      </IconClose>
    );
  };
  const mouseLeave = () => {
    if (isSmallDeviceSize()) return;
    setchangeIcon(defineFirstLetter(children));
  };
  return (
    <Button
      className="ResultsKeywords-button"
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Icon>{changeIcon}</Icon>
      {deleteMinusFromString(children)}
      {isSmallDeviceSize() && (
        <IconCloseMobile>
          <CloseIcon />
        </IconCloseMobile>
      )}
    </Button>
  );
};
const ResultsKeywords = ({
  onClear = () => {},
  onClearFilters = () => {},
  editEnabled = false,
}: Props) => {
  const navigate = useNavigate();
  const {
    keywords,
    magicKeywords,
    mergedUIKeywords,
    mergedQueryKeywords,
    setQuery,
    magicQuery,
    setMagicQuery,
    handleClearSongFilters,
    appliedFilters,
    youtubeQuery,
    handleRemoveYouTubeKeyword,
    youtubeVideoTitle,
    youtubeVideoId,
    youtubeChannelTitle,
    youtubeChannelId,
    similarityQuery,
    similarSongQuery,
  } = useResultsManagerContext();
  const copy = useMiscCopy();
  const initialKeywordLimit = getInitialKeywordLimit('results');
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [keywordLimit, setKeywordLimit] = useState(initialKeywordLimit);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [showTruncateButton, setShowTruncateButton] = useState(false);

  const handleTruncate = () => {
    if (mergedUIKeywords.length > keywordLimit) {
      setKeywordLimit(mergedUIKeywords.length);
      setShowAllKeywords(true);
    } else {
      setShowAllKeywords(false);
      setKeywordLimit(initialKeywordLimit);
    }
  };

  useEffect(() => {
    if (showAllKeywords) {
      setKeywordLimit(Math.max(mergedUIKeywords.length, initialKeywordLimit));
    }

    if (mergedUIKeywords.length <= initialKeywordLimit) {
      setShowTruncateButton(false);
    } else {
      setShowTruncateButton(true);
    }
  }, [mergedUIKeywords]);

  const handleEdit = () => {
    navigate(
      ROUTES.find.navigatePath({
        keywords,
        magicKeywords: magicQuery,
        ytKeywords: youtubeQuery,
        channelId: youtubeChannelId,
        channelTitle: youtubeChannelTitle,
        videoId: youtubeVideoId,
        videoTitle: youtubeVideoTitle,
      })
    );
  };

  const removeKeyword = (keyword: string) => {
    const newKeywords = keywords.slice().filter(filterKeyword => {
      return filterKeyword.toLowerCase() !== keyword.toLowerCase();
    });
    const newMergedUIkeywordsLength = getVisibleResultsKeywords(newKeywords, magicKeywords).length;
    analyticsMixpanelRemoveKeyword(mixpanel, moengage, keyword, 'regular', [], false, 'category');
    setResultsSearchTriggerLocation('removeKeyword');
    if (newMergedUIkeywordsLength === 0 && (!youtubeQuery || youtubeQuery.length === 0)) {
      onClear();
    } else if (Object.keys(appliedFilters).length > 0) {
      onClearFilters(newKeywords, {
        setQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: false,
      });
    } else {
      setQuery(newKeywords);
    }
  };

  const removeMagicKeyword = (keyword: string) => {
    const newMagicKeywords = magicKeywords.slice().filter(magicKeyword => {
      return getMagicQueryKeyword(magicKeyword) !== keyword;
    });
    const replacementKeywords = magicKeywords
      .slice()
      .filter(magicKeyword => {
        return getMagicQueryKeyword(magicKeyword) === keyword;
      })
      .map(removedMagicKeyword => {
        return getMagicQueryReplacementKeyword(removedMagicKeyword);
      });
    const newMergedUIkeywordsLength = getVisibleResultsKeywords(keywords, newMagicKeywords).length;
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      keyword,
      'magic',
      replacementKeywords,
      false,
      'category'
    );
    setResultsSearchTriggerLocation('removeKeyword');
    if (newMergedUIkeywordsLength === 0 && (!youtubeQuery || youtubeQuery.length === 0)) {
      onClear();
    } else if (Object.keys(appliedFilters).length > 0) {
      onClearFilters(newMagicKeywords, {
        setQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: true,
      });
    } else {
      setMagicQuery(newMagicKeywords);
    }
  };

  const handleRemoveKeyword = (keyword: string) => {
    const isKeywordToRemoveMagic = !mergedQueryKeywords.includes(keyword);

    if (isKeywordToRemoveMagic) {
      removeMagicKeyword(keyword);
    } else {
      removeKeyword(keyword);
    }
  };

  const removeYouTubeKeyword = () => {
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      youtubeVideoTitle,
      'youtube',
      youtubeQuery.map(youtubeKeyword => base64DecodeURLValue(youtubeKeyword, true)),
      false,
      'category'
    );
    setResultsSearchTriggerLocation('removeKeyword');
    if (mergedUIKeywords.length === 0) {
      onClear();
    } else if (Object.keys(appliedFilters).length > 0) {
      onClearFilters(mergedUIKeywords, {
        setQuery,
        setMagicQuery,
        handleClearSongFilters,
        isMagicKeyword: false,
        isYouTubeKeyword: true,
        handleRemoveYouTubeKeyword,
      });
    } else {
      handleRemoveYouTubeKeyword();
    }
  };

  return (
    <>
      {youtubeQuery && (
        <CompoundKeywordWrapper>
          <YouTubeKeyword
            handleClick={removeYouTubeKeyword}
            video={youtubeVideoTitle}
            channel={youtubeChannelTitle}
          />
          <>
            {editEnabled && youtubeQuery && mergedUIKeywords.length === 0 && (
              <YoutubeEditButtonWrapper>
                <TextButton className={clearButtonClass} onClick={handleEdit}>
                  {getMiscCopyEditButton(copy)}
                </TextButton>
              </YoutubeEditButtonWrapper>
            )}
          </>
        </CompoundKeywordWrapper>
      )}
      {similarityQuery && similarSongQuery && (
        <CompoundKeywordWrapper>
          <SimilarityKeyword slug={similarSongQuery} />
        </CompoundKeywordWrapper>
      )}
      <KeywordWrapper>
        {mergedUIKeywords.slice(0, keywordLimit).map(keyword => (
          <InteractiveKeywordNewUi
            // className={cx(keywordClass, {
            //   [noResultsKeywordClass]: noResults,
            // })}
            key={keyword}
            icon={<MinusIcon />}
            iconStyle="minusIcon"
            onClick={() => handleRemoveKeyword(keyword)}
          >
            {keyword}
          </InteractiveKeywordNewUi>
        ))}
        {showTruncateButton && (
          <TruncateButton onClick={handleTruncate}>
            {showAllKeywords ? getMiscShowLessButton(copy) : getMiscShowMoreButton(copy)}
          </TruncateButton>
        )}
        {editEnabled && ((!youtubeQuery && !similarityQuery) || mergedUIKeywords.length > 0) && (
          <EditButtonWrapper>
            <TextButton className={clearButtonClass} onClick={handleEdit}>
              {getMiscCopyEditButton(copy)}
            </TextButton>
          </EditButtonWrapper>
        )}
      </KeywordWrapper>
    </>
  );
};

export default ResultsKeywords;
