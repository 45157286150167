// @flow
import React from 'react';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import KeywordsManager from '../FindScreen/components/KeywordsSearchView/components/KeywordsManager/KeywordsManager';
import KeywordsSearchView from '../FindScreen/components/KeywordsSearchView/KeywordsSearchView';
import { useSearchBookmarksCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getSearchBookmarksButtonText,
  getSearchBookmarksSearchPlaceholder,
  getSearchBookmarksTitle,
} from '../../../cms/searchBookmarks';
import { useNavigate } from '../../hooks';
import { ROUTES } from '../../routes';
import PageComponent from '../../components/PageComponent/PageComponent';

const Content = () => {
  const navigate = useNavigate();
  const copy = useSearchBookmarksCopy();
  const handleCancel = () => {
    navigate(ROUTES.bookmarks.navigatePath({}));
  };
  const handleSearch = (keywords: Array<string>, magicKeywords: Array<string>) => {
    navigate(ROUTES.bookmarks.navigatePath({ keywords, magicKeywords }));
  };
  return (
    <LayoutWrapper>
      <KeywordsManager>
        <KeywordsSearchView
          cancelEnabled
          onCancel={handleCancel}
          onSearch={handleSearch}
          showDescription={false}
          actionText={getSearchBookmarksButtonText(copy)}
          title={getSearchBookmarksTitle(copy)}
          placeholder={getSearchBookmarksSearchPlaceholder(copy)}
        />
      </KeywordsManager>
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

const BookmarksFilterScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default BookmarksFilterScreen;
