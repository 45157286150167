import React, { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useRecoilValue } from 'recoil';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { sendMixpanelPageVisit } from '../../../analytics/mixpanel';
import { useMusicSearchBarContext } from '../../../components/SiteHeader/components/SiteNav/components/MusicSearchBarWrapper/MusicSearchBarWrapper';
import { isStagingEnv } from '../../../utils/env';
import { searchActiveAtom } from '../../../store/siteHeaderSearch';

type Props = {
  children: any,
  path?: string,
  referralCode?: string,
};

export const useQueryParamUtmMedium = () => {
  return useQueryParam('utm_medium', StringParam);
};

export const useQueryParamUtmCampaign = () => {
  return useQueryParam('utm_campaign', StringParam);
};

export const useQueryParamUtmSource = () => {
  return useQueryParam('utm_source', StringParam);
};

const PageComponent = ({ children, path = '', referralCode = '' }: Props) => {
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [medium] = useQueryParamUtmMedium();
  const [campaign] = useQueryParamUtmCampaign();
  const [source] = useQueryParamUtmSource();

  const searchActive = useRecoilValue(searchActiveAtom);
  const { handleSearchToggle } = useMusicSearchBarContext();

  useEffect(() => {
    if (!path) return;
    const pageVisitProperties = {};
    if (medium) {
      pageVisitProperties['UTM Medium'] = medium;
    }
    if (campaign) {
      pageVisitProperties['UTM Campaign'] = campaign;
    }
    if (source) {
      pageVisitProperties['UTM Source'] = source;
    }
    if (referralCode) {
      pageVisitProperties['Referral Code'] = referralCode;
    }
    sendMixpanelPageVisit(mixpanel, moengage, path, pageVisitProperties);
  }, []);

  useEffect(() => {
    if (searchActive && !path.includes('search')) {
      handleSearchToggle();
    }
  }, [path]);

  return <>{children}</>;
};

export default PageComponent;
