// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useRecoilValue } from 'recoil';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewParts,
  getSongMixpanelAnalyticsDimensions,
  sendMixpanelPageVisit,
} from '../../../../../analytics/mixpanel';
import { fetchDirectSongAndFamily } from '../../../../../api/algolia/direct';
import {
  getSongMixType,
  getSongName,
  getSongShareSlug,
  isSongFullMix,
  getSongID,
} from '../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import LoadingDisplay from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useLocale } from '../../../../components/LocaleWrapper/LocaleWrapper';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';
import PackSongs from './components/PackSongs/PackSongs';
import GlobalPlayerPackSongs from './components/GlobalPlayerPackSongs/GlobalPlayerPackSongs';
import {
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../../../store/mixpanelAnalytics';
import PackCollections from './components/PackCollections/PackCollections';

type Props = {
  path: string,
  shareSlug: string,
};

const LoadingWrapper = styled('div')`
  height: 60vh;
`;

const getPartTypesList = (songs: Array<AlgoliaSongMdl>) => {
  const partTypesList = [];

  songs.forEach(song => {
    partTypesList.push(getSongMixType(song));
  });

  return partTypesList;
};

const PackView = ({ path, shareSlug }: Props) => {
  const directSearchTriggerLocation = useRecoilValue(directTriggerAtom);
  const directSearchMixpanelKeywords = useRecoilValue(directSearchMixpanelKeywordsAtom);
  const directSearchMagicKeywords = useRecoilValue(directSearchMagicKeywordsAtom);
  const directSearchYouTubeKeywords = useRecoilValue(directSearchYouTubeKeywordsAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [songs, setSongs] = useState([]);
  const [state, setState] = useState({
    loading: false,
    loaded: false,
  });
  const locale = useLocale();
  const { userRole } = useSubscriptionsContext();

  useEffect(() => {
    const pageVisitProperties = {};
    if (shareSlug) {
      pageVisitProperties['Share Slug'] = shareSlug;
    }
    sendMixpanelPageVisit(mixpanel, moengage, path, pageVisitProperties);
  }, [shareSlug]);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    fetchDirectSongAndFamily(locale, shareSlug).then(response => {
      setSongs(response);
      const songIndex = response.findIndex((song: AlgoliaSongMdl) => {
        return getSongShareSlug(song) === shareSlug;
      });

      const selectedSongMixpanelDimensions = getSongMixpanelAnalyticsDimensions(
        response[songIndex]
      );

      const partTypes = getPartTypesList(response);
      analyticsMixpanelViewParts(
        mixpanel,
        moengage,
        selectedSongMixpanelDimensions,
        directSearchMixpanelKeywords,
        directSearchMagicKeywords,
        directSearchYouTubeKeywords,
        userRole,
        directSearchTriggerLocation,
        partTypes
      );

      setState({
        ...state,
        loaded: true,
      });
    });
  }, [shareSlug]);

  const { loading } = state;

  const fullMixTitle =
    songs.length > 0
      ? getSongName(
          songs.filter(song => {
            return isSongFullMix(song);
          })[0]
        )
      : '';

  const allowFullMixes = songs.length > 0 ? !isSongFullMix(songs[0]) : false;

  return (
    <BrowseLayout nav={<></>} hideNav wideLayout>
      {loading ? (
        <LoadingWrapper>
          <LoadingDisplay />
        </LoadingWrapper>
      ) : (
        <>
          <GlobalPlayerPackSongs songs={songs} shareSlug={shareSlug} songTitle={fullMixTitle} />
          <PackSongs
            songs={songs}
            shareSlug={shareSlug}
            songTitle={fullMixTitle}
            allowFullMixes={allowFullMixes}
          />
          <PackCollections songIds={songs.map(song => getSongID(song))} packSlug={shareSlug} />
        </>
      )}
    </BrowseLayout>
  );
};

export default PackView;
