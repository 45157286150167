// @flow
import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { useSetRecoilState } from 'recoil';
import {
  FilterElement,
  FilterLabel,
  FilterButtonWrapper,
  AddFilterButton,
  activeFilterButton,
  hideFilterButton,
} from '../FilterMenuOption/FilterMenuOption';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { useLocale } from '../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import { boldFontWeightCss } from '../../../../../../../../../styles/typography/typography';
import PadlockIcon from '../../../../../../../../../assets/inline-assets/padlock-icon.svg';
import { getFormattedCount } from '../../../../../../../../../user/subscriptions/components/AddedSubscription/components/YouTubeChannelSearch/YouTubeChannelSearch';
import {
  useFilterChangeToast,
  useFreeTierRestrictionToast,
} from '../../../../../../../../../toasts/hooks';
import { isMobile, isLargeMobileDeviceSize } from '../../../../../../../../../utils/device';
import {
  useMiscCopy,
  useResultsPage,
} from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../styles/responsive';
import AddFilterIcon from '../../../../../../../../../assets/inline-assets/include-filter.svg';
import RemoveFilterIcon from '../../../../../../../../../assets/inline-assets/exclude-filter.svg';
import { getGroupedBooleanFilters } from '../../../../../../../../../api/algolia/data';
import {
  getResultsPageIncludeFilterLabel,
  getResultsPageExcludeFilterLabel,
} from '../../../../../../../../../cms/resultsPage';
import { useSubscriptionsContext } from '../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useShowSignUp } from '../../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { conversionSourceAtom } from '../../../../../../../../../store/mixpanelAnalytics';

type Props = {
  facetKey: string,
  filterKey: string,
  filterLabel: string,
  filterCount: number,
  isIncluded: boolean,
  isExcluded: boolean,
  isGroupFilter?: boolean,
  isSubGroupFilter?: boolean,
  filterPermission: boolean,
};

const FilterElementWithTooltip = styled(FilterElement)`
  position: relative;
`;

const InteractiveFilterWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BooleanFilterButtonWrapper = styled(FilterButtonWrapper)`
  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 120px;
  }
`;

const BooleanFilterButton = styled(AddFilterButton)`
  &:hover {
    & span {
      &.includeFilter {
        right: 25px;
      }
      &.excludeFilter {
        right: 50px;
      }
    }
  }
`;

const activeFilterLabel = css`
  ${boldFontWeightCss};
`;

const zeroCountFilter = css`
  opacity: 0.5;

  & button {
    display: none;
  }
`;

const lockedFilter = css`
  & span {
    text-decoration: line-through;
    opacity: 0.5;

    & svg {
      margin-left: 10px;
    }
  }

  & button {
    display: none;
  }

  &:hover {
    background-color: transparent;
  }
`;

const FilterMenuBooleanOption = ({
  facetKey,
  filterKey,
  filterLabel,
  filterCount,
  isIncluded,
  isExcluded,
  isGroupFilter = false,
  isSubGroupFilter = false,
  filterPermission,
}: Props) => {
  const {
    handleAddSongsFilter,
    handleRemoveSongsFilter,
    swapSongsFilter,
    addFilterGroup,
    removeFilterGroup,
    swapFilterGroup,
  } = useResultsManagerContext();
  const locale = useLocale();
  const miscCopy = useMiscCopy();
  const resultsPage = useResultsPage();
  const { subscriptions, openChangeSubscriptionModal, selectPlan } = useSubscriptionsContext();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };
  const filterToast = useFreeTierRestrictionToast('filters', toastUtilities);
  const includeFilterChangeToast = useFilterChangeToast(facetKey, filterLabel, 'included');
  const excludeFilterChangeToast = useFilterChangeToast(facetKey, filterLabel, 'excluded');
  const removedFilterChangeToast = useFilterChangeToast(facetKey, filterLabel, 'removed');

  const handleFilterRestricted = () => {
    if (!filterPermission) {
      if (isLargeMobileDeviceSize()) {
        filterToast();
      }
      return;
    }
  };

  const handleAddInclusiveBooleanFilter = () => {
    if (isGroupFilter) {
      if (isIncluded) {
        const filtersToRemove = getGroupedBooleanFilters(locale, facetKey);
        removeFilterGroup(facetKey, filtersToRemove[filterKey], filterLabel, 'included');
        if (isMobile()) {
          removedFilterChangeToast();
        }
      } else if (isExcluded) {
        const filtersToSwap = getGroupedBooleanFilters(locale, facetKey);
        swapFilterGroup(facetKey, filtersToSwap[filterKey], filterLabel, 'excluded', 'included');
        if (isMobile()) {
          includeFilterChangeToast();
        }
      } else {
        const filtersToAdd = getGroupedBooleanFilters(locale, facetKey);
        addFilterGroup(facetKey, filtersToAdd[filterKey], filterLabel, 'included');
        if (isMobile()) {
          includeFilterChangeToast();
        }
      }
    } else if (isIncluded) {
      handleRemoveSongsFilter(facetKey, filterKey, 'included');
      if (isMobile()) {
        removedFilterChangeToast();
      }
    } else if (isExcluded) {
      swapSongsFilter(facetKey, filterKey, 'excluded', 'included', false, '');
      if (isMobile()) {
        includeFilterChangeToast();
      }
    } else if (filterCount > 0) {
      handleAddSongsFilter(facetKey, filterKey, 'included', false, '');
      if (isMobile()) {
        includeFilterChangeToast();
      }
    }
  };

  const handleAddExclusiveBooleanFilter = () => {
    if (isGroupFilter) {
      if (isExcluded) {
        const filtersToRemove = getGroupedBooleanFilters(locale, facetKey);
        removeFilterGroup(facetKey, filtersToRemove[filterKey], filterLabel, 'excluded');
        if (isMobile()) {
          removedFilterChangeToast();
        }
      } else if (isIncluded) {
        const filtersToSwap = getGroupedBooleanFilters(locale, facetKey);
        swapFilterGroup(facetKey, filtersToSwap[filterKey], filterLabel, 'included', 'excluded');
        if (isMobile()) {
          excludeFilterChangeToast();
        }
      } else {
        const filtersToAdd = getGroupedBooleanFilters(locale, facetKey);
        addFilterGroup(facetKey, filtersToAdd[filterKey], filterLabel, 'excluded');
        if (isMobile()) {
          includeFilterChangeToast();
        }
      }
    } else if (isExcluded) {
      handleRemoveSongsFilter(facetKey, filterKey, 'excluded');
      if (isMobile()) {
        removedFilterChangeToast();
      }
    } else if (isIncluded) {
      swapSongsFilter(facetKey, filterKey, 'included', 'excluded', false, '');
      if (isMobile()) {
        excludeFilterChangeToast();
      }
    } else if (filterCount > 0) {
      handleAddSongsFilter(facetKey, filterKey, 'excluded', false, '');
      if (isMobile()) {
        includeFilterChangeToast();
      }
    }
  };

  return (
    <FilterElementWithTooltip
      className={cx({
        [activeFilterLabel]: isIncluded || isExcluded,
        [zeroCountFilter]: filterCount === 0,
        [lockedFilter]: !filterPermission,
      })}
    >
      <InteractiveFilterWrapper onClick={handleFilterRestricted}>
        <FilterLabel isSubGroupFilter={isSubGroupFilter}>
          {`${filterLabel} (${getFormattedCount(filterCount)})`}
          {!filterPermission && <PadlockIcon />}
        </FilterLabel>
        {filterPermission && (
          <BooleanFilterButtonWrapper>
            <BooleanFilterButton
              className={cx('includeFilter', {
                [hideFilterButton]: isExcluded,
                [activeFilterButton]: isIncluded,
              })}
              onClick={handleAddInclusiveBooleanFilter}
            >
              <AddFilterIcon />
              <span className="includeFilter">{getResultsPageIncludeFilterLabel(resultsPage)}</span>
            </BooleanFilterButton>
            <BooleanFilterButton
              className={cx('excludeFilter', {
                [hideFilterButton]: isIncluded,
                [activeFilterButton]: isExcluded,
              })}
              onClick={handleAddExclusiveBooleanFilter}
            >
              <RemoveFilterIcon />
              <span className="excludeFilter">{getResultsPageExcludeFilterLabel(resultsPage)}</span>
            </BooleanFilterButton>
          </BooleanFilterButtonWrapper>
        )}
      </InteractiveFilterWrapper>
    </FilterElementWithTooltip>
  );
};

export default FilterMenuBooleanOption;
