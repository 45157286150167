// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ShareTrackModalGraphMdl = {
  data: {
    share_track_header: {
      text: string,
    },
    action_copy_link: {
      text: string,
    },
    action_link_copied: {
      text: string,
    },
    start_at_tick_box: {
      text: string,
    },
    facebook: {
      text: string,
    },
    twitter: {
      text: string,
    },
    tumblr: {
      text: string,
    },
    messenger: {
      text: string,
    },
    email: {
      text: string,
    },
  },
};

export const getShareTrackModalHeading = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.share_track_header.text', '') || '';
};

export const getShareTrackModalCopyAction = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.action_copy_link.text', '') || '';
};

export const getShareTrackModalCopiedAction = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.action_link_copied.text', '') || '';
};

export const getShareTrackModalStartAt = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.start_at_tick_box.text', '') || '';
};

export const getShareTrackModalFacebook = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.facebook.text', '') || '';
};

export const getShareTrackModalTwitter = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.twitter.text', '') || '';
};

export const getShareTrackModalTumblr = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.tumblr.text', '') || '';
};

export const getShareTrackModalMessenger = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.messenger.text', '') || '';
};

export const getShareTrackModalEmail = (copy: ShareTrackModalGraphMdl): string => {
  return get(copy, 'data.email.text', '') || '';
};

export const query = graphql`
  fragment ShareTrackModal on PrismicShareTrackModal {
    data {
      share_track_header {
        text
      }
      action_copy_link {
        text
      }
      action_link_copied {
        text
      }
      start_at_tick_box {
        text
      }
      facebook {
        text
      }
      twitter {
        text
      }
      tumblr {
        text
      }
      messenger {
        text
      }
      email {
        text
      }
    }
  }
`;
