// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SignInUpErrorsGraphMdl = {
  data: {
    generic: {
      text: string,
    },
    email_required: {
      text: string,
    },
    password_required: {
      text: string,
    },
    valid_email_required: {
      text: string,
    },
    sign_in_access_denied: {
      text: string,
    },
    invalid_sign_up: {
      text: string,
    },
    invalid_password: {
      text: string,
    },
    generic_error: {
      text: string,
    },
    empty_inputs_error: {
      text: string,
    },
    unchecked_terms_of_service_error: {
      text: string,
    },
    emoji_restricted_error: {
      text: string,
    },
  },
};

export const getInvalidSignUpError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.invalid_sign_up.text', '') || '';
};

export const getInvalidPasswordError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.invalid_password.text', '') || '';
};

export const getGenericError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.generic_error.text', '') || '';
};

export const getSignInAccessDeniedError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.sign_in_access_denied.text', '') || '';
};

export const getEmailRequiredError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.email_required.text', '') || '';
};

export const getPasswordRequiredError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.password_required.text', '') || '';
};

export const getValidEmailRequiredError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.valid_email_required.text', '') || '';
};

export const getEmptyInputsError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.empty_inputs_error.text', '') || '';
};

export const getUncheckedTermsOfServiceError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.unchecked_terms_of_service_error.text', '') || '';
};

export const getEmojiRestrictedError = (copy: SignInUpErrorsGraphMdl): string => {
  return get(copy, 'data.emoji_restricted_error.text', '') || '';
};

export const query = graphql`
  fragment SignInUpErrors on PrismicSignInupErrors {
    data {
      generic {
        text
      }
      email_required {
        text
      }
      password_required {
        text
      }
      valid_email_required {
        text
      }
      sign_in_access_denied {
        text
      }
      invalid_sign_up {
        text
      }
      invalid_password {
        text
      }
      generic_error {
        text
      }
      empty_inputs_error {
        text
      }
      unchecked_terms_of_service_error {
        text
      }
      emoji_restricted_error {
        text
      }
    }
  }
`;
