// @flow
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { bodyTextCss, smallMonoBoldCss } from '../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { inputResetCss, placeholderCss } from '../../styles/shared';
import { darkColor } from '../../styles/config/colors';
import { LargeHeading } from '../../styles/typography/components/Heading/Heading';

export const FormHeading = styled(LargeHeading)`
  margin-bottom: 58px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 54px;
  }
`;

export const FormSection = styled('section')`
  margin-top: 35px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 25px;
  }
`;
export const FormInputContainer = styled('div')`
  position: relative;
`;
export const FormInputLabel = styled('label')`
  ${smallMonoBoldCss};
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: ${props => (!props.visible ? 'hidden' : 'visible')};

  input:focus + & {
    visibility: visible;
  }
`;
export const FormInput = styled('input')`
  ${inputResetCss};
  display: block;
  width: 100%;
  ${bodyTextCss};
  border-bottom: 1px solid ${transparentize(0.9, darkColor)};
  color: ${darkColor};
  padding-bottom: 15px;
  padding-top: 24px;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-bottom: 12px;
    padding-top: 22px;
    font-size: 16px;
  }

  ${placeholderCss(`
    color: ${transparentize(0.6, darkColor)};
  `)};

  &:hover {
    ${placeholderCss(`
      color: ${transparentize(0.4, darkColor)};
    `)};
  }

  &:focus {
    border-color: ${darkColor};
    outline: none;

    ${placeholderCss(`
      color: transparent;
    `)};
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px rgba(255, 230, 140, 0.15) inset !important;
  }
`;
