// @flow

import ReactGA from 'react-ga';

ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
  titleCase: false,
});

export const analyticsSetUser = (uid: string) => {
  ReactGA.set({
    userId: uid,
  });
};

export const analyticsLogApiDuration = (url: string, duration: number) => {
  ReactGA.timing({
    category: 'API Duration',
    variable: url,
    value: duration,
  });
};
