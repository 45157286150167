import React from 'react';
import styled from 'react-emotion';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { mobileHeaderHeight } from '../../../../../components/SiteHeader/SiteHeader';
import AllCollections from './components/AllCollections/AllCollections';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';

type Props = {
  path: string,
};

export const Container = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    margin-top: ${126}px;
    margin-bottom: 150px;
    width: 100%;
    padding: 0 60px;
    display: flex;
    flex-direction: row;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${18 + mobileHeaderHeight}px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

// コレクションの一覧画面
const CollectionsView = ({ path }: Props) => {
  return (
    <BrowseLayout path={path}>
      <AllCollections path="/" />
    </BrowseLayout>
  );
};

export default CollectionsView;
