// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css } from 'emotion';
import {
  bodyTextCss,
  interactiveLinkCss,
  monoNavCss,
} from '../../../../../styles/typography/typography';
import { darkColor } from '../../../../../styles/config/colors';
import { activeLinkBoldClass } from '../../../../components/SiteNavLinks/SiteNavLinks';
import MusicNavLinks from '../../../../components/MusicNavLinks/MusicNavLinks';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';

const linkClass = css`
  ${bodyTextCss};
  color: ${transparentize(0.6, darkColor)};
  text-decoration: none;

  &.${activeLinkBoldClass} {
    color: ${darkColor};
    font-weight: 500;
  }

  &:focus {
    outline: none;
  }

  ${interactiveLinkCss};
`;

const Nav = styled('nav')`
  display: flex;
  top: 126px;
  justify-content: center;

  ${MEDIA_BREAKPOINTS.max600} {
    width: 100%;
    overflow: scroll;
    scrollbar-width: thin;
    scrollbar-color: white black;
    justify-content: flex-start;
  }

  h3 {
    text-transform: uppercase;
    ${monoNavCss};
    margin-bottom: 10px;
  }
  a {
    ${linkClass};
  }

  li {
    &:not(:first-child) {
      margin-top: 5px;
      ${MEDIA_BREAKPOINTS.max1440} {
        max-width: 150px;
      }
      ${MEDIA_BREAKPOINTS.max1180} {
        max-width: 200px;
      }
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px;

    & ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: scroll;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: grey;
      }

      li {
        &:not(:first-child) {
          margin-top: 0px;
        }
        margin-right: 12px;
        margin-bottom: 7px;
      }
    }
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px 15px;
  }
`;

const MusicNav = () => {
  return (
    <Nav>
      <MusicNavLinks>
        {links => {
          return links.map((Link, index) => {
            return <Link key={index.toString()} />;
          });
        }}
      </MusicNavLinks>
    </Nav>
  );
};

export default MusicNav;
