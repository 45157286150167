// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type ConnectPaypalGraphMdl = {
  data: {
    paypal_header: {
      text: string,
    },
    paypal_instruction: {
      html: string,
    },
    address_placeholder: {
      text: string,
    },
  },
};

export const getConnectPaypalHeading = (copy: ConnectPaypalGraphMdl): string => {
  return get(copy, 'data.paypal_header.text', '') || '';
};

export const getConnectPaypalInstructions = (copy: ConnectPaypalGraphMdl): string => {
  return get(copy, 'data.paypal_instruction.html', '') || '';
};

export const getConnectPaypalAddressPlaceholder = (copy: ConnectPaypalGraphMdl): string => {
  return get(copy, 'data.address_placeholder.text', '') || '';
};

export const query = graphql`
  fragment ConnectPaypal on PrismicConntectPaypalPage {
    data {
      paypal_header {
        text
      }
      paypal_instruction {
        html
      }
      address_placeholder {
        text
      }
    }
  }
`;
