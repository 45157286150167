// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SiteFooterMdl = {
  data: {
    contact_us_link: {
      html: string,
    },
    terms_of_service_link: {
      html: string,
    },
    privacy_policy_link: {
      html: string,
    },
    twitter_link: {
      url: string,
    },
    medium_link: {
      url: string,
    },
    youtube_link: {
      url: string,
    },
    facebook_link: {
      url: string,
    },
    instagram_link: {
      url: string,
    },
    tiktok_social_link: {
      url: String,
    },
    press_link: {
      html: string,
    },
  },
};

export const getFooterTwitterLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.twitter_link.url', '') || '';
};

export const getFooterMediumLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.medium_link.url', '') || '';
};

export const getFooterYouTubeLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.youtube_link.url', '') || '';
};

export const getFooterFacebookLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.facebook_link.url', '') || '';
};

export const getFooterInstagramLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.instagram_link.url', '') || '';
};

export const getFooterTikTokLink = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.tiktok_link.url', '') || '';
};

export const getFooterPress = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.press_link.html', '') || '';
};

export const getFooterContactUs = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.contact_us_link.html', '') || '';
};

export const getFooterTermsOfService = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.terms_of_service_link.html', '') || '';
};

export const getFooterPrivacyPolicy = (footer: SiteFooterMdl): string => {
  return get(footer, 'data.privacy_policy_link.html', '') || '';
};

export const query = graphql`
  fragment SiteFooter on PrismicSiteFoote {
    data {
      contact_us_link {
        html
      }
      terms_of_service_link {
        html
      }
      privacy_policy_link {
        html
      }
      twitter_link {
        url
      }
      medium_link {
        url
      }
      youtube_link {
        url
      }
      facebook_link {
        url
      }
      instagram_link {
        url
      }
      tiktok_link {
        url
      }
      press_link {
        html
      }
    }
  }
`;
