// @flow
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { css } from 'emotion';
import { BANNERS } from '../../../images/paths';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import { SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';

const bannerClass = css`
  height: 0px;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 0px;
  }
`;

const responsiveIframe = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    position: relative;
    width: 80%;
    padding-bottom: 56.25%;
    height: 0;
    margin: auto;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

type Props = { onClose: () => void, videoIframe: string };

const VideoModal = ({ onClose, videoIframe }: Props) => {
  return (
    <ClassicModalLayout bannerClassName={bannerClass} onClose={onClose} fullSized={false}>
      <ClassicModalContentLayout heading={''}>
        <div className={responsiveIframe}>{parse(videoIframe)}</div>
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default VideoModal;
