// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type MiscCopyMdl = {
  data: {
    clear_button: {
      text: string,
    },
    keywords_title: {
      text: string,
    },
    keywords_empty_placeholder: {
      text: string,
    },
    suggestions_title: {
      text: string,
    },
    edit_button: {
      text: string,
    },
    bpm: {
      text: string,
    },
    genre: {
      text: string,
    },
    genres: {
      text: string,
    },
    popularity: {
      text: string,
    },
    mood: {
      text: string,
    },
    moods: {
      text: string,
    },
    energy: {
      text: string,
    },
    energies: {
      text: string,
    },
    location: {
      text: string,
    },
    locations: {
      text: string,
    },
    movement: {
      text: string,
    },
    movements: {
      text: string,
    },
    movement_sound: {
      text: string,
    },
    video_theme: {
      text: string,
    },
    video_themes: {
      text: string,
    },
    edit_style: {
      text: string,
    },
    edit_styles: {
      text: string,
    },
    instruments: {
      text: string,
    },
    sfx_type: {
      text: string,
    },
    sfx_types: {
      text: string,
    },
    sfx_feature: {
      text: string,
    },
    sfx_features: {
      text: string,
    },
    material: {
      text: string,
    },
    materials: {
      text: string,
    },
    sound_effects: {
      text: string,
    },
    browse: {
      text: string,
    },
    back_to_browse: {
      text: string,
    },
    featured: {
      text: string,
    },
    collections: {
      text: string,
    },
    type: {
      text: string,
    },
    types: {
      text: string,
    },
    length: {
      text: string,
    },
    all_loops: {
      text: string,
    },
    pack: {
      text: string,
    },
    name: {
      text: string,
    },
    card_number: {
      text: string,
    },
    country: {
      text: string,
    },
    mm: {
      text: string,
    },
    yy: {
      text: string,
    },
    cvc: {
      text: string,
    },
    email_address: {
      text: string,
    },
    password: {
      text: string,
    },
    view: {
      text: string,
    },
    view_all: {
      text: string,
    },
    continue_button: {
      text: string,
    },
    stay_button: {
      text: string,
    },
    leave_button: {
      text: string,
    },
    done_button: {
      text: string,
    },
    contact_support_button: {
      html: string,
    },
    youtube_channel_placeholder: {
      text: string,
    },
    youtube_channel_paid_placeholder: {
      text: string,
    },
    facebook_page_placeholder: {
      text: string,
    },
    instagram_account_placeholder: {
      text: string,
    },
    remove: {
      text: string,
    },
    keep: {
      text: string,
    },
    add_button: {
      text: string,
    },
    get_in_contact: {
      text: string,
    },
    sign_in_button: {
      text: string,
    },
    sign_out_button: {
      text: string,
    },
    sign_up_button: {
      text: string,
    },
    total: {
      text: string,
    },
    submit_button: {
      text: string,
    },
    skip_button: {
      text: string,
    },
    cancel_button: {
      text: string,
    },
    retry: {
      text: string,
    },
    upgrade_button: {
      text: string,
    },
    downgrade_button: {
      text: string,
    },
    change: {
      text: string,
    },
    connect_button: {
      text: string,
    },
    disconnect_button: {
      text: string,
    },
    connect: {
      text: string,
    },
    connecting: {
      text: string,
    },
    part: {
      text: string,
    },
    expand_track: {
      text: string,
    },
    load_more: {
      text: string,
    },
    confirm_button: {
      text: string,
    },
    loading_popup_message: {
      text: string,
    },
    monthly_button: {
      text: string,
    },
    quarterly_button: {
      text: string,
    },
    bi_annually_button: {
      text: string,
    },
    annually_button: {
      text: string,
    },
    per_month_interval: {
      text: string,
    },
    per_quarter_interval: {
      text: string,
    },
    bi_annually_interval: {
      text: string,
    },
    annually_interval: {
      text: string,
    },
    discounted_to_label: {
      text: string,
    },
    paid_label: {
      text: string,
    },
    advanced_access_tooltip: {
      text: string,
    },
    downloadable_parts_tooltip: {
      text: string,
    },
    downloadable_microparts_tooltip: {
      text: string,
    },
    upgrade_now_button: {
      text: string,
    },
    close_button: {
      text: string,
    },
    reset_button: {
      text: string,
    },
    show_less_button: {
      text: string,
    },
    show_more_button: {
      text: string,
    },
    more_button: {
      text: string,
    },
    less_button: {
      text: string,
    },
    tag_filters_title: {
      text: string,
    },
    tag_filters_modal_title: {
      text: string,
    },
    english: {
      text: string,
    },
    japanese: {
      text: string,
    },
  },
};

export const getMiscCopyLoadingPopupMessage = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.loading_popup_message.text', '') || '';
};

export const getMiscCopyLoadMore = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.load_more.text', '') || '';
};

export const getMiscCopyExpandTrack = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.expand_track.text', '') || '';
};

export const getMiscCopyConfirmButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.confirm_button.text', '') || '';
};

export const getMiscCopyPart = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.part.text', '') || '';
};

export const getMiscCopyConnecting = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.connecting.text', '') || '';
};

export const getMiscCopyConnect = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.connect.text', '') || '';
};

export const getMiscCopyDisconnectButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.disconnect_button.text', '') || '';
};

export const getMiscCopyConnectButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.connect_button.text', '') || '';
};

export const getMiscCopyChange = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.change.text', '') || '';
};

export const getMiscCopyDowngradeButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.downgrade_button.text', '') || '';
};

export const getMiscCopyUpgradeButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.upgrade_button.text', '') || '';
};

export const getMiscCopyRetry = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.retry.text', '') || '';
};

export const getMiscCopyCancelButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.cancel_button.text', '') || '';
};

export const getMiscCopySkipButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.skip_button.text', '') || '';
};

export const getMiscCopySubmitButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.submit_button.text', '') || '';
};

export const getMiscCopySignUp = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sign_up_button.text', '') || '';
};

export const getMiscCopyTotal = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.total.text', '') || '';
};

export const getMiscCopySignIn = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sign_in_button.text', '') || '';
};

export const getMiscCopySignOut = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sign_out_button.text', '') || '';
};

export const getMiscCopyAdd = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.add_button.text', '') || '';
};

export const getMiscCopyGetInContact = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.get_in_contact.text', '') || '';
};

export const getMiscCopyRemove = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.remove.text', '') || '';
};

export const getMiscCopyKeep = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.keep.text', '') || '';
};

export const getMiscCopyInstagramAccount = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.instagram_account_placeholder.text', '') || '';
};

export const getMiscCopyFacebookPage = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.facebook_page_placeholder.text', '') || '';
};

export const getMiscCopyYouTubeChannel = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.youtube_channel_placeholder.text', '') || '';
};

export const getMiscCopyYouTubePaidChannel = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.youtube_channel_paid_placeholder.text', '') || '';
};

export const getMiscCopyContinue = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.continue_button.text', '') || '';
};

export const getMiscCopyStay = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.stay_button.text', '') || '';
};

export const getMiscCopyLeave = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.leave_button.text', '') || '';
};

export const getMiscDoneButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.done_button.text', '') || '';
};

export const getMiscContactSupportButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.contact_support_button.html', '') || '';
};

export const getMiscCopyView = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.view.text', '') || '';
};

export const getMiscCopyViewAll = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.view_all.text', '') || '';
};

export const getMiscCopyOr = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.or.text', '') || '';
};

export const getMiscCopyPassword = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.password.text', '') || '';
};

export const getMiscCopyEmailAddress = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.email_address.text', '') || '';
};

export const getMiscCopyCVC = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.cvc.text', '') || '';
};

export const getMiscCopyYY = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.yy.text', '') || '';
};

export const getMiscCopyMM = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.mm.text', '') || '';
};

export const getMiscCopyCountry = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.country.text', '') || '';
};

export const getMiscCopyCardNumber = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.card_number.text', '') || '';
};

export const getMiscCopyName = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.name.text', '') || '';
};

export const getMiscCopyClearButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.clear_button.text', '') || '';
};

export const getMiscCopyKeywordsTitle = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.keywords_title.text', '') || '';
};

export const getMiscCopyKeywordsEmpty = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.keywords_empty_placeholder.text', '') || '';
};

export const getMiscCopySuggestionsTitle = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.suggestions_title.text', '') || '';
};

export const getMiscCopyEditButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.edit_button.text', '') || '';
};

export const getMiscCopyBPM = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.bpm.text', '') || '';
};

export const getMiscCopyGenre = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.genre.text', '') || '';
};

export const getMiscCopyGenres = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.genres.text', '') || '';
};

export const getMiscCopyPopularity = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.popularity.text', '') || '';
};

export const getMiscCopyMood = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.mood.text', '') || '';
};

export const getMiscCopyMoods = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.moods.text', '') || '';
};

export const getMiscCopyEnergy = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.energy.text', '') || '';
};

export const getMiscCopyEnergies = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.energies.text', '') || '';
};

export const getMiscCopyLocation = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.location.text', '') || '';
};

export const getMiscCopyLocations = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.locations.text', '') || '';
};

export const getMiscCopyMovement = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.movement.text', '') || '';
};

export const getMiscCopyMovements = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.movements.text', '') || '';
};

export const getMiscCopyMovementSound = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.movement_sound.text', '') || '';
};

export const getMiscCopyVideoTheme = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.video_theme.text', '') || '';
};

export const getMiscCopyVideoThemes = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.video_themes.text', '') || '';
};

export const getMiscCopyEditStyle = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.edit_style.text', '') || '';
};

export const getMiscCopyEditStyles = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.edit_styles.text', '') || '';
};

export const getMiscCopyInstruments = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.instruments.text', '') || '';
};

export const getMiscCopySfxType = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sfx_type.text', '') || '';
};

export const getMiscCopySfxTypes = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sfx_types.text', '') || '';
};

export const getMiscCopySfxFeature = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sfx_feature.text', '') || '';
};

export const getMiscCopySfxFeatures = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sfx_features.text', '') || '';
};

export const getMiscCopyMaterial = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.material.text', '') || '';
};

export const getMiscCopyMaterials = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.materials.text', '') || '';
};

export const getMiscCopySoundEffects = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.sound_effects.text', '') || '';
};

export const getMiscCopyBrowse = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.browse.text', '') || '';
};

export const getMiscCopyBackToBrowse = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.back_to_browse.text', '') || '';
};

export const getMiscCopyFeatured = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.featured.text', '') || '';
};

export const getMiscCopyCollections = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.collections.text', '') || '';
};

export const getMiscCopyType = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.type.text', '') || '';
};

export const getMiscCopyTypes = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.types.text', '') || '';
};

export const getMiscCopyLength = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.length.text', '') || '';
};

export const getMiscCopyAllLoops = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.all_loops.text', '') || '';
};

export const getMiscCopyPack = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.pack.text', '') || '';
};

export const getMiscCopyMonthlyButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.monthly_button.text', '') || '';
};

export const getMiscCopyQuarterlyButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.quarterly_button.text', '') || '';
};

export const getMiscCopyBiannuallyButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.bi_annually_button.text', '') || '';
};

export const getMiscCopyAnnuallyButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.annually_button.text', '') || '';
};

export const getMiscCopyMonthlyInterval = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.per_month_interval.text', '') || '';
};

export const getMiscCopyQuarterlyInterval = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.per_quarter_interval.text', '') || '';
};

export const getMiscCopyBiannuallyInterval = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.bi_annually_interval.text', '') || '';
};

export const getMiscCopyAnnuallyInterval = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.annually_interval.text', '') || '';
};

export const getMiscCopyDiscountedTo = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.discounted_to_label.text', '') || '';
};

export const getMiscCopyPaidLabel = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.paid_label.text', '') || '';
};

export const getMiscCopyAdvancedSearchTooltip = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.advanced_access_tooltip.text', '') || '';
};

export const getMiscCopyDownloadablePartsTooltip = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.downloadable_parts_tooltip.text', '') || '';
};

export const getMiscCopyDownloadableMicropartsTooltip = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.downloadable_microparts_tooltip.text', '') || '';
};

export const getMiscCopyUpgradeNowButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.upgrade_now_button.text', '') || '';
};

export const getMiscCopyCloseButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.close_button.text', '') || '';
};

export const getMiscCopyResetButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.reset_button.text', '') || '';
};

export const getMiscShowLessButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.show_less_button.text', '') || '';
};

export const getMiscShowMoreButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.show_more_button.text', '') || '';
};

export const getMiscMoreButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.more_button.text', '') || '';
};

export const getMiscLessButton = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.less_button.text', '') || '';
};

export const getMiscTagFiltersTitle = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.tag_filters_title.text', '') || '';
};

export const getMiscTagFiltersModalTitle = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.tag_filters_modal_title.text', '') || '';
};

export const getMiscEnglishLabel = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.english.text', '') || '';
};

export const getMiscJapaneseLabel = (copy: MiscCopyMdl): string => {
  return get(copy, 'data.japanese.text', '') || '';
};

export const query = graphql`
  fragment MiscCopy on PrismicMiscCopy {
    data {
      confirm_button {
        text
      }
      retry {
        text
      }
      skip_button {
        text
      }
      submit_button {
        text
      }
      clear_button {
        text
      }
      keywords_title {
        text
      }
      keywords_empty_placeholder {
        text
      }
      suggestions_title {
        text
      }
      edit_button {
        text
      }
      bpm {
        text
      }
      genre {
        text
      }
      genres {
        text
      }
      popularity {
        text
      }
      mood {
        text
      }
      moods {
        text
      }
      energy {
        text
      }
      energies {
        text
      }
      location {
        text
      }
      locations {
        text
      }
      movement {
        text
      }
      movements {
        text
      }
      movement_sound {
        text
      }
      video_theme {
        text
      }
      video_themes {
        text
      }
      edit_style {
        text
      }
      edit_styles {
        text
      }
      instruments {
        text
      }
      sfx_type {
        text
      }
      sfx_types {
        text
      }
      sfx_feature {
        text
      }
      sfx_features {
        text
      }
      material {
        text
      }
      materials {
        text
      }
      sound_effects {
        text
      }
      browse {
        text
      }
      back_to_browse {
        text
      }
      featured {
        text
      }
      collections {
        text
      }
      type {
        text
      }
      types {
        text
      }
      length {
        text
      }
      all_loops {
        text
      }
      pack {
        text
      }
      name {
        text
      }
      card_number {
        text
      }
      country {
        text
      }
      mm {
        text
      }
      yy {
        text
      }
      cvc {
        text
      }
      email_address {
        text
      }
      password {
        text
      }
      view {
        text
      }
      view_all {
        text
      }
      continue_button {
        text
      }
      stay_button {
        text
      }
      leave_button {
        text
      }
      done_button {
        text
      }
      contact_support_button {
        html
      }
      youtube_channel_placeholder {
        text
      }
      youtube_channel_paid_placeholder {
        text
      }
      facebook_page_placeholder {
        text
      }
      instagram_account_placeholder {
        text
      }
      remove {
        text
      }
      keep {
        text
      }
      add_button {
        text
      }
      get_in_contact {
        text
      }
      sign_in_button {
        text
      }
      sign_out_button {
        text
      }
      sign_up_button {
        text
      }
      total {
        text
      }
      cancel_button {
        text
      }
      upgrade_button {
        text
      }
      downgrade_button {
        text
      }
      change {
        text
      }
      connect_button {
        text
      }
      disconnect_button {
        text
      }
      connect {
        text
      }
      connecting {
        text
      }
      part {
        text
      }
      expand_track {
        text
      }
      load_more {
        text
      }
      loading_popup_message {
        text
      }
      monthly_button {
        text
      }
      quarterly_button {
        text
      }
      bi_annually_button {
        text
      }
      annually_button {
        text
      }
      per_month_interval {
        text
      }
      per_quarter_interval {
        text
      }
      bi_annually_interval {
        text
      }
      annually_interval {
        text
      }
      discounted_to_label {
        text
      }
      paid_label {
        text
      }
      advanced_access_tooltip {
        text
      }
      downloadable_parts_tooltip {
        text
      }
      downloadable_microparts_tooltip {
        text
      }
      upgrade_now_button {
        text
      }
      close_button {
        text
      }
      reset_button {
        text
      }
      show_less_button {
        text
      }
      show_more_button {
        text
      }
      less_button {
        text
      }
      more_button {
        text
      }
      tag_filters_title {
        text
      }
      tag_filters_modal_title {
        text
      }
      english {
        text
      }
      japanese {
        text
      }
    }
  }
`;
