// @flow

export const padNumber = (number: number): string => {
  if (number >= 10) return number.toString();
  return `0${number.toString()}`;
};

export const getHashCode = (string: string): number => {
  let hash = 0;
  let i;
  let chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
