// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import ErrorIcon from '../../assets/inline-assets/error-icon.svg';
import { smallPlainTextCss } from '../../styles/typography/typography';

const Container = styled('div')`
  display: flex;
  align-items: center;
  background-color: rgba(99, 211, 255, 0.2);
  border-radius: 3px;
  padding: 10px 20px;

  svg {
    display: block;

    g path:first-child {
      fill: #009fdf;
    }
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const topMarginClass = css`
  &:first-child {
    margin-top: 10px;
  }
`;

const Message = styled('p')`
  ${smallPlainTextCss};
  flex: 1;
  margin-left: 10px;
`;

type Props = {
  children: any,
};

const DisplayErrorMessage = ({ children, dataCy, ...otherProps }: Props) => (
  <Container {...otherProps} data-cy={dataCy}>
    <ErrorIcon />
    <Message>{children}</Message>
  </Container>
);

export default DisplayErrorMessage;

export const DisplayErrorMessageTopMargin = props => (
  <DisplayErrorMessage className={topMarginClass} {...props} />
);
