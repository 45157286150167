import React from 'react';
import styled from 'react-emotion';
import TruncateMarkup from 'react-truncate-markup';
import { transparentize } from 'polished';
import { SmallMonoTextButton } from '../../../components/Button/Button';
import { useMiscCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getMiscShowMoreButton } from '../../../cms/miscCopy';
import VerifiedIcon from '../../../assets/inline-assets/verified-icon.svg';
import {
  HideOnSmallDevice,
  MOBILE_DEVICE_BREAKPOINT,
  ShowOnSmallDevice,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { isSmallDeviceSize } from '../../../utils/device';
import { darkColor } from '../../../styles/config/colors';
import { ICONS } from '../../../images/paths';
import PosSnsSonotaButton from './PosSnsSonotaButton/PosSnsSonotaButton';

type Props = {
  coverUrl: string,
  coverPosition: number,
  thumbnailUrl: string,
  title: string,
  catchcopy: string,
  description: string,
  path: string,
  artistSlug: string,
  openDescriptionModal?: (slug: string) => void,
};

const StyledLayoutWrapper = styled('div')`
  width: 100%;
  height: 350px;
  background-image: ${props => (props.coverUrl ? `url(${props.coverUrl})` : '')};
  background-size: cover;
  background-position: ${props => `center ${props.coverPosition}%`};
  margin-bottom: 80px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-bottom: 110px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-position: center;
    height: 320px;
    margin-bottom: 20px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    background-position: center;
    height: 290px;
    margin-bottom: 20px;
  }
`;

const StyledOverlayWrapper = styled('div')`
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3) 100%);
  display: flex;
  align-items: flex-end;

  ${SMALL_DEVICE_BREAKPOINT} {
    background: ${transparentize(0.6, darkColor)};
  }
`;

const StyledContentWraper = styled('div')`
  width: 1020px;
  padding: 0px 20px;
  margin: 0px auto;
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 80px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    padding: 0px 40px;
    margin: 0px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    top: 0px;
  }
`;

const StyledThumbnailWrapper = styled('figure')`
  width: 220px;

  & img {
    border-radius: 5px;
    width: 220px;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 150px;
    display: flex;
    justify-content: flex-end;

    & img {
      position: relative;
      top: 88px;
      width: 150px;
      height: auto;
      border-radius: 10px;
    }
  }
`;

const StyledTextWrapper = styled('section')`
  max-width: 800px;
  padding: 0px 20px 0px 0px;
  margin: 50px 0px 0px 50px;

  & h2 {
    line-height: 1;
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;

    & svg {
      position: relative;
      bottom: -3px;
      margin-left: 5px;
    }

    & img {
      width: 40px;
      height: auto;
      margin-left: 20px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    text-align: center;
    margin: 100px 0px 0px 0px;
    padding: 0px;

    & h2 {
      font-size: 35px;
      margin-bottom: 0px;
      justify-content: center;

      & svg {
        transform: scale(0.8);
        bottom: -5px;
        margin-left: 5px;
      }
    }
    & img {
      width: 40px;
      height: auto;
      flex-direction: row;
      bottom: -5px;
      margin: 0px 20px;
    }
    & p {
      padding: 0px 20px;
    }
  }
`;

const StyledEllipsisButton = styled(SmallMonoTextButton)`
  margin-left: 10px;
  opacity: 0.6;
`;

const EllipsisButton = ({ label, handleClick }) => {
  return (
    <span>
      ...
      <StyledEllipsisButton onClick={handleClick}>{label}</StyledEllipsisButton>
    </span>
  );
};

const StyledSnsIcons = styled(`div`)`
  display: flex;
  align-items: center;
  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: center;
  }
  &.hide {
    display: none;
  }
`;

const StyledSnsIcon = styled(`div`)`
  float: right;
  &.hide {
    display: none;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    float: none;
  }
  & img {
    width: 40px;
    height: 40px;
  }
`;

const SnsIcons = ({ linktoYouTube, linktoTwitter, linktoInstagram, linktoPokekara }) => {
  const usingSnsList = () => {
    const usingSnsList = [];
    const snsList = [
      [linktoYouTube, ICONS.youtube],
      [linktoTwitter, ICONS.x],
      [linktoInstagram, ICONS.instagram],
      [linktoPokekara, ICONS.pokekara],
    ];
    snsList.forEach(sns => {
      if (sns[0] !== '') {
        usingSnsList.push(sns);
      }
    });
    return usingSnsList;
  };
  return (
    <StyledSnsIcons>
      <StyledSnsIcon>
        {usingSnsList().length > 0 ? (
          <a href={usingSnsList()[0][0]} target="_blank" rel="noopener noreferrer">
            <img src={usingSnsList()[0][1]} />
          </a>
        ) : (
          ''
        )}
        {usingSnsList().length > 1 ? (
          <a href={usingSnsList()[1][0]} target="_blank" rel="noopener noreferrer">
            <img src={usingSnsList()[1][1]} />
          </a>
        ) : (
          ''
        )}
      </StyledSnsIcon>
      {usingSnsList().length > 2 ? (
        <PosSnsSonotaButton linktoInstagram={linktoInstagram} linktoPokekara={linktoPokekara} />
      ) : (
        ''
      )}
    </StyledSnsIcons>
  );
};

const PosCoverTextLayout = ({
  title,
  catchcopy,
  description,
  openDescriptionModal,
  linktoYouTube,
  linktoTwitter,
  linktoInstagram,
  linktoPokekara,
}) => {
  const miscCopy = useMiscCopy();
  return (
    <StyledTextWrapper>
      <p>{catchcopy}</p>
      <h2>
        {title}
        <VerifiedIcon />
        <HideOnSmallDevice>
          <StyledSnsIcons>
            <SnsIcons
              linktoYouTube={linktoYouTube}
              linktoTwitter={linktoTwitter}
              linktoInstagram={linktoInstagram}
              linktoPokekara={linktoPokekara}
            />
          </StyledSnsIcons>
        </HideOnSmallDevice>
      </h2>
      <ShowOnSmallDevice>
        <StyledSnsIcons>
          <SnsIcons
            linktoYouTube={linktoYouTube}
            linktoTwitter={linktoTwitter}
            linktoInstagram={linktoInstagram}
            linktoPokekara={linktoPokekara}
          />
        </StyledSnsIcons>
      </ShowOnSmallDevice>
      <TruncateMarkup
        lines={isSmallDeviceSize() ? 4 : 2}
        ellipsis={
          <EllipsisButton
            handleClick={openDescriptionModal}
            label={getMiscShowMoreButton(miscCopy)}
          />
        }
      >
        <p>{description}</p>
      </TruncateMarkup>
    </StyledTextWrapper>
  );
};

const PosFullCoverArtLayout = ({
  coverUrl,
  coverPosition,
  thumbnailUrl,
  title,
  catchcopy,
  description,
  linktoYouTube,
  linktoTwitter,
  linktoInstagram,
  linktoPokekara,
  openDescriptionModal = () => {},
  openSnsIconsModal = () => {},
}) => {
  return (
    <>
      <StyledLayoutWrapper coverUrl={coverUrl} coverPosition={coverPosition}>
        <StyledOverlayWrapper>
          <StyledContentWraper>
            <StyledThumbnailWrapper onClick={openDescriptionModal}>
              <img src={thumbnailUrl} alt={title} />
            </StyledThumbnailWrapper>
            <HideOnSmallDevice>
              <PosCoverTextLayout
                title={title}
                description={description}
                openDescriptionModal={openDescriptionModal}
                openSnsIconsModal={openSnsIconsModal}
                catchcopy={catchcopy}
                linktoYouTube={linktoYouTube}
                linktoTwitter={linktoTwitter}
                linktoInstagram={linktoInstagram}
                linktoPokekara={linktoPokekara}
              />
            </HideOnSmallDevice>
          </StyledContentWraper>
        </StyledOverlayWrapper>
      </StyledLayoutWrapper>
      <ShowOnSmallDevice>
        <PosCoverTextLayout
          catchcopy={catchcopy}
          linktoYouTube={linktoYouTube}
          linktoTwitter={linktoTwitter}
          linktoInstagram={linktoInstagram}
          linktoPokekara={linktoPokekara}
          title={title}
          description={description}
          openDescriptionModal={openDescriptionModal}
          openSnsIconsModal={openSnsIconsModal}
        />
      </ShowOnSmallDevice>
    </>
  );
};

export default PosFullCoverArtLayout;
