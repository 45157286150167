// @flow
import React from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackDownloadAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import DownloadIcon from '../../../../../../../assets/inline-assets/download-icon.svg';
import DownloadingIcon from '../../../../../../../components/DownloadingIcon/DownloadingIcon';

type Props = {
  busy?: boolean,
  condensed?: boolean,
};

const DownloadButton = ({ busy = false, condensed = false, ...props }: Props) => {
  const trackCopy = useTrackCopy();
  return (
    <SongActionButton
      condensed={condensed}
      icon={busy ? <DownloadingIcon /> : <DownloadIcon />}
      label={getTrackDownloadAction(trackCopy)}
      {...props}
    />
  );
};

export default DownloadButton;
