// @flow
import React, { useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { GlobalHotKeys } from 'react-hotkeys';
import {
  useMagicSearchQuery,
  useSearchQuery,
  useViewingQuery,
} from '../../../../../../routing/screens/MusicScreen/views/SearchView/components/ResultsManager/ResultsManager';
import {
  getAlgoliaKeyword,
  getAlgoliaReplacementKeywords,
  getMagicKeywordQueryValues,
} from '../../../../../../api/algolia/magicKeywords';
import type { AlgoliaKeyword } from '../../../../../../api/algolia/magicKeywords';
import { useNavigate } from '../../../../../../routing/hooks';
import { ROUTES } from '../../../../../../routing/routes';
import {
  useYouTubeChannelIdQuery,
  useYouTubeChannelQuery,
  useYouTubeSearchQuery,
  useYouTubeVideoIdQuery,
  useYouTubeVideoQuery,
} from '../../../../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';
import { analyticsMixpanelAddKeyword } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { getPlayerKeyboardShortcuts } from '../../../../../../utils/keyboardShortcuts';
import { searchActiveAtom } from '../../../../../../store/siteHeaderSearch';
import { searchTriggerAtom } from '../../../../../../store/mixpanelAnalytics';

export type MusicSearchBarContextState = {
  searchActive: boolean,
  handleSearchToggle: () => void,
  handleKeywordSearch: (
    keyword: string,
    magicKeyword: AlgoliaKeyword | {},
    isSuggestion: boolean
  ) => void,
  handleOpenSearch: () => void,
};

export const MusicSearchBarContext = React.createContext<any>();

export const useMusicSearchBarContext = (): MusicSearchBarContextState => {
  return useContext(MusicSearchBarContext);
};

type Props = {
  children: any,
};

const MusicSearchBarWrapper = ({ children }: Props) => {
  const [searchActive, setSearchActive] = useRecoilState(searchActiveAtom);

  const navigate = useNavigate();
  const [keywordsQuery] = useSearchQuery();
  const [magicQuery] = useMagicSearchQuery();
  const [youtubeQuery] = useYouTubeSearchQuery();
  const [youtubeVideoTitle] = useYouTubeVideoQuery();
  const [youtubeVideoId] = useYouTubeVideoIdQuery();
  const [youtubeChannelTitle] = useYouTubeChannelQuery();
  const [youtubeChannelId] = useYouTubeChannelIdQuery();
  const [viewingQuery] = useViewingQuery();
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: undefined, moengage: undefined };

  const handleNewSearch = (keywords: Array<string>, magicKeywords: Array<string>) => {
    navigate(ROUTES.musicSearch.navigatePath({ keywords, magicKeywords }));
  };

  const handleUpdateSearch = (queryKeywords: Array<string>, magicQueryKeywords: Array<string>) => {
    const joinedKeywords = queryKeywords.concat(keywordsQuery);
    const joinedMagicKeywords = magicQueryKeywords.concat(magicQuery);
    navigate(
      ROUTES.musicSearch.navigatePath({
        keywords: joinedKeywords,
        magicKeywords: joinedMagicKeywords,
        ytKeywords: youtubeQuery,
        channelId: youtubeChannelId,
        channelTitle: youtubeChannelTitle,
        videoId: youtubeVideoId,
        videoTitle: youtubeVideoTitle,
        section: viewingQuery,
      })
    );
  };

  const handleKeywordSearch = (
    keyword: string,
    magicKeyword: AlgoliaKeyword,
    isSuggestion: boolean
  ) => {
    if (!keyword && !magicKeyword && keywordsQuery && magicQuery) return;

    const isUpdateSearch =
      (keywordsQuery ? keywordsQuery.length > 0 : false) ||
      (magicQuery ? magicQuery.length > 0 : false) ||
      (youtubeQuery ? youtubeQuery.length > 0 : false);

    const queryKeywords = keyword ? [keyword] : [];
    const magicQueryKeywords = magicKeyword ? getMagicKeywordQueryValues([magicKeyword]) : [];

    if (mixpanel && moengage) {
      if (keyword) {
        analyticsMixpanelAddKeyword(
          mixpanel,
          moengage,
          keyword,
          'regular',
          [],
          isSuggestion,
          'TBD',
          'TBD'
        );
      } else {
        analyticsMixpanelAddKeyword(
          mixpanel,
          moengage,
          getAlgoliaKeyword(magicKeyword),
          'magic',
          getAlgoliaReplacementKeywords(magicKeyword),
          isSuggestion,
          'TBD',
          'TBD'
        );
      }
    }

    setResultsSearchTriggerLocation('search');

    if (isUpdateSearch) {
      handleUpdateSearch(queryKeywords, magicQueryKeywords);
      return;
    }

    handleNewSearch(queryKeywords, magicQueryKeywords);
  };

  const handleSearchToggle = () => {
    setSearchActive(!searchActive);
  };

  const handleOpenSearch = () => {
    setSearchActive(true);
  };

  const handleSearchKeyboardShortcut = event => {
    if (event) {
      event.preventDefault();
    }
    if (searchActive) {
      const element = document.getElementById('searchInput');
      if (element) element.focus();
    } else {
      setSearchActive(true);
    }
  };

  const keyboardShortcutHandlers = (() => {
    return {
      edit_search: handleSearchKeyboardShortcut,
    };
  })();

  return (
    <MusicSearchBarContext.Provider
      value={{ handleSearchToggle, handleKeywordSearch, handleOpenSearch }}
    >
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      {children}
    </MusicSearchBarContext.Provider>
  );
};

export default MusicSearchBarWrapper;
