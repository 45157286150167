// @flow
import { useAnalyticsMixpanelContext } from './components/MixpanelWrapper';
import { domainLocaleToMixpanelLocale } from './mixpanel';

export const sendMoEngageEvent = (
  moengage: any,
  eventName: string,
  eventProperties: { [string]: any }
) => {
  moengage.addEvent(eventName, eventProperties);
};

export const updateMoEngageStandardUserProps = (
  moengage: any,
  standardUserProps: { [string]: any }
) => {
  moengage.updateAttributes({ ...standardUserProps });
};

export const updateMoEngageCustomUserProps = (
  moengage: any,
  customUserProperties: { [string]: any }
) => {
  Object.keys(customUserProperties).forEach(key => {
    const updateValue = customUserProperties[key];
    if (Array.isArray(updateValue)) {
      moengage.setCustomAttribute(key, customUserProperties[key].join(', '));
    } else if (updateValue === null) {
      moengage.setCustomAttribute(key, 'null');
    } else {
      moengage.setCustomAttribute(key, customUserProperties[key]);
    }
  });
};

export const analyticsMoEngageCreateAccount = (
  moengage: any,
  userId: String,
  firstName: string,
  lastName: string,
  email: string,
  signUpMethod: string,
  locale: string,
  eventProperties: { [string]: any }
) => {
  moengage.createUniqueUser(userId);

  updateMoEngageStandardUserProps(moengage, {
    email,
    addFirstName: firstName,
    addLastName: lastName,
    name: `${firstName} ${lastName}`,
  });

  updateMoEngageCustomUserProps(moengage, {
    'Sign Up Method': signUpMethod,
    'Auth0 ID': userId,
    Locale: domainLocaleToMixpanelLocale(locale),
    'Created Date': new Date().toISOString(),
    'Last Profile Updated': new Date().toISOString(),
    'New Music News': true,
    'Evoke Updates': true,

    // Initial Search Data
    '# of Filters Applied': 0,
    '# of Searches': 0,
    '# of Similar Searches': 0,
    'YouTube Extension Used': 'FALSE',
    '# of Previews': 0,

    // Track Data
    '# of Bookmarks': 0,
    '# of Bookmarked Collections': 0,
    '# of Downloads': 0,
    '# of Hidden Tracks': 0,
    '# of Plays': 0,
    '# of Shares': 0,

    // Subscription Data
    '# Subscribed': 0,
    '# YouTube Channels': 0,
    '# of Custom Orders': 0,
    'Active Subscriptions': [],
    'Active Subscription Codes': [],
    'Free Trial Eligiblity': true,
    'On Trial?': false,
    'Subscription Tier': 'No Subscriptions',
    'YouTube Channels': [],
  });
  sendMoEngageEvent(moengage, 'Create Account', { ...eventProperties });
};

export const updateMoEngageProfileDetails = (moengage: any, userId: string, profileData: any) => {
  const customAttributes = {
    Avatar: profileData.avatar,
    Company: profileData.company,
    Country: profileData.country,
    'Last  Profile Updated': new Date().toISOString(),
  };
  const standardAttributes = {
    addFirstName: profileData.firstName,
    addLastName: profileData.lastName,
    name: `${profileData.firstName} ${profileData.lastName}`,
    mobile: profileData.phoneNumber,
  };
  updateMoEngageStandardUserProps(moengage, standardAttributes);
  updateMoEngageCustomUserProps(moengage, customAttributes);
};

export const analyticsMoEngageSignIn = (moengage: any, userId: string, method: string) => {
  sendMoEngageEvent(moengage, 'Sign In', { 'Sign In Method': method });
  moengage.createUniqueUser(userId);
};
