// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  usePricingPlans,
  useSignUpEnterprisePage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPlans2 } from '../../../../cms/signup';
import {
  getPricingPlan,
  getPricingPlanBriefDescription,
  getPricingPlanName,
  getPricingPlanSubtitle,
} from '../../../../cms/pricingPlan';
import PlanPreview from '../PlanPreview/PlanPreview';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../data';

const Container = styled('section')`
  ul li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {};

const SelectEnterpriseSubscription = props => {
  const signUpEnterprisePage = useSignUpEnterprisePage();
  const pricingPlans = usePricingPlans();
  const { selectPlan } = useSubscriptionsContext();
  const enterprisePlan = [SUBSCRIPTIONS.enterpriseBasic.key];
  const subscriptionPricingPlans2 = getPricingPlans2(signUpEnterprisePage).filter(pricingPlan =>
    enterprisePlan.includes(pricingPlan)
  );

  const viewSubscription = (planKey: string) => {
    const selectedPlan = SUBSCRIPTIONS.enterpriseBasic.key;
    selectPlan(selectedPlan);
  };

  return (
    <Container {...props}>
      <ul>
        {subscriptionPricingPlans2.map(uid => {
          const plan = getPricingPlan(pricingPlans, uid);
          return (
            <li key={uid}>
              <PlanPreview
                plan={uid}
                name={getPricingPlanName(plan)}
                price={getPricingPlanSubtitle(plan)}
                description={getPricingPlanBriefDescription(plan)}
                onClick={() => {
                  viewSubscription(uid);
                }}
              />
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default SelectEnterpriseSubscription;
