// @flow
import React from 'react';
import styled from 'react-emotion';
import { buttonResetCss } from '../../../Button/Button';
import MenuIcon from '../../../../assets/inline-assets/mobile-menu-icon.svg';
import { useUserProfileContext } from '../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { brightColor } from '../../../../styles/config/colors';
import { USER_ROLES } from '../../../../user/subscriptions/data';
import {
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
} from '../../../../styles/responsive';

const MenuButton = styled('button')`
  ${buttonResetCss};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    display: block;
  }
`;

const DownloadCount = styled('div')`
  background-color: ${brightColor};
  position: absolute;
  top: -10px;
  right: -5px;
  border-radius: 1px;
  width: 13px;
  height: 13px;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  justify-content: center;

  p {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 1px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

type Props = {};

const MobileMenuButton = props => {
  const { remainingDailyDownloads } = useUserProfileContext();
  const { userRole } = useSubscriptionsContext();

  return (
    <MenuButton {...props}>
      <MenuIcon />
      {userRole === USER_ROLES.creator ? (
        <DownloadCount>
          <p>{remainingDailyDownloads}</p>
        </DownloadCount>
      ) : (
        ''
      )}
    </MenuButton>
  );
};

export default MobileMenuButton;
