// @flow
import React, { useState } from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { ErrorMessage, Formik } from 'formik';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button, { BUTTON_WIDTHS } from '../../../components/Button/Button';
import { useResetPasswordModalCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { LARGE_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import {
  getResetPasswordModalEmailAddressPlaceholder,
  getResetPasswordModalEmailMeButton,
  getResetPasswordModalHeading,
  getResetPasswordModalInstructions,
  getResetPasswordModalSuccess,
} from '../../../cms/resetPasswordModal';
import { RoundedInput } from '../../../components/forms/inputs/RoundedInput';
import { useForgotPasswordFormSchema } from './schema';
import { useResetPassword } from '../../../api/auth/account';
import DisplayErrorMessage from '../../../components/DisplayErrorMessage/DisplayErrorMessage';

const Body = styled('div')`
  margin-top: 29px;
`;

const InputContainer = styled('div')`
  width: 100%;
  margin-bottom: 20px;

  ${LARGE_DEVICE_BREAKPOINT} {
    max-width: 400px;
  }
`;

const resetPasswordButtonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  initialEmail: string,
  onClose: () => void,
};

const ForgotPasswordModal = ({ onClose, initialEmail }: Props) => {
  const [success, setSuccess] = useState();
  const [resetPassword, resetPasswordBusy] = useResetPassword();
  const schema = useForgotPasswordFormSchema();
  const copy = useResetPasswordModalCopy();
  const handleClose = () => {
    onClose();
  };
  const onSubmit = ({ email }, { setSubmitting }) => {
    if (resetPasswordBusy) return;
    setSubmitting(true);
    resetPassword(email)
      .then(() => {
        setSuccess(email);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const description = success
    ? getResetPasswordModalSuccess(copy).replace('$EMAIL$', `<strong>${success}</strong>`)
    : getResetPasswordModalInstructions(copy);

  return (
    <ClassicModalLayout
      onClose={handleClose}
      label={getResetPasswordModalHeading(copy)}
      fullSized={false}
    >
      <ClassicModalContentLayout
        heading={getResetPasswordModalHeading(copy)}
        description={description}
      >
        {!success && (
          <Body>
            <Formik
              initialValues={{ email: initialEmail }}
              isInitialValid
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {formProps => {
                const {
                  values,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = formProps;

                return (
                  <form onSubmit={handleSubmit}>
                    <ErrorMessage name="email" component={DisplayErrorMessage} />
                    <InputContainer>
                      <RoundedInput
                        id="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        type="email"
                        placeholder={getResetPasswordModalEmailAddressPlaceholder(copy)}
                        autoFocus
                      />
                    </InputContainer>
                    <div>
                      <Button
                        className={resetPasswordButtonLayout}
                        disabled={!isValid}
                        type="submit"
                        loading={isSubmitting}
                        mobileWidth={BUTTON_WIDTHS.full}
                      >
                        {getResetPasswordModalEmailMeButton(copy)}
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Body>
        )}
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default ForgotPasswordModal;
