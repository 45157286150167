// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import { get } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getSongName,
  getSongID,
  getSongShareSlug,
  getSongArtwork,
  getSongArtist,
  getSongArtistSlug,
  getSongBPM,
  getSongGenres,
  getSongVideoTheme,
  getSongEnergy,
  getSongMood,
  getSongEditStyle,
  getSongMixType,
  getSongDurationFormatted,
  isSongStem,
} from '../../../api/algolia/song';
import DownloadButton from '../FullSongCard/components/SongActions/components/DownloadButton/DownloadButton';
import DisabledDownloadButton from '../FullSongCard/components/SongActions/components/DisabledDownloadButton/DisabledDownloadButton';
import PlayPauseButton from '../FullDisplaySongCard/components/PlayPauseButton/PlayPauseButton';
import { useDownloadSong } from '../../hooks';
import {
  LARGE_DESKTOP_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { monoFontCss } from '../../../styles/typography/typography';
import { buttonResetCss, interactiveTextButtonCss } from '../../../components/Button/Button';
import { darkColor } from '../../../styles/config/colors';
import BookmarkButton from '../FullSongCard/components/SongActions/components/BookmarkButton/BookmarkButton';
import { USER_PERMISSIONS } from '../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useFreeTierRestrictionToast } from '../../../toasts/hooks';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadedSongsContext } from '../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { useDownloadContext } from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import DisplayAudioBars from '../FullDisplaySongCard/components/DisplayAudioBars/DisplayAudioBars';
import { useUserHiddenTracks } from '../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useBrowseManagerContext } from '../GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import {
  songPositionAtom,
  songPlayingSelector,
  playbackListSlugAtom,
  songProgressAtom,
  globalPlayingAtom,
} from '../../../store/globalPlayer';
import {
  conversionSourceAtom,
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../store/mixpanelAnalytics';
import { useNavigate } from '../../../routing/hooks';
import { ROUTES } from '../../../routing/routes';
import ArtistName from '../FullDisplaySongCard/components/ArtistName/ArtistName';
import ShareButton from '../FullSongCard/components/SongActions/components/ShareButton/ShareButton';
import ViewPackButton from '../FullSongCard/components/SongActions/components/ViewPackButton/ViewPackButton';
import SimilarSongsButton from '../FullSongCard/components/SongActions/components/SimilarSongsButton/SimilarSongsButton';
import HideSongButton from '../FullSongCard/components/SongActions/components/HideSongButton/HideSongButton';
import { useHideTrack, useUnhideTrack } from '../../../api/firebase/user/hiddenTracks';
import SearchLink from '../../../components/Link/components/SearchLink';
import { formatSeconds } from '../../../utils/time';
import DownloadMIDIButton from '../FullSongCard/components/SongActions/components/DownloadMIDIButton/DownloadMIDIButton';

const Container = styled('div')`
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #dcdad8;

  &:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const LeftWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${MEDIA_BREAKPOINTS.max1180} {
    width: 270px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    width: 650px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    width: 600px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 500px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 1;
    flex: 1;
    margin-right: 5px;
  }
`;

const MiddleWrapper = styled('div')`
  margin-right: 25px;
  margin-left: 25px;
  height: 60px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    width: 40%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px 20px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 4;
    width: 100%;
    margin: 0;
    height: 20px;
    margin-top: 10px;
  }
`;

const PlayWrapper = styled('div')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    margin: 0 20px;
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
  }
`;

const SongSubtitle = styled('button')`
  ${buttonResetCss};
  ${monoFontCss};
  ${interactiveTextButtonCss};
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 2px;
  color: ${transparentize(0.4, darkColor)};
  text-transform: uppercase;

  &.nonInteractive {
    text-decoration: none;
    cursor: default;
  }
`;

const Info = styled('div')`
  width: 200px;

  ${MEDIA_BREAKPOINTS.max1180} {
    width: 120px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    width: 500px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    width: 450px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 350px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 180px;
  }
`;

const ControlsWrapper = styled('div')`
  display: flex;
  margin-left: auto;

  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
    justify-content: flex-end;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 3;
  }
`;

const DetailsWrapper = styled('div')`
  display: flex;

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
    padding-top: 15px;
  }
`;

const DetailsContainer = styled('div')``;

const DetailValue = styled('div')`
  padding-right: 15px;
`;

const Control = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const SongTypeWrapper = styled('div')`
  margin-left: -3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const dimmedSectionCss = css`
  opacity: 0.5;
`;

const disabledSongName = css`
  &:hover {
    text-decoration: none;
    cursor: default;
  }
`;

const similarSongsButtonCss = css`
  margin-left: -10px !important;
  margin-right: 5px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
    display: block;
    margin-left: 5px !important;
    margin-right: unset;
  }
`;

const detailsContainer1Css = css`
  margin: auto 5px;
  min-width: 65px;

  ${MEDIA_BREAKPOINTS.max1180} {
    font-size: small;
    min-width: 55px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    font-size: unset;
    min-width: 65px;
  }
`;

const detailsContainer2Css = css`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: hidden;
  height: 75px;
  align-items: center;

  ${MEDIA_BREAKPOINTS.max1260} {
    width: 90px;
  }

  ${MEDIA_BREAKPOINTS.max1180} {
    width: 80px;
    font-size: small;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 100%;
    height: 100%;
    font-size: unset;
  }
`;

const detailTextCss = css`
  ${MEDIA_BREAKPOINTS.max1440} {
    display: none;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: inline;
  }
`;

const controlButtonCss = css`
  ${MEDIA_BREAKPOINTS.max1440} {
    display: none;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: block;
  }
`;

const hiddenDownloadMidi = css`
  display: none;
`;

type Props = {
  song: AlgoliaSongMdl,
  onExpand: () => void,
  songPosition: number,
  neverExpanded?: boolean,
  fullSongTitle?: string,
  hidePackActions?: boolean,
  checkSongPosition: boolean,
};

const CondensedDisplaySongCard = ({
  song,
  songPosition,
  onExpand,
  neverExpanded = false,
  fullSongTitle = '',
  hidePackActions = false,
  checkSongPosition,
}: Props) => {
  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(song);
  const [handleHideTrack, hideTrackBusy] = useHideTrack(song);
  const [handleUnhideTrack, unhideTrackBusy] = useUnhideTrack(song);

  const downloadedContext = useDownloadedSongsContext() ? useDownloadedSongsContext() : {};
  const {
    location = '',
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();
  const globalSongPosition = useRecoilValue(songPositionAtom);
  const songPlaying = useRecoilValue(songPlayingSelector);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const songProgress = useRecoilValue(songProgressAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const { songListSlug } = useBrowseManagerContext();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const showSignUp = useShowSignUp();
  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };

  const navigate = useNavigate();
  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const freeTierRestrictionToast = useFreeTierRestrictionToast('parts', toastUtilities);

  const handleDisabledClick = () => {
    freeTierRestrictionToast();
  };
  const previousDownloadSongs = get(downloadedContext, 'downloadedSongIDs', []);
  const previousDownloadMidis = get(downloadedContext, 'downloadedMidis', []);
  const previouslyDownloadedSong = previousDownloadSongs.includes(getSongID(song));
  const previouslyDownloadedMidi = previousDownloadMidis.includes(getSongID(song));

  const userHiddenSongs = useUserHiddenTracks();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const isQueuePlaying = songListSlug === playbackListSlug;
  const playingSongId = songPlaying ? songPlaying.objectID : '';
  const isGlobalPlayerSong = song.objectID === playingSongId;
  const isSongPositionPlaying = checkSongPosition ? songPosition === globalSongPosition : true;

  const playing = isGlobalPlayerSong && globalPlaying && isQueuePlaying && isSongPositionPlaying;

  const songArtwork = getSongArtwork(song);
  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  const disableMidi = song.audio.downloadMidi === undefined;

  const downloadButton = (() => {
    if (USER_PERMISSIONS[userRole].canDownloadAllTracks) {
      return (
        <DownloadButton
          condensed
          onClick={() => downloadSong()}
          previouslyDownloaded={previouslyDownloadedSong}
          busy={downloadBusy}
        />
      );
    }
    return <DisabledDownloadButton onClick={handleDisabledClick} />;
  })();

  const midiDownloadButton = (() => {
    return (
      <DownloadMIDIButton
        condensed
        onClick={() => downloadSong('MIDI')}
        previouslyDownloaded={previouslyDownloadedMidi}
        busy={downloadMidiBusy}
      />
    );
  })();

  const shareSlug = getSongShareSlug(song);
  const isPackPage = location === 'pack';

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    if (isPackPage) return;
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));
  };

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  const subTitle = isSongStem(song) ? getSongMixType(song).replace('and', '&') : '';
  const songTime = playing ? `${formatSeconds(songProgress)}` : `${getSongDurationFormatted(song)}`;
  const bpm = getSongBPM(song);
  const genre = getSongGenres(song).join(', ');
  const videoTheme = getSongVideoTheme(song).join(', ');
  const energy = getSongEnergy(song).join(', ');
  const mood = getSongMood(song).join(', ');
  const editStyle = getSongEditStyle(song).join(', ');

  return (
    <Container>
      <LeftWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <PlayWrapper>
          <PlayPauseButton
            songPosition={songPosition}
            checkSongPosition={checkSongPosition}
            playBackground={songArtwork}
          />
        </PlayWrapper>
        <Info>
          <SongTypeWrapper
            onClick={handleNavigatePack}
            className={cx({ [disabledSongName]: isPackPage })}
          >
            {fullSongTitle || getSongName(song)}
          </SongTypeWrapper>
          <SongSubtitle className={cx({ nonInteractive: true })}>
            <ArtistName name={artistName} slug={artistslug} />
            {subTitle}
          </SongSubtitle>
        </Info>
      </LeftWrapper>
      <MiddleWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <DisplayAudioBars
          condensed
          songPosition={songPosition}
          checkSongPosition={checkSongPosition}
        />
      </MiddleWrapper>
      <Control className={cx(similarSongsButtonCss, { [dimmedSectionCss]: songIsHidden })}>
        <SimilarSongsButton />
      </Control>
      <DetailsWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <DetailsContainer className={cx(detailsContainer1Css)}>
          <p>{songTime}</p>
          <p>{bpm}BPM</p>
        </DetailsContainer>
        <DetailsContainer className={cx(detailsContainer2Css)}>
          <DetailValue>
            <SearchLink queryText={genre} />
          </DetailValue>
          <DetailValue>
            <SearchLink queryText={mood} />
          </DetailValue>
          <DetailValue className={cx(detailTextCss)}>
            <SearchLink queryText={videoTheme} />
          </DetailValue>
          <DetailValue className={cx(detailTextCss)}>
            <SearchLink queryText={editStyle} />
          </DetailValue>
          <DetailValue className={cx(detailTextCss)}>
            <SearchLink queryText={energy} />
          </DetailValue>
        </DetailsContainer>
      </DetailsWrapper>
      <ControlsWrapper
        className={cx({
          [dimmedSectionCss]: songIsHidden,
        })}
      >
        <Control className={cx({ [hiddenDownloadMidi]: disableMidi })}>
          {midiDownloadButton}
        </Control>
        <Control>
          <BookmarkButton condensed />
        </Control>
        <Control>{downloadButton}</Control>
        {!hidePackActions && (
          <Control className={cx(controlButtonCss)}>
            <ViewPackButton />
          </Control>
        )}
        <Control className={cx(controlButtonCss)}>
          <ShareButton />
        </Control>
        <Control className={cx(controlButtonCss)}>
          <HideSongButton
            isHidden={songIsHidden}
            onClick={handleToggleHidden}
            busy={hideTrackBusy || unhideTrackBusy}
          />
        </Control>
      </ControlsWrapper>
    </Container>
  );
};

export default CondensedDisplaySongCard;
