// @flow
import React, { useState } from 'react';
import ShareIcon from '../../../../../../../../assets/inline-assets/share-icon.svg';
import { getTrackShareAction } from '../../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import ShareSongModal from '../../../../ShareSongModal/ShareSongModal';
import { isMobile } from '../../../../../../../../utils/device';
import { useDisplaySongShareSongShareURL } from '../../../../DisplaySongShareContext/DisplaySongShareContext';
import InlineButton from './InlineButton';

type Props = {
  hideTooltip: () => void,
};

const ShareInlineButton = ({ hideTooltip }: Props) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const shareURL = useDisplaySongShareSongShareURL();
  const trackCopy = useTrackCopy();

  const handleOpen = () => {
    if (isMobile() && navigator.share) {
      navigator
        .share({
          title: 'evokemusic.ai',
          url: shareURL,
        })
        .then(() => {
          console.log('shared...');
        })
        .catch(error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when sharing via navigator.share');
          Sentry.captureException(error);
          console.error(error);
        });
    } else {
      setShareModalOpen(true);
    }
  };
  const handleClose = () => {
    hideTooltip();
    setShareModalOpen(false);
  };

  return (
    <>
      <InlineButton
        label={getTrackShareAction(trackCopy)}
        icon={<ShareIcon />}
        action={handleOpen}
      />
      {shareModalOpen && <ShareSongModal onClose={handleClose} />}
    </>
  );
};

export default ShareInlineButton;
