import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { transparentize } from 'polished';
import AddFilterIcon from '../../../../../../../../../../../assets/inline-assets/include-filter.svg';
import RemoveFilterIcon from '../../../../../../../../../../../assets/inline-assets/exclude-filter.svg';
import {
  bodyTextCss,
  boldFontWeightCss,
} from '../../../../../../../../../../../styles/typography/typography';
import { lightColor } from '../../../../../../../../../../../styles/config/colors';
import { useResultsManagerContext } from '../../../../../ResultsManager/ResultsManager';
import { useResultsPage } from '../../../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getResultsPageIncludeFilterLabel,
  getResultsPageExcludeFilterLabel,
} from '../../../../../../../../../../../cms/resultsPage';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../../../styles/responsive';
import { getFormattedCount } from '../../../../../../../../../../../user/subscriptions/components/AddedSubscription/components/YouTubeChannelSearch/YouTubeChannelSearch';
import { useFilterChangeToast } from '../../../../../../../../../../../toasts/hooks';
import { isMobile } from '../../../../../../../../../../../utils/device';

type Props = {
  filterKey: string,
  filterLabel: string,
  filterCount: number,
  isIncluded: boolean,
  isExcluded: boolean,
  hideCount?: boolean,
  isSuggestion?: boolean,
  searchTerm?: string,
};

export const FilterElement = styled('li')`
  height: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${bodyTextCss}
  list-style: none;
  margin: 5px 0px;
  padding: 0px 5px;
  border-radius: 2px;

  &:hover {
    background-color: ${transparentize(0.95, lightColor)};
    & div button {
      visibility: visible;
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 29px;
    margin: 10px 0px;
    padding: 2px 5px 2px 10px;
  }
`;

export const FilterLabel = styled('span')`
  text-transform: capitalize;
  padding-left: ${props => (props.isSubGroupFilter ? '10px' : '0px')};
`;

export const activeFilterContainer = css`
  ${SMALL_DESKTOP_BREAKPOINT} {
    background-color: ${transparentize(0.95, lightColor)};
  }
`;

export const activeFilterLabel = css`
  ${boldFontWeightCss};
`;

export const zeroCountFilter = css`
  opacity: 0.5;
`;

export const FilterButtonWrapper = styled('div')`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddFilterButton = styled('button')`
  height: 17px;
  width: 17px;
  padding: 0;
  background-color: ${transparentize(1, lightColor)};
  border-radius: 1px;
  cursor: pointer;
  border: none;
  visibility: hidden;
  border: 1px solid transparent;

  ${SMALL_DESKTOP_BREAKPOINT} {
    visibility: visible;
    border: 1px solid ${transparentize(0.9, lightColor)};
  }

  span {
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: black;
    color: ${lightColor};
    height: 25px;
    width: 90px;
    border-radius: 3px;
    border-none;
  }

  &:hover {
    border: 1px solid ${transparentize(0.9, lightColor)};

    & svg {
      position: relative;
      right: 1px;
      top: -1px;
    }

    & span {
      display: flex;
      bottom: 50px;
      right: 25px;

      ${SMALL_DESKTOP_BREAKPOINT} {
        display: none;
      }

      &.includeFilter {
        right: 25px;
      }

      &.excludeFilter {
        right: 50px;
      }
    }
  }

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 1px;
    top: -1px;

    path, rect {
      fill: ${lightColor};
    }
  }

  &.excludeFilter {
    margin-left: 4px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-right: 5px;
    }
  }

  &.includeFilter {
    margin-right: 4px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-right: 30px;
    }
  }
`;

export const hideFilterButton = css`
  visibility: hidden;
`;

export const activeFilterButton = css`
  background-color: ${lightColor};
  visibility: visible;

  svg {
    position: relative;
    right: 1px;
    top: -1px;

    path,
    rect {
      fill: #25272c;
    }
  }
`;

const FilterMenuOption = ({
  filterKey,
  filterLabel,
  filterCount,
  isIncluded,
  isExcluded,
  hideCount = false,
  isSuggestion = false,
  searchTerm = '',
}: Props) => {
  const resultsPage = useResultsPage();
  const {
    handleAddSongsFilter,
    handleRemoveSongsFilter,
    swapSongsFilter,
  } = useResultsManagerContext();
  const includeFilterChangeToast = useFilterChangeToast(filterKey, filterLabel, 'included');
  const excludeFilterChangeToast = useFilterChangeToast(filterKey, filterLabel, 'excluded');
  const removedFilterChangeToast = useFilterChangeToast(filterKey, filterLabel, 'removed');
  const count = !hideCount ? `(${getFormattedCount(filterCount)})` : '';

  const handleAddInclusiveFilter = () => {
    if (filterCount === 0 && !isExcluded) {
      return;
    }
    if (isIncluded && !isExcluded) {
      handleRemoveSongsFilter(filterKey, filterLabel, 'included');
      if (isMobile()) {
        removedFilterChangeToast();
      }
    } else if (isExcluded && !isIncluded) {
      swapSongsFilter(filterKey, filterLabel, 'excluded', 'included', isSuggestion, searchTerm);
      if (isMobile()) {
        includeFilterChangeToast();
      }
    } else {
      handleAddSongsFilter(filterKey, filterLabel, 'included', isSuggestion, searchTerm);
      if (isMobile()) {
        includeFilterChangeToast();
      }
    }
  };

  const handleAddExclusiveFilter = () => {
    if (filterCount === 0 && !isExcluded) {
      return;
    }
    if (isExcluded && !isIncluded) {
      handleRemoveSongsFilter(filterKey, filterLabel, 'excluded');
      if (isMobile()) {
        removedFilterChangeToast();
      }
    } else if (isIncluded && !isExcluded) {
      swapSongsFilter(filterKey, filterLabel, 'included', 'excluded', isSuggestion, searchTerm);
      if (isMobile()) {
        excludeFilterChangeToast();
      }
    } else {
      handleAddSongsFilter(filterKey, filterLabel, 'excluded', isSuggestion, searchTerm);
      if (isMobile()) {
        excludeFilterChangeToast();
      }
    }
  };

  return (
    <FilterElement
      className={cx({
        [activeFilterContainer]: isIncluded || isExcluded,
      })}
    >
      <FilterLabel
        className={cx({
          [activeFilterLabel]: isIncluded || isExcluded,
          [zeroCountFilter]: filterCount === 0 && !hideCount,
        })}
      >{`${filterLabel} ${count}`}</FilterLabel>
      {(filterCount > 0 || isExcluded) && (
        <FilterButtonWrapper>
          <AddFilterButton
            className={cx('includeFilter', {
              [hideFilterButton]: isExcluded,
              [activeFilterButton]: isIncluded,
            })}
            onClick={handleAddInclusiveFilter}
          >
            <AddFilterIcon />
            <span className="includeFilter">{getResultsPageIncludeFilterLabel(resultsPage)}</span>
          </AddFilterButton>
          <AddFilterButton
            className={cx('excludeFilter', {
              [hideFilterButton]: isIncluded,
              [activeFilterButton]: isExcluded,
            })}
            onClick={handleAddExclusiveFilter}
          >
            <RemoveFilterIcon />
            <span className="excludeFilter">{getResultsPageExcludeFilterLabel(resultsPage)}</span>
          </AddFilterButton>
        </FilterButtonWrapper>
      )}
    </FilterElement>
  );
};

export default FilterMenuOption;
