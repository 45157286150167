// @flow
import React from 'react';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import PageComponent from '../../components/PageComponent/PageComponent';

const Content = () => <LayoutWrapper></LayoutWrapper>;

type Props = {
  path: string,
};

// ヘッダー
const BrowseScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default BrowseScreen;
