// @flow
import * as Yup from 'yup';

export const useSignUpEnterpriseFormSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(2)
      .required(),
    email: Yup.string()
      .email()
      .required(),
    firstName: Yup.string()
      .min(1)
      .required(),
    lastName: Yup.string()
      .min(1)
      .required(),
    terms: Yup.bool().oneOf([true]),
  });
};
