// @flow
import React from 'react';
import {
  getMusicPageFeaturedGenres,
  getMusicPageFeaturedGenresSectionTitle,
} from '../../../../../../cms/musicPage';
import { useMusicPage } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import FeaturedTagSection from './FeaturedTagSection';

type Props = {
  viewAll?: boolean,
  viewAllLink?: boolean,
  oversizedTile?: boolean,
  page: string,
  rows: number,
};

const FeaturedGenres = ({
  viewAll = false,
  viewAllLink = false,
  oversizedTile = false,
  page,
  rows,
}: Props) => {
  const musicPage = useMusicPage();
  const sectionTitle = getMusicPageFeaturedGenresSectionTitle(musicPage);
  const tags = getMusicPageFeaturedGenres(musicPage);
  return (
    <FeaturedTagSection
      tagCategory="genre"
      title={sectionTitle}
      tags={tags}
      viewAll={viewAll}
      viewAllLink={viewAllLink}
      oversizedTile={oversizedTile}
      page={page}
      rows={rows}
    />
  );
};

export default FeaturedGenres;
