// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type EnterpriseContactModalGraphMdl = {
  data: {
    heading: {
      text: string,
    },
    description: {
      html: string,
    },
  },
};

export const getEnterpriseContactModalHeading = (copy: EnterpriseContactModalGraphMdl): string => {
  return get(copy, 'data.heading.text', '') || '';
};

export const getEnterpriseContactModalDescription = (
  copy: EnterpriseContactModalGraphMdl
): string => {
  return get(copy, 'data.description.html', '') || '';
};

export const query = graphql`
  fragment PrismicEnterpriseContactModal on PrismicEnterpriseContactModal {
    data {
      heading {
        text
      }
      description {
        html
      }
    }
  }
`;
