// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { USER_ROLES } from '../user/subscriptions/data';

export type ProfileMenuGraphMdl = {
  data: {
    profile: {
      text: string,
    },
    subscriptions: {
      text: string,
    },
    billing: {
      text: string,
    },
    ad_revenue: {
      text: string,
    },
    download_history: {
      text: string,
    },
    email_settings: {
      text: string,
    },
    guest_tier: {
      text: string,
    },
    creator_tier: {
      text: string,
    },
    creator_pro_tier: {
      text: string,
    },
    business_tier: {
      text: string,
    },
    enterprise_tier: {
      text: string,
    },
  },
};

export const getProfileMenuProfile = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.profile.text', '') || '';
};

export const getProfileMenuSubscriptions = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.subscriptions.text', '') || '';
};

export const getProfileMenuBilling = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.billing.text', '') || '';
};

export const getProfileMenuAdRevenue = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.ad_revenue.text', '') || '';
};

export const getProfileMenuDownloadHistory = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.download_history.text', '') || '';
};

export const getProfileMenuEmailSettings = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.email_settings.text', '') || '';
};

export const getProfileMenuGuestTier = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.guest_tier.text', '') || '';
};

export const getProfileMenuCreatorTier = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.creator_tier.text', '') || '';
};

export const getProfileMenuCreatorProTier = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.creator_pro_tier.text', '') || '';
};

export const getProfileMenuBusinessTier = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.business_tier.text', '') || '';
};

export const getProfileMenuEnterpriseTier = (copy: ProfileMenuGraphMdl): string => {
  return get(copy, 'data.enterprise_tier.text', '') || '';
};

export const getProfileMenuTierLabel = (userRole: string, copy: ProfileMenuGraphMdl): string => {
  if (userRole === USER_ROLES.creator) {
    return getProfileMenuCreatorTier(copy);
  }
  if (userRole === USER_ROLES.creatorPro) {
    return getProfileMenuCreatorProTier(copy);
  }
  if (userRole === USER_ROLES.business) {
    return getProfileMenuBusinessTier(copy);
  }
  if (userRole === USER_ROLES.enterprise) {
    return getProfileMenuEnterpriseTier(copy);
  }
  return getProfileMenuGuestTier(copy);
};

export const query = graphql`
  fragment ProfileMenu on PrismicMenu {
    data {
      profile {
        text
      }
      subscriptions {
        text
      }
      billing {
        text
      }
      ad_revenue {
        text
      }
      download_history {
        text
      }
      email_settings {
        text
      }
      guest_tier {
        text
      }
      creator_tier {
        text
      }
      creator_pro_tier {
        text
      }
      business_tier {
        text
      }
      enterprise_tier {
        text
      }
    }
  }
`;
