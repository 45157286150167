// @flow
import React from 'react';
import GenericError from './components/GenericError/GenericError';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';

type Props = {
  children: any,
};

const ErrorView = ({ children }: Props) => (
  <LayoutWrapper>{children || <GenericError />}</LayoutWrapper>
);

export default ErrorView;
