// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import {
  smallMediumTextCss,
  smallPlainTextMonoCss,
} from '../../../../../../styles/typography/typography';
import { lightColor, darkColor } from '../../../../../../styles/config/colors';
import { CompactButton } from '../../../../../Button/Button';
import {
  useTrialCounterMenu,
  useSubscriptionsPage,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getTrialCounterMenuTitle,
  getTrialCounterRemainingLessThanDayInfo,
  getTrialCounterRemainingDayInfo,
  getTrialCounterRemainingDaysInfo,
  getTrialCounterAllowlistingDateNotice,
  getTrialCounterTrialCancelledDateNotice,
  getTrialCounterEndTrialEarlyPrompt,
} from '../../../../../../cms/trialCounterMenu';
import { getSubscriptionsUndoCancellationButton } from '../../../../../../cms/subscriptionsPage';
import {
  getSubscriptionChannelId,
  getSubscriptionPlanKey,
  getSeatInterval,
  getSubscriptionTrialValidUntilDate,
  getSubscriptionStatus,
} from '../../../../../../api/firebase/user/subscriptions';
import {
  useSubscriptionsContext,
  useFreeTrialSubscriptions,
} from '../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { parseAllowlistingDate } from '../../../TrialCounterMenu/TrialCounterMenu';
import { getDaysUntilDate } from '../../../../../../utils/date';
import { SUBSCRIPTION_STATUSES } from '../../../../../../api/firebase/user/user';
import SpinnerIcon from '../../../../../SpinnerIcon/SpinnerIcon';

const countLimitReached = 'countLimitReached';

const Container = styled('section')`
  background: ${lightColor}
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 13px;
`;

const MenuTitle = styled('h6')`
  ${smallMediumTextCss};
  margin: 0px 40px 3px 40px;
  padding-top: 12px;
  border-top: 1px solid ${transparentize(0.9, darkColor)};
`;

const RemainingDaysInfo = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
  padding: 0px 40px 0px 40px;
`;

const CountInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const CountInformation = styled('p')`
  width: 200px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-top: 5px;

  & span {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CountNumberWrapper = styled('div')`
  width: 50px;
  height: 50px;
  background-color: ${transparentize(0.9, darkColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 2px;
  margin-left: 15px;
`;

const CountNumber = styled('h6')`
  ${smallPlainTextMonoCss};
  font-size: 28px;
  font-weight: 300;

  &.${countLimitReached} {
    opacity: 0.3;
  }
`;

const UpgradePromptWrapper = styled('div')`
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const UpgradePromptButtonWrapper = styled('div')`
  padding: 15px 40px 20px 40px;
  display: flex;
  justify-content: center;

  & a {
    width: 100%;
  }
`;
const UpgradePromptButton = styled(CompactButton)`
  background-color: #4d4d4d;
  width: 100%;
`;

const spinnerClass = css`
  position: static;
`;

type Props = {
  onClose: () => void,
};

const MobileMenuTrialCounter = ({ onClose }: Props) => {
  const [busy, setBusy] = useState(false);
  const { upgradeSubscription, removeScheduledChanges } = useSubscriptionsContext();
  const trialCounterMenuCopy = useTrialCounterMenu();
  const subscriptionsPage = useSubscriptionsPage();
  const freeTrialSubscription = useFreeTrialSubscriptions()[0];

  const remainingTrialDayCount = getDaysUntilDate(
    get(freeTrialSubscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000
  );

  const handleClick = () => {
    if (busy) return;
    setBusy(true);

    const subStatus = getSubscriptionStatus(freeTrialSubscription);
    // If the trial has been scheduled for cancellation, the button instead will be set to undo the cancellation
    if (subStatus === SUBSCRIPTION_STATUSES.trial_cancelled) {
      removeScheduledChanges(freeTrialSubscription.key).finally(() => {
        onClose();
        setBusy(false);
      });
      return;
    }

    upgradeSubscription(
      getSubscriptionChannelId(freeTrialSubscription),
      getSubscriptionPlanKey(freeTrialSubscription),
      getSeatInterval(freeTrialSubscription)
    )
      .then(() => {
        console.log('Free Trial Upgrade Processed');
        setBusy(false);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage(
          'Something went wrong when converting subscription from free trial to paid subscripton'
        );
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const remainingDaysMessage = (() => {
    if (remainingTrialDayCount <= 0) {
      return parse(getTrialCounterRemainingLessThanDayInfo(trialCounterMenuCopy));
    }

    if (remainingTrialDayCount === 1) {
      return parse(getTrialCounterRemainingDayInfo(trialCounterMenuCopy));
    }

    return parse(
      getTrialCounterRemainingDaysInfo(trialCounterMenuCopy).replace(
        '$REMAINING_DAYS_ON_TRIAL$',
        remainingTrialDayCount.toString()
      )
    );
  })();

  return (
    <Container>
      <MenuTitle>{getTrialCounterMenuTitle(trialCounterMenuCopy)}</MenuTitle>
      <RemainingDaysInfo>
        <CountInfoWrapper>
          <CountInformation>{remainingDaysMessage}</CountInformation>
          <CountInformation>
            {getSubscriptionStatus(freeTrialSubscription) === SUBSCRIPTION_STATUSES.trial
              ? parseAllowlistingDate(
                  getTrialCounterAllowlistingDateNotice(trialCounterMenuCopy),
                  getSubscriptionTrialValidUntilDate(freeTrialSubscription)
                )
              : parseAllowlistingDate(
                  getTrialCounterTrialCancelledDateNotice(trialCounterMenuCopy),
                  getSubscriptionTrialValidUntilDate(freeTrialSubscription)
                )}
          </CountInformation>
        </CountInfoWrapper>
        <CountNumberWrapper>
          <CountNumber
            className={cx({
              [countLimitReached]: false,
            })}
          >
            {remainingTrialDayCount >= 0 ? remainingTrialDayCount : 0}
          </CountNumber>
        </CountNumberWrapper>
      </RemainingDaysInfo>
      <UpgradePromptWrapper>
        <UpgradePromptButtonWrapper>
          <UpgradePromptButton onClick={handleClick}>
            {busy && <SpinnerIcon inverse={false} containerClassName={spinnerClass} />}
            {!busy &&
              getSubscriptionStatus(freeTrialSubscription) === SUBSCRIPTION_STATUSES.trial &&
              getTrialCounterEndTrialEarlyPrompt(trialCounterMenuCopy)}
            {!busy &&
              getSubscriptionStatus(freeTrialSubscription) !== SUBSCRIPTION_STATUSES.trial &&
              getSubscriptionsUndoCancellationButton(subscriptionsPage)}
          </UpgradePromptButton>
        </UpgradePromptButtonWrapper>
      </UpgradePromptWrapper>
    </Container>
  );
};

export default MobileMenuTrialCounter;
