// @flow
import React from 'react';
import styled from 'react-emotion';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../../../../../locales';
import {
  algoliaClient,
  getAlgoliaKeywordsIndexName,
} from '../../../../../../../api/algolia/client';
import KeywordAutocomplete from '../KeywordAutocomplete/KeywordAutocomplete';
import { useKeywordsManagerContext } from '../KeywordsManager/KeywordsManager';
import {
  isAlgoliaAnalyticsEnabled,
  isSearchIndexSwitcherEnabled,
} from '../../../../../../../utils/env';

const Container = styled('div')`
  width: 100%;
  max-width: 740px;
  margin: 8px auto 0 auto;
  position: relative;
`;

type Props = {
  placeholder: string,
  handleSearch: () => void,
};

const KeywordsSearchInput = ({ placeholder, handleSearch }: Props) => {
  const locale = useLocale();
  const { addKeyword, addMagicKeywords } = useKeywordsManagerContext();

  const indexName = getAlgoliaKeywordsIndexName(locale);

  return (
    <Container>
      <InstantSearch searchClient={algoliaClient} indexName={indexName} data-cy="Test">
        <Configure hitsPerPage={5} analytics={isAlgoliaAnalyticsEnabled()} />
        <KeywordAutocomplete
          addKeyword={addKeyword}
          addMagicKeywords={addMagicKeywords}
          placeholder={placeholder}
          handleSearch={handleSearch}
        />
      </InstantSearch>
    </Container>
  );
};

export default KeywordsSearchInput;
