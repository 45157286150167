// @flow
import React from 'react';
import styled from 'react-emotion';
import { useMiscCopy } from '../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { SmallMonoTextButton } from '../Button/Button';
import ExpandableGroupedTagLayout from '../../layouts/components/ExpandableGroupedTagLayout/ExpandableGroupedTagLayout';
import { getMiscCopyClearButton, getMiscTagFiltersTitle } from '../../cms/miscCopy';
import TagButton from '../../layouts/components/ExpandableGroupedTagLayout/components/TagButton';
import type { SongTagFiltersMdl } from '../../utils/tagFilters';

const StyledClearWrapper = styled('div')`
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: #dad8d3;
  padding: 0px 8px;
`;

const StyledClearButton = styled(SmallMonoTextButton)`
  height: 100%;
`;

type Props = {
  slug: string,
  facets: SongTagFiltersMdl,
  handleAddFilter: (filterKey: string, filterValue: string) => void,
  handleRemoveFilter: (filterKey: string, filterValue: string) => void,
  handleClearFilters: () => void,
};

const TagFiltersList = ({
  slug,
  facets,
  handleAddFilter,
  handleRemoveFilter,
  handleClearFilters,
}: Props) => {
  const miscCopy = useMiscCopy();
  const headerActiveTags = facets
    .map(facet => {
      const activeFilters = facet.filters.filter(af => af.active);
      const hasSelectedFacets = activeFilters.length > 0;

      if (hasSelectedFacets) {
        return (
          <React.Fragment key={facet.facetKey}>
            {activeFilters.map(filter => {
              return (
                <TagButton
                  key={filter.label}
                  facetKey={facet.facetKey}
                  label={filter.label}
                  count={filter.count}
                  handleFilterToggle={() => handleRemoveFilter(facet.facetKey, filter.label)}
                  isSelected
                />
              );
            })}
          </React.Fragment>
        );
      }
      return null;
    })
    .filter(validTag => !!validTag);

  const headerActiveButton = (
    <StyledClearWrapper>
      <StyledClearButton type="button" onClick={handleClearFilters}>
        {getMiscCopyClearButton(miscCopy)}
      </StyledClearButton>
    </StyledClearWrapper>
  );

  const handleFilterToggle = (isSelected: boolean, filterKey: string, filterValue: string) => {
    if (isSelected) {
      handleRemoveFilter(filterKey, filterValue);
    } else {
      handleAddFilter(filterKey, filterValue);
    }
  };

  return (
    <ExpandableGroupedTagLayout
      slug={slug}
      facetsMap={facets}
      handleFilterToggle={handleFilterToggle}
      headerTitle={getMiscTagFiltersTitle(miscCopy)}
      headerActiveTags={headerActiveTags}
      headerActiveButton={headerActiveButton}
    />
  );
};

export default TagFiltersList;
