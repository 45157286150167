// @flow
import styled from 'react-emotion';
import { css } from 'emotion';
import {
  bodyFontCss,
  largeFontSizeCss,
  lightFontWeightCss,
  mediumHeadingCss,
  monoFontCss,
  monoNavCss,
} from '../../typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../../responsive';
import { lightColor } from '../../../config/colors';

const responsiveCss = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 24px;
    line-height: 35px;
  }
`;

export const largeHeadingCss = props => css`
  ${props.mono ? monoFontCss : bodyFontCss};
  ${lightFontWeightCss};
  ${lightColor};
  ${largeFontSizeCss};
  ${props.responsive ? responsiveCss : ''};
`;

export const LargeHeading = styled('h2')`
  ${largeHeadingCss};
`;

export const MediumHeading = styled('h3')`
  ${mediumHeadingCss};
`;

export const smallHeadingCss = css`
  ${monoNavCss};
  text-transform: uppercase;
`;

export const SmallHeading = styled('h4')`
  ${smallHeadingCss};
`;
