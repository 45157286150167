// @flow
import React from 'react';
import ResultsManager from './components/ResultsManager/ResultsManager';
import SearchKeywords from './components/SearchKeywords/SearchKeywords';
import SearchResults from './components/SearchResults/SearchResults';
import SearchFilters from './components/SearchFilters/SearchFilters';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';

type Props = {
  path: string,
};

// 楽曲検索ページ
const SearchView = ({ path }: Props) => {
  return (
    <ResultsManager>
      <BrowseLayout nav={<SearchFilters />} path={path}>
        <SearchKeywords />
        <SearchResults />
      </BrowseLayout>
    </ResultsManager>
  );
};

export default SearchView;
