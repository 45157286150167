// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import parse from 'html-react-parser';
import Modal, { ModalContainer, wideModalCss, wideModalOverlayCss } from '../Modal/Modal';
import CloseIcon from '../../../assets/inline-assets/close.svg';
import { buttonResetCss } from '../../../components/Button/Button';
import type { ModalProps } from '../Modal/Modal';
import { LargeHeading } from '../../../styles/typography/components/Heading/Heading';
import GenericCopy from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { spacing } from '../../../styles/config/spacing';
import {
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  LANDSCAPE_DEVICE,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { isSmallDeviceSize } from '../../../utils/device';

const SplitContainter = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column;
    height: 80vh;
  }
`;

const ImageContainer = styled('div')`
  width: 50%;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 50%;
    width: 100%;
    background-position-y: 100%;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    background-size: 100%;
  }
`;

export const ModalCloseWrapper = styled('div')`
  position: absolute;
  top: 19px;
  right: 19px;
`;

export const ModalCloseButton = styled('button')`
  ${buttonResetCss};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
`;

const Body = styled('div')`
  width: 50%;
  padding: 50px;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 20px;
  }
`;

const ContentHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const HeaderOption = styled('div')`
  ${SMALL_DEVICE_BREAKPOINT} {
    position: relative;
    top: -3px;
  }
`;

export const contentHeadingClass = css`
  margin-top: -13px;

  ${SMALL_DEVICE_BREAKPOINT} {
    flex: 1;
    margin-top: -7px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const contentBodyClass = css`
  max-width: 400px;
  &:not(:first-child) {
    margin-top: ${spacing.medium}px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-top: ${spacing.small}px;
    }
  }
`;

const ContentFooter = styled('footer')`
  margin-top: ${spacing.largePlus}px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: ${spacing.medium}px;
  }
`;

const splitWideModalCss = css`
  ${wideModalCss};
  max-width: 780px;

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: none;

    ${LANDSCAPE_DEVICE} {
      max-width: 450px;
    }
  }
`;

type ContentProps = {
  footer?: any,
  heading: string,
  headerOption?: any,
  description?: string,
  children?: any,
};

export const SplitModalContentLayout = ({
  footer,
  heading,
  headerOption = null,
  description,
  children = null,
}: ContentProps) => {
  return (
    <div>
      <ContentHeader>
        <LargeHeading className={contentHeadingClass} responsive>
          {heading}
        </LargeHeading>
        {headerOption && <HeaderOption>{headerOption}</HeaderOption>}
      </ContentHeader>
      {description && <GenericCopy className={contentBodyClass}>{parse(description)}</GenericCopy>}
      {footer && <ContentFooter>{footer}</ContentFooter>}
      {children && children}
    </div>
  );
};

type Props = ModalProps & {
  children: any,
  imageSrc: string,
};

const SplitModalLayout = ({ children, imageSrc, onClose, ...props }: Props) => (
  <Modal
    className={splitWideModalCss}
    overlayClassName={wideModalOverlayCss}
    onClose={onClose}
    {...props}
  >
    <ModalContainer>
      <SplitContainter>
        <ImageContainer bgImage={imageSrc}>
          <>
            {isSmallDeviceSize() && (
              <ModalCloseWrapper data-cy="modalClose">
                <ModalCloseButton onClick={onClose}>
                  <CloseIcon />
                </ModalCloseButton>
              </ModalCloseWrapper>
            )}
          </>
        </ImageContainer>
        <Body>
          {!isSmallDeviceSize() && (
            <ModalCloseWrapper data-cy="modalClose">
              <ModalCloseButton onClick={onClose}>
                <CloseIcon />
              </ModalCloseButton>
            </ModalCloseWrapper>
          )}
          {children}
        </Body>
      </SplitContainter>
    </ModalContainer>
  </Modal>
);

export default SplitModalLayout;
