// @flow
import { isMobile as isDetectedMobile } from 'mobile-device-detect';
import { BREAKPOINTS } from '../styles/responsive';

export const isWindowAvailable = (): boolean => {
  return typeof window !== 'undefined';
};

export const isMobileDeviceSize = () => {
  if (isWindowAvailable()) {
    return window.innerWidth <= BREAKPOINTS.d600 - 1;
  }
  return false;
};

export const isSmallDeviceSize = () => {
  if (isWindowAvailable()) {
    return window.innerWidth <= BREAKPOINTS.d770 - 1;
  }
  return false;
};

export const is850MobileDeviceSize = () => {
  if (isWindowAvailable()) {
    return window.innerWidth <= BREAKPOINTS.d850 - 1;
  }

  return false;
};

export const isLargeMobileDeviceSize = () => {
  if (isWindowAvailable()) {
    return window.innerWidth <= BREAKPOINTS.d1000 - 1;
  }
  return false;
};

export const isSmallDesktopDevice = () => {
  if (isWindowAvailable()) {
    return window.innerWidth <= BREAKPOINTS.d1180 - 1;
  }
  return false;
};

export const isMobile = (): boolean => {
  return isDetectedMobile;
};
