// @flow
import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import {
  getSiteMetaDefaultPageTitle,
  getSiteMetaShareImage,
  getSiteMetaTags,
} from '../../cms/siteMeta';
import {
  isFacebookPixelEnabled,
  isSniplyPixelEnabled,
  isYahooAnalyticsEnabled,
} from '../../utils/env';
import { useSiteMeta } from '../../copy/components/CopyContextWrapper/CopyContextWrapper';

type Props = {};

const SiteMeta = () => {
  const siteMeta = useSiteMeta();
  const pageTitle = getSiteMetaDefaultPageTitle(siteMeta);
  const metaTags = getSiteMetaTags(siteMeta);
  const shareImage = getSiteMetaShareImage(siteMeta);

  const moEngageAppId = get(process.env, 'GATSBY_MOENGAGE_APP_ID', '');
  const moEngageLogs = get(process.env, 'GATSBY_MOENGAGE_DEBUG_LOGS', 0);

  useEffect(() => {
    if (isFacebookPixelEnabled()) {
      ReactPixel.init('779411805868414');
      ReactPixel.pageView();
    }
  }, []);

  const locale = (() => {
    if (typeof window !== 'undefined') {
      // return window.location.href.includes('/ja/') ? 'ja' : 'en';
      return window.location.href.includes('/ja/')
        ? 'ja'
        : window.location.href.includes('/cn/')
        ? 'cn'
        : 'en';
    }
    return 'en';
  })();

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: locale }}>
        <title>{pageTitle}</title>
        <meta property="og:image" content={shareImage} />
        <meta name="twitter:image" content={shareImage} />
        <meta
          name="google-site-verification"
          content="p7_rKtyfNkl9OiR0d7K9jcE2FUOXgt4LKjY4o3dJBvs"
        />
        <meta http-equiv="Content-Language" content={locale}></meta>
        {parse(metaTags)}
        <script type="text/javascript">
          {`
          (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')

          Moengage = moe({
            app_id: ${moEngageAppId},
            debug_logs: ${moEngageLogs}
          });
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-673687837"></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-673687837');`}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-109129753-3"></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-109129753-3', { 'optimize_id': 'GTM-W9D5C5W'});`}
        </script>
        {isFacebookPixelEnabled() ? (
          <noscript>
            {`<img
            height="1"
            width="1"
            src="https://www.facebook.com/tr?id=779411805868414&ev=PageView
        &noscript=1"
          />`}
          </noscript>
        ) : null}
        {isSniplyPixelEnabled() ? (
          <script
            type="text/javascript"
            src="https://gosniply.com/site/5ea6c22d03a7a85c6a98fc44.js"
          ></script>
        ) : null}
        {isSniplyPixelEnabled() && (
          <img
            src="https://gosniply.com/services/pixel/5defb38478522fc8a0ac5faa/"
            height="1"
            width="1"
            border="0"
            style={{ position: 'absolute', visibility: 'hidden', pointerEvents: 'none' }}
          />
        )}
        <script
          data-ad-client="ca-pub-2421336481301165"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
        {isYahooAnalyticsEnabled() ? (
          <script type="text/javascript">
            {`
              function loadYahoo () {
                var tagjs = document.createElement("script");
                var s = document.getElementsByTagName("script")[0];
                tagjs.async = true;
                tagjs.src = "//s.yjtag.jp/tag.js#site=Dzr7gkI";
                s.parentNode.insertBefore(tagjs, s);
              };
              loadYahoo();
            `}
          </script>
        ) : null}
        {isYahooAnalyticsEnabled() ? (
          <noscript>
            {` <iframe
              src="//b.yjtag.jp/iframe?c=Dzr7gkI"
              width="1"
              height="1"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>`}
          </noscript>
        ) : null}
      </Helmet>
    </React.Fragment>
  );
};

export default SiteMeta;
