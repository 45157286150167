// @flow
import React, { useEffect, useRef } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import Details from './components/Details/Details';
import { buttonResetCss, interactiveTextButtonCss } from '../../../components/Button/Button';
import { useTrackCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  hideOnSmallDeviceCss,
  SMALL_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
  showOnSmallDeviceCss,
} from '../../../styles/responsive';
import { darkColor, lightColor } from '../../../styles/config/colors';
import {
  getSongID,
  getSongArtist,
  getSongArtistSlug,
  getSongArtwork,
  getSongSubtitle,
  getSongName,
  getSongDurationFormatted,
  isSongSfx,
  getSongTypeTags,
} from '../../../api/algolia/song';
import { monoFontCss, monoNavCss } from '../../../styles/typography/typography';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { PLAYER_TYPES } from '../../data';
import { useUserHiddenTracks } from '../../../user/components/UserProfileWrapper/UserProfileWrapper';
import ArtistName from '../FullDisplaySongCard/components/ArtistName/ArtistName';
import BookmarkButton from './components/SongActions/components/BookmarkButton/BookmarkButton';
import SimilarSongsButton from './components/SongActions/components/SimilarSongsButton/SimilarSongsButton';
import ViewPackButton from './components/SongActions/components/ViewPackButton/ViewPackButton';
import ViewLoopsButton from './components/SongActions/components/ViewLoopsButton/ViewLoopsButton';
import ShareButton from './components/SongActions/components/ShareButton/ShareButton';
import HideSongButton from './components/SongActions/components/HideSongButton/HideSongButton';
import DownloadingIcon from '../../../components/DownloadingIcon/DownloadingIcon';
import { getTrackDownloadMidiAction, getTrackDownloadWavAction } from '../../../cms/track';
import { useDownloadSong } from '../../hooks';
import { useHideTrack, useUnhideTrack } from '../../../api/firebase/user/hiddenTracks';
import PlayPauseButton from './components/SongActions/components/PlayPauseButton/PlayPauseButton';
import { useAudioPlayerContext } from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import AudioBars from '../AudioBars/AudioBars';
import SfxDetails from '../FullDisplaySongCard/components/SfxDetails/SfxDetails';

const Wrapper = styled('div')`
  position: relative;
  margin-top: 64px;

  &.mobileResultsWrapper {
    background-color: ${lightColor};
    margin: 0px 10 0px 0px;
  }

  &.hiddenSong {
    opacity: 0.5;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 32px;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-top: 10px;
  }
`;

const Container = styled('div')`
  background-color: #dad8d3;
  border-radius: 5px;
  padding: 25px 30px 35px 30px;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 20px 10px;
    overflow: hidden;
  }
`;

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const viewAllButtonClass = css`
  padding: 5px;
  margin-right: -5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 11px;
    letter-spacing: 1.5px;
  }

  &:nth-child(2) {
    margin-left: 20px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-left: 10px;
    }
  }
`;

const PlaybackHeader = styled('div')`
  display: flex;
  max-width: 50%;

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: 85%;
  }
`;

const PlayWrapper = styled('div')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-right: 20px;
  }
  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
  }
`;

const SongTypeWrapper = styled('div')`
  margin-left: -3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Info = styled('div')`
  min-width: 110px;

  ${LARGE_DESKTOP_BREAKPOINT} {
    min-width: 400px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    width: 340px;
  }

  ${MEDIA_BREAKPOINTS.max900} {
    width: 300px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    width: 260px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 400px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 300px;
  }
`;

const SongSubtitle = styled('button')`
  ${buttonResetCss};
  ${monoFontCss};
  ${interactiveTextButtonCss};
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 2px;
  color: ${transparentize(0.4, darkColor)};
  text-transform: uppercase;

  &.nonInteractive {
    text-decoration: none;
    cursor: default;
  }
`;

const Middle = styled('div')`
  margin-top: 30px;
  margin-bottom: 40px;
  height: 120px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 10px;
    margin-bottom: 5px;
    height: 70px;
  }
`;

const ControlsWrapper = styled('div')`
  display: flex;

  ${LARGE_DESKTOP_BREAKPOINT} {
    margin-right: 10px;
    justify-content: flex-end;
    width: 15%;
    min-width: 120px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    order: 2;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const Control = styled('div')`
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const DownloadButton = styled('button')`
  ${monoNavCss};
  background-color: none;
  border: 1px solid #4b4c50;
  border-radius: 3px;
  text-transform: uppercase;
  color: ${darkColor};
  background-color: unset;
  padding: 8px 15px;
  margin: 5px 10px;
  height: 40px;
  min-width: 160px;

  &:hover {
    cursor: pointer;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 5px 10px;
    width: 160px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const hiddenMidiDownload = css`
  display: none;
`;

const DownloadAction = styled('div')``;

type Props = {
  sideAction?: any,
  song: AlgoliaSongMdl,
  autoScroll?: boolean,
  disableViewAllParts?: boolean,
  playerType?: string,
  hidePackActions?: boolean,
};

const FullSongCard = ({
  song,
  autoScroll = false,
  disableViewAllParts = false,
  sideAction,
  playerType = '',
  hidePackActions = true,
}: Props) => {
  const containerRef = useRef();
  const { playingSongID } = useAudioPlayerContext();

  const userHiddenSongs = useUserHiddenTracks();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const [handleHideTrack, hideTrackBusy] = useHideTrack(song);
  const [handleUnhideTrack, unhideTrackBusy] = useUnhideTrack(song);
  const trackCopy = useTrackCopy();
  const [downloadSong, downloadBusy, downloadMidiBusy] = useDownloadSong(song);

  const songActive = getSongID(song) === playingSongID;
  const songProgress = songActive ? progress : getSongDurationFormatted(song);
  const songArtwork = getSongArtwork(song);
  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  const showSfxDetails = isSongSfx(song) && getSongTypeTags(song).length > 0;

  const disableMidi = song.audio.downloadMidi === undefined;

  useEffect(() => {
    if (autoScroll && containerRef.current) {
      try {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } catch (e) {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when scrolling to song card');
        Sentry.captureException(e);
        console.error(e);
      }
    }
  }, [autoScroll, containerRef]);

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  return (
    <Wrapper
      className={cx({
        mobileResultsWrapper: playerType === PLAYER_TYPES.mobileResults,
        hiddenSong:
          songIsHidden &&
          (playerType !== PLAYER_TYPES.direct ||
            playerType === PLAYER_TYPES.embed ||
            playerType === PLAYER_TYPES.download_bookmark),
      })}
    >
      <div ref={containerRef}>
        <Container>
          <Header>
            <PlaybackHeader>
              <PlayWrapper>
                <PlayPauseButton backgroundImage={songArtwork} />
              </PlayWrapper>
              <Info>
                <SongTypeWrapper>{getSongName(song)}</SongTypeWrapper>
                <SongSubtitle className={cx({ nonInteractive: true })}>
                  <ArtistName name={artistName} slug={artistslug} />
                  {getSongSubtitle(song, songActive, songProgress)}
                </SongSubtitle>
              </Info>
            </PlaybackHeader>
            <ControlsWrapper className={hideOnSmallDeviceCss}>
              <Control>
                <BookmarkButton condensed />
              </Control>
              <Control>
                <SimilarSongsButton />
              </Control>
              {!hidePackActions && (
                <>
                  <Control>
                    <ViewPackButton />
                  </Control>
                  <Control>
                    <ViewLoopsButton />
                  </Control>
                </>
              )}
              <Control>
                <ShareButton playerType={playerType} />
              </Control>
              <Control>
                <HideSongButton
                  isHidden={songIsHidden}
                  onClick={handleToggleHidden}
                  busy={hideTrackBusy || unhideTrackBusy}
                />
              </Control>
            </ControlsWrapper>
          </Header>
          <ControlsWrapper className={showOnSmallDeviceCss}>
            <Control>
              <BookmarkButton condensed />
            </Control>
            <Control>
              <SimilarSongsButton />
            </Control>
            {!hidePackActions && (
              <>
                <Control>
                  <ViewPackButton />
                </Control>
                <Control>
                  <ViewLoopsButton />
                </Control>
              </>
            )}
            <Control>
              <ShareButton playerType={playerType} />
            </Control>
            <Control>
              <HideSongButton
                isHidden={songIsHidden}
                onClick={handleToggleHidden}
                busy={hideTrackBusy || unhideTrackBusy}
              />
            </Control>
          </ControlsWrapper>
          <Middle>
            <AudioBars />
          </Middle>
          <DownloadAction>
            <DownloadButton onClick={() => downloadSong()}>
              {downloadBusy ? <DownloadingIcon /> : getTrackDownloadWavAction(trackCopy)}
            </DownloadButton>
            <DownloadButton
              className={cx({ [hiddenMidiDownload]: disableMidi })}
              onClick={() => downloadSong('MIDI')}
            >
              {downloadMidiBusy ? <DownloadingIcon /> : getTrackDownloadMidiAction(trackCopy)}
            </DownloadButton>
          </DownloadAction>
        </Container>
      </div>
      {showSfxDetails ? <SfxDetails /> : <Details />}
    </Wrapper>
  );
};

export default FullSongCard;
