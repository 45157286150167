// @flow
import React, { useContext, useState } from 'react';

export type EmbedSongViewContextState = {
  viewingDetails: boolean,
  setViewingDetails: any => void,
};

export const EmbedSongViewContext = React.createContext();

export const useEmbedSongViewContext = (): SongViewContextState => {
  return useContext(EmbedSongViewContext);
};

type Props = {
  children: any,
};

const EmbedSongViewWrapper = ({ children }: Props) => {
  const [viewingDetails, setViewingDetails] = useState(false);

  return (
    <EmbedSongViewContext.Provider value={{ viewingDetails, setViewingDetails }}>
      {children}
    </EmbedSongViewContext.Provider>
  );
};

export default EmbedSongViewWrapper;
