// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
  LARGE_DESKTOP_BREAKPOINT,
} from '../../../../../../../../../../styles/responsive';

const List = styled('ul')`
  ${LARGE_DESKTOP_BREAKPOINT} {
    max-height: 40vh;
    overflow-y: ${props => (props.itemCount > 7 ? 'auto' : 'hidden')};

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: grey;
    }
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: center;
  }

  > li {
    &:not(:first-child) {
      margin-top: 5px;

      ${SMALL_DESKTOP_BREAKPOINT} {
        margin-top: 0;
      }
    }

    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-right: 5px;
      margin-bottom: 5px;

      ${SMALL_DEVICE_BREAKPOINT} {
        margin-right: 11px;
        margin-bottom: 9px;
      }
    }
  }
`;

type Props = {
  children: Array<any>,
};

const KeywordsList = ({ children }: Props) => (
  <List data-cy="selectedKeywords" itemCount={children.length}>
    {React.Children.map(children, child => {
      return <li key={child.key}>{child}</li>;
    })}
  </List>
);

export default KeywordsList;
