// @flow
import React from 'react';
import styled from 'react-emotion';

const spinner = '/gifs/Spinner.gif';
const spinner2x = '/gifs/Spinner@2x.gif';
const spinnerInverse = '/gifs/SpinnerReverse.gif';
const spinnerInverse2x = '/gifs/SpinnerReverse@2x.gif';

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 42%;
  }
`;

type Props = {
  containerClassName?: string,
  inverse: boolean,
};

const SpinnerIcon = ({ inverse = false, containerClassName = '', ...props }: Props) => {
  const src = inverse ? spinnerInverse : spinner;
  const src2x = inverse ? spinnerInverse2x : spinner2x;
  return (
    <Container className={containerClassName}>
      <img src={src} srcSet={`${src2x} 2x`} alt="" {...props} />
    </Container>
  );
};

export default SpinnerIcon;
