export const urlReplaceSlug = (locationSearch, pathnameURL, searchURL, song) => {
  const rx = new RegExp('[?&]' + 'slug' + '=([^&]+).*$');
  const slugMatch = window.location.search.match(rx);
  const newSearchURL = window.location.search.replace(
    `slug=${slugMatch[1]}`,
    `slug=${song.shareSlug}`
  );

  window.history.replaceState(
    null,
    '',
    `${window.location.search.replace(window.location.search, `${pathnameURL}${newSearchURL}`)}`
  );
};
