// @flow
import React from 'react';
import { useNavigate } from '../../../../../hooks';
import { useGlobalPlayerContext } from '../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { ROUTES } from '../../../../../routes';
import {
  useCuratedCollections,
  useMusicPage,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
  getRecentlyUpdatedCollections,
} from '../../../../../../cms/curatedCollection';
import PreviewTile from './PreviewTile';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';
import PreviewTileRow from './PreviewTileRow';
import { getMusicPageNewCollectionsTitle } from '../../../../../../cms/musicPage';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';

const NewestCollections = () => {
  const navigate = useNavigate();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const musicPage = useMusicPage();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const curatedCollections = useCuratedCollections();
  const newestCollections = getRecentlyUpdatedCollections(curatedCollections);

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'newestCollection', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'newestCollection', '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  return (
    <PreviewTileRow
      title={getMusicPageNewCollectionsTitle(musicPage)}
      viewAllLink={ROUTES.collections.navigatePath({})}
      showAllTiles={false}
      rows={2}
    >
      {newestCollections.map(collection => {
        const slug = getCuratedCollectionId(collection);
        return (
          <PreviewTile
            key={slug}
            title={getCuratedCollectionTitle(collection)}
            slug={slug}
            bgImage={getCuratedCollectionImage(collection)}
            listType="collection"
            previewTile={() => {
              analyticsMixpanelPreviewSongs(
                mixpanel,
                moengage,
                'Collection',
                slug,
                'Newest Collections',
                userId
              );
              handlePreviewCollection(collection);
            }}
            navigateTile={() => {
              navigateCollection(slug);
            }}
          />
        );
      })}
    </PreviewTileRow>
  );
};

export default NewestCollections;
