// @flow

import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { useRecoilValue } from 'recoil';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { sendMixpanelPageVisit } from '../../../../../analytics/mixpanel';
import { searchAlgolia } from '../../../../../api/algolia/search';
import {
  getArtistProfile,
  getArtistProfileCoverArt,
  getArtistProfileCoverArtPosition,
  getArtistProfileCatchCopy,
  getArtistProfileDescription,
  getArtistProfileName,
  getArtistProfileThumbnail,
  getArtistProfileWholeBodyImage,
  getlinkToMv,
  getArtistProfileMushimegane,
  getLinktoTwitter,
  getLinktoYouTube,
  getLinktoInstagram,
  getLinktoPokekara,
} from '../../../../../cms/artistProfile';
import {
  usePosArtistProfiles,
  useCuratedCollections,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import PosFullCoverArtLayout from '../../../../../layouts/components/PosFullCoverArtLayout/PosFullCoverArtLayout';
import TabbedSongList from '../../../../../layouts/components/TabbedSongList/TabbedSongList';
import { useLocale } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { searchAlgoliaCollections } from '../../../../../api/algolia/collections';
import { useModalsContext } from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import ArtistCollections from './components/ArtistCollections/ArtistCollections';
import { getCuratedCollectionId } from '../../../../../cms/curatedCollection';
import {
  colelctionKeywordsSearchedAtom,
  colelctionMixpanelKeywordsSearchedAtom,
  collectionMagicKeywordsSearchedAtom,
  collectionSectionOriginAtom,
  collectionYoutubeKeywordsSearchedAtom,
  collectionYoutubeVideoTitleSearchedAtom,
} from '../../../../../store/mixpanelAnalytics';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';

export const ListItem = styled('div')`
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    padding: 10px 0;
    &::after,
    &::before {
      content: '';
      height: 1px;
      position: absolute;
      left: 30px;
      right: 30px;
      border-top: 1px solid rgba(240, 240, 235, 0.05);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
      visibility: hidden;
    }

    &:last-child {
      &::after {
        visibility: visible;
      }
    }

    &:first-child:last-child {
      &::after {
        visibility: hidden;
      }
    }
  }
`;

const songlistCss = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0 30px;
  }
`;

type Props = {
  path: string,
  artistSlug: string,
};

const PosArtistView = ({ path, artistSlug }: Props) => {
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const artistProfiles = usePosArtistProfiles();
  const { showPosArtistModal } = useModalsContext();
  const { showPosSnsModal } = useModalsContext();
  const curatedCollections = useCuratedCollections();

  const [artistProfile, setArtistProfile] = useState(getArtistProfile(artistSlug, artistProfiles));
  const [songs, setSongs] = useState([]);
  const [artistCollections, setArtistCollections] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const locale = useLocale();

  const colelctionKeywordsSearched = useRecoilValue(colelctionKeywordsSearchedAtom);
  const colelctionMixpanelKeywordsSearched = useRecoilValue(colelctionMixpanelKeywordsSearchedAtom);
  const collectionMagicKeywordsSearched = useRecoilValue(collectionMagicKeywordsSearchedAtom);
  const collectionYoutubeKeywordsSearched = useRecoilValue(collectionYoutubeKeywordsSearchedAtom);
  const collectionYoutubeVideoTitleSearched = useRecoilValue(
    collectionYoutubeVideoTitleSearchedAtom
  );
  const collectionSectionOrigin = useRecoilValue(collectionSectionOriginAtom);

  const handleViewFullDescription = () => {
    showPosArtistModal(artistSlug);
  };

  const handleViewFullSnsIcons = () => {
    showPosSnsModal(artistSlug);
  };

  const handleArtistSongSearch = async () => {
    const queryFilter = `artistSlug: ${artistSlug}`;
    const songSearchResults = await searchAlgolia('', locale, [], queryFilter);
    const { hits } = songSearchResults;
    return hits;
  };

  const handleArtistCollectionSearch = async () => {
    const queryFilter = `collectionArtist: ${artistSlug}`;
    const collectionSearchResults = await searchAlgoliaCollections('', queryFilter, 16, locale);
    const { hits } = collectionSearchResults;
    const collectionIDs = hits.map(collection => collection.collectionSlug);
    const artitCollections = curatedCollections.filter(curatedCollection => {
      return collectionIDs.includes(getCuratedCollectionId(curatedCollection));
    });

    return artitCollections;
  };

  const handleRetrieveArtistData = async () => {
    const [songResults, collectionResults] = await Promise.all([
      handleArtistSongSearch(artistSlug),
      handleArtistCollectionSearch(artistSlug),
    ]);
    return [songResults, collectionResults];
  };

  useEffect(() => {
    // Send New Page Visit event
    const pageVisitProperties = {};
    if (artistSlug) {
      pageVisitProperties['Artist Slug'] = artistSlug;
    }
    sendMixpanelPageVisit(mixpanel, moengage, path, pageVisitProperties);
    // Change Artist Prismic Data
    setArtistProfile(getArtistProfile(artistSlug, artistProfiles));

    // Load song and collection data
    setLoading(true);
    if (artistSlug) {
      handleRetrieveArtistData()
        .then(data => {
          const [songResults, collectionResults] = data;
          setSongs(songResults);
          setArtistCollections(collectionResults);
        })
        .finally(() => {
          setLoaded(true);
          setLoading(false);
        });
    } else {
      setLoaded(true);
      setLoading(false);
    }
  }, [artistSlug]);

  const artistTitle = artistProfile ? getArtistProfileName(artistProfile) : '';

  return (
    artistProfile && (
      <>
        <PosFullCoverArtLayout
          coverUrl={getArtistProfileCoverArt(artistProfile)}
          coverPosition={getArtistProfileCoverArtPosition(artistProfile)}
          thumbnailUrl={getArtistProfileThumbnail(artistProfile)}
          title={artistTitle}
          catchcopy={getArtistProfileCatchCopy(artistProfile)}
          description={getArtistProfileDescription(artistProfile)}
          openDescriptionModal={handleViewFullDescription}
          openSnsIconsModal={handleViewFullSnsIcons}
          wholeBodyImage={getArtistProfileWholeBodyImage(artistProfile)}
          linkToMv={getlinkToMv(artistProfile)}
          mushimegane={getArtistProfileMushimegane(artistProfile)}
          linktoYouTube={getLinktoYouTube(artistProfile)}
          linktoTwitter={getLinktoTwitter(artistProfile)}
          linktoInstagram={getLinktoInstagram(artistProfile)}
          linktoPokekara={getLinktoPokekara(artistProfile)}
        />
        <ArtistCollections collections={artistCollections} artistSlug={artistSlug} />
        <TabbedSongList
          className={songlistCss}
          songs={songs}
          songListType="artist"
          songListTitle={artistTitle}
          songListSlug={artistSlug}
          songlistKeywords={[]}
          keywords={colelctionKeywordsSearched}
          mixpanelKeywords={colelctionMixpanelKeywordsSearched}
          magicKeywords={collectionMagicKeywordsSearched}
          youtubeKeywords={collectionYoutubeKeywordsSearched}
          youtubeVideoTitle={collectionYoutubeVideoTitleSearched}
          sectionOrigin={collectionSectionOrigin}
        />
      </>
    )
  );
};

export default PosArtistView;
