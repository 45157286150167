// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SiteMetaGraphMdl = {
  data: {
    default_page_title: {
      text: string,
    },
    meta_tags: {
      text: string,
    },
    meta_share_image: {
      url: string,
    },
  },
};

export const getSiteMetaDefaultPageTitle = (data: SiteMetaGraphMdl): string => {
  return get(data, 'data.default_page_title.text', '') || '';
};

export const getSiteMetaTags = (data: SiteMetaGraphMdl): string => {
  return get(data, 'data.meta_tags.text', '') || '';
};

export const getSiteMetaShareImage = (data: SiteMetaGraphMdl): string => {
  return get(data, 'data.meta_share_image.url', '') || '';
};

export const query = graphql`
  fragment SiteMeta on PrismicSiteMeta {
    data {
      default_page_title {
        text
      }
      meta_tags {
        text
      }
      meta_share_image {
        url
      }
    }
  }
`;
