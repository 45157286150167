// @flow
import React from 'react';
import parse from 'html-react-parser';
import { transparentize } from 'polished';
import styled, { css, cx } from 'react-emotion';
import { hugeTextCss, monoNavCss } from '../../../../../styles/typography/typography';
import { CURRENCY_CODES, INTERVAL_MONTHLY_DURATION } from '../../../data';
import { useLocale } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useTranslation } from 'react-i18next';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
} from '../../../../../utils/subscriptions';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import {
  getSubscriptionsPagePromotionalLabelIntervalChange,
  getSubscriptionsPagePromotionalLabelNewPlan,
  getSubscriptionsPagePromotionalLabelUpgradePlan,
  isPromotionAvailable,
} from '../../../../../cms/subscriptionsPage';
import {
  usePricingPlan,
  useSubscriptionsPage,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useSubscriptionsContext } from '../../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getSeatInterval,
  getSubscriptionPlanKey,
} from '../../../../../api/firebase/user/subscriptions';
import {
  businessPlanCss,
  enterprisePlanCss,
  guestPlanCss,
  personalPlanCss,
} from '../../PlanPreview/PlanPreview';
import { darkColor, lightColor, personalBgColor } from '../../../../../styles/config/colors';
import Button from '../../../../../components/Button/Button';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../../api/firebase/user/profile';
import { getPricingPlanFormLink } from '../../../../../cms/pricingPlan';
import { useUserProfileDetails } from '../../../../components/UserProfileWrapper/UserProfileWrapper';
import { SPLIT_PAYMENT_EN_URL, SPLIT_PAYMENT_URL } from '../../../../../utils/const';

const pricingHeading = css`
  ${hugeTextCss};
`;

const pricingSubheading = css`
  ${monoNavCss};
`;

const Price = styled('div')`
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 2px;
  padding: 22px 5px 20px 5px;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  max-height: 120px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Mono', sans-serif;

  h3 {
    ${pricingHeading};
    font-size: ${props => (props.currencyCode === 'JPY' ? '25px' : '30px')};
  }
  p {
    ${pricingSubheading};
    opacity: 0.6;
    text-transform: uppercase;
    margin-top: 5px;

    &:last-of-type {
      margin-top: 2px;
    }
  }

  & sub {
    font-size: 20px;
  }
`;

const BillingCyclesWrapper = styled('div')`
  margin: auto;
  margin-top: 0px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const hiddenButtonClass = css`
  visibility: hidden;
  pointer-events: none;
`;

const subscribeButtonCss = css`
  background-color: #4d4d4d;
  display: flex;
  margin: auto;
  min-width: 240px;
`;

const japaneseLocalePromotionLabel = css`
  & h6:first-of-type {
    padding: 10px 20px 0px 20px;
  }

  & h6:last-of-type {
    padding: 0px 20px 10px 20px;
  }

  & p {
    padding: 0px 10px 10px 10px;
  }
`;

const PromotionLabel = styled('div')`
  text-align: center;
  margin-top: 20px;
  border-radius: 2px;
  ${personalBgColor}
  background-size: cover;
  background-position: center;
  min-height: 100px;

  ${SMALL_DEVICE_BREAKPOINT} {
    min-height: unset;
    width: 100%;
  }

  & div {
    width: 100%;
    height: 100%;
    background-color: ${transparentize(0.5, lightColor)};
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h6 {
    height: 30%;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 10px 0px 2px 0px;
    color: ${darkColor};
  }

  & p {
    height: 70%;
    font-size: 12px;
    padding: 0px 7px 10px 7px;
    line-height: 20px;
  }
`;

type PricingSectionCardType = {
  selectedPlan: string,
  disableAction: boolean,
  busy: boolean,
  action: () => void,
  changingSubscription: any,
  selectOption: string,
  subscriptionModalType?: string,
};

const PricingSectionCard = ({
  selectedPlan,
  disableAction = false,
  busy,
  action,
  changingSubscription,
  selectOption = 'oneTimePayment',
  subscriptionModalType,
}: PricingSectionCardType) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const subscriptionsPage = useSubscriptionsPage();
  const { billingCycle } = useSubscriptionsContext();
  const profile = useUserProfileDetails();
  const planCopy = usePricingPlan(selectedPlan);

  const promotionAvailable = isPromotionAvailable(subscriptionsPage, selectedPlan, billingCycle);

  const downgradingBusiness = changingSubscription
    ? isBusinessSubscription(getSubscriptionPlanKey(changingSubscription)) &&
      isCreatorProSubscription(selectedPlan)
    : false;

  const downgradingInterval = changingSubscription
    ? INTERVAL_MONTHLY_DURATION[billingCycle] <
        INTERVAL_MONTHLY_DURATION[getSeatInterval(changingSubscription)] &&
      selectedPlan === getSubscriptionPlanKey(changingSubscription)
    : false;

  const promotionalText = ((): string => {
    if (changingSubscription) {
      const isIntervalChange = getSubscriptionPlanKey(changingSubscription) === selectedPlan;
      if (isIntervalChange) {
        return getSubscriptionsPagePromotionalLabelIntervalChange(subscriptionsPage);
      }

      return getSubscriptionsPagePromotionalLabelUpgradePlan(subscriptionsPage);
    }
    return getSubscriptionsPagePromotionalLabelNewPlan(subscriptionsPage);
  })();

  const getPricingPlanAnnuallyPrice = (plan: string) => {
    if (isCreatorProSubscription(plan)) {
      return t('general.pricing.price.loyalMember.annually', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.pricing.price.loyalMemberPlus.annually', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.pricing.price.enterprise.annually', '');
    }
  };

  const getPricingPlanMonthlyBillingSubtext = () => {
    return t('general.pricing.billingSubtext.monthly', '');
  };

  const getAddSubscriptionAnnuallyActionButtonText = (plan: string, actionState: string) => {
    if (isEnterpriseSubscription(plan)) {
      return t('modal.addSubscription.button.getInContact', '');
    }

    if (actionState === 'downgrade') {
      return t('modal.addSubscription.button.downgrade.oneTimePayment', '');
    }

    if (actionState === 'upgrade') {
      return t('modal.addSubscription.button.upgrade.oneTimePayment', '');
    }

    return t('modal.addSubscription.button.add.oneTimePayment', '');
  };

  const getPricingPlanMonthlyPrice = (plan: string) => {
    if (isCreatorProSubscription(plan)) {
      return t('general.pricing.price.loyalMember.monthly', '');
    }

    if (isBusinessSubscription(plan)) {
      return t('general.pricing.price.loyalMemberPlus.monthly', '');
    }

    if (isEnterpriseSubscription(plan)) {
      return t('general.pricing.price.enterprise.monthly', '');
    }
  };

  const getPricingPlanAnnuallyOrMonthlyPrice = (selectOption, plan) => {
    if (selectOption === 'oneTimePayment') {
      return getPricingPlanAnnuallyPrice(plan);
    }

    if (selectOption === 'splitPayment') {
      return getPricingPlanMonthlyPrice(plan);
    }
  };

  const getAddSubscriptionMonthlyActionButtonText = (plan: string, actionState: string) => {
    if (isEnterpriseSubscription(plan)) {
      return t('modal.addSubscription.button.getInContact', '');
    }

    if (actionState === 'downgrade') {
      return t('modal.addSubscription.button.downgrade.splitPayment', '');
    }

    if (actionState === 'upgrade') {
      return t('modal.addSubscription.button.upgrade.splitPayment', '');
    }

    return t('modal.addSubscription.button.add.splitPayment', '');
  };

  const getAddSubscriptionAnnuallyOrMonthlyActionButtonText = (selectOption, plan, actionState) => {
    if (selectOption === 'oneTimePayment') {
      return getAddSubscriptionAnnuallyActionButtonText(plan, actionState);
    }

    if (selectOption === 'splitPayment') {
      return getAddSubscriptionMonthlyActionButtonText(plan, actionState);
    }
  };

  const handleEnterpriseClick = async () => {
    try {
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = getPricingPlanFormLink(planCopy);
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        window.location.href = completeFormLink;
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      return;
    }
  };

  const handleSplitPayment = plan => {
    if (isEnterpriseSubscription(plan)) {
      handleEnterpriseClick();
    } else {
      window.location.href = locale === 'ja' ? SPLIT_PAYMENT_URL : SPLIT_PAYMENT_EN_URL;
    }
  };

  const handlePaymentUpAndDowngrade = (selectOption, plan) => {
    if (selectOption === 'oneTimePayment') {
      action();
    }

    if (selectOption === 'splitPayment') {
      handleSplitPayment(plan);
    }
  };

  return (
    <>
      <Price currencyCode={CURRENCY_CODES[locale]}>
        <h3>
          {getPricingPlanAnnuallyOrMonthlyPrice(selectOption, selectedPlan)}
          {!isCreatorSubscription(selectedPlan) && (
            <sub>{getPricingPlanMonthlyBillingSubtext()}</sub>
          )}
        </h3>
      </Price>
      <BillingCyclesWrapper>
        <Button
          className={cx(
            {
              [hiddenButtonClass]: disableAction,
            },
            subscribeButtonCss
          )}
          loading={busy}
          onClick={() => handlePaymentUpAndDowngrade(selectOption, selectedPlan)}
          disabled={disableAction}
        >
          {getAddSubscriptionAnnuallyOrMonthlyActionButtonText(
            selectOption,
            selectedPlan,
            subscriptionModalType
          )}
        </Button>
      </BillingCyclesWrapper>

      {promotionAvailable && !downgradingBusiness && !downgradingInterval && (
        <PromotionLabel
          className={cx({
            [japaneseLocalePromotionLabel]: locale === 'ja',
            [guestPlanCss]: isCreatorSubscription(selectedPlan),
            [personalPlanCss]: isCreatorProSubscription(selectedPlan),
            [businessPlanCss]: isBusinessSubscription(selectedPlan),
            [enterprisePlanCss]: isEnterpriseSubscription(selectedPlan),
          })}
        >
          <div>{parse(promotionalText)}</div>
        </PromotionLabel>
      )}
    </>
  );
};

export default PricingSectionCard;
