import { graphql } from 'gatsby';
import { get } from 'lodash';

export type DownloadHistoryPageGraphMdl = {
  data: {
    no_results_heading: {
      text: string,
    },
    no_results_message: {
      html: string,
    },
  },
};

export const getDownloadHistoryPageNoResultsHeading = (
  copy: DownloadHistoryPageGraphMdl
): string => {
  return get(copy, 'data.no_results_heading.text', '') || '';
};

export const getDownloadHistoryPageNoResultsMessage = (
  copy: DownloadHistoryPageGraphMdl
): string => {
  return get(copy, 'data.no_results_message.html', '') || '';
};

export const query = graphql`
  fragment DownloadHistoryPage on PrismicDownloadHistoryPage {
    data {
      no_results_heading {
        text
      }
      no_results_message {
        html
      }
    }
  }
`;
