// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useRecoilValue } from 'recoil';
import { monoFontCss, smallFontSizeCss } from '../../../../../styles/typography/typography';
import { hideOnSmallDeviceCss, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import { getSongDuration, getSongID } from '../../../../../api/algolia/song';
import { formatSeconds } from '../../../../../utils/time';
import DisplayAudioBars from '../DisplayAudioBars/DisplayAudioBars';
import { songPlayingSelector, songProgressAtom } from '../../../../../store/globalPlayer';

const Container = styled('div')`
  height: 120px;
  display: flex;
  align-items: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 60px;
  }
`;

const Time = styled('div')`
  ${hideOnSmallDeviceCss};
  ${monoFontCss};
  ${smallFontSizeCss};
  line-height: 20px;
  letter-spacing: 0.5px;
  min-width: 50px;

  &:last-child {
    text-align: right;
  }
`;

const hideTimeCss = css`
  display: none;
`;

const GraphContainer = styled('div')`
  flex: 1;
  height: 100%;
`;

type Props = {
  songPosition: number,
  hideTime?: boolean,
  checkSongPosition: boolean,
};

const SongMiddle = ({ songPosition, hideTime = false, checkSongPosition }: Props) => {
  const song = useSong();
  const songPlaying = useRecoilValue(songPlayingSelector);
  const songProgress = useRecoilValue(songProgressAtom);

  const isSongPlaying = getSongID(song) === getSongID(songPlaying);
  const progress = isSongPlaying ? songProgress : 0;

  return (
    <Container>
      <Time className={cx({ [hideTimeCss]: hideTime })}>{formatSeconds(progress)}</Time>
      <GraphContainer>
        <DisplayAudioBars
          hideMicropartsOnMobile
          songPosition={songPosition}
          checkSongPosition={checkSongPosition}
        />
      </GraphContainer>
      <Time className={cx({ [hideTimeCss]: hideTime })}>
        {formatSeconds(getSongDuration(song))}
      </Time>
    </Container>
  );
};

export default SongMiddle;
