// @flow
import React, { useEffect, useState, useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { urlReplaceSlug } from '../../../utils/useUrlReplace';
import {
  getMicropartPosition,
  getMicropartShareSlug,
  getParsedMicroparts,
  getSongDuration,
  getSongID,
  getSongMicropartDownloadURL,
  getSongMicropartPosition,
  getSongMicropartShareSlug,
  getSongStreamUrl,
} from '../../../api/algolia/song';
import { useMicroPartsContext } from '../MicroPartsWrapper/MicroPartsWrapper';
import {
  analyticsSongsMicropartDownload,
  analyticsSongsMicropartPlay,
  analyticsSongsMicroparts,
  analyticsSongsPlay,
  useSongAnalyticsDimensions,
  analyticsKeywordsNewDownloadSearchTerm,
} from '../../../analytics/events';
import { useSongAudioPlayback } from '../../../audio/hooks';
import { useAudioPlayerContext } from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { findMicropartIndex } from '../AudioBars/AudioBars';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import {
  useModalsContext,
  useShowSignUp,
} from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import {
  useDownloadContext,
  getDownloadContextKeywords,
} from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { firebaseApiHandler } from '../../../api/firebase/api';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../user/subscriptions/data';
import { PLAYER_TYPES } from '../../data';
import { useResultsManagerContext } from '../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';
import { useBookmarksManagerContext } from '../../../routing/screens/ProfileScreen/views/ProfileBookmarksView/components/BookmarksManager/BookmarksManager';
import { useDownloadsManagerContext } from '../../../routing/screens/ProfileScreen/views/ProfileDownloadHistoryView/components/DownloadHistoryManager/DownloadHistoryManager';
import {
  analyticsMixpanelPlayTrack,
  useMixpanelSongAnalyticsDimensions,
  analyticsMixpanelDownloadMicropart,
} from '../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { useGlobalPlayerContext } from '../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { globalPlayingAtom } from '../../../store/globalPlayer';
import { conversionSourceAtom } from '../../../store/mixpanelAnalytics';

export type AudioPlaybackContextState = {
  tryingToPlay: boolean,
  loadError: boolean,
  canPlay: boolean,
  progress: number,
  played: boolean,
  onSeek: number => void,
  viewingMicroparts: boolean,
  microPartIndex: number,
  onMicropartSelect: number => void,
  duration: number,
  onPlayToggle: () => void,
  songID: string,
  toggleMicroparts: () => void,
  getAudioProgress: () => number,
  playing: boolean,
  microparts: Array<[number, number]>,
  onDownloadMicropart: number => void,
  swappingLoop: boolean,
  setSwappingLoop: (state: boolean) => void,
};

export const useSongSlugQuery = () => {
  return useQueryParam('slug', StringParam);
};

export const AudioPlaybackContext = React.createContext<any>();

export const useAudioPlaybackContext = (): AudioPlaybackContextState => {
  return useContext(AudioPlaybackContext);
};

type Props = {
  children: any,
  song: AlgoliaSongMdl,
  autoplay?: boolean,
  autoload?: boolean,
  mainAudioCard: boolean,
  playerType: string,
  expandKey?: string,
  modalGlobalPause?: () => void,
};

let toggle = true;
let pathnameURL;
let searchURL;
const AudioPlayback = ({
  children,
  song,
  autoplay = false,
  autoload = false,
  mainAudioCard,
  playerType,
  expandKey = '',
  modalGlobalPause = () => {},
}: Props) => {
  let unmounted = false;
  if (toggle) {
    pathnameURL = window.location.pathname;
    searchURL = window.location.search;
  }
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const showSignUp = useShowSignUp();
  const downloadContext = useDownloadContext();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const { showSubscriptionRequired } = useModalsContext();
  const {
    isNextSongPlayable,
    numberOfVisibleGroups,
    setNumberOfVisibleGroups,
    goToNextGroupedSong,
    // eslint-disable-next-line no-nested-ternary
  } = useBookmarksManagerContext()
    ? useBookmarksManagerContext()
    : useDownloadsManagerContext()
    ? useDownloadsManagerContext()
    : {
        isNextSongPlayable: (songID, expandKey) => {},
        numberOfVisibleGroups: null,
        setNumberOfVisibleGroups: visibleGroups => {},
        goToNextGroupedSong: (songID, expandKey) => {
          return { nextGroupedSongId: null, nextExpandKey: null };
        },
      };

  const {
    currentIndexSong,
    goToNextSong,
    nextEnabled,
    songNavigationPermitted,
  } = useResultsManagerContext()
    ? useResultsManagerContext()
    : {
        currentIndexSong: null,
        goToNextSong: () => {},
        goToPreviousSong: () => {},
        nextEnabled: null,
        nextMobileSongId: songID => {},
        setMobileResultsNumberVisibleSongs: visibleGroups => {},
        isNextMobileSongPlayable: songID => {},
        mobileResultsNumberVisibleSongs: null,
        sortingMethod: '',
        similarSongQuery: '',
        songNavigationPermitted: true,
      };

  const [duration, setDuration] = useState(getSongDuration(song));

  const microparts = getParsedMicroparts(song);

  const songID = getSongID(song);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const isInlinePlayer =
    playerType === PLAYER_TYPES.loops_loop ||
    playerType === PLAYER_TYPES.loops_main ||
    playerType === PLAYER_TYPES.sfx;

  const {
    playingSongID,
    setPlayingSongID,
    previousPlayingState,
    setPreviousPlayingState,
    playingSongExpandKey,
    setPlayingSongExpandKey,
    analyticsSentSongId,
    setAnalyticsSentSongId,
    setAnalyticsSentMicropartSongId,
    setAnalyticsSentMicropartPosition,
    loopsPlaying,
    setLoopsPlaying,
  } = useAudioPlayerContext();

  const [canPlay, setCanPlay] = useState(false);
  const [tryingToPlay, setTryingToPlay] = useState(autoplay && !canPlay);
  const [progress, setProgress] = useState(0);
  const [microPartIndex, setMicroPartIndex] = useState(0);
  const { setViewingMicroparts, viewingMicroparts } = useMicroPartsContext();
  const [swappingLoop, setSwappingLoop] = useState(false);

  const [setShareSlug] = useSongSlugQuery();

  const dimensions = useSongAnalyticsDimensions();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: null, moengage: null };
  const userId = useUserId();

  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const { pauseGlobalPlayback } = useGlobalPlayerContext()
    ? useGlobalPlayerContext()
    : { globalPlaying: true, pauseGlobalPlayback: modalGlobalPause };

  const handleSetTryingToPlay = () => {
    if (!canPlay) {
      setTryingToPlay(true);
    }
  };

  const onPlay = () => {
    setPlayingSongExpandKey(expandKey);
    setPlayingSongID(songID);
    setPlayed(true);
    setPlaying(true);
    setCanPlay(true);
    setTryingToPlay(false);
  };

  const onPause = () => {
    setPlaying(false);
    setTryingToPlay(false);
  };

  const onStop = () => {
    setPlaying(false);
    setTryingToPlay(false);
    setProgress(0);
  };

  const handlePreviousPlayingState = (previousState: boolean) => {
    setPreviousPlayingState(previousState);
  };

  const handleLooping = async () => {
    if (playerType === PLAYER_TYPES.mainResults || playerType === PLAYER_TYPES.mobileResults) {
      if (nextEnabled && songNavigationPermitted) {
        goToNextSong();
      } else {
        audioHandler.stop();
      }
    }

    if (playerType === PLAYER_TYPES.download_bookmark) {
      audioHandler.stop();
      const { nextGroupedSongId, nextExpandKey } = await goToNextGroupedSong(songID, expandKey);
      if (nextGroupedSongId) {
        if (!isNextSongPlayable(songID, expandKey)) {
          setNumberOfVisibleGroups(numberOfVisibleGroups + 10);

          setTimeout(() => {
            setPlayingSongExpandKey(nextExpandKey);
            setPlayingSongID(nextGroupedSongId);
          }, 2500);
        } else {
          setTimeout(() => {
            setPlayingSongExpandKey(nextExpandKey);
          }, 1000);
        }
      }
    }
  };

  const handleGetPlayerType = (): string => {
    return playerType;
  };

  const [audio, audioHandler] = useSongAudioPlayback(
    songID,
    getSongStreamUrl(song),
    microparts,
    duration,
    autoplay,
    autoload,
    onPlay,
    onPause,
    onStop,
    previousPlayingState,
    handlePreviousPlayingState,
    handleLooping,
    handleGetPlayerType
  );

  const handleDownloadMicropart = (index: number): Promise<any> => {
    const downloadURL = getSongMicropartDownloadURL(song, index);

    if (isAuthenticated) {
      if (!hasSubscription) {
        setConversionSource('download_micropart');
        showSubscriptionRequired();
        return Promise.resolve();
      }

      if (!USER_PERMISSIONS[userRole].canDownloadMicroparts) {
        console.log('Sorry you are not allowed to download microparts');
        return Promise.reject();
      }

      analyticsSongsMicropartDownload(
        getSongMicropartShareSlug(song, index),
        getSongMicropartPosition(song, index),
        dimensions
      );
      analyticsKeywordsNewDownloadSearchTerm(
        getDownloadContextKeywords(downloadContext),
        songID,
        getSongMicropartPosition(song, index)
      );
      return firebaseApiHandler.downloadSong(songID, downloadURL, downloadContext).finally(() => {
        analyticsMixpanelDownloadMicropart(
          mixpanel,
          moengage,
          mixpanelSongDimensions,
          downloadContext,
          userRole,
          userId,
          getSongMicropartPosition(song, index) + 1
        );
      });
    }
    if (signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Download', signUpCampaign: '' });
    }
    setConversionSource('download_micropart');
    showSignUp();
    return Promise.reject();
  };

  const handlePlay = () => {
    if (
      song.shareSlug &&
      (playerType === PLAYER_TYPES.mainResults || playerType === PLAYER_TYPES.mobileResults)
    ) {
      urlReplaceSlug(window.location.pathname, pathnameURL, searchURL, song);
    }

    if (playerType === PLAYER_TYPES.loops_main) {
      setLoopsPlaying(false);
    }
    if (playerType === PLAYER_TYPES.loops_loop) {
      setLoopsPlaying(true);
    }
    handleSetTryingToPlay();
    setPlayingSongExpandKey(expandKey);
    setPlayingSongID(songID);
    if (audio) {
      audioHandler.play();
    }
    if (viewingMicroparts) {
      const micropart = microparts[microPartIndex];
      analyticsSongsMicropartPlay(
        getMicropartShareSlug(micropart),
        getMicropartPosition(micropart),
        dimensions
      );
    } else {
      analyticsSongsPlay(dimensions);
    }
  };

  const handleCanPlay = () => {
    if (unmounted) return;
    setCanPlay(true);
    if (audio) {
      const audioDuration = audio.duration();
      if (audioDuration) {
        setDuration(audioDuration);
      }
    }
  };

  const handleListen = () => {
    if (unmounted) return;
    if (audio) {
      let currentTime = audioHandler.getProgress();
      if (typeof currentTime !== 'number') {
        currentTime = progress;
      }
      setProgress(currentTime);
    }
  };

  const handlePause = () => {
    setTryingToPlay(false);
    if (audio) {
      audioHandler.pause();
    }
  };

  const handleStop = () => {
    setTryingToPlay(false);
    if (audio) {
      audioHandler.stop();
    }
  };

  const handleSeek = (percent: number) => {
    if (unmounted) return;
    if (!canPlay) return;
    if (!audio) return;
    if (
      song.shareSlug &&
      (playerType === PLAYER_TYPES.mainResults || playerType === PLAYER_TYPES.mobileResults)
    ) {
      urlReplaceSlug(window.location.pathname, pathnameURL, searchURL, song);
    }
    const newTime = Math.floor(percent * duration);
    audioHandler.seek(newTime, null, true);

    setProgress(newTime);
  };

  const handlePlayError = (id, error) => {
    console.log('play error', error);
    setTryingToPlay(false);
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureMessage('Something went wrong when playing audio');
    Sentry.captureException(error);
    // todo - handle
  };

  const handleLoadError = (id, error) => {
    console.log('load error', error);
    setTryingToPlay(false);
    setLoadError(true);
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureMessage('Something went wrong when loading audio');
    Sentry.captureException(error);
    // todo - handle
  };

  const getAudioProgress = () => {
    return progress;
  };

  const handlePlayToggle = event => {
    if (event) {
      event.preventDefault();
    }
    if (playing) {
      handlePause();
    } else {
      if (song.shareSlug && playerType === PLAYER_TYPES.mobileResults) {
        urlReplaceSlug(window.location.pathname, pathnameURL, searchURL, song);
      }
      handlePlay();
    }
  };
  const handleToggleMicroparts = () => {
    if (song.shareSlug && playerType === PLAYER_TYPES.mobileResults) {
      urlReplaceSlug(window.location.pathname, pathnameURL, searchURL, song);
      toggle = false;
    }
    const nowViewingMicroparts = !viewingMicroparts;

    setViewingMicroparts(nowViewingMicroparts);

    if (nowViewingMicroparts) {
      analyticsSongsMicroparts(dimensions);
      const rawProgress = progress / duration;
      const currentMicroPartIndex = findMicropartIndex(rawProgress, microparts);
      setMicroPartIndex(currentMicroPartIndex);
      audioHandler.switchToMicroparts(currentMicroPartIndex);

      // Handles case when  viewing microparts of a song that is not playing. If the playingSongID does not match  songiD of this audio player,
      // then the new song should not be playing and it should be automatically be paused

      if (playingSongID !== songID) {
        handlePause();
      }
    } else {
      setAnalyticsSentMicropartSongId('');
      setAnalyticsSentMicropartPosition(null);
      audioHandler.switchToFullSong();
    }
  };
  const handleSelectMicropart = (index: number) => {
    if (playerType === PLAYER_TYPES.loops_loop) {
      setLoopsPlaying(true);
    }
    if (viewingMicroparts && index === microPartIndex) {
      return;
    }
    if (song.shareSlug && playerType === PLAYER_TYPES.mobileResults) {
      urlReplaceSlug(window.location.pathname, pathnameURL, searchURL, song);
    }
    audioHandler.playMicropart(index);
    setMicroPartIndex(index);
    setTimeout(() => {
      setSwappingLoop(false);
    }, 400);
  };

  useEffect(() => {
    return () => {
      toggle = true;
    };
  }, []);

  useEffect(() => {
    // if the callback functions ever involve state, need to list them here for the inputs
    if (audioHandler && audioHandler !== undefined) {
      audioHandler.onPlayCallback = onPlay;
      audioHandler.onPauseCallback = onPause;
      audioHandler.handleLooping = handleLooping;
    }
  }, []);

  useEffect(() => {
    return () => {
      unmounted = true;
    };
  }, [songID]);

  useEffect(() => {
    let listening;
    handleListen();
    if (playing) {
      listening = setInterval(handleListen, 250);
    }
    return () => {
      if (listening) {
        clearInterval(listening);
      }
    };
  }, [playing]);

  useEffect(() => {
    console.log('setting up audio...');
    if (
      (song.shareSlug && playerType === PLAYER_TYPES.mainResults) ||
      (song.shareSlug && playerType === PLAYER_TYPES.mobileResults)
    ) {
      setShareSlug(song.shareSlug);
    }
    if (audio) {
      audio.once('load', handleCanPlay);
      audio.once('playerror', handlePlayError);
      audio.once('loaderror', handleLoadError);
    }
    return () => {
      if (audio) {
        console.log(`deregistering audio...`);
        audio.off();
      }
    };
  }, [!!audio]);

  useEffect(() => {
    if (playerType === PLAYER_TYPES.download_bookmark) {
      if (songID !== playingSongID || expandKey !== playingSongExpandKey) {
        handleStop();
      } else {
        handlePlay();
        if (mixpanel && moengage && analyticsSentSongId !== mixpanelSongDimensions['Song ID']) {
          analyticsMixpanelPlayTrack(
            mixpanel,
            moengage,
            mixpanelSongDimensions,
            downloadContext,
            userRole,
            userId
          );
          setAnalyticsSentSongId(mixpanelSongDimensions['Song ID']);
        }
      }
    }

    if (playerType === PLAYER_TYPES.direct || isInlinePlayer) {
      if (playingSongID && songID !== playingSongID) {
        handleStop();
      }
    }

    if (
      (mainAudioCard || playerType === PLAYER_TYPES.mobileResults) &&
      previousPlayingState &&
      !viewingMicroparts &&
      mixpanel &&
      moengage &&
      analyticsSentSongId !== mixpanelSongDimensions['Song ID']
    ) {
      analyticsMixpanelPlayTrack(
        mixpanel,
        moengage,
        mixpanelSongDimensions,
        downloadContext,
        userRole,
        userId
      );
      setAnalyticsSentSongId(mixpanelSongDimensions['Song ID']);
    }
  }, [playingSongID]);

  useEffect(() => {
    if (playerType === PLAYER_TYPES.download_bookmark) {
      if (songID === playingSongID && expandKey !== playingSongExpandKey) {
        handleStop();
      }
    }
  }, [playingSongExpandKey]);

  useEffect(() => {
    if (playerType === PLAYER_TYPES.loops_main && loopsPlaying) {
      handleStop();
    }
    if (playerType === PLAYER_TYPES.loops_loop && !loopsPlaying) {
      handleStop();
    }
  }, [loopsPlaying]);

  useEffect(() => {
    if (isInlinePlayer) {
      handleStop();
    }
  }, [songID]);

  useEffect(() => {
    if (isInlinePlayer && globalPlaying && playing) {
      handleStop();
    }
  }, [globalPlaying]);

  useEffect(() => {
    if (isInlinePlayer && globalPlaying && playing) {
      console.log('should pause');
      pauseGlobalPlayback();
    }
  }, [playing]);

  return (
    <AudioPlaybackContext.Provider
      value={{
        tryingToPlay,
        loadError,
        canPlay,
        progress,
        played,
        onSeek: handleSeek,
        viewingMicroparts,
        microPartIndex,
        onMicropartSelect: handleSelectMicropart,
        duration,
        onPlayToggle: handlePlayToggle,
        songID,
        toggleMicroparts: handleToggleMicroparts,
        getAudioProgress,
        playing,
        microparts,
        onDownloadMicropart: handleDownloadMicropart,
        audio,
        playerType,
        currentIndexSong,
        audioHandler,
        swappingLoop,
        setSwappingLoop,
      }}
    >
      {children}
    </AudioPlaybackContext.Provider>
  );
};

export default AudioPlayback;
