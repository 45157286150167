// @flow
import React from 'react';
import styled from 'react-emotion';
import { useSetRecoilState } from 'recoil';
import { useAudioPlaybackContext } from '../../../../../song/components/AudioPlayback/AudioPlayback';
import { useDownloadMicropart } from '../../../../../song/hooks';
import { useSubscriptionsContext } from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS, USER_ROLES } from '../../../../../user/subscriptions/data';
import { useFreeTierRestrictionToast } from '../../../../../toasts/hooks';
import { useShowSignUp } from '../../../ModalsWrapper/ModalsWrapper';
import { useAuthContext, useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { useSong } from '../../../../../song/components/SongContextWrapper/SongContextWrapper';
import DownloadButton from '../../../../../song/components/FullSongCard/components/SongActions/components/DownloadButton/DownloadButton';
import DisabledDownloadButton from '../../../../../song/components/FullSongCard/components/SongActions/components/DisabledDownloadButton/DisabledDownloadButton';
import { RawPlayPauseButton } from '../../../../../song/components/FullSongCard/components/SongActions/components/PlayPauseButton/PlayPauseButton';
import MicropartBars from '../../../../../song/components/FullSongCard/components/MobileMicroparts/components/Micropart/component/MicropartBars/MicropartBars';
import { formatSeconds } from '../../../../../utils/time';
import {
  getSongArtist,
  getSongArtistSlug,
  getSongMixType,
  isSongFullMix,
} from '../../../../../api/algolia/song';
import { useAudioPlayerContext } from '../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import {
  analyticsMixpanelPlayMicropart,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../analytics/mixpanel';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { conversionSourceAtom } from '../../../../../store/mixpanelAnalytics';
import ArtistName from '../../../../../song/components/FullDisplaySongCard/components/ArtistName/ArtistName';

const Container = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 11px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    flex-wrap: wrap;
  }
`;

const PlayControl = styled('div')`
  width: 10%;
`;

const SongInfo = styled('div')`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 20px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 80%;
  }
`;

const Name = styled('div')`
  width: 100%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0f0f14;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 25px;
  margin-bottom: 3px;
  padding-right: 8px;
`;

const SongSubtitle = styled('div')`
  color: rgba(15, 15, 20, 0.6);
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 13px;
`;

const LeftWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    order: 1;
    flex: 1;
    width: 85%;
  }
`;

const MiddleWrapper = styled('div')`
  width: 40%;
  margin-left: 15px;
  margin-right: 9px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    order: 3;
    width: 100%;
    margin-top: 5px;
  }
`;

const ControlsWrapper = styled('div')`
  width: 10%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    order: 2;
    width: 15%;
  }
`;

const DownloadControl = styled('div')`
  width: 100%;
`;

type Props = {
  songTitle: string,
  micropartIndex: number,
  inModal?: boolean,
};

const LoopSongCard = ({ songTitle, micropartIndex, inModal }: Props) => {
  const song = useSong();
  const [download, downloadBusy] = useDownloadMicropart(song, micropartIndex);
  const {
    playing,
    microPartIndex: selectedMicroPartIndex,
    onMicropartSelect,
    onPlayToggle,
    microparts,
    duration,
    swappingLoop,
    setSwappingLoop,
  } = useAudioPlaybackContext();

  const {
    loopsPlaying,
    setAnalyticsSentMicropartPosition,
    setAnalyticsSentMicropartSongId,
  } = useAudioPlayerContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const userId = useUserId();

  const {
    userRole,
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
  } = useSubscriptionsContext();
  const { setSignUpSourceData, isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  const isPlaying =
    (playing && selectedMicroPartIndex === micropartIndex) ||
    (swappingLoop && selectedMicroPartIndex === micropartIndex);

  const playMicropartAnalytics = () => {
    analyticsMixpanelPlayMicropart(
      mixpanel,
      moengage,
      micropartIndex,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
    setAnalyticsSentMicropartSongId(mixpanelSongDimensions['Song ID']);
    setAnalyticsSentMicropartPosition(micropartIndex);
  };

  const handleToggle = () => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (selectedMicroPartIndex === micropartIndex) {
      if (!loopsPlaying) {
        setSwappingLoop(true);
        playMicropartAnalytics();
        onMicropartSelect(micropartIndex);
      } else {
        onPlayToggle();
      }
    } else {
      setSwappingLoop(true);
      playMicropartAnalytics();
      onMicropartSelect(micropartIndex);
    }
  };

  const toastUtilities = {
    subscriptions,
    openChangeSubscriptionModal,
    selectPlan,
    showSignUp,
    setConversionSource,
  };
  const freeTierRestrictionToast = useFreeTierRestrictionToast('microparts', toastUtilities);

  const handleDisabledClick = () => {
    if (USER_ROLES.noSubscriptions === userRole) {
      setSignUpSourceData({ signUpSource: 'Microparts', signUpCampaign: '' });
      showSignUp();
    } else {
      freeTierRestrictionToast();
    }
  };

  const downloadButton = (() => {
    if (USER_PERMISSIONS[userRole].canDownloadMicroparts) {
      return <DownloadButton onClick={download} busy={downloadBusy} />;
    }
    return <DisabledDownloadButton inModal={inModal} onClick={handleDisabledClick} />;
  })();
  const [beginning, end] = microparts[micropartIndex];
  const width = end - beginning;
  const partDuration = width * duration;
  const durationLabel = formatSeconds(partDuration);

  const fullTitle = isSongFullMix(song)
    ? `${songTitle}_${micropartIndex + 1}`
    : `${songTitle}_${getSongMixType(song).toLocaleLowerCase()}_${micropartIndex + 1}`.replace(
        / /g,
        '_'
      );
  const artistName = getSongArtist(song);
  const artistslug = getSongArtistSlug(song);

  return (
    <Container>
      <LeftWrapper>
        <PlayControl>
          <RawPlayPauseButton playing={isPlaying} onClick={handleToggle} noOutline />
        </PlayControl>
        <SongInfo>
          <Name>{fullTitle}</Name>
          <SongSubtitle>
            <ArtistName name={artistName} slug={artistslug} />
            {durationLabel}
          </SongSubtitle>
        </SongInfo>
      </LeftWrapper>
      <MiddleWrapper>
        <MicropartBars micropartIndex={micropartIndex} alwaysShowMicroparts />
      </MiddleWrapper>
      <ControlsWrapper>
        <DownloadControl>{downloadButton}</DownloadControl>
      </ControlsWrapper>
    </Container>
  );
};

export default LoopSongCard;
