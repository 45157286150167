// @flow
import React from 'react';
import styled from 'react-emotion';
import DownArrowIcon from '../../../../../../../../../assets/inline-assets/down-arrow-icon.svg';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { Container, SelectWrapper, Select } from '../ResultsMainPagination/ResultsMainPagination';
import { getResultsSortingMethods } from '../../../data';
import { getSortLabel } from '../../../../../../../../../cms/resultsPage';
import { useResultsPage } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useAuthContext } from '../../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useModalsContext } from '../../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useSubscriptionsContext } from '../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../../user/subscriptions/data';

type Props = {};

const SortSelect = styled(Select)`
  min-width: 150px;
`;

const SortResultsDropdown = () => {
  const { sortingMethod, handleSort, similarSongQuery } = useResultsManagerContext();
  const resultsPage = useResultsPage();
  const { sortingRestrictedOpen, showSortingRestrictedModal } = useModalsContext();
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const { userRole } = useSubscriptionsContext();

  const handleSortOptionClick = event => {
    const newSortingMethod = event.target.value;

    if (USER_PERMISSIONS[userRole].sortingPermissions.includes(newSortingMethod)) {
      handleSort(event);
    } else {
      showSortingRestrictedModal();
    }
  };

  const isSimilaritySearch = similarSongQuery ? true : false;
  const isSortingDisabled = isSimilaritySearch;

  const resultsSortingMethods = getResultsSortingMethods();

  return (
    <Container>
      {!isSimilaritySearch && (
        <SelectWrapper>
          <SortSelect
            disabled={isSortingDisabled}
            value={sortingMethod}
            onChange={handleSortOptionClick}
          >
            {Object.keys(resultsSortingMethods).map(sorting =>
              USER_PERMISSIONS[userRole].sortingPermissions.includes(sorting) ? (
                <option value={resultsSortingMethods[sorting]} key={resultsSortingMethods[sorting]}>
                  {`${getSortLabel(resultsSortingMethods[sorting], resultsPage)}`}
                </option>
              ) : (
                <option value={resultsSortingMethods[sorting]} key={resultsSortingMethods[sorting]}>
                  {`${getSortLabel(resultsSortingMethods[sorting], resultsPage)}`} &#x1f512;
                </option>
              )
            )}
          </SortSelect>
          <DownArrowIcon />
        </SelectWrapper>
      )}
    </Container>
  );
};

export default SortResultsDropdown;
