// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type SubscriptionsPageMdl = {
  data: {
    subscriptions_header: {
      text: string,
    },
    subscriptions_instruction: {
      html: string,
    },
    add_subscription_modal_title: {
      text: string,
    },
    plan_name: {
      text: string,
    },
    change_subscription_modal_title: {
      text: string,
    },
    upgrade_subscription_modal_title: {
      text: string,
    },
    downgrade_subscription_modal_title: {
      text: string,
    },
    undo_downgrade_button: {
      text: string,
    },
    undo_cancellation_button: {
      text: string,
    },
    cancellation_pending: {
      text: string,
    },
    cancellation_pending_message: {
      text: string,
    },
    downgrade_pending_message: {
      text: string,
    },
    price_change_pending_message: {
      html: string,
    },
    youtube_whitelist_message: {
      text: string,
    },
    youtube_connection_info: {
      html: string,
    },
    youtube_connection_help_title: {
      text: string,
    },
    youtube_connection_help_description: {
      html: string,
    },
    youtube_connection_wrong_format_title: {
      text: string,
    },
    youtube_connection_wrong_format_description: {
      html: string,
    },
    youtube_connection_tooltip: {
      html: string,
    },
    youtube_connection_tooltip_action: {
      html: string,
    },
    youtube_connection_channel_details: {
      text: string,
    },
    free_trial_badge: {
      text: string,
    },
    free_trial_whitelisting_info: {
      text: string,
    },
    end_trial_prompt: {
      text: string,
    },
    free_trial_yt_connection_restriction: {
      text: string,
    },
    free_trial_yt_connection_change_restriction: {
      text: string,
    },
    free_trial_cancel_pending_message: {
      text: string,
    },
    plan_price_info: {
      text: string,
    },
    plan_revenue_share: {
      text: string,
    },
    manual_plans_title: {
      text: string,
    },
    manual_plans_instruction: {
      text: string,
    },
    plan_broadcast_title: {
      text: string,
    },
    plan_broadcast_message: {
      html: string,
    },
    plan_agency_title: {
      text: string,
    },
    plan_agency_message: {
      html: string,
    },
    contact_message: {
      html: string,
    },
    plan_creator_price: {
      text: string,
    },
    show_promotional_labels: boolean,
    promotional_valid_plans: {
      text: string,
    },
    promotional_valid_intervals: {
      text: string,
    },
    promotional_default_interval: {
      text: string,
    },
    promotional_label_new_plan: {
      html: string,
    },
    promotional_label_upgrade_plan: {
      html: string,
    },
    promotional_label_interval_change: {
      html: string,
    },
    add_subscription_button: {
      text: string,
    },
  },
};

export const getSubscriptionsYouTubeAllowlistMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_whitelist_message.text', '') || '';
};

export const getSubscriptionsDowngradePendingMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.downgrade_pending_message.text', '') || '';
};

export const getSubscriptionsCancellationPendingMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.cancellation_pending_message.text', '') || '';
};

export const getSubscriptionsPriceChangePendingMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.price_change_pending_message.html', '') || '';
};

export const getSubscriptionsUndoCancellationButton = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.undo_cancellation_button.text', '') || '';
};

export const getSubscriptionsUndoDowngradeButton = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.undo_downgrade_button.text', '') || '';
};

export const getSubscriptionsHeading = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.subscriptions_header.text', '') || '';
};

export const getSubscriptionsInstructions = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.subscriptions_instruction.html', '') || '';
};

export const getSubscriptionPlanName = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_name.text', '') || '';
};

export const getAddSubscriptionModalTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.add_subscription_modal_title.text', '') || '';
};

export const getChangeSubscriptionModalTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.change_subscription_modal_title.text', '') || '';
};

export const getUpgradeSubscriptionModalTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.upgrade_subscription_modal_title.text', '') || '';
};

export const getDowngradeSubscriptionModalTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.downgrade_subscription_modal_title.text', '') || '';
};

export const getYouTubeConnectionInfo = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_info.html', '') || '';
};

export const getYouTubeConnectionHelpTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_help_title.text', '') || '';
};

export const getYouTubeConnectionHelpDescription = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_help_description.html', '') || '';
};

export const getYouTubeConnectionWrongFormatTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_wrong_format_title.text', '') || '';
};

export const getYouTubeConnectionWrongFormatDescription = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_wrong_format_description.html', '') || '';
};

export const getYouTubeConnectionTooltip = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_tooltip.html', '') || '';
};

export const getYouTubeConnectionTooltipAction = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_tooltip_action.html', '') || '';
};

export const getYouTubeConnectionChannelDetails = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.youtube_connection_channel_details.text', '') || '';
};

export const getSubscriptionsFreeTrialBadge = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.free_trial_badge.text', '') || '';
};

export const getSubscriptionsFreeTrialAllowlistingInfo = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.free_trial_whitelisting_info.text', '') || '';
};

export const getSubscriptionsEndTrialPrompt = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.end_trial_prompt.text', '') || '';
};

export const getSubscriptionsFreeTrialYTConnectionRestriction = (
  page: SubscriptionsPageMdl
): string => {
  return get(page, 'data.free_trial_yt_connection_restriction.text', '') || '';
};

export const getSubscriptionsFreeTrialYTConnectionChangeRestriction = (
  page: SubscriptionsPageMdl
): string => {
  return get(page, 'data.free_trial_yt_connection_change_restriction.text', '') || '';
};

export const getSubscriptionsFreeTrialCancelPendingMessage = (
  page: SubscriptionsPageMdl
): string => {
  return get(page, 'data.free_trial_cancel_pending_message.text', '') || '';
};

export const getPlanPriceInfo = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_price_info.text', '') || '';
};

export const getPlanRevenueShare = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_revenue_share.text', '') || '';
};

export const getManualPlansTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.manual_plans_title.text', '') || '';
};

export const getManualPlansInstructions = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.manual_plans_instruction.text', '') || '';
};

export const getPlanBroadcastTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_broadcast_title.text', '') || '';
};

export const getPlanBroadcastMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_broadcast_message.html', '') || '';
};

export const getPlanAgencyTitle = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_agency_title.text', '') || '';
};

export const getPlanAgencyMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_agency_message.html', '') || '';
};

export const getContactMessage = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.contact_message.html', '') || '';
};

export const getCreatorPlanFreeLabel = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.plan_creator_price.text', '') || '';
};

export const getSubscriptionsPageShowPromotionalLabels = (copy: SubscriptionsPageMdl): boolean => {
  return get(copy, 'data.show_promotional_labels', false);
};

export const getSubscriptionsPagePromotionalValidPlans = (copy: SubscriptionsPageMdl): string => {
  return get(copy, 'data.promotional_valid_plans.text', '');
};

export const getSubscriptionPageAddSubscriptionButton = (copy: SubscriptionsPageMdl): string => {
  return get(copy, 'data.add_subscription_button.text', '') || '';
};

export const getSubscriptionsPagePromotionalValidIntervals = (
  copy: SubscriptionsPageMdl
): string => {
  return get(copy, 'data.promotional_valid_intervals.text', '');
};

export const getSubscriptionsPagePromotionalDefaultInterval = (
  copy: SubscriptionsPageMdl
): string => {
  if (!getSubscriptionsPageShowPromotionalLabels(copy)) return '';
  return get(copy, 'data.promotional_default_interval.text', '');
};

export const isPromotionAvailable = (
  copy: SubscriptionsPageMdl,
  planCode: string,
  planInterval: string
): boolean => {
  const showPromotionalLabel = getSubscriptionsPageShowPromotionalLabels(copy);

  if (!showPromotionalLabel) {
    return false;
  }

  const validPromotionalPlans = getSubscriptionsPagePromotionalValidPlans(copy).split(', ');
  const validPromotionalIntervals = getSubscriptionsPagePromotionalValidIntervals(copy).split(', ');

  const planIsPromotionAllowed = validPromotionalPlans.includes(planCode);
  const intervalIsPromotionAllowed = validPromotionalIntervals.includes(planInterval);

  return planIsPromotionAllowed && intervalIsPromotionAllowed;
};

export const getSubscriptionsPagePromotionalLabelNewPlan = (page: SubscriptionsPageMdl): string => {
  return get(page, 'data.promotional_label_new_plan.html', '') || '';
};

export const getSubscriptionsPagePromotionalLabelUpgradePlan = (
  page: SubscriptionsPageMdl
): string => {
  return get(page, 'data.promotional_label_upgrade_plan.html', '') || '';
};

export const getSubscriptionsPagePromotionalLabelIntervalChange = (
  page: SubscriptionsPageMdl
): string => {
  return get(page, 'data.promotional_label_interval_change.html', '') || '';
};

export const query = graphql`
  fragment SubscriptionsPage on PrismicSubscriptionsPage {
    data {
      subscriptions_header {
        text
      }
      subscriptions_instruction {
        html
      }
      add_subscription_modal_title {
        text
      }
      plan_name {
        text
      }
      change_subscription_modal_title {
        text
      }
      upgrade_subscription_modal_title {
        text
      }
      downgrade_subscription_modal_title {
        text
      }
      undo_downgrade_button {
        text
      }
      undo_cancellation_button {
        text
      }
      cancellation_pending {
        text
      }
      cancellation_pending_message {
        text
      }
      downgrade_pending_message {
        text
      }
      price_change_pending_message {
        html
      }
      youtube_whitelist_message {
        text
      }
      youtube_connection_info {
        html
      }
      youtube_connection_help_title {
        text
      }
      youtube_connection_help_description {
        html
      }
      youtube_connection_wrong_format_title {
        text
      }
      youtube_connection_wrong_format_description {
        html
      }
      youtube_connection_tooltip {
        html
      }
      youtube_connection_tooltip_action {
        html
      }
      youtube_connection_channel_details {
        text
      }
      free_trial_badge {
        text
      }
      free_trial_whitelisting_info {
        text
      }
      end_trial_prompt {
        text
      }
      free_trial_yt_connection_restriction {
        text
      }
      free_trial_yt_connection_change_restriction {
        text
      }
      free_trial_cancel_pending_message {
        text
      }
      plan_price_info {
        text
      }
      plan_revenue_share {
        text
      }
      manual_plans_title {
        text
      }
      manual_plans_instruction {
        text
      }
      plan_broadcast_title {
        text
      }
      plan_broadcast_message {
        html
      }
      plan_agency_title {
        text
      }
      plan_agency_message {
        html
      }
      contact_message {
        html
      }
      plan_creator_price {
        text
      }
      show_promotional_labels
      promotional_valid_plans {
        text
      }
      promotional_valid_intervals {
        text
      }
      promotional_default_interval {
        text
      }
      promotional_label_new_plan {
        html
      }
      promotional_label_upgrade_plan {
        html
      }
      promotional_label_interval_change {
        html
      }
      add_subscription_button {
        text
      }
    }
  }
`;
