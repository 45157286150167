// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SortingRestrictedModalGraphMdl = {
  data: {
    heading: {
      text: string,
    },
    add_plan_instructions: {
      text: string,
    },
    upgrade_plan_instructions: {
      text: string,
    },
    similar_search_restriction_add: {
      text: string,
    },
    similar_search_restriction_upgrade: {
      text: string,
    },
    custom_order_add_plan_instructions: {
      text: string,
    },
    custom_order_upgrade_plan_instructions: {
      text: string,
    },
  },
};

export const getSortingRestrictedModalHeading = (copy: SortingRestrictedModalGraphMdl): string => {
  return get(copy, 'data.heading.text', '') || '';
};

export const getSortingRestrictedModalAddPlanInstructions = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.add_plan_instructions.text', '') || '';
};

export const getSortingRestrictedModalUpgradePlanInstructions = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.upgrade_plan_instructions.text', '') || '';
};

export const getSortingRestrictedModalSimilarRestrictionAdd = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.similar_search_restriction_add.text', '') || '';
};

export const getSortingRestrictedModalSimilarRestrictionUpgrade = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.similar_search_restriction_upgrade.text', '') || '';
};

export const getCustomOrderRestrictionAddPlanInstructions = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.custom_order_add_plan_instructions.text', '') || '';
};

export const getCustomOrderRestrictionUpgradePlanInstructions = (
  copy: SortingRestrictedModalGraphMdl
): string => {
  return get(copy, 'data.custom_order_upgrade_plan_instructions.text', '') || '';
};

export const query = graphql`
  fragment SortingRestrictedModal on PrismicSortingRestrictedModal {
    data {
      heading {
        text
      }
      add_plan_instructions {
        text
      }
      upgrade_plan_instructions {
        text
      }
      similar_search_restriction_add {
        text
      }
      similar_search_restriction_upgrade {
        text
      }
      custom_order_add_plan_instructions {
        text
      }
      custom_order_upgrade_plan_instructions {
        text
      }
    }
  }
`;
