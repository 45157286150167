// @flow
import React, { useState, useEffect, useContext } from 'react';

export type KeyboardShortcutContextState = {
  muted: boolean,
  handleMute: () => void,
  volume: number,
  handleVolumeChange: number => void,
};

type Props = {
  children: any,
};

export const KeyboardShortcutContext = React.createContext();

export const useKeyboardShortcutContext = (): KeyboardShortcutContextState =>
  useContext(KeyboardShortcutContext);

const KeyboardShortcutWrapper = ({ children }: Props) => {
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);

  const handleMute = () => {
    setMuted(!muted);
  };

  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  return (
    <KeyboardShortcutContext.Provider
      value={{
        muted,
        handleMute,
        volume,
        handleVolumeChange,
      }}
    >
      {children}
    </KeyboardShortcutContext.Provider>
  );
};

export default KeyboardShortcutWrapper;
