// @flow
import React from 'react';
import parse from 'html-react-parser';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  useMiscCopy,
  useAllowlistStatusesCopy,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { SubscriptionModalFooter } from '../CancelSubscriptionModal/CancelSubscriptionModal';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { getMiscDoneButton, getMiscContactSupportButton } from '../../../../cms/miscCopy';
import {
  getAllowlistingUnsuccessfulModalTitle,
  getAllowlistingUnsuccessfulModalDescription,
} from '../../../../cms/allowlistingStatuses';

type Props = {
  deniedChannels: Array<string>,
  onClose: () => void,
  onConfirm: () => void,
};

const styledLink = css`
  a {
    text-decoration: none;
  }
`;

const AllowlistUnsuccessfulModal = ({ deniedChannels, onClose, onConfirm }: Props) => {
  const allowlistCopy = useAllowlistStatusesCopy();
  const miscCopy = useMiscCopy();
  const handleClose = () => {
    onClose();
  };

  const unsuccesChannalsList = getAllowlistingUnsuccessfulModalDescription(allowlistCopy).replace(
    '$CHANNEL_NAME$',
    deniedChannels.join(' - ')
  );

  return (
    <ClassicModalLayout
      label={getAllowlistingUnsuccessfulModalTitle(allowlistCopy)}
      onClose={handleClose}
      fullSized={false}
    >
      <ClassicModalContentLayout
        heading={getAllowlistingUnsuccessfulModalTitle(allowlistCopy)}
        description={unsuccesChannalsList}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{getMiscDoneButton(miscCopy)}</DarkButton>
            <Button className={styledLink}>{parse(getMiscContactSupportButton(miscCopy))}</Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default AllowlistUnsuccessfulModal;
