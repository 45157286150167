// @flow
import React from 'react';
import styled, { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';
import { ICONS, IMAGES } from '../../../../../../images/paths';

const Container = styled('div')`
  margin-top: 80px;
`;

const Header = styled('header')`
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 35px;
`;

const CreditCards = styled('div')`
  width: 80%;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CreditCard = styled('div')`
  display: inline-flex;
  vertical-align: middle;
  padding: 10px 5px;
  margin: auto 10px;
`;

const visaCardCss = css`
  width: 35px;
`;

const masterCardCss = css`
  width: 35px;
`;

const jcbCardCss = css`
  width: 30px;
`;

const americanExpressCardCss = css`
  width: 30px;
`;

const dinersClubCardCss = css`
  width: 55px;
`;

const PricingCreditCards = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('pricing.creditCards.header')}</Header>
      <CreditCards>
        <CreditCard>
          <img className={visaCardCss} src={IMAGES.visa} />
        </CreditCard>
        <CreditCard>
          <img className={masterCardCss} src={ICONS.masterCard} />
        </CreditCard>
        <CreditCard>
          <img className={jcbCardCss} src={ICONS.jcbCard} />
        </CreditCard>
        <CreditCard>
          <img className={americanExpressCardCss} src={IMAGES.americanExpress} />
        </CreditCard>
        <CreditCard>
          <img className={dinersClubCardCss} src={ICONS.dinersClub} />
        </CreditCard>
      </CreditCards>
    </Container>
  );
};

export default PricingCreditCards;
