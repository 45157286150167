// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type TrialCounterMenuGraphMdl = {
  data: {
    trial_counter_menu_title: {
      text: string,
    },
    trial_remaining_less_than_day: {
      html: string,
    },
    trial_remaining_day_info: {
      html: string,
    },
    trial_remaining_days_info: {
      html: string,
    },
    whitelisting_date_notice: {
      text: string,
    },
    trial_cancelled_date_notice: {
      text: string,
    },
    end_trial_early_prompt: {
      text: string,
    },
  },
};

export const getTrialCounterMenuTitle = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.trial_counter_menu_title.text', '') || '';
};

export const getTrialCounterRemainingLessThanDayInfo = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.trial_remaining_less_than_day.html', '') || '';
};

export const getTrialCounterRemainingDayInfo = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.trial_remaining_day_info.html', '') || '';
};

export const getTrialCounterRemainingDaysInfo = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.trial_remaining_days_info.html', '') || '';
};

export const getTrialCounterAllowlistingDateNotice = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.whitelisting_date_notice.text', '') || '';
};

export const getTrialCounterTrialCancelledDateNotice = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.trial_cancelled_date_notice.text', '') || '';
};

export const getTrialCounterEndTrialEarlyPrompt = (copy: TrialCounterMenuGraphMdl): string => {
  return get(copy, 'data.end_trial_early_prompt.text', '') || '';
};

export const query = graphql`
  fragment TrialCounterMenu on PrismicTrialCounterMenu {
    data {
      trial_counter_menu_title {
        text
      }
      trial_remaining_less_than_day {
        html
      }
      trial_remaining_day_info {
        html
      }
      trial_remaining_days_info {
        html
      }
      whitelisting_date_notice {
        text
      }
      trial_cancelled_date_notice {
        text
      }
      end_trial_early_prompt {
        text
      }
    }
  }
`;
