// @flow
import React from 'react';
import styled from 'react-emotion';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../../../../../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../../../../../cms/curatedCollection';
import { useMiscCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { isMobileDeviceSize } from '../../../../../../../utils/device';
import { useNavigate } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import PreviewTile from '../../../BrowseView/components/PreviewTile';
import PreviewTileRow from '../../../BrowseView/components/PreviewTileRow';
import { getMiscCopyCollections } from '../../../../../../../cms/miscCopy';

const Container = styled('article')`
  margin: 150px auto 0px auto;
  max-width: 800px;

  ${MEDIA_BREAKPOINTS.max1260} {
    max-width: 800px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: unset;
    width: 100%;
    padding: 0px 40px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 70px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 0px 20px;
    margin-top: 50px;
  }
`;

type Props = {
  collections: Array<CuratedCollectionGraphMdl>,
  artistSlug: string,
};

const ArtistCollections = ({ collections, artistSlug }: Props) => {
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const miscCopy = useMiscCopy();
  const userId = useUserId();
  const navigate = useNavigate();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'artistCollection', collectionSlug);
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'artistCollection', '', artistSlug);
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  return (
    <Container>
      {collections.length > 0 && (
        <PreviewTileRow title={getMiscCopyCollections(miscCopy)} columnTitle rows={1} showAll>
          {collections.map(collection => {
            const slug = getCuratedCollectionId(collection);
            return (
              <PreviewTile
                key={slug}
                title={getCuratedCollectionTitle(collection)}
                slug={slug}
                bgImage={getCuratedCollectionImage(collection)}
                listType="collection"
                previewTile={() => {
                  analyticsMixpanelPreviewSongs(
                    mixpanel,
                    moengage,
                    'Collection',
                    slug,
                    'artist_collections',
                    userId
                  );
                  handlePreviewCollection(collection);
                }}
                navigateTile={() => {
                  navigateCollection(slug);
                }}
              />
            );
          })}
        </PreviewTileRow>
      )}
    </Container>
  );
};

export default ArtistCollections;
