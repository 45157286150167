// @flow
import React from 'react';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import {
  useMiscCopy,
  useSubscriptionRequiredModalCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getSubscriptionRequiredModalAddButton,
  getSubscriptionRequiredModalHeading,
  getSubscriptionRequiredModalInstructions,
} from '../../../cms/subscriptionRequiredModal';
import Button from '../../../components/Button/Button';
import { SubscriptionModalFooter } from '../../../user/subscriptions/components/CancelSubscriptionModal/CancelSubscriptionModal';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../../user/subscriptions/data';

const addButtonClass = css`
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const SubscriptionsRequiredModal = ({ onClose }: Props) => {
  const { selectPlan } = useSubscriptionsContext();
  const copy = useSubscriptionRequiredModalCopy();
  const handleAdd = () => {
    selectPlan(SUBSCRIPTIONS.creatorPro.key);
    onClose();
  };
  return (
    <ClassicModalLayout onClose={onClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={getSubscriptionRequiredModalHeading(copy)}
        description={getSubscriptionRequiredModalInstructions(copy)}
        footer={
          <SubscriptionModalFooter>
            <Button className={addButtonClass} onClick={handleAdd}>
              {getSubscriptionRequiredModalAddButton(copy)}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default SubscriptionsRequiredModal;
