// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { useRecoilValue } from 'recoil';
import { findIndex } from 'lodash';
import type { AlgoliaSearchResults } from '../../../../api/algolia/search';
import { searchAlgolia } from '../../../../api/algolia/search';
import { useLocale } from '../../../components/LocaleWrapper/LocaleWrapper';
import {
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../styles/responsive';
import ExpandableDisplaySongCard from '../../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import BrowseSongsManager from '../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import LoadingDisplay from '../../../../components/LoadingDisplay/LoadingDisplay';
import { getSongID } from '../../../../api/algolia/song';
import { createSongIDFilterQuery } from '../../../../utils/algolia';
import {
  colelctionKeywordsSearchedAtom,
  colelctionMixpanelKeywordsSearchedAtom,
  collectionMagicKeywordsSearchedAtom,
  collectionSectionOriginAtom,
  collectionYoutubeKeywordsSearchedAtom,
  collectionYoutubeVideoTitleSearchedAtom,
  relatedCollectionSearchedAtom,
} from '../../../../store/mixpanelAnalytics';
import TabbedSongList from '../../../../layouts/components/TabbedSongList/TabbedSongList';
import TagFiltersList from '../../../../components/TagFiltersList/TagFiltersList';
import {
  getTagFiltersData,
  getUpdatedActiveFacetFilters,
  getTagFilterQuery,
  getUpdatedRemovedFacetFilters,
} from '../../../../utils/tagFilters';
import {
  useCollectionsPage,
  useMiscCopy,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getCollectionsPageTagFilterCategories } from '../../../../cms/collectionsPage';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelApplyTagFilter } from '../../../../analytics/mixpanel';

type Props = {
  songIDs: Array<string>,
  title?: string,
  slug?: string,
};

const LoadingWrapper = styled('div')`
  height: 30vh;
`;

const CollectionSongs = ({ songIDs, title = '', slug = '' }: Props) => {
  const [collectionSongs, setCollectionSongs] = useState([]);
  const [songFacets, setSongFacets] = useState([]);
  const [allFacetData, setAllFacetData] = useState({});
  const [selectedFacets, setSelectedFacets] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const locale = useLocale();
  const miscCopy = useMiscCopy();
  const collectionsPage = useCollectionsPage();

  const colelctionKeywordsSearched = useRecoilValue(colelctionKeywordsSearchedAtom);
  const colelctionMixpanelKeywordsSearched = useRecoilValue(colelctionMixpanelKeywordsSearchedAtom);
  const collectionMagicKeywordsSearched = useRecoilValue(collectionMagicKeywordsSearchedAtom);
  const collectionYoutubeKeywordsSearched = useRecoilValue(collectionYoutubeKeywordsSearchedAtom);
  const collectionYoutubeVideoTitleSearched = useRecoilValue(
    collectionYoutubeVideoTitleSearchedAtom
  );
  const collectionSectionOrigin = useRecoilValue(collectionSectionOriginAtom);
  const relatedCollectionSearched = useRecoilValue(relatedCollectionSearchedAtom);

  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const filterCategories = getCollectionsPageTagFilterCategories(collectionsPage);

  useEffect(() => {
    setLoading(true);
    const songIdFilter = createSongIDFilterQuery(songIDs);
    const tagFilters = getTagFilterQuery(selectedFacets);
    const filterQuery = tagFilters ? `(${songIdFilter}) AND `.concat(tagFilters) : songIdFilter;

    if (songIDs.length > 0) {
      searchAlgolia('', locale, [], filterQuery)
        .then((response: AlgoliaSearchResults) => {
          const sortedHits = response.hits.sort((a, b) => {
            const indexA = findIndex(songIDs, id => id === getSongID(a));
            const indexB = findIndex(songIDs, id => id === getSongID(b));
            return indexA - indexB;
          });

          setCollectionSongs(sortedHits);
          const completeFacets =
            allFacetData.slug === slug ? allFacetData.facetData : response.facets;
          const currentFacets = getTagFiltersData(
            response.facets,
            completeFacets,
            filterCategories,
            selectedFacets,
            miscCopy
          );
          setAllFacetData({ facetData: completeFacets, slug });
          setSongFacets(currentFacets);
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    } else {
      setLoading(false);
      setLoaded(true);
    }
  }, [slug, selectedFacets]);

  const handleSelectFilterTag = (filterKey: string, filterValue: string) => {
    const updatedSelectedFilters = getUpdatedActiveFacetFilters(
      filterKey,
      filterValue,
      selectedFacets
    );
    setSelectedFacets(updatedSelectedFilters);
    analyticsMixpanelApplyTagFilter(mixpanel, moengage, filterKey, filterValue, slug);
  };

  const handleRemoveFiterTag = (filterKey: string, filterValue: string) => {
    const updatedSelectedFilters = getUpdatedRemovedFacetFilters(
      filterKey,
      filterValue,
      selectedFacets
    );
    setSelectedFacets(updatedSelectedFilters);
  };

  const handleClearFilterTags = ev => {
    ev.stopPropagation();
    setSelectedFacets({});
  };

  const hasFacets = songFacets.length > 0;

  return (
    <>
      {hasFacets && (
        <TagFiltersList
          slug={slug}
          facets={songFacets}
          handleAddFilter={handleSelectFilterTag}
          handleRemoveFilter={handleRemoveFiterTag}
          handleClearFilters={handleClearFilterTags}
        />
      )}
      {loaded && !loading ? (
        <TabbedSongList
          songs={collectionSongs}
          songListType="collection"
          songListTitle={title}
          songListSlug={slug}
          songlistKeywords={[]}
          keywords={colelctionKeywordsSearched}
          mixpanelKeywords={colelctionMixpanelKeywordsSearched}
          magicKeywords={collectionMagicKeywordsSearched}
          youtubeKeywords={collectionYoutubeKeywordsSearched}
          youtubeVideoTitle={collectionYoutubeVideoTitleSearched}
          sectionOrigin={collectionSectionOrigin}
          relatedCollection={relatedCollectionSearched}
        />
      ) : (
        <LoadingWrapper>
          <LoadingDisplay />
        </LoadingWrapper>
      )}
    </>
  );
};

export default CollectionSongs;
