// @flow
import { css } from 'emotion';
import { transparentize } from 'polished';
import { darkColor, lightColor } from '../config/colors';

export const bodyFontCss = css`
  font-family: 'Roboto', sans-serif;
`;

export const monoFontCss = css`
  font-family: 'Roboto Mono', 'Roboto', sans-serif;
`;

export const lightFontWeightCss = css`
  font-weight: 300;
`;

export const regularFontWeightCss = css`
  font-weight: 400;
`;

export const mediumFontWeightCss = css`
  font-weight: 500;
`;

export const boldFontWeightCss = css`
  font-weight: 700;
`;

export const lightColorCss = css`
  color: ${lightColor};
`;

export const smallMonoCss = css`
  ${monoFontCss};
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 18px;
`;

export const smallMediumFontSizeCss = css`
  font-size: 15px;
  line-height: 25px;
`;

export const bodyTextCss = css`
  ${bodyFontCss};
  ${smallMediumFontSizeCss};
  letter-spacing: 0.5px;
`;

export const largeFontSizeCss = css`
  font-size: 30px;
  line-height: 35px;
`;

export const mediumFontSizeCss = css`
  font-size: 16px;
  letter-spacing: 0.5px;
`;

export const smallFontSizeCss = css`
  font-size: 12px;
  line-height: 18px;
`;

export const monoNavCss = css`
  ${monoFontCss};
  ${boldFontWeightCss};
  ${smallFontSizeCss};
  letter-spacing: 2px;
  line-height: 20px;
`;

export const buttonTextCss = css`
  ${monoFontCss};
  ${boldFontWeightCss};
  ${smallFontSizeCss};
  color: ${lightColor};
  letter-spacing: 2px;
  line-height: 20px;
`;

export const smallPlainTextCss = css`
  ${bodyFontCss};
  ${smallFontSizeCss};
  letter-spacing: 0.5px;
`;

export const monoSmallPlainTextCss = css`
  ${smallPlainTextCss};
  ${monoFontCss};
`;

export const plainTextCss = css`
  ${smallPlainTextCss};
  color: ${darkColor};
`;
export const smallHeadingHoverStateCss = css`
  color: ${darkColor};
  text-decoration: underline;
`;

export const smallHeadingActiveStateCss = css`
  color: ${transparentize(0.2, darkColor)};
`;

export const interactiveLinkCss = css`
  &:focus,
  &:hover {
    color: ${darkColor};
    text-decoration: underline;
    outline: none;
  }

  &:active {
    color: ${transparentize(0.2, darkColor)};
  }
`;

export const smallMediumTextCss = css`
  ${mediumFontWeightCss};
  ${smallMediumFontSizeCss};
  letter-spacing: 0.75px;
`;

export const smallMonoBoldCss = css`
  ${smallMonoCss};
  ${boldFontWeightCss};
  color: rgba(15, 15, 20, 0.6);
  letter-spacing: 2px;
`;

export const smallPlainTextMonoCss = css`
  ${smallPlainTextCss};
  ${monoFontCss};
`;

export const smallMonoCondensedCss = css`
  ${smallMonoCss};
  ${boldFontWeightCss};
  letter-spacing: 2px;
  line-height: 13px;
`;

export const smallMediumPlusTextCss = css`
  font-size: 16px;
  letter-spacing: 0.5px;
`;

export const hugeTextCss = css`
  font-weight: 300;
  font-size: 50px;
  line-height: 45px;
`;

export const mediumHeadingCss = css`
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.5px;
`;

export const largeTextCss = css`
  ${bodyFontCss};
  font-weight: 300;
  font-size: 30px;
  line-height: 45px;
`;
