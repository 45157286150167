// @flow
import React from 'react';
import FeaturedInstruments from '../BrowseView/components/FeaturedInstruments';
import TagList from '../GenresView/components/TagList/TagList';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';

type Props = {
  path: string,
};

const InstrumentsView = ({ path }: Props) => {
  return (
    <BrowseLayout path={path}>
      <FeaturedInstruments viewAll oversizedTile page="instrumentsPage" rows={2} />
      <TagList tagCategory="instruments" />
    </BrowseLayout>
  );
};

export default InstrumentsView;
