// @flow
import React from 'react';
import styled from 'react-emotion';
import { useTranslation } from 'react-i18next';
import PlanCheckedIcon from '../../../../../../assets/inline-assets/plan-checked-icon.svg';
import { MEDIA_BREAKPOINTS } from '../../../../../../styles/responsive';

const TableSection = styled('div')`
  margin: 80px auto 100px auto;
  width: 80%;

  ${MEDIA_BREAKPOINTS.max1260} {
    width: 100%;
  }
`;

const Table = styled('table')`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled('thead')`
  background-color: #4a90e2;
  color: #fff;
  font-size: 1.2em;

  th {
    border: 1px solid #ddd;
    padding: 12px;
    font-weight: 700;
  }
`;

const TableBody = styled('tbody')`
  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    vertical-align: middle;

    svg {
      width: 8%;
      min-width: 15px;
    }
  }

  td:has(svg) {
    line-height: 15px;
  }

  td:first-of-type {
    text-align: start;
    width: 20%;
    min-width: 107px;
  }
`;

const PricingPlanTable = () => {
  const { t } = useTranslation();
  return (
    <TableSection>
      <Table>
        <TableHeader>
          <tr>
            <th>{t('pricing.plans.table.title.content', '')}</th>
            <th>{t('general.planCode.loyalMember', '')}</th>
            <th>{t('general.planCode.loyalMemberPlus', '')}</th>
            <th>{t('general.planCode.enterprise', '')}</th>
          </tr>
        </TableHeader>
        <TableBody>
          <tr>
            <td>{t('pricing.plans.table.content.title1', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title2', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title3', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title4', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title5', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title6', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title7', '')}</td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title8', '')}</td>
            <td></td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>{t('pricing.plans.table.content.title9', '')}</td>
            <td></td>
            <td>
              <PlanCheckedIcon />
            </td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://www.amadeuscode.com/musictga-hr/ja">
                {t('pricing.plans.table.content.title10', '')}
              </a>
            </td>
            <td></td>
            <td></td>
            <td>
              <PlanCheckedIcon />
            </td>
          </tr>
        </TableBody>
      </Table>
    </TableSection>
  );
};

export default PricingPlanTable;
