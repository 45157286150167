// @flow
import algoliasearch from 'algoliasearch';
import { LOCALES } from '../../locales';
import { isProdEnv, isSearchIndexSwitcherEnabled } from '../../utils/env';

export const algoliaClient = algoliasearch('PYJRQ4Y6Q9', '0ed5b22f084e16a81ac328b471c8485d');

const enSongsIndex = algoliaClient.initIndex('evoke_songs');
const jaSongsIndex = algoliaClient.initIndex('evoke_songs_ja');
const cnSongsIndex = algoliaClient.initIndex('evoke_songs_cn');
const enStgSongsIndex = algoliaClient.initIndex('evoke_songs_stg');
const jaStgSongsIndex = algoliaClient.initIndex('evoke_songs_ja_stg');
const cnStgSongsIndex = algoliaClient.initIndex('evoke_songs_cn_stg');

const enKeywordsIndex = algoliaClient.initIndex('evoke_keywords');
const jaKeywordsIndex = algoliaClient.initIndex('evoke_keywords_ja');
const cnKeywordsIndex = algoliaClient.initIndex('evoke_keywords_cn');
const enStgKeywordsIndex = algoliaClient.initIndex('evoke_keywords_stg');
const jaStgKeywordsIndex = algoliaClient.initIndex('evoke_keywords_ja_stg');
const cnStgKeywordsIndex = algoliaClient.initIndex('evoke_keywords_cn_stg');

const enCollectionsIndex = algoliaClient.initIndex('evoke_collections');
const jaCollectionsIndex = algoliaClient.initIndex('evoke_collections_ja');
const cnCollectionsIndex = algoliaClient.initIndex('evoke_collections_cn');
const enStgCollectionsIndex = algoliaClient.initIndex('evoke_collections_stg');
const jaStgCollectionsIndex = algoliaClient.initIndex('evoke_collections_ja_stg');
const cnStgCollectionsIndex = algoliaClient.initIndex('evoke_collections_cn_stg');

export const getAlgoliaIndex = (locale: string) => {
  // Allow us to test alternate search indices
  if (isProdEnv()) {
    // const indexToUse = locale === LOCALES.japanese.code ? jaSongsIndex : enSongsIndex;
    const indexToUse =
      locale === LOCALES.japanese.code
        ? jaSongsIndex
        : locale === LOCALES.chinese.code
        ? cnSongsIndex
        : enSongsIndex;
    return indexToUse;
  }
  // const indexToUse = locale === LOCALES.japanese.code ? jaStgSongsIndex : enStgSongsIndex;
  const indexToUse =
    locale === LOCALES.japanese.code
      ? jaStgSongsIndex
      : locale === LOCALES.chinese.code
      ? cnStgSongsIndex
      : enStgSongsIndex;
  return indexToUse;
};

export const getAlgoliaKeywordsIndex = (locale: string) => {
  if (isProdEnv()) {
    const indexToUse =
      locale === LOCALES.japanese.code
        ? jaKeywordsIndex
        : locale === LOCALES.chinese.code
        ? cnKeywordsIndex
        : enKeywordsIndex;
    return indexToUse;
  }
  const indexToUse =
    locale === LOCALES.japanese.code
      ? jaStgKeywordsIndex
      : locale === LOCALES.chinese.code
      ? cnStgKeywordsIndex
      : enStgKeywordsIndex;
  return indexToUse;
};

export const getAlgoliaCollectionsIndex = (locale: string) => {
  if (isProdEnv()) {
    const indexToUse =
      locale === LOCALES.japanese.code
        ? jaCollectionsIndex
        : locale === LOCALES.chinese.code
        ? cnCollectionsIndex
        : enCollectionsIndex;
    return indexToUse;
  }
  const indexToUse =
    locale === LOCALES.japanese.code
      ? jaStgCollectionsIndex
      : locale === LOCALES.chinese.code
      ? cnStgCollectionsIndex
      : enStgCollectionsIndex;
  return indexToUse;
};

export const getAlgoliaKeywordsIndexName = (locale: string) => {
  if (isProdEnv()) {
    // const indexToUse = locale === LOCALES.japanese.code ? 'evoke_keywords_ja' : 'evoke_keywords';
    const indexToUse =
      locale === LOCALES.japanese.code
        ? 'evoke_keywords_ja'
        : locale === LOCALES.chinese.code
        ? 'evoke_keywords_cn'
        : 'evoke_keywords';
    return indexToUse;
  }
  // const indexToUse =
  //   locale === LOCALES.japanese.code ? 'evoke_keywords_ja_stg' : 'evoke_keywords_stg';
  const indexToUse =
    locale === LOCALES.japanese.code
      ? 'evoke_keywords_ja_stg'
      : locale === LOCALES.chinese.code
      ? 'evoke_keywords_cn_stg'
      : 'evoke_keywords_stg';
  return indexToUse;
};
