// @flow
import React from 'react';
import styled from 'react-emotion';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { useLocale } from '../../../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import {
  algoliaClient,
  getAlgoliaKeywordsIndexName,
} from '../../../../../../../api/algolia/client';
import KeywordAutocomplete from './KeywordAutocomplete';
import { isAlgoliaAnalyticsEnabled } from '../../../../../../../utils/env';
import { useMusicSearchBarContext } from '../../MusicSearchBarWrapper/MusicSearchBarWrapper';
import type { AlgoliaKeyword } from '../../../../../../../api/algolia/magicKeywords';

const Container = styled('div')`
  width: 100%;
  max-width: 740px;
`;

type Props = {
  placeholder: string,
  handleSearch: void,
};

const KeywordsSearchInput = ({ placeholder, handleSearch }: Props) => {
  const locale = useLocale();
  const { handleKeywordSearch } = useMusicSearchBarContext();

  const indexName = getAlgoliaKeywordsIndexName(locale);

  const handleKeyword = (keyword: string, isSuggestion: boolean) => {
    if (keyword === '') return;
    handleKeywordSearch(keyword, {}, isSuggestion);
  };

  const handleMagicKeyword = (magicKeyword: AlgoliaKeyword, isSuggestion: boolean) => {
    if (!magicKeyword) return;
    handleKeywordSearch('', magicKeyword, isSuggestion);
  };

  return (
    <Container>
      <InstantSearch searchClient={algoliaClient} indexName={indexName}>
        <Configure hitsPerPage={5} analytics={isAlgoliaAnalyticsEnabled()} />
        <KeywordAutocomplete
          addKeyword={handleKeyword}
          addMagicKeywords={handleMagicKeyword}
          placeholder={placeholder}
          handleSearch={handleSearch}
        />
      </InstantSearch>
    </Container>
  );
};

export default KeywordsSearchInput;
