// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import ResultsAside from './components/ResultsAside/ResultsAside';
import ResultsNav from './components/ResultsNav/ResultsNav';
import ResultsMain from './components/ResultsMain/ResultsMain';
import {
  BREAKPOINTS,
  LARGE_DEVICE_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { useResultsManagerContext } from './components/ResultsManager/ResultsManager';
import LoadingDisplay from '../../../../../components/LoadingDisplay/LoadingDisplay';

export const Container = styled('div')`
  padding: 0 55px;
  position: relative;

  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${MEDIA_BREAKPOINTS.min1000} {
    min-height: 100vh;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    flex-wrap: wrap;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 107px;
    justify-content: space-between;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 60px;
  }
`;

const Aside = styled('aside')`
  ${MEDIA_BREAKPOINTS.min1000} {
    width: 176px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 1;
    }
  }
  //height: 100vh;
`;

const ResultsAsideWrapper = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    position: fixed;
    position: sticky;
    top: 0;
    height: 100vh;
    left: 55px;
    width: 176px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${MEDIA_BREAKPOINTS.max1260} {
      left: 40px;
    }
  }
`;

const NavAside = styled(Aside)`
  ${LARGE_DEVICE_BREAKPOINT} {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 2;
      display: none;
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const ResultsNavAsideWrapper = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    position: fixed;
    position: sticky;
    top: 0;
    height: 100vh;
    right: 55px;
    width: 155px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${MEDIA_BREAKPOINTS.min1000} {
      padding-top: 70px;
    }

    ${MEDIA_BREAKPOINTS.max1260} {
      right: 40px;
    }
  }
`;

export const Main = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    flex: 1;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 3;
      width: 100%;
      margin-top: 36px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 75px;
    margin-bottom: 27vh;
  }
`;

export const mainLoadingClass = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    ${MEDIA_BREAKPOINTS.max1000} {
      min-height: 33vh;
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 30vh;
    padding-bottom: 10vh;
  }
`;

const modalOverlap = css`
  z-index: 1000;
`;

const asideTopAlignment = css`
  align-items: flex-start !important;
`;

type Props = {};

const ResultsView = () => {
  const { loading } = useResultsManagerContext();
  const [filterMenuIsActive, setFilterMenuIsActive] = useState(false);
  const { similarSongQuery } = useResultsManagerContext();

  const setFilterMenuActive = (activeStatus: boolean) => {
    setFilterMenuIsActive(activeStatus);
  };

  return (
    <Container>
      <Aside className={cx({ [modalOverlap]: filterMenuIsActive })}>
        <ResultsAsideWrapper className={cx({ [asideTopAlignment]: similarSongQuery })}>
          <ResultsAside
            filterMenuIsActive={filterMenuIsActive}
            setFilterMenuActive={setFilterMenuActive}
          />
        </ResultsAsideWrapper>
      </Aside>
      <Main
        className={cx({
          [mainLoadingClass]: loading,
        })}
      >
        {loading ? <LoadingDisplay /> : <ResultsMain />}
      </Main>
      <NavAside>
        <ResultsNavAsideWrapper>
          <ResultsNav />
        </ResultsNavAsideWrapper>
      </NavAside>
    </Container>
  );
};

export default ResultsView;
