// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import { headerHeight } from '../../../../../../../components/SiteHeader/SiteHeader';
import {
  getCuratedCollection,
  getCuratedCollectionImage,
  getCuratedCollectionImageAltText,
  getCuratedCollectionDescription,
  getCuratedCollectionTags,
  getCuratedCollectionTitle,
  getCuratedCollectionRelatedCollections,
  getCuratedCollectionSongs,
  getCuratedCollectionId,
  getCuratedCollectionVideoUrl,
  getCuratedCollectionVideoEmbed,
} from '../../../../../../../cms/curatedCollection';
import CollectionBanner from '../../../../../CollectionScreen/components/CollectionBanner';
import CollectionSongs from '../../../../../CollectionScreen/components/CollectionSongs';
import RelatedCollections from '../../../../../CollectionScreen/components/RelatedCollections';
import { useCuratedCollections } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { sendMixpanelPageVisit } from '../../../../../../../analytics/mixpanel';
import {
  useRecentCollections,
  useWatchUserRecentCollections,
} from '../../../../../../../api/firebase/user/recentCollections';
import { useIsAuthenticated } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';

const Container = styled('div')`
  margin: 0 auto;
  padding-top: ${headerHeight}px;
  margin-bottom: 50px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 50px;
    padding-top: 75px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

type Props = {
  path: string,
  collectionSlug: string,
};

// コレクション詳細ページ
const CollectionView = ({ path, collectionSlug }: Props) => {
  const curatedCollections = useCuratedCollections();
  const [recentCollections, loadedRecentCollections] = useWatchUserRecentCollections();
  const [saveRecentCollections] = useRecentCollections(collectionSlug, recentCollections);
  const isAuthenticated = useIsAuthenticated();
  const [collection, setCollection] = useState(
    getCuratedCollection(collectionSlug, curatedCollections)
  );
  const [showRelatedCollections, setShowRelatedCollections] = useState(true);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  useEffect(() => {
    const updateShowStatus = window ? !window.location.pathname.includes('bookmarks') : false;
    setShowRelatedCollections(updateShowStatus);
  });

  useEffect(() => {
    const pageVisitProperties = {};
    if (collectionSlug) {
      pageVisitProperties['Curated Collection ID'] = collectionSlug;
    }
    setCollection(getCuratedCollection(collectionSlug, curatedCollections));
    sendMixpanelPageVisit(mixpanel, moengage, path, pageVisitProperties);
  }, [collectionSlug]);

  useEffect(() => {
    if (isAuthenticated) {
      saveRecentCollections();
    }
  }, [collectionSlug, loadedRecentCollections]);

  return (
    <Container>
      {collection && (
        <>
          <CollectionBanner
            slug={getCuratedCollectionId(collection)}
            title={getCuratedCollectionTitle(collection)}
            image={getCuratedCollectionImage(collection)}
            imageAltText={getCuratedCollectionImageAltText(collection)}
            tags={getCuratedCollectionTags(collection)}
            description={getCuratedCollectionDescription(collection)}
            videoUrl={getCuratedCollectionVideoUrl(collection)}
            videoEmbed={getCuratedCollectionVideoEmbed(collection)}
          />
          <CollectionSongs
            songIDs={getCuratedCollectionSongs(collection)}
            title={getCuratedCollectionTitle(collection)}
            slug={getCuratedCollectionId(collection)}
          />
          {showRelatedCollections && (
            <RelatedCollections
              relatedCollections={getCuratedCollectionRelatedCollections(collection)}
              collectionId={getCuratedCollectionId(collection)}
              tags={getCuratedCollectionTags(collection).concat([
                getCuratedCollectionTitle(collection),
              ])}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default CollectionView;
