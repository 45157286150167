// @flow
import React from 'react';
import { ROUTES } from '../../routes';
import {
  // useChromeExtensionCopy,
  useProfileMenuCopy,
  useSiteHeader,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getProfileMenuBilling,
  getProfileMenuDownloadHistory,
  getProfileMenuEmailSettings,
  getProfileMenuProfile,
  getProfileMenuSubscriptions,
} from '../../../cms/profileMenu';
import { useLocalePath } from '../LocaleWrapper/LocaleWrapper';
import { SiteNavLink } from '../SiteNavLinks/SiteNavLinks';
// import {
//   getExtensionDownloadUrl,
//   getSafariExtensionDownloadUrl,
//   getExtensionDownloadText,
//   getExtensionChromeLabel,
//   getExtensionSafariLabel,
// } from '../../../cms/chromeExtension';
// import { isDesktopSafariBrowser } from '../../../utils/browser';
import { getBookmarksNav } from '../../../cms/header';

type Props = {
  children: any,
};

const ProfileNavLinks = ({ children }: Props) => {
  const copy = useProfileMenuCopy();
  const siteHeader = useSiteHeader();
  // const chromeExtension = useChromeExtensionCopy();
  const localePath = useLocalePath();

  // const browser = isDesktopSafariBrowser()
  //   ? getExtensionSafariLabel(chromeExtension)
  //   : getExtensionChromeLabel(chromeExtension);

  // const downloadURL = isDesktopSafariBrowser()
  //   ? getSafariExtensionDownloadUrl(chromeExtension)
  //   : getExtensionDownloadUrl(chromeExtension);

  const links = [
    props => (
      <SiteNavLink to={ROUTES.profile.navigatePath({ localePath })} {...props}>
        {getProfileMenuProfile(copy)}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.subscriptions.navigatePath({ localePath })} {...props}>
        {getProfileMenuSubscriptions(copy)}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.billing.navigatePath({ localePath })} {...props}>
        {getProfileMenuBilling(copy)}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.downloads.navigatePath({ localePath })} {...props}>
        {getProfileMenuDownloadHistory(copy)}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.bookmarks.navigatePath({ localePath })} matchPartial {...props}>
        {getBookmarksNav(siteHeader)}
      </SiteNavLink>
    ),
    props => (
      <SiteNavLink to={ROUTES.email.navigatePath({ localePath })} {...props}>
        {getProfileMenuEmailSettings(copy)}
      </SiteNavLink>
    ),
    // 問い合わせ中のためコメントアウト
    // props => (
    //   <a href={downloadURL} target="_blank" rel="noopener noreferrer" {...props}>
    //     {getExtensionDownloadText(chromeExtension).replace('$VERSION$', browser)}
    //   </a>
    // ),
  ];
  return children(links);
};

export default ProfileNavLinks;
