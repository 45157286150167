// @flow
import React from 'react';
import styled from 'react-emotion';
import { monoFontCss, smallFontSizeCss } from '../../../../../styles/typography/typography';
import { hideOnSmallDeviceCss, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import { getSongDuration, getSongHistogram, getSongID } from '../../../../../api/algolia/song';
import { formatSeconds } from '../../../../../utils/time';
import AudioBars from '../../../AudioBars/AudioBars';
import { useAudioPlaybackContext } from '../../../AudioPlayback/AudioPlayback';

const Container = styled('div')`
  height: 120px;
  display: flex;
  align-items: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 60px;
  }
`;

const Time = styled('div')`
  ${hideOnSmallDeviceCss};
  ${monoFontCss};
  ${smallFontSizeCss};
  line-height: 20px;
  letter-spacing: 0.5px;
  min-width: 50px;

  &:last-child {
    text-align: right;
  }
`;

const GraphContainer = styled('div')`
  flex: 1;
  height: 100%;
`;

type Props = {};

const SongMiddle = () => {
  const song = useSong();
  const { progress } = useAudioPlaybackContext();
  return (
    <Container>
      <Time>{formatSeconds(progress)}</Time>
      <GraphContainer>
        <AudioBars hideMicropartsOnMobile />
      </GraphContainer>
      <Time>{formatSeconds(getSongDuration(song))}</Time>
    </Container>
  );
};

export default SongMiddle;
