import {
  is850MobileDeviceSize,
  isLargeMobileDeviceSize,
  isMobileDeviceSize,
  isSmallDeviceSize,
} from './device';

export const getRows = (tiles: number) => {
  let tilesPerRow = 4;

  if (isSmallDeviceSize()) {
    tilesPerRow = 3;
  }
  if (isMobileDeviceSize()) {
    tilesPerRow = 2;
  }

  return Math.ceil(tiles / tilesPerRow);
};

/**
 * スライダーで表示する行数を現在のwindow幅ごと取得する。
 *
 * @param {number} tiles 全タイル数
 * @returns スライダーに表示する行数
 *
 */
export const getSliderRows = (tiles: number) => {
  let tilesPerRow = 8;

  if (isLargeMobileDeviceSize()) {
    tilesPerRow = 6;
  }
  if (is850MobileDeviceSize()) {
    tilesPerRow = 4;
  }
  if (isMobileDeviceSize()) {
    tilesPerRow = 2;
  }

  return Math.ceil(tiles / tilesPerRow);
};
