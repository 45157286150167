// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { buttonResetCss } from '../../../../../components/Button/Button';
import { darkColor } from '../../../../../styles/config/colors';
import PlayIcon from '../../../../../assets/inline-assets/play-icon.svg';
import PauseIcon from '../../../../../assets/inline-assets/pause-icon.svg';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import SpinnerIcon from '../../../../../components/SpinnerIcon/SpinnerIcon';

const Button = styled('button')`
  ${buttonResetCss};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 40px;
    height: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${darkColor};
    border-radius: 50%;
    transition: all 200ms ease-in;
  }

  &:active {
    opacity: 0.8;

    &::before {
      transition-timing-function: ease-out;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
  }
`;

const cantPlayClass = css`
  opacity: 0.33;
`;

const playClass = css`
  position: relative;
  left: 2px;

  ${SMALL_DEVICE_BREAKPOINT} {
    left: 2px;
  }
`;

type RawPlayPauseButtonProps = {
  playing: boolean,
  onClick: () => void,
  cantPlay?: boolean,
  tryingToPlay?: boolean,
};

export const RawPlayPauseButton = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  onClick,
}: RawPlayPauseButtonProps) => {
  // eslint-disable-next-line no-nested-ternary
  const icon = tryingToPlay ? (
    <SpinnerIcon inverse />
  ) : playing ? (
    <PauseIcon />
  ) : (
    <PlayIcon className={playClass} />
  );
  return (
    <Button
      className={cx({
        [cantPlayClass]: cantPlay,
      })}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

type Props = {
  playing: boolean,
  tryingToPlay: boolean,
  loadError: boolean,
  handleOnPlayToggle: () => void,
};

const PreviewPlayPauseButton = ({
  playing,
  tryingToPlay,
  loadError,
  handleOnPlayToggle,
}: Props) => {
  return (
    <RawPlayPauseButton
      playing={playing}
      onClick={handleOnPlayToggle}
      tryingToPlay={tryingToPlay}
      cantPlay={loadError}
    />
  );
};

export default PreviewPlayPauseButton;
