// @flow

import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
} from '../../../../../../../store/globalPlayer';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { ROUTES } from '../../../../../../routes';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useNavigate } from '../../../../../../hooks';
import FullScreenCarouselItem from '../../../../../../../layouts/components/FullScreenCarousel/components/FullScreenCarouselItem';
import type { CarouselItemMdl } from '../../../../../../../utils/featuredContent';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';

type Props = {
  itemData: CarouselItemMdl,
};

const FeaturedArtistItem = ({ itemData }: Props) => {
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const globalTryingToPlay = useRecoilValue(globalTryingToPlayAtom);
  const globalLoadError = useRecoilValue(globalLoadErrorAtom);

  const userId = useUserId();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const {
    searchAlgoliaArtist,
    handleUpdateGlobalAnalyticsData,
    updateGlobalPlayerPlaybackDetails,
    handleToggleGlobalPlayback,
  } = useGlobalPlayerContext();
  const navigate = useNavigate();

  const {
    id,
    prismicType,
    listType,
    title,
    featuredImage,
    featuredMobileImage,
    mainImage,
    songs,
  } = itemData;

  const desktopImage = featuredImage || mainImage;
  const mobileImage = featuredMobileImage || mainImage;

  const itemIsOnQueue = playbackListType === listType && playbackListSlug === id;
  const isPlaying = itemIsOnQueue && globalPlaying;
  const tryingToPlay = itemIsOnQueue && globalTryingToPlay;
  const loadError = itemIsOnQueue && globalLoadError;

  const handleNavigateArtist = () => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'featuredArtist', '');
    navigate(ROUTES.artist.navigatePath({ artistSlug: id }));
  };

  const handlePlayClick = (event: any) => {
    if (itemIsOnQueue) {
      handleToggleGlobalPlayback();
      return;
    }
    analyticsMixpanelPreviewSongs(mixpanel, moengage, 'Artist', id, 'Featured Artists', userId);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'featuredArtist', '', id);
    searchAlgoliaArtist(id, title);
  };

  return (
    <FullScreenCarouselItem
      itemData={itemData}
      isVerified
      playing={isPlaying}
      tryingToPlay={tryingToPlay}
      cantPlay={loadError}
      handleClick={handleNavigateArtist}
      handlePlay={handlePlayClick}
    />
  );
};

export default FeaturedArtistItem;
