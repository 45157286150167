// @flow
import { get } from 'lodash';

const isCorrectFilterQueryFormat = (filterParam: string) => {
  if ((filterParam[0] === '~' || filterParam[0] === '-') && filterParam.length > 1) {
    return true;
  }
  return false;
};

const isIncludedParam = (filterParam: string): boolean => {
  if (filterParam[0] === '~') {
    return true;
  }
  return false;
};

const removeSyntaxCharacter = (filterParam: string) => {
  return filterParam.slice(1);
};

const queryParamsToSelectFilters = (filterParams: Array<string>) => {
  const includedFilters = [];
  const excludedFilters = [];

  filterParams.forEach(filterParam => {
    if (isIncludedParam(filterParam) && isCorrectFilterQueryFormat(filterParam)) {
      includedFilters.push(removeSyntaxCharacter(filterParam).toLowerCase());
    } else if (!isIncludedParam(filterParam) && isCorrectFilterQueryFormat(filterParam)) {
      excludedFilters.push(removeSyntaxCharacter(filterParam).toLowerCase());
    }
  });

  const appliedFilterData = {
    excluded: excludedFilters,
    included: includedFilters,
  };

  return appliedFilterData;
};

export const queryParamsToRangeFilters = (filterParams: string) => {
  const rangeValues = filterParams.split('-');
  const appliedFilterData = {
    min: rangeValues[0],
    max: rangeValues[1],
  };

  return appliedFilterData;
};

export const getInitialFiltersApplied = (
  bpmParams: string,
  editStyleParams: Array<string>,
  energyParams: Array<string>,
  genreParams: Array<string>,
  instrumentParams: Array<string>,
  lengthParams: string,
  locationParams: Array<string>,
  moodParams: Array<string>,
  movementParams: Array<string>,
  videoThemeParams: Array<string>,
  sfxTypeParams: Array<string>,
  sfxFeatureParams: Array<string>
) => {
  const preAppliedFilters = {};

  if (bpmParams) {
    preAppliedFilters.bpm = queryParamsToRangeFilters(bpmParams);
  }

  if (editStyleParams) {
    preAppliedFilters['keywords.manual.edit-style'] = queryParamsToSelectFilters(editStyleParams);
  }

  if (energyParams) {
    preAppliedFilters['keywords.manual.energy'] = queryParamsToSelectFilters(energyParams);
  }

  if (genreParams) {
    preAppliedFilters['keywords.manual.genre'] = queryParamsToSelectFilters(genreParams);
  }

  if (instrumentParams) {
    preAppliedFilters['keywords.manual.instruments'] = queryParamsToSelectFilters(instrumentParams);
  }

  if (lengthParams) {
    preAppliedFilters['audio.duration'] = queryParamsToRangeFilters(lengthParams);
  }

  if (locationParams) {
    preAppliedFilters['keywords.manual.location'] = queryParamsToSelectFilters(locationParams);
  }

  if (moodParams) {
    preAppliedFilters['keywords.manual.mood'] = queryParamsToSelectFilters(moodParams);
  }

  if (movementParams) {
    preAppliedFilters['keywords.manual.movement'] = queryParamsToSelectFilters(movementParams);
  }

  if (videoThemeParams) {
    preAppliedFilters['keywords.manual.video-theme'] = queryParamsToSelectFilters(videoThemeParams);
  }

  if (sfxTypeParams) {
    preAppliedFilters['keywords.manual.type'] = queryParamsToSelectFilters(sfxTypeParams);
  }

  if (sfxFeatureParams) {
    preAppliedFilters['keywords.manual.feature'] = queryParamsToSelectFilters(sfxFeatureParams);
  }

  return preAppliedFilters;
};

const getSelectQueryParams = (selectFilter: any): Array<string> => {
  const excludedFilters = get(selectFilter, 'excluded', []);
  const includedFilters = get(selectFilter, 'included', []);

  const excludedParams = excludedFilters.map(excludedFilter => {
    return `-${excludedFilter}`;
  });
  const includedParams = includedFilters.map(includedFilter => {
    return `~${includedFilter}`;
  });

  return includedParams.concat(excludedParams);
};

const getRangeQueryParams = (rangeFilter: any): string => {
  const minValue = get(rangeFilter, 'min', 0);
  const maxValue = get(rangeFilter, 'max', 0);

  return `${minValue}-${maxValue}`;
};

export const getFilterQueryParams = (appliedFilters: any): any => {
  const filterQueryParams = {
    bpmFilterParams: '',
    editStyleFilterParams: [],
    energyFilterParams: [],
    genreFilterParams: [],
    instrumentsFilterParams: [],
    lengthFilterParams: '',
    locationFilterParams: [],
    moodFilterParams: [],
    movementFilterParams: [],
    videoThemeFilterParams: [],
    sfxTypeFilterParams: [],
    sfxFeatureFilterParams: [],
  };

  const bpmFilterData = get(appliedFilters, 'bpm', null);
  const editStyleFilterData = get(appliedFilters, 'keywords.manual.edit-style', null);
  const energyFilterData = get(appliedFilters, 'keywords.manual.energy', null);
  const genreFilterData = get(appliedFilters, 'keywords.manual.genre', null);
  const instrumentsFilterData = get(appliedFilters, 'keywords.manual.instruments', null);
  const lengthFilterData = get(appliedFilters, 'audio.duration', null);
  const locationFilterData = get(appliedFilters, 'keywords.manual.location', null);
  const moodFilterData = get(appliedFilters, 'keywords.manual.mood', null);
  const movementFilterData = get(appliedFilters, 'keywords.manual.movement', null);
  const videoThemeFilterData = get(appliedFilters, 'keywords.manual.video-theme', null);
  const sfxTypeFilterData = get(appliedFilters, 'keywords.manual.type', null);
  const sfxFeatureFilterData = get(appliedFilters, 'keywords.manual.feature', null);

  if (bpmFilterData) {
    filterQueryParams.bpmFilterParams = getRangeQueryParams(bpmFilterData);
  }

  if (editStyleFilterData) {
    filterQueryParams.editStyleFilterParams = getSelectQueryParams(editStyleFilterData);
  }

  if (energyFilterData) {
    filterQueryParams.energyFilterParams = getSelectQueryParams(energyFilterData);
  }

  if (genreFilterData) {
    filterQueryParams.genreFilterParams = getSelectQueryParams(genreFilterData);
  }

  if (instrumentsFilterData) {
    filterQueryParams.instrumentsFilterParams = getSelectQueryParams(instrumentsFilterData);
  }

  if (lengthFilterData) {
    filterQueryParams.lengthFilterParams = getRangeQueryParams(lengthFilterData);
  }

  if (locationFilterData) {
    filterQueryParams.locationFilterParams = getSelectQueryParams(locationFilterData);
  }

  if (moodFilterData) {
    filterQueryParams.moodFilterParams = getSelectQueryParams(moodFilterData);
  }

  if (movementFilterData) {
    filterQueryParams.movementFilterParams = getSelectQueryParams(movementFilterData);
  }

  if (videoThemeFilterData) {
    filterQueryParams.videoThemeFilterParams = getSelectQueryParams(videoThemeFilterData);
  }

  if (sfxTypeFilterData) {
    filterQueryParams.sfxTypeFilterParams = getSelectQueryParams(sfxTypeFilterData);
  }

  if (sfxFeatureFilterData) {
    filterQueryParams.sfxFeatureFilterParams = getSelectQueryParams(sfxFeatureFilterData);
  }

  return filterQueryParams;
};
