// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import LoadingIcon from './components/LoadingIcon/LoadingIcon';

const fixedCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100vh;
  pointer-events: none;
  ${props => (props.fixed ? fixedCss : '')};
`;

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
  }
`;

type Props = {};

const LoadingDisplay = () => (
  <Container>
    <LoadingIcon />
  </Container>
);

export default LoadingDisplay;
