// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import SiteNavLinks, {
  activeLinkClass,
} from '../../../../routing/components/SiteNavLinks/SiteNavLinks';
import { darkColor } from '../../../../styles/config/colors';
import { plainTextButtonCss } from '../../../Button/Button';

const Nav = styled('nav')`
  display: flex;
  justify-content: center;
`;

const NavLink = styled('div')`
  margin: 0 15px;

  a {
    ${plainTextButtonCss};
    color: ${transparentize(0.6, darkColor)};

    &.${activeLinkClass} {
      color: ${darkColor};
    }
  }
`;

const SiteNav = () => (
  <Nav>
    <SiteNavLinks>
      {links => {
        return links.map((Link, index) => {
          return (
            <NavLink key={index.toString()}>
              <Link />
            </NavLink>
          );
        });
      }}
    </SiteNavLinks>
  </Nav>
);

export default SiteNav;
