import { atom, selector } from 'recoil';
import { ROUTES } from '../routing/routes';

export const songPositionAtom = atom({
  key: 'songPosition',
  default: undefined,
});

export const songsAtom = atom({
  key: 'songs',
  default: [],
});

export const songStartTimeAtom = atom({
  key: 'songStartTime',
  default: 0,
});

export const songProgressAtom = atom({
  key: 'songProgress',
  default: undefined,
});

export const globalPlayConfigurationAtom = atom({
  key: 'globalPlayConfiguration',
  default: true,
});

export const globalPlayingAtom = atom({
  key: 'globalPlaying',
  default: false,
});

export const globalTryingToPlayAtom = atom({
  key: 'globalTryingToPlay',
  default: false,
});

export const globalLoadErrorAtom = atom({
  key: 'globalLoadError',
  default: false,
});

export const activeGlobalPlaybackAtom = atom({
  key: 'activeGlobalPlayback',
  default: true,
});

export const silencePlayedAtom = atom({
  key: 'silencePlayed',
  default: false,
});

export const playbackListTypeAtom = atom({
  key: 'playbackListType',
  default: '',
});

export const playbackListSlugAtom = atom({
  key: 'playbackListSlug',
  default: '',
});

export const playbackListNameAtom = atom({
  key: 'playbackListName',
  default: '',
});

export const mixpanelKeywordsSearchedAtom = atom({
  key: 'mixpanelKeywordsSearched',
  default: [],
});

export const magicKeywordsSearchedAtom = atom({
  key: 'magicKeywordsSearched',
  default: [],
});

export const youtubeKeywordsSearchedAtom = atom({
  key: 'youtubeKeywordsSearched',
  default: [],
});

export const youtubeVideoTitleSearchedAtom = atom({
  key: 'youtubeVideoTitleSearched',
  default: '',
});

export const sectionOriginAtom = atom({
  key: 'sectionOrigin',
  default: '',
});

export const relatedSearchedAtom = atom({
  key: 'relatedSearched',
  default: '',
});

export const artistSlugAtom = atom({
  key: 'artistSlug',
  default: '',
});

export const songPlayingSelector = selector({
  key: 'songPlaying',
  default: undefined,
  get: ({ get }) => {
    const songsOnQueue = get(songsAtom);
    const playingSongPosition = get(songPositionAtom);
    if (songsOnQueue.length < 1) {
      return undefined;
    }
    return songsOnQueue[playingSongPosition || 0];
  },
});

export const nextSongEnabledSelector = selector({
  key: 'nextSong',
  default: false,
  get: ({ get }) => {
    const songsOnQueue = get(songsAtom);
    const playingSongPosition = get(songPositionAtom);
    return songsOnQueue.length > 0 && songsOnQueue.length - 1 !== playingSongPosition;
  },
});

export const previousSongEnabledSelector = selector({
  key: 'previousSong',
  default: false,
  get: ({ get }) => {
    const songsOnQueue = get(songsAtom);
    const playingSongPosition = get(songPositionAtom);
    return songsOnQueue.length > 0 && playingSongPosition !== 0;
  },
});

export const playlistPathSelector = selector({
  key: 'playlistPath',
  default: '',
  get: ({ get }) => {
    const playbackListType = get(playbackListTypeAtom);
    const playbackListSlug = get(playbackListSlugAtom);

    if (playbackListType === 'collection') {
      return ROUTES.collection.navigatePath({ collectionSlug: playbackListSlug });
    }
    if (playbackListType === 'artist') {
      return ROUTES.artist.navigatePath({ artistSlug: playbackListSlug });
    }
    if (playbackListType === 'similar') {
      return ROUTES.similarSearch.navigatePath({ songId: playbackListSlug });
    }
    if (playbackListType === 'search') {
      const basePath = ROUTES.musicSearch.navigatePath({});
      return playbackListSlug ? `${basePath}?${playbackListSlug}` : basePath;
    }
    if (playbackListType === 'pack') {
      return ROUTES.musicPack.navigatePath({ shareSlug: playbackListSlug });
    }
    if (['genre', 'mood', 'instruments', 'type'].includes(playbackListType)) {
      const basePath = ROUTES.musicSearch.navigatePath({});
      return playbackListSlug ? `${basePath}?q=${playbackListSlug}` : basePath;
    }
    return '';
  },
});

export const playlistPathAnalyticsSelector = selector({
  key: 'playlistPathAnalytics',
  default: {
    playlistType: '',
    collectionId: '',
    similarSongId: '',
    keywords: [],
    magicKeywords: [],
    youtubeKeywords: [],
    packShareSlug: '',
  },
  get: ({ get }) => {
    const analytics = {
      playlistType: '',
      collectionId: '',
      similarSongId: '',
      keywords: [],
      magicKeywords: [],
      youtubeKeywords: [],
      packShareSlug: '',
      artistSlug: '',
    };
    const playbackListType = get(playbackListTypeAtom);
    const playbackListSlug = get(playbackListSlugAtom);
    const mixpanelKeywordsSearched = get(mixpanelKeywordsSearchedAtom);
    const magicKeywordsSearched = get(magicKeywordsSearchedAtom);
    const youtubeKeywordsSearched = get(youtubeKeywordsSearchedAtom);

    if (playbackListType === 'collection') {
      analytics.playlistType = playbackListType;
      analytics.collectionId = playbackListSlug;
    }
    if (playbackListType === 'artist') {
      analytics.playlistType = playbackListType;
      analytics.artistSlug = playbackListSlug;
    }
    if (playbackListType === 'similar') {
      analytics.playlistType = playbackListType;
      analytics.similarSongId = playbackListSlug;
    }
    if (
      playbackListType === 'search' ||
      ['genre', 'mood', 'instruments'].includes(playbackListType)
    ) {
      analytics.playlistType = playbackListType;
      analytics.keywords = mixpanelKeywordsSearched;
      analytics.magicKeywords = magicKeywordsSearched;
      analytics.youtubeKeywords = youtubeKeywordsSearched;
    }
    if (playbackListType === 'pack') {
      analytics.playlistType = playbackListType;
      analytics.keywords = mixpanelKeywordsSearched;
      analytics.magicKeywords = magicKeywordsSearched;
      analytics.youtubeKeywords = youtubeKeywordsSearched;
      analytics.packShareSlug = playbackListSlug;
    }
    return analytics;
  },
});
