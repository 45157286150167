// @flow
import React, { useContext, useState, useEffect } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { searchAlgolia, searchPreviewAlgolia } from '../../../api/algolia/search';
import { useLocale } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import type { AlgoliaSearchResults } from '../../../api/algolia/search';
import { isSongSfx } from '../../../api/algolia/song';
import { useNavigate } from '../../../routing/hooks';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { getPlayerKeyboardShortcuts } from '../../../utils/keyboardShortcuts';
import { analyticsMixpanelNavigatePlaylist } from '../../../analytics/mixpanel';
import {
  globalPlayConfigurationAtom,
  songPositionAtom,
  songProgressAtom,
  songsAtom,
  songStartTimeAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
  activeGlobalPlaybackAtom,
  playbackListTypeAtom,
  playbackListSlugAtom,
  playbackListNameAtom,
  mixpanelKeywordsSearchedAtom,
  magicKeywordsSearchedAtom,
  youtubeKeywordsSearchedAtom,
  youtubeVideoTitleSearchedAtom,
  sectionOriginAtom,
  relatedSearchedAtom,
  nextSongEnabledSelector,
  previousSongEnabledSelector,
  playlistPathSelector,
  playlistPathAnalyticsSelector,
  artistSlugAtom,
} from '../../../store/globalPlayer';
import { createSongIDFilterQuery } from '../../../utils/algolia';

export type GlobalPlayerContextState = {
  browsingSongIds: Array<string>,
  songPosition: number,
  songPlaying: AlgoliaSongMdl,
  changeSongList: (
    Array<AlgoliaSongMdl>,
    number | typeof undefined,
    number | typeof undefined
  ) => void,
  changeSongIDs: (Array<string>, number | typeof undefined, number | typeof undefined) => void,
  songs: Array<AlgoliaSongMdl>,
  nextEnabled: boolean,
  previousEnabled: boolean,
  nextSong: () => void,
  previousSong: () => void,
  songProgress: number,
  handleUpdateSongProgress: number => void,
  songStartTime: number,
  handleResetStartTime: () => void,
  handleUpdateGlobalPlayingState: (boolean, boolean, boolean) => void,
  globalPlaying: boolean,
  globalTryingToPlay: boolean,
  globalLoadError: boolean,
  handleToggleGlobalPlayback: () => void,
  activeGlobalPlayback: boolean,
  pauseGlobalPlayback: () => void,
  playGlobalPlayback: () => void,
  globalPlayConfiguration: boolean,
  playbackListType: string,
  playbackListName: string,
  playbackListSlug: string,
  PlaybackListFilters: string,
  updateGlobalPlayerPlaybackDetails: (string, string, string) => void,
  navigateToPlaybackList: () => void,
  searchAlgoliaTag: (tag: string, category: string, songType: string) => void,
  searchAlgoliaArtist: (artistSlug: string, artistName: string) => void,
  closeGlobalPlayer: () => void,
  handleUpdateGlobalAnalyticsData: (
    keywords: Array<string>,
    magicKeywords: Array<string>,
    youtubeKeywords: Array<string>,
    youTubeTitle: string,
    origin: string,
    relatedCollection: string,
    artistSlug: string
  ) => void,
  silencePlayed: boolean,
  setSilencePlayed: (state: boolean) => void,
};

export const GlobalPlayerContext = React.createContext<any>();

export const useGlobalPlayerContext = (): GlobalPlayerContextState => {
  return useContext(GlobalPlayerContext);
};

type Props = {
  children: any,
};

const GlobalPlayerWrapper = ({ children }: Props) => {
  const [currentPathname, setCurrentPathname] = useState('');
  const [previousPath, setPreviousPath] = useState('');

  // Player state
  const setActiveGlobalPlayback = useSetRecoilState(activeGlobalPlaybackAtom);
  const [globalPlayConfiguration, setGlobalPlayConfiguration] = useRecoilState(
    globalPlayConfigurationAtom
  );

  // Playlist Data
  const [playbackListType, setPlaybackListType] = useRecoilState(playbackListTypeAtom);
  const setPlaybackListSlug = useSetRecoilState(playbackListSlugAtom);
  const setPlaybackListName = useSetRecoilState(playbackListNameAtom);
  const [songs, setSongs] = useRecoilState(songsAtom);
  const playlistPath = useRecoilValue(playlistPathSelector);

  // Song Queue Data
  const nextSongEnabled = useRecoilValue(nextSongEnabledSelector);
  const previousSongEnabled = useRecoilValue(previousSongEnabledSelector);
  const [songPosition, setSongPosition] = useRecoilState(songPositionAtom);
  const [songStartTime, setSongStartTime] = useRecoilState(songStartTimeAtom);
  const setSongProgress = useSetRecoilState(songProgressAtom);
  const setGlobalPlaying = useSetRecoilState(globalPlayingAtom);
  const setGlobalTryingToPlay = useSetRecoilState(globalTryingToPlayAtom);
  const setGlobalLoadError = useSetRecoilState(globalLoadErrorAtom);

  // Analytics Data
  const setMixpanelKeywordsSearched = useSetRecoilState(mixpanelKeywordsSearchedAtom);
  const setMagicKeywordSearched = useSetRecoilState(magicKeywordsSearchedAtom);
  const setYoutubeKeywordsSearched = useSetRecoilState(youtubeKeywordsSearchedAtom);
  const setYoutubeVideoTitleSearched = useSetRecoilState(youtubeVideoTitleSearchedAtom);
  const setSectionOrigin = useSetRecoilState(sectionOriginAtom);
  const setRelatedSearched = useSetRecoilState(relatedSearchedAtom);
  const setArtistSlug = useSetRecoilState(artistSlugAtom);
  const playlistPathAnalytics = useRecoilValue(playlistPathAnalyticsSelector);

  const navigate = useNavigate();
  const locale = useLocale();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: undefined, moengage: undefined, handleUpdateCollectionAnalytics: () => {} };

  const updateGlobalPlayerPlaybackDetails = (
    listType: string,
    listName: string,
    listSlug: string
  ) => {
    setPlaybackListType(listType);
    setPlaybackListName(listName);
    setPlaybackListSlug(listSlug);
  };

  const changeSongList = (
    newSongs: Array<AlgoliaSongMdl>,
    startAtSong: number | typeof undefined,
    startAtSecond: number | typeof undefined
  ) => {
    const startTime = startAtSecond === undefined ? 0 : startAtSecond;
    const newPosition = startAtSong === undefined ? 0 : startAtSong;
    setSongStartTime(startTime);
    setSongs(newSongs);
    setSongPosition(newPosition);
  };

  const changeSongIDs = (
    newSongs: Array<string>,
    startAtSong: number | typeof undefined,
    startAtSecond: number | typeof undefined
  ) => {
    const searchFilter = createSongIDFilterQuery(newSongs);

    searchAlgolia('', locale, [], searchFilter).then((response: AlgoliaSearchResults) => {
      const startTime = startAtSecond === undefined ? 0 : startAtSecond;
      const newPosition = startAtSong === undefined ? 0 : startAtSong;
      const globalPlayerSongs = response.hits.filter(hit => !isSongSfx(hit));
      setSongStartTime(startTime);
      setSongs(globalPlayerSongs);
      setSongPosition(newPosition);
    });
  };

  const searchAlgoliaTag = (tag: string, tagCategory: string, songType: string) => {
    const filterQuery = songType ? `type: ${songType}` : '';
    searchPreviewAlgolia(tag, locale, [], filterQuery).then((response: AlgoliaSearchResults) => {
      setSongPosition(0);
      setSongStartTime(0);
      setSongs(response.hits);
      updateGlobalPlayerPlaybackDetails(tagCategory, tag, tag.toLowerCase());
    });
  };

  const searchAlgoliaArtist = (artistSlug: string, artisName: string) => {
    const queryFilter = `artistSlug: "${artistSlug}" AND NOT type: "sfx"`;
    searchPreviewAlgolia('', locale, [], queryFilter).then((response: AlgoliaSearchResults) => {
      setSongPosition(0);
      setSongStartTime(0);
      setSongs(response.hits);
      updateGlobalPlayerPlaybackDetails('artist', artisName, artistSlug);
    });
  };

  const pauseGlobalPlayback = () => {
    setActiveGlobalPlayback(false);
  };

  const playGlobalPlayback = () => {
    setActiveGlobalPlayback(true);
  };

  const nextSong = () => {
    if (!nextSongEnabled) return;
    const newPosition = songPosition + 1;
    playGlobalPlayback();
    setSongStartTime(0);
    setSongPosition(newPosition);
  };

  const previousSong = () => {
    if (!previousSongEnabled) return;
    const newPosition = songPosition - 1;
    playGlobalPlayback();
    setSongStartTime(0);
    setSongPosition(newPosition);
  };

  const handleUpdateSongProgress = (progress: number) => {
    setSongProgress(progress);
  };

  const handleResetStartTime = () => {
    setSongStartTime(0);
  };

  const handleUpdateGlobalPlayingState = (
    playing: boolean,
    tryingToPlay: boolean,
    loadError: boolean
  ) => {
    setGlobalPlaying(playing);
    setGlobalTryingToPlay(tryingToPlay);
    setGlobalLoadError(loadError);
  };

  const handleUpdateGlobalAnalyticsData = (
    keywords,
    magicKeywords,
    youtubeKeywords,
    youTubeTitle,
    origin,
    relatedCollection,
    artistSlug
  ) => {
    setMixpanelKeywordsSearched(keywords);
    setMagicKeywordSearched(magicKeywords);
    setYoutubeKeywordsSearched(youtubeKeywords);
    setYoutubeVideoTitleSearched(youTubeTitle);
    setSectionOrigin(origin);
    setRelatedSearched(relatedCollection);
    setArtistSlug(artistSlug);
  };

  const handleToggleGlobalPlayback = () => {
    setGlobalPlayConfiguration(!globalPlayConfiguration);
  };

  const navigateToPlaybackList = () => {
    if (playbackListType === 'collection') {
      handleUpdateCollectionAnalytics([], [], [], [], '', 'collection', '');
    }
    if (playlistPath) {
      navigate(playlistPath);
      const {
        playlistType,
        collectionId,
        similarSongId,
        keywords,
        magicKeywords,
        youtubeKeywords,
        packShareSlug,
        artistSlug,
      } = playlistPathAnalytics;
      analyticsMixpanelNavigatePlaylist(
        mixpanel,
        moengage,
        playlistType,
        collectionId,
        similarSongId,
        keywords,
        magicKeywords,
        youtubeKeywords,
        packShareSlug,
        artistSlug
      );
    }
  };

  const closeGlobalPlayer = () => {
    setSongPosition(0);
    setSongs([]);
    setSongStartTime(0);
    setSongProgress(0);
    setGlobalPlayConfiguration(false);
    // setActiveGlobalPlayback(false);
    setGlobalPlaying(false);
    setGlobalTryingToPlay(false);
    setGlobalLoadError(false);

    setPlaybackListType('');
    setPlaybackListSlug('');
    setPlaybackListName('');
  };

  useEffect(() => {
    setPreviousPath(currentPathname);
    setCurrentPathname(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (currentPathname.includes('/bookmarks') && previousPath.includes('/bookmarks')) return;
    if (!currentPathname.includes('/music')) {
      closeGlobalPlayer();
    }
  }, [currentPathname]);

  const keyboardShortcutHandlers = (() => {
    return {
      next_song: nextSong,
      previous_song: previousSong,
    };
  })();

  useEffect(() => {
    if (songs.length >= 1) {
      setGlobalPlayConfiguration(true);
    }
  }, [songPosition, songs, songStartTime]);

  return (
    <GlobalPlayerContext.Provider
      value={{
        changeSongList,
        changeSongIDs,
        nextSong,
        previousSong,
        handleUpdateSongProgress,
        handleResetStartTime,
        handleUpdateGlobalPlayingState,
        handleToggleGlobalPlayback,
        pauseGlobalPlayback,
        playGlobalPlayback,
        updateGlobalPlayerPlaybackDetails,
        navigateToPlaybackList,
        searchAlgoliaTag,
        searchAlgoliaArtist,
        closeGlobalPlayer,
        handleUpdateGlobalAnalyticsData,
      }}
    >
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      {children}
    </GlobalPlayerContext.Provider>
  );
};

export default GlobalPlayerWrapper;
