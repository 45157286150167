// @flow

import styled from 'react-emotion';
import { transparentize } from 'polished';
import { darkColor } from '../../../styles/config/colors';
import { inputResetCss, placeholderCss } from '../../../styles/shared';

export const RoundedInput = styled('input')`
  ${inputResetCss};
  height: 60px;
  border: 1px solid rgba(15, 15, 20, 0.1);
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 12px 20px;
  color: ${darkColor};

  ${placeholderCss(`
    color: ${transparentize(0.6, darkColor)};
  `)};

  &:hover {
    ${placeholderCss(`
      color: ${transparentize(0.4, darkColor)};
    `)};
  }

  &:focus {
    border-color: ${darkColor};
    outline: none;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px rgba(255, 230, 140, 0.15) inset !important;
  }
`;
