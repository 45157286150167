// @flow
import React, { useContext, useEffect, useState } from 'react';
import loadjs from 'loadjs';
import { get } from 'lodash';
import YouTubeChannelModal from './components/YouTubeChannelModal/YouTubeChannelModal';
import type { YouTubeAPIChannelMdl } from '../../../../api/firebase/user/channels';
import {
  YouTubeChannelNoAvaliableChannelError,
  YouTubeChannelUndefinedYouTubeDataError,
} from '../../../../errors/errors';

declare var gapi;

export type YouTubeAPIChannelsResponseMdl = {
  items: Array<YouTubeAPIChannelMdl>,
};

export type YouTubeAPIHandlerContextState = {
  fetchChannels: () => Promise<YouTubeAPIChannelsResponseMdl>,
  fetchChannelFromChannelUrl: string => Promise<YouTubeAPIChannelsResponseMdl>,
  fetchChannelFromUserUrl: string => Promise<YouTubeAPIChannelsResponseMdl>,
  openYouTubeChannelModal: string => void,
};

export const YouTubeAPIHandlerContext = React.createContext();

export const useYouTubeAPIHandler = (): YouTubeAPIHandlerContextState =>
  useContext(YouTubeAPIHandlerContext);

const getYouTubeResponseItems = (response: any) => {
  return get(response, 'result.items', []);
};

type Props = {
  children: any,
};

const YouTubeAPIHandler = ({ children }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [api, setApi] = useState();
  const [selectingChannel, setSelectingChannel] = useState();
  const [planKey, setPlanKey] = useState();
  const [channelInfo, setChannelInfo] = useState();
  const [isPrimaryChannel, setIsPrimaryChannel] = useState();
  const [extraYouTubeChannels, setExtraYouTubeChannels] = useState([]);

  const authenticate = () => {
    console.log('authenticate...');
    return gapi.auth2
      .getAuthInstance()
      .signIn({ scope: 'https://www.googleapis.com/auth/youtube.readonly' })
      .then(() => {})
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when authenticating youtube api');
        Sentry.captureException(error);
        console.error(error);
        return Promise.reject(error);
      });
  };

  const fetchChannels = async (): YouTubeAPIChannelsResponseMdl | Promise<any> => {
    await authenticate();
    console.log('fetch youtube channels...');
    return gapi.client.youtube.channels
      .list({
        part: 'snippet,contentDetails,statistics',
        mine: true,
      })
      .then(response => {
        console.log('Response', response);
        if (!response) {
          console.log(`No response information was returned by Google Auth`);
          throw new YouTubeChannelNoAvaliableChannelError(
            'No response information was returned by Google Auth'
          );
        }
        if (!response.result) {
          console.warn(`no result in response.`);
          return Promise.reject();
        }
        const responseItems = getYouTubeResponseItems(response);
        if (responseItems.length === 0) {
          console.log('No channel information was returned by Google Auth');
          throw new YouTubeChannelNoAvaliableChannelError(
            'No channel information was returned by Google Auth'
          );
        }
        setChannelInfo({
          youtubeChannelId: responseItems[0].id,
          title: responseItems[0].snippet.title,
          thumbnail: responseItems[0].snippet.thumbnails.default.url,
          subscriberCount: responseItems[0].statistics.subscriberCount,
          videoCount: responseItems[0].statistics.videoCount,
        });
        return response.result;
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when fetching youtube channels');
        Sentry.captureException(error);
        console.error(error);
        return Promise.reject(error);
      });
  };

  const fetchChannelFromChannelUrl = async (
    channelID: string
  ): YouTubeAPIChannelsResponseMdl | Promise<any> => {
    // await authenticate
    return gapi.client.youtube.channels
      .list({
        part: 'snippet,contentDetails,statistics',
        id: channelID,
      })
      .then(response => {
        if (!response.result) {
          console.warn(`no result in response.`);
          return Promise.reject();
        }
        return response.result;
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage(
          'Something went wrong when fetching youtube channels with the channel URL'
        );
        Sentry.captureException(error);
        console.error(error);
        return Promise.reject(error);
      });
  };

  const fetchChannelFromUserUrl = async (
    username: string
  ): YouTubeAPIChannelsResponseMdl | Promise<any> => {
    // await authenticate
    return gapi.client.youtube.channels
      .list({
        part: 'snippet,contentDetails,statistics',
        forUsername: username,
      })
      .then(response => {
        if (!response.result) {
          console.warn(`no result in response.`);
          return Promise.reject();
        }
        return response.result;
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage(
          'Something went wrong when fetching youtube channels with the user URL'
        );
        Sentry.captureException(error);
        console.error(error);
        return Promise.reject(error);
      });
  };

  const initApi = () => {
    console.log('initApi');

    try {
      gapi.client
        .init({
          apiKey: process.env.GATSBY_GOOGLE_API_KEY,
          clientId: process.env.GATSBY_GOOGLE_CLIENT_ID,
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'],
          // cookiepolicy: 'single_host_origin',
          // ux_mode: 'redirect',
          // redirect_uri: 'http://localhost:8000/googleAuth',
          scope: 'https://www.googleapis.com/auth/youtube.readonly',
        })
        .then(() => {
          setApi(gapi.auth2.getAuthInstance());
          setInitialized(true);
          // authenticate();
        });
    } catch (error) {
      Sentry.captureMessage('Something went wrong when initializing google api client');
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const openYouTubeChannelModal = (
    subscriptionKey: string,
    planKey: string,
    channelInfo: any,
    isPrimaryChannel: boolean,
    extraYouTubeChannels: Array<any>
  ) => {
    setChannelInfo(channelInfo);
    setPlanKey(planKey);
    setSelectingChannel(subscriptionKey);
    setIsPrimaryChannel(isPrimaryChannel);
    setExtraYouTubeChannels(extraYouTubeChannels);
  };

  const closeYouTubeChannelModal = () => {
    setSelectingChannel(null);
  };

  useEffect(() => {
    console.log('load youtube...');
    loadjs(['https://apis.google.com/js/api.js'], { returnPromise: true }).then(() => {
      console.log('loaded youtube...');
      try {
        gapi.load('client:auth2', initApi);
      } catch (error) {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when loading youtube api js file');
        Sentry.captureException(error);
        console.error(error);
      }
    });
  }, []);

  return (
    <YouTubeAPIHandlerContext.Provider
      value={{
        fetchChannels,
        openYouTubeChannelModal,
        fetchChannelFromChannelUrl,
        fetchChannelFromUserUrl,
      }}
    >
      {children}
      {selectingChannel && (
        <YouTubeChannelModal
          subscriptionKey={selectingChannel}
          planKey={planKey}
          channelInfo={channelInfo}
          isPrimaryChannel={isPrimaryChannel}
          extraYouTubeChannels={extraYouTubeChannels}
          onClose={closeYouTubeChannelModal}
        />
      )}
    </YouTubeAPIHandlerContext.Provider>
  );
};

export default YouTubeAPIHandler;
