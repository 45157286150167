// @flow
import React from 'react';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import SongContextWrapper from '../../../SongContextWrapper/SongContextWrapper';
import DisplaySongShareContextWrapper from '../../../FullDisplaySongCard/components/DisplaySongShareContext/DisplaySongShareContext';

type Props = {
  children: any,
  song: AlgoliaSongMdl,
};

const DisplayAudioCardWrapper = ({ children, song }: Props) => {
  return (
    <SongContextWrapper song={song}>
      <DisplaySongShareContextWrapper song={song}>{children}</DisplaySongShareContextWrapper>
    </SongContextWrapper>
  );
};

export default DisplayAudioCardWrapper;
