// Sorting Options: remove any to take out from site
import { get } from 'lodash';
import { isProdEnv } from '../../../../../../utils/env';

export const RESULTS_SORTING_METHODS = {
  newest: 'newest',
  oldest: 'oldest',
  popularity: 'popularity',
  popularity_ascending: 'popularity_ascending',
};

export const getResultsSortingMethods = () => {
  if (isProdEnv()) {
    return RESULTS_SORTING_METHODS;
  }

  return RESULTS_SORTING_METHODS;
};

export const getSortingMethod = (method: string) => {
  const resultMethods = getResultsSortingMethods();
  return get(resultMethods, method, resultMethods.newest);
};
