// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import { smallPlainTextCss } from '../../../../../../styles/typography/typography';
import { SmallMonoTextButton } from '../../../../../../components/Button/Button';
import {
  NON_HOVER_DEVICE,
  SMALL_DEVICE_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import {
  useMiscCopy,
  useAllowlistStatusesCopy,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getMiscCopyConnectButton,
  getMiscCopyDisconnectButton,
} from '../../../../../../cms/miscCopy';
import SpinnerIcon from '../../../../../../components/SpinnerIcon/SpinnerIcon';
import { lightColor } from '../../../../../../styles/config/colors';
import {
  ALLOWLIST_STATUSES,
  getAllowListCurrentStateForceSuccessfulIfPending,
} from '../../../../../../api/firebase/user/allowlist';
import type { AllowlistDisplayData } from '../../../../../../api/firebase/user/allowlist';
import AllowlistBadge from '../AllowlistBadge/AllowlistBadge';
import {
  getAllowlistingUnsuccessfulNoticeMessage,
  getAllowlistingUnsuccessfulNoticeCta,
} from '../../../../../../cms/allowlistingStatuses';
import { useSubscriptionsContext } from '../../../SubscriptionsContextWrapper/SubscriptionsContextWrapper';

const socialAccountClassName = 'socialAccount';
const socialAccountConnectedClassName = 'socialAccount--connected';
const socialAccountHoverClassName = 'socialAccount--hover';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border: 0.5px solid ${transparentize(0.9, lightColor)};
  border-radius: 3px;
  cursor: pointer;

  &.${socialAccountConnectedClassName} {
    cursor: default;
  }
`;
export const ContainerFlex = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Info = styled('div')`
  ${smallPlainTextCss};
  flex: 1;
  display: flex;
  align-items: center;
  margin: 5px 10px;

  img {
    opacity: 0.4;
  }

  .${socialAccountConnectedClassName} & {
    color: ${transparentize(0, lightColor)};
    img {
      opacity: 1;
    }
  }
`;

export const Icon = styled('div')`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    height: 100%;
    padding-top: 4px;
    align-items: flex-start;
  }
`;

export const Name = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;
  }
  margin-left: 14px;
  color: ${transparentize(0.6, lightColor)};

  .${socialAccountConnectedClassName} & {
    color: ${transparentize(0, lightColor)};
  }

  &.${socialAccountHoverClassName} {
    color: ${transparentize(0, lightColor)};
  }
`;

export const Button = styled(SmallMonoTextButton)`
  opacity: 0;
  margin-right: 10px;

  ${NON_HOVER_DEVICE} {
    opacity: 1;
  }

  &:hover,
  &:focus,
  .${socialAccountClassName}:hover & {
    opacity: 1;
  }
`;

const tooltipActive = css`
  opacity: 1;
`;

export const spinnerClass = css`
  position: static;
`;

export const DisplayError = styled('div')`
  width: 100%;
  padding: 3px 10px 0px 10px;
`;

const AllowlistErrorMessage = styled('div')`
  width: 100%;
  background: rgba(255, 40, 30, 0.2);
  border-radius: 3px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;

  & p {
    width: 100%;
  }
`;

const UnsuccessfulAllowlistMessageCta = styled('span')`
  display: inline;
  padding-left: 5px;
  cursor: pointer;
  text-decoration: underline;
`;

export const AllowlistUnsuccessfulMessage = ({ youtubeName }: { youtubeName: string }) => {
  const allowlistCopy = useAllowlistStatusesCopy();
  const { openAllowlistUnsuccessfulModal } = useSubscriptionsContext();

  const popUpUnsuccessfulAllowlist = (deniedChannels: Array<string>) => {
    openAllowlistUnsuccessfulModal(deniedChannels);
  };

  return (
    <AllowlistErrorMessage>
      <p>
        {getAllowlistingUnsuccessfulNoticeMessage(allowlistCopy)}
        <UnsuccessfulAllowlistMessageCta onClick={() => popUpUnsuccessfulAllowlist([youtubeName])}>
          {getAllowlistingUnsuccessfulNoticeCta(allowlistCopy)}
        </UnsuccessfulAllowlistMessageCta>
      </p>
    </AllowlistErrorMessage>
  );
};

type Props = {
  primaryYouTubeBusy?: boolean,
  name: string,
  connected: boolean,
  icon: any,
  onConnect?: ({}) => void,
  onDisconnect?: ({}) => void,
  tooltipDisplay: boolean,
  allowlistData: AllowlistDisplayData,
  showAllowlist: boolean,
  onTrial: boolean,
};

const Account = ({
  primaryYouTubeBusy = false,
  name,
  connected,
  icon,
  onConnect = () => {},
  onDisconnect = () => {},
  tooltipDisplay,
  allowlistData,
  showAllowlist,
  onTrial,
}: Props) => {
  const miscCopy = useMiscCopy();
  const [accountHover, setAccountHover] = useState(false);

  const allowListCurrentState = getAllowListCurrentStateForceSuccessfulIfPending(allowlistData);

  const handleHover = () => {
    setAccountHover(true);
  };

  const handleHoverOut = () => {
    setAccountHover(false);
  };

  const handleClick = event => {
    if (!connected) {
      onConnect(event);
    }
  };

  return (
    <Container
      className={cx(socialAccountClassName, {
        [socialAccountConnectedClassName]: connected,
      })}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOut}
      onClick={handleClick}
    >
      <ContainerFlex>
        <Info>
          <Icon>
            <img src={icon} alt="account icon" />
          </Icon>
          <Name className={cx({ [socialAccountHoverClassName]: accountHover })}>
            {name}
            {showAllowlist && (
              <AllowlistBadge allowlistSate={allowListCurrentState} onTrial={onTrial} />
            )}
          </Name>
        </Info>
        <div>
          {primaryYouTubeBusy && <SpinnerIcon inverse containerClassName={spinnerClass} />}
          {connected && !primaryYouTubeBusy && (
            <Button onClick={onDisconnect}>{getMiscCopyDisconnectButton(miscCopy)}</Button>
          )}
          {!connected && !primaryYouTubeBusy && (
            <Button className={cx({ [tooltipActive]: tooltipDisplay })}>
              {getMiscCopyConnectButton(miscCopy)}
            </Button>
          )}
        </div>
      </ContainerFlex>
      {allowListCurrentState === ALLOWLIST_STATUSES.denied && !onTrial && (
        <DisplayError>
          <AllowlistUnsuccessfulMessage youtubeName={name} />
        </DisplayError>
      )}
    </Container>
  );
};

export default Account;
