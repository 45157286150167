// @flow
import React from 'react';
import styled from 'react-emotion';
import { StringParam, useQueryParam } from 'use-query-params';
import { css, cx } from 'emotion';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import AudioCardWrapper from '../../../../../song/components/AudioCardWrapper/AudioCardWrapper';
import { getSongID } from '../../../../../api/algolia/song';
import EmbedSongCard from '../../../../../song/components/EmbedSongCard/EmbedSongCard';
import { darkColor } from '../../../../../styles/config/colors';
import { PLAYER_TYPES } from '../../../../../song/data';

const useBackgroundColor = () => {
  return useQueryParam('bgColor', StringParam);
};

const Container = styled('div')`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 500;
  background-color: ${props => (props.backgroundColor ? `#${props.backgroundColor}` : darkColor)};
  overflow: hidden;

  ${SMALL_DEVICE_BREAKPOINT} {
    position: fixed;
    z-index: 500;
  }
`;

const EmbedSongContainer = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 900px;
  margin: 0 auto;
`;

const fullWrapperClass = css`
  ${LARGE_DEVICE_BREAKPOINT} {
    width: 80%;
    padding-bottom: 45px;

    &:not(:first-child) {
      padding-top: 45px;
    }

    &:first-child {
      padding-top: 0;

      &::before {
        visibility: hidden;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 24px;
    width: 85%;
  }
`;

export const ListItem = styled('div')`
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }

  ${LARGE_DEVICE_BREAKPOINT} {
    padding: 10px 0;
    &::after,
    &::before {
      content: '';
      height: 1px;
      position: absolute;
      left: 30px;
      right: 30px;
      border-top: 1px solid rgba(240, 240, 235, 0.05);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
      visibility: hidden;
    }

    &:last-child {
      &::after {
        visibility: visible;
      }
    }

    &:first-child:last-child {
      &::after {
        visibility: hidden;
      }
    }
  }
  //&:first-child {
  //  &::after {
  //    visibility: visible;
  //  }
  //}
`;

type Props = {
  initialSelectedIndex: number,
  songs: Array<AlgoliaSongMdl>,
};

const EmbedSongView = ({ initialSelectedIndex, songs }: Props) => {
  const [backgroundColor] = useBackgroundColor();

  return (
    <Container backgroundColor={backgroundColor}>
      <EmbedSongContainer>
        {songs.map(song => {
          if (song.type === 'full_mix') {
            return (
              <AudioCardWrapper
                autoplay={false}
                autoload
                song={song}
                key={getSongID(song)}
                mainAudioCard={false}
                playerType={PLAYER_TYPES.embed}
              >
                <ListItem className={fullWrapperClass}>
                  <EmbedSongCard song={song} />
                </ListItem>
              </AudioCardWrapper>
            );
          }
        })}
      </EmbedSongContainer>
    </Container>
  );
};

export default EmbedSongView;
