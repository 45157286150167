// @flow
import React from 'react';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';
import FeaturedSfx from '../BrowseView/components/FeaturedSfx';
import TagList from '../GenresView/components/TagList/TagList';

type Props = {
  path: string,
};

// SFX一覧ページ
const SoundEffectsView = ({ path }: Props) => {
  return (
    <BrowseLayout path={path}>
      <FeaturedSfx viewAll oversizedTile page="sfxPage" rows={2} />
      <TagList tagCategory="type" searchPathParams={'&viewing=sfx'} />
    </BrowseLayout>
  );
};

export default SoundEffectsView;
