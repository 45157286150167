// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import parse from 'html-react-parser';
import { GlobalHotKeys } from 'react-hotkeys';
import { ICONS } from '../../../../images/paths';
import {
  getKeyboardShortcutsFooterPopupTitle,
  getKeyboardShortcutsFooterPopupDescription,
  getKeyboardShortcutsModalKeys,
} from '../../../../cms/keyboardShortucts';
import { useModalsContext } from '../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useKeyboardShortcutsCopy } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPlayerKeyboardShortcuts } from '../../../../utils/keyboardShortcuts';
import { lightColor } from '../../../../styles/config/colors';
import { isLargeMobileDeviceSize } from '../../../../utils/device';

const KeyboardShortcutsIconWrapper = styled('div')`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: rgba(15, 15, 20, 0.9);
  text-align: center;
`;

const KeyboardShortcutPopup = styled('div')`
  height: 195px;
  width: 250px;
  border: solid 1px #dcdad8;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.2);
  display: none;
  position: relative;
  bottom: 230px;
  right: 225px;
  padding: 25px 20px;
`;

const ShortcutTitle = styled('h6')`
  color: #0f0f24;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 25px;
  text-align: center;
  padding-bottom: 5px;
`;

const ShortcutDescription = styled('span')`
  color: rgba(15, 15, 20, 0.6);
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  text-align: center;
  padding-bottom: 20px;
`;

const ShortcutKeys = styled('span')`
  border-radius: 5px;
  background-color: #0f0f24;
  box-shadow: 0 2px 0 0 #616061;
  color: ${lightColor};
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 19px;
  text-align: center;
  padding: 12px 16px;
  margin: 0px 5px;
`;

const visibleShortcutsPopup = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const KeyboardShortcutsFooter = () => {
  const [visibleKeyboardShortcuts, setVisibleKeyboardShortcuts] = useState(false);
  const {
    showKeyboardShortcutsModal,
    setVisibleKeyboardShortcutsOpen,
    keyboardShortcutsOpen,
  } = useModalsContext();
  const keyboardShortcutsCopy = useKeyboardShortcutsCopy();

  const showKeyboarShortcutPopup = () => {
    if (isLargeMobileDeviceSize()) return;
    setVisibleKeyboardShortcuts(true);
  };

  const hideKeyboarShortcutPopup = () => {
    if (isLargeMobileDeviceSize()) return;
    setVisibleKeyboardShortcuts(false);
  };

  const toggleKeyboarShortcutModal = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    setVisibleKeyboardShortcutsOpen(!keyboardShortcutsOpen);
  };

  const handleIconClick = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    showKeyboardShortcutsModal();
  };

  const keyboardShortcutHandlers = { open_shortcuts_map: toggleKeyboarShortcutModal };

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <KeyboardShortcutsIconWrapper
        onMouseOver={showKeyboarShortcutPopup}
        onMouseOut={hideKeyboarShortcutPopup}
        onFocus={showKeyboarShortcutPopup}
        onBlur={hideKeyboarShortcutPopup}
        onClick={handleIconClick}
      >
        <span>
          <img src={ICONS.keyboardShortcuts} alt="Keyboard Shortcuts" width={24} height={24} />
        </span>
        <KeyboardShortcutPopup
          className={cx({
            [visibleShortcutsPopup]: visibleKeyboardShortcuts,
          })}
          onClick={ev => ev.stopPropagation()}
        >
          <ShortcutTitle>
            {getKeyboardShortcutsFooterPopupTitle(keyboardShortcutsCopy)}
          </ShortcutTitle>
          <ShortcutDescription>
            {parse(getKeyboardShortcutsFooterPopupDescription(keyboardShortcutsCopy))}
          </ShortcutDescription>
          <div>
            {getKeyboardShortcutsModalKeys(keyboardShortcutsCopy).map(key => {
              return <ShortcutKeys key={key}>{key}</ShortcutKeys>;
            })}
          </div>
        </KeyboardShortcutPopup>
      </KeyboardShortcutsIconWrapper>
    </>
  );
};

export default KeyboardShortcutsFooter;
