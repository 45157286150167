// @flow
import { getAlgoliaCollectionsIndex } from './client';
import { isAlgoliaAnalyticsEnabled } from '../../utils/env';

export type AlgoliaCollectionMdl = {
  objectID: string,
  title: string,
  tags: Array<string>,
  image: string,
  description: string,
  songs: Array<string>,
  collectionSlug: string,
};

export type AlgoliaCollectionResults = {
  hits: Array<AlgoliaCollectionMdl>,
  nbHits: number,
  nbPages: number,
};

export const searchAlgoliaCollections = (
  searchTerm: string,
  filters: string,
  hitsPerPage: number,
  locale: string
): Promise<AlgoliaCollectionResults> => {
  const indexToUse = getAlgoliaCollectionsIndex(locale);
  return indexToUse
    .search({
      analytics: isAlgoliaAnalyticsEnabled(),
      query: searchTerm,
      hitsPerPage,
      filters,
    })
    .then((content: AlgoliaCollectionResults) => {
      return content;
    });
};
