// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type ResetPasswordModalGraphMdl = {
  data: {
    reset_password_header: {
      text: string,
    },
    reset_password_instruction: {
      text: string,
    },
    reset_password_success_message: {
      html: string,
    },
    email_address_placeholder: {
      text: string,
    },
    email_me_button: {
      text: string,
    },
    error_message: {
      text: string,
    },
  },
};

export const getResetPasswordModalHeading = (copy: ResetPasswordModalGraphMdl): string => {
  return get(copy, 'data.reset_password_header.text', '') || '';
};

export const getResetPasswordModalSuccess = (copy: ResetPasswordModalGraphMdl): string => {
  return get(copy, 'data.reset_password_success_message.html', '') || '';
};

export const getResetPasswordModalInstructions = (copy: ResetPasswordModalGraphMdl): string => {
  return get(copy, 'data.reset_password_instruction.text', '') || '';
};

export const getResetPasswordModalEmailMeButton = (copy: ResetPasswordModalGraphMdl): string => {
  return get(copy, 'data.email_me_button.text', '') || '';
};

export const getResetPasswordModalEmailAddressPlaceholder = (
  copy: ResetPasswordModalGraphMdl
): string => {
  return get(copy, 'data.email_address_placeholder.text', '') || '';
};

export const query = graphql`
  fragment ResetPasswordModal on PrismicResetPasswordModal {
    data {
      reset_password_header {
        text
      }
      reset_password_instruction {
        text
      }
      reset_password_success_message {
        html
      }
      email_address_placeholder {
        text
      }
      email_me_button {
        text
      }
      error_message {
        text
      }
    }
  }
`;
