import React from 'react';
import styled from 'react-emotion';
import { ListItem } from '../../../../../song/components/CondensedLoopSongCard/CondensedLoopSongCard';
import ExpandableDisplaySongCard from '../../../../../song/components/ExpandableDisplaySongCard/ExpandableDisplaySongCard';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';

const StyledSongContainer = styled('div')`
  position: relative;
  margin-bottom: 5px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-bottom: 10px;
  }
`;

const TabSong = ({ song, index }: Props) => {
  return (
    <StyledSongContainer>
      <ExpandableDisplaySongCard song={song} songPosition={index} />
    </StyledSongContainer>
  );
};

export default TabSong;
