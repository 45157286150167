// @flow
import React, { useState } from 'react';
import ProfileSection from '../ProfileSection/ProfileSection';
import { useProfilePageCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getProfilePageChangePasswordHeading,
  getProfilePagePasswordInstructions,
  getProfilePagePasswordResetButton,
  getProfilePagePasswordResetSuccessMessage,
} from '../../../../../../../cms/profilePage';
import Button from '../../../../../../../components/Button/Button';
import { useUserEmail } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useResetPassword } from '../../../../../../../api/auth/account';

type Props = {};

const ResetPassword = () => {
  const profileCopy = useProfilePageCopy();
  const email = useUserEmail();
  const [resetPassword, busy] = useResetPassword();
  const [passwordHasReset, setPasswordHasReset] = useState(false);
  const handleReset = () => {
    resetPassword(email).then(() => {
      setPasswordHasReset(true);
    });
  };
  const description = passwordHasReset
    ? getProfilePagePasswordResetSuccessMessage(profileCopy).replace(
        '$EMAIL$',
        `<strong>${email}</strong>`
      )
    : getProfilePagePasswordInstructions(profileCopy);
  return (
    <ProfileSection
      heading={getProfilePageChangePasswordHeading(profileCopy)}
      description={description}
    >
      {!passwordHasReset && (
        <Button onClick={handleReset} loading={busy}>
          {getProfilePagePasswordResetButton(profileCopy)}
        </Button>
      )}
    </ProfileSection>
  );
};

export default ResetPassword;
