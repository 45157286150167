// @flow
import React, { useState, useEffect } from 'react';
import { cx } from 'emotion';
import ls from 'local-storage';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import LoadingDisplay from '../../../components/LoadingDisplay/LoadingDisplay';
import PageComponent from '../../components/PageComponent/PageComponent';
import { firebaseApiHandler } from '../../../api/firebase/api';
import {
  Container,
  Main,
  mainLoadingClass,
} from '../ResultsScreen/components/ResultsView/ResultsView';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useNavigate } from '../../hooks';
import { ROUTES } from '../../routes';

const Content = ({ referralCode = '' }: { referralCode: string }) => {
  const [loadingReferral, setLoadingReferral] = useState(true);
  const { setReferralCoupon, setShowReferralBanner } = useSubscriptionsContext();
  const navigate = useNavigate();

  const getChargebeeCouponData = async () => {
    const couponData = await firebaseApiHandler.fetchReferralCouponData(referralCode);
    return couponData;
  };

  useEffect(() => {
    getChargebeeCouponData()
      .then(response => {
        if (response) {
          response.id = referralCode;
          ls.set('REFERRAL_COUPON_DATA', response);
          setReferralCoupon(response);
          setShowReferralBanner(true);

          navigate(ROUTES.music.navigatePath({}));
        }
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when searching for referral coupon');
        Sentry.captureException(error);
        setLoadingReferral(false);
        navigate(ROUTES.music.navigatePath({}));
        return Promise.reject(error);
      });
  }, []);

  return (
    <LayoutWrapper bodyPadding>
      <Container>
        <Main
          className={cx({
            [mainLoadingClass]: loadingReferral,
          })}
        >
          {loadingReferral && <LoadingDisplay />}
        </Main>
      </Container>
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
  referralCode: string,
};

const ReferralScreen = ({ path, referralCode }: Props) => {
  return (
    <PageComponent path={path} referralCode={referralCode}>
      <Content referralCode={referralCode} />;
    </PageComponent>
  );
};

export default ReferralScreen;
