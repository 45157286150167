// @flow
import React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';
import App from '../components/App/App';
import EmbedApp from '../components/EmbedApp/EmbedApp';
import CopyContextWrapper from '../copy/components/CopyContextWrapper/CopyContextWrapper';
import type { AppData } from '../copy/components/CopyContextWrapper/CopyContextWrapper';
import ErrorBoundary from '../errors/components/ErrorBoundary/ErrorBoundary';
import SiteMeta from '../components/SiteMeta/SiteMeta';
import { PATHS } from '../routing/routes';

type Props = {
  data: AppData,
};

export default ({ data }: Props) => (
  <ErrorBoundary>
    <CopyContextWrapper data={data}>
      <Router basepath="/">
        <EmbedApp path={PATHS.embedSong} key={PATHS.embedSong} />
        <EmbedApp path={PATHS.embedSongJapanese} key={PATHS.embedSong} />
        <EmbedApp path={PATHS.embedSongChinese} key={PATHS.embedSong} />
        <App default path={PATHS.mainApp} key={PATHS.mainApp} />
      </Router>
      <SiteMeta />
    </CopyContextWrapper>
  </ErrorBoundary>
);

export const pageQuery = graphql`
  query SiteQuery {
    allPrismicPricingPlan(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...PricingPlan
        }
      }
    }
    jpAllPrismicPricingPlan: allPrismicPricingPlan(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...PricingPlan
        }
      }
    }
    prismicPricingPage(lang: { eq: "en-au" }) {
      ...PricingPage
    }
    jpPrismicPricingPage: prismicPricingPage(lang: { eq: "ja-jp" }) {
      ...PricingPage
    }
    allPrismicPricingPagePlan(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...PricingPagePlan
        }
      }
    }
    jpAllPrismicPricingPagePlan: allPrismicPricingPagePlan(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...PricingPagePlan
        }
      }
    }
    prismicCustomOrderPage(lang: { eq: "en-au" }) {
      ...CustomOrderPage
    }
    jpPrismicCustomOrderPage: prismicCustomOrderPage(lang: { eq: "ja-jp" }) {
      ...CustomOrderPage
    }
    allPrismicCustomTrackProduct(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...CustomTrackProduct
        }
      }
    }
    jpAllPrismicCustomTrackProduct: allPrismicCustomTrackProduct(
      filter: { lang: { eq: "ja-jp" } }
    ) {
      edges {
        node {
          ...CustomTrackProduct
        }
      }
    }

    prismicSiteHeader(lang: { eq: "en-au" }) {
      ...Header
    }
    jpPrismicSiteHeader: prismicSiteHeader(lang: { eq: "ja-jp" }) {
      ...Header
    }
    cnPrismicSiteHeader: prismicSiteHeader(lang: { eq: "zh-cn" }) {
      ...Header
    }
    prismicSignUpPage(lang: { eq: "en-au" }) {
      ...SignUpPage
    }
    jpPrismicSignUpPage: prismicSignUpPage(lang: { eq: "ja-jp" }) {
      ...SignUpPage
    }
    prismicSignUpEnterprisePage(lang: { eq: "en-au" }) {
      ...SignUpEnterprisePage
    }
    jpPrismicSignUpEnterprisePage: prismicSignUpEnterprisePage(lang: { eq: "ja-jp" }) {
      ...SignUpEnterprisePage
    }
    prismicSignInPage(lang: { eq: "en-au" }) {
      ...SignInPage
    }
    jpPrismicSignInPage: prismicSignInPage(lang: { eq: "ja-jp" }) {
      ...SignInPage
    }
    prismicFindMusicPage(lang: { eq: "en-au" }) {
      ...FindMusicPage
    }
    jpPrismicFindMusicPage: prismicFindMusicPage(lang: { eq: "ja-jp" }) {
      ...FindMusicPage
    }
    prismicMusicPage(lang: { eq: "en-au" }) {
      ...MusicPage
    }
    jpPrismicMusicPage: prismicMusicPage(lang: { eq: "ja-jp" }) {
      ...MusicPage
    }
    prismicMiscCopy(lang: { eq: "en-au" }) {
      ...MiscCopy
    }
    jpPrismicMiscCopy: prismicMiscCopy(lang: { eq: "ja-jp" }) {
      ...MiscCopy
    }
    cnPrismicMiscCopy: prismicMiscCopy(lang: { eq: "zh-cn" }) {
      ...MiscCopy
    }
    prismicDownloadCountMenu(lang: { eq: "en-au" }) {
      ...DownloadCountMenu
    }
    jpPrismicDownloadCountMenu: prismicDownloadCountMenu(lang: { eq: "ja-jp" }) {
      ...DownloadCountMenu
    }
    prismicTrialCounterMenu(lang: { eq: "en-au" }) {
      ...TrialCounterMenu
    }
    jpPrismicTrialCounterMenu: prismicTrialCounterMenu(lang: { eq: "ja-jp" }) {
      ...TrialCounterMenu
    }
    prismicResultsPage(lang: { eq: "en-au" }) {
      ...ResultsPage
    }
    jpPrismicResultsPage: prismicResultsPage(lang: { eq: "ja-jp" }) {
      ...ResultsPage
    }
    prismicSiteFooter: prismicSiteFoote(lang: { eq: "en-au" }) {
      ...SiteFooter
    }
    jpPrismicSiteFooter: prismicSiteFoote(lang: { eq: "ja-jp" }) {
      ...SiteFooter
    }
    cnPrismicSiteFooter: prismicSiteFoote(lang: { eq: "zh-cn" }) {
      ...SiteFooter
    }
    prismicSubscriptionsPage(lang: { eq: "en-au" }) {
      ...SubscriptionsPage
    }
    jpPrismicSubscriptionsPage: prismicSubscriptionsPage(lang: { eq: "ja-jp" }) {
      ...SubscriptionsPage
    }
    prismicNoConnectionsModal(lang: { eq: "en-au" }) {
      ...NoConnectionsModal
    }
    jpPrismicNoConnectionsModal: prismicNoConnectionsModal(lang: { eq: "ja-jp" }) {
      ...NoConnectionsModal
    }
    allPrismicPopUpMessage(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...PopupMessage
        }
      }
    }
    jpAllPrismicPopUpMessage: allPrismicPopUpMessage(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...PopupMessage
        }
      }
    }
    prismicSignUpModal(lang: { eq: "en-au" }) {
      ...SignUpModal
    }
    jpPrismicSignUpModal: prismicSignUpModal(lang: { eq: "ja-jp" }) {
      ...SignUpModal
    }
    prismicPaymentSummaryPage(lang: { eq: "en-au" }) {
      ...PaymentSummaryPage
    }
    jpPrismicPaymentSummaryPage: prismicPaymentSummaryPage(lang: { eq: "ja-jp" }) {
      ...PaymentSummaryPage
    }
    prismicConntectPaypalPage(lang: { eq: "en-au" }) {
      ...ConnectPaypal
    }
    jpPrismicConntectPaypalPage: prismicConntectPaypalPage(lang: { eq: "ja-jp" }) {
      ...ConnectPaypal
    }
    prismicChromeExtension(lang: { eq: "en-au" }) {
      ...ChromeExtension
    }
    jpPrismicChromeExtension: prismicChromeExtension(lang: { eq: "ja-jp" }) {
      ...ChromeExtension
    }
    prismicEditKeywordsPage(lang: { eq: "en-au" }) {
      ...EditKeywordsPage
    }
    jpPrismicEditKeywordsPage: prismicEditKeywordsPage(lang: { eq: "ja-jp" }) {
      ...EditKeywordsPage
    }
    prismicPrivacyPolicyPage(lang: { eq: "en-au" }) {
      ...PrivacyPolicy
    }
    jpPrismicPrivacyPolicyPage: prismicPrivacyPolicyPage(lang: { eq: "ja-jp" }) {
      ...PrivacyPolicy
    }
    prismicTermsOfServicePage(lang: { eq: "en-au" }) {
      ...TermsPage
    }
    jpPrismicTermsOfServicePage: prismicTermsOfServicePage(lang: { eq: "ja-jp" }) {
      ...TermsPage
    }
    prismicBusinessSubscriptionMusicLicensePage(lang: { eq: "en-au" }) {
      ...BusinessSubscriptionMusicLicensePage
    }
    jpPrismicBusinessSubscriptionMusicLicensePage: prismicBusinessSubscriptionMusicLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...BusinessSubscriptionMusicLicensePage
    }
    prismicCreatorSubscriptionMusicLicensePage(lang: { eq: "en-au" }) {
      ...CreatorSubscriptionMusicLicensePage
    }
    jpPrismicCreatorSubscriptionMusicLicensePage: prismicCreatorSubscriptionMusicLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...CreatorSubscriptionMusicLicensePage
    }
    prismicCreatorProSubscriptionMusicLicensePage(lang: { eq: "en-au" }) {
      ...CreatorProSubscriptionMusicLicensePage
    }
    jpPrismicCreatorProSubscriptionMusicLicensePage: prismicCreatorProSubscriptionMusicLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...CreatorProSubscriptionMusicLicensePage
    }
    prismicEnterpriseSubscriptionMusicLicensePage(lang: { eq: "en-au" }) {
      ...EnterpriseSubscriptionMusicLicensePage
    }
    jpPrismicEnterpriseSubscriptionMusicLicensePage: prismicEnterpriseSubscriptionMusicLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...EnterpriseSubscriptionMusicLicensePage
    }
    prismicCustomTrackLicensePage(lang: { eq: "en-au" }) {
      ...CustomTrackLicensePage
    }
    jpPrismicCustomTrackLicensePage: prismicCustomTrackLicensePage(lang: { eq: "ja-jp" }) {
      ...CustomTrackLicensePage
    }
    prismicSingleTrackAllMediaLicensePage(lang: { eq: "en-au" }) {
      ...SingleTrackAllMediaLicensePage
    }
    jpPrismicSingleTrackAllMediaLicensePage: prismicSingleTrackAllMediaLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...SingleTrackAllMediaLicensePage
    }
    prismicExclusiveRightsTrackLicensePage(lang: { eq: "en-au" }) {
      ...ExclusiveRightsTrackLicensePage
    }
    jpPrismicExclusiveRightsTrackLicensePage: prismicExclusiveRightsTrackLicensePage(
      lang: { eq: "ja-jp" }
    ) {
      ...ExclusiveRightsTrackLicensePage
    }
    prismicResetPasswordModal(lang: { eq: "en-au" }) {
      ...ResetPasswordModal
    }
    jpPrismicResetPasswordModal: prismicResetPasswordModal(lang: { eq: "ja-jp" }) {
      ...ResetPasswordModal
    }
    prismicEmailSettingsPage(lang: { eq: "en-au" }) {
      ...EmailSettingsPage
    }
    jpPrismicEmailSettingsPage: prismicEmailSettingsPage(lang: { eq: "ja-jp" }) {
      ...EmailSettingsPage
    }
    prismicProfilePage(lang: { eq: "en-au" }) {
      ...ProfilePage
    }
    jpPrismicProfilePage: prismicProfilePage(lang: { eq: "ja-jp" }) {
      ...ProfilePage
    }
    prismicAdRevenuePage(lang: { eq: "en-au" }) {
      ...AdRevenuePage
    }
    jpPrismicAdRevenuePage: prismicAdRevenuePage(lang: { eq: "ja-jp" }) {
      ...AdRevenuePage
    }
    prismicBillingPage(lang: { eq: "en-au" }) {
      ...BillingPage
    }
    jpPrismicBillingPage: prismicBillingPage(lang: { eq: "ja-jp" }) {
      ...BillingPage
    }
    prismicSiteMeta(lang: { eq: "en-au" }) {
      ...SiteMeta
    }
    jpPrismicSiteMeta: prismicSiteMeta(lang: { eq: "ja-jp" }) {
      ...SiteMeta
    }
    prismicYoutubeConnectionErrorModal(lang: { eq: "en-au" }) {
      ...YoutubeError
    }
    jpPrismicYoutubeConnectionErrorModal: prismicYoutubeConnectionErrorModal(
      lang: { eq: "ja-jp" }
    ) {
      ...YoutubeError
    }
    prismicFooterPromptBanner(lang: { eq: "en-au" }) {
      ...FooterBanner
    }
    jpPrismicFooterPromptBanner: prismicFooterPromptBanner(lang: { eq: "ja-jp" }) {
      ...FooterBanner
    }
    prismicMenu(lang: { eq: "en-au" }) {
      ...ProfileMenu
    }
    jpPrismicMenu: prismicMenu(lang: { eq: "ja-jp" }) {
      ...ProfileMenu
    }
    zhPrismicMenu: prismicMenu(lang: { eq: "zh-cn" }) {
      ...ProfileMenu
    }
    prismicSignInModal(lang: { eq: "en-au" }) {
      ...SignInModal
    }
    jpPrismicSignInModal: prismicSignInModal(lang: { eq: "ja-jp" }) {
      ...SignInModal
    }
    prismicDowngradeSubscriptionModal(lang: { eq: "en-au" }) {
      ...DowngradeSubscriptionModal
    }
    jpPrismicDowngradeSubscriptionModal: prismicDowngradeSubscriptionModal(lang: { eq: "ja-jp" }) {
      ...DowngradeSubscriptionModal
    }
    prismicIntervalChangeSubscriptionModal(lang: { eq: "en-au" }) {
      ...ChangeIntervalSubscriptionModal
    }
    jpPrismicIntervalChangeSubscriptionModal: prismicIntervalChangeSubscriptionModal(
      lang: { eq: "ja-jp" }
    ) {
      ...ChangeIntervalSubscriptionModal
    }
    prismicChangeFreeTrialSubscriptionModal(lang: { eq: "en-au" }) {
      ...ChangeFreeTrialSubscriptionModal
    }
    jpPrismicChangeFreeTrialSubscriptionModal: prismicChangeFreeTrialSubscriptionModal(
      lang: { eq: "ja-jp" }
    ) {
      ...ChangeFreeTrialSubscriptionModal
    }
    prismicCancelSubscriptionModal(lang: { eq: "en-au" }) {
      ...CancelSubscriptionModal
    }
    jpPrismicCancelSubscriptionModal: prismicCancelSubscriptionModal(lang: { eq: "ja-jp" }) {
      ...CancelSubscriptionModal
    }
    prismicCancelFreeSubscriptionModal(lang: { eq: "en-au" }) {
      ...CancelFreeSubscriptionModal
    }
    jpPrismicCancelFreeSubscriptionModal: prismicCancelFreeSubscriptionModal(
      lang: { eq: "ja-jp" }
    ) {
      ...CancelFreeSubscriptionModal
    }
    prismicEnterpriseContactModal(lang: { eq: "en-au" }) {
      ...PrismicEnterpriseContactModal
    }
    jpPrismicEnterpriseContactModal: prismicEnterpriseContactModal(lang: { eq: "ja-jp" }) {
      ...PrismicEnterpriseContactModal
    }
    prismicConnectYoutubeChannelModal(lang: { eq: "en-au" }) {
      ...ConnectYouTubeModal
    }
    jpPrismicConnectYoutubeChannelModal: prismicConnectYoutubeChannelModal(lang: { eq: "ja-jp" }) {
      ...ConnectYouTubeModal
    }
    prismicTrack(lang: { eq: "en-au" }) {
      ...Track
    }
    jpPrismicTrack: prismicTrack(lang: { eq: "ja-jp" }) {
      ...Track
    }
    prismic404ErrorPage(lang: { eq: "en-au" }) {
      ...NotFoundPage
    }
    jpPrismic404ErrorPage: prismic404ErrorPage(lang: { eq: "ja-jp" }) {
      ...NotFoundPage
    }
    prismicShareTrackModal(lang: { eq: "en-au" }) {
      ...ShareTrackModal
    }
    jpPrismicShareTrackModal: prismicShareTrackModal(lang: { eq: "ja-jp" }) {
      ...ShareTrackModal
    }
    prismicSignInupErrors(lang: { eq: "en-au" }) {
      ...SignInUpErrors
    }
    jpPrismicSignInupErrors: prismicSignInupErrors(lang: { eq: "ja-jp" }) {
      ...SignInUpErrors
    }
    prismicErrorScreens(lang: { eq: "en-au" }) {
      ...ErrorScreens
    }
    jpPrismicErrorScreens: prismicErrorScreens(lang: { eq: "ja-jp" }) {
      ...ErrorScreens
    }
    prismicToastMessage(lang: { eq: "en-au" }) {
      ...ToastMessages
    }
    jpPrismicToastMessage: prismicToastMessage(lang: { eq: "ja-jp" }) {
      ...ToastMessages
    }
    prismicNoResultsPage(lang: { eq: "en-au" }) {
      ...NoResultsPage
    }
    jpPrismicNoResultsPage: prismicNoResultsPage(lang: { eq: "ja-jp" }) {
      ...NoResultsPage
    }
    prismicMicropartView(lang: { eq: "en-au" }) {
      ...MicropartView
    }
    jpPrismicMicropartView: prismicMicropartView(lang: { eq: "ja-jp" }) {
      ...MicropartView
    }
    prismicLeavePageModal(lang: { eq: "en-au" }) {
      ...LeavePageModal
    }
    jpPrismicLeavePageModal: prismicLeavePageModal(lang: { eq: "ja-jp" }) {
      ...LeavePageModal
    }
    prismicSubscriptionRequiredModal(lang: { eq: "en-au" }) {
      ...SubscriptionRequiredModal
    }
    jpPrismicSubscriptionRequiredModal: prismicSubscriptionRequiredModal(lang: { eq: "ja-jp" }) {
      ...SubscriptionRequiredModal
    }
    prismicSortingRestrictedModal(lang: { eq: "en-au" }) {
      ...SortingRestrictedModal
    }
    jpPrismicSortingRestrictedModal: prismicSortingRestrictedModal(lang: { eq: "ja-jp" }) {
      ...SortingRestrictedModal
    }
    prismicTagSearchModal(lang: { eq: "en-au" }) {
      ...TagSearchModal
    }
    jpPrismicTagSearchModal: prismicTagSearchModal(lang: { eq: "ja-jp" }) {
      ...TagSearchModal
    }
    prismicBookmarksPage(lang: { eq: "en-au" }) {
      ...BookmarksPage
    }
    jpPrismicBookmarksPage: prismicBookmarksPage(lang: { eq: "ja-jp" }) {
      ...BookmarksPage
    }
    prismicSearchBookmarks(lang: { eq: "en-au" }) {
      ...SearchBookmarks
    }
    jpPrismicSearchBookmarks: prismicSearchBookmarks(lang: { eq: "ja-jp" }) {
      ...SearchBookmarks
    }
    prismicDownloadHistoryPage(lang: { eq: "en-au" }) {
      ...DownloadHistoryPage
    }
    jpPrismicDownloadHistoryPage: prismicDownloadHistoryPage(lang: { eq: "ja-jp" }) {
      ...DownloadHistoryPage
    }
    allPrismicDiscountCode(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...Coupon
        }
      }
    }
    jpAllPrismicDiscountCode: allPrismicDiscountCode(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...Coupon
        }
      }
    }
    prismicWhitelistStatuses(lang: { eq: "en-au" }) {
      ...WhitelistStatuses
    }
    jpPrismicWhitelistStatuses: prismicWhitelistStatuses(lang: { eq: "ja-jp" }) {
      ...WhitelistStatuses
    }
    prismicReferralBanners(lang: { eq: "en-au" }) {
      ...ReferralBanners
    }
    jpPrismicReferralBanners: prismicReferralBanners(lang: { eq: "ja-jp" }) {
      ...ReferralBanners
    }
    prismicKeyboardShortcuts(lang: { eq: "en-au" }) {
      ...KeyboardShortcuts
    }
    jpPrismicKeyboardShortcuts: prismicKeyboardShortcuts(lang: { eq: "ja-jp" }) {
      ...KeyboardShortcuts
    }
    prismicCollectionsPage(lang: { eq: "en-au" }) {
      ...CollectionsPage
    }
    jpPrismicCollectionsPage: prismicCollectionsPage(lang: { eq: "ja-jp" }) {
      ...CollectionsPage
    }
    allPrismicCuratedCollection(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...CuratedCollection
        }
      }
    }
    jpAllPrismicCuratedCollection: allPrismicCuratedCollection(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...CuratedCollection
        }
      }
    }
    allPrismicArtistProfile(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...ArtistProfile
        }
      }
    }
    jpAllPrismicArtistProfile: allPrismicArtistProfile(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...ArtistProfile
        }
      }
    }
    allPrismicPosArtistProfile(filter: { lang: { eq: "en-au" } }) {
      edges {
        node {
          ...PosArtistProfile
        }
      }
    }
    jpAllPrismicPosArtistProfile: allPrismicPosArtistProfile(filter: { lang: { eq: "ja-jp" } }) {
      edges {
        node {
          ...PosArtistProfile
        }
      }
    }
  }
`;
