// @flow
import React from 'react';
import SearchIcon from '../../../../../../../../../assets/inline-assets/search-icon.svg';
import {
  getNoResultsFilteringHeading,
  getNoResultsFilteringMessage,
  getNoResultsMessage,
} from '../../../../../../../../../cms/noResultsPage';
import { useNoResultsPageCopy } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import SearchResultsMessageLayout from '../SearchResultsMessageLayout/SearchResultsMessageLayout';

const NoSearchResults = () => {
  const copy = useNoResultsPageCopy();
  const { appliedFilters } = useResultsManagerContext();
  const hasFiltersApplied = Object.keys(appliedFilters).length > 0;
  const description = hasFiltersApplied
    ? getNoResultsFilteringMessage(copy)
        .replace('<p>', '')
        .replace('</p>', '')
    : getNoResultsMessage(copy)
        .replace('<p>', '')
        .replace('</p>', '');

  return (
    <SearchResultsMessageLayout
      title={getNoResultsFilteringHeading(copy)}
      description={description}
      icon={<SearchIcon />}
    />
  );
};

export default NoSearchResults;
