// @flow
import React from 'react';
import styled from 'react-emotion';
import AudioBars from '../../../../../../../AudioBars/AudioBars';

const Container = styled('div')`
  height: 40px;
  width: 100%;
`;

type Props = {
  micropartIndex: number,
  alwaysShowMicroparts?: boolean,
};

const MicropartBars = ({ micropartIndex, alwaysShowMicroparts = false }: Props) => (
  <Container>
    <AudioBars
      micropartsLabelDuration
      micropartsHiglight={micropartIndex}
      alwaysShowMicroparts={alwaysShowMicroparts}
    />
  </Container>
);

export default MicropartBars;
