// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionNextBillingDate,
  getSubscriptionPlanKey,
} from '../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS } from '../../data';
import {
  getCancelFreeSubscriptionModalInstructions,
  getCancelFreeSubscriptionModalTitle,
  getCancelSubscriptionModalPageInstructions,
  getCancelSubscriptionModalTitle,
} from '../../../../cms/cancelSubscriptionModal';
import {
  useCancelFreeSubscriptionModalCopy,
  useCancelSubscriptionModalCopy,
  useMiscCopy,
  usePricingPlans,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPlan, getPricingPlanName } from '../../../../cms/pricingPlan';
import Button, { DarkButton } from '../../../../components/Button/Button';
import { getMiscCopyCancelButton, getMiscCopyConfirmButton } from '../../../../cms/miscCopy';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';

export const SubscriptionModalFooter = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;

    > button {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    flex-direction: column;

    > button {
      &:first-child {
        order: 2;
        margin-top: 10px;
      }

      &:last-child {
        order: 1;
      }
    }
  }
`;

type Props = {
  onClose: () => void,
  subscription: UserSubscriptionMdl,
};

const CancelSubscriptionModal = ({ onClose, subscription }: Props) => {
  const [busy, setBusy] = useState(false);
  const copy = useCancelSubscriptionModalCopy();
  const freeCopy = useCancelFreeSubscriptionModalCopy();
  const miscCopy = useMiscCopy();
  const pricingPlans = usePricingPlans();
  const { removeSubscription } = useSubscriptionsContext();

  const handleClose = () => {
    if (busy) return;
    onClose();
  };

  const isFree = SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription));

  const handleConfirm = () => {
    if (busy) return;
    setBusy(true);
    removeSubscription(subscription.key, false)
      .then(() => {
        handleClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when removing subscription');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const planName = getPricingPlanName(
    getPricingPlan(pricingPlans, getSubscriptionPlanKey(subscription))
  );
  const date = getSubscriptionNextBillingDate(subscription);

  const title = isFree
    ? getCancelFreeSubscriptionModalTitle(freeCopy)
    : getCancelSubscriptionModalTitle(copy);
  const instructions = isFree
    ? getCancelFreeSubscriptionModalInstructions(freeCopy)
    : getCancelSubscriptionModalPageInstructions(copy)
        .replace('$PLAN$', planName)
        .replace('$DATE$', date);

  return (
    <ClassicModalLayout onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={title}
        description={instructions}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{getMiscCopyCancelButton(miscCopy)}</DarkButton>
            <Button loading={busy} onClick={handleConfirm}>
              {getMiscCopyConfirmButton(miscCopy)}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default CancelSubscriptionModal;
