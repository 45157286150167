import React from 'react';
import styled from 'react-emotion';
import { usePosArtistProfile } from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import VerifiedIcon from '../../../../../assets/inline-assets/verified-icon.svg';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useNavigate } from '../../../../../routing/hooks';
import { ROUTES } from '../../../../../routing/routes';
import { getPos } from '../../../../../cms/artistProfile';
import { MEDIA_BREAKPOINTS } from '../../../../../styles/responsive';

const StyledArtistName = styled('span')`
  position: relative;
  margin-right: 15px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  & svg {
    position: absolute;
    top: -7px;
    right: -20px;
    transform: scale(0.4);

    ${MEDIA_BREAKPOINTS.max1180} {
      top: 3px;
      right: -20px;
    }

    ${MEDIA_BREAKPOINTS.max1000} {
      top: -7px;
      right: -20px;
    }
  }
`;

type Props = {
  name?: string,
  slug?: string,
  isVerified?: boolean,
};

const ArtistName = ({ name = '', slug = '', isVerified = true }: Props) => {
  const {
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    sectionOrigin,
    relatedCollection,
    location,
    curatedCollection,
    artistProfile,
  } = useDownloadContext();
  const [posArtistProfile] = usePosArtistProfile(slug);
  const { handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const navigate = useNavigate();

  const handleClick = () => {
    const hasPosStr = getPos(posArtistProfile) === 'pos_artist';
    handleUpdateCollectionAnalytics(
      keywords,
      mixpanelKeywords,
      magicKeywords,
      youtubeKeywords,
      youtubeVideoTitle,
      sectionOrigin,
      ''
    );
    hasPosStr
      ? navigate(ROUTES.posartist.navigatePath({ artistSlug: slug }))
      : navigate(ROUTES.artist.navigatePath({ artistSlug: slug }));
  };

  return (
    name && (
      <StyledArtistName onClick={handleClick}>
        {name}
        {isVerified && <VerifiedIcon />}
      </StyledArtistName>
    )
  );
};

export default ArtistName;
