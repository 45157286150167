// @flow

import { PLAYER_TYPES } from './data';

const defaultAudioId = '__default';

export class AudioPlaybackHandler {
  audio;

  currentAudioId = null;

  currentAudioPendingSeek = null;

  currentMicropartAudioId = null;

  currentMicropartId = null;

  isPlaying: boolean = false;

  interactionWantsPlaying: boolean = false;

  isViewingMicroparts: boolean = false;

  onPlayCallback;

  onPauseCallback;

  previousProgress: number = 0;

  interacted: boolean = false;

  previousPlayingState;

  handlePreviousPlayingState;

  handleLooping;

  handleGetPlayerType;

  isMuted: boolean = false;

  constructor(
    audio,
    onPlayCallback,
    onPauseCallback,
    onStopCallback,
    previousPlayingState,
    handlePreviousPlayingState,
    handleLooping,
    handleGetPlayerType
  ) {
    console.log(`AudioPlaybackHandler::init`);
    this.audio = audio;
    this.onPlayCallback = onPlayCallback;
    this.onPauseCallback = onPauseCallback;
    this.onStopCallback = onStopCallback;
    this.audio.on('play', this.onPlay);
    this.audio.on('pause', this.onPause);
    this.audio.on('stop', this.onStop);
    this.audio.on('seek', this.onSeek);
    this.audio.on('end', this.onEnd);
    this.previousPlayingState = previousPlayingState;
    this.handlePreviousPlayingState = handlePreviousPlayingState;
    this.handleLooping = handleLooping;
    this.handleGetPlayerType = handleGetPlayerType;
  }

  logInfo = () => {
    console.log('logInfo::', {
      audio: this.audio,
      currentAudioId: this.currentAudioId,
      currentAudioPendingSeek: this.currentAudioPendingSeek,
      currentMicropartAudioId: this.currentMicropartAudioId,
      currentMicropartId: this.currentMicropartId,
      interactionWantsPlaying: this.interactionWantsPlaying,
      interacted: this.interacted,
      isPlaying: this.isPlaying,
      isViewingMicroparts: this.isViewingMicroparts,
      previousProgress: this.previousProgress,
      previousPlayingState: this.previousPlayingState,
    });
  };

  getAudioId = () => {
    if (this.isViewingMicroparts) return this.currentMicropartAudioId;
    this.currentAudioId = this.currentAudioId || this.audio._sounds[0]._id;
    return this.currentAudioId;
  };

  play = () => {
    console.log(`play:: ${this.currentAudioId}`);
    // this.logInfo();
    this.interacted = true;
    this.interactionWantsPlaying = true;
    this.previousPlayingState = true;
    this.handlePreviousPlayingState(true);
    if (this.isViewingMicroparts) {
      if (!this.currentMicropartAudioId) {
        const id = this.audio.play(this.currentMicropartId);
        this.currentMicropartAudioId = id;
        console.log(`playing micropart: id: ${id} currentMicropartId: ${this.currentMicropartId}`);
      } else {
        this.audio.play(this.currentMicropartAudioId);
        console.log(`playing micropart: currentMicropartAudioId: ${this.currentMicropartAudioId}`);
      }
    } else if (!this.currentAudioId) {
      const id = this.audio.play(defaultAudioId);
      this.currentAudioId = id;
      console.log(`playing song: id: ${id}`);
    } else {
      this.audio.play(this.currentAudioId);
      console.log(`playing song: currentAudioId: ${this.currentAudioId}`);
    }
    if (!this.isViewingMicroparts && this.currentAudioPendingSeek) {
      console.log(`seeking: currentAudioPendingSeek: ${this.currentAudioPendingSeek}`);
      const seekPosition = this.currentAudioPendingSeek;
      this.currentAudioPendingSeek = null;
      setTimeout(() => {
        this.seek(seekPosition);
      });
    }
  };

  pause = () => {
    console.log(`pause:: ${this.currentAudioId}`);
    // this.logInfo();
    this.interacted = true;
    this.interactionWantsPlaying = false;
    this.previousPlayingState = false;
    this.handlePreviousPlayingState(false);
    this.audio.pause();
  };

  stop = () => {
    console.log(`stop:: ${this.currentAudioId}`);
    this.interacted = true;
    this.interactionWantsPlaying = false;
    if (this.currentAudioId || this.currentMicropartAudioId) {
      this.audio.stop();
    }
  };

  onPlay = () => {
    console.log(`onPlay:: ${this.currentAudioId}`);
    // this.logInfo();
    this.isPlaying = true;
    this.onPlayCallback();
    if (this.isViewingMicroparts && !this.currentMicropartAudioId) {
      // eslint-disable-next-line no-underscore-dangle
      this.currentMicropartAudioId = this.audio._sounds[0]._id;
    } else if (!this.isViewingMicroparts && !this.currentAudioId) {
      // eslint-disable-next-line no-underscore-dangle
      this.currentAudioId = this.audio._sounds[0]._id;
    }
    if (this.interacted && !this.interactionWantsPlaying) {
      console.warn('should not be playing...');
      this.pause();
    }
    if (this.previousPlayingState === false) {
      this.pause();
    } else {
      this.previousPlayingState = true;
    }
  };

  onPause = () => {
    console.log(`onPause:: ${this.currentAudioId}`);
    // this.logInfo();
    this.isPlaying = false;
    this.onPauseCallback();
  };

  onStop = () => {
    console.log(`onStop:: ${this.currentAudioId}`);
    // this.logInfo();
    this.isPlaying = false;
    this.onStopCallback();
  };

  onEnd = () => {
    if (this.isViewingMicroparts) {
      return;
    }
    if (
      this.handleGetPlayerType() === PLAYER_TYPES.mainResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.mobileResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.direct
    ) {
      this.interactionWantsPlaying = true;
    }

    if (this.handleGetPlayerType() === PLAYER_TYPES.sfx) {
      this.stop();
      return;
    }

    this.handleLooping();
  };

  switchToMicroparts = (micropartIndex: number) => {
    console.log('switchToMicroparts::', `micropartIndex`, micropartIndex);
    this.interacted = true;
    this.audio.stop();
    setTimeout(() => {
      this.currentAudioId = null;
      this.currentMicropartAudioId = null;
      this.currentMicropartId = `${micropartIndex.toString()}`;
      this.isViewingMicroparts = true;
      if (this.previousPlayingState) {
        this.play();
      }
    });
  };

  switchToFullSong = () => {
    const progress = this.getSafeProgress();
    console.log(`switchToFullSong:: ${this.currentAudioId} -`, `progress ${progress}`, this.audio);
    // this.logInfo();
    this.interacted = true;
    if (
      this.handleGetPlayerType() === PLAYER_TYPES.mainResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.mobileResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.direct
    ) {
      this.interactionWantsPlaying = true;
    }
    this.audio.stop();
    setTimeout(() => {
      this.currentAudioId = null;
      this.currentMicropartAudioId = null;
      this.currentMicropartId = null;
      this.isViewingMicroparts = false;
      if (this.previousPlayingState) {
        this.currentAudioPendingSeek = progress;
        this.play();
      } else {
        this.currentAudioPendingSeek = progress;
        if (progress !== 0) this.play();
        this.pause();
      }
    });
  };

  seek = (time: number, id?: string, autoplay?: boolean) => {
    console.log(`seek:: ${this.currentAudioId}`);
    this.interacted = true;
    if (
      this.handleGetPlayerType() === PLAYER_TYPES.mainResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.mobileResults ||
      this.handleGetPlayerType() === PLAYER_TYPES.direct
    ) {
      this.interactionWantsPlaying = true;
    }
    id = id || this.getAudioId();
    console.log('time', time, 'id', id);

    this.audio.seek(time, id);
    console.log(`seek2:: ${id}`);
    setTimeout(() => {
      if (autoplay && !this.isPlaying) {
        this.play();
      }
    });
  };

  onSeek = () => {
    console.log(`onSeek::${this.currentAudioId}`);
    // this.logInfo();
  };

  playMicropart = (micropartIndex: number) => {
    console.log('playMicropart:: ', `micropartIndex ${micropartIndex}`);
    // this.logInfo();
    this.audio.stop();
    setTimeout(() => {
      this.currentMicropartAudioId = null;
      this.currentMicropartId = `${micropartIndex.toString()}`;
      this.isViewingMicroparts = true;
      this.play();
    });
  };

  getProgress = (): number | null => {
    if (this.audio) {
      if (this.isViewingMicroparts) {
        const progress = this.audio.seek(this.getAudioId());
        this.previousProgress = progress;
        return progress;
      } else {
        const progress = this.audio.seek();
        this.previousProgress = progress;
        return progress;
      }
    }
    return null;
  };

  getSafeProgress = (): number => {
    const progress = this.getProgress();
    if (typeof progress !== 'number') {
      return this.previousProgress;
    }
    return progress;
  };

  muteAudio = (id?: string) => {
    console.log('mute');
    if (this.audio) {
      id = id || this.getAudioId();
      if (this.isMuted) {
        this.isMuted = false;
        this.audio.mute(false);
      } else {
        this.isMuted = true;
        this.audio.mute(true);
      }
    }
  };

  decreaseVolume = (newVolume: number) => {
    if (this.audio) {
      const currentVolume = this.audio.volume();
      const newVolume = Math.max(currentVolume - 0.1, 0);
      this.audio.volume(newVolume);
    }
  };

  increaseVolume = () => {
    if (this.audio) {
      const currentVolume = this.audio.volume();
      const newVolume = Math.min(currentVolume + 0.1, 1);
      this.audio.volume(newVolume);
    }
  };

  cleanup = () => {
    this.audio.off('play');
    this.audio.off('pause');
    this.audio.off('seek');
  };
}
