// @flow
import React from 'react';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { getSongID } from '../../../api/algolia/song';
import AudioCardWrapper from '../AudioCardWrapper/AudioCardWrapper';
import FullSongCard, { viewAllButtonClass } from '../FullSongCard/FullSongCard';
import { TextButton } from '../../../components/Button/Button';
import { getTrackMinimizeAction } from '../../../cms/track';
import CondensedSongCard from '../CondensedSongCard/CondensedSongCard';
import { useTrackCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { PLAYER_TYPES } from '../../data';

type Props = {
  autoplay?: boolean,
  song: AlgoliaSongMdl,
  onMinimize: () => void,
  onExpand: () => void,
  expanded: boolean,
  expandKey: string,
  alwaysExpanded?: boolean,
  neverExpanded?: boolean,
  playerType?: string,
};

const ExpandableSongCard = ({
  autoplay = false,
  song,
  onMinimize,
  onExpand,
  expanded,
  expandKey,
  alwaysExpanded = false,
  neverExpanded = false,
  playerType = PLAYER_TYPES.download_bookmark,
}: Props) => {
  const trackCopy = useTrackCopy();
  return (
    <AudioCardWrapper
      autoplay={autoplay}
      autoload
      song={song}
      expandKey={expandKey}
      key={getSongID(song)}
      mainAudioCard={false}
      playerType={playerType}
    >
      {(expanded || alwaysExpanded) && !neverExpanded ? (
        <FullSongCard
          song={song}
          sideAction={
            <TextButton className={viewAllButtonClass} onClick={onMinimize}>
              {getTrackMinimizeAction(trackCopy)}
            </TextButton>
          }
        />
      ) : (
        <CondensedSongCard song={song} onExpand={onExpand} neverExpanded />
      )}
    </AudioCardWrapper>
  );
};

export default ExpandableSongCard;
