import { isMobileDeviceSize, isMobile, isSmallDesktopDevice, isSmallDeviceSize } from './device';

const KEYWORD_LIMIT_MAP = {
  find: {
    mobile: 3,
    mobileSize: 8,
    smallDevice: 10,
    smallDesktop: 12,
    default: 16,
  },
  results: {
    mobile: 3,
    mobileSize: 8,
    smallDevice: 10,
    smallDesktop: 12,
    default: 16,
  },
};

export const getInitialKeywordLimit = (type: string) => {
  if (isMobile()) {
    return KEYWORD_LIMIT_MAP[type].mobile;
  }
  if (isMobileDeviceSize()) {
    return KEYWORD_LIMIT_MAP[type].mobileSize;
  }

  if (isSmallDeviceSize()) {
    return KEYWORD_LIMIT_MAP[type].smallDevice;
  }

  if (isSmallDesktopDevice()) {
    return KEYWORD_LIMIT_MAP[type].smallDesktop;
  }

  return KEYWORD_LIMIT_MAP[type].default;
};
