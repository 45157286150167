// @flow
import React from 'react';
import MicroPartsWrapper from '../../../MicroPartsWrapper/MicroPartsWrapper';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import { getSongID } from '../../../../../api/algolia/song';
import SongContextWrapper from '../../../SongContextWrapper/SongContextWrapper';
import GlobalAudioPlayback from '../GlobalAudioPlayback/GlobalAudioPlayback';
import GlobalShareContextWrapper from '../GlobalShareContextWrapper/GlobalShareContextWrapper';

type Props = {
  children: any,
  song: AlgoliaSongMdl,
  autoplay?: boolean,
  autoload?: boolean,
  playbackListSlug?: string,
  songPosition: number,
};

const GlobalAudioCardWrapper = ({
  children,
  song,
  playbackListSlug = '',
  songPosition = 0,
  autoload = false,
  autoplay = false,
}: Props) => {
  return (
    <SongContextWrapper song={song}>
      <MicroPartsWrapper>
        <GlobalAudioPlayback
          song={song}
          autoload={autoload}
          autoplay={autoplay}
          key={`${getSongID(song)}_${playbackListSlug}_${songPosition}`}
          playbackListSlug={playbackListSlug}
        >
          <GlobalShareContextWrapper song={song}>{children}</GlobalShareContextWrapper>
        </GlobalAudioPlayback>
      </MicroPartsWrapper>
    </SongContextWrapper>
  );
};

export default GlobalAudioCardWrapper;
