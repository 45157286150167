// @flow
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css } from 'emotion';
import {
  bodyTextCss,
  smallMonoCondensedCss,
  smallMonoCss,
} from '../../../../../../../styles/typography/typography';
import { darkColor } from '../../../../../../../styles/config/colors';
import { useChargebeeContext } from '../../../../../../../user/subscriptions/components/ChargebeeWrapper/ChargebeeWrapper';
import { useBillingPageCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getBillingPageCardNumberDescription,
  getBillingPageCardNumberLabel,
  getBillingPageChangeCardButton,
  getBillingPagePaymentMethodFreeUserMessage,
  getBillingPagePaymentMethodHeading,
  getBillingPagePaymentMethodInstructions,
} from '../../../../../../../cms/billingPage';
import { MediumHeading } from '../../../../../../../styles/typography/components/Heading/Heading';
import {
  useFetchPaymentSource,
  useFetchUpdatePaymentCard,
} from '../../../../../../../api/firebase/api';
import {
  getPaymentSourceLast4,
  getPaymentSourceType,
} from '../../../../../../../api/firebase/user/payment';
import type { PaymentSourceMdl } from '../../../../../../../api/firebase/user/payment';
import { ICONS } from '../../../../../../../images/paths';
import {
  lessVerticalPaddingButtonClass,
  LightButton,
} from '../../../../../../../components/Button/Button';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import LoadingDisplay from '../../../../../../../components/LoadingDisplay/LoadingDisplay';

const Instruction = styled('p')`
  ${smallMonoCss};
  margin-top: 23px;
`;

const CardSection = styled('section')`
  margin-top: 39px;
  position: relative;
  border-bottom: 1px solid ${transparentize(0.9, darkColor)};
`;

const CardLabel = styled('p')`
  ${smallMonoCondensedCss};
  text-transform: uppercase;
  color: ${transparentize(0.4, darkColor)};
  position: absolute;
  top: -5px;
  left: 0;
`;

const Card = styled('div')`
  min-height: 65px;
  display: flex;
  align-items: center;
`;

const CardInfo = styled('div')`
  ${bodyTextCss};
  flex: 1;
  display: flex;
  align-items: center;

  img,
  svg {
    display: block;
    margin-right: 15px;
  }
`;

const cardButtonClass = css`
  ${lessVerticalPaddingButtonClass};
  width: auto;
  min-width: 132px;
  margin-top: 4px;
`;

export const LoadingContainer = styled('div')`
  margin-top: 5px;
`;

type Props = {
  onLoad: () => void,
};

const PaymentMethod = ({ onLoad, ...props }: Props) => {
  const { isPaidCustomer } = useSubscriptionsContext();
  const [fetchPaymentSource] = useFetchPaymentSource();
  const { chargebeeInstance, loadChargebee } = useChargebeeContext();
  const [fetchUpdateCardPage] = useFetchUpdatePaymentCard();
  const [busy, setBusy] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [paymentSource, setPaymentSource]: [PaymentSourceMdl, () => {}] = useState();
  const copy = useBillingPageCopy();

  useEffect(() => {
    loadChargebee();
  }, []);

  useEffect(() => {
    fetchPaymentSource().then(response => {
      setPaymentSource(response.primaryPaymentSource);
      setLoaded(true);
      onLoad();
    });
  }, []);

  const validCard = paymentSource && getPaymentSourceType(paymentSource) === 'card';

  const handleOpenChargebee = hostedPage => {
    chargebeeInstance.openCheckout({
      hostedPage: () => {
        // We will discuss on how to implement this end point in the next step.
        return Promise.resolve(hostedPage);
      },
      success: hostedPageId => {
        // success callback
        console.log('success!', hostedPageId);
        chargebeeInstance.closeAll();
      },
      loaded: () => console.log('loaded'),
      error: () => console.log('error'),
      close: () => console.log('closed'),
    });
  };

  const handleUpdateCard = () => {
    if (busy) return;
    setBusy(true);
    fetchUpdateCardPage()
      .then(response => {
        handleOpenChargebee(response);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const content = (() => {
    if (!loaded) {
      return (
        <LoadingContainer>
          <LoadingDisplay />
        </LoadingContainer>
      );
    }
    if (!isPaidCustomer) {
      return <Instruction>{parse(getBillingPagePaymentMethodFreeUserMessage(copy))}</Instruction>;
    }
    if (validCard) {
      return (
        <React.Fragment>
          <Instruction>{getBillingPagePaymentMethodInstructions(copy)}</Instruction>
          <CardSection>
            <CardLabel>{getBillingPageCardNumberLabel(copy)}</CardLabel>
            <Card>
              <CardInfo>
                <img src={ICONS.card} width={21} height={14} alt="card icon" />
                {getBillingPageCardNumberDescription(copy).replace(
                  '$NUMBER$',
                  getPaymentSourceLast4(paymentSource)
                )}
              </CardInfo>
              <div>
                <LightButton className={cardButtonClass} onClick={handleUpdateCard} loading={busy}>
                  {getBillingPageChangeCardButton(copy)}
                </LightButton>
              </div>
            </Card>
          </CardSection>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Instruction>{getBillingPagePaymentMethodInstructions(copy)}</Instruction>
        <div>
          <LightButton className={cardButtonClass} onClick={handleUpdateCard} loading={busy}>
            {getBillingPageChangeCardButton(copy)}
          </LightButton>
        </div>
      </React.Fragment>
    );
  })();

  return (
    <section {...props}>
      <MediumHeading>{getBillingPagePaymentMethodHeading(copy)}</MediumHeading>
      {content}
    </section>
  );
};

export default PaymentMethod;
