import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useResultsPage } from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { buttonResetCss } from '../../../../../components/Button/Button';
import LoopsMixTypeSelector from '../LoopsMixTypeSelector/LoopsMixTypeSelector';
import {
  getSongTabCopy,
  getTabMappedSongsCount,
  getTabMappedSongsList,
  SONG_TAB_TYPES,
} from '../../../../../utils/songTabs';

const StyledTabsContainer = styled('div')`
  position: sticky;
  top: 75px;
  z-index: 100;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0px;
  align-items: baseline;
  width: 100%;

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column;
    top: 55px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    padding: 20px 15px 10px 15px;
    width: 100%;
  }
`;

const TabsContainer = styled('div')`
  display: flex;
  align-items: baseline;
  width: 100%;
  padding-bottom: 15px;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: white black;
`;

const SelectorContainer = styled('div')`
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    margin-top: 5px;
  }
`;

const Tab = styled('button')`
  ${buttonResetCss};
  font-family: Roboto;
  font-size: 19px;
  letter-spacing: 0.53px;
  line-height: 23px;
  opacity: 0.4;
  margin: 0px 15px;

  &:first-of-type {
    margin-left: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    white-space: nowrap;
    width: fit-content;

    &:last-of-type {
      padding: 0px 15px 0px 0px;
    }
  }
`;

const activeTabClass = css`
  opacity: 1;
`;

type Props = {
  songsMap: SongMap,
  activeTab: string,
  activeLoopsMixType: string,
  handleClick: (tabType: string) => void,
  handleMixTypeChange: (mixType: string) => void,
};

const SongTabs = ({
  songsMap,
  activeTab,
  activeLoopsMixType,
  handleClick,
  handleMixTypeChange,
}: Props) => {
  const resultsPage = useResultsPage();

  const loopSongs = getTabMappedSongsList(songsMap, SONG_TAB_TYPES.loops);
  const loopSongsCount = getTabMappedSongsCount(songsMap, SONG_TAB_TYPES.loops);
  const dropdown =
    activeTab === SONG_TAB_TYPES.loops && loopSongsCount ? (
      <LoopsMixTypeSelector
        loops={loopSongs}
        currentMixType={activeLoopsMixType}
        handleChange={handleMixTypeChange}
      />
    ) : (
      <></>
    );

  return (
    <StyledTabsContainer>
      <TabsContainer>
        {Object.keys(songsMap).map(tabType => {
          return (
            <Tab
              key={tabType}
              type="button"
              onClick={() => {
                handleClick(SONG_TAB_TYPES[tabType]);
              }}
              className={cx({
                [activeTabClass]: SONG_TAB_TYPES[tabType] === activeTab,
              })}
            >{`${getSongTabCopy(SONG_TAB_TYPES[tabType], resultsPage)} (${getTabMappedSongsCount(
              songsMap,
              tabType
            )})`}</Tab>
          );
        })}
      </TabsContainer>
      <SelectorContainer>{dropdown}</SelectorContainer>
    </StyledTabsContainer>
  );
};

export default SongTabs;
