// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type MicropartViewGraphMdl = {
  data: {
    title: {
      text: string,
    },
  },
};

export const getMicropartViewTitle = (copy: MicropartViewGraphMdl): string => {
  return get(copy, 'data.title.text', '') || '';
};

export const query = graphql`
  fragment MicropartView on PrismicMicropartView {
    data {
      title {
        text
      }
    }
  }
`;
