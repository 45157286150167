// @flow
import React from 'react';
import ErrorView from '../../../errors/components/ErrorView/ErrorView';
import ErrorCodeHandler from '../../../errors/components/ErrorCodeHandler/ErrorCodeHandler';
import PageComponent from '../../components/PageComponent/PageComponent';

type Props = {
  code?: string,
  path: string,
};

const ErrorScreen = ({ code = '', path }: Props) => {
  return (
    <PageComponent path={path}>
      <ErrorView>
        <ErrorCodeHandler code={code} />
      </ErrorView>
    </PageComponent>
  );
};

export default ErrorScreen;
