// @flow
import React from 'react';
import { getSongID } from '../../../../../../../../api/algolia/song';
import {
  useHideTrack,
  useUnhideTrack,
} from '../../../../../../../../api/firebase/user/hiddenTracks';
import HideIcon from '../../../../../../../../assets/inline-assets/hide-icon.svg';
import HiddenIcon from '../../../../../../../../assets/inline-assets/hidden-icon.svg';
import { useUserHiddenTracks } from '../../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getTrackHideAction, getTrackUnhideAction } from '../../../../../../../../cms/track';

const HideTrackInlineButton = () => {
  const song = useSong();
  const trackCopy = useTrackCopy();
  const [handleHideTrack] = useHideTrack(song);
  const [handleUnhideTrack] = useUnhideTrack(song);
  const userHiddenSongs = useUserHiddenTracks();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));

  const handleToggleHidden = () => {
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
    }
  };

  return (
    <InlineButton
      label={songIsHidden ? getTrackUnhideAction(trackCopy) : getTrackHideAction(trackCopy)}
      icon={songIsHidden ? <HideIcon /> : <HiddenIcon />}
      action={handleToggleHidden}
    />
  );
};

export default HideTrackInlineButton;
