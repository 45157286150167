// @flow
import { storage } from './firestore';

export const firebaseStorageRef = storage.ref();

const usersStorageRef = firebaseStorageRef.child(`/users`);

export const getUserAvatarsStorageRef = (uid: string) => {
  return usersStorageRef.child(`${uid}/avatars`);
};
