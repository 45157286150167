// @flow
import React from 'react';
import { css } from 'emotion';
import SplitModalLayout, { SplitModalContentLayout } from '../SplitModalLayout/SplitModalLayout';
import Button from '../../../components/Button/Button';
import { SubscriptionModalFooter } from '../../../user/subscriptions/components/CancelSubscriptionModal/CancelSubscriptionModal';
import { useChromeExtensionCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getExtensionModalTitle,
  getExtensionModalDescription,
  getExtensionModalLink,
  getExtensionSafariLabel,
  getExtensionChromeLabel,
  getSafariExtensionDownloadUrl,
  getExtensionDownloadUrl,
} from '../../../cms/chromeExtension';
import { isDesktopSafariBrowser } from '../../../utils/browser';
import { IMAGES } from '../../../images/paths';

const buttonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const ExtensionShowcaseModal = ({ onClose }: Props) => {
  const copy = useChromeExtensionCopy();
  const browser = isDesktopSafariBrowser()
    ? getExtensionSafariLabel(copy)
    : getExtensionChromeLabel(copy);
  const downloadURL = isDesktopSafariBrowser()
    ? getSafariExtensionDownloadUrl(copy)
    : getExtensionDownloadUrl(copy);
  const imageSource = isDesktopSafariBrowser() ? IMAGES.safariExtension : IMAGES.chromeExtension;
  return (
    <SplitModalLayout onClose={onClose} imageSrc={imageSource}>
      <SplitModalContentLayout
        heading={getExtensionModalTitle(copy).replace('$VERSION$', browser)}
        description={getExtensionModalDescription(copy)}
        footer={
          <SubscriptionModalFooter>
            <Button className={buttonLayout} href={downloadURL} target="_blank">
              {getExtensionModalLink(copy)}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </SplitModalLayout>
  );
};

export default ExtensionShowcaseModal;
