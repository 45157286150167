import { get } from 'lodash';
import { getSongDateUpdated } from '../api/algolia/song';
import { RESULTS_SORTING_METHODS } from '../routing/screens/ResultsScreen/components/ResultsView/components/data';

const sortByNewest = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedSongs = songs.slice().sort((songA, songB) => {
    const dateA = new Date(getSongDateUpdated(songA) * 1000);
    const dateB = new Date(getSongDateUpdated(songB) * 1000);
    return dateB - dateA;
  });
  return sortedSongs;
};

const sortByOldest = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedByNewest = sortByNewest(songs);
  const sortedByOldest = sortedByNewest.slice().reverse();
  return sortedByOldest;
};

const sortByPopularity = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedSongs = songs.slice().sort((songA, songB) => {
    const popularityA = get(songA, 'popularity', 0);
    const popularityB = get(songB, 'popularity', 0);
    return popularityB - popularityA;
  });
  return sortedSongs;
};

const sortByPopularityAscending = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const songsByDesecendingPopularity = sortByPopularity(songs);
  return [].concat(songsByDesecendingPopularity).reverse();
};

export const sortSongResults = (
  songs: Array<AlgoliaSongMdl>,
  method: string
): Array<AlgoliaSongMdl> => {
  if (method === RESULTS_SORTING_METHODS.newest) {
    return sortByNewest(songs);
  }
  if (method === RESULTS_SORTING_METHODS.oldest) {
    return sortByOldest(songs);
  }
  if (method === RESULTS_SORTING_METHODS.popularity) {
    return sortByPopularity(songs);
  }
  if (method === RESULTS_SORTING_METHODS.descending_popularity) {
    return sortByPopularityAscending(songs);
  }
  return sortByNewest(songs);
};
