// @flow
import React from 'react';
import { css, cx } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import Button, { BUTTON_WIDTHS, extraVerticalPaddingButtonClass } from '../../../Button/Button';
import {
  useMiscCopy,
  useReferralBanners,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getMiscCopyCloseButton } from '../../../../cms/miscCopy';
import {
  getReferralTermsModalHeading,
  getReferralTermsModalDescription,
} from '../../../../cms/referralBanners';
import { BANNERS } from '../../../../images/paths';
import { referralBgColor } from '../../../../styles/config/colors';
import {
  getReferralCouponType,
  getReferralCouponName,
  getReferralCouponTrialDays,
  replaceReferralPlaceholders,
} from '../../../../api/firebase/referral/referral';
import type { ReferralCouponDataMDL } from '../../../../api/firebase/referral/referral';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';

const referralBannerCss = css`
  background-color: ${referralBgColor};
  background-image: url(${BANNERS.referralModal});
  background-repeat: no-repeat;
`;

type Props = {
  onClose: () => void,
  referralCoupon: ReferralCouponDataMDL,
};

const ReferralConditionsModal = ({ onClose, referralCoupon }: Props) => {
  const locale = useLocale();
  const miscCopy = useMiscCopy();
  const copy = useReferralBanners();
  const referralCouponType = getReferralCouponType(referralCoupon);
  const referralCouponName = getReferralCouponName(referralCoupon, locale);
  const referralCouponTrialDays = getReferralCouponTrialDays(referralCoupon);
  return (
    <ClassicModalLayout
      bannerClassName={cx(referralBannerCss)}
      onClose={onClose}
      label={getReferralTermsModalHeading(copy, referralCouponType)}
    >
      <ClassicModalContentLayout
        heading={replaceReferralPlaceholders(
          getReferralTermsModalHeading(copy, referralCouponType),
          referralCouponName,
          referralCouponTrialDays
        )}
        description={getReferralTermsModalDescription(copy, referralCouponType)}
        footer={
          <Button
            onClick={onClose}
            className={extraVerticalPaddingButtonClass}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {getMiscCopyCloseButton(miscCopy)}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default ReferralConditionsModal;
