// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type EmailSettingsPageGraphMdl = {
  data: {
    email_settings_header: {
      text: string,
    },
    email_settings_instruction: {
      text: string,
    },
    new_music_header: {
      text: string,
    },
    new_music_message: {
      html: string,
    },
    evoke_music_updates: {
      text: string,
    },
    evoke_music_message: {
      html: string,
    },
  },
};

export const getEmailSettingsPageHeading = (copy: EmailSettingsPageGraphMdl): string => {
  return get(copy, 'data.email_settings_header.text', '') || '';
};

export const getEmailSettingsPageInstructions = (copy: EmailSettingsPageGraphMdl): string => {
  return get(copy, 'data.email_settings_instruction.text', '') || '';
};

export const getEmailSettingsPageNewMusicHeading = (copy: EmailSettingsPageGraphMdl): string => {
  return get(copy, 'data.new_music_header.text', '') || '';
};

export const getEmailSettingsPageNewMusicMessage = (copy: EmailSettingsPageGraphMdl): string => {
  return get(copy, 'data.new_music_message.html', '') || '';
};

export const getEmailSettingsPageMusicUpdatesHeading = (
  copy: EmailSettingsPageGraphMdl
): string => {
  return get(copy, 'data.evoke_music_updates.text', '') || '';
};

export const getEmailSettingsPageMusicUpdatesMessage = (
  copy: EmailSettingsPageGraphMdl
): string => {
  return get(copy, 'data.evoke_music_message.html', '') || '';
};

export const query = graphql`
  fragment EmailSettingsPage on PrismicEmailSettingsPage {
    data {
      email_settings_header {
        text
      }
      email_settings_instruction {
        text
      }
      new_music_header {
        text
      }
      new_music_message {
        html
      }
      evoke_music_updates {
        text
      }
      evoke_music_message {
        html
      }
    }
  }
`;
