// @flow
import { get } from 'lodash';
import { firestore } from '../firestore';
import { useGenericApiState } from '../../hooks';
import { useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import type { FbUserProfileMdl } from './profile';
import { getDateString } from '../../../utils/date';
import type { AlgoliaSongMdl } from '../../algolia/song';

export const SUBSCRIPTION_STATUSES = {
  trial: 'trial',
  trial_cancelled: 'trial_cancelled', // The plan is on trial, and will be cancelled at the end of the trial without billing the user. Channel will be cancelled at the end of the trial as well.
  active: 'active',
  cancel_pending: 'cancel_pending', // The plan is pending cancellation, and the channel will be removed at the end of the billing term.
  change_pending: 'change_pending', // The plan is pending a change that will still result in a paid plan in the next billing term.
  change_pending_price: 'change_pending_price', // The plan will see price change in the future, but everything else remains as the current plan.
  downgrade_to_free_pending: 'downgrade_to_free_pending', // The plan is pending a downgrade to free at the end of the billing term.
  overdue: 'overdue',
  cancelled: 'cancelled',
  other: 'other',
};

export const PLAN_INTERVAL_PRICES = {
  en: {
    free1: {
      monthly: 0,
      quarterly: 0,
      biannual: 0,
      annual: 0,
    },
    free2: {
      monthly: 0,
      quarterly: 0,
      biannual: 0,
      annual: 0,
    },
    pro1: {
      monthly: 1400,
      quarterly: 3900,
      biannual: 7500,
      annual: 13900,
    },
    business1: {
      monthly: 4900,
      quarterly: 14000,
      biannual: 26500,
      annual: 48800,
    },
  },
  ja: {
    free1: {
      monthly: 0,
      quarterly: 0,
      biannual: 0,
      annual: 0,
    },
    free2: {
      monthly: 0,
      quarterly: 0,
      biannual: 0,
      annual: 0,
    },
    pro1: {
      monthly: 1500,
      quarterly: 4275,
      biannual: 8100,
      annual: 14940,
    },
    business1: {
      monthly: 5000,
      quarterly: 14250,
      biannual: 27000,
      annual: 49800,
    },
  },
};

export type SubscriptionStatus = $Keys<typeof SUBSCRIPTION_STATUSES>;

export type CouponMdl = {
  expirySeconds: number,
  id: string,
};

export const getCouponId = (coupon: CouponMdl): string => {
  return get(coupon, 'id', '');
};

export const getCouponExpirySeconds = (coupon: CouponMdl): number => {
  return get(coupon, 'expirySeconds', 0);
};

export const getCouponExpirationDate = (coupon: CouponMdl): string => {
  return getDateString(getCouponExpirySeconds(coupon) * 1000);
};

export type AllocatedSeatMdl = {
  channelId: string,
  coupons: Array<CouponMdl>,
  status: SubscriptionStatus,
  plan: string,
  monthlyTotalCents: number,
  validUntilSeconds: number | null,
  nextBillingDateSeconds: number | null,
  trialPlanValidUntilSeconds: number | null,
  interval: string | null,
  currency?: string | null,
};

export type FBUserAccountMdl = {
  seats: {
    allocatedSeats: Array<AllocatedSeatMdl>,
  },
  isUserFreeTrialEligible: boolean,
};

export type FBUserMdl = {
  auth: {
    auth0Id: string,
  },
  account: FBUserAccountMdl,
  profile: FbUserProfileMdl,
};

export const getUserProfile = (user: FBUserMdl): FbUserProfileMdl => {
  return get(user, 'profile');
};

export const getUserProfileHiddenTracks = (user: FBUserMdl): Array<AlgoliaSongMdl> => {
  return get(user, 'profile.hiddenTracks', {});
};

const usersRef = firestore.collection('users');

export const getFirestoreUserRef = (uid: string) => {
  return usersRef.doc(uid);
};

export const getFirestoreUserData = (uid: string): Promise<FBUserMdl> => {
  const userRef = getFirestoreUserRef(uid);

  return userRef
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data();
      }
      // doc.data() will be undefined in this case
      console.warn('No such document!');
      return Promise.reject();
    })
    .catch(error => {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong getting the firestore user data');
      Sentry.captureException(error);
      console.log('Error getting document:', error);
      return Promise.reject(error);
    });
};

export const useFetchFirestoreUserData = () => {
  const [busy, setBusy, apiErrorHandler] = useGenericApiState();
  const fetch = (userId: string) => {
    if (busy) return Promise.reject();
    setBusy(true);
    return getFirestoreUserData(userId)
      .catch(apiErrorHandler)
      .finally(() => {
        setBusy(false);
      });
  };
  return [fetch, busy];
};

export const firestoreUpdateUserProfileData = (uid: string, values): Promise<any> => {
  const userRef = getFirestoreUserRef(uid);
  const update = {};
  Object.keys(values).forEach(valueKey => {
    update[`profile.${valueKey}`] = values[valueKey];
  });
  return userRef.update(update);
};

export const useFirestoreUpdateUserProfileData = () => {
  const [busy, setBusy] = useGenericApiState();
  const userId = useUserId();
  const update = values => {
    if (!userId) return Promise.reject();
    if (busy) return Promise.reject();
    setBusy(true);
    return firestoreUpdateUserProfileData(userId, values).finally(() => {
      setBusy(false);
    });
  };
  return [update, busy];
};

export const firestoreSetUserProfilePaypal = (uid: string, email: string): Promise<any> => {
  return firestoreUpdateUserProfileData(uid, {
    paypal: email,
  });
};

export const useFirestoreSetUserProfilePaypal = () => {
  const [busy, setBusy] = useGenericApiState();
  const userId = useUserId();
  const update = (email: string): Promise<any> => {
    if (!userId) return Promise.reject();
    if (busy) return Promise.reject();
    setBusy(true);
    return firestoreSetUserProfilePaypal(userId, email).finally(() => {
      setBusy(false);
    });
  };
  return [update, busy];
};
