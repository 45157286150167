// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import {
  getFacetKey,
  getFilterLabel,
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
  getFacetStatsMax,
  getFacetStatsMin,
} from '../../../../../../../../../../../api/algolia/data';
import { darkColor } from '../../../../../../../../../../../styles/config/colors';
import { mediumFontWeightCss } from '../../../../../../../../../../../styles/typography/typography';
import {
  useMiscCopy,
  useResultsPage,
} from '../../../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../../../../../../styles/responsive';
import { getFormattedRangeLabel } from '../FiltersList/FiltersList';
import { TextButton } from '../../../../../../../../../../../components/Button/Button';
import { getMiscCopyClearButton } from '../../../../../../../../../../../cms/miscCopy';
import { useFilterChangeToast } from '../../../../../../../../../../../toasts/hooks';
import { isMobile } from '../../../../../../../../../../../utils/device';
import { USER_PERMISSIONS } from '../../../../../../../../../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import PadlockIcon from '../../../../../../../../../../../assets/inline-assets/padlock-icon.svg';
import { getInternalFilterLabel } from '../../../../../../../../../../../cms/resultsPage';
import { useResultsManagerContext } from '../../../../../../../../../MusicScreen/views/SearchView/components/ResultsManager/ResultsManager';

type Props = {
  facetFilters: any,
  appliedFilters: any,
  handleSelectFilterMenu: string => void,
  selectedFilter: string,
};

type InteractiveFilterProps = {
  facetKey: string,
  excludedFilters?: Array<string>,
  includedFilters?: Array<string>,
  maxValue?: number,
  minValue?: number,
  appliedValue?: string,
  trueLabel?: string,
  falseLabel?: string,
  handleSelectFilterMenu: string => void,
  isSelectedFilter: boolean,
};

type InternalFilterProps = {
  filterKey: string,
  handleSelectFilterMenu: string => void,
};

const InteractiveFilter = styled('li')`
  color: ${transparentize(0.1, darkColor)};
  padding: 2px 0px 2px 10px;

  &:hover {
    cursor: pointer;
    color: ${darkColor};
    text-decoration: underline;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px;
    padding: 3px 0px 3px 10px;

    &:first-of-type {
      margin-top: 15px;
    }
  }
`;

const LabelContainer = styled('div')``;

const PadlockContainer = styled('span')`
  margin-left: 10px;
`;

const ButtonContainer = styled('div')`
  margin-right: 10px;
`;

const ClearButton = styled(TextButton)`
  visibility: hidden;
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.47px;
  line-height: 24px;
  text-align: right;
  margin-right: 5px;
`;

const activeInteractiveFilter = css`
  opcaity: 1;
  background: ${transparentize(0.9, darkColor)};
  border-radius: 2px 0 0 2px;
  font-weight: ${mediumFontWeightCss};
`;

const hasActiveFilters = css`
  color: ${darkColor};
  ${mediumFontWeightCss};

  ${SMALL_DESKTOP_BREAKPOINT} {
    background: ${transparentize(0.9, darkColor)};
  }
`;

const restrictedFilter = css`
  text-decoration: line-through;
  color: ${transparentize(0.6, darkColor)} !important;

  &:hover {
    cursor: pointer;
    text-decoration: line-through;
    color: ${transparentize(0.6, darkColor)} !important;
  }
`;

const showClear = css`
  visibility: visible;
`;

const MobileFilterItem = ({
  facetKey,
  excludedFilters = [],
  includedFilters = [],
  maxValue = 0,
  minValue = 0,
  appliedValue = '',
  trueLabel = '',
  falseLabel = '',
  handleSelectFilterMenu,
  isSelectedFilter,
}: InteractiveFilterProps) => {
  const miscCopy = useMiscCopy();
  const { resetSongsFilter } = useResultsManagerContext();
  const clearedFilterChangeToast = useFilterChangeToast(facetKey, '', 'cleared');
  const { userRole } = useSubscriptionsContext();
  const userHasFilterPermission = USER_PERMISSIONS[userRole].filteringPermissions.includes(
    facetKey
  );

  const getAppliedFiltersLabel = () => {
    if (includedFilters.length > 0 || excludedFilters.length > 0) {
      return ` (${includedFilters.length + excludedFilters.length})`;
    }

    if (maxValue && minValue) {
      return ` (${getFormattedRangeLabel(facetKey, minValue, maxValue)})`;
    }

    if (appliedValue) {
      return ` (${appliedValue === 'true' ? trueLabel : falseLabel})`;
    }

    return '';
  };

  const handleClick = () => {
    handleSelectFilterMenu(facetKey);
  };

  const handleClearFilter = event => {
    resetSongsFilter(facetKey);
    if (isMobile()) {
      clearedFilterChangeToast();
    }
    event.stopPropagation();
  };

  return (
    <>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: isSelectedFilter,
          [hasActiveFilters]:
            includedFilters.length > 0 ||
            excludedFilters.length > 0 ||
            (maxValue, minValue) ||
            appliedValue,
          [restrictedFilter]: !userHasFilterPermission,
        })}
        onClick={handleClick}
      >
        <LabelContainer>
          {getFilterLabel(facetKey, miscCopy)}
          {!userHasFilterPermission ? (
            <PadlockContainer className="padlockIcon">
              <PadlockIcon />
            </PadlockContainer>
          ) : (
            getAppliedFiltersLabel()
          )}
        </LabelContainer>
        <ButtonContainer>
          <ClearButton
            className={cx({
              [showClear]:
                includedFilters.length > 0 ||
                excludedFilters.length > 0 ||
                (maxValue, minValue) ||
                appliedValue,
            })}
            onClick={handleClearFilter}
          >
            {getMiscCopyClearButton(miscCopy)}
          </ClearButton>
        </ButtonContainer>
      </InteractiveFilter>
    </>
  );
};

const MobileInternalFilter = ({ filterKey, handleSelectFilterMenu }: InternalFilterProps) => {
  const { showHiddenSongs, handleShowHiddenSongs } = useResultsManagerContext();
  const resultsPage = useResultsPage();
  const miscCopy = useMiscCopy();
  const clearedFilterChangeToast = useFilterChangeToast(filterKey, '', 'cleared');

  const handleClick = () => {
    if (filterKey === 'hidden') {
      handleSelectFilterMenu(filterKey);
    }
  };

  const handleClearFilter = event => {
    handleShowHiddenSongs();
    if (isMobile()) {
      clearedFilterChangeToast();
    }
    event.stopPropagation();
  };

  return (
    <>
      <InteractiveFilter
        className={cx({
          [activeInteractiveFilter]: false,
          [hasActiveFilters]: showHiddenSongs,
        })}
        onClick={handleClick}
      >
        <LabelContainer>{`${getInternalFilterLabel(filterKey, resultsPage)} ${
          showHiddenSongs ? ' (1) ' : ''
        }`}</LabelContainer>
        <ButtonContainer>
          <ClearButton
            className={cx({
              [showClear]: showHiddenSongs,
            })}
            onClick={handleClearFilter}
          >
            {getMiscCopyClearButton(miscCopy)}
          </ClearButton>
        </ButtonContainer>
      </InteractiveFilter>
    </>
  );
};

const MobileFiltersList = ({ appliedFilters, handleSelectFilterMenu, selectedFilter }: Props) => {
  return (
    <>
      <ul>
        <MobileFilterItem
          key={getFacetKey('genre')}
          facetKey={getFacetKey('genre')}
          excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('genre'))}
          includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('genre'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('genre') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('mood')}
          facetKey={getFacetKey('mood')}
          excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('mood'))}
          includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('mood'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('mood') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('instruments')}
          facetKey={getFacetKey('instruments')}
          excludedFilters={getAppliedFiltersExclusiveList(
            appliedFilters,
            getFacetKey('instruments')
          )}
          includedFilters={getAppliedFiltersInclusiveList(
            appliedFilters,
            getFacetKey('instruments')
          )}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('instruments') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('mix_type')}
          facetKey={getFacetKey('mix_type')}
          excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('mix_type'))}
          includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('mix_type'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('mix_type') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('energy')}
          facetKey={getFacetKey('energy')}
          excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('energy'))}
          includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('energy'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('energy') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('sfx_type')}
          facetKey={getFacetKey('sfx_type')}
          excludedFilters={getAppliedFiltersExclusiveList(appliedFilters, getFacetKey('sfx_type'))}
          includedFilters={getAppliedFiltersInclusiveList(appliedFilters, getFacetKey('sfx_type'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('sfx_type') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('sfx_feature')}
          facetKey={getFacetKey('sfx_feature')}
          excludedFilters={getAppliedFiltersExclusiveList(
            appliedFilters,
            getFacetKey('sfx_feature')
          )}
          includedFilters={getAppliedFiltersInclusiveList(
            appliedFilters,
            getFacetKey('sfx_feature')
          )}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('sfx_feature') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('bpm')}
          facetKey={getFacetKey('bpm')}
          minValue={getFacetStatsMin(appliedFilters, getFacetKey('bpm'))}
          maxValue={getFacetStatsMax(appliedFilters, getFacetKey('bpm'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('bpm') === selectedFilter}
        />
        <MobileFilterItem
          key={getFacetKey('length')}
          facetKey={getFacetKey('length')}
          minValue={getFacetStatsMin(appliedFilters, getFacetKey('length'))}
          maxValue={getFacetStatsMax(appliedFilters, getFacetKey('length'))}
          handleSelectFilterMenu={handleSelectFilterMenu}
          isSelectedFilter={getFacetKey('length') === selectedFilter}
        />
        <MobileInternalFilter filterKey="hidden" handleSelectFilterMenu={handleSelectFilterMenu} />
      </ul>
    </>
  );
};

export default MobileFiltersList;
