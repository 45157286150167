// @flow
import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import styled, { css, cx } from 'react-emotion';
import ls from 'local-storage';
import ResultsMainPagination from './components/ResultsMainPagination/ResultsMainPagination';
import SortResultsDropdown from './components/SortResultsDropdown/SortResultsDropdown';
import FullSongCard from '../../../../../../../song/components/FullSongCard/FullSongCard';
import {
  hideOnSmallDeviceCss,
  LARGE_DEVICE_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { useResultsManagerContext, useResultsSelectedSong } from '../ResultsManager/ResultsManager';
import NoResults from '../NoResults/NoResults';
import AudioCardWrapper from '../../../../../../../song/components/AudioCardWrapper/AudioCardWrapper';
import AudioPlayerWrapper from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { getSongID } from '../../../../../../../api/algolia/song';
import {
  LargeDeviceOnly,
  SmallDeviceOnly,
} from '../../../../../../../components/responsive/SmallDeviceOnly';
import MobileResults from './components/MobileResults/MobileResults';
import { KEY_REDIRECTED } from '../../../../../../../auth/destination';
import GenericCopy from '../../../../../../../styles/typography/components/GenericCopy/GenericCopy';
import { useResultsPage } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getResultsPageGenericErrorMessage } from '../../../../../../../cms/resultsPage';
import { PLAYER_TYPES } from '../../../../../../../song/data';
import ResultsKeywords from '../ResultsAside/components/ResultsKeywords/ResultsKeywords';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import ResultsNav from '../ResultsNav/ResultsNav';

const Container = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 200px;
    margin-top: 140px;
    position: relative;
    margin-bottom: 100px;

    ${MEDIA_BREAKPOINTS.min1720} {
      margin-top: 200px;
    }

    ${MEDIA_BREAKPOINTS.max1180} {
      max-width: 600px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-bottom: 25px;
  }
`;

const offsetBanner = css`
  ${MEDIA_BREAKPOINTS.min1000} {
    margin-top: calc(50vh - 240px);

    ${MEDIA_BREAKPOINTS.min1720} {
      margin-top: calc(37vh - 240px);
    }
  }
`;

const KeywordsContainer = styled('div')`
  margin-bottom: 60px;

  ${MEDIA_BREAKPOINTS.min1720} {
    position: relative;
    bottom: 5vh;
  }
`;

const ResultsContainer = styled('div')``;

const PaginationContainer = styled('div')`
  ${hideOnSmallDeviceCss};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${MEDIA_BREAKPOINTS.min1000} {
    position: relative;
    top: -10px;
  }
  ${LARGE_DEVICE_BREAKPOINT} {
    ${MEDIA_BREAKPOINTS.max1000} {
      margin-bottom: 10px;
    }
  }
`;

export const DropdownsContainer = styled('div')`
  display: flex;
  flex-direction: row;

  & > div:first-of-type {
    margin-right: 10px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    flex-direction: column;

    & > div:first-of-type {
      margin-bottom: 20px;
    }

    & > div:last-of-type {
      margin-bottom: 10px;
    }
  }
`;

const ResultsNavAsideWrapper = styled('div')`
  ${MEDIA_BREAKPOINTS.min1000} {
    display: none;
  }
`;

export const KEY_VIEWING_SONG = 'VIEWING_SONG';

const DesktopResults = () => {
  const { keywords, goToSongById } = useResultsManagerContext();
  const { referralCoupon, showReferralBanner } = useSubscriptionsContext();
  const { showLeaveResultsModal, showLeaveResultFiltersModal } = useModalsContext();
  const selectedSong = useResultsSelectedSong();
  const songID = getSongID(selectedSong);

  const handleNewSearch = () => {
    showLeaveResultsModal();
  };

  const handleClearFilters = (
    newKeywords: Array<string>,
    confirmKeywordRemoval: {
      setQuery: (Array<string>) => void,
      setMagicQuery: (Array<string>) => void,
      handleClearSongFilters: string => void,
      isMagicKeyword: boolean,
      isYouTubeKeyword?: boolean,
      handleRemoveYouTubeKeyword?: () => void,
    }
  ) => {
    showLeaveResultFiltersModal(newKeywords, confirmKeywordRemoval);
  };

  const checkNav = () => {
    const REDIRECTED = ls.get(KEY_REDIRECTED);
    const VIEWING_SONG = ls.get(KEY_VIEWING_SONG);
    if (REDIRECTED && REDIRECTED > Date.now() - 2 * (60 * 1000)) {
      if (VIEWING_SONG) {
        if (keywords.join(',') === VIEWING_SONG.query) {
          setTimeout(() => {
            goToSongById(VIEWING_SONG.songID);
          });
        }
      }
    }
    ls.remove(KEY_REDIRECTED);
    ls.remove(KEY_VIEWING_SONG);
  };

  useEffect(() => {
    checkNav();
  }, []);

  useEffect(() => {
    ls.set(KEY_VIEWING_SONG, {
      songID,
      query: keywords.join(','),
    });
  }, [songID]);
  if (!selectedSong) return null;
  return (
    <Container className={cx({ [offsetBanner]: referralCoupon && showReferralBanner })}>
      <KeywordsContainer>
        <ResultsKeywords
          onClear={handleNewSearch}
          onClearFilters={handleClearFilters}
          editEnabled
        />
      </KeywordsContainer>
      <ResultsContainer>
        <PaginationContainer>
          <DropdownsContainer>
            <SortResultsDropdown />
            <ResultsMainPagination />
          </DropdownsContainer>
          <ResultsNavAsideWrapper>
            <ResultsNav />
          </ResultsNavAsideWrapper>
        </PaginationContainer>
        <div>
          <AudioPlayerWrapper>
            <AudioCardWrapper
              autoload
              autoplay
              song={selectedSong}
              key={getSongID(selectedSong)}
              mainAudioCard
              playerType={PLAYER_TYPES.mainResults}
            >
              <FullSongCard song={selectedSong} playerType={PLAYER_TYPES.mainResults} />
            </AudioCardWrapper>
          </AudioPlayerWrapper>
        </div>
      </ResultsContainer>
    </Container>
  );
};

const ErrorContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {};

const ResultsMain = () => {
  const { noResults, resultsError } = useResultsManagerContext();
  const resultsCopy = useResultsPage();

  if (resultsError) {
    return (
      <ErrorContainer>
        <GenericCopy>{parse(getResultsPageGenericErrorMessage(resultsCopy))}</GenericCopy>
      </ErrorContainer>
    );
  }

  if (noResults) {
    return (
      <>
        <NoResults />
      </>
    );
  }
  return (
    <React.Fragment>
      <LargeDeviceOnly>
        <DesktopResults />
      </LargeDeviceOnly>
      <SmallDeviceOnly>
        <MobileResults />
      </SmallDeviceOnly>
    </React.Fragment>
  );
};

export default ResultsMain;
