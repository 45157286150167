// @flow
import React, { useState } from 'react';
import styled, { css, cx } from 'react-emotion';
import {
  LARGE_DESKTOP_BREAKPOINT,
  MEDIA_BREAKPOINTS,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../styles/responsive';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  planCodeToCurrentPlanKey,
} from '../../../../../../utils/subscriptions';
import { isLargeMobileDeviceSize } from '../../../../../../utils/device';
import SmallArrow from '../../../../../../assets/inline-assets/arrow-small-icon.svg';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../../../../components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../../../../locales';
import { CURRENCY_CODES, CURRENCY_SYMBOLS } from '../../../../../../user/subscriptions/data';
import {
  getPricingPagePromotionalLabelNewPlan,
  isPromotionAvailable,
} from '../../../../../../cms/pricingPage';
import {
  usePricingPage,
  usePricingPagePlan,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  businessBgColor,
  creatorBgColor,
  creatorProBgColor,
  darkColor,
  enterpriseBgColor,
  lightColor,
} from '../../../../../../styles/config/colors';
import { BANNERS } from '../../../../../../images/paths';
import { transparentize } from 'polished';
import parse from 'html-react-parser';
import Button from '../../../../../../components/Button/Button';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../../../api/firebase/user/profile';
import { getPricingPagePlanFormLink } from '../../../../../../cms/pricingPagePlan';
import {
  analyticsMixpanelEnterpriseContactRequest,
  userRoleToMixpanelRole,
} from '../../../../../../analytics/mixpanel';
import {
  useAuthContext,
  useIsAuthenticated,
} from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useUserProfileDetails } from '../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useNavigate } from '../../../../../hooks';
import { ROUTES } from '../../../../../routes';
import { useSubscriptionsContext } from '../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SPLIT_PAYMENT_EN_URL, SPLIT_PAYMENT_URL } from '../../../../../../utils/const';

const PlanLayout = styled('div')`
  padding: 10px 30px 10px 30px;

  ${MEDIA_BREAKPOINTS.max1260} {
    padding-bottom: 15px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    padding-bottom: 25px;
  }
`;

const highlightedBackground = css`
  ${LARGE_DESKTOP_BREAKPOINT} {
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const PricingContainer = styled('div')`
  margin-top: 35px;
`;

const PriceDisplay = styled('div')`
  ${SMALL_DESKTOP_BREAKPOINT} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const ArrowContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const hiddenArrow = css`
  visibility: hidden;
`;

const leftArrowRotate = css`
  & svg {
    transform: rotate(180deg);
  }
`;

const PlanInclusionList = styled('div')`
  height: 320px;

  h3 {
    font-weight: 700;
  }

  ul {
    padding: 15px;
  }

  li {
    list-style: inside;
    list-style-position: outside;
    padding-bottom: 10px;
  }

  ${MEDIA_BREAKPOINTS.max1720} {
    height: 370px;
  }

  ${MEDIA_BREAKPOINTS.max1600} {
    height: 375px;
  }

  ${MEDIA_BREAKPOINTS.max1500} {
    height: 445px;
  }

  ${MEDIA_BREAKPOINTS.max1400} {
    height: 510px;
  }

  ${MEDIA_BREAKPOINTS.max1300} {
    height: 520px;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    height: 400px;
  }

  ${MEDIA_BREAKPOINTS.max1180} {
    height: 445px;
  }

  ${MEDIA_BREAKPOINTS.max1070} {
    height: 500px;
  }

  ${MEDIA_BREAKPOINTS.max1020} {
    height: 550px;
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    height: 270px;
  }

  ${MEDIA_BREAKPOINTS.max450} {
    height: unset;
  }
`;

const Price = styled('p')`
  font-family: 'Roboto Mono';
  font-size: 30px;
  letter-spacing: 0;
  padding: 15px 0px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 15px;
  height: 65px;

  & sup {
    font-family: Roboto;
    letter-spacing: 0.75px;
    line-height: 30px;
  }

  & sub {
    font-size: 30px;
    position: relative;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    height: 100px;
    padding: 10px;
    font-size: 25px;

    sub {
      font-size: 20px;
    }
  }
`;

const japaneseLocalePrice = css`
  & sub {
    font-size: 20px;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    & sub {
      font-size: 25px;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    & sub {
      font-size: 20px;
    }
  }
`;

const textPrice = css`
  margin-right: 0px;
`;

const creatorPlanCss = css`
  background-color: ${creatorBgColor};
  background-image: url(${BANNERS.creator});
`;

const creatorProPlanCss = css`
  background-color: ${creatorProBgColor};
  background-image: url(${BANNERS.creatorPro});
`;

const businessPlanCss = css`
  background-color: ${businessBgColor};
`;

const enterprisePlanCss = css`
  background-color: ${enterpriseBgColor};
`;

const roiyalMemberSubscriptionButtonLayout = css`
  background-color: #f4c731;
`;

const PromotionLabel = styled('div')`
  text-align: center;
  margin-top: 20px;
  border-radius: 2px;
  background-size: cover;
  background-position: center;

  & div {
    width: 100%;
    height: 100%;
    background-color: ${transparentize(0.5, darkColor)};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px 0px;
  }

  & h6 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: ${lightColor};
  }

  & p {
    margin-top: 1px;
    font-size: 12px;
    padding: 0px 20px 0px 20px;
    line-height: 20px;
  }
`;

const PlanAction = styled('div')`
  height: 130px;
  margin-bottom: 10px;
  padding: 0px 25px;
  justify-content: center;
  width: 100%;

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    & button {
      padding: 10px;
    }
  }
`;

const subscribeButtonLayout = css`
  background-color: #171c61;
`;

const enterpriceSubscriptionButtonLayout = css`
  background-color: #4d4d4d;
`;

const PaymentLabel = styled('p')`
  text-align: center;
  font-size: 12px;
  padding-bottom: 20px;
`;

const PlanRecommendList = styled('div')`
  padding: 20px;

  h4 {
    font-size: 18px;
    padding: 10px;
    margin-bottom: 10px;
  }

  ul {
    list-style: inherit;
    padding: 0px 20px 20px;
  }

  li {
    line-height: 15px;
    font-size: 11px;
  }
`;

const paymentButtonCss = css`
  margin: 0 !important;
`;

type PricingPlanCardType = {
  planCode: string,
  selectedInterval: String,
  isPreviousAvailable: boolean,
  handleIntervalChange: () => void,
  isNextAvailable: boolean,
  hasExternalLink: boolean,
  setShowEnterpriseModal: (showEnterpriseModal: boolean) => void,
  selectOption: string,
};

const PricingPlanCard = ({
  planCode,
  selectedInterval,
  isPreviousAvailable,
  handleIntervalChange,
  isNextAvailable,
  hasExternalLink = false,
  setShowEnterpriseModal,
  selectOption = 'oneTimePayment',
}: PricingPlanCardType) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const pricingPageCopy = usePricingPage();
  const { signUpSourceData, setSignUpSourceData, isAuthenticated } = useAuthContext();
  const [busy, setBusy] = useState(false);
  const profile = useUserProfileDetails();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const authenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { addNewSubscription, userRole } = useSubscriptionsContext();
  const pricingPagePlanCopy = usePricingPagePlan(planCode);

  const currencySymbol = CURRENCY_SYMBOLS[CURRENCY_CODES[locale]];
  const promotionAvailable = isPromotionAvailable(pricingPageCopy, planCode, selectedInterval);
  const promotionalText = getPricingPagePromotionalLabelNewPlan(pricingPageCopy);

  const handlePreviousInterval = () => {
    handleIntervalChange(selectedInterval, false);
  };

  const handleNextInterval = () => {
    handleIntervalChange(selectedInterval, true);
  };

  const handleEnterpriseClick = async () => {
    if (!isAuthenticated) {
      setShowEnterpriseModal(true);
      return;
    }

    setBusy(true);

    try {
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = getPricingPagePlanFormLink(pricingPagePlanCopy);
      const userTier = userRoleToMixpanelRole(userRole);
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        analyticsMixpanelEnterpriseContactRequest(
          mixpanel,
          moengage,
          email,
          firstName,
          lastName,
          'Pricing',
          userTier
        );
        setTimeout(() => {
          window.location.href = completeFormLink;
        }, 300);
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      setBusy(true);
      return;
    }
  };

  const handleCtaClick = () => {
    if (!authenticated) {
      const chosenPlan = planCodeToCurrentPlanKey(planCode);
      if (!authenticated && signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Pricing', signUpCampaign: '' });
      }
      navigate(ROUTES.signup.navigatePath({ chosenPlan, selectedInterval }));
    } else {
      handleAddSubscription();
    }
  };

  const handleAddSubscription = () => {
    if (busy) return;
    setBusy(true);
    addNewSubscription(planCode, selectedInterval)
      .then(() => {
        setBusy(false);
        navigate(ROUTES.subscriptions.navigatePath({}));
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when adding subscription from pricing page');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const getPlanInclusionList = planCode => {
    if (isCreatorSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.member.prevInclusion', '')}</h3>
          <ul>
            {t('pricing.plans.plan.inclusions.member.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isCreatorProSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.loyalMember.prevInclusion', '')}</h3>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMember.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isBusinessSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.loyalMemberPlus.prevInclusion', '')}</h3>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMemberPlus.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isEnterpriseSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.enterprise.prevInclusion', '')}</h3>
        </>
      );
    }

    return '';
  };

  const getPlanAnnualyPrice = planCode => {
    if (isCreatorSubscription(planCode)) {
      return t('general.pricing.price.member.annually', '');
    }

    if (isCreatorProSubscription(planCode)) {
      return t('general.pricing.price.loyalMember.annually', '');
    }

    if (isBusinessSubscription(planCode)) {
      return t('general.pricing.price.loyalMemberPlus.annually', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.pricing.price.enterprise.annually', '');
    }
  };

  const getAnnuallyButtonLabel = planCode => {
    if (isCreatorSubscription(planCode)) {
      return t('general.button.signUp', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.button.getInContact', '');
    }

    return t('general.button.oneTimePayment', '');
  };

  const getPaymentMethodAnnually = planCode => {
    if (isCreatorProSubscription(planCode) || isBusinessSubscription(planCode)) {
      return t('pricing.plans.plan.payment.method.annually', '');
    }

    return '';
  };

  const getPlanMonthlyPrice = planCode => {
    if (isCreatorSubscription(planCode)) {
      return t('general.pricing.price.member.monthly', '');
    }

    if (isCreatorProSubscription(planCode)) {
      return t('general.pricing.price.loyalMember.monthly', '');
    }

    if (isBusinessSubscription(planCode)) {
      return t('general.pricing.price.loyalMemberPlus.monthly', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.pricing.price.enterprise.monthly', '');
    }
  };

  const getMonthlyButtonLabel = planCode => {
    if (isCreatorSubscription(planCode)) {
      return t('general.button.signUp', '');
    }

    if (isEnterpriseSubscription(planCode)) {
      return t('general.button.getInContact', '');
    }

    return t('general.button.splitPayment', '');
  };

  const getPlanAnnuallyOrMonthlyButtonLabel = (selectOption, planCode) => {
    if (selectOption === 'oneTimePayment') {
      return getAnnuallyButtonLabel(planCode);
    }

    if (selectOption === 'splitPayment') {
      return getMonthlyButtonLabel(planCode);
    }
  };

  const getPlanAnnuallyOrMonthlyPrice = (selectOption, planCode) => {
    if (selectOption === 'oneTimePayment') {
      return getPlanAnnualyPrice(planCode);
    }

    if (selectOption === 'splitPayment') {
      return getPlanMonthlyPrice(planCode);
    }
  };

  const getPaymentMethodMonthly = planCode => {
    if (isCreatorProSubscription(planCode) || isBusinessSubscription(planCode)) {
      return t('pricing.plans.plan.payment.method.monthly', '');
    }

    return '';
  };

  const getPaymentMethodAnnuallyOrMonthly = (selectOption, planCode) => {
    if (selectOption === 'oneTimePayment') {
      return getPaymentMethodAnnually(planCode);
    }

    if (selectOption === 'splitPayment') {
      return getPaymentMethodMonthly(planCode);
    }
  };

  const getRecommendContent = planCode => {
    if (isCreatorSubscription(planCode)) {
      return (
        <>
          <h4>{t('pricing.plans.plan.inclusions.member.recommendation.title', '')}</h4>
          <ul>
            {t('pricing.plans.plan.inclusions.member.recommendation.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isCreatorProSubscription(planCode)) {
      return (
        <>
          <h4>{t('pricing.plans.plan.inclusions.loyalMember.recommendation.title', '')}</h4>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMember.recommendation.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isBusinessSubscription(planCode)) {
      return (
        <>
          <h4>{t('pricing.plans.plan.inclusions.loyalMemberPlus.recommendation.title', '')}</h4>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMemberPlus.recommendation.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isEnterpriseSubscription(planCode)) {
      return (
        <>
          <h4>{t('pricing.plans.plan.inclusions.enterprise.recommendation.title', '')}</h4>
          <ul>
            {t('pricing.plans.plan.inclusions.enterprise.recommendation.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }
  };

  const handleSplitPayment = () => {
    window.location.href = locale === 'ja' ? SPLIT_PAYMENT_URL : SPLIT_PAYMENT_EN_URL;
  };

  const handlePayment = (selectOption, hasExternalLink, planCode) => {
    const chosenPlan = planCodeToCurrentPlanKey(planCode);
    if (isCreatorSubscription(planCode)) {
      return navigate(ROUTES.signup.navigatePath({ chosenPlan, selectedInterval }));
    }

    if (selectOption === 'oneTimePayment') {
      return hasExternalLink ? handleEnterpriseClick() : handleCtaClick();
    }

    if (selectOption === 'splitPayment') {
      return hasExternalLink ? handleEnterpriseClick() : handleSplitPayment();
    }
  };

  return (
    <>
      <PlanLayout
        className={cx({
          [highlightedBackground]: isBusinessSubscription(planCode),
        })}
      >
        <PricingContainer>
          <PriceDisplay>
            {isLargeMobileDeviceSize() && (
              <ArrowContainer
                className={cx(
                  {
                    [hiddenArrow]:
                      !isLargeMobileDeviceSize() ||
                      !isPreviousAvailable ||
                      isCreatorSubscription(planCode),
                  },
                  leftArrowRotate
                )}
              >
                <SmallArrow onClick={handlePreviousInterval} />
              </ArrowContainer>
            )}

            <PlanInclusionList>{getPlanInclusionList(planCode)}</PlanInclusionList>

            {isLargeMobileDeviceSize() && (
              <ArrowContainer
                className={cx({
                  [hiddenArrow]:
                    !isLargeMobileDeviceSize() ||
                    !isNextAvailable ||
                    isCreatorSubscription(planCode),
                })}
              >
                <SmallArrow onClick={handleNextInterval} />
              </ArrowContainer>
            )}
          </PriceDisplay>

          <>
            {promotionAvailable && (
              <PromotionLabel
                className={cx({
                  [creatorPlanCss]: isCreatorSubscription(planCode),
                  [creatorProPlanCss]: isCreatorProSubscription(planCode),
                  [businessPlanCss]: isBusinessSubscription(planCode),
                  [enterprisePlanCss]: isEnterpriseSubscription(planCode),
                })}
              >
                <div>{parse(promotionalText)}</div>
              </PromotionLabel>
            )}
          </>
        </PricingContainer>
      </PlanLayout>
      <PlanAction>
        <Price
          className={cx({
            [japaneseLocalePrice]: locale === LOCALES.japanese.code,
            [textPrice]: isEnterpriseSubscription(planCode),
          })}
        >
          <sup>{currencySymbol}</sup>
          {getPlanAnnuallyOrMonthlyPrice(selectOption, planCode)}
          {!isCreatorSubscription(planCode) && (
            <sub>{t('general.pricing.billingSubtext.monthly', '')}</sub>
          )}
        </Price>
        <Button
          className={cx(
            {
              [subscribeButtonLayout]: !isEnterpriseSubscription(planCode),
              [roiyalMemberSubscriptionButtonLayout]:
                selectOption === 'splitPayment' &&
                (isCreatorProSubscription(planCode) || isBusinessSubscription(planCode)),
              [enterpriceSubscriptionButtonLayout]: isEnterpriseSubscription(planCode),
            },
            paymentButtonCss
          )}
          width="full"
          onClick={() => handlePayment(selectOption, hasExternalLink, planCode)}
          loading={busy}
        >
          {getPlanAnnuallyOrMonthlyButtonLabel(selectOption, planCode)}
        </Button>
        <PaymentLabel>{getPaymentMethodAnnuallyOrMonthly(selectOption, planCode)}</PaymentLabel>
      </PlanAction>
      <PlanRecommendList>{getRecommendContent(planCode)}</PlanRecommendList>
    </>
  );
};

export default PricingPlanCard;
