import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import 'src/styles/global';
import AppContent from './components/AppContent/AppContent';
import AuthWrapper from '../../auth/components/AuthWrapper/AuthWrapper';
import MixpanelWrapper from '../../analytics/components/MixpanelWrapper';
import ErrorBoundary from '../../errors/components/ErrorBoundary/ErrorBoundary';
import UserProfileWrapper from '../../user/components/UserProfileWrapper/UserProfileWrapper';
import ToastWrapper from '../../toasts/components/ToastWrapper/ToastWrapper';
import ChargebeeWrapper from '../../user/subscriptions/components/ChargebeeWrapper/ChargebeeWrapper';
import '../../i18n/configs';

const App = () => {
  const [clientLoaded, setClientLoaded] = useState(false);
  useEffect(() => {
    setClientLoaded(true);
  });
  if (!clientLoaded) return null;
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <MixpanelWrapper>
          <AuthWrapper>
            <UserProfileWrapper>
              <ToastWrapper>
                <ChargebeeWrapper>
                  <AppContent />
                </ChargebeeWrapper>
              </ToastWrapper>
            </UserProfileWrapper>
          </AuthWrapper>
        </MixpanelWrapper>
      </RecoilRoot>
    </ErrorBoundary>
  );
};

export default App;
