// @flow
import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import ls from 'local-storage';
import styled from 'react-emotion';
import { useCookies } from 'react-cookie';
import { get } from 'lodash';
import LoadingDisplay from '../../../components/LoadingDisplay/LoadingDisplay';
import { useNavigate } from '../../hooks';
import { useLocalePath } from '../../components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../routes';
import { auth0ApiHandler, getAuth0AuthResultToken } from '../../../api/auth/auth0';
import { authenticateFirebaseUser, firebaseApiHandler } from '../../../api/firebase/api';
import {
  clearAuthDestination,
  getAuthDestination,
  setRedirectedTimestamp,
} from '../../../auth/destination';
import { useAuthContext } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { ERROR_PATH_CODES } from '../../../errors/components/ErrorCodeHandler/ErrorCodeHandler';
import { analyticsMixpanelAccountSignIn } from '../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';

export const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const handleRedirect = (destination: string) => {
  clearAuthDestination();
  setRedirectedTimestamp();
  window.history.replaceState({}, document.title, destination);
  navigate(destination);
};

type Props = {};

const CallbackScreen = () => {
  const localePath = useLocalePath();
  const localeNavigate = useNavigate();
  const { handleAuthenticated, handleUserSignedIn } = useAuthContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [cookies] = useCookies([]);

  const handleRedirectToDestination = () => {
    let destination = getAuthDestination();
    if (!destination) {
      destination = ROUTES.music.navigatePath({ localePath });
    }
    handleRedirect(destination);
  };

  useEffect(() => {
    const authCookieKey = Object.keys(cookies).filter(cookieName => {
      return cookieName.includes('com.auth0.auth');
    });
    const authCookieState = cookies[authCookieKey[0]];

    const auth = async () => {
      if (!window.location.hash.includes('access_token=')) {
        if (!process.env.GATSBY_IGNORE_REDIRECT) {
          window.location.href = ROUTES.landing.navigatePath({ localePath });
        }
      }
      let authResult;
      try {
        const sentryLocationLog = window.location.hash.replace('#access_token', '');
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.addBreadcrumb({
          message: sentryLocationLog,
        });
        Sentry.configureScope(scope => {
          scope.setUser({ callback_ocation: sentryLocationLog });
        });
        authResult = await auth0ApiHandler.parseHash(window.location.hash);
        const sentryParseValue = getAuth0AuthResultToken(authResult);
        const sentryParseExpires = authResult.expiresIn;
        const sentryParseState = get(authResult, 'state', '');
        Sentry.addBreadcrumb({
          data: {
            stateMatch: sentryParseState === authCookieState.state,
            value: sentryParseValue,
            expiry: sentryParseExpires,
          },
        });
        Sentry.configureScope(scope => {
          scope.setUser({
            stateMatch: sentryParseState === authCookieState.state,
            value: sentryParseValue,
            expiry: sentryParseExpires,
          });
        });
      } catch (error) {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when parsing auth hash');
        Sentry.captureException(error);
        console.error(error);
        localeNavigate(ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code }));
        return;
      }
      try {
        auth0ApiHandler.setAuthResult(authResult, true, true);
      } catch (error) {
        Sentry.captureMessage('Something went wrong when setting auth result');
        Sentry.captureException(error);
        console.error(error);
        localeNavigate(ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code }));
        return;
      }
      let firebaseToken;
      try {
        const response = await firebaseApiHandler.getFirebaseToken({
          authToken: getAuth0AuthResultToken(authResult),
        });
        Sentry.addBreadcrumb({
          message: `FB response ${response}`,
        });
        const { firebaseToken: token } = response;
        firebaseToken = token;
        const sentryFbValue = firebaseToken;
        Sentry.addBreadcrumb({
          message: `FB value ${sentryFbValue}`,
        });
        Sentry.configureScope(scope => {
          scope.setUser({ fb_value: sentryFbValue });
        });
      } catch (error) {
        Sentry.captureMessage('Something went wrong when fetching firebase token');
        Sentry.captureException(error);
        console.error(error);
        localeNavigate(ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code }));
        return;
      }
      try {
        await authenticateFirebaseUser(firebaseToken);
      } catch (error) {
        Sentry.captureMessage('Something went wrong when authenticating firebase user');
        Sentry.captureException(error);
        console.error(error);
        localeNavigate(ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code }));
        return;
      }
      try {
        await handleUserSignedIn();
      } catch (error) {
        Sentry.captureMessage('Something went wrong when handling user signed in');
        Sentry.captureException(error);
        console.error(error);
        localeNavigate(ROUTES.error.navigatePath({ errorCode: ERROR_PATH_CODES.auth.code }));
        return;
      }
      if (!ls.get('NEW_ACCOUNT_CREATED')) {
        analyticsMixpanelAccountSignIn(mixpanel, moengage, 'Email');
        ls.set('IS_LOGIN', true);
      }
      console.log('user signed in...');
      handleRedirectToDestination();
    };

    auth();
  }, []);
  return (
    <Container>
      <LoadingDisplay />
    </Container>
  );
};

export default CallbackScreen;
