// @flow
import React, { useContext, useEffect, useState } from 'react';
import ls from 'local-storage';
import { useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { useLocale } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useGenericApiState } from '../../../api/hooks';
import { useFetchFirestoreUserData } from '../../../api/firebase/user/user';
import type { FBUserMdl } from '../../../api/firebase/user/user';
import type { FbUserProfileMdl } from '../../../api/firebase/user/profile';
import {
  getProfileAvatar,
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../api/firebase/user/profile';
import { getUserDownloadCount } from '../../../api/firebase/user/downloads';
import { useWatchUserBookmarks } from '../../../api/firebase/user/bookmarks';
import type { UserBookmarks } from '../../../api/firebase/user/bookmarks';
import type { HiddenTracks } from '../../../api/firebase/user/hiddenTracks';
import {
  SavedCollections,
  useWatchUserSavedCollections,
} from '../../../api/firebase/user/collections';
import { getUserFreeTrialEligibility } from '../../../api/firebase/user/subscriptions';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import {
  mixpanelIdentifyUser,
  analyticsMixpanelCreateAccount,
  analyticsMixpanelAccountSignIn,
  mixpanelAliasUserId,
  syncMixpanelLocale,
} from '../../../analytics/mixpanel';
import { analyticsMoEngageSignIn } from '../../../analytics/moEngage';
import { useWatchUserHiddenTracks } from '../../../api/firebase/user/hiddenTracks';

const KEY_USER_STORAGE = 'user';

const handleStoreUser = (user: FBUserMdl) => {
  ls.set(KEY_USER_STORAGE, user);
};

const handleGetUser = (): FBUserMdl | null => {
  const user = ls.get(KEY_USER_STORAGE);
  return user || null;
};

export type UserProfileContextState = {
  user: FBUserMdl | null,
  loading: boolean,
  loaded: boolean,
  updateUserProfileData: ({}) => void,
  fetchLatestProfile: () => Promise<FBUserMdl>,
  bookmarks: UserBookmarks,
  bookmarksLoaded: boolean,
  hiddenTracks: HiddenTracks,
  hiddenTracksLoaded: boolean,
  savedCollections: SavedCollections,
  remainingDailyDownloads: number,
  setRemainingDailyDownloads: number => any,
  currentToastId: string,
  setCurrentToastId: string => void,
};

export const UserProfileContext = React.createContext();

export const useUserProfileContext = (): UserProfileContextState => useContext(UserProfileContext);

export const useUserProfileLoading = (): boolean => useUserProfileContext().loading;

export const useUserProfileLoaded = (): boolean => useUserProfileContext().loaded;

export const useUserProfileDetails = (): FbUserProfileMdl | null => {
  const { user } = useUserProfileContext();
  if (user) {
    return user.profile;
  }
  return null;
};

export const useUserAcronym = (): string => {
  const profile = useUserProfileDetails();
  if (!profile) return '';
  const firstName = getProfileFirstName(profile).trim();
  const lastName = getProfileLastName(profile).trim();
  const firstLetter = firstName ? firstName.substring(0, 1) : '';
  const lastLetter = lastName ? lastName.substring(0, 1) : '';
  return `${firstLetter}${lastLetter}`;
};

export const useUserAvatar = (): string => {
  const profile = useUserProfileDetails();
  if (!profile) return '';
  return getProfileAvatar(profile);
};

export const useUserName = (): string => {
  const profile = useUserProfileDetails();
  if (!profile) return '';
  const firstName = getProfileFirstName(profile);
  const lastName = getProfileLastName(profile);
  return `${firstName} ${lastName}`;
};

export const useUserEmail = (): string => {
  const profile = useUserProfileDetails();
  if (!profile) return '';
  return getProfileEmail(profile);
};

export const useUserBookmarks = (): UserBookmarks => {
  return useUserProfileContext().bookmarks;
};

export const useUserSavedCollections = (): SavedCollections => {
  return useUserProfileContext().savedCollections;
};

export const useUserHiddenTracks = (): HiddenTracks => {
  return useUserProfileContext().hiddenTracks;
};

export const useIsSongBookmarked = (songId: string): boolean => {
  const bookmarks = useUserBookmarks();
  return Object.keys(bookmarks).includes(songId);
};

export const useIsCollectionSaved = (collectionSlug: string): boolean => {
  const savedCollections = useUserSavedCollections();

  if (savedCollections) {
    return Object.keys(savedCollections).includes(collectionSlug);
  }
  return false;
};

export const useUserFreeTrialEligibility = (): boolean | null => {
  const { user } = useUserProfileContext();
  if (user) {
    return getUserFreeTrialEligibility(user);
  }
  return null;
};

type Props = {
  children: any,
};

const UserProfileWrapper = ({ children }: Props) => {
  const userId = useUserId();
  const [user, setUser] = useState(handleGetUser());
  const [fetchProfile, loading] = useFetchFirestoreUserData();
  const [bookmarks, bookmarksLoaded] = useWatchUserBookmarks();
  const [hiddenTracks, hiddenTracksLoaded] = useWatchUserHiddenTracks();
  const [savedCollections] = useWatchUserSavedCollections();
  const locale = useLocale();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [todaysDownloads, setTodaysDownloads] = useState(0);
  const [remainingDailyDownloads, setRemainingDailyDownloads] = useState(3);
  const [currentToastId, setCurrentToastId] = useState('');

  const loaded = !!user;

  const handleSetUser = (fbUser: FBUserMdl) => {
    handleStoreUser(fbUser);
    setUser(fbUser);
  };

  const fetchLatestProfile = (): Promise<any> => {
    if (!userId) {
      return Promise.reject();
    }
    return fetchProfile(userId)
      .then((response: FBUserMdl) => {
        handleSetUser(response);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when fetching user profile');
        Sentry.captureException(error);
        console.error(error);
        // todo - handle better
      });
  };

  useEffect(() => {
    if (user) {
      const {
        profile: { company, country, email, firstName, lastName, phoneNumber },
      } = user;
    }
  }, [user]);

  useEffect(() => {
    if (user && userId) {
      const isNewAccount = ls.get('NEW_ACCOUNT_CREATED') || false;
      if (isNewAccount) {
        // create Mixpanel aliases for auth0 id and user's email
        mixpanelAliasUserId(mixpanel, userId);
      }
      mixpanelIdentifyUser(mixpanel, userId);
      // syncMixpanelLocale(mixpanel, userId, locale);
      if (isNewAccount) {
        const newAccountData = ls.get('NEW_ACCOUNT_DATA');
        const {
          email,
          firstName,
          lastName,
          signUpLocale,
          signUpMethod,
          signUpSourceData,
        } = newAccountData;
        // Email Aliasing commented out until we find a resolution to profile data being overwritted
        // mixpanelAliasUserEmail(mixpanel, userId, email);
        analyticsMixpanelCreateAccount(
          mixpanel,
          moengage,
          email,
          firstName,
          lastName,
          signUpMethod,
          signUpSourceData,
          signUpLocale,
          userId
        );
        analyticsMixpanelAccountSignIn(mixpanel, moengage, 'Email');
        ls.remove('NEW_ACCOUNT_CREATED');
        ls.remove('NEW_ACCOUNT_DATA');
      }
      const isLogin = ls.get('IS_LOGIN') || false;
      if (isLogin) {
        analyticsMoEngageSignIn(moengage, userId, 'Email');
        ls.remove('IS_LOGIN');
      }
    }
  }, [user]);

  const updateUserProfileData = data => {
    if (!user) return Promise.reject();
    const { profile = {} } = user;
    const updatedUser = {
      ...user,
      profile: {
        ...profile,
        ...data,
      },
    };
    handleSetUser(updatedUser);
    return fetchLatestProfile();
  };

  useEffect(() => {
    if (userId) {
      fetchLatestProfile();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getUserDownloadCount(userId).then(response => {
        setTodaysDownloads(response);
      });
    }
  }, [userId]);

  useEffect(() => {
    const remainingDownloads = todaysDownloads >= 1 ? 0 : Math.max(0, 1 - todaysDownloads);
    setRemainingDailyDownloads(remainingDownloads);
  }, [todaysDownloads]);

  useEffect(() => {
    if (userId) {
      getUserDownloadCount(userId).then(response => {
        if (response !== todaysDownloads) {
          setTodaysDownloads(response);
        }
      });
    }
  }, [remainingDailyDownloads]);

  return (
    <UserProfileContext.Provider
      value={{
        user,
        loading,
        loaded,
        userHasSubscriptions: false,
        updateUserProfileData,
        fetchLatestProfile,
        bookmarks,
        bookmarksLoaded,
        remainingDailyDownloads,
        setRemainingDailyDownloads,
        hiddenTracks,
        hiddenTracksLoaded,
        savedCollections,
        currentToastId,
        setCurrentToastId,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileWrapper;
