// @flow
import React from 'react';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { CompactButton } from '../../../../../../components/Button/Button';
import { darkColor, lightColor, greyColor } from '../../../../../../styles/config/colors';
import {
  getMiscCopyAnnuallyButton,
  getMiscCopyBiannuallyButton,
  getMiscCopyQuarterlyButton,
  getMiscCopyMonthlyButton,
} from '../../../../../../cms/miscCopy';
import { BILLING_CYCLES } from '../../../../../../user/subscriptions/data';
import { useMiscCopy } from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';

type Props = {
  handleIntervalChange: string => void,
  isActiveInterval: boolean,
  intervalKey: string,
};

const CompactIntervalButton = styled(CompactButton)`
  margin: 0px 5px;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-align: center;
`;

const inactiveIntervalButton = css`
  background-color: ${darkColor};
  color: ${transparentize(0.6, lightColor)};

  &:hover {
    background-color: ${greyColor};
    color: ${lightColor};
  }

  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

const activeIntervalButton = css`
  background-color: ${lightColor};
  color: ${darkColor};

  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

const IntervalButton = ({ handleIntervalChange, isActiveInterval, intervalKey }: Props) => {
  const miscCopy = useMiscCopy();
  const handleClick = () => {
    handleIntervalChange(intervalKey);
  };

  const intervalLabel = (() => {
    if (intervalKey === BILLING_CYCLES.annually.cycle) {
      return getMiscCopyAnnuallyButton(miscCopy);
    }

    if (intervalKey === BILLING_CYCLES.biannually.cycle) {
      return getMiscCopyBiannuallyButton(miscCopy);
    }

    if (intervalKey === BILLING_CYCLES.quarterly.cycle) {
      return getMiscCopyQuarterlyButton(miscCopy);
    }

    return getMiscCopyMonthlyButton(miscCopy);
  })();

  return (
    <CompactIntervalButton
      className={cx({
        [inactiveIntervalButton]: !isActiveInterval,
        [activeIntervalButton]: isActiveInterval,
      })}
      onClick={handleClick}
    >
      {intervalLabel}
    </CompactIntervalButton>
  );
};

export default IntervalButton;
