// @flow

import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
} from '../../../../../../../store/globalPlayer';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { ROUTES } from '../../../../../../routes';
import { useGlobalPlayerContext } from '../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useNavigate } from '../../../../../../hooks';
import FullScreenCarouselItem from '../../../../../../../layouts/components/FullScreenCarousel/components/FullScreenCarouselItem';
import { analyticsMixpanelPreviewSongs } from '../../../../../../../analytics/mixpanel';
import type { CarouselItemMdl } from '../../../../../../../utils/featuredContent';

type Props = {
  itemData: CarouselItemMdl,
};

const FeaturedCollectionItem = ({ itemData }: Props) => {
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const globalTryingToPlay = useRecoilValue(globalTryingToPlayAtom);
  const globalLoadError = useRecoilValue(globalLoadErrorAtom);

  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleToggleGlobalPlayback,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();

  const userId = useUserId();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const navigate = useNavigate();

  const { id, listType, title, songs } = itemData;

  const itemIsOnQueue = playbackListType === listType && playbackListSlug === id;
  const isPlaying = itemIsOnQueue && globalPlaying;
  const tryingToPlay = itemIsOnQueue && globalTryingToPlay;
  const loadError = itemIsOnQueue && globalLoadError;

  const handleNavigateCollection = () => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'featuredCollection', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug: id }));
  };

  const handlePreviewCollection = () => {
    if (itemIsOnQueue) {
      handleToggleGlobalPlayback();
      return;
    }
    if (songs.length < 1) return;
    analyticsMixpanelPreviewSongs(
      mixpanel,
      moengage,
      'Collection',
      id,
      'Featured Collections',
      userId
    );
    handleUpdateGlobalAnalyticsData([], [], [], '', 'featuredCollection', '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', title, id);
  };

  return (
    <FullScreenCarouselItem
      itemData={itemData}
      playing={isPlaying}
      tryingToPlay={tryingToPlay}
      cantPlay={loadError}
      handleClick={handleNavigateCollection}
      handlePlay={handlePreviewCollection}
    />
  );
};

export default FeaturedCollectionItem;
