// @flow
import * as Yup from 'yup';
import { useSignInUpErrors } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getEmailRequiredError,
  getPasswordRequiredError,
  getValidEmailRequiredError,
} from '../../../../cms/signInUpErrors';

export const useSignInFormSchema = () => {
  const errors = useSignInUpErrors();
  return Yup.object().shape({
    password: Yup.string().required(getPasswordRequiredError(errors)),
    email: Yup.string()
      .email(getValidEmailRequiredError(errors))
      .required(getEmailRequiredError(errors)),
  });
};
