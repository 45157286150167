// @flow

import React from 'react';
import Slider from 'react-slick';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { isSmallDeviceSize } from '../../../utils/device';
import NextArrowIcon from '../../../assets/inline-assets/next-arrow-icon.svg';
import PrevArrowIcon from '../../../assets/inline-assets/prev-arrow-icon.svg';
import { buttonResetCss } from '../../../components/Button/Button';
import { smallHeadingCss } from '../../../styles/typography/components/Heading/Heading';
import { MEDIA_BREAKPOINTS } from '../../../styles/responsive';
import { darkColor } from '../../../styles/config/colors';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NavButton = styled('button')`
  ${buttonResetCss};
  ${smallHeadingCss};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: opacity 200ms ease-in-out;
  padding: 0px 30px;

  ${MEDIA_BREAKPOINTS.min1000} {
    &:not(:first-child) {
      margin-top: 20px;
    }

    svg {
      &:first-child {
        position: relative;
        left: -2px;
      }

      &:last-child {
        position: relative;
        right: 2px;
      }
    }
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    padding: 15px 24px 0px 24px;

    &:last-child {
      margin-left: -1px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 20);
    width: 50px;
    height: 50px;
    background-color: ${transparentize(0.95, darkColor)};
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transform: scale(0.75);
    transition: all 200ms ease-in;

    ${MEDIA_BREAKPOINTS.max1000} {
      width: 40px;
      height: 40px;
    }
  }

  &:disabled {
    opacity: 0.2;
    &::before {
      visibility: hidden;
    }
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    &::before {
      opacity: 1;
      transform: scale(1);
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }
  }

  &:active {
    &::before {
      transform: scale(0.85);
      transition-duration: 200ms;
    }
  }

  &.previous {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 2;
    }
    &::before {
      right: 43px;

      ${MEDIA_BREAKPOINTS.max1000} {
        right: 39px;
      }
    }
  }

  &.next {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 1;
    }
    &::before {
      left: 40px;

      ${MEDIA_BREAKPOINTS.max1000} {
        left: 39px;
      }
    }
  }

  svg {
    position: relative;
  }
`;

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: '0',
  display: 'flex',
  cursor: 'pointer',
  color: darkColor,
};

const CustomArrow = (props: { onClick?: () => void, previous?: boolean }) => {
  const { onClick, previous } = props;
  return (
    <div
      style={{
        ...arrowStyles,
        left: previous ? 0 : 'unset',
        right: previous ? 'unset' : 0,
        height: '100%',
      }}
    >
      <NavButton className={previous ? 'previous' : 'next'} type="button" onClick={onClick}>
        {previous ? <PrevArrowIcon /> : <NextArrowIcon />}
      </NavButton>
    </div>
  );
};

const settings = {
  infinte: true,
  dots: false,
  arrows: true,
  slidesToShow: 1,
  centerMode: true,
  prevArrow: <CustomArrow previous />,
  nextArrow: <CustomArrow />,
};

type Props = {
  children: any,
};

const FullScreenCarousel = ({ children }: Props) => {
  return (
    <Slider
      {...settings}
      centerPadding={isSmallDeviceSize() ? '5%' : '15%'}
      arrows={!isSmallDeviceSize()}
      touchMove={isSmallDeviceSize()}
      swipe={isSmallDeviceSize()}
    >
      {children}
    </Slider>
  );
};

export default FullScreenCarousel;
