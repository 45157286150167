// @flow
import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { css } from 'emotion';
import AuthRequiredWrapper from '../../../../../auth/components/AuthRequiredWrapper/AuthRequiredWrapper';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  useConnectPaypalCopy,
  useMiscCopy,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getConnectPaypalAddressPlaceholder,
  getConnectPaypalHeading,
  getConnectPaypalInstructions,
} from '../../../../../cms/connectPaypal';
import GenericCopy from '../../../../../styles/typography/components/GenericCopy/GenericCopy';
import PaypalEmailForm from './components/PaypalEmailForm/PaypalEmailForm';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { getMiscCopySubmitButton } from '../../../../../cms/miscCopy';
import PageComponent from '../../../../components/PageComponent/PageComponent';

const headingClass = css`
  margin-bottom: 20px;
`;

const FormSection = styled('section')`
  margin-top: 73px;
`;

type Props = {
  uri: string,
};

const SignUpPaypalView = ({ uri }: Props) => {
  const navigate = useNavigate();
  const copy = useConnectPaypalCopy();
  const miscCopy = useMiscCopy();

  const onSubmit = () => {
    navigate(ROUTES.signupCompleted.navigatePath({}));
  };

  return (
    <PageComponent path={uri}>
      <AuthRequiredWrapper>
        <header>
          <LargeHeading className={headingClass}>{getConnectPaypalHeading(copy)}</LargeHeading>
          <GenericCopy>{parse(getConnectPaypalInstructions(copy))}</GenericCopy>
        </header>
        <FormSection>
          <PaypalEmailForm
            onUpdate={onSubmit}
            inputPlaceholderText={getConnectPaypalAddressPlaceholder(copy)}
            saveButtonText={getMiscCopySubmitButton(miscCopy)}
          />
        </FormSection>
      </AuthRequiredWrapper>
    </PageComponent>
  );
};

export default SignUpPaypalView;
