// @flow
import { get } from 'lodash';
import { getUserAvatarsStorageRef } from '../storage';
import type { UserBookmarks } from './bookmarks';
import type { HiddenTracks } from './hiddenTracks';
import type { KeywordCount } from './keywordCount';
import type { SavedCollections } from './collections';

export type FbUserProfileMdl = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  company: string,
  country: string,
  avatar: string,
  emailSettings: {
    newmusic: boolean,
    updates: boolean,
  },
  paypal: string,
  bookmarks: UserBookmarks,
  hiddenTracks: HiddenTracks,
  keywordCount: KeywordCount,
  savedCollections: SavedCollections,
  recentCollections: Array<string>,
};

export const getProfileBookmarks = (profile: FbUserProfileMdl): UserBookmarks => {
  return get(profile, 'bookmarks', {});
};

export const getProfileHiddenTracks = (profile: FbUserProfileMdl): HiddenTracks => {
  return get(profile, 'hiddenTracks', {});
};

export const getProfileSavedCollections = (profile: FbUserProfileMdl): SavedCollections => {
  return get(profile, 'savedCollections', {});
};

export const getProfileEmailSettingsNewMusic = (profile: FbUserProfileMdl): string => {
  return get(profile, 'emailSettings.newmusic', true);
};

export const getProfileEmailSettingsUpdates = (profile: FbUserProfileMdl): string => {
  return get(profile, 'emailSettings.updates', true);
};

export const getProfileFirstName = (profile: FbUserProfileMdl): string => {
  return get(profile, 'firstName', '');
};

export const getProfileLastName = (profile: FbUserProfileMdl): string => {
  return get(profile, 'lastName', '');
};

export const getProfileEmail = (profile: FbUserProfileMdl): string => {
  return get(profile, 'email', '');
};

export const getProfilePhoneNumber = (profile: FbUserProfileMdl): string => {
  return get(profile, 'phoneNumber', '');
};

export const getProfileCompany = (profile: FbUserProfileMdl): string => {
  return get(profile, 'company', '');
};

export const getProfileCountry = (profile: FbUserProfileMdl): string => {
  return get(profile, 'country', '');
};

export const getProfileAvatar = (profile: FbUserProfileMdl): string => {
  return get(profile, 'avatar', '');
};

export const getProfilePaypal = (profile: FbUserProfileMdl): string => {
  return get(profile, 'paypal', '');
};

export const getProfileKeywordCount = (profile: FbUserProfileMdl): KeywordCount => {
  return get(profile, 'keywordCount', {});
};

export const getProfileRecentCollections = (profile: FbUserProfileMdl): Array<string> => {
  return get(profile, 'recentCollections', []);
};

export const uploadUserAvatar = (uid: string, file: any): Promise<string> => {
  const ref = getUserAvatarsStorageRef(uid);
  return ref.put(file).then(response => {
    return response.ref.getDownloadURL();
  });
};
