// @flow
import React from 'react';
import { Router } from '@reach/router';
import styled from 'react-emotion';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import { MediumContainer } from '../../../styles/layout';
import SignUpFormView from './views/SignUpFormView/SignUpFormView';
import { ROUTES } from '../../routes';
import SiteHeader from '../../../components/SiteHeader/SiteHeader';
import SignUpHeaderOptions from './components/SignUpHeaderOptions/SignUpHeaderOptions';
import SignUpSubscriptionsView from './views/SignUpSubscriptionsView/SignUpSubscriptionsView';
import SignUpPaypalView from './views/SignUpPaypalView/SignUpPaypalView';
import PageComponent from '../../components/PageComponent/PageComponent';
import SignUpEnterpriseFormView from './views/SignUpEnterpriseFormView/SignUpEnterpriseFormView';
import SignUpEnterpriseSubscriptionsView from './views/SignUpEnterpriseSubscriptionsView/SignUpEnterpriseSubscriptionsView';

const Container = styled(MediumContainer)`
  margin: 80px auto auto auto;
`;

type Props = {
  '*': string,
  path: string,
};

const Content = (props: Props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const path = props['*'];
  return (
    <PageComponent path={props.path}>
      <LayoutWrapper
        header={
          <SiteHeader
            headerOptions={<SignUpHeaderOptions path={path} />}
            siteName={null}
            hideNav
            disableHomeLink
            removeSiteName
            hideSiteNameOnDesktop
            showHeaderOptionsOnMobile
            hideReferralSignUpSignIn
          />
        }
        footer={null}
      >
        <Container>
          <Router>
            <SignUpFormView path={ROUTES.signup.signupPath} />
            <SignUpSubscriptionsView path={ROUTES.signupSubscriptions.signupPath} />
            <SignUpPaypalView path={ROUTES.signupPaypal.signupPath} />
            {/** for Enterprise-business */}
            <SignUpEnterpriseFormView path={ROUTES.signupEnterprise.signupPath} />
            <SignUpEnterpriseSubscriptionsView
              path={ROUTES.signupEnterpriseSubscriptions.signupPath}
            />
          </Router>
        </Container>
      </LayoutWrapper>
    </PageComponent>
  );
};

// サインアップページ
const SignUpScreen = (props: any) => <Content {...props} />;

export default SignUpScreen;
