import React from 'react';
import styled from 'react-emotion';
import { DarkButton } from '../../../../../components/Button/Button';

type Props = {
  label: string,
  handleClick: () => void,
  show: boolean,
};

const LoadWrapper = styled('div')`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const LoadMoreButton = ({ label, handleClick, show }: Props) => {
  return show ? (
    <LoadWrapper>
      <DarkButton onClick={handleClick}>{label}</DarkButton>
    </LoadWrapper>
  ) : (
    <></>
  );
};

export default LoadMoreButton;
