// @flow
import React, { useContext } from 'react';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';

export type SongContextState = {
  song: AlgoliaSongMdl,
};

export const SongContext = React.createContext();

export const useSongContext = (): SongContextState => {
  return useContext(SongContext);
};

export const useSong = (): AlgoliaSongMdl => {
  return useSongContext().song;
};

type Props = {
  children: any,
  song: AlgoliaSongMdl,
};

const SongContextWrapper = ({ children, song }: Props) => {
  return <SongContext.Provider value={{ song }}>{children}</SongContext.Provider>;
};

export default SongContextWrapper;
