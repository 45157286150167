// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import {
  simpleListItemCss,
  simpleListItemHighlightedCss,
  suggestionFocusedCss,
} from '../../styles/shared';
import { bodyTextCss } from '../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';

const Button = styled('button')`
  ${simpleListItemCss};
  background: none;
  text-align: left;
  padding: 0 9px 0 11px;

  svg {
    align-self: flex-start;
    position: relative;
  }

  &.minusOption {
    svg {
      top: 13px;
    }
  }

  &.plusOption {
    svg {
      top: 10px;
    }
  }
`;

export const keywordTextClass = css`
  ${bodyTextCss};
  text-transform: capitalize;

  ${SMALL_DEVICE_BREAKPOINT} {
    ${suggestionFocusedCss};
    border: 2px solid rgba(240, 240, 235, 0.05);
    background: none;
    border-radius: 3px;
  }
`;

type Props = {
  children: any,
  icon: any,
  iconStyle: string,
  highlighted?: boolean,
};

const InteractiveOption = ({
  children,
  className = '',
  highlighted,
  icon,
  iconStyle,
  ...otherProps
}: Props) => (
  <Button
    {...otherProps}
    iconStyle={iconStyle}
    className={cx(
      {
        [simpleListItemHighlightedCss]: highlighted,
        minusOption: iconStyle === 'minusIcon',
        plusOption: iconStyle === 'plusIcon',
      },
      className
    )}
  >
    {icon}
    <span>{children}</span>
  </Button>
);

export default InteractiveOption;

export const InteractiveKeyword = ({ className = '', dataCy, ...props }: any) => (
  <InteractiveOption {...props} className={cx(keywordTextClass, className)} data-cy={dataCy} />
);
