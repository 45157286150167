// @flow
import { get } from 'lodash';
import type { HiddenTracks } from '../api/firebase/user/hiddenTracks';
import {
  FILTER_MENU_TYPES,
  FILTER_TYPES,
} from '../routing/screens/ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';

export const getCountAfterHiddenSongs = (
  filterKey: string,
  filterLabel: string,
  hiddenTracksMap: { [string]: number },
  currentCount: number
): number => {
  let categoryName = '';
  const filterType = FILTER_MENU_TYPES[filterKey];

  if (filterType === FILTER_TYPES.filterSelect) {
    const separatedFilterKey = filterKey.split('.');
    // eslint-disable-next-line prefer-destructuring
    categoryName = separatedFilterKey[2];
  } else {
    categoryName = filterKey;
  }
  const hiddenTrackCount = get(hiddenTracksMap, `${categoryName}.${filterLabel}`, 0);
  return Math.max(currentCount - hiddenTrackCount, 0);
};

export const getHiddenCountMap = (hiddenSongs: HiddenTracks) => {
  const hiddenCountMap = {};
  const hiddenSongIds = Object.keys(hiddenSongs);

  hiddenSongIds.forEach(hiddenSongId => {
    const hiddenSongDetails = hiddenSongs[hiddenSongId];
    const detailsCategories = Object.keys(hiddenSongDetails);
    detailsCategories.forEach(category => {
      if (Array.isArray(hiddenSongDetails[category])) {
        const tagsList = hiddenSongDetails[category];
        tagsList.forEach(tag => {
          if (hiddenCountMap[`${category}.${tag}`]) {
            hiddenCountMap[`${category}.${tag}`] = hiddenCountMap[`${category}.${tag}`] + 1;
          } else {
            hiddenCountMap[`${category}.${tag}`] = 1;
          }
        });
      } else {
        hiddenCountMap[`${category}.${hiddenSongDetails[category]}`] = 1;
      }
    });
  });

  return hiddenCountMap;
};