// @flow
import React from 'react';
import styled from 'react-emotion';
import LoadingDisplay from '../../../../../../../components/LoadingDisplay/LoadingDisplay';
import { useResultsManagerContext } from '../ResultsManager/ResultsManager';
import CollectionResults from './components/CollectionResults/CollectionResults';
import EmptySearch from './components/EmptySearch/EmptySearch';
import SongResults from './components/SongResults/SongResults';
import NoSearchResults from './components/NoSearchResults/NoSearchResults';

const ResultsContainer = styled('div')``;

const LoadingWrapper = styled('div')`
  height: 50vh;
`;

const SearchResults = () => {
  const {
    loading,
    loaded,
    viewingResults,
    handleSectionChange,
    noResults,
    emptySearch,
    initialSearch,
  } = useResultsManagerContext();

  const handleViewMore = (viewSection: string) => {
    handleSectionChange(viewSection);
  };

  return (
    <>
      {(loading || initialSearch) && !emptySearch && (
        <LoadingWrapper>
          <LoadingDisplay />
        </LoadingWrapper>
      )}
      {loaded && emptySearch && <EmptySearch />}
      {!loading && loaded && !emptySearch && !initialSearch && noResults && <NoSearchResults />}
      {loaded && !loading && !noResults && (
        <ResultsContainer>
          <CollectionResults
            onViewMore={handleViewMore}
            showAll={viewingResults === 'collections'}
          />
          {viewingResults !== 'collections' && <SongResults />}
        </ResultsContainer>
      )}
    </>
  );
};

export default SearchResults;
