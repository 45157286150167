import React from 'react';
import Loops from '../../../../../modals/components/LoopsModal/components/Loops/Loops';
import AudioCardWrapper from '../../../../../song/components/AudioCardWrapper/AudioCardWrapper';
import { PLAYER_TYPES } from '../../../../../song/data';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import { AlgoliaSongMdl, getSongID } from '../../../../../api/algolia/song';
import { getTabbedLoopStartingIndex } from '../../../../../utils/songTabs';

type Props = {
  song: AlgoliaSongMdl,
  index: number,
  songName: string,
  selectedLoops: Array<AlgoliaSongMdl>,
  displayQuantity: number,
  loopsMixType: string,
};

const TabLoops = ({
  song,
  index,
  songName,
  selectedLoops,
  displayQuantity,
  loopsMixType,
}: Props) => {
  return (
    <AudioCardWrapper
      autoload
      song={song}
      key={getSongID(song)}
      mainAudioCard={false}
      playerType={PLAYER_TYPES.loops_loop}
    >
      <div>
        <Loops
          songTitle={songName}
          condensed
          loopStartingPosition={getTabbedLoopStartingIndex(selectedLoops, index)}
          visibleLoops={displayQuantity}
          loopsFilter={loopsMixType}
          pack
        />
      </div>
    </AudioCardWrapper>
  );
};

export default TabLoops;
