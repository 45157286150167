// @flow
import React, { useEffect } from 'react';
import styled from 'react-emotion';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import { useIsAuthenticated } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { ROUTES } from '../../routes';
import { useNavigate } from '../../hooks';
import SiteHeader from '../../../components/SiteHeader/SiteHeader';
import SignInScreenForm from './components/SignInScreenForm/SignInScreenForm';
import SignInHeaderOptions from './components/SignInHeaderOptions/SignInHeaderOptions';
import PageComponent from '../../components/PageComponent/PageComponent';

const SignInContainer = styled('div')`
  max-width: 500px;
  margin: 80px auto auto auto;
`;

const Content = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.music.navigatePath({}));
    }
  }, [isAuthenticated]);

  return (
    <LayoutWrapper
      header={
        <SiteHeader
          headerOptions={<SignInHeaderOptions />}
          siteName={null}
          hideNav
          disableHomeLink
          removeSiteName
          hideReferralSignUpSignIn
        />
      }
      footer={null}
    >
      <SignInContainer>
        <SignInScreenForm />
      </SignInContainer>
    </LayoutWrapper>
  );
};

type Props = {
  path: string,
};

// サインイン（ログイン）ページ
const SignInScreen = ({ path }: Props) => {
  return (
    <PageComponent path={path}>
      <Content />
    </PageComponent>
  );
};

export default SignInScreen;
