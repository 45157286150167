// @flow
import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import CommonSection from '../../../../../../../../../components/CommonSection/CommonSection';
import { getMiscCopySuggestionsTitle } from '../../../../../../../../../cms/miscCopy';
import { useMiscCopy } from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import InteractiveOption, {
  InteractiveKeyword,
} from '../../../../../../../../../components/InteractiveOption/InteractiveOption';
import PlusIcon from '../../../../../../../../../assets/inline-assets/plus-icon.svg';
import KeywordsList from './components/KeywordsList';
import { useLocale } from '../../../../../../../../components/LocaleWrapper/LocaleWrapper';
import { firebaseApiHandler } from '../../../../../../../../../api/firebase/api';
import { useKeywordsManagerContext } from '../../../KeywordsManager/KeywordsManager';
import { isSmallDeviceSize } from '../../../../../../../../../utils/device';

const suggestionsClass = css`
  margin-top: 28px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-itmes: center;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 160px;
  }
`;

type Props = {};

const SuggestedKeywords = () => {
  const locale = useLocale();
  const { keywords, addKeyword } = useKeywordsManagerContext();
  const [suggestions, setSuggestions] = useState([]);
  const [pendingSuggestions, setPendingSuggestions] = useState(null);
  const [mouseOver, setMouseOver] = useState(false);
  const copy = useMiscCopy();

  const handleMouseEnter = () => {
    setMouseOver(true);
  };

  const handleMouseLeave = () => {
    setMouseOver(false);
    if (pendingSuggestions) {
      setSuggestions(pendingSuggestions);
    }
  };

  useEffect(() => {
    firebaseApiHandler.getKeywordSuggestions(keywords, locale).then(response => {
      if (response && response instanceof Array && response.length > 0) {
        if (suggestions.length > 0 && mouseOver && !isSmallDeviceSize()) {
          setPendingSuggestions(response);
        } else {
          setPendingSuggestions(null);
          setSuggestions(response);
        }
      }
    });
  }, [keywords.join(',')]);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CommonSection className={suggestionsClass} heading={getMiscCopySuggestionsTitle(copy)}>
        <KeywordsList>
          {suggestions.map(suggestion => (
            <InteractiveKeyword
              icon={<PlusIcon />}
              iconStyle="plusIcon"
              key={suggestion}
              onClick={() => {
                addKeyword(suggestion, true);
              }}
            >
              {suggestion}
            </InteractiveKeyword>
          ))}
        </KeywordsList>
      </CommonSection>
    </div>
  );
};

export default SuggestedKeywords;
