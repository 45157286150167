// @flow
import React, { useEffect, useRef } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { StringParam, useQueryParam } from 'use-query-params';
import Details from './components/Details/Details';
import { TextButton } from '../../../components/Button/Button';
import { getResultsPageViewAllPartsButton } from '../../../cms/resultsPage';
import {
  useMicropartViewCopy,
  useResultsPage,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import SongActions from './components/SongActions/SongActions';
import SongType from './components/SongType/SongType';
import SongMiddle from './components/SongMiddle/SongMiddle';
import { hideOnSmallDeviceCss, SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import { useAudioPlaybackContext } from '../AudioPlayback/AudioPlayback';
import { monoFontCss, smallFontSizeCss } from '../../../styles/typography/typography';
import { getMicropartViewTitle } from '../../../cms/micropartView';

const usePlayerColor = () => {
  return useQueryParam('plColor', StringParam);
};

const Wrapper = styled('div')`
  position: relative;
`;

const Container = styled('div')`
  background-color: ${props => (props.playerColor ? `#${props.playerColor}` : '#25272c')};
  border-radius: 5px;
  padding: 25px 30px 35px 30px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 10px;
    overflow: hidden;
  }
`;

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Options = styled('div')`
  margin-top: -5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: -7px;
    margin-bottom: -5px;
  }
`;

export const viewAllButtonClass = css`
  padding: 5px;
  margin-right: -5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 11px;
    letter-spacing: 1.5px;
  }

  &:nth-child(2) {
    margin-left: 20px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-left: 10px;
    }
  }
`;

const Middle = styled('div')`
  margin-top: 74px;
  margin-bottom: 57px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 23px;
    margin-bottom: 20px;
  }
`;

const ViewingMicropartsTitle = styled('div')`
  ${hideOnSmallDeviceCss};
  ${monoFontCss};
  ${smallFontSizeCss};
  line-height: 20px;
  letter-spacing: 0.5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  margin-bottom: 8px;
  text-align: center;
  pointer-events: none;
`;

type Props = {
  song: AlgoliaSongMdl,
  disableViewAllParts?: boolean,
  handleShareModalState?: any,
};

const EmbedSongCard = ({
  song,
  handleShareModalState,
}: Props) => {
  const containerRef = useRef();
  const micropartViewCopy = useMicropartViewCopy();
  const { viewingMicroparts } = useAudioPlaybackContext();
  const [playerColor] = usePlayerColor();

  return (
    <Wrapper>
      {viewingMicroparts && (
        <ViewingMicropartsTitle>{getMicropartViewTitle(micropartViewCopy)}</ViewingMicropartsTitle>
      )}
      <div ref={containerRef}>
        <Container playerColor={playerColor}>
          <Header>
            <SongType song={song} />
          </Header>
          <Middle>
            <SongMiddle />
          </Middle>
          <SongActions handleShareModalState={handleShareModalState}/>
        </Container>
      </div>
    </Wrapper>
  );
};

export default EmbedSongCard;
