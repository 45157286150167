// @flow
import React, { useMemo, useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import seedrandom from 'seedrandom';
import { useMousePosition } from '@react-hook/mouse-position';
import { css } from 'emotion';
import { useRecoilValue } from 'recoil';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import {
  getParsedMicroparts,
  getSongDuration,
  getSongFamily,
  getSongHistogram,
} from '../../../../../api/algolia/song';
import type { HistogramDataMdl } from '../../../../../api/algolia/song';
import { darkColor } from '../../../../../styles/config/colors';
import MicroParts from '../../../AudioBars/components/MicroParts';
import useDimensions from '../../../../../utils/useDimensions';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import { useGradient } from '../../../../../utils/gradient';
import { useIsSmallDevice } from '../../../../../components/responsive/SmallDeviceOnly';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useBrowseManagerContext } from '../../../GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import {
  useModalsContext,
  useShowSignUp,
} from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { getHashCode } from '../../../../../utils/numbers';
import {
  songPositionAtom,
  songPlayingSelector,
  playbackListSlugAtom,
  songProgressAtom,
} from '../../../../../store/globalPlayer';
import { BREAKPOINTS } from '../../../../../styles/responsive';
import { useAuthContext } from '../../../../../auth/components/AuthWrapper/AuthWrapper';

const containerClass = css`
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    svg {
      rect {
        transition: fill 200ms ease, opacity 200ms ease;
      }
    }
  }
`;

const SVG = styled('svg')`
  rect {
    transition: fill 100ms ease, opacity 100ms ease;
    stroke: none;
  }
`;

const MicroPartsWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const defaultFill = `${transparentize(0.9, darkColor)}`;

const getHeight = (top: number, bottom: number, height: number): number => {
  const calculatedHeight = height * ((top + bottom) / 2);
  if (calculatedHeight < 2) return 2;
  return calculatedHeight;
};

const getYOffset = (top: number, bottom: number, height: number): number => {
  const yOffset = 1 - top;
  return (yOffset * height) / 2;
};

const idealBarWidth = 2;
const barGap = 1;

const randomBarTransform = (bars: [number, number], songFamily: string): [number, number] => {
  const randomiser = seedrandom(getHashCode(songFamily));
  let topBar = bars[0];
  let bottomBar = bars[1];
  const randomNumber = randomiser();
  if (randomNumber < 0.5) {
    topBar = bars[0] === 0 ? 0 : Math.max(bars[0] - 0.1, 0);
    bottomBar = bars[1] === 0 ? 0 : Math.max(bars[1] - 0.1, 0);
  } else {
    topBar = bars[0] === 0 ? 0 : Math.max(bars[0] + 0.1, 0);
    bottomBar = bars[1] === 0 ? 0 : Math.max(bars[1] + 0.1, 0);
  }

  return [topBar, bottomBar];
};

const getFilledArray = (histogram: HistogramDataMdl, numberOfBars: number, songFamily: string) => {
  const indexOffset = histogram.length / numberOfBars;
  const indexes = Array.from({ length: numberOfBars }).map((item, index) => {
    return Math.round(index * indexOffset);
  });
  let currentIndex = null;
  return indexes.map(index => {
    if (histogram[index] === undefined) return [0, 0];

    if (currentIndex === index) {
      const [top, bottom] = histogram[index];
      return randomBarTransform([top, bottom], songFamily);
    }
    currentIndex = index;
    return histogram[index];
  });
};

const getReducedArray = (histogram: HistogramDataMdl, numberOfBars: number) => {
  const indexOffset = histogram.length / numberOfBars;
  const indexes = Array.from({ length: numberOfBars }).map((item, index) => {
    return Math.round(index * indexOffset);
  });
  return indexes.map(index => {
    if (histogram[index] === undefined) return [0, 0];
    return histogram[index];
  });
};

const getBarsArray = (
  histogram: HistogramDataMdl,
  width: number,
  songFamily: string
): HistogramDataMdl => {
  const numberOfBars = Math.floor(width / (idealBarWidth + barGap));
  if (histogram.length < numberOfBars) {
    return getFilledArray(histogram, numberOfBars, songFamily);
  }
  return getReducedArray(histogram, numberOfBars);
};

const getScaledWaveform = (histogram: HistogramDataMdl): HistogramDataMdl => {
  return histogram.map(([top, bottom]) => {
    return [top / 100, bottom / 100];
  });
};

export type MicropartsMdl = Array<[number, number]>;

export const findMicropartIndex = (barOffset: number, microparts: MicropartsMdl): number => {
  return microparts.findIndex(([start, end]) => {
    return barOffset >= start && barOffset <= end;
  });
};

type Props = {
  condensed?: boolean,
  micropartsLabelDuration?: boolean,
  micropartsHiglight?: number,
  hideMicropartsOnMobile?: boolean,
  songPosition: number,
  checkSongPosition: boolean,
};

const DisplayAudioBars = ({
  condensed = false,
  micropartsLabelDuration = false,
  micropartsHiglight = null,
  hideMicropartsOnMobile = false,
  songPosition,
  checkSongPosition,
}: Props) => {
  const song = useSong();
  const songID = song.objectID;

  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();

  const globalSongPosition = useRecoilValue(songPositionAtom);
  const songPlaying = useRecoilValue(songPlayingSelector);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const songProgress = useRecoilValue(songProgressAtom);
  const {
    changeSongList,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const {
    browsingSongs,
    songListType,
    songListTitle,
    songListSlug,
    filterQuery,
    queueRestricted,
    restrictionType,
  } = useBrowseManagerContext();
  const {
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    sectionOrigin,
    artistProfile,
  } = useDownloadContext();
  const { showSimilarSearchRestrictedModal } = useModalsContext();

  const canPlay = true;
  const viewingMicroparts = false;
  const microPartIndex = 0;
  const onMicropartSelect = () => {};
  const onDownloadMicropart = () => {};

  // fix above
  const playingSongId = songPlaying ? songPlaying.objectID : '';
  const isQueuePlaying = songListSlug === playbackListSlug;
  const isSongPositionPlaying = checkSongPosition ? songPosition === globalSongPosition : true;

  const microparts = getParsedMicroparts(song);
  const duration = getSongDuration(song);
  const onSeek = (startingPosition: number) => {
    if (queueRestricted) {
      if (restrictionType === 'paidSubscription') {
        showSimilarSearchRestrictedModal();
      }
      return;
    }
    handleUpdateGlobalAnalyticsData(
      mixpanelKeywords,
      magicKeywords,
      youtubeKeywords,
      youtubeVideoTitle,
      sectionOrigin,
      '',
      artistProfile
    );
    changeSongList(browsingSongs, songPosition, startingPosition);
    updateGlobalPlayerPlaybackDetails(songListType, songListTitle, songListSlug, filterQuery);
  };

  const isSmallDevice = useIsSmallDevice();

  const hideMicroparts = isSmallDevice && hideMicropartsOnMobile;

  const rawProgress = songProgress / duration;
  const progressScaled =
    playingSongId === song.objectID && isQueuePlaying && isSongPositionPlaying ? rawProgress : 1;

  const gradient = useGradient(getSongFamily(song), microparts.length || 7);

  const histogram = getSongHistogram(song);

  const [hoveredMicroPart, setHoveredMicroPart] = useState(null);
  const [ref, { height, width }] = useDimensions();
  const [mousePositionRef, mousePosition] = useMousePosition(
    0, // enterDelay
    0, // leaveDelay
    60 // fps
  );

  const windowWidth = useWindowWidth();

  const svgWidth = useMemo(() => {
    if (BREAKPOINTS.d1720 - 1 < windowWidth) {
      return `${windowWidth * 0.4}px`;
    } else if (BREAKPOINTS.d1000 - 1 < windowWidth) {
      return `${windowWidth * 0.37}px`;
    } else {
      return ``;
    }
  }, [windowWidth]);

  const bars = useMemo(
    () => getBarsArray(getScaledWaveform(histogram), width, getSongFamily(song)),
    [width, songID]
  );

  const numberOfBars = bars.length;
  const finalBarWidth = idealBarWidth;
  const { x: mouseX, y: mouseY } = mousePosition;
  const cursorX = mouseX / width;
  const mouseIsOver = canPlay && mouseX !== null && mouseY !== null;

  const handleClick = event => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    const offsetX = (event.pageX - currentTargetRect.left) / width;

    if (viewingMicroparts) {
      const clickedMicroPartIndex = findMicropartIndex(offsetX, microparts);
      if (clickedMicroPartIndex !== microPartIndex) {
        onSeek(microparts[clickedMicroPartIndex][0]);
        return;
      }
    }
    onSeek(offsetX);
  };

  const svgBars = useMemo(() => {
    return bars.map(([top, bottom], index) => {
      const offset = index / numberOfBars;
      const played = canPlay && songProgress > 0 ? offset <= progressScaled : true; // from false
      const moused = mouseIsOver && offset <= cursorX;
      const active = played || moused;
      let highlightOpacity = active ? 1 : 0;
      if ((active && mouseIsOver && !played) || (active && played && mouseIsOver && !moused)) {
        highlightOpacity = 0.4;
      }
      let backOpacity = !active ? 1 : 0;

      const barMicroPartIndex = findMicropartIndex(offset, microparts);

      if (viewingMicroparts && hideMicroparts) {
        highlightOpacity = 1;
        backOpacity = 0;
      } else if (viewingMicroparts) {
        if (micropartsHiglight || micropartsHiglight === 0) {
          if (micropartsHiglight !== barMicroPartIndex) {
            highlightOpacity = 0;
            backOpacity = 1;
          } else if (
            micropartsHiglight === barMicroPartIndex &&
            barMicroPartIndex !== microPartIndex
          ) {
            highlightOpacity = 1;
            backOpacity = 0;
          }
        } else if (barMicroPartIndex !== microPartIndex) {
          if (barMicroPartIndex === hoveredMicroPart) {
            highlightOpacity = 0.4;
            backOpacity = 0;
          } else {
            highlightOpacity = 0;
            backOpacity = 1;
          }
        }
      }

      const barHeight = getHeight(top, bottom, height);
      const useMicropartsGradient = microparts.length > 0;

      const gradientIndex = (() => {
        if (useMicropartsGradient) {
          return barMicroPartIndex && barMicroPartIndex >= 0 ? barMicroPartIndex : 0;
        }
        return Math.floor((index / bars.length) * 7);
      })();

      const fill =
        gradient && gradient.length > 0 && gradient.length >= gradientIndex
          ? gradient[gradientIndex]
          : '';

      return (
        <React.Fragment key={`${songID}::${width.toString()}::${index.toString()}`}>
          <rect
            width={finalBarWidth}
            height={barHeight}
            y={getYOffset(top, bottom, height)}
            x={index * (idealBarWidth + barGap)}
            fill={defaultFill}
            opacity={backOpacity}
          />
          <rect
            width={finalBarWidth}
            height={barHeight}
            y={getYOffset(top, bottom, height)}
            x={index * (idealBarWidth + barGap)}
            fill={fill}
            opacity={highlightOpacity}
          />
        </React.Fragment>
      );
    });
  }, [songID, width, height, cursorX, rawProgress, mouseIsOver, numberOfBars, hoveredMicroPart]);

  return (
    <div className={containerClass} ref={mousePositionRef}>
      <div className={containerClass} ref={ref} onClick={handleClick}>
        <SVG className="audio-bar" width={svgWidth} height={height}>
          <defs>
            <linearGradient
              id="audioGradient"
              gradientTransform="rotate(90)"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="0"
              x2="100"
              y2="100"
            >
              <stop offset="0" stopColor="#8AD7D3" />
              <stop offset="1" stopColor="#18A299" />
            </linearGradient>
          </defs>
          {svgBars}
        </SVG>
        {viewingMicroparts && (
          <MicroPartsWrapper>
            <MicroParts
              duration={duration}
              microparts={microparts}
              microPartIndex={microPartIndex}
              onMicropartSelect={onMicropartSelect}
              setHoveredMicroPart={setHoveredMicroPart}
              condensed={condensed}
              onDownloadMicropart={onDownloadMicropart}
              micropartsLabelDuration={micropartsLabelDuration}
              micropartsHiglight={micropartsHiglight}
              hideMicroparts={hideMicroparts}
            />
          </MicroPartsWrapper>
        )}
      </div>
    </div>
  );
};

export default DisplayAudioBars;
