import { get } from 'lodash';
import {
  getMiscCopyBPM,
  getMiscCopyGenre,
  getMiscCopyGenres,
  getMiscCopyMood,
  getMiscCopyMoods,
  getMiscCopyEnergy,
  getMiscCopyEnergies,
  getMiscCopyLocation,
  getMiscCopyLocations,
  getMiscCopyMovement,
  getMiscCopyMovements,
  getMiscCopyVideoTheme,
  getMiscCopyVideoThemes,
  getMiscCopyEditStyle,
  getMiscCopyEditStyles,
  getMiscCopyInstruments,
  getMiscCopyType,
  getMiscCopyLength,
  getMiscCopySfxFeature,
  getMiscCopySfxFeatures,
  getMiscCopySfxType,
  getMiscCopySfxTypes,
} from '../../cms/miscCopy';
import type { MiscCopyMdl } from '../../cms/miscCopy';

export const FACET_KEYS = {
  length: 'audio.duration',
  bpm: 'bpm',
  edit_style: 'keywords.manual.edit-style',
  energy: 'keywords.manual.energy',
  genre: 'keywords.manual.genre',
  instruments: 'keywords.manual.instruments',
  location: 'keywords.manual.location',
  mood: 'keywords.manual.mood',
  movement: 'keywords.manual.movement',
  video_theme: 'keywords.manual.video-theme',
  type: 'isPart',
  mix_type: 'mixType',
  sfx_type: 'keywords.manual.type',
  sfx_feature: 'keywords.manual.feature',
};

const MIX_TYPES = {
  en: {
    grouped: {
      'All Parts': ['Karaoke', 'Drum and Bass', 'Melody', 'Harmony', 'Bass', 'Rhythm'],
    },
    ungrouped: ['Full Mix', 'SFX'],
  },
  ja: {
    grouped: {
      すべて: ['カラオケ', 'ドラム＆ベース', 'メロディ', 'ハーモニー', 'ベース', 'リズム'],
    },
    ungrouped: ['フルミックス', '効果音'],
  },
  cn: {
    grouped: {
      'All Parts': ['Karaoke', 'Drum and Bass', 'Melody', 'Harmony', 'Bass', 'Rhythm'],
    },
    ungrouped: ['Full Mix', 'SFX'],
  },
};

export const GROUPED_FILTER_LABELS = {
  en: {
    'All Parts': 'All Parts',
  },
  ja: {
    'All Parts': 'すべて',
  },
  cn: {
    'All Parts': 'All Parts',
  },
};

export const BOOLEAN_FILTERS_VALUE_MAP = {
  mixType: MIX_TYPES,
};

export const getGroupedBooleanFilters = (locale: string, facetKey: string): Array<string> => {
  return get(BOOLEAN_FILTERS_VALUE_MAP, `${facetKey}.${locale}.grouped`, {});
};

export const getUngroupedBooleanFilters = (locale: string, facetKey: string): any => {
  return get(BOOLEAN_FILTERS_VALUE_MAP, `${facetKey}.${locale}.ungrouped`, []);
};

export const getFacetKey = (facetKey: string): string => {
  return FACET_KEYS[facetKey];
};

export const getFilterLabel = (
  facetKey: string,
  miscCopy: MiscCopyMdl,
  isPlural?: true
): string => {
  if (facetKey === FACET_KEYS.mood) {
    return !isPlural ? getMiscCopyMood(miscCopy) : getMiscCopyMoods(miscCopy);
  }
  if (facetKey === FACET_KEYS.video_theme) {
    return !isPlural ? getMiscCopyVideoTheme(miscCopy) : getMiscCopyVideoThemes(miscCopy);
  }
  if (facetKey === FACET_KEYS.edit_style) {
    return !isPlural ? getMiscCopyEditStyle(miscCopy) : getMiscCopyEditStyles(miscCopy);
  }
  if (facetKey === FACET_KEYS.movement) {
    return !isPlural ? getMiscCopyMovement(miscCopy) : getMiscCopyMovements(miscCopy);
  }
  if (facetKey === FACET_KEYS.location) {
    return !isPlural ? getMiscCopyLocation(miscCopy) : getMiscCopyLocations(miscCopy);
  }
  if (facetKey === FACET_KEYS.energy) {
    return !isPlural ? getMiscCopyEnergy(miscCopy) : getMiscCopyEnergies(miscCopy);
  }
  if (facetKey === FACET_KEYS.genre) {
    return !isPlural ? getMiscCopyGenre(miscCopy) : getMiscCopyGenres(miscCopy);
  }
  if (facetKey === FACET_KEYS.instruments) {
    return getMiscCopyInstruments(miscCopy);
  }
  if (facetKey === FACET_KEYS.bpm) {
    return getMiscCopyBPM(miscCopy);
  }
  if (facetKey === FACET_KEYS.type) {
    return getMiscCopyType(miscCopy);
  }
  if (facetKey === FACET_KEYS.mix_type) {
    return getMiscCopyType(miscCopy);
  }
  if (facetKey === FACET_KEYS.length) {
    return getMiscCopyLength(miscCopy);
  }
  if (facetKey === FACET_KEYS.sfx_type) {
    return !isPlural ? getMiscCopySfxType(miscCopy) : getMiscCopySfxTypes(miscCopy);
  }
  if (facetKey === FACET_KEYS.sfx_feature) {
    return !isPlural ? getMiscCopySfxFeature(miscCopy) : getMiscCopySfxFeatures(miscCopy);
  }
  return '';
};

export const getAppliedFiltersExclusiveList = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string
): Array<string> => {
  const appliedFilter = get(appliedFilters, filter, null);
  return get(appliedFilter, `excluded`, []);
};

export const getAppliedFiltersInclusiveList = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string
) => {
  const appliedFilter = get(appliedFilters, filter, null);
  return get(appliedFilter, `included`, []);
};

export const isFilterExcluded = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string,
  filterValueToCheck: string
): boolean => {
  const excludedFilters = getAppliedFiltersExclusiveList(appliedFilters, filter);
  return excludedFilters.includes(filterValueToCheck);
};

export const isFilterIncluded = (
  appliedFilters: AppliedAlgoliaFilters,
  filter: string,
  filterValueToCheck: string
): boolean => {
  const includedFilters = getAppliedFiltersInclusiveList(appliedFilters, filter);
  return includedFilters.includes(filterValueToCheck);
};

export const getFacetBooleanValue = (resultFacetStats: any, facetKey: string): string | null => {
  return get(resultFacetStats, facetKey, null);
};

export const getFacetStatsMax = (resultFacetStats: any, facetKey: string): number => {
  const facetStats = resultFacetStats[facetKey];
  return get(facetStats, `max`, 0);
};

export const getFacetStatsMin = (resultFacetStats: any, facetKey: string): number => {
  const facetStats = resultFacetStats[facetKey];
  return get(facetStats, `min`, 0);
};
