// @flow
import ReactGA from 'react-ga';
import React from 'react';
import { globalHistory, Router } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import LocaleWrapper from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../../locales';
import NotFoundScreen from '../../../../routing/screens/NotFoundScreen/NotFoundScreen';
import LandingScreen from '../../../../routing/screens/LandingScreen/LandingScreen';
import BrowseScreen from '../../../../routing/screens/BrowseScreen/BrowseScreen';
import ProfileScreen from '../../../../routing/screens/ProfileScreen/ProfileScreen';
import SignUpScreen from '../../../../routing/screens/SignUpScreen/SignUpScreen';
import { PATHS } from '../../../../routing/routes';
import CallbackScreen from '../../../../routing/screens/CallbackScreen/CallbackScreen';
import ErrorScreen from '../../../../routing/screens/ErrorScreen/ErrorScreen';
import SignInScreen from '../../../../routing/screens/SignInScreen/SignInScreen';
import SignUpCompletedScreen from '../../../../routing/screens/SignUpCompletedScreen/SignUpCompletedScreen';
import TermsScreen from '../../../../routing/screens/TermsScreen/TermsScreen';
import BusinessLicenseScreen from '../../../../routing/screens/BusinessLicenseScreen/BusinessLicenseScreen';
import CreatorProLicenseScreen from '../../../../routing/screens/CreatorProLicenseScreen/CreatorProLicenseScreen';
import EnterpriseLicenseScreen from '../../../../routing/screens/EnterpriseLicenseScreen/EnterpriseLicenseScreen';
import LimitedMediaTrackLicenseScreen from '../../../../routing/screens/LimitedMediaTrackLicensePage/LimitedMediaTrackLicensePage';
import AllMediaTrackLicenseScreen from '../../../../routing/screens/AllMediaTrackLicensePage/AllMediaTrackLicensePage';
import ExclusiveRightsTrackLicenseScreen from '../../../../routing/screens/ExclusiveRightsTrackLicensePage/ExclusiveRightsTrackLicensePage';
import PrivacyScreen from '../../../../routing/screens/PrivacyScreen/PrivacyScreen';
import BookmarksFilterScreen from '../../../../routing/screens/BookmarksFilterScreen/BookmarksFilterScreen';
import ReferralScreen from '../../../../routing/screens/ReferralScreen/ReferralScreen';
// import ChromeExtensionScreen from '../../../../routing/screens/ChromeExtensionScreen/ChromeExtensionScreen';
import PricingScreen from '../../../../routing/screens/PricingScreen/PricingScreen';
import MusicScreen from '../../../../routing/screens/MusicScreen/MusicScreen';

// 各種画面コンポーネント
const ROUTES_COMPONENTS = [
  <NotFoundScreen default key="404" />,
  <LandingScreen path={PATHS.landing} key={PATHS.landing} />,
  <BrowseScreen path={PATHS.browse} key={PATHS.browse} />,
  <BookmarksFilterScreen path={PATHS.bookmarksFilter} key={PATHS.bookmarksFilter} />,
  <MusicScreen path={PATHS.music} key={PATHS.music} />,
  <ProfileScreen path={PATHS.profile} key={PATHS.profile} />,
  <SignUpCompletedScreen path={PATHS.signupCompleted} key={PATHS.signupCompleted} />,
  <SignUpScreen path={PATHS.signup} key={PATHS.signup} />,
  <SignInScreen path={PATHS.signin} key={PATHS.signin} />,
  <CallbackScreen path={PATHS.callback} key={PATHS.callback} />,
  <PrivacyScreen path={PATHS.privacy} key={PATHS.privacy} />,
  <TermsScreen path={PATHS.terms} key={PATHS.terms} />,
  <BusinessLicenseScreen path={PATHS.businessLicense} key={PATHS.businessLicense} />,
  <CreatorProLicenseScreen path={PATHS.creatorProLicense} key={PATHS.creatorProLicense} />,
  <EnterpriseLicenseScreen path={PATHS.enterpriseLicense} key={PATHS.enterpriseLicense} />,
  <LimitedMediaTrackLicenseScreen
    path={PATHS.limitedMediaTrackLicense}
    key={PATHS.limitedMediaTrackLicense}
  />,
  <AllMediaTrackLicenseScreen path={PATHS.allMediaTrackLicense} key={PATHS.allMediaTrackLicense} />,
  <ExclusiveRightsTrackLicenseScreen
    path={PATHS.exclusiveRightsTrackLicense}
    key={PATHS.exclusiveRightsTrackLicense}
  />,
  <ErrorScreen path={PATHS.errorCode} key={PATHS.errorCode} />,
  <ErrorScreen path={PATHS.error} key={PATHS.error} />,
  <ReferralScreen path={PATHS.referral} key={PATHS.referral} />,
  // Chrome Extensionの問い合わせ中のためコメントアウト
  // <ChromeExtensionScreen path={PATHS.chromeExtension} key={PATHS.chromeExtension} />,
  <PricingScreen path={PATHS.pricing} key={PATHS.pricing} />,
];

globalHistory.listen(({ location }) => {
  ReactGA.pageview(location.pathname);
});

const AppContent = () => {
  return (
    <React.Fragment>
      <QueryParamProvider reachHistory={globalHistory}>
        <Router basepath="/">
          <LocaleWrapper locale={LOCALES.english.code} path="/" localePath="">
            {ROUTES_COMPONENTS}
          </LocaleWrapper>
          <LocaleWrapper
            locale={LOCALES.english.code}
            path={`/${LOCALES.english.code}`}
            localePath={`/${LOCALES.english.code}`}
          >
            {ROUTES_COMPONENTS}
          </LocaleWrapper>
          <LocaleWrapper
            locale={LOCALES.japanese.code}
            path={`/${LOCALES.japanese.code}`}
            localePath={`/${LOCALES.japanese.code}`}
          >
            {ROUTES_COMPONENTS}
          </LocaleWrapper>
          <LocaleWrapper
            locale={LOCALES.chinese.code}
            path={`/${LOCALES.chinese.code}`}
            localePath={`/${LOCALES.chinese.code}`}
          >
            {ROUTES_COMPONENTS}
          </LocaleWrapper>
        </Router>
      </QueryParamProvider>
    </React.Fragment>
  );
};

export default AppContent;
