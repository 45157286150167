// @flow
import React from 'react';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { bodyTextCss, smallPlainTextCss } from '../../../../../styles/typography/typography';
import { styledListCss } from '../../../../../styles/shared';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';

const Container = styled('section')`
  ${smallPlainTextCss};
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 126px;
  margin-bottom: 145px;

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin: 30px 0px;
    padding: 10px 40px;
  }

  h2 {
    margin-bottom: 20px;
  }
  h6 {
    ${bodyTextCss};
    margin-top: 17px;
    & strong {
      font-weight: 400;
    }
  }
  h3 + p {
    margin-top: 10px;
  }
  p:not(:first-child) {
    margin-top: 10px;
  }
  ul {
    ${styledListCss};
    margin-top: 12px;
    padding-left: 10px;
    li {
      padding-left: 26px;
    }
  }
`;

const Links = styled('div')`
  margin-bottom: 30px;
`;

type Props = {
  heading: string,
  body: string,
  children?: any,
};

const InfoScreen = ({ heading, body, children }: Props) => (
  <Container>
    <Links>{children}</Links>
    <LargeHeading>{heading}</LargeHeading>
    <div>{parse(body)}</div>
  </Container>
);

export default InfoScreen;
