import { transparentize } from 'polished';
import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { getMiscCopyCancelButton } from '../../../cms/miscCopy';
import Modal, { ModalContainer, slimModalCss, slimModalOverlayCss } from '../Modal/Modal';
import { lightColor } from '../../../styles/config/colors';
import { BUTTON_WIDTHS, LightButton } from '../../../components/Button/Button';
import {
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { ICONS } from '../../../images/paths';
import { useMiscCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';

const Body = styled('div')`
  padding: 50px 0px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 20px 0px;
  }
`;

type Props = {
  className?: String,
  artistSlug: string,
  onClose: () => void,
};

const IconsArea = styled(`div`)`
  display: flex;
  flex-flow: column;
  width: 100%;
  & img {
    width: 20px;
    height: 20px;
  }
  li {
    letter-spacing: 0.5px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 20px;
    padding: 3px 0px;
    border-bottom: 1px solid ${transparentize(0.9, lightColor)};

    &:last-of-type {
      border-bottom: none;
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    & img {
      width: 20px;
      height: 20px;
    }
  }
`;

const CancelButtonWrapper = styled('div')`
  margin: 15px 20px 0px 20px;
`;

const StyledSnsButton = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 15px;
  &.hide {
    display: none;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 15px;
  }
`;

const cancelButtonCss = css`
  background-color: #4d4d4d;
`;

const SnsButton = ({ snsName, snsIcon, snsLink }) => {
  function onClickLink() {
    window.open(`${snsLink}`, '_blank');
  }
  return (
    <li>
      <StyledSnsButton onClick={onClickLink}>
        <div>{snsName}</div>
        <div>
          <img src={snsIcon} />
        </div>
      </StyledSnsButton>
    </li>
  );
};

const PosSnsModal = ({ onClose, linktoInstagram, linktoPokekara }) => {
  const handleClose = event => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };
  const miscCopy = useMiscCopy();

  return (
    <Modal className={slimModalCss} overlayClassName={slimModalOverlayCss} onClose={onClose}>
      <ModalContainer>
        <Body>
          <IconsArea>
            {linktoInstagram !== '' ? (
              <SnsButton
                snsName={'Instagram'}
                snsIcon={ICONS.instagram}
                snsLink={linktoInstagram}
              />
            ) : (
              ''
            )}
            {linktoPokekara !== '' ? (
              <SnsButton snsName={'Pokekara'} snsIcon={ICONS.pokekara} snsLink={linktoPokekara} />
            ) : (
              ''
            )}
          </IconsArea>
          <CancelButtonWrapper>
            <LightButton
              className={cancelButtonCss}
              onClick={handleClose}
              width={BUTTON_WIDTHS.full}
            >
              {getMiscCopyCancelButton(miscCopy)}
            </LightButton>
          </CancelButtonWrapper>
        </Body>
      </ModalContainer>
    </Modal>
  );
};

export default PosSnsModal;
