// @flow
import React from 'react';
import { useSetRecoilState } from 'recoil';
import PackIcon from '../../../../../../../../assets/inline-assets/pack-icon.svg';
import InlineButton from './InlineButton';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getTrackPackAction } from '../../../../../../../../cms/track';
import { ROUTES } from '../../../../../../../../routing/routes';
import { useNavigate } from '../../../../../../../../routing/hooks';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../../../../../../store/mixpanelAnalytics';

const ViewPackInlineButton = () => {
  const trackCopy = useTrackCopy();
  const song = useSong();
  const shareSlug = getSongShareSlug(song);
  const navigate = useNavigate();

  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const {
    location,
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));
  };
  return (
    <InlineButton
      label={getTrackPackAction(trackCopy)}
      icon={<PackIcon />}
      action={handleNavigatePack}
    />
  );
};

export default ViewPackInlineButton;
