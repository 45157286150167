// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ConnectYouTubeModalGraphMdl = {
  data: {
    header: {
      text: string,
    },
    subscriber: {
      text: string,
    },
    subscribers: {
      text: string,
    },
    error_message: {
      html: string,
    },
    empty_channel_error_message: {
      html: string,
    },
    retry_button: {
      text: string,
    },
    already_connected_message: {
      html: string,
    },
    already_connected_retry_button: {
      text: string,
    },
    contact_us_link: {
      html: string,
    },
    already_connected_header: {
      text: string,
    },
    duplicate_request_message: {
      text: string,
    },
  },
};

export const getConnectYouTubeChannelAlreadyConnectedRetryButton = (
  copy: ConnectYouTubeModalGraphMdl
): string => {
  return get(copy, 'data.already_connected_retry_button.text', '') || '';
};

export const getConnectYouTubeChannelAlreadyConnectedMessage = (
  copy: ConnectYouTubeModalGraphMdl
): string => {
  return get(copy, 'data.already_connected_message.html', '') || '';
};

export const getConnectYouTubeChannelRetryButton = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.retry_button.text', '') || '';
};

export const getConnectYouTubeChannelErrorMessage = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.error_message.html', '') || '';
};

export const getConnectYouTubeChannelEmptyChannelErrorMessage = (
  copy: ConnectYouTubeModalGraphMdl
): string => {
  return get(copy, 'data.empty_channel_error_message.html', '') || '';
};

export const getConnectYouTubeChannelHeading = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.header.text', '') || '';
};

export const getConnectYouTubeChannelSubscriber = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.subscriber.text', '') || '';
};

export const getConnectYouTubeChannelSubscribers = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.subscribers.text', '') || '';
};

export const getConnectYouTubeChannelContact = (copy: ConnectYouTubeModalGraphMdl): string => {
  return get(copy, 'data.contact_us_link.html', '') || '';
};

export const getConnectYouTubeChannelAlreadyConnectedHeader = (
  copy: ConnectYouTubeModalGraphMdl
): string => {
  return get(copy, 'data.already_connected_header.text', '') || '';
};

export const getConnectYouTubeChannelDuplicateRequestMessage = (
  copy: ConnectYouTubeModalGraphMdl
): string => {
  return get(copy, 'data.duplicate_request_message.text', '') || '';
};

export const query = graphql`
  fragment ConnectYouTubeModal on PrismicConnectYoutubeChannelModal {
    data {
      header {
        text
      }
      subscriber {
        text
      }
      subscribers {
        text
      }
      error_message {
        html
      }
      empty_channel_error_message {
        html
      }
      retry_button {
        text
      }
      already_connected_message {
        html
      }
      already_connected_retry_button {
        text
      }
      contact_us_link {
        html
      }
      already_connected_header {
        text
      }
      duplicate_request_message {
        text
      }
    }
  }
`;
