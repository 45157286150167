// @flow
import React from 'react';
import { css } from 'emotion';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button, {
  BUTTON_WIDTHS,
  extraVerticalPaddingButtonClass,
  PlainTextButton,
} from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import {
  useMiscCopy,
  useSignUpModal,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getSignUpModalHeading, getSignUpModalMessage } from '../../../cms/signup';
import { getMiscCopySignIn, getMiscCopySignUp } from '../../../cms/miscCopy';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useModalsContext } from '../ModalsWrapper/ModalsWrapper';

const signUpButtonLayout = css`
  ${extraVerticalPaddingButtonClass}
  background-color: #4d4d4d;
`;

type Props = {
  onClose: () => void,
};

const SignUpModal = ({ onClose }: Props) => {
  const { setSignInOpen } = useModalsContext();
  const localePath = useLocalePath();
  const miscCopy = useMiscCopy();
  const copy = useSignUpModal();
  const handleSignIn = () => {
    setSignInOpen(true);
    onClose();
  };
  const handleSignUp = () => {
    storeCurrentLocationAsAuthDestination();
    onClose();
  };
  return (
    <ClassicModalLayout onClose={onClose} label={getSignUpModalHeading(copy)} dataCy="signUpModal">
      <ClassicModalContentLayout
        heading={getSignUpModalHeading(copy)}
        description={getSignUpModalMessage(copy)}
        headerOption={
          <PlainTextButton uppercase onClick={handleSignIn} dataCy="modalSignInLink">
            {getMiscCopySignIn(miscCopy)}
          </PlainTextButton>
        }
        footer={
          <Button
            className={signUpButtonLayout}
            onClick={handleSignUp}
            to={ROUTES.signup.navigatePath({ localePath })}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {getMiscCopySignUp(miscCopy)}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default SignUpModal;
