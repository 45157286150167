// @flow
import { useSetRecoilState } from 'recoil';
import {
  AlgoliaSongMdl,
  getSongName,
  getSongDownloadUrl,
  getSongID,
  getSongMicropartDownloadURL,
  getSongMicropartPosition,
  getSongMicropartShareSlug,
  isSongSfx,
  getDownloadMIDIUrl,
} from '../api/algolia/song';
import { useGenericApiState } from '../api/hooks';
import {
  analyticsSongsDownload,
  analyticsSongsMicropartDownload,
  analyticsSongsSignUpPrompt,
  useSongAnalyticsDimensions,
  analyticsAccountDownloadQuotaReached,
  analyticsKeywordsNewDownloadSearchTerm,
} from '../analytics/events';
import {
  useDownloadContext,
  getDownloadContextKeywords,
} from '../components/DownloadContextWrapper/DownloadContextWrapper';
import { useAuthContext, useUserId } from '../auth/components/AuthWrapper/AuthWrapper';
import { useModalsContext, useShowSignUp } from '../modals/components/ModalsWrapper/ModalsWrapper';
import { firebaseApiHandler } from '../api/firebase/api';
import {
  useSubscriptionsContext,
  useFreeTrialSubscriptions,
} from '../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_ROLES, USER_PERMISSIONS } from '../user/subscriptions/data';
import { useUserProfileContext } from '../user/components/UserProfileWrapper/UserProfileWrapper';
import { useDesktopDownloadCounterToast } from '../toasts/hooks';
import { getUserDownloadCount } from '../api/firebase/user/downloads';
import { isSmallDeviceSize } from '../utils/device';
import { useAnalyticsMixpanelContext } from '../analytics/components/MixpanelWrapper';
import {
  useMixpanelSongAnalyticsDimensions,
  analyticsMixpanelDownloadTrack,
  analyticsMixpanelDownloadMicropart,
} from '../analytics/mixpanel';
import { conversionSourceAtom } from '../store/mixpanelAnalytics';
import { useLocale } from '../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../locales';
import { useState } from 'react';

export const useDownloadMicropart = (song: AlgoliaSongMdl, micropartIndex: number) => {
  const [busy, setBusy, apiErrorHandler] = useGenericApiState();
  const dimensions = useSongAnalyticsDimensions();
  const songID = getSongID(song);
  const downloadURL = getSongMicropartDownloadURL(song, micropartIndex);
  const downloadContext = useDownloadContext();
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const { showSubscriptionRequired, setShowTrialCounter } = useModalsContext();
  const freeTrialSubscriptions = useFreeTrialSubscriptions();
  const showSignUp = useShowSignUp();
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: null, moengage: null };
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const userId = useUserId();

  const handleDownload = () => {
    if (isAuthenticated) {
      if (!hasSubscription) {
        setConversionSource('download_micropart');
        showSubscriptionRequired();
        return Promise.resolve();
      }
      if (busy) return Promise.reject();

      if (userRole === USER_ROLES.creator) {
        console.log('Sorry you are not allowed to download microparts');
        return Promise.reject();
      }

      setBusy(true);
      analyticsSongsMicropartDownload(
        getSongMicropartShareSlug(song, micropartIndex),
        getSongMicropartPosition(song, micropartIndex),
        dimensions
      );
      analyticsKeywordsNewDownloadSearchTerm(
        getDownloadContextKeywords(downloadContext),
        songID,
        getSongMicropartPosition(song, micropartIndex)
      );

      return firebaseApiHandler
        .downloadSong(songID, downloadURL, downloadContext)
        .catch(apiErrorHandler)
        .finally(() => {
          analyticsMixpanelDownloadMicropart(
            mixpanel,
            moengage,
            mixpanelSongDimensions,
            downloadContext,
            userRole,
            userId,
            getSongMicropartPosition(song, micropartIndex) + 1
          );
          setTimeout(() => {
            setBusy(false);
            if (freeTrialSubscriptions.length >= 1) {
              if (isSmallDeviceSize()) {
                return;
              }
              setShowTrialCounter(true);
            }
          }, 1000);
        });
    }

    analyticsSongsSignUpPrompt('Download Micropart', dimensions);
    if (signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Download', signUpCampaign: '' });
    }
    setConversionSource('download_micropart');
    showSignUp();
    return Promise.resolve();
  };

  return [handleDownload, busy];
};

export const useDownloadSong = (song: AlgoliaSongMdl) => {
  const [busy, setBusy, apiErrorHandler] = useGenericApiState();
  const [midiBusy, setMidiBusy] = useState(false);
  const dimensions = useSongAnalyticsDimensions();
  const songID = getSongID(song);
  const downloadSongURL = getSongDownloadUrl(song);
  const downloadMIDIURL = getDownloadMIDIUrl(song);
  const downloadContext = useDownloadContext();
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const freeTrialSubscriptions = useFreeTrialSubscriptions();
  const {
    showSubscriptionRequired,
    setShowDownloadCounter,
    setShowTrialCounter,
  } = useModalsContext();
  const showSignUp = useShowSignUp();
  const userId = useUserId();
  const { remainingDailyDownloads, setRemainingDailyDownloads } = useUserProfileContext()
    ? useUserProfileContext()
    : { remainingDailyDownloads: 0, setRemainingDailyDownloads: number => {} };
  const downloadCounterToast = useDesktopDownloadCounterToast();
  const downloadQuotaDepletedToast = useDesktopDownloadCounterToast(true);
  const setConversionSource = useSetRecoilState(conversionSourceAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: null, moengage: null };
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const locale = useLocale();
  const songName = locale === LOCALES.english.code ? getSongName(song) : '';

  const isSfx = isSongSfx(song);
  const canDownloadSong = isSfx
    ? USER_PERMISSIONS[userRole].canDownloadSfx
    : hasSubscription &&
      (USER_PERMISSIONS[userRole].canDownloadSongs || USER_PERMISSIONS[userRole].canDownloadMIDI);

  const handleDownload = (downloadUrlType: string = 'wav') => {
    const isMidiType = downloadUrlType === 'MIDI';

    if (isAuthenticated) {
      if (busy) return Promise.reject();
      if (midiBusy) return Promise.reject();

      if (!canDownloadSong) {
        setConversionSource('download');
        showSubscriptionRequired();
        return Promise.resolve();
      }

      if (userRole === USER_ROLES.creator && remainingDailyDownloads <= 0) {
        setShowDownloadCounter(true);
        downloadCounterToast();
        return Promise.reject();
      }

      if (isMidiType) {
        setMidiBusy(true);
      } else {
        setBusy(true);
      }

      analyticsSongsDownload(dimensions);
      analyticsKeywordsNewDownloadSearchTerm(
        getDownloadContextKeywords(downloadContext),
        songID,
        null
      );

      return getUserDownloadCount(userId).then(response => {
        if (userRole === USER_ROLES.creator && response >= 1) {
          setShowDownloadCounter(true);
          setRemainingDailyDownloads(0);
          downloadQuotaDepletedToast();

          if (isMidiType) {
            setMidiBusy(false);
          } else {
            setBusy(false);
          }

          return Promise.reject();
        }

        const downloadURL = isMidiType ? downloadMIDIURL : downloadSongURL;

        return firebaseApiHandler
          .downloadSong(songID, downloadURL, downloadContext, songName)
          .catch(apiErrorHandler)
          .finally(() => {
            const updatedRemainingDownloads = remainingDailyDownloads - 1;
            const downloadQuotaReached =
              userRole === USER_ROLES.creator ? updatedRemainingDownloads === 0 : null;

            if (mixpanel && moengage) {
              analyticsMixpanelDownloadTrack(
                mixpanel,
                moengage,
                mixpanelSongDimensions,
                downloadContext,
                userRole,
                downloadQuotaReached,
                userId
              );
            }
            if (userRole === USER_ROLES.creator) {
              downloadCounterToast();

              if (updatedRemainingDownloads === 0) {
                analyticsAccountDownloadQuotaReached(userId, downloadContext);
              }
            }
            setRemainingDailyDownloads(updatedRemainingDownloads);
            setTimeout(() => {
              if (isMidiType) {
                setMidiBusy(false);
              } else {
                setBusy(false);
              }

              if (freeTrialSubscriptions.length >= 1) {
                if (isSmallDeviceSize()) {
                  return;
                }
                setShowTrialCounter(true);
              }
            }, 1000);
          });
      });
    }
    analyticsSongsSignUpPrompt('Download', dimensions);
    if (signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Download', signUpCampaign: '' });
    }
    setConversionSource('download');
    showSignUp();
    return Promise.resolve();
  };

  return [handleDownload, busy, midiBusy];
};
