// @flow
import { useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';
import { AudioPlaybackHandler } from '../song/player';
import type { MicropartsMdl } from '../song/components/AudioBars/AudioBars';
import { PLAYER_TYPES } from '../song/data';
import { firebaseApiHandler } from '../api/firebase/api';

const generateSprite = (microparts: MicropartsMdl, duration: number) => {
  const sprite = {
    __default: [0, duration * 1000, true],
  };
  microparts.forEach(([start, end], index) => {
    const startOffset = start * duration * 1000;
    const partDuration = (end - start) * duration * 1000;
    sprite[`${index.toString()}`] = [startOffset, partDuration, true];
  });
  return sprite;
};

export const useSongAudioPlayback = (
  songId: string,
  streamURL: string,
  microparts: MicropartsMdl,
  duration: number,
  autoplay: boolean,
  autoload: boolean,
  onPlay: () => void,
  onPause: () => void,
  onStop: () => void,
  previousPlayingState: boolean,
  handlePreviousPlayingState: () => void,
  handleLooping: () => void,
  handleGetPlayerType: () => string
) => {
  const [initialized, setInitialized] = useState(false);
  const audioRef = useRef(null);
  const audioHandlerRef = useRef(null);
  const shouldLoop = handleGetPlayerType() !== PLAYER_TYPES.sfx;
  const hasSprite = microparts.length > 0;

  // iOS17以降のブラウザで楽曲の再生がされなくなった。リダイレクトURLが問題と思われる。
  // よってモバイルの場合、Algoliaから取得したURLをこの時点でリダイレクト後のURLに置き換える。
  useEffect(() => {
    const initializeAudio = async () => {
      let songStreamURL = streamURL;
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        songStreamURL = await firebaseApiHandler.getRedirectUrl(streamURL);
      }
      if (audioRef.current === null) {
        if (hasSprite) {
          const sprite = generateSprite(microparts, duration);
          audioRef.current = new Howl({
            html5: true,
            autoplay,
            loop: shouldLoop,
            preload: autoload,
            src: songStreamURL,
            format: ['mp3'],
            sprite,
          });
        } else {
          audioRef.current = new Howl({
            html5: true,
            autoplay,
            loop: shouldLoop,
            preload: autoload,
            src: songStreamURL,
            format: ['mp3'],
          });
        }
      }

      if (audioHandlerRef.current === null) {
        audioHandlerRef.current = new AudioPlaybackHandler(
          audioRef.current,
          onPlay,
          onPause,
          onStop,
          previousPlayingState,
          handlePreviousPlayingState,
          handleLooping,
          handleGetPlayerType
        );
      }

      setInitialized(true);
    };

    initializeAudio();

    return () => {
      if (audioHandlerRef.current) {
        audioHandlerRef.current.cleanup();
      }
      if (audioRef.current) {
        audioRef.current.unload();
      }
    };
  }, []);

  return [audioRef.current, audioHandlerRef.current, initialized];
};
