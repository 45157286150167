// @flow
import React from 'react';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import TabbedSongList from '../../../../../../../layouts/components/TabbedSongList/TabbedSongList';

type Props = {
  results: Array<AlgoliaSongMdl>,
};

const BookmarkSongList = ({ results }: Props) => {
  return (
    <>
      {results.length > 0 && (
        <TabbedSongList
          songs={results}
          songListType="bookmarks"
          songListTitle="bookamrks"
          songListSlug="bookmarks"
          songlistKeywords={[]}
          sectionOrigin="bookmarks"
        />
      )}
    </>
  );
};

export default BookmarkSongList;
