// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type NoResultsPageGraphMdl = {
  data: {
    no_results_header: {
      text: string,
    },
    no_results_message: {
      html: string,
    },
    no_results_filtering_header: {
      text: string,
    },
    no_results_filtering_message: {
      html: string,
    },
    search_tips_header: {
      text: string,
    },
    search_tips_instruction: {
      html: string,
    },
    empty_search_header: {
      text: string,
    },
    empty_search_message: {
      text: string,
    },
    empty_similar_search_header: {
      text: string,
    },
    empty_similar_search_message: {
      text: string,
    },
  },
};

export const getNoResultsHeading = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.no_results_header.text', '') || '';
};

export const getNoResultsMessage = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.no_results_message.html', '') || '';
};

export const getNoResultsFilteringHeading = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.no_results_filtering_header.text', '') || '';
};

export const getNoResultsFilteringMessage = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.no_results_filtering_message.html', '') || '';
};

export const getNoResultsSearchTipsHeading = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.search_tips_header.text', '') || '';
};

export const getNoResultsSearchTipsInstructions = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.search_tips_instruction.html', '') || '';
};

export const getNoResultsSearchEmptySearchHeader = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.empty_search_header.text', '') || '';
};

export const getNoResultsSearchEmptySearchMessage = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.empty_search_message.text', '') || '';
};

export const getNoResultsSimilarSearchEmptySearchHeader = (copy: NoResultsPageGraphMdl): string => {
  return get(copy, 'data.empty_similar_search_header.text', '') || '';
};

export const getNoResultsSimilarSearchEmptySearchMessage = (
  copy: NoResultsPageGraphMdl
): string => {
  return get(copy, 'data.empty_similar_search_message.text', '') || '';
};

export const query = graphql`
  fragment NoResultsPage on PrismicNoResultsPage {
    data {
      no_results_header {
        text
      }
      no_results_message {
        html
      }
      no_results_filtering_header {
        text
      }
      no_results_filtering_message {
        html
      }
      search_tips_header {
        text
      }
      search_tips_instruction {
        html
      }
      empty_search_header {
        text
      }
      empty_search_message {
        text
      }
      empty_similar_search_header {
        text
      }
      empty_similar_search_message {
        text
      }
    }
  }
`;
