// @flow
import React from 'react';
import styled from 'react-emotion';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';

const List = styled('ul')`
  ${SMALL_DEVICE_BREAKPOINT} {
    overflow-x: hidden;
  }

  > li {
    margin-top: 20px;

    ${SMALL_DEVICE_BREAKPOINT} {
      margin-top: 30px;
    }
  }
`;

type Props = {
  children: any,
};

const GroupedTracksList = ({ children }: Props) => (
  <List>
    {React.Children.map(children, child => {
      return <li key={child.key}>{child}</li>;
    })}
  </List>
);

export default GroupedTracksList;
