// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { css, cx } from 'emotion';
import { lightColor } from '../../../../../../../styles/config/colors';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { smallMonoCss } from '../../../../../../../styles/typography/typography';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';

const parentClass = 'songActionButton';

const Button = styled('button')`
  ${buttonResetCss};
  display: block;
  width: 40px;
  height: 40px;
  //border-radius: 50%;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    z-index: 5;
  }
`;

const IconContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: ${transparentize(0.95, lightColor)};
    opacity: 0;
    transform: scale(0.75);
    transition: all 200ms ease-in;

    .${parentClass}:hover &,
    .${parentClass}:active &,
    .${parentClass}:focus & {
      opacity: 1;
      transform: scale(1);
      transition-duration: 250ms;
      transition-timing-function: ease-out;
    }

    .${parentClass}:active & {
      transform: scale(0.85);
      transition-duration: 200ms;
    }
  }
`;

const Label = styled('div')`
  ${smallMonoCss};
  position: absolute;
  top: 100%;
  padding-top: 5px;
  left: -20px;
  right: -20px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease-in;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding-top: 0;
    margin-top: -2px;
  }

  .${parentClass}:hover &,
  .${parentClass}:active &,
  .${parentClass}:focus & {
    pointer-events: unset;
    opacity: 1;
  }
`;

const condensedLabelClass = css`
  padding-top: 1px;
`;

type Props = {
  label: string,
  icon: any,
  condensed?: boolean,
};

const SongActionButton = ({ label, icon, condensed = false, ...props }: Props) => (
  <Button className={parentClass} {...props}>
    <IconContainer>{icon}</IconContainer>
    <Label
      className={cx({
        [condensedLabelClass]: condensed,
      })}
    >
      {label}
    </Label>
  </Button>
);

export default SongActionButton;
