// @flow
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
} from '../../../../../../../store/mixpanelAnalytics';
import TabbedSongList from '../../../../../../../layouts/components/TabbedSongList/TabbedSongList';
import BrowseSongsManager from '../../../../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';

type Props = {
  songs: Array<AlgoliaSongMdl>,
  shareSlug: string,
  songTitle: string,
  allowFullMixes?: boolean,
};

const getLoopStartingIndex = (songs, songIndex) => {
  let startingIndex = 0;

  if (songIndex === 0) {
    return startingIndex;
  }

  for (let i = 0; i < songIndex; i++) {
    startingIndex += songs[i].microparts.length;
  }
  return startingIndex;
};

const PackSongs = ({ songs, shareSlug, songTitle, allowFullMixes = false }: Props) => {
  const directSearchKeywords = useRecoilValue(directSearchKeywordsAtom);
  const directSearchMixpanelKeywords = useRecoilValue(directSearchMixpanelKeywordsAtom);
  const directSearchMagicKeywords = useRecoilValue(directSearchMagicKeywordsAtom);
  const directSearchYouTubeKeywords = useRecoilValue(directSearchYouTubeKeywordsAtom);

  return (
    <>
      {songs.length > 0 && (
        <TabbedSongList
          songs={songs}
          allowFullMixes={allowFullMixes}
          allowSfx={!allowFullMixes}
          allowLoops
          useFullMixName
          songListType="pack"
          songListTitle="Pack"
          songListSlug={shareSlug}
          songlistKeywords={[]}
          keywords={directSearchKeywords}
          mixpanelKeywords={directSearchMixpanelKeywords}
          magicKeywords={directSearchMagicKeywords}
          youtubeKeywords={directSearchYouTubeKeywords}
          sectionOrigin="pack"
        />
      )}
    </>
  );
};

export default PackSongs;
