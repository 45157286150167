// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { getSongID } from '../../../../../../../../api/algolia/song';
import {
  useHideTrack,
  useUnhideTrack,
} from '../../../../../../../../api/firebase/user/hiddenTracks';
import HideIcon from '../../../../../../../../assets/inline-assets/hide-icon.svg';
import HiddenIcon from '../../../../../../../../assets/inline-assets/hidden-icon.svg';
import { useUserHiddenTracks } from '../../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getTrackHideAction, getTrackUnhideAction } from '../../../../../../../../cms/track';
import { getPlayerKeyboardShortcuts } from '../../../../../../../../utils/keyboardShortcuts';
import { useKeyboardShortcutRestricted } from '../../../../../../../../toasts/hooks';

type Props = {
  shortcutEnabled: boolean,
};

const HideTrackInlineButton = ({ shortcutEnabled = false }: Props) => {
  const song = useSong();
  const trackCopy = useTrackCopy();
  const [handleHideTrack] = useHideTrack(song);
  const [handleUnhideTrack] = useUnhideTrack(song);
  const userHiddenSongs = useUserHiddenTracks();
  const { nextSong } = useGlobalPlayerContext();
  const songIsHidden = Object.keys(userHiddenSongs).includes(getSongID(song));
  const restrictedShortcutToast = useKeyboardShortcutRestricted();

  const handleToggleHidden = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (songIsHidden) {
      handleUnhideTrack();
    } else {
      handleHideTrack();
      nextSong();
    }
  };

  const keyboardShortcutHandlers = (() => {
    if (true) {
      return {
        hide_song: handleToggleHidden,
      };
    }
    return {
      hide_song: restrictedShortcutToast,
    };
  })();

  return (
    <>
      {shortcutEnabled && (
        <GlobalHotKeys
          keyMap={getPlayerKeyboardShortcuts('results')}
          handlers={keyboardShortcutHandlers}
          allowChanges
        />
      )}
      <InlineButton
        label={songIsHidden ? getTrackUnhideAction(trackCopy) : getTrackHideAction(trackCopy)}
        icon={songIsHidden ? <HideIcon /> : <HiddenIcon />}
        action={handleToggleHidden}
      />
    </>
  );
};

export default HideTrackInlineButton;
