// @flow
import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { getFirestoreUserRef, getUserProfile } from './user';
import type { AlgoliaSongMdl } from '../../algolia/song';
import { useGenericApiState } from '../../hooks';
import { getSongID } from '../../algolia/song';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import {
  analyticsSongsBookmark,
  analyticsSongsSignUpPrompt,
  useSongAnalyticsDimensions,
} from '../../../analytics/events';
import {
  analyticsMixpanelBookmarkTrack,
  useMixpanelSongAnalyticsDimensions,
} from '../../../analytics/mixpanel';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadContext } from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import type { FBUserMdl } from './user';
import { getProfileBookmarks } from './profile';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { useSubscriptionsContext } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';

export type UserBookmarkMdl = {
  songId: string,
  keywords: Array<string>,
  location: string,
  timestamp: any,
};

export const getBookmarkTimestamp = (bookmark: UserBookmarkMdl): number => {
  if (bookmark.timestamp) {
    return bookmark.timestamp.seconds * 1000;
  }
  const date = new Date();
  return date.getTime() / 1000;
};

export const getBookmarkKeywords = (bookmark: UserBookmarkMdl): Array<string> => {
  const { keywords = [] } = bookmark;
  return keywords;
};

export type UserBookmarks = {
  [string]: UserBookmarkMdl,
};

export const addFirestoreUserBookmark = (
  userId: string,
  songId: string,
  partId: string,
  keywords: Array<string>,
  location: string
) => {
  const userRef = getFirestoreUserRef(userId);

  return userRef.update({
    [`profile.bookmarks.${songId}`]: {
      songId,
      partId,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      keywords,
      location,
    },
  });

  // return userRef.collection('bookmarks').add({
  //   timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //   context,
  //   data: {
  //     songId,
  //     partId,
  //   },
  // });
};

export const removeFirestoreUserBookmark = (userId: string, songId: string): Promise<any> => {
  const userRef = getFirestoreUserRef(userId);

  return userRef.update({
    [`profile.bookmarks.${songId}`]: firebase.firestore.FieldValue.delete(),
  });
};

export const useRemoveBookmark = (song: AlgoliaSongMdl) => {
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  const dimensions = useSongAnalyticsDimensions();
  const showSignUp = useShowSignUp();
  const userId = useUserId();
  const songID = getSongID(song);

  const remove = () => {
    if (busy) return Promise.reject();
    setBusy(true);
    if (!isAuthenticated) {
      analyticsSongsSignUpPrompt('Bookmark', dimensions);
      if (signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Bookmark', signUpCampaign: '' });
      }
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }
    return removeFirestoreUserBookmark(userId, songID).finally(() => {
      setBusy(false);
    });
  };

  return [remove, busy];
};

export const useBookmark = (song: AlgoliaSongMdl) => {
  const { isAuthenticated, signUpSourceData, setSignUpSourceData } = useAuthContext();
  const [busy, setBusy] = useGenericApiState();
  const dimensions = useSongAnalyticsDimensions();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const showSignUp = useShowSignUp();
  const userId = useUserId();
  const songID = getSongID(song);
  const downloadData = useDownloadContext();
  const { userRole } = useSubscriptionsContext();

  const bookmark = () => {
    if (busy) return Promise.reject();
    setBusy(true);
    if (!isAuthenticated) {
      analyticsSongsSignUpPrompt('Bookmark', dimensions);
      if (signUpSourceData.signUpSource !== 'Landing Page') {
        setSignUpSourceData({ signUpSource: 'Bookmark', signUpCampaign: '' });
      }
      showSignUp();
      setBusy(false);
      return Promise.resolve();
    }
    analyticsSongsBookmark(dimensions);

    analyticsMixpanelBookmarkTrack(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadData,
      userRole,
      userId
    );

    const firestoreBookmarkKeywords = downloadData.youtubeVideoTitle
      ? [].concat(downloadData.youtubeVideoTitle).concat(downloadData.mixpanelKeywords)
      : downloadData.mixpanelKeywords;
    return addFirestoreUserBookmark(
      userId,
      songID,
      '',
      firestoreBookmarkKeywords,
      downloadData.location
    ).finally(() => {
      setBusy(false);
    });
  };

  return [bookmark, busy];
};

export const useWatchUserBookmarks = () => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [bookmarks, setBookmarks] = useState({});

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getFirestoreUserRef(userId).onSnapshot(data => {
      if (data.exists) {
        const user: FBUserMdl = data.data();
        const profile = getUserProfile(user);
        const updatedBookmarks = getProfileBookmarks(profile);
        setBookmarks(updatedBookmarks);
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [bookmarks, loaded];
};
