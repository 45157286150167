// @flow
import React, { useState, useEffect } from 'react';
import ls from 'local-storage';
import styled, { css } from 'react-emotion';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { transparentize } from 'polished';
import { darkColor } from '../../../../styles/config/colors';
import { mediumFontWeightCss, smallPlainTextCss } from '../../../../styles/typography/typography';
import Button, { SmallMonoTextButton } from '../../../../components/Button/Button';
import { SMALL_DEVICE_BREAKPOINT, MOBILE_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import {
  getSubscriptionPlanName,
  getSubscriptionsCancellationPendingMessage,
  getSubscriptionsDowngradePendingMessage,
  getSubscriptionsPriceChangePendingMessage,
  getSubscriptionsUndoCancellationButton,
  getSubscriptionsUndoDowngradeButton,
  getPlanPriceInfo,
  getYouTubeConnectionInfo,
  getYouTubeConnectionTooltip,
  getYouTubeConnectionTooltipAction,
  getSubscriptionsFreeTrialBadge,
  getSubscriptionsFreeTrialAllowlistingInfo,
  getSubscriptionsEndTrialPrompt,
  getSubscriptionsFreeTrialCancelPendingMessage,
  getPlanRevenueShare,
  getSubscriptionsYouTubeAllowlistMessage,
} from '../../../../cms/subscriptionsPage';
import {
  useMiscCopy,
  usePricingPlans,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import Account from './components/Account/Account';
import {
  doesSubscriptionHaveYouTubeChannelConnected,
  doesChannelHaveYouTubeChannelConnected,
  getSubscriptionConnectedYouTubeChannelName,
  getSubscriptionCoupons,
  getSubscriptionStatus,
  getSubscriptionValidUntilDate,
  isSubscriptionCancelOrDowngradePending,
  getSubscriptionPlanKey,
  getSubscriptionCurrencyCode,
  getSubscriptionExtraChannels,
  getSubscriptionChannelId,
  getSeatInterval,
  getSubscriptionTrialValidUntilDate,
  getYoutubeIdFromSubscription,
  getYouTubeChannelConnection,
  getChannelYouTubeId,
} from '../../../../api/firebase/user/subscriptions';
import { ICONS } from '../../../../images/paths';
import {
  getMiscCopyCancelButton,
  getMiscCopyDowngradeButton,
  getMiscCopyUpgradeButton,
  getMiscCopyYouTubeChannel,
  getMiscCopyAnnuallyInterval,
  getMiscCopyBiannuallyInterval,
  getMiscCopyQuarterlyInterval,
  getMiscCopyMonthlyInterval,
  getMiscCopyPaidLabel,
} from '../../../../cms/miscCopy';
import { useYouTubeAPIHandler } from '../YouTubeAPIHandler/YouTubeAPIHandler';
import {
  useSubscriptionsContext,
  isSubscriptionFreeTrial,
} from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTION_STATUSES } from '../../../../api/firebase/user/user';
import SpinnerIcon from '../../../../components/SpinnerIcon/SpinnerIcon';
import { getPricingPlan, getPricingPlanName } from '../../../../cms/pricingPlan';
import {
  SUBSCRIPTIONS,
  CURRENCY_SYMBOLS,
  CURRENCY_CODES,
  REVENUE_SHARE_BREAKDOWN,
} from '../../data';
import FooterCoupon from './components/FooterCoupon/FooterCoupon';
import LoadingModal from '../../../../components/LoadingModal/LoadingModal';
import YouTubeChannelSearch from './components/YouTubeChannelSearch/YouTubeChannelSearch';
import ExtraYouTubeAccounts from './components/ExtraYouTubeAccounts/ExtraYouTubeAccounts';
import { parseAllowlistingDate } from '../../../../components/SiteHeader/components/TrialCounterMenu/TrialCounterMenu';
import { getDaysUntilDate } from '../../../../utils/date';
import { analyticsMixpanelAccountEndTrialClick } from '../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { ACTIONS } from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import { ALLOWLIST_STATUSES } from '../../../../api/firebase/user/allowlist';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../../../../utils/subscriptions';

const Container = styled('div')`
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 3px;
  padding: 15px 20px 10px;

  &:hover {
    box-shadow: 0 5px 15px 0 rgba(255, 255, 255, 0.1);
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
`;

const DesktopDevicePlanInfo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const PlanName = styled('div')`
  ${smallPlainTextCss};
  ${mediumFontWeightCss};
  letter-spacing: 0.75px;
  flex: 1;
  margin-right: 10px;
`;

const FreeTrialBadge = styled('span')`
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: rgba(15, 15, 20, 0.05);
  text-transform: uppercase;
  padding: 5px 7px;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1.33px;
  line-height: 10px;
`;

const MobileDevicePlanInfo = styled('div')`
  display: none;

  ${MOBILE_DEVICE_BREAKPOINT} {
    ${smallPlainTextCss};
    ${mediumFontWeightCss};
    display: flex;
    flex-direction: column;
    letter-spacing: 0.75px;
    flex: 1;
    margin-right: 10px;
  }
`;

const MobileBadgeWrapper = styled('div')`
  margin-left: 2px;
`;

const MobileDevicePlanActionsBar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MobileDevicePlanName = styled('div')`
  margin: 10px 0px 0px 8px;
`;

const MobileDevicePlanPrice = styled('div')`
  opacity: 0.6;
  font-weight: 400;
  padding-top: 2px;
  padding-left: 1px;
  margin-left: 8px;
`;

const ActionButton = styled(SmallMonoTextButton)``;

const Accounts = styled('ul')`
  margin-top: 12px;

  & li {
    position: relative;
  }
`;

const TooltipContainer = styled('div')`
  @keyframes tooltipAppear {
    from {
      top: -80px;
      opacity: 0;
    }
    to {
      top: -95px;
      opacity: 1;
    }
  }

  opacity: 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: #fff;
  width: 320px;
  height: 80px;
  border-radius: 3px;
  padding: 10px 20px;
  position: absolute;
  top: -95px;
  left: 140px;
  animation: tooltipAppear 0.5s 0.75s 1;
  animation-fill-mode: forwards;

  & p {
    font-weight: 500;
    line-height: 20px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 290px;
    top: -90px;
    left: 5px;
  }
`;

const TooltipAction = styled(SmallMonoTextButton)`
  text-align: right;
`;

const TooltipPointer = styled('div')`
  height: 15px;
  width: 15px;
  background-color: #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 67px;
  left: 255px;
`;

const Options = styled('div')`
  display: flex;
  align-items: center;

  > button {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const FooterMessage = styled('footer')`
  ${smallPlainTextCss};
  margin-top: 2px;
  padding-top: 17px;
  margin-bottom: 2px;
  color: ${transparentize(0.5, darkColor)};
`;

const FreeFooterMessage = styled('div')`
  ${smallPlainTextCss};
  text-align: center;
  dont-size: 10px;
  color: ${transparentize(0.5, darkColor)};
  margin-top: 10px;
  margin-bottom: 0px;
`;

const YouTubeFootermessage = styled('div')`
  ${smallPlainTextCss};
  margin-top: 17px;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;

  span {
    position: absolute;
    left: 10px;
  }
`;

const FreeTrialAllowlistingFooter = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 17px;
  ${smallPlainTextCss};
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;

  & > span {
    position: absolute;
    left: 10px;
  }

  p {
    padding-left: 30px;
    width: 300px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column;

    p {
      width: 100%;
      padding-left: 20px;
    }

    & > span {
      left: 0px;
    }
  }
`;

const TrialPromptWrapper = styled('div')`
  width: 166px;

  button {
    padding: 7px 0px;
    min-width: 166px;
    font-size: 10px;
    letter-spacing: 1.67px;
    line-height: 15px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    button {
      width: 240px;
      margin: 14px 0px 10px 0px;
    }
  }
`;

const spinnerClass = css`
  position: static;
`;

const parsePlanName = (name: string, number: number): string => {
  return name.replace('$NUMBER$', number.toString());
};

const getLocalisedSubscriptionCost = (costInCents: number, currency: string): string => {
  const miscCopy = useMiscCopy();
  const planCurrencySymbol = CURRENCY_SYMBOLS[currency];

  let localisedPrice = 0;

  if (currency === CURRENCY_CODES.en) {
    localisedPrice = costInCents / 100;
  } else if (currency === CURRENCY_CODES.ja) {
    localisedPrice = costInCents;
  }

  const formattedLocalisedPrice = localisedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return localisedPrice > 0
    ? `${planCurrencySymbol}${formattedLocalisedPrice}`
    : getMiscCopyPaidLabel(miscCopy);
};

const getPlanIntervalCopy = (interval: string): string => {
  const miscCopy = useMiscCopy();

  if (interval === 'annual') {
    return getMiscCopyAnnuallyInterval(miscCopy);
  }
  if (interval === 'biannual') {
    return getMiscCopyBiannuallyInterval(miscCopy);
  }
  if (interval === 'quarterly') {
    return getMiscCopyQuarterlyInterval(miscCopy);
  }
  return getMiscCopyMonthlyInterval(miscCopy);
};

const getPlanPricingInfo = (
  planPrice: number,
  subscription: UserSubscriptionMdl,
  coupons
): string | typeof undefined => {
  const plan = getSubscriptionPlanKey(subscription);
  const subscriptionPage = useSubscriptionsPage();

  if (SUBSCRIPTIONS.creator.plan_codes.includes(plan)) {
    return getPlanRevenueShare(subscriptionPage).replace(
      '$SHARE_PERCENTAGE$',
      REVENUE_SHARE_BREAKDOWN[plan]
    );
  }

  const planCurrency = getSubscriptionCurrencyCode(subscription);
  const localisedPrice = getLocalisedSubscriptionCost(planPrice, planCurrency);
  const interval = getSeatInterval(subscription);
  const planInterval = getPlanIntervalCopy(interval);

  const priceInfo = getPlanPriceInfo(subscriptionPage)
    .replace('$DISCOUNTEDTO$', '')
    .replace('$PRICE$', localisedPrice)
    .replace('$INTERVAL$', planInterval);

  return priceInfo;
};

const setPlanInformation = (
  planCopy,
  planNumber: number,
  planPrice: number,
  subscription: UserSubscriptionMdl,
  coupons
): string => {
  const plan = getSubscriptionPlanKey(subscription);
  const planLabel = parsePlanName(getSubscriptionPlanName(planCopy), planNumber);
  const priceInfo = getPlanPricingInfo(planPrice, subscription, coupons);
  const planStatus = getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial ? '*' : '';

  const planInfo = SUBSCRIPTIONS.creator.plan_codes.includes(plan)
    ? `${planLabel} ${priceInfo}`
    : `${planLabel}${planStatus} \xa0 ${priceInfo}`;
  return planInfo;
};

type Props = {
  planNumber: number,
  subscription: UserSubscriptionMdl,
  monthlyTotalCents: number,
};

const AddedSubscription = ({ planNumber, subscription, monthlyTotalCents }: Props) => {
  const subscriptionPage = useSubscriptionsPage();
  const miscCopy = useMiscCopy();
  const { openYouTubeChannelModal } = useYouTubeAPIHandler();
  const {
    removeYouTubeChannelFromSubscription,
    removeExtraYouTubeChannelFromSubscription,
    openCancelSubscriptionModal,
    removeScheduledChanges,
    openChangeSubscriptionModal,
    setSelectedPlanNumber,
    subscriptions,
    isAtLeastOneSocialAccountConnected,
    showFreeTrialChangeModal,
    upgradeSubscription,
    fetchChannelAllowlist,
  } = useSubscriptionsContext();

  const [busy, setBusy] = useState(false);
  const [youTubeBusy, setYouTubeBusy] = useState(false);
  const [primaryYouTubeBusy, setPrimaryYouTubeBusy] = useState(false);
  const [tooltipDisplay, setTooltipDisplay] = useState(false);
  const [freeTrialYTTooltipDisplay, setFreeTrialYTTooltipDisplay] = useState(false);

  const [youtubeChannelConnected, setYouTubeChannelConnected] = useState(false);
  const [allChannelsAllowlist, setAllChannelsAllowlist] = useState({
    mainChannelAllowlist: {},
    extraChannelsAllowlist: {},
  });
  const pricingPlans = usePricingPlans();
  const creatorPlan = getPricingPlan(pricingPlans, SUBSCRIPTIONS.creator.key);
  const creatorProPlan = getPricingPlan(pricingPlans, SUBSCRIPTIONS.creatorPro.key);

  const KEY_INITIAL_SUBSCRIPTIONS_COUNT = 'INITIAL_SUBSCRIPTIONS_COUNT';
  const initialSubscriptionCount = ls.get(KEY_INITIAL_SUBSCRIPTIONS_COUNT);
  const youTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
  const youTubeName = youTubeConnected
    ? getSubscriptionConnectedYouTubeChannelName(subscription)
    : getMiscCopyYouTubeChannel(miscCopy);
  const isFreeTrialSubscription = isSubscriptionFreeTrial(subscription);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const subscriptionExtraChannels = getSubscriptionExtraChannels(subscription);

  const onConnectYouTube = () => {
    openYouTubeChannelModal(
      subscription.key,
      subscription.seat.plan,
      null,
      true,
      subscriptionExtraChannels
    );
  };

  const onDisconnectYouTube = event => {
    if (youTubeBusy) return;
    setYouTubeBusy(true);
    setPrimaryYouTubeBusy(true);
    removeYouTubeChannelFromSubscription(
      subscription.key,
      subscription.seat.plan,
      subscriptionExtraChannels
    ).finally(() => {
      setPrimaryYouTubeBusy(false);
      setYouTubeBusy(false);
    });
    event.stopPropagation();
  };

  const onDisconnectExtraYouTube = (
    extraChannels: any,
    subscriptionKey: string,
    youtubeSubscriptionId: string
  ) => {
    if (youTubeBusy) return;
    setYouTubeBusy(true);
    removeExtraYouTubeChannelFromSubscription(
      extraChannels,
      subscriptionKey,
      youtubeSubscriptionId
    ).finally(() => {
      setYouTubeBusy(false);
    });

    event.stopPropagation();
  };

  const handleUndo = () => {
    if (busy) return;
    setBusy(true);
    removeScheduledChanges(subscription.key).finally(() => {
      setBusy(false);
    });
  };

  const handleChange = () => {
    setSelectedPlanNumber(planNumber);
    if (subscriptionExtraChannels.length > 0) {
      openChangeSubscriptionModal(subscription.key, subscriptionExtraChannels);
    } else {
      openChangeSubscriptionModal(subscription.key);
    }
  };

  const handleCancel = () => {
    if (isFreeTrialSubscription) {
      showFreeTrialChangeModal(subscription.key, '', ACTIONS.cancel);
    } else {
      openCancelSubscriptionModal(subscription.key);
    }
  };

  const handleToolTipClose = () => {
    setTooltipDisplay(false);
  };

  const handleUpgradeFreeTrialSubscription = () => {
    // It looks like we are selecting the same subscription here, but the back end will be in charge of serving the paid version of the free trial plan
    if (busy) return;
    setBusy(true);

    const subStatus = getSubscriptionStatus(subscription);
    const subscriptionPlanKey = getSubscriptionPlanKey(subscription);

    // If the trial has been scheduled for cancellation, the upgrade cannot happen until the cancellation is undone.
    if (subStatus === SUBSCRIPTION_STATUSES.trial_cancelled) {
      showFreeTrialChangeModal(
        subscription.key,
        subscriptionPlanKey,
        ACTIONS.upgrade_cancelled_trial
      );
      setBusy(false);
      return;
    }

    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);
    const daysRemainingOnTrial = getDaysUntilDate(
      get(subscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000
    );
    analyticsMixpanelAccountEndTrialClick(
      mixpanel,
      moengage,
      subscriptionPlanKey,
      isYouTubeConnected,
      daysRemainingOnTrial
    );

    upgradeSubscription(
      getSubscriptionChannelId(subscription),
      getSubscriptionPlanKey(subscription),
      getSeatInterval(subscription)
    )
      .then(() => {
        setBusy(false);
      })
      .catch(error => {
        Sentry.captureMessage(
          'Something went wrong when converting subscription from free trial to paid subscripton'
        );
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  useEffect(() => {
    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(subscription);

    if (isYouTubeConnected) {
      setYouTubeChannelConnected(true);
    } else {
      setYouTubeChannelConnected(false);
    }
  }, [subscriptions]);

  useEffect(() => {
    const youtubeChannelExists = isAtLeastOneSocialAccountConnected();
    if (planNumber === 1 && !youtubeChannelExists && subscriptions.length === 1) {
      if (!initialSubscriptionCount) {
        setTooltipDisplay(true);
      } else {
        setTooltipDisplay(false);
      }
    } else {
      setTooltipDisplay(false);
    }
  }, [subscriptions]);

  useEffect(() => {
    const primaryChannelId = getSubscriptionChannelId(subscription);
    const mainChannelYouTubeId = getYoutubeIdFromSubscription(subscription);
    const extraChannelYouTubeIDs = subscriptionExtraChannels
      .filter(extraChannel => {
        return doesChannelHaveYouTubeChannelConnected(extraChannel);
      })
      .map(channelWithYouTube => {
        return getChannelYouTubeId(getYouTubeChannelConnection(channelWithYouTube));
      });

    if (
      primaryChannelId &&
      mainChannelYouTubeId &&
      !isCreatorSubscription(getSubscriptionPlanKey(subscription))
    ) {
      fetchChannelAllowlist(mainChannelYouTubeId, extraChannelYouTubeIDs).then(data => {
        setAllChannelsAllowlist(data);
      });
    } else {
      setAllChannelsAllowlist({ mainChannelAllowlist: {}, extraChannelsAllowlist: {} });
    }
  }, [subscriptions]);

  const actionOptions = (() => {
    if (busy) {
      return (
        <div>
          <SpinnerIcon inverse containerClassName={spinnerClass} />
        </div>
      );
    }
    const subscriptionStatus = getSubscriptionStatus(subscription);

    if (subscription && subscriptionStatus) {
      if (
        subscriptionStatus === SUBSCRIPTION_STATUSES.cancel_pending ||
        subscriptionStatus === SUBSCRIPTION_STATUSES.trial_cancelled
      ) {
        return (
          <Options>
            <ActionButton onClick={handleUndo}>
              {getSubscriptionsUndoCancellationButton(subscriptionPage)}
            </ActionButton>
          </Options>
        );
      }

      if (
        subscriptionStatus === SUBSCRIPTION_STATUSES.downgrade_to_free_pending ||
        subscriptionStatus === SUBSCRIPTION_STATUSES.change_pending
      ) {
        return (
          <Options>
            <ActionButton onClick={handleUndo}>
              {getSubscriptionsUndoDowngradeButton(subscriptionPage)}
            </ActionButton>
          </Options>
        );
      }
    }

    if (
      SUBSCRIPTIONS.creator.plan_codes.includes(subscription.seat.plan) ||
      SUBSCRIPTIONS.creatorPro.plan_codes.includes(subscription.seat.plan)
    ) {
      return (
        <Options>
          <ActionButton onClick={handleChange}>{getMiscCopyUpgradeButton(miscCopy)}</ActionButton>
          <ActionButton onClick={handleCancel}>{getMiscCopyCancelButton(miscCopy)}</ActionButton>
        </Options>
      );
    }

    if (SUBSCRIPTIONS.business.plan_codes.includes(subscription.seat.plan)) {
      return (
        <Options>
          <ActionButton onClick={handleChange}>{getMiscCopyDowngradeButton(miscCopy)}</ActionButton>
          <ActionButton onClick={handleCancel}>{getMiscCopyCancelButton(miscCopy)}</ActionButton>
        </Options>
      );
    }

    return (
      <Options>
        <ActionButton onClick={handleCancel}>{getMiscCopyCancelButton(miscCopy)}</ActionButton>
      </Options>
    );
  })();

  const coupons = getSubscriptionCoupons(subscription);

  const hasCoupons = coupons && coupons.length > 0;

  const footerMessage = (() => {
    let message;

    const subscriptionStatus = getSubscriptionStatus(subscription);

    if (subscriptionStatus) {
      if (subscriptionStatus === SUBSCRIPTION_STATUSES.cancel_pending) {
        message = getSubscriptionsCancellationPendingMessage(subscriptionPage).replace(
          '$DATE$',
          `<strong>${getSubscriptionValidUntilDate(subscription)}</strong>`
        );
      } else if (subscriptionStatus === SUBSCRIPTION_STATUSES.change_pending) {
        message = getSubscriptionsDowngradePendingMessage(subscriptionPage)
          .replace('$PLAN$', getPricingPlanName(creatorProPlan))
          .replace('$DATE$', `<strong>${getSubscriptionValidUntilDate(subscription)}</strong>`);
      } else if (subscriptionStatus === SUBSCRIPTION_STATUSES.change_pending_price) {
        message = getSubscriptionsPriceChangePendingMessage(subscriptionPage).replace(
          '$DATE$',
          `${getSubscriptionValidUntilDate(subscription)}`
        );
      } else if (subscriptionStatus === SUBSCRIPTION_STATUSES.downgrade_to_free_pending) {
        message = getSubscriptionsDowngradePendingMessage(subscriptionPage)
          .replace('$PLAN$', getPricingPlanName(creatorPlan))
          .replace('$DATE$', `<strong>${getSubscriptionValidUntilDate(subscription)}</strong>`);
      } else if (subscriptionStatus === SUBSCRIPTION_STATUSES.trial_cancelled) {
        message = getSubscriptionsFreeTrialCancelPendingMessage(subscriptionPage).replace(
          '$DATE$',
          `<strong>${getSubscriptionTrialValidUntilDate(subscription)}</strong>`
        );
      }
    }

    if (
      SUBSCRIPTIONS.creator.plan_codes.includes(subscription.seat.plan) &&
      !youtubeChannelConnected
    ) {
      return (
        <FreeFooterMessage>{parse(getYouTubeConnectionInfo(subscriptionPage))}</FreeFooterMessage>
      );
    }

    if (message) {
      return (
        <FooterMessage>
          {parse(message)}
          {hasCoupons && (
            <FooterCoupon
              cancelOrDowngradePending={isSubscriptionCancelOrDowngradePending(subscription)}
              coupons={coupons}
            />
          )}
        </FooterMessage>
      );
    }

    return null;
  })();

  const tooltip = (() => {
    return (
      <TooltipContainer>
        {parse(getYouTubeConnectionTooltip(subscriptionPage))}
        <TooltipAction onClick={handleToolTipClose}>
          {parse(getYouTubeConnectionTooltipAction(subscriptionPage))}
        </TooltipAction>
        <TooltipPointer>&nbsp;</TooltipPointer>
      </TooltipContainer>
    );
  })();

  const planKey = getSubscriptionPlanKey(subscription);
  const addYouTubeChannel = (() => {
    if (isBusinessSubscription(planKey) || isEnterpriseSubscription(planKey)) {
      return youTubeConnected ? (
        <>
          <li>
            <Account
              name={youTubeName}
              icon={ICONS.youtubeChannel}
              connected={youTubeConnected}
              onConnect={onConnectYouTube}
              onDisconnect={onDisconnectYouTube}
              busy={youTubeBusy}
              primaryYouTubeBusy={primaryYouTubeBusy}
              tooltipDisplay={tooltipDisplay}
              allowlistData={allChannelsAllowlist.mainChannelAllowlist}
              showAllowlist={!isCreatorSubscription(getSubscriptionPlanKey(subscription))}
              onTrial={isFreeTrialSubscription}
            />
          </li>
          {subscriptionExtraChannels.length > 0 ? (
            <ExtraYouTubeAccounts
              channels={subscriptionExtraChannels}
              icon={ICONS.youtubeChannel}
              onDisconnect={onDisconnectExtraYouTube}
              subscriptionKey={subscription.key}
              extraAllowlistData={allChannelsAllowlist.extraChannelsAllowlist}
              onTrial={isFreeTrialSubscription}
            />
          ) : (
            ''
          )}
          {
            <li>
              <YouTubeChannelSearch
                busy={youTubeBusy}
                icon={ICONS.youtubeChannel}
                subscriptionKey={subscription.key}
                planKey={subscription.seat.plan}
                extraChannels={subscriptionExtraChannels}
                isPrimaryConnection={false}
                setFreeTrialYTTooltipDisplay={setFreeTrialYTTooltipDisplay}
              />
            </li>
          }
        </>
      ) : (
        <li>
          {tooltipDisplay ? tooltip : ''}
          <YouTubeChannelSearch
            busy={youTubeBusy}
            icon={ICONS.youtubeChannel}
            subscriptionKey={subscription.key}
            planKey={subscription.seat.plan}
            extraChannels={subscriptionExtraChannels}
            isPrimaryConnection
            setFreeTrialYTTooltipDisplay={setFreeTrialYTTooltipDisplay}
          />
        </li>
      );
    }
    if (isCreatorProSubscription(planKey)) {
      return youTubeConnected ? (
        <li>
          <Account
            name={youTubeName}
            icon={ICONS.youtubeChannel}
            connected={youTubeConnected}
            onDisconnect={onDisconnectYouTube}
            busy={youTubeBusy}
            primaryYouTubeBusy={primaryYouTubeBusy}
            tooltipDisplay={tooltipDisplay}
            allowlistData={allChannelsAllowlist.mainChannelAllowlist}
            showAllowlist={!isCreatorSubscription(getSubscriptionPlanKey(subscription))}
            onTrial={isFreeTrialSubscription}
          />
        </li>
      ) : (
        <li>
          {tooltipDisplay ? tooltip : ''}
          <YouTubeChannelSearch
            busy={youTubeBusy}
            icon={ICONS.youtubeChannel}
            subscriptionKey={subscription.key}
            planKey={subscription.seat.plan}
            extraChannels={subscriptionExtraChannels}
            isPrimaryConnection
            setFreeTrialYTTooltipDisplay={setFreeTrialYTTooltipDisplay}
          />
        </li>
      );
    }
    return (
      <li>
        {tooltipDisplay ? tooltip : ''}
        {youTubeConnected ? (
          <Account
            name={youTubeName}
            icon={ICONS.youtubeChannel}
            connected={youTubeConnected}
            onConnect={onConnectYouTube}
            onDisconnect={onDisconnectYouTube}
            busy={youTubeBusy}
            primaryYouTubeBusy={primaryYouTubeBusy}
            tooltipDisplay={tooltipDisplay}
            isFreeTrialSubscription={isFreeTrialSubscription}
            allowlistData={allChannelsAllowlist.mainChannelAllowlist}
            showAllowlist={!isCreatorSubscription(getSubscriptionPlanKey(subscription))}
            onTrial={isFreeTrialSubscription}
          />
        ) : (
          <YouTubeChannelSearch
            busy={youTubeBusy}
            icon={ICONS.youtubeChannel}
            subscriptionKey={subscription.key}
            planKey={subscription.seat.plan}
            extraChannels={subscriptionExtraChannels}
            isPrimaryConnection
            setFreeTrialYTTooltipDisplay={setFreeTrialYTTooltipDisplay}
          />
        )}
      </li>
    );
  })();

  const mainChannelIsPending =
    allChannelsAllowlist.mainChannelAllowlist.currentState === ALLOWLIST_STATUSES.pending;
  const extraChannelsPending = Object.keys(allChannelsAllowlist.extraChannelsAllowlist)
    .map(extraChannelId => {
      return allChannelsAllowlist.extraChannelsAllowlist[extraChannelId];
    })
    .filter(extraAllowlistChannel => {
      return extraAllowlistChannel.currentState === ALLOWLIST_STATUSES.pending;
    });

  return (
    <React.Fragment>
      <Container>
        <Header>
          <DesktopDevicePlanInfo>
            <PlanName>
              {!SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription)) &&
                isFreeTrialSubscription && (
                  <FreeTrialBadge>
                    {getSubscriptionsFreeTrialBadge(subscriptionPage)}
                  </FreeTrialBadge>
                )}
              {setPlanInformation(
                subscriptionPage,
                planNumber,
                monthlyTotalCents,
                subscription,
                coupons
              )}
            </PlanName>
            {actionOptions}
          </DesktopDevicePlanInfo>
          <MobileDevicePlanInfo>
            {!SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription)) &&
              isFreeTrialSubscription && (
                <MobileBadgeWrapper>
                  <FreeTrialBadge>
                    {getSubscriptionsFreeTrialBadge(subscriptionPage)}
                  </FreeTrialBadge>
                </MobileBadgeWrapper>
              )}
            <MobileDevicePlanActionsBar>
              <MobileDevicePlanName>
                {parsePlanName(getSubscriptionPlanName(subscriptionPage), planNumber)}
              </MobileDevicePlanName>
              {actionOptions}
            </MobileDevicePlanActionsBar>
            <MobileDevicePlanPrice>
              {getPlanPricingInfo(monthlyTotalCents, subscription, coupons)}
            </MobileDevicePlanPrice>
          </MobileDevicePlanInfo>
        </Header>
        {!isEnterpriseBasicSubscription(planKey) && <Accounts>{addYouTubeChannel}</Accounts>}
        {footerMessage}
      </Container>
      {!isFreeTrialSubscription &&
        !SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription)) &&
        youTubeConnected &&
        (mainChannelIsPending || extraChannelsPending.length > 0) && (
          <YouTubeFootermessage>
            <span>*</span>
            {getSubscriptionsYouTubeAllowlistMessage(subscriptionPage)}
          </YouTubeFootermessage>
        )}
      {!SUBSCRIPTIONS.creator.plan_codes.includes(getSubscriptionPlanKey(subscription)) &&
        isFreeTrialSubscription &&
        getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial &&
        !isEnterpriseBasicSubscription(planKey) && (
          <FreeTrialAllowlistingFooter>
            <span>*</span>
            <p>
              {parseAllowlistingDate(
                getSubscriptionsFreeTrialAllowlistingInfo(subscriptionPage),
                getSubscriptionTrialValidUntilDate(subscription)
              )}
            </p>
            <TrialPromptWrapper>
              <Button
                onClick={() => {
                  handleUpgradeFreeTrialSubscription();
                }}
              >
                {getSubscriptionsEndTrialPrompt(subscriptionPage)}
              </Button>
            </TrialPromptWrapper>
          </FreeTrialAllowlistingFooter>
        )}
      {(busy || youTubeBusy) && <LoadingModal />}
    </React.Fragment>
  );
};

export default AddedSubscription;
