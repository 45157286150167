// @flow
import { get } from 'lodash';

export type PaymentSourceMdl = {
  brand: string,
  isPrimary: boolean,
  last4: string,
  type: 'card' | 'other',
};

export const getPaymentSourceType = (source: PaymentSourceMdl): string => {
  return get(source, 'type');
};

export const getPaymentSourceLast4 = (source: PaymentSourceMdl): string => {
  return get(source, 'last4');
};

export type InvoiceMdl = {
  amount: number,
  currency: string,
  dueDate: number,
  id: string,
  isPaid: boolean,
  paidDate: number,
  isFirstInvoiceForSubscription?: boolean,
  type?: string,
  receiptUrl: string,
};

export const getInvoiceType = (invoice: InvoiceMdl): string => {
  return get(invoice, 'type', 'subscriptions') || 'subscriptions';
};

export const getInvoiceReceiptUrl = (invoice: InvoiceMdl): string => {
  return get(invoice, 'receiptUrl', '') || '';
};

export type InvoicesResponse = Array<InvoiceMdl>;

export type InvoiceDownloadMdl = {
  download_url: string,
  valid_till: number,
  object: string,
};

export const openInvoiceDownload = (download: InvoiceDownloadMdl) => {
  const url = get(download, 'download_url', '');
  if (url) {
    window.open(url, '_blank');
  }
};
