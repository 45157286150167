// @flow

/**
 * Sort array of objects based on another array
 */

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
};
