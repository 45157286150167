// @flow
import React, { useEffect, useState } from 'react';
import LayoutWrapper from '../../../components/LayoutWrapper/LayoutWrapper';
import EmbedSongView from './components/EmbedSongView/EmbedSongView';
import DownloadContextWrapper from '../../../components/DownloadContextWrapper/DownloadContextWrapper';
import AudioPlayerWrapper from '../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { useLocale } from '../../components/LocaleWrapper/LocaleWrapper';
import LoadingDisplay, {
  LoadingContainer,
} from '../../../components/LoadingDisplay/LoadingDisplay';
import { getSongShareSlug } from '../../../api/algolia/song';
import { fetchDirectSongAndFamily } from '../../../api/algolia/direct';
import EmbedSongViewWrapper from './components/EmbedSongViewWrapper/EmbedSongViewWrapper';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';

type Props = {
  shareSlug: string,
};

const Content = ({ shareSlug }: Props) => {
  const locale = useLocale();
  const [songs, setSongs] = useState([]);
  const [state, setState] = useState({
    loading: false,
    loaded: false,
  });
  const [initialSelectedIndex, setInitialSelectedIndex] = useState(0);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    fetchDirectSongAndFamily(locale, shareSlug).then(response => {
      setSongs(response);
      const songIndex = response.findIndex((song: AlgoliaSongMdl) => {
        return getSongShareSlug(song) === shareSlug;
      });
      setInitialSelectedIndex(songIndex);
      setState({
        ...state,
        loaded: true,
      });
    });
  }, [shareSlug]);

  const { loading } = state;

  return (
    <DownloadContextWrapper location="direct">
      <AudioPlayerWrapper>
        <LayoutWrapper header="" footer="">
          {loading ? (
            <LoadingContainer>
              <LoadingDisplay />
            </LoadingContainer>
          ) : (
            <EmbedSongViewWrapper>
              <EmbedSongView initialSelectedIndex={initialSelectedIndex} songs={songs} />
            </EmbedSongViewWrapper>
          )}
        </LayoutWrapper>
      </AudioPlayerWrapper>
    </DownloadContextWrapper>
  );
};

const EmbedScreen = (props: Props) => <Content {...props} />;

export default EmbedScreen;
