// @flow
import { RESULTS_SORTING_METHODS } from '../../routing/screens/ResultsScreen/components/ResultsView/components/data';

export const SUBSCRIPTIONS = {
  creator: {
    key: 'free2',
    plan_codes: ['free1', 'free2'],
  },
  creatorPro: {
    key: 'pro1',
    plan_codes: ['pro1'],
  },
  business: {
    key: 'business1',
    plan_codes: ['business1'],
  },
  enterprise: {
    key: 'enterprise1',
    plan_codes: ['enterprise1'],
  },
  enterpriseBasic: {
    key: 'enterprise2_basic',
    plan_codes: ['enterprise2_basic'],
  },
};

export const getPlanCodeName = (planCode: string) => {
  return planCode.replace(/[0-9]/g, '');
};

type BillingCycleInterface = {
  [string]: {
    available: boolean,
    cycle: string,
    label: string,
  },
};

export const BILLING_CYCLES: BillingCycleInterface = {
  monthly: {
    available: true,
    cycle: 'monthly',
    label: 'monthly',
  },
  quarterly: {
    available: false,
    cycle: 'quarterly',
    label: 'quarterly',
  },
  biannually: {
    available: false,
    cycle: 'biannual',
    label: 'biannually',
  },
  annually: {
    available: true,
    cycle: 'annual',
    label: 'annually',
  },
};

export const getAvailableBillingCycles = (
  availableIntervals: Array<string> = []
): BillingCycleInterface => {
  let availableBillingCycles = {};

  const newBillingCycles = Object.keys(BILLING_CYCLES).filter(intervalKey => {
    return availableIntervals.includes(intervalKey);
  });

  newBillingCycles.forEach(billingCycle => {
    if (BILLING_CYCLES[billingCycle].available) {
      availableBillingCycles = {
        ...availableBillingCycles,
        [billingCycle]: BILLING_CYCLES[billingCycle],
      };
    }
  });

  return availableBillingCycles;
};

export const INTERVAL_MONTHLY_DURATION = {
  monthly: 1,
  quarterly: 3,
  biannual: 6,
  annual: 12,
};

export const USER_ROLES = {
  guest: 'guestUser',
  noSubscriptions: 'noSubscriptionsUser',
  creator: 'creatorUser',
  creatorPro: 'creatorProUser',
  business: 'businessUser',
  enterprise: 'enterpriseUser',
  enterpriseBasic: 'enterpriseUser',
};

export const USER_PERMISSIONS = {
  guestUser: {
    canFilterResults: true,
    canDownloadSongs: false,
    canDownloadAllTracks: true,
    canDownloadMicroparts: true,
    canDownloadSfx: false,
    canDownloadMIDI: false,
    limitedDownloads: null,
    canBookmarkSongs: false,
    initialFilterPermissions: null,
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
    ],
    sortingPermissions: [RESULTS_SORTING_METHODS.newest, RESULTS_SORTING_METHODS.oldest],
    similarSearch: false,
  },
  // This is the guest user type for free downloading without a subscription
  noSubscriptionsUser: {
    canFilterResults: false,
    canDownloadSongs: false,
    canDownloadAllTracks: true,
    canDownloadMicroparts: true,
    canDownloadSfx: false,
    canDownloadMIDI: false,
    limitedDownloads: true,
    canBookmarkSongs: false,
    initialFilterPermissions: null,
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
    ],
    sortingPermissions: [RESULTS_SORTING_METHODS.newest, RESULTS_SORTING_METHODS.oldest],
    similarSearch: false,
  },
  creatorUser: {
    canFilterResults: false,
    canDownloadSongs: true,
    canDownloadAllTracks: true,
    canDownloadMicroparts: false,
    canDownloadSfx: true,
    canDownloadMIDI: false,
    limitedDownloads: true,
    canBookmarkSongs: true,
    initialFilterPermissions: null,
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
    ],
    sortingPermissions: [RESULTS_SORTING_METHODS.newest, RESULTS_SORTING_METHODS.oldest],
    similarSearch: false,
  },
  creatorProUser: {
    canFilterResults: true,
    canDownloadSongs: true,
    canDownloadAllTracks: true,
    canDownloadMicroparts: true,
    canDownloadSfx: true,
    canDownloadMIDI: false,
    limitedDownloads: false,
    canBookmarkSongs: true,
    initialFilterPermissions: {
      isPart: 'false',
      mixType: { excluded: [], included: ['Full Mix'] },
    },
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
      'isPart',
      'mixType',
    ],
    sortingPermissions: [
      RESULTS_SORTING_METHODS.newest,
      RESULTS_SORTING_METHODS.oldest,
      RESULTS_SORTING_METHODS.popularity,
      RESULTS_SORTING_METHODS.popularity_ascending,
    ],
    similarSearch: true,
  },
  businessUser: {
    canFilterResults: true,
    canDownloadSongs: true,
    canDownloadAllTracks: true,
    canDownloadMicroparts: true,
    canDownloadSfx: true,
    canDownloadMIDI: true,
    limitedDownloads: false,
    canBookmarkSongs: true,
    initialFilterPermissions: {
      isPart: 'false',
      mixType: { excluded: [], included: ['Full Mix'] },
    },
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
      'isPart',
      'mixType',
    ],
    sortingPermissions: [
      RESULTS_SORTING_METHODS.newest,
      RESULTS_SORTING_METHODS.oldest,
      RESULTS_SORTING_METHODS.popularity,
      RESULTS_SORTING_METHODS.popularity_ascending,
    ],
    similarSearch: true,
  },
  enterpriseUser: {
    canFilterResults: true,
    canDownloadSongs: true,
    canDownloadAllTracks: true,
    canDownloadMicroparts: true,
    canDownloadSfx: true,
    canDownloadMIDI: true,
    limitedDownloads: false,
    canBookmarkSongs: true,
    initialFilterPermissions: {
      isPart: 'false',
      mixType: { excluded: [], included: ['Full Mix'] },
    },
    filteringPermissions: [
      'audio.duration',
      'bpm',
      'keywords.manual.edit-style',
      'keywords.manual.energy',
      'keywords.manual.genre',
      'keywords.manual.instruments',
      'keywords.manual.location',
      'keywords.manual.mood',
      'keywords.manual.movement',
      'keywords.manual.video-theme',
      'keywords.manual.type',
      'keywords.manual.feature',
      'isPart',
      'mixType',
    ],
    sortingPermissions: [
      RESULTS_SORTING_METHODS.newest,
      RESULTS_SORTING_METHODS.oldest,
      RESULTS_SORTING_METHODS.popularity,
      RESULTS_SORTING_METHODS.popularity_ascending,
    ],
    similarSearch: true,
  },
};

export const SUBSCRIPTION_HINTS = {
  free: 'free',
  paid: 'paid',
};

export const CURRENCY_CODES = {
  default: 'USD',
  en: 'USD',
  ja: 'JPY',
};

export const CURRENCY_SYMBOLS = {
  USD: '$',
  JPY: '￥',
};

export const REVENUE_SHARE_BREAKDOWN = {
  free1: '10%',
  free2: '50%',
};
