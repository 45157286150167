// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type AllowlistingStatusesGraphMdl = {
  data: {
    whitelist_successful_status_badge: {
      text: string,
    },
    whitelist_unsuccessful_status_badge: {
      text: string,
    },
    whitelist_in_progress_status_badge: {
      text: string,
    },
    whitelist_unsuccessful_notice_message: {
      text: string,
    },
    whitelist_unsuccessful_notice_cta: {
      text: string,
    },
    whitelist_unsuccessful_modal_title: {
      text: string,
    },
    whitelist_unsuccessful_modal_description: {
      html: string,
    },
  },
};

export const getAllowlistingSuccessfulStatusBadge = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_successful_status_badge.text', '') || '';
};

export const getAllowlistingUnsuccessfulStatusBadge = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_unsuccessful_status_badge.text', '') || '';
};

export const getAllowlistingInProgressStatusBadge = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_in_progress_status_badge.text', '') || '';
};

export const getAllowlistingUnsuccessfulNoticeMessage = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_unsuccessful_notice_message.text', '') || '';
};

export const getAllowlistingUnsuccessfulNoticeCta = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_unsuccessful_notice_cta.text', '') || '';
};

export const getAllowlistingUnsuccessfulModalTitle = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_unsuccessful_modal_title.text', '') || '';
};

export const getAllowlistingUnsuccessfulModalDescription = (
  copy: AllowlistingStatusesGraphMdl
): string => {
  return get(copy, 'data.whitelist_unsuccessful_modal_description.html', '') || '';
};

export const query = graphql`
  fragment WhitelistStatuses on PrismicWhitelistStatuses {
    data {
      whitelist_successful_status_badge {
        text
      }
      whitelist_unsuccessful_status_badge {
        text
      }
      whitelist_in_progress_status_badge {
        text
      }
      whitelist_unsuccessful_notice_message {
        text
      }
      whitelist_unsuccessful_notice_cta {
        text
      }
      whitelist_unsuccessful_modal_title {
        text
      }
      whitelist_unsuccessful_modal_description {
        html
      }
    }
  }
`;
