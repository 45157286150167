// @flow
import React from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { smallMonoCss } from '../../../../../../../styles/typography/typography';
import { LargeHeading } from '../../../../../../../styles/typography/components/Heading/Heading';

const Container = styled('section')`
  margin-top: 92px;
`;

const Description = styled('div')`
  ${smallMonoCss};
  margin-top: 20px;
  margin-bottom: 25px;
  max-width: 400px;
`;

const Body = styled('div')`
  max-width: 400px;
`;

type Props = {
  heading: any,
  description: any,
  children: any,
};

const ProfileSection = ({ children, heading, description, ...otherProps }: Props) => (
  <Container {...otherProps}>
    <header>
      <LargeHeading>{heading}</LargeHeading>
      {description && <Description>{parse(description)}</Description>}
    </header>
    <Body>{children}</Body>
  </Container>
);

export default ProfileSection;
