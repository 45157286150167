/* eslint-disable jsx-a11y/media-has-caption */
// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useRecoilValue, useRecoilState } from 'recoil';
import { transparentize } from 'polished';
import { buttonResetCss } from '../../../../../components/Button/Button';
import { darkColor, lightColor } from '../../../../../styles/config/colors';
import PlayIcon from '../../../../../assets/inline-assets/play-icon.svg';
import PauseIcon from '../../../../../assets/inline-assets/pause-icon.svg';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../styles/responsive';
import SpinnerIcon from '../../../../../components/SpinnerIcon/SpinnerIcon';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useBrowseManagerContext } from '../../../GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import { useSong } from '../../../SongContextWrapper/SongContextWrapper';
import {
  useModalsContext,
  useShowSignUp,
} from '../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  songPlayingSelector,
  playbackListSlugAtom,
  globalPlayingAtom,
  globalTryingToPlayAtom,
  globalLoadErrorAtom,
  songPositionAtom,
  silencePlayedAtom,
} from '../../../../../store/globalPlayer';
import { useAuthContext } from '../../../../../auth/components/AuthWrapper/AuthWrapper';

const Button = styled('button')`
  ${buttonResetCss};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 40px;
    height: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${darkColor};
    border-radius: 50%;
    transition: all 200ms ease-in;
  }

  &:active {
    opacity: 0.8;

    &::before {
      //transform: scale(0.95);
      transition-timing-function: ease-out;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
  }
`;

const ImageButtonLayout = styled('div')`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
`;

const ImageButton = styled(Button)`
  &::before {
    border: none;
    border-radius: 2px;
  }
`;

const cantPlayClass = css`
  opacity: 0.33;
`;

const playClass = css`
  position: relative;
  left: 2px;

  ${SMALL_DEVICE_BREAKPOINT} {
    left: 2px;
  }
`;

const overlayShadow = css`
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.5, lightColor)};

  &::before {
    border-radius: 2px;
    border: none;
  }
`;

type RawPlayPauseButtonProps = {
  playing: boolean,
  onClick: () => void,
  cantPlay?: boolean,
  tryingToPlay?: boolean,
};

export const RawPlayPauseButton = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  onClick,
}: RawPlayPauseButtonProps) => {
  // eslint-disable-next-line no-nested-ternary
  const icon = tryingToPlay ? (
    <SpinnerIcon inverse />
  ) : playing ? (
    <PauseIcon />
  ) : (
    <PlayIcon className={playClass} />
  );
  return (
    <Button
      className={cx({
        [cantPlayClass]: cantPlay,
      })}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export const ImagePlayPauseButton = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  backgroundImage,
  onClick,
}: RawPlayPauseButtonProps) => {
  // eslint-disable-next-line no-nested-ternary
  const icon = tryingToPlay ? (
    <SpinnerIcon inverse />
  ) : playing ? (
    <PauseIcon />
  ) : (
    <PlayIcon className={playClass} />
  );
  return (
    <ImageButtonLayout
      className={cx({
        [cantPlayClass]: cantPlay,
      })}
      onClick={onClick}
      backgroundImage={backgroundImage}
    >
      <div className={overlayShadow}>
        <ImageButton>{icon}</ImageButton>
      </div>
    </ImageButtonLayout>
  );
};

export const PlayPauseButtonControl = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  backgroundImage,
  onClick,
}) => {
  return backgroundImage ? (
    <ImagePlayPauseButton
      playing={playing}
      onClick={onClick}
      tryingToPlay={tryingToPlay}
      backgroundImage={backgroundImage}
      cantPlay={cantPlay}
    />
  ) : (
    <RawPlayPauseButton
      playing={playing}
      onClick={onClick}
      tryingToPlay={tryingToPlay}
      cantPlay={cantPlay}
    />
  );
};

type Props = {
  songPosition: number,
  checkSongPosition: boolean,
  playBackground: string,
};

const PlayPauseButton = ({ songPosition, checkSongPosition, playBackground }: Props) => {
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const songPlaying = useRecoilValue(songPlayingSelector);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);
  const globalSongPosition = useRecoilValue(songPositionAtom);
  const globalPlaying = useRecoilValue(globalPlayingAtom);
  const globalTryingToPlay = useRecoilValue(globalTryingToPlayAtom);
  const globalLoadError = useRecoilValue(globalLoadErrorAtom);
  const [silencePlayed, setSilencePlayed] = useRecoilState(silencePlayedAtom);
  const {
    changeSongList,
    handleToggleGlobalPlayback,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
    playGlobalPlayback,
  } = useGlobalPlayerContext();
  const {
    browsingSongs,
    songListType,
    songListTitle,
    songListSlug,
    queueRestricted,
    restrictionType,
  } = useBrowseManagerContext();
  const {
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
    youtubeVideoTitle,
    sectionOrigin,
    artistProfile,
  } = useDownloadContext();

  const song = useSong();
  const { showSimilarSearchRestrictedModal } = useModalsContext();

  const isQueuePlaying = songListSlug === playbackListSlug;
  const playingSongId = songPlaying ? songPlaying.objectID : '';
  const isGlobalPlayerSong = song.objectID === playingSongId && isQueuePlaying;
  const isSongPositionPlaying = checkSongPosition ? songPosition === globalSongPosition : true;

  const playing = isGlobalPlayerSong && globalPlaying && isSongPositionPlaying;
  const tryingToPlay = isGlobalPlayerSong && globalTryingToPlay && isSongPositionPlaying;
  const loadError = isGlobalPlayerSong && globalLoadError;

  const handleOnPlayToggle = () => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    // play silence to enable mobile playback
    if (!silencePlayed) {
      const audioElements = document.getElementsByClassName('audio_silence');
      const audioSample = audioElements[0];
      audioSample.play();
      setSilencePlayed(true);
    }
    //
    playGlobalPlayback();
    if (isGlobalPlayerSong && isSongPositionPlaying) {
      handleToggleGlobalPlayback();
    } else {
      if (queueRestricted) {
        if (restrictionType === 'paidSubscription') {
          showSimilarSearchRestrictedModal();
        }
        return;
      }
      handleUpdateGlobalAnalyticsData(
        mixpanelKeywords,
        magicKeywords,
        youtubeKeywords,
        youtubeVideoTitle,
        sectionOrigin,
        '',
        artistProfile
      );
      changeSongList(browsingSongs, songPosition);
      updateGlobalPlayerPlaybackDetails(songListType, songListTitle, songListSlug);
    }
  };

  return (
    <>
      {playBackground ? (
        <ImagePlayPauseButton
          playing={playing}
          onClick={handleOnPlayToggle}
          tryingToPlay={tryingToPlay}
          backgroundImage={playBackground}
          cantPlay={loadError}
        />
      ) : (
        <RawPlayPauseButton
          playing={playing}
          onClick={handleOnPlayToggle}
          tryingToPlay={tryingToPlay}
          cantPlay={loadError}
        />
      )}
      {songPosition === 0 && (
        <audio muted className="audio_silence">
          <source src="/audio/silence.mp3" type="audio/mpeg" />
        </audio>
      )}
    </>
  );
};

export default PlayPauseButton;
