import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import 'src/styles/global';
import EmbedAppContent from './components/EmbedAppContent/EmbedAppContent';
import ErrorBoundary from '../../errors/components/ErrorBoundary/ErrorBoundary';
import ToastWrapper from '../../toasts/components/ToastWrapper/ToastWrapper';
import ChargebeeWrapper from '../../user/subscriptions/components/ChargebeeWrapper/ChargebeeWrapper';

type Props = {
  shareSlug: string,
};

const EmbedApp = ({ shareSlug }: Props) => {
  const [clientLoaded, setClientLoaded] = useState(false);
  useEffect(() => {
    setClientLoaded(true);
  });
  if (!clientLoaded) return null;
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <ToastWrapper>
          <ChargebeeWrapper>
            <EmbedAppContent shareSlug={shareSlug} />
          </ChargebeeWrapper>
        </ToastWrapper>
      </RecoilRoot>
    </ErrorBoundary>
  );
};

export default EmbedApp;
