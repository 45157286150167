import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { MEDIA_BREAKPOINTS, SMALL_DESKTOP_BREAKPOINT } from '../../../../../../styles/responsive';
import SmallNextArrowIcon from '../../../../../../assets/inline-assets/arrow-small-right-icon.svg';
import SmallPrevArrowIcon from '../../../../../../assets/inline-assets/arrow-small-left-icon.svg';

const buttonResetCss = css`
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const NavButtonContainer = styled('div')`
  height: 50px;
  top: -32px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    top: -42px;
  }
`;

const NavButton = styled('button')`
  ${buttonResetCss};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: opacity 200ms ease-in-out;
  padding: 0px 10px;

  ${MEDIA_BREAKPOINTS.min1000} {
    &:not(:first-child) {
      margin-top: 20px;
    }

    svg {
      &:first-child {
        position: relative;
        left: -2px;
      }

      &:last-child {
        position: relative;
        right: 2px;
      }
    }
  }

  ${MEDIA_BREAKPOINTS.max1000} {
    padding: 15px 24px 0px 24px;

    &:last-child {
      margin-left: -1px;
    }
  }

  &.previous {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 2;
    }
    &::before {
      right: 0px;

      ${MEDIA_BREAKPOINTS.max1000} {
        right: 0px;
      }
    }
  }

  &.next {
    ${MEDIA_BREAKPOINTS.max1000} {
      order: 1;
    }
    &::before {
      left: 0px;

      ${MEDIA_BREAKPOINTS.max1000} {
        left: 0px;
      }
    }
  }

  svg {
    position: relative;
  }
`;

const arrowStyles = {
  position: 'absolute',
  zIndex: 0,
  display: 'flex',
  cursor: 'pointer',
};

export default function CustomPreviewSliderArrow(props) {
  const { onClick, previous } = props;
  return (
    <NavButtonContainer style={{ ...arrowStyles, right: previous ? 35 : 0 }}>
      <NavButton className={previous ? 'previous' : 'next'} type="button" onClick={onClick}>
        {previous ? <SmallPrevArrowIcon /> : <SmallNextArrowIcon />}
      </NavButton>
    </NavButtonContainer>
  );
}
