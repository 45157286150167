// @flow
import React from 'react';
import styled from 'react-emotion';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { usePricingPlans } from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getPricingPlan,
  getPricingPlanDynamicSubtitle,
  getPricingPlanName,
  getPricingPlanSubtitle,
} from '../../../../cms/pricingPlan';
import PlanPreview from '../PlanPreview/PlanPreview';
import AddedSubscription from '../AddedSubscription/AddedSubscription';
import { getSubscriptionChannelId } from '../../../../api/firebase/user/subscriptions';
import { getCurrencyString, getUSDCurrencyString } from '../../../../utils/currency';

const Subscriptions = styled('section')`
  margin-top: 10px;
  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {
  planKey: string,
  subscriptions: Array<UserSubscriptionMdl>,
  groupStartingIndex: number,
};

const GroupedSubscriptions = ({ planKey, subscriptions, groupStartingIndex }: Props) => {
  const pricingPlans = usePricingPlans();
  const plan = getPricingPlan(pricingPlans, planKey);
  return (
    <div>
      <div>
        <PlanPreview disabled plan={planKey} name={getPricingPlanName(plan)} />
      </div>
      <Subscriptions>
        <ul>
          {subscriptions.map((subscription, index) => {
            const seatMonthlyTotalCents = subscription.seat.monthlyTotalCents;
            const channelId = getSubscriptionChannelId(subscription);
            return (
              <li key={channelId}>
                <AddedSubscription
                  planNumber={groupStartingIndex + index + 1}
                  subscription={subscription}
                  monthlyTotalCents={seatMonthlyTotalCents}
                />
              </li>
            );
          })}
        </ul>
      </Subscriptions>
    </div>
  );
};

export default GroupedSubscriptions;
