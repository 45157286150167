// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  useDowngradeSubscriptionModalCopy,
  useMiscCopy,
  usePricingPlans,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getDowngradeSubscriptionModalPageInstruction,
  getDowngradeSubscriptionModalYoutubeInstructions,
  getDowngradeSubscriptionModalTitle,
  getDowngradeSubscriptionModalYoutubeInstructionsFreeTrial,
} from '../../../../cms/downgradeSubscriptionModal';
import { getPricingPlan, getPricingPlanName } from '../../../../cms/pricingPlan';
import {
  getSubscriptionChannelId,
  getSubscriptionNextBillingDate,
  getSubscriptionPlanKey,
  getSubscriptionExtraChannels,
  getSeatInterval,
  getSubscriptionStatus,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import Button, { DarkButton } from '../../../../components/Button/Button';
import {
  getMiscCopyCancelButton,
  getMiscCopyConfirmButton,
  getMiscCopyMonthlyButton,
  getMiscCopyQuarterlyButton,
  getMiscCopyBiannuallyButton,
  getMiscCopyAnnuallyButton,
} from '../../../../cms/miscCopy';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { lightColor } from '../../../../styles/config/colors';
import Checkmark from '../../../../assets/inline-assets/checkmark-icon.svg';
import { ICONS } from '../../../../images/paths';
import { LARGE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';
import LoadingDisplay from '../../../../components/LoadingDisplay/LoadingDisplay';
import { SUBSCRIPTION_STATUSES } from '../../../../api/firebase/user/user';
import { BILLING_CYCLES } from '../../data';
import { getSubscriptionsPagePromotionalDefaultInterval } from '../../../../cms/subscriptionsPage';

type Props = {
  subscription: UserSubscriptionMdl,
  newPlanKey: string,
  onClose: () => void,
};

export const SubscriptionModalFooter = styled('div')`
  ${LARGE_DEVICE_BREAKPOINT} {
    display: flex;
    margin-top: 45px;

    > button {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    > button {
      &:first-child {
        order: 2;
        margin-top: 10px;
      }

      &:last-child {
        order: 1;
      }
    }
  }
`;

const YouTubeChannelList = styled('ul')`
  margin-bottom: 10px;
`;

const YouTubeChannelItem = styled('li')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1.5px ${transparentize(0.9, lightColor)} solid;
  padding: 7px 0px;
  color: ${lightColor};
  position: relative;
  cursor: pointer;

  &.primaryChanel {
    cursors: default;
  }

  & svg {
    path {
      stroke: ${lightColor};
      stroke-width: 2px;
    }
  }

  &:last-of-type {
    border-bottom: 1.5px ${transparentize(0.9, lightColor)} solid;
  }
`;

const Icon = styled('div')`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding-bottom: 3px;
  opacity: 0.3;

  .primaryChannel & {
    opacity: 1;
  }
`;

const ChannelName = styled('div')`
  opacity: 0.3;
  font-weight: 400;

  .primaryChannel & {
    opacity: 1;
  }
`;

const CheckmarkContainer = styled('div')`
  position: absolute;
  right: 15px;
`;

export const getLocalisedBillingCycle = (billingCycle: string) => {
  const miscCopy = useMiscCopy();
  if (billingCycle === BILLING_CYCLES.monthly.cycle) {
    return getMiscCopyMonthlyButton(miscCopy);
  }

  if (billingCycle === BILLING_CYCLES.quarterly.cycle) {
    return getMiscCopyQuarterlyButton(miscCopy);
  }

  if (billingCycle === BILLING_CYCLES.biannually.cycle) {
    return getMiscCopyBiannuallyButton(miscCopy);
  }

  return getMiscCopyAnnuallyButton(miscCopy);
};

const ExtraYouTubeChannelOption = ({
  subscriptionKey,
  extraChannels,
  extraChannel,
  updatingChannelSwap,
}: any) => {
  const { swapPrimaryChannelOnDowngrade } = useSubscriptionsContext();
  const [isSwapingYouTubeChannel, setIsSwappingYouTubeChannel] = useState(false);

  const handleClick = event => {
    updatingChannelSwap(true);
    swapPrimaryChannelOnDowngrade(extraChannels, subscriptionKey, extraChannel).then(() => {
      updatingChannelSwap(false);
    });
  };

  return isSwapingYouTubeChannel ? (
    <LoadingDisplay />
  ) : (
    <YouTubeChannelItem onClick={handleClick} key={extraChannel.youtube.id}>
      <Icon>
        <img src={ICONS.youtubeChannel} alt="account icon" />
      </Icon>
      <ChannelName>{extraChannel.youtube.name}</ChannelName>
    </YouTubeChannelItem>
  );
};

const DowngradeYouTubeChannelSelection = ({ subscription, extraYouTubeChannels }: any) => {
  const primaryYouTubeChannel = subscription.channel.youtube;
  const [isSwappingYouTubeChannel, setIsSwappingYouTubeChannel] = useState(false);

  const updatingChannelSwap = (newState: boolean) => {
    setIsSwappingYouTubeChannel(newState);
  };

  return isSwappingYouTubeChannel ? (
    <LoadingDisplay />
  ) : (
    <div>
      <YouTubeChannelList>
        <YouTubeChannelItem className={'primaryChannel'}>
          <Icon>
            <img src={ICONS.youtubeChannel} alt="account icon" />
          </Icon>
          <ChannelName>{primaryYouTubeChannel.name}</ChannelName>
          <CheckmarkContainer>
            <Checkmark />
          </CheckmarkContainer>
        </YouTubeChannelItem>

        {extraYouTubeChannels.map(extraChannel => {
          return (
            <ExtraYouTubeChannelOption
              subscriptionKey={subscription.key}
              extraChannels={extraYouTubeChannels}
              extraChannel={extraChannel}
              key={extraChannel.youtube.id}
              updatingChannelSwap={updatingChannelSwap}
            />
          );
        })}
      </YouTubeChannelList>
    </div>
  );
};

const DowngradeSubscriptionModal = ({ subscription, newPlanKey, onClose }: Props) => {
  const [busy, setBusy] = useState(false);
  const {
    downgradeSubscription,
    upgradeSubscription,
    billingCycle,
    setBillingCycle,
    selectedPlanNumber,
  } = useSubscriptionsContext();
  const copy = useDowngradeSubscriptionModalCopy();
  const miscCopy = useMiscCopy();
  const pricingPlans = usePricingPlans();
  const plan1Name = getPricingPlanName(
    getPricingPlan(pricingPlans, getSubscriptionPlanKey(subscription))
  );
  const plan2Name = getPricingPlanName(getPricingPlan(pricingPlans, newPlanKey));
  const date = getSubscriptionNextBillingDate(subscription);
  const title = getDowngradeSubscriptionModalTitle(copy).replace('$PLAN$', selectedPlanNumber);
  const description = getDowngradeSubscriptionModalPageInstruction(copy)
    .replace('$PLAN1$', plan1Name)
    .replace('$INTERVAL1$', getSeatInterval(subscription))
    .replace('$PLAN2$', plan2Name)
    .replace('$INTERVAL2$', getLocalisedBillingCycle(billingCycle))
    .replace('$DATE$', date);

  const swapYouTubeDescription =
    getSubscriptionStatus(subscription) !== SUBSCRIPTION_STATUSES.trial
      ? getDowngradeSubscriptionModalYoutubeInstructions(copy)
          .replace('$PLAN1$', plan1Name)
          .replace('$INTERVAL1$', getLocalisedBillingCycle(getSeatInterval(subscription)))
          .replace('$PLAN2$', plan2Name)
          .replace('$INTERVAL2$', getLocalisedBillingCycle(billingCycle))
          .replace('$DATE$', date)
      : getDowngradeSubscriptionModalYoutubeInstructionsFreeTrial(copy)
          .replace('$PLAN1$', plan1Name)
          .replace('$INTERVAL1$', getSeatInterval(subscription))
          .replace('$PLAN2$', plan2Name)
          .replace('$INTERVAL2$', getLocalisedBillingCycle(billingCycle));

  const subscriptionsPage = useSubscriptionsPage();
  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleConfirm = () => {
    if (busy) return;
    setBusy(true);

    // If the subscription is on free trial, we will be actioing an upgrade in order to open a Chargebee checkout
    if (getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial) {
      upgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, billingCycle)
        .then(() => {
          onClose();
        })
        .catch(error => {
          Sentry.captureMessage('Something went wrong when downgrading subscription on free trial');
          Sentry.captureException(error);
          console.error(error);
          setBusy(false);
        });
    } else {
      downgradeSubscription(getSubscriptionChannelId(subscription), newPlanKey, billingCycle)
        .then(() => {
          onClose();
        })
        .catch(error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when downgrading subscription');
          Sentry.captureException(error);
          console.error(error);
          setBusy(false);
        });
    }
  };

  return (
    <ClassicModalLayout label={title} onClose={handleClose} fullSized={false}>
      <ClassicModalContentLayout
        heading={title}
        description={
          subscription.seat.plan === 'business1' &&
          getSubscriptionExtraChannels(subscription).length
            ? swapYouTubeDescription
            : description
        }
        footer={
          <div>
            {subscription.seat.plan === 'business1' &&
            getSubscriptionExtraChannels(subscription).length > 0 ? (
              <DowngradeYouTubeChannelSelection
                subscription={subscription}
                extraYouTubeChannels={getSubscriptionExtraChannels(subscription)}
              />
            ) : (
              ''
            )}
            <SubscriptionModalFooter>
              <DarkButton onClick={handleClose}>{getMiscCopyCancelButton(miscCopy)}</DarkButton>
              <Button loading={busy} onClick={handleConfirm}>
                {getMiscCopyConfirmButton(miscCopy)}
              </Button>
            </SubscriptionModalFooter>
          </div>
        }
      ></ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

export default DowngradeSubscriptionModal;
