// @flow
import { transparentize } from 'polished';
import React, { useState } from 'react';
import styled from 'react-emotion';
import CloseIcon from '../../../../../../../assets/inline-assets/close.svg';
import MinusIcon from '../../../../../../../assets/inline-assets/minus-icon.svg';
import PlusIcon from '../../../../../../../assets/inline-assets/plus-icon.svg';
import { darkColor, evokeBorderColor } from '../../../../../../../styles/config/colors';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { monoNavCss } from '../../../../../../../styles/typography/typography';
import { isSmallDeviceSize } from '../../../../../../../utils/device';

type Props = {
  children: string,
  onClick: () => void,
  keywordClass?: string,
};

const MinusIconWrapper = styled('div')`
  transform: translateY(-3px);
`;

const CloseIconWrapper = styled('div')`
  transform: scale(0.5, 0.5) translateY(15px) translateX(-5px);
  width: 7px;
  height: 7px;
`;

const CloseIconWrapperMobile = styled('div')`
  transform: scale(0.6, 0.6) translateY(10px) translateX(-5px);
  padding-left: 12px;
  width: 7px;
  height: 7px;
  ${SMALL_DESKTOP_BREAKPOINT} {
    transform: scale(0.6, 0.6) translateY(9px) translateX(-5px);
  }
`;

const Button = styled('span')`
  border: 2px solid ${evokeBorderColor};
  margin-top: 5px;
  display: flex;
  font-size: 16px;
  margin-left: 5px;
  text-align: center;
  color: black;
  border-radius: 3px;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    background: rgba(15, 15, 20, 0.05);
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    font-size: 15px;
    line-height: 25px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-top: 5px;
    margin-left: 10px;
  }

  &.actionKeyword {
    ${monoNavCss}
    line-height: 25px;
    text-transform: uppercase;
    padding: 7px 10px 7px 10px;
    border-radius: 2px;
    background-color: #dad8d3;
    border: none;
    color: ${transparentize(0.5, darkColor)};

    ${SMALL_DESKTOP_BREAKPOINT} {
      line-height: 28px;
    }

    ${MOBILE_DEVICE_BREAKPOINT} {
      line-height: 24px;
    }

    &:hover {
      color: ${darkColor};
    }

    & div {
      display: none;
    }
  }
`;

const Icon = styled('div')`
  padding-right: 5px;
  position: relative;
  bottom: 2px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    bottom: 1px;
  }
`;

const isNegativeKeyword = (keyword: string): boolean => {
  return keyword[0] === '-';
};

const getKeywordIcon = (keyword: string) => {
  const icon = isNegativeKeyword(keyword) ? (
    <MinusIconWrapper>
      <MinusIcon />
    </MinusIconWrapper>
  ) : (
    <PlusIcon />
  );

  return icon;
};

const removeSyntaxElement = (keyword: string) => {
  return isNegativeKeyword(keyword) ? keyword.slice(1) : keyword;
};

const InteractiveKeyword = ({ children, onClick, keywordClass = '' }: Props): React.Node => {
  const [keywordIcon, setKeywordIcon] = useState(getKeywordIcon(children));

  const mouseEnter = () => {
    if (isSmallDeviceSize()) return;
    setKeywordIcon(
      <CloseIconWrapper>
        <CloseIcon />
      </CloseIconWrapper>
    );
  };
  const mouseLeave = () => {
    if (isSmallDeviceSize()) return;
    setKeywordIcon(getKeywordIcon(children));
  };
  return (
    <Button
      className={keywordClass}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Icon>{keywordIcon}</Icon>
      {removeSyntaxElement(children)}
      {isSmallDeviceSize() && (
        <CloseIconWrapperMobile>
          <CloseIcon />
        </CloseIconWrapperMobile>
      )}
    </Button>
  );
};

export default InteractiveKeyword;
