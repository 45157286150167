// @flow
import React, { useMemo, useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';

import { useMousePosition } from '@react-hook/mouse-position';
import { css } from 'emotion';
import type { HistogramDataMdl } from '../../../api/algolia/song';
import { lightColor } from '../../../styles/config/colors';
import MicroParts from './components/MicroParts';
import useDimensions from '../../../utils/useDimensions';
import { useAudioPlaybackContext } from '../AudioPlayback/AudioPlayback';
import { getSongFamily, getSongHistogram } from '../../../api/algolia/song';
import { useSong } from '../SongContextWrapper/SongContextWrapper';
import { useGradient } from '../../../utils/gradient';
import { useIsSmallDevice } from '../../../components/responsive/SmallDeviceOnly';
import { useAuthContext } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { useShowSignUp } from '../../../modals/components/ModalsWrapper/ModalsWrapper';

const containerClass = css`
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    svg {
      rect {
        transition: fill 200ms ease, opacity 200ms ease;
      }
    }
  }
`;

const SVG = styled('svg')`
  width: 100%;
  rect {
    transition: fill 100ms ease, opacity 100ms ease;
    stroke: none;
  }
`;

const MicroPartsWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const defaultFill = `${transparentize(0.9, lightColor)}`;

const getHeight = (top: number, bottom: number, height: number): number => {
  const calculatedHeight = height * ((top + bottom) / 2);
  if (calculatedHeight < 2) return 2;
  return calculatedHeight;
};

const getYOffset = (top: number, bottom: number, height: number): number => {
  const yOffset = 1 - top;
  return (yOffset * height) / 2;
};

const idealBarWidth = 2;
const barGap = 1;

const getReducedArray = (histogram: HistogramDataMdl, width: number): HistogramDataMdl => {
  const numberOfBars = Math.floor(width / (idealBarWidth + barGap));
  if (histogram.length < numberOfBars) {
    console.warn(
      `Not enough bars. Need ${numberOfBars} but only ${histogram.length} were provided.`
    );
    return histogram;
  }
  const indexOffset = histogram.length / numberOfBars;
  const indexes = Array.from({ length: numberOfBars }).map((item, index) => {
    return Math.round(index * indexOffset);
  });
  return indexes.map(index => {
    if (histogram[index] === undefined) return [0, 0];
    return histogram[index];
  });
};

const getScaledWaveform = (histogram: HistogramDataMdl): HistogramDataMdl => {
  return histogram.map(([top, bottom]) => {
    return [top / 100, bottom / 100];
  });
};

export type MicropartsMdl = Array<[number, number]>;

export const findMicropartIndex = (barOffset: number, microparts: MicropartsMdl): number => {
  return microparts.findIndex(([start, end]) => {
    return barOffset >= start && barOffset <= end;
  });
};

type Props = {
  condensed?: boolean,
  micropartsLabelDuration?: boolean,
  micropartsHiglight?: number,
  hideMicropartsOnMobile?: boolean,
  alwaysShowMicroparts?: boolean,
};

const AudioBars = ({
  condensed = false,
  micropartsLabelDuration = false,
  micropartsHiglight = null,
  hideMicropartsOnMobile = false,
  alwaysShowMicroparts = false,
}: Props) => {
  const song = useSong();
  const {
    played: songPlayed,
    songID,
    canPlay,
    progress,
    onSeek,
    viewingMicroparts,
    microPartIndex,
    onMicropartSelect,
    duration,
    microparts,
    onDownloadMicropart,
    swappingLoop,
    setSwappingLoop,
  } = useAudioPlaybackContext();
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();

  const isSmallDevice = useIsSmallDevice();

  const hideMicroparts = isSmallDevice && hideMicropartsOnMobile;

  const rawProgress = progress / duration;
  const progressScaled = songPlayed ? rawProgress : 1;

  const gradient = useGradient(getSongFamily(song), microparts.length || 7);

  const histogram = getSongHistogram(song);

  const [hoveredMicroPart, setHoveredMicroPart] = useState(null);
  const [ref, { height, width }] = useDimensions();
  const [mousePositionRef, mousePosition] = useMousePosition(
    0, // enterDelay
    0, // leaveDelay
    60 // fps
  );
  const bars = useMemo(() => getReducedArray(getScaledWaveform(histogram), width), [width, songID]);
  const numberOfBars = bars.length;
  const finalBarWidth = idealBarWidth;
  const { x: mouseX, y: mouseY } = mousePosition;
  const cursorX = mouseX / width;
  const mouseIsOver = canPlay && mouseX !== null && mouseY !== null;

  const handleClick = event => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    const offsetX = (event.pageX - currentTargetRect.left) / width;

    if (viewingMicroparts || alwaysShowMicroparts) {
      return;
      /* We no longer need the full length seek on audiobars when the micropart view is on
      console.log('running 2');
      const clickedMicroPartIndex = findMicropartIndex(offsetX, microparts);
      if (clickedMicroPartIndex !== microPartIndex) {
        console.log('running 3');
        onSeek(microparts[clickedMicroPartIndex][0]);
        return;
      }
      return;
      */
    }
    onSeek(offsetX);
  };

  const svgBars = useMemo(() => {
    return bars.map(([top, bottom], index) => {
      const offset = index / numberOfBars;
      const played = canPlay && progress > 0 ? offset <= progressScaled : true; // from false
      const moused = mouseIsOver && offset <= cursorX;
      const active = played || swappingLoop || moused;
      let highlightOpacity = active ? 1 : 0;
      if ((active && mouseIsOver && !played) || (active && played && mouseIsOver && !moused)) {
        highlightOpacity = 0.4;
      }
      let backOpacity = !active ? 1 : 0;

      const barMicroPartIndex = findMicropartIndex(offset, microparts);

      if ((viewingMicroparts || alwaysShowMicroparts) && hideMicroparts) {
        highlightOpacity = 1;
        backOpacity = 0;
      } else if (viewingMicroparts || alwaysShowMicroparts) {
        if (micropartsHiglight || micropartsHiglight === 0) {
          if (micropartsHiglight !== barMicroPartIndex) {
            highlightOpacity = 0;
            backOpacity = 1;
          } else if (
            micropartsHiglight === barMicroPartIndex &&
            barMicroPartIndex !== microPartIndex
          ) {
            highlightOpacity = 1;
            backOpacity = 0;
          }
        } else if (barMicroPartIndex !== microPartIndex) {
          if (barMicroPartIndex === hoveredMicroPart) {
            highlightOpacity = 0.4;
            backOpacity = 0;
          } else {
            highlightOpacity = 0;
            backOpacity = 1;
          }
        }
      }

      const barHeight = getHeight(top, bottom, height);
      const useMicropartsGradient = microparts.length > 0;

      const gradientIndex = (() => {
        if (useMicropartsGradient) {
          return barMicroPartIndex && barMicroPartIndex >= 0 ? barMicroPartIndex : 0;
        }
        return Math.floor((index / bars.length) * 7);
      })();

      const fill =
        gradient && gradient.length > 0 && gradient.length >= gradientIndex
          ? gradient[gradientIndex]
          : '';

      return (
        <React.Fragment key={`${songID}::${width.toString()}::${index.toString()}`}>
          <rect
            width={finalBarWidth}
            height={barHeight}
            y={getYOffset(top, bottom, height)}
            x={index * (idealBarWidth + barGap)}
            fill={defaultFill}
            opacity={backOpacity}
          />
          <rect
            width={finalBarWidth}
            height={barHeight}
            y={getYOffset(top, bottom, height)}
            x={index * (idealBarWidth + barGap)}
            fill={fill}
            opacity={highlightOpacity}
          />
        </React.Fragment>
      );
    });
  }, [
    songID,
    width,
    height,
    cursorX,
    rawProgress,
    canPlay,
    mouseIsOver,
    numberOfBars,
    viewingMicroparts,
    microPartIndex,
    hoveredMicroPart,
  ]);
  return (
    <div className={containerClass} ref={mousePositionRef}>
      <div className={containerClass} ref={ref} onClick={handleClick}>
        <SVG height={height}>
          <defs>
            <linearGradient
              id="audioGradient"
              gradientTransform="rotate(90)"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="0"
              x2="100"
              y2="100"
            >
              <stop offset="0" stopColor="#8AD7D3" />
              <stop offset="1" stopColor="#18A299" />
            </linearGradient>
          </defs>
          {svgBars}
        </SVG>
        {(viewingMicroparts || alwaysShowMicroparts) && (
          <MicroPartsWrapper>
            <MicroParts
              duration={duration}
              microparts={microparts}
              microPartIndex={microPartIndex}
              onMicropartSelect={onMicropartSelect}
              setHoveredMicroPart={setHoveredMicroPart}
              condensed={condensed}
              onDownloadMicropart={onDownloadMicropart}
              micropartsLabelDuration={micropartsLabelDuration}
              micropartsHiglight={micropartsHiglight}
              hideMicroparts={hideMicroparts}
              onLoopSwap={setSwappingLoop}
            />
          </MicroPartsWrapper>
        )}
      </div>
    </div>
  );
};

export default AudioBars;
