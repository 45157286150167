/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { usePalette } from 'react-palette';
import { useSetRecoilState } from 'recoil';
import parse from 'html-react-parser';
import { darkColor, lightColor } from '../../../../styles/config/colors';
import SaveCollectionsButton from './SaveCollectionsButton';
import {
  MEDIA_BREAKPOINTS,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../styles/responsive';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { capitalize } from '../../../../utils/text';
import { searchTriggerAtom } from '../../../../store/mixpanelAnalytics';
import { ROUTES } from '../../../routes';
import { analyticsMixpanelAddKeyword } from '../../../../analytics/mixpanel';
import { useNavigate } from '../../../hooks';
import PlusIcon from '../../../../assets/inline-assets/plus-icon.svg';
import VideoModal from '../../../../modals/components/VideoModal/VideoModal';

const BannerContainer = styled('article')`
  width: 100%;
  background-color: #dad8d3;
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
  background-image: ${props =>
    props.dominantColor
      ? `linear-gradient(${transparentize(0, lightColor)}, ${props.dominantColor})`
      : ``};
`;

const OverlayContainer = styled('div')`
  width: 100%;
  background-color: ${transparentize(0.2, lightColor)};
`;

const BannerContent = styled('div')`
  max-width: 900px;
  min-height: 400px;
  display: flex;
  margin: 0px auto;

  ${SMALL_DESKTOP_BREAKPOINT} {
    max-width: 850px;
  }

  ${MEDIA_BREAKPOINTS.max900} {
    max-width: 800px;
  }

  ${MEDIA_BREAKPOINTS.max850} {
    max-width: 730px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-direction: column;
    padding: 0px 20px;
  }
`;

const CollectionImageContainer = styled('div')`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 0px 50px 0px;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 28px 0px;
  }
`;

const CollectionDetailsContainer = styled('div')`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 0px 50px 0px;

  ${MEDIA_BREAKPOINTS.max850} {
    padding: 0px 0px 50px 30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 100%;
    text-align: center;
    padding: 0px 0px 24px 0px;
  }
  & h6 {
    font-weight: 300;
    font-size: 60px;
    line-height: 1;
    color: #0f0f14;
    margin-bottom: 30px;
    ${SMALL_DEVICE_BREAKPOINT} {
      font-size: 35px;
      margin-bottom: 22px;
    }
  }
  & ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
    ${SMALL_DEVICE_BREAKPOINT} {
      justify-content: center;
      margin-bottom: 28px;
    }
    & li {
      margin: 0px 10px 0px 0px;
      box-sizing: border-box;
      border: 1px solid rgba(15, 15, 20, 0.1);
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0);
      padding: 3px 16px 3px 8px;
      margin-right: 5px;
      margin-top: 5px;
      text-transform: capitalize;
      position: relative;

      ${SMALL_DEVICE_BREAKPOINT} {
        padding: 3px 8px;
      }

      & svg {
        visibility: hidden;
        margin-right: 4px;
        position: relative;
        top: -1px;
        right: 1px;

        ${SMALL_DEVICE_BREAKPOINT} {
          display: none;
        }
      }

      & span.searchToolTip {
        display: none;
        top: -20px;
      }

      &:hover {
        cursor: pointer;
        background: ${transparentize(0.95, darkColor)};
        color: ${darkColor};
        border: 1px solid ${transparentize(0.95, darkColor)};

        & span.searchToolTip {
          display: block;
          position: absolute;
          background-color: #fff;
          text-align: center;
          font-size: 10px;
          width: 100%;
          margin: 0px auto;
          top: -30px;
          left: 0;
        }

        & svg {
          visibility: visible;
        }
      }
    }
  }
  & p {
    font-wieght: 300;
    font-size: 15px;
  }

  & a {
    color: ${transparentize(0.4, darkColor)};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${darkColor};
    }
  }
`;

const CollectionImage = styled('figure')`
  width: 220px;
  & img {
    width: 100%;
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    width: 255px;
  }
`;

const VideoButton = styled('span')`
  color: ${transparentize(0.4, darkColor)};
  text-decoration: none;
  text-align: left;
  margin-top: 5px;
  width: fit-content;
  cursor: pointer;

  ${SMALL_DEVICE_BREAKPOINT} {
    text-align: center;
  }

  &:hover {
    text-decoration: underline;
    color: ${darkColor};
    pointer: cursor;
  }
`;

const CollectionBanner = ({
  slug,
  title,
  image,
  tags,
  description,
  videoUrl,
  videoEmbed,
}: Props) => {
  const { data } = usePalette(image);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const setResultsSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);

  const handleTagSearch = (tag: string) => {
    const pathParams = {
      keywords: [tag.toLowerCase()],
    };

    analyticsMixpanelAddKeyword(
      mixpanel,
      moengage,
      capitalize(tag),
      'regular',
      [],
      false,
      '',
      'collectionTag'
    );
    setResultsSearchTriggerLocation('collectionTag');
    const path = ROUTES.musicSearch.navigatePath(pathParams) || ROUTES.music.navigatePath({});
    navigate(path);
  };

  const openVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <BannerContainer dominantColor={data.vibrant ? data.vibrant : ''}>
        <OverlayContainer>
          <BannerContent>
            <CollectionImageContainer>
              <CollectionImage>
                <img src={image} alt="alt text" />
              </CollectionImage>
              <SaveCollectionsButton collectionSlug={slug} />
            </CollectionImageContainer>
            <CollectionDetailsContainer>
              <h6>{title}</h6>
              <ul>
                {tags.map(tag => {
                  return (
                    <li
                      key={tag}
                      onClick={() => {
                        handleTagSearch(tag);
                      }}
                    >
                      <PlusIcon />
                      <span>{tag}</span>
                    </li>
                  );
                })}
              </ul>
              {parse(description)}
              <VideoButton onClick={openVideo}>{videoUrl}</VideoButton>
            </CollectionDetailsContainer>
          </BannerContent>
        </OverlayContainer>
      </BannerContainer>
      {showVideo && <VideoModal videoIframe={videoEmbed} onClose={closeVideo} />}
    </>
  );
};

export default CollectionBanner;
