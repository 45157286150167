// @flow
import React, { useEffect } from 'react';
import ls from 'local-storage';
import {
  useSubscriptions,
  useSubscriptionsContext,
  useUserHasSubscriptions,
} from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import SelectSubscriptions from '../SelectSubscriptions/SelectSubscriptions';
import SelectEnterpriseSubscription from '../SelectEnterpriseSubscription/SelectEnterpriseSubscription';
import UserSubscriptions from '../UserSubscriptions/UserSubscriptions';
import YouTubeAPIHandler from '../YouTubeAPIHandler/YouTubeAPIHandler';
import { getSubscriptionPlanKey } from '../../../../api/firebase/user/subscriptions';
import { SUBSCRIPTIONS } from '../../data';

type Props = {};

const SubscriptionsHandler = () => {
  const { fetchUserSubscriptions, subscriptions } = useSubscriptionsContext();
  const hasSubscriptions = useUserHasSubscriptions();
  const isSignUpEnterpriseBasic = ls.get('SIGNUP_ENTERPRISE_BASIC_SUBSCRIPTION');
  const hasEnterpriseBasicPlan =
    subscriptions.filter(
      element => getSubscriptionPlanKey(element) === SUBSCRIPTIONS.enterpriseBasic.key
    ).length >= 1;

  const KEY_INITIAL_SUBSCRIPTIONS_COUNT = 'INITIAL_SUBSCRIPTIONS_COUNT';

  useEffect(() => {
    fetchUserSubscriptions();
  }, []);

  useEffect(() => {
    if (hasSubscriptions) {
      ls.set(KEY_INITIAL_SUBSCRIPTIONS_COUNT, subscriptions.length);
    }
  }, [hasSubscriptions]);

  const content = hasSubscriptions ? (
    <UserSubscriptions />
  ) : isSignUpEnterpriseBasic || hasEnterpriseBasicPlan ? (
    <SelectEnterpriseSubscription />
  ) : (
    <SelectSubscriptions />
  );

  return <YouTubeAPIHandler>{content}</YouTubeAPIHandler>;
};

export default SubscriptionsHandler;
