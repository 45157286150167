// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type BillingPageGraphMdl = {
  data: {
    billing_header: {
      text: string,
    },
    next_bill_due: {
      text: string,
    },
    payment_method_header: {
      text: string,
    },
    payment_method_free_user_message: {
      html: string,
    },
    payment_method_instruction: {
      text: string,
    },
    card_number_label: {
      text: string,
    },
    card_number_description: {
      text: string,
    },
    change_card_button: {
      text: string,
    },
    billing_history_header: {
      text: string,
    },
    date_label: {
      text: string,
    },
    action_label: {
      text: string,
    },
    details_label: {
      text: string,
    },
    debit_text: {
      text: string,
    },
    action_billed: {
      text: string,
    },
    action_subscription_added: {
      text: string,
    },
    action_subscription_changed: {
      text: string,
    },
    action_subscription_commenced: {
      text: string,
    },
    action_custom_order: {
      text: string,
    },
  },
};

export const getBillingPageHeading = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.billing_header.text', '');
};

export const getBillingPageNextBillDue = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.next_bill_due.text', '') || '';
};

export const getBillingPagePaymentMethodHeading = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.payment_method_header.text', '') || '';
};

export const getBillingPagePaymentMethodFreeUserMessage = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.payment_method_free_user_message.html', '') || '';
};

export const getBillingPagePaymentMethodInstructions = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.payment_method_instruction.text', '') || '';
};

export const getBillingPageCardNumberLabel = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.card_number_label.text', '') || '';
};

export const getBillingPageCardNumberDescription = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.card_number_description.text', '') || '';
};

export const getBillingPageChangeCardButton = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.change_card_button.text', '') || '';
};

export const getBillingPageDateLabel = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.date_label.text', '') || '';
};

export const getBillingHistoryHeading = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.billing_history_header.text', '') || '';
};

export const getBillingPageActionLabel = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_label.text', '') || '';
};

export const getBillingPageDetailsLabel = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.details_label.text', '') || '';
};

export const getBillingPageDebitText = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.debit_text.text', '') || '';
};

export const getBillingPageActionBilled = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_billed.text', '') || '';
};

export const getBillingPageActionSubscriptionAdded = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_subscription_added.text', '') || '';
};

export const getBillingPageActionSubscriptionChanged = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_subscription_changed.text', '') || '';
};

export const getBillingPageActionSubscriptionCommenced = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_subscription_commenced.text', '') || '';
};

export const getBillingPageActionCustomOrder = (copy: BillingPageGraphMdl): string => {
  return get(copy, 'data.action_custom_order.text', '') || '';
};

export const query = graphql`
  fragment BillingPage on PrismicBillingPage {
    data {
      billing_header {
        text
      }
      next_bill_due {
        text
      }
      payment_method_header {
        text
      }
      payment_method_free_user_message {
        html
      }
      payment_method_instruction {
        text
      }
      card_number_label {
        text
      }
      card_number_description {
        text
      }
      change_card_button {
        text
      }
      billing_history_header {
        text
      }
      date_label {
        text
      }
      action_label {
        text
      }
      details_label {
        text
      }
      debit_text {
        text
      }
      action_billed {
        text
      }
      action_subscription_added {
        text
      }
      action_subscription_changed {
        text
      }
      action_subscription_commenced {
        text
      }
      action_custom_order {
        text
      }
    }
  }
`;
