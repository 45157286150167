// @flow
import React, { useMemo } from 'react';
import Slider from 'react-slick';
import styled from 'react-emotion';
import { useWindowSize } from '@react-hook/window-size';
import { MOBILE_DEVICE_BREAKPOINT } from '../../../../../../styles/responsive';

interface IPreviewSliderProps {
  children: React.ReactNode;
  settings: any;
  ref?: any;
  asNavFor?: any;
  rows?: number;
  infinite?: boolean;
}

const Container = styled('div')`
  padding: 0px;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: 10px;

  & .slick-track {
    margin-left: unset !important;
    margin-right: unset !important;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-left: 0px;
    width: 100%;
  }
`;

export default function PreviewSlider({
  children,
  settings,
  ref,
  asNavFor,
  rows = 1,
  infinite,
}: IPreviewSliderProps) {
  const [width] = useWindowSize(770, 720, { fps: 30 });

  const isInfinite = useMemo(() => {
    let itemsPerRow = 8;

    if (width < 600) {
      itemsPerRow = 2;
    } else if (width >= 600 && width < 770) {
      itemsPerRow = 4;
    } else if (width >= 770 && width < 1000) {
      itemsPerRow = 6;
    }

    return infinite && children.length > rows * itemsPerRow;
  }, [infinite, rows, children, width]);

  return (
    <Container>
      <Slider
        rows={rows}
        asNavFor={asNavFor}
        infinite={isInfinite}
        ref={ref && (slider => (ref.current = slider))}
        {...settings}
      >
        {children}
      </Slider>
    </Container>
  );
}
