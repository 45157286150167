// @flow
import React, { useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  stringify,
  encodeQueryParams,
} from 'use-query-params';
import { useRecoilValue, useRecoilState } from 'recoil';
import { getFilterQuery, searchAlgolia } from '../../../../../../../api/algolia/search';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import type {
  AlgoliaSearchResults,
  AlgoliaFacetResults,
  AlgoliaFacetStats,
  AppliedAlgoliaFilters,
} from '../../../../../../../api/algolia/search';
import {
  getSongID,
  isSongFullMix,
  isSongQueryFullMatch,
  isSongStem,
} from '../../../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import {
  getAlgoliaKeyword,
  getAlgoliaQueryKeywords,
  getAlgoliaReplacementKeywords,
  getVisibleResultsKeywords,
} from '../../../../../../../api/algolia/magicKeywords';
import { sortSongResults } from '../../../../../../../utils/sorting';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useUserHiddenTracks } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { getHiddenCountMap } from '../../../../../../../utils/hiddenSongs';
import {
  getFilterQueryParams,
  getInitialFiltersApplied,
} from '../../../../../../../utils/filterParams';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelApplyFilter,
  analyticsMixpanelInexactSearch,
  analyticsMixpanelRemoveFilter,
  analyticsMixpanelRemoveKeyword,
  analyticsMixpanelSearchKeywords,
  getMixpanelCombinedKeywordsList,
  getMixpanelPartsBreakdown,
  getMixpanelRangeValue,
} from '../../../../../../../analytics/mixpanel';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import {
  FILTER_MENU_TYPES,
  FILTER_TYPES,
} from '../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/data';
import { getGroupedBooleanFilters } from '../../../../../../../api/algolia/data';
import { isGroupApplied } from '../../../../../ResultsScreen/components/ResultsView/components/ResultsAside/components/ResultsFilters/components/FilterBooleanMenu/FilterBooleanMenu';
import { useUpdateProfileKeywordCount } from '../../../../../../../api/firebase/user/keywordCount';
import { searchAlgoliaCollections } from '../../../../../../../api/algolia/collections';
import type { AlgoliaCollectionResults } from '../../../../../../../api/algolia/collections';
import {
  searchEditModeAtom,
  searchTriggerAtom,
} from '../../../../../../../store/mixpanelAnalytics';
import { ROUTES } from '../../../../../../routes';
import { useNavigate } from '../../../../../../hooks';

export const getSearchQueryParams = (keywords: Array<string>): string => {
  const encodedQuery = encodeQueryParams({ q: ArrayParam }, { q: keywords });
  return stringify(encodedQuery);
};

export const getMagicSearchQueryParams = (magicKeywords: Array<string>): string => {
  const encodedMagicQuery = encodeQueryParams({ mq: ArrayParam }, { mq: magicKeywords });
  return stringify(encodedMagicQuery);
};

export const useSearchQuery = () => {
  return useQueryParam('q', ArrayParam);
};

export const useMagicSearchQuery = () => {
  return useQueryParam('mq', ArrayParam);
};

export const useYouTubeSearchQuery = () => {
  return useQueryParam('ytq', ArrayParam);
};

export const useYouTubeVideoQuery = () => {
  return useQueryParam('videoTitle', ArrayParam);
};

export const useYouTubeVideoIdQuery = () => {
  return useQueryParam('videoId', ArrayParam);
};

export const useYouTubeChannelQuery = () => {
  return useQueryParam('channelTitle', ArrayParam);
};

export const useYouTubeChannelIdQuery = () => {
  return useQueryParam('channelId', ArrayParam);
};

export const useBpmQuery = () => {
  return useQueryParam('bpm', StringParam);
};

export const useEditStyleQuery = () => {
  return useQueryParam('edit_style', ArrayParam);
};

export const useEnergyQuery = () => {
  return useQueryParam('energy', ArrayParam);
};

export const useGenreQuery = () => {
  return useQueryParam('genre', ArrayParam);
};

export const useInstrumentsQuery = () => {
  return useQueryParam('instruments', ArrayParam);
};

export const useLengthQuery = () => {
  return useQueryParam('length', StringParam);
};

export const useLocationQuery = () => {
  return useQueryParam('location', ArrayParam);
};

export const useMoodQuery = () => {
  return useQueryParam('mood', ArrayParam);
};

export const useMovementQuery = () => {
  return useQueryParam('movement', ArrayParam);
};

export const useVideoThemeQuery = () => {
  return useQueryParam('video_theme', ArrayParam);
};

export const useSfxTypeQuery = () => {
  return useQueryParam('sfx_type', ArrayParam);
};

export const useSfxFeatureQuery = () => {
  return useQueryParam('sfx_feature', ArrayParam);
};

export const useViewingQuery = () => {
  return useQueryParam('viewing', StringParam);
};

export const getFilterSearchQueryParams = (
  genres: Array<string>,
  moods: Array<string>,
  instruments: Array<string>,
  energy: Array<string>,
  bpm: string,
  length: string,
  sfxType: Array<string>,
  sfxFeature: Array<string>
): string => {
  const paramConfig = {
    genre: ArrayParam,
    mood: ArrayParam,
    instruments: ArrayParam,
    energy: ArrayParam,
    sfx_type: ArrayParam,
    sfx_feature: ArrayParam,
  };

  const paramValues = {
    genre: genres,
    mood: moods,
    instruments,
    energy,
    sfx_type: sfxType,
    sfx_feature: sfxFeature,
  };

  if (bpm) {
    paramConfig.bpm = StringParam;
    paramValues.bpm = bpm;
  }

  if (length) {
    paramConfig.length = StringParam;
    paramValues.length = length;
  }

  const encodedMagicQuery = encodeQueryParams(paramConfig, paramValues);
  return stringify(encodedMagicQuery);
};

const sendResetFiltersMixpanelEvents = (
  mixpanel,
  moengage,
  currentFilters,
  filterKey: string,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  userRole: string,
  locale: string
) => {
  if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterSelect) {
    const includedFilters = currentFilters[filterKey].included;
    const excludedFilters = currentFilters[filterKey].excluded;

    includedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Included',
        userRole
      );
    });

    excludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Excluded',
        userRole
      );
    });
  } else if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterRange) {
    const mixpanelValue = getMixpanelRangeValue(
      filterKey,
      currentFilters[filterKey].min,
      currentFilters[filterKey].max
    );
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      mixpanelValue,
      keywords,
      magicKeywords,
      youtubeKeywords,
      'Included',
      userRole
    );
  } else if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterBoolean) {
    const groupedFiltersList = getGroupedBooleanFilters(locale, filterKey);
    const groupLabel = Object.keys(groupedFiltersList)[0];
    const groupedValues = groupedFiltersList[groupLabel];
    const includedFilters = currentFilters[filterKey].included;
    const excludedFilters = currentFilters[filterKey].excluded;

    const isGroupIncluded = isGroupApplied(includedFilters, groupedValues);
    const isGroupExcluded = isGroupApplied(excludedFilters, groupedValues);

    const groupedIncludedFilters = isGroupIncluded
      ? includedFilters.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
      : includedFilters;
    const groupedExcludedFilters = isGroupExcluded
      ? excludedFilters.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
      : excludedFilters;

    groupedIncludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Included',
        userRole
      );
    });

    groupedExcludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Excluded',
        userRole
      );
    });
  }
};

const sendClearFiltersMixpanelEvents = (
  mixpanel: any,
  moengage: analyticsMixpanelSearchKeywords,
  appliedFilters: any,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  userRole: string,
  locale: string
) => {
  Object.keys(appliedFilters).forEach(currentFilter => {
    sendResetFiltersMixpanelEvents(
      mixpanel,
      moengage,
      appliedFilters,
      currentFilter,
      keywords,
      magicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
  });
};

export type ResultsManagerContextState = {
  loading: boolean,
  loaded: boolean,
  initialSearch: boolean,
  emptySearch: boolean,
  noSongResults: boolean,
  noResults: boolean,
  displayKeywords: Array<string>,
  algoliaKeywords: Array<string>,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  collectionResultIDs: Array<string>,
  youtubeVideoTitle: string,
  youtubeVideoId: string,
  youtubeChannelTitle: string,
  youtubeChannelId: string,
  mixpanelSearchKeywords: Array<string>,
  setKeywordsQuery: (Array<string>) => void,
  setMagicQuery: (Array<string>) => void,
  handleRemoveYouTubeKeyword: () => void,
  allSongs: Array<AlgoliaSongMdl>,
  fullMixSongs: Array<AlgoliaSongMdl>,
  stemSongs: Array<AlgoliaSongMdl>,
  resultFacets: AlgoliaFacetResults,
  completeResultFacets: any,
  resultFacetStats: AlgoliaFacetStats,
  appliedFilters: AppliedAlgoliaFilters,
  browseSearchSlug: string,
  handleAddSongsFilter: (string, string, string, boolean) => void,
  handleRemoveSongsFilter: (string, string, string) => void,
  swapSongsFilter: (string, string, string, string, boolean) => void,
  handleClearSongFilters: () => void,
  resetSongsFilter: string => void,
  addFilterGroup: (string, Array<string>, string) => void,
  removeFilterGroup: (string, Array<string>, string) => void,
  swapFilterGroup: (string, Array<string>, string, string, string) => void,
  addRangeSongsFilter: (string, number, number) => void,
  hiddenTracksCount: number,
  hiddenTracksMap: { [string]: number },
  showHiddenSongs: boolean,
  handleShowHiddenSongs: () => void,
  viewingResults: string,
  handleSectionChange: string => void,
  sortingMethod: string,
  handleSort: any => void,
  handleResetSearch: () => void,
};

type Props = {
  children: any,
};

export const ResultsManagerContext = React.createContext<any>();

export const useResultsManagerContext = (): ResultsManagerContextState => {
  return useContext(ResultsManagerContext);
};

const ResultsManager = ({ children }: Props) => {
  // Other Hooks
  const locale = useLocale();
  const { userRole } = useSubscriptionsContext();
  const navigate = useNavigate();

  const [resultsSearchTriggerLocation, setResultsSearchTriggerLocation] = useRecoilState(
    searchTriggerAtom
  );
  const resultsSearchIsEditMode = useRecoilValue(searchEditModeAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userID = useUserId();

  // Search State
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [initialSearch, setInitialSearch] = useState(true);

  // URL params
  const [keywordsQuery, setKeywordsQuery] = useSearchQuery();
  const [magicQuery, setMagicQuery] = useMagicSearchQuery();
  const [youtubeQuery, setYouTubeQuery] = useYouTubeSearchQuery();
  const [youtubeVideoTitle, setYouTubeVideoTitle] = useYouTubeVideoQuery();
  const [youtubeVideoId, setYouTubeVideoId] = useYouTubeVideoIdQuery();
  const [youtubeChannelTitle, setYouTubeChannelTitle] = useYouTubeChannelQuery();
  const [youtubeChannelId, setYouTubeChannelId] = useYouTubeChannelIdQuery();
  const emptySearch = !keywordsQuery && !magicQuery && !youtubeQuery;

  // Search Query Data
  const [keywords, setKeywords] = useState([]);
  const [magicKeywords, setMagicKeywords] = useState([]);
  const [algoliaKeywords, setAlgoliaKeywords] = useState([]);
  const [displayKeywords, setDisplayKeywords] = useState([]);
  const [youtubeKeywords, setYouTubeKeywords] = useState([]);
  const [collectionResultIDs, setCollectionResultIDs] = useState([]);

  // Filter Query Data
  const [bpmParams, setBpmParams] = useBpmQuery();
  const [editStyleParams, setEditStyleParams] = useEditStyleQuery();
  const [energyParams, setEnergyParams] = useEnergyQuery();
  const [genreParams, setGenreParams] = useGenreQuery();
  const [instrumentParams, setInstrumentsParams] = useInstrumentsQuery();
  const [lengthParams, setLengthParams] = useLengthQuery();
  const [locationParams, setLocationParams] = useLocationQuery();
  const [moodParams, setMoodParams] = useMoodQuery();
  const [movementParams, setMovementParams] = useMovementQuery();
  const [videoThemeParams, setVideoThemeParams] = useVideoThemeQuery();
  const [sfxTypeParams, setSfxTypeParams] = useSfxTypeQuery();
  const [sfxFeatureParams, setSfxFeatureParams] = useSfxFeatureQuery();
  const [viewingParam, setViewingParam] = useViewingQuery();
  const initialFiltersApplied = getInitialFiltersApplied(
    bpmParams,
    editStyleParams,
    energyParams,
    genreParams,
    instrumentParams,
    lengthParams,
    locationParams,
    moodParams,
    movementParams,
    videoThemeParams,
    sfxTypeParams,
    sfxFeatureParams
  );
  const [browseSearchSlug, setBrowseSearchSlug] = useState('');

  // Filter Results Data
  const [resultFacets, setResultFacets] = useState({});
  const [appliedFilters, setAppliedFilters] = useState(initialFiltersApplied);
  const [resultFacetStats, setResultFacetStats] = useState({});
  const [completeResultFacets, setCompleteResultFacets] = useState({
    keywords: '',
    facets: {},
    facetStats: {},
    userRole,
  });

  // Song Results Data
  const [allSongs, setAllSongs] = useState([]);
  const [fullMixSongs, setFullMixSongs] = useState([]);
  const [stemSongs, setStemSongs] = useState([]);

  const noSongResults = allSongs.length < 1;
  const noCollectionResults = collectionResultIDs.length < 1;
  const noResults = noSongResults && noCollectionResults;

  // Results Browsing Cofig
  const [viewingResults, setViewingResults] = useState(viewingParam || 'all');
  const [sortingMethod, setSortingMethod] = useState('newest');

  // User Data
  const [showHiddenSongs, setShowHiddenSongs] = useState(false);
  const userHiddenSongs = useUserHiddenTracks();
  const [hiddenTracksCount, setHiddenTracksCount] = useState(0);
  const [hiddenTracksMap, setHiddenTracksMap] = useState({});

  // Analytics Data
  const mixpanelMagicKeywords = displayKeywords.filter(
    displayKeyword => !keywords.includes(displayKeyword)
  );

  const mixpanelSearchKeywords = getMixpanelCombinedKeywordsList(
    keywords,
    magicKeywords,
    youtubeKeywords
  );

  const [handleUpdateProfileKeywordCount] = useUpdateProfileKeywordCount(mixpanelSearchKeywords);

  const handleAlgoliaSearch = async () => {
    if (algoliaKeywords ? algoliaKeywords.length < 1 : false) {
      setAppliedFilters({});
    }

    setLoading(true);
    const filterQuery = getFilterQuery(appliedFilters);
    const collectionKeywords = youtubeVideoTitle
      ? [...algoliaKeywords, youtubeVideoTitle[0]]
      : algoliaKeywords;

    await searchAlgoliaCollections(collectionKeywords.join(', '), '', 100, locale).then(
      (response: AlgoliaCollectionResults) => {
        const collectionIds =
          response.hits.length > 0
            ? response.hits.map(collectionResult => {
                return collectionResult.collectionSlug;
              })
            : [];
        setCollectionResultIDs(collectionIds);
      }
    );

    await searchAlgolia(algoliaKeywords.join(', '), locale, [], filterQuery).then(
      (response: AlgoliaSearchResults) => {
        if (response.hits.length < 1) {
          setAllSongs([]);
          setResultFacets({});
          setResultFacetStats({});
          setInitialSearch(false);
          setLoaded(true);
          setLoading(false);
          const partsCountBreakdown = getMixpanelPartsBreakdown(null);
          analyticsMixpanelSearchKeywords(
            mixpanel,
            moengage,
            mixpanelSearchKeywords,
            mixpanelMagicKeywords,
            youtubeKeywords,
            '',
            0,
            0,
            0,
            partsCountBreakdown,
            resultsSearchIsEditMode,
            resultsSearchTriggerLocation,
            appliedFilters,
            userRole,
            userID,
            locale
          );
        }
        const songs = sortSongResults(response.hits, sortingMethod);

        if (algoliaKeywords.join(', ') !== completeResultFacets.keywords) {
          if (
            resultsSearchTriggerLocation === 'search' ||
            resultsSearchTriggerLocation === 'tagSearch' ||
            resultsSearchTriggerLocation === 'extension' ||
            resultsSearchTriggerLocation === 'collectionTag'
          ) {
            handleUpdateProfileKeywordCount();
          }
          setCompleteResultFacets({
            keywords: algoliaKeywords.sort().join(', '),
            facets: response.facets,
            facetStats: response.facets_stats,
            userRole,
          });
        }

        const searchIsFullMatch = isSongQueryFullMatch(response.hits[0], algoliaKeywords);

        if (!searchIsFullMatch) {
          analyticsMixpanelInexactSearch(mixpanel, moengage, algoliaKeywords);
        }

        setAllSongs(songs);
        setResultFacets(response.facets);
        setResultFacetStats(response.facets_stats);
        setInitialSearch(false);

        const fullMixes = songs.filter(song => isSongFullMix(song));
        const stems = songs.filter(song => isSongStem(song));
        const partsCountBreakdown = getMixpanelPartsBreakdown(stems);
        analyticsMixpanelSearchKeywords(
          mixpanel,
          moengage,
          mixpanelSearchKeywords,
          mixpanelMagicKeywords,
          youtubeKeywords,
          '',
          songs.length,
          fullMixes.length,
          stems.length,
          partsCountBreakdown,
          resultsSearchIsEditMode,
          resultsSearchTriggerLocation,
          appliedFilters,
          userRole,
          userID,
          locale
        );
      }
    );
  };

  const handleSort = event => {
    setSortingMethod(event.target.value);
  };

  const handleAddSongsFilter = (
    filterKey: string,
    filterValue: string,
    type: string,
    isSuggestion: boolean,
    searchTerm: string
  ) => {
    const appliedFilter = get(appliedFilters, filterKey, null);
    setResultsSearchTriggerLocation('filters');
    if (!appliedFilter) {
      const filterKind = { included: [], excluded: [] };
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...filterKind,
          [type]: [filterValue],
        },
      };
      setAppliedFilters(updatedAppliedFilters);
    } else {
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...appliedFilter,
          [type]: appliedFilter[type].concat([filterValue]),
        },
      };
      setAppliedFilters(updatedAppliedFilters);
    }
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterValue,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      isSuggestion,
      searchTerm,
      userRole,
      userID
    );
  };

  const handleRemoveSongsFilter = (filterKey: string, filterValue: string, type: string) => {
    const filtersToChange = appliedFilters[filterKey][type];
    const updatedFilterValues = filtersToChange.filter(currentFilter => {
      return currentFilter !== filterValue;
    });
    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [type]: updatedFilterValues,
      },
    };

    if (
      updatedAppliedFilters[filterKey].included.length <= 0 &&
      updatedAppliedFilters[filterKey].excluded.length <= 0
    ) {
      delete updatedAppliedFilters[filterKey];
    }
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterValue,
      mixpanelSearchKeywords,
      magicKeywords,
      youtubeKeywords,
      type,
      userRole
    );
  };

  const swapSongsFilter = (
    filterKey: string,
    filterValue: string,
    removeType: string,
    addType: string,
    isSuggestion: boolean,
    searchTerm: string
  ) => {
    const filtersToChange = appliedFilters[filterKey];
    const filtersWithRemovedFilter = filtersToChange[removeType].filter(currentFilter => {
      return currentFilter !== filterValue;
    });
    const filtersWithAddedFilter = filtersToChange[addType].concat([filterValue]);

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        [removeType]: filtersWithRemovedFilter,
        [addType]: filtersWithAddedFilter,
      },
    };
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterValue,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      removeType,
      userRole
    );
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterValue,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      addType,
      isSuggestion,
      searchTerm,
      userRole,
      userID
    );
  };

  const resetSongsFilter = (filterKey: string) => {
    const updatedAppliedFilters = {
      ...appliedFilters,
    };
    setResultsSearchTriggerLocation('filters');
    sendResetFiltersMixpanelEvents(
      mixpanel,
      moengage,
      updatedAppliedFilters,
      filterKey,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
    delete updatedAppliedFilters[filterKey];
    setAppliedFilters(updatedAppliedFilters);
  };

  const addFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    type: string
  ) => {
    const appliedFilter = get(appliedFilters, filterKey, null);
    setResultsSearchTriggerLocation('filters');

    if (!appliedFilter) {
      const filterKind = { included: [], excluded: [] };
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...filterKind,
          [type]: filterValues,
        },
      };
      setAppliedFilters(updatedAppliedFilters);
    } else {
      filterValues.forEach(filterValue => {
        if (!appliedFilter[type].includes(filterValue)) {
          appliedFilter[type].push(filterValue);
        }
      });
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...appliedFilter,
          [type]: appliedFilter[type],
        },
      };
      setAppliedFilters(updatedAppliedFilters);
    }

    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      false,
      '',
      userRole,
      userID
    );
  };

  const removeFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    type: string
  ) => {
    const filtersToChange = appliedFilters[filterKey][type];
    const updatedFilterValues = filtersToChange.filter(currentFilter => {
      return !filterValues.includes(currentFilter);
    });

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [type]: updatedFilterValues,
      },
    };

    if (
      updatedAppliedFilters[filterKey].included.length <= 0 &&
      updatedAppliedFilters[filterKey].excluded.length <= 0
    ) {
      delete updatedAppliedFilters[filterKey];
    }
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      userRole
    );
  };

  const swapFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    removeType: string,
    addType: string
  ) => {
    const filtersToChange = appliedFilters[filterKey];

    const updatedAddedValues = filtersToChange[addType].concat(filterValues);
    const updatedRemovedValues = filtersToChange[removeType].filter(currentFilter => {
      return !filterValues.includes(currentFilter);
    });

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [addType]: updatedAddedValues,
        [removeType]: updatedRemovedValues,
      },
    };
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      removeType,
      userRole
    );
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      addType,
      false,
      '',
      userRole,
      userID
    );
  };

  const handleClearSongFilters = (origin: string) => {
    if (origin) {
      setResultsSearchTriggerLocation(origin);
    } else {
      setResultsSearchTriggerLocation('filters');
    }
    sendClearFiltersMixpanelEvents(
      mixpanel,
      moengage,
      appliedFilters,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
    setAppliedFilters({});
    setShowHiddenSongs(false);
  };

  const handleRemoveYouTubeKeyword = () => {
    setResultsSearchTriggerLocation('removeKeyword');
    analyticsMixpanelRemoveKeyword(
      mixpanel,
      moengage,
      youtubeVideoTitle,
      'youtube',
      youtubeKeywords
    );
    setYouTubeQuery([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');
  };

  const handleShowHiddenSongs = () => {
    setShowHiddenSongs(!showHiddenSongs);
  };

  const handleResetSearch = () => {
    displayKeywords.forEach(keyword => {
      const isMagicKeyword = !!keywords.includes(keyword);
      const keywordType = isMagicKeyword ? 'magic' : 'regular';
      const keywordReplacements = isMagicKeyword
        ? magicKeywords
            .filter(magicKeyword => {
              return getAlgoliaKeyword(magicKeyword) === keyword;
            })
            .map(filteredMagicKeyword => {
              return getAlgoliaReplacementKeywords(filteredMagicKeyword) === keyword;
            })
        : [];
      analyticsMixpanelRemoveKeyword(
        mixpanel,
        moengage,
        keyword,
        keywordType,
        keywordReplacements,
        false,
        'category'
      );
    });

    if (youtubeKeywords.length > 0) {
      analyticsMixpanelRemoveKeyword(
        mixpanel,
        moengage,
        youtubeVideoTitle,
        'youtube',
        youtubeKeywords,
        false,
        'category'
      );
    }

    setAllSongs([]);
    setFullMixSongs([]);
    setStemSongs([]);
    setCollectionResultIDs([]);
    setKeywordsQuery([]);
    setMagicQuery([]);
    setYouTubeQuery([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');
    setKeywords([]);
    setMagicKeywords([]);
    setYouTubeKeywords([]);
    setAlgoliaKeywords([]);
    setDisplayKeywords([]);
    setAppliedFilters({});
    setResultFacets({});
    setResultFacetStats({});
    setCompleteResultFacets({
      keywords: '',
      facets: {},
      facetStats: {},
      userRole,
    });
    setViewingResults('all');
    setSortingMethod('newest');
  };

  const addRangeSongsFilter = (filterKey: string, max: number, min: number) => {
    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        min,
        max,
      },
    };
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    const mixpanelRangeValue = getMixpanelRangeValue(filterKey, min, max);
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      mixpanelRangeValue,
      min,
      max,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      'Included',
      false,
      '',
      userRole,
      userID
    );
  };

  const handlelistPlaybackLink = () => {
    const keywordsPath = getSearchQueryParams(keywords);
    const magicKeywordsPath = getMagicSearchQueryParams(magicKeywords);

    const {
      bpmFilterParams,
      energyFilterParams,
      genreFilterParams,
      instrumentsFilterParams,
      lengthFilterParams,
      moodFilterParams,
      sfxTypeFilterParams,
      sfxFeatureFilterParams,
    } = getFilterQueryParams(appliedFilters);

    const filterQuery = getFilterSearchQueryParams(
      genreFilterParams,
      moodFilterParams,
      instrumentsFilterParams,
      energyFilterParams,
      bpmFilterParams,
      lengthFilterParams,
      sfxTypeFilterParams,
      sfxFeatureFilterParams
    );
    let combinedPath = '';

    if (keywordsPath) {
      combinedPath = combinedPath.concat(keywordsPath);
    }

    if (magicKeywordsPath) {
      combinedPath = combinedPath
        ? combinedPath.concat(`&${magicKeywordsPath}`)
        : combinedPath.concat(magicKeywordsPath);
    }

    if (filterQuery) {
      combinedPath = combinedPath
        ? combinedPath.concat(`&${filterQuery}`)
        : combinedPath.concat(filterQuery);
    }

    if (viewingResults !== 'all') {
      combinedPath = combinedPath
        ? combinedPath.concat(`&viewing=${viewingResults}`)
        : combinedPath.concat(filterQuery);
    }

    setBrowseSearchSlug(combinedPath);
  };

  const handleSectionChange = (section: string) => {
    if (section === 'all') {
      navigate(
        ROUTES.musicSearch.navigatePath({
          keywords: keywordsQuery,
          magicKeywords: magicQuery,
          ytKeywords: youtubeQuery,
          channelId: youtubeChannelId,
          channelTitle: youtubeChannelTitle,
          videoId: youtubeVideoId,
          videoTitle: youtubeVideoTitle,
        })
      );
    } else {
      navigate(
        ROUTES.musicSearch.navigatePath({
          keywords: keywordsQuery,
          magicKeywords: magicQuery,
          ytKeywords: youtubeQuery,
          channelId: youtubeChannelId,
          channelTitle: youtubeChannelTitle,
          videoId: youtubeVideoId,
          videoTitle: youtubeVideoTitle,
          section,
        })
      );
    }
  };

  useEffect(() => {
    const queryKeywords = keywordsQuery && keywordsQuery.length > 0 ? keywordsQuery : [];
    const queryMagicKeywords = magicQuery && magicQuery.length > 0 ? magicQuery : [];
    const queryYouTubeKeywords = youtubeQuery && youtubeQuery.length > 0 ? youtubeQuery : [];

    if (queryKeywords.length + queryMagicKeywords.length + queryYouTubeKeywords.length < 0) {
      setKeywords([]);
      setMagicKeywords([]);
      setYouTubeKeywords([]);
      setAlgoliaKeywords([]);
      setDisplayKeywords([]);
      return;
    }

    const newDisplayKeywords = getVisibleResultsKeywords(
      queryKeywords || [],
      queryMagicKeywords || []
    );

    const newAlgoliaKeywrods = getAlgoliaQueryKeywords(
      queryKeywords,
      queryMagicKeywords,
      queryYouTubeKeywords
    ).sort();

    setKeywords(queryKeywords);
    setMagicKeywords(queryMagicKeywords);
    setYouTubeKeywords(queryYouTubeKeywords);
    setAlgoliaKeywords(newAlgoliaKeywrods);
    setDisplayKeywords(newDisplayKeywords);
  }, [
    keywordsQuery ? keywordsQuery.join(',') : '',
    magicQuery ? magicQuery.join(',') : '',
    youtubeQuery ? youtubeQuery.join(',') : '',
  ]);

  useEffect(() => {
    if (allSongs.length < 1) {
      setFullMixSongs([]);
      setStemSongs([]);
      setHiddenTracksCount(0);
      setHiddenTracksMap({});
      if (!initialSearch) {
        setLoaded(true);
        setLoading(false);
      }
      return;
    }
    const activeSongs = showHiddenSongs
      ? allSongs
      : allSongs.filter(song => {
          return !Object.keys(userHiddenSongs).includes(getSongID(song));
        });

    const newSongOrder = sortSongResults(activeSongs, sortingMethod);
    const newFullMixOrder = newSongOrder.filter(song => isSongFullMix(song));
    const newStemsOorder = newSongOrder.filter(song => isSongStem(song));

    const hiddenResultsCount = allSongs.filter(song => {
      return Object.keys(userHiddenSongs).includes(getSongID(song));
    }).length;

    const activeSongIDs = allSongs.map(activeSong => {
      return getSongID(activeSong);
    });

    const activeHiddenSongIDs = Object.keys(userHiddenSongs).filter(hiddenId => {
      return activeSongIDs.includes(hiddenId);
    });

    const activeHiddenSongs = {};

    activeHiddenSongIDs.forEach(
      // eslint-disable-next-line no-return-assign
      activeHiddenSongID =>
        (activeHiddenSongs[activeHiddenSongID] = userHiddenSongs[activeHiddenSongID])
    );
    const hiddenTrackCountMap = getHiddenCountMap(showHiddenSongs ? {} : activeHiddenSongs);

    setFullMixSongs(newFullMixOrder);
    setStemSongs(newStemsOorder);
    setHiddenTracksCount(hiddenResultsCount);
    setHiddenTracksMap(hiddenTrackCountMap);

    setLoaded(true);
    setLoading(false);
  }, [allSongs, sortingMethod, showHiddenSongs, userHiddenSongs]);

  useEffect(() => {
    if (algoliaKeywords.length > 0) {
      handleAlgoliaSearch();
      handlelistPlaybackLink();
    } else {
      setAllSongs([]);
      setFullMixSongs([]);
      setStemSongs([]);
      setCollectionResultIDs([]);
      setResultFacets({});
      setResultFacetStats({});
      setCompleteResultFacets({
        keywords: '',
        facets: {},
        facetStats: {},
        userRole,
      });
      setHiddenTracksCount(0);
      setHiddenTracksMap({});
      setSortingMethod('newest');
      setLoaded(true);
      setLoading(false);
      if (!initialSearch) {
        setViewingResults('all');
      }
    }
  }, [
    algoliaKeywords ? algoliaKeywords.join(',') : '',
    displayKeywords ? displayKeywords.join(',') : '',
    appliedFilters,
  ]);

  useEffect(() => {
    const {
      bpmFilterParams,
      editStyleFilterParams,
      energyFilterParams,
      genreFilterParams,
      instrumentsFilterParams,
      lengthFilterParams,
      locationFilterParams,
      moodFilterParams,
      movementFilterParams,
      videoThemeFilterParams,
      sfxTypeFilterParams,
      sfxFeatureFilterParams,
    } = getFilterQueryParams(appliedFilters);

    setBpmParams(bpmFilterParams);
    setEditStyleParams(editStyleFilterParams);
    setEnergyParams(energyFilterParams);
    setGenreParams(genreFilterParams);
    setInstrumentsParams(instrumentsFilterParams);
    setLengthParams(lengthFilterParams);
    setLocationParams(locationFilterParams);
    setMoodParams(moodFilterParams);
    setMovementParams(movementFilterParams);
    setVideoThemeParams(videoThemeFilterParams);
    setSfxTypeParams(sfxTypeFilterParams);
    setSfxFeatureParams(sfxFeatureFilterParams);
  }, [appliedFilters]);

  useEffect(() => {
    handlelistPlaybackLink();
  }, [viewingResults]);

  useEffect(() => {
    if (viewingParam && viewingParam !== 'all') {
      setViewingResults(viewingParam);
    } else {
      setViewingResults('all');
    }
  }, [viewingParam]);

  return (
    <ResultsManagerContext.Provider
      value={{
        loading,
        loaded,
        initialSearch,
        emptySearch,
        noSongResults,
        noResults,
        displayKeywords,
        algoliaKeywords,
        keywords,
        magicKeywords,
        youtubeKeywords,
        collectionResultIDs,
        youtubeVideoTitle,
        youtubeVideoId,
        youtubeChannelTitle,
        youtubeChannelId,
        mixpanelSearchKeywords,
        setKeywordsQuery,
        setMagicQuery,
        handleRemoveYouTubeKeyword,
        allSongs,
        fullMixSongs,
        stemSongs,
        resultFacets,
        completeResultFacets,
        resultFacetStats,
        appliedFilters,
        browseSearchSlug,
        handleAddSongsFilter,
        handleRemoveSongsFilter,
        swapSongsFilter,
        resetSongsFilter,
        handleClearSongFilters,
        addFilterGroup,
        removeFilterGroup,
        swapFilterGroup,
        addRangeSongsFilter,
        setHiddenTracksCount,
        showHiddenSongs,
        handleShowHiddenSongs,
        hiddenTracksCount,
        hiddenTracksMap,
        viewingResults,
        handleSectionChange,
        sortingMethod,
        handleSort,
        handleResetSearch,
      }}
    >
      {children}
    </ResultsManagerContext.Provider>
  );
};

export default ResultsManager;
