import React, { useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Slider } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import 'react-input-range/lib/css/index.css';
import { useSong } from '../../../../SongContextWrapper/SongContextWrapper';
import { getSongDuration, getSongFamily } from '../../../../../../api/algolia/song';
import { lightColor } from '../../../../../../styles/config/colors';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../../styles/responsive';
import { useGlobalAudioPlaybackContext } from '../../GlobalAudioPlayback/GlobalAudioPlayback';
import { useGradient } from '../../../../../../utils/gradient';
import { songProgressAtom } from '../../../../../../store/globalPlayer';

const sliderRoot = css`
  color: transparent;
  width: 100%;
  height: 5px;
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: content-box;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
`;

const inputRangeTrack = css`
  cursor: pointer;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;
  width: 100%;
  height: 5px;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 1px;
`;

const inputRangeTrackActive = (gradient: string) => {
  return css`
    background: ${gradient};
    cursor: pointer;
    height: 5px;
    display: block;
    position: absolute;
    border-radius: 1px;
  `;
};

const sliderThumb = css`
  display: flex;
  outline: 0;
  position: absolute;
  top: 1px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  border: 6px solid ${lightColor};

  &::after {
    visibility: hidden;
  }

  &:last-of-type {
    ${SMALL_DESKTOP_BREAKPOINT} {
      left: 25px;
    }
`;

const RangeInputContainer = styled('div')`
  width: 100%;
  height: 5px;
  position: relative;

  & .sliderRoot {
    ${sliderRoot}
  }

  & .sliderRail {
    ${inputRangeTrack};
  }

  & .sliderTrack {
    ${props => inputRangeTrackActive(props.gradient)};
  }

  & .sliderThumb {
    ${sliderThumb};
  }
`;

const getCssGradient = (colors: Array<string>): string => {
  let gradient = '';
  const mappedColors = colors.map((color, index) => {
    return `${color} ${Math.round((index / colors.length) * 100, 2)}%`;
  });

  mappedColors.forEach((mappedColour, index) => {
    if (index > 0) {
      gradient = gradient.concat(`, ${mappedColour}`);
    } else {
      gradient = gradient.concat(mappedColour);
    }
  });
  gradient = `linear-gradient(90deg, `.concat(gradient).concat(`);`);
  return gradient;
};

const SongProgressSlider = () => {
  const songProgress = useRecoilValue(songProgressAtom);
  const { onSeek, microparts } = useGlobalAudioPlaybackContext();
  const song = useSong();
  const duration = getSongDuration(song);

  const gradient = useGradient(getSongFamily(song), microparts.length || 7);

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [updateValue, setUpdateValue] = useState(0);

  const minValue = 0;
  const maxValue = duration;
  const selectedRange = changeInProgress ? updateValue : songProgress || 0;

  // const progressPercent = songProgress / maxValue;
  // const coloursToInclude = Math.ceil(gradient.length * progressPercent) + 1 || 2;
  const coloursToInclude = gradient.length;
  const usableGradientColors = gradient.filter((color, index) => {
    return index < coloursToInclude;
  });

  const handleSeek = (value: number) => {
    const updatedProgressPercent = value / maxValue;
    onSeek(updatedProgressPercent, () => {
      setChangeInProgress(false);
    });
  };

  return (
    <RangeInputContainer gradient={getCssGradient(usableGradientColors)}>
      <Slider
        value={selectedRange}
        max={maxValue}
        min={minValue}
        onChange={(event, value) => {
          setUpdateValue(value);
          setChangeInProgress(true);
        }}
        onChangeCommitted={async (event, value) => {
          await handleSeek(value);
        }}
        valueLabelDisplay="off"
        aria-labelledby="range-slider"
        orientation="horizontal"
        classes={{
          root: 'sliderRoot',
          rail: 'sliderRail',
          track: 'sliderTrack',
          thumb: 'sliderThumb',
        }}
      />
    </RangeInputContainer>
  );
};

export default SongProgressSlider;
