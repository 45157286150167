import React from 'react';
import BrowseSongsManager from '../../../../../song/components/GlobalSongPlayer/components/BrowseSongsManager/BrowseSongsManager';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';

type Props = {
  children: any,
  songs: Array<AlgoliaSongMdl>,
  songListType: string,
  songListTitle: string,
  songListSlug: string,
  songlistKeywords: Array<string>,
  queueRestricted: boolean,
  restrictionType: string,
  keywords: Array<string>,
  mixpanelKeywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  youtubeVideoTitle: string,
  sectionOrigin: string,
  relatedCollection: string,
};

const TabbedSongListWrapper = ({
  children,
  songs,
  songListType,
  songListTitle,
  songListSlug,
  songlistKeywords,
  queueRestricted,
  restrictionType,
  keywords,
  mixpanelKeywords,
  magicKeywords,
  youtubeKeywords,
  youtubeVideoTitle,
  sectionOrigin,
  relatedCollection,
}: Props) => {
  return (
    <BrowseSongsManager
      songs={songs}
      songListType={songListType}
      songListTitle={songListTitle}
      songListSlug={songListSlug}
      songlistKeywords={songlistKeywords}
      queueRestricted={queueRestricted}
      restrictionType={restrictionType}
      keywords={keywords}
      mixpanelKeywords={mixpanelKeywords}
      magicKeywords={magicKeywords}
      youtubeKeywords={youtubeKeywords}
      youtubeVideoTitle={youtubeVideoTitle}
      sectionOrigin={sectionOrigin}
      relatedCollection={relatedCollection}
    >
      {children}
    </BrowseSongsManager>
  );
};

export default TabbedSongListWrapper;
