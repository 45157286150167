// @flow
import React, { useContext } from 'react';
import { get } from 'lodash';

type Props = {
  children: any,
  keywords?: Array<string>,
  mixpanelKeywords?: Array<string>,
  magicKeywords?: Array<string>,
  youtubeKeywords?: Array<string>,
  youtubeVideoTitle?: string,
  sectionOrigin?: string,
  relatedCollection?: string,
  location: string,
  curatedCollection?: string,
  artistProfile?: string,
  PosArtistProfile?: string,
};

export const DownloadContext = React.createContext<any>({
  keywords: [],
  location: '',
});

export type DownloadContextState = {
  keywords?: Array<string>,
  mixpanelKeywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  youtubeVideoTitle: string,
  sectionOrigin: string,
  relatedCollection: string,
  location: string,
  curatedCollection: string,
  artistProfile: string,
  PosArtistProfile: string,
};

export const getDownloadContextKeywords = (
  downloadContext: DownloadContextState
): Array<string> => {
  return get(downloadContext, 'keywords', []);
};

export const getDownloadContextLocation = (downloadContext: DownloadContextState): string => {
  return get(downloadContext, 'location', []);
};

const DownloadContextWrapper = ({
  children,
  keywords = [],
  mixpanelKeywords = [],
  magicKeywords = [],
  youtubeKeywords = [],
  youtubeVideoTitle = '',
  location = '',
  curatedCollection = '',
  artistProfile = '',
  PosArtistProfile = '',
  sectionOrigin = '',
  relatedCollection = '',
}: Props) => (
  <DownloadContext.Provider
    value={{
      keywords,
      mixpanelKeywords,
      magicKeywords,
      youtubeKeywords,
      youtubeVideoTitle,
      sectionOrigin,
      relatedCollection,
      location,
      curatedCollection,
      artistProfile,
      PosArtistProfile,
    }}
  >
    {children}
  </DownloadContext.Provider>
);

export const useDownloadContext = (): DownloadContextState => {
  return useContext(DownloadContext);
};

export default DownloadContextWrapper;
