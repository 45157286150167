// @flow
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import type { AlgoliaSongMdl } from '../api/algolia/song';
import {
  getSongEditStyle,
  getSongEnergy,
  getSongFamily,
  getSongGenres,
  getSongID,
  getSongInstruments,
  getSongLocation,
  getSongMood,
  getSongMovement,
  getSongShareSlug,
  getSongVideoTheme,
} from '../api/algolia/song';
import { useSong } from '../song/components/SongContextWrapper/SongContextWrapper';
import { isFacebookPixelEnabled } from '../utils/env';

const CATEGORIES = {
  keywords: 'Keywords',
  songs: 'Songs',
  sharing: 'Sharing',
  account: 'Account',
};

type EventValue = {
  action: string,
  category?: string,
  label?: string,
  value?: number | string,
};

// metric1 = Number of Search Results

export type AnalyticsSongDimensions = {
  dimension1: string, // id
  dimension3: string, // type
  dimension4: string, // family
  dimension5: string, // share slug
  dimension6: string, // mood
  dimension7: string, // video theme
  dimension8: string, // edit style
  dimension9: string, // movement
  dimension10: string, // location
  dimension11: string, // energy
  dimension12: string, // genres
  dimension13: string, // instruments
};

export type KeywordsContext = {
  keywords: Array<string> | typeof undefined,
  location: string,
};

export const getSongType = (song: AlgoliaSongMdl): string => {
  let type = `${song.type}`;
  if (song.isPart) {
    type += ` (Part)`;
  }
  return type;
};

export const getSongAnalyticsDimensions = (song: AlgoliaSongMdl): AnalyticsSongDimensions => {
  return {
    dimension1: getSongID(song),
    dimension3: getSongType(song),
    dimension4: getSongFamily(song),
    dimension5: getSongShareSlug(song),
    dimension6: getSongMood(song).join(','),
    dimension7: getSongVideoTheme(song).join(','),
    dimension8: getSongEditStyle(song).join(','),
    dimension9: getSongMovement(song).join(','),
    dimension10: getSongLocation(song).join(','),
    dimension11: getSongEnergy(song).join(','),
    dimension12: getSongGenres(song).join(','),
    dimension13: getSongInstruments(song).join(','),
  };
};

export const useSongAnalyticsDimensions = (): AnalyticsSongDimensions => {
  const song = useSong();
  return getSongAnalyticsDimensions(song);
};

export const getKeywordsAsDimensions = (keywords: Array<string>) => {
  const keywordDimensions = {};

  if (keywords.length === 0) {
    return keywordDimensions;
  }

  keywords.forEach((keyword, index) => {
    keywordDimensions[`keyword${index + 1}`] = keyword;
  });

  return keywordDimensions;
};

const sendAccountEvent = (values: EventValue) => {
  ReactGA.event({
    category: CATEGORIES.account,
    ...values,
  });
};

const sendAccountPixelEvent = (values: EventValue) => {
  if (isFacebookPixelEnabled()) {
    ReactPixel.init('779411805868414');
    ReactPixel.trackCustom('AccountEvent', {
      category: CATEGORIES.account,
      ...values,
    });
  }
};

const sendGoogleTagManagerEvent = (values: EventValue) => {
  window.dataLayer.push({
    category: CATEGORIES.account,
    ...values,
  });
};

export const analyticsAccountAddPlanOnboarding = (planName: string) => {
  sendAccountEvent({
    action: 'Add Plan Onboarding',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Add Plan Onboarding', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Add Plan Onboarding',
    label: planName,
  });
};

export const analyticsAccountAddPlan = (planName: string) => {
  sendAccountEvent({
    action: 'Add Plan',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Add Plan', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Add Plan',
    label: planName,
  });
};

export const analyticsAccountAddSubscription = (planName: string) => {
  sendAccountEvent({
    action: 'Add Subscription',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Add Subscription', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Add Subscription',
    label: planName,
  });
};

export const analyticsAccountConnectYoutube = (channelName: string) => {
  sendAccountEvent({
    action: 'Connect YouTube',
    label: channelName,
  });
  sendAccountPixelEvent({ action: 'Connect YouTube', label: channelName });

  sendGoogleTagManagerEvent({
    action: 'Connect YouTube',
    label: channelName,
  });
};

export const analyticsAccountConnectFacebook = (pageName: string) => {
  sendAccountEvent({
    action: 'Connect Facebook',
    label: pageName,
  });
  sendAccountPixelEvent({ action: 'Connect Facebook', label: pageName });

  sendGoogleTagManagerEvent({
    action: 'Connect Facebook',
    label: pageName,
  });
};

export const analyticsAccountConnectInstagram = (handle: string) => {
  sendAccountEvent({
    action: 'Connect Instagram',
    label: handle,
  });
  sendAccountPixelEvent({ action: 'Connect Instagram', label: handle });

  sendGoogleTagManagerEvent({
    action: 'Connect Instagram',
    label: handle,
  });
};

export const analyticsAccountPayment = (planName: string) => {
  sendAccountEvent({
    action: 'Payment',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Payment', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Payment',
    label: planName,
  });
};

export const analyticsAccountUpgrade = (planName: string) => {
  sendAccountEvent({
    action: 'Upgrade',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Upgrade', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Upgrade',
    label: planName,
  });
};

export const analyticsAccountDowngrade = (planName: string) => {
  sendAccountEvent({
    action: 'Downgrade',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Downgrade', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Downgrade',
    label: planName,
  });
};

export const analyticsPlanIntervalChange = (planName: string) => {
  sendAccountEvent({
    action: 'Interval Change',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Interval Change', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Interval Change',
    label: planName,
  });
};

export const analyticsAccountCancel = (planName: string) => {
  sendAccountEvent({
    action: 'Cancel',
    label: planName,
  });
  sendAccountPixelEvent({ action: 'Cancel', label: planName });

  sendGoogleTagManagerEvent({
    action: 'Cancel',
    label: planName,
  });
};

export const analyticsAccountAddCompany = (companyName: string) => {
  sendAccountEvent({
    action: 'Add Company',
    label: companyName,
  });
  sendAccountPixelEvent({ action: 'Add Company', label: companyName });

  sendGoogleTagManagerEvent({
    action: 'Add Company',
    label: companyName,
  });
};

export const analyticsAccountAddPayPal = (paypal: string) => {
  sendAccountEvent({
    action: 'Add PayPal',
    label: paypal,
  });
  sendAccountPixelEvent({ action: 'Add PayPal', label: paypal });

  sendGoogleTagManagerEvent({
    action: 'Add PayPal',
    label: paypal,
  });
};

export const analyticsAccountDownloadQuotaReached = (
  userId: string,
  keywordsContext: KeywordsContext
) => {
  const keywordDimensions = getKeywordsAsDimensions(keywordsContext.keywords);
  const triggerLocation = keywordsContext.location;

  sendAccountEvent({
    action: 'User Reached Download Quota',
    label: `User ${userId} has reached their daily download limit`,
    triggerLocation,
    ...keywordDimensions,
  });

  sendAccountPixelEvent({
    action: 'User Reached Download Quota',
    label: `User ${userId} has reached their daily download limit`,
    triggerLocation,
    ...keywordDimensions,
  });

  sendGoogleTagManagerEvent({
    action: 'User Reached Download Quota',
    label: `User ${userId} has reached their daily download limit`,
    triggerLocation,
    ...keywordDimensions,
  });
};

const sendSharingEvent = (values: EventValue) => {
  ReactGA.event({
    category: CATEGORIES.sharing,
    ...values,
  });
};

export const analyticsShareSource = (source: string, dimensions: AnalyticsSongDimensions) => {
  sendSharingEvent({
    ...dimensions,
    action: 'Share Source',
    label: source,
  });
};

export const analyticsShareLink = (link: string, dimensions: AnalyticsSongDimensions) => {
  sendSharingEvent({
    ...dimensions,
    action: 'Share Link',
    label: link,
  });
};

const sendSongsEvent = (values: EventValue) => {
  ReactGA.event({
    category: CATEGORIES.songs,
    ...values,
  });
};

export const analyticsSongsPlay = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Play',
  });
};

export const analyticsSongsMicropartPlay = (
  shareSlug: string,
  position: number,
  dimensions: AnalyticsSongDimensions
) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Micropart Play',
    label: shareSlug,
    value: position,
  });
};

export const analyticsSongsPause = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Pause',
  });
};

export const analyticsSongsMicropartPause = (
  shareSlug: string,
  position: number,
  dimensions: AnalyticsSongDimensions
) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Micropart Pause',
    label: shareSlug,
    value: position,
  });
};

export const analyticsSongsBookmark = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Bookmark',
  });
};

export const analyticsSongsDownload = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Download',
  });
};

export const analyticsSongsMicropartDownload = (
  shareSlug: string,
  position: string,
  dimensions: AnalyticsSongDimensions
) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Micropart Download',
    label: shareSlug,
    value: position,
  });
};

export const analyticsSongsMicroparts = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Microparts',
  });
};

export const analyticsSongsShare = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Share',
  });
};

export const analyticsSongsSignUpPrompt = (action: string, dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Sign Up Prompt',
    label: action,
  });
};

export const analyticsSongsParts = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Parts',
  });
};

export const analyticsSongsDetails = (dimensions: AnalyticsSongDimensions) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Details',
  });
};

export const analyticsSongsSwitch = (dimensions: AnalyticsSongDimensions, direction: string) => {
  sendSongsEvent({
    ...dimensions,
    action: 'Switch',
    label: direction,
  });
};

const sendKeywordsEvent = (values: EventValue) => {
  ReactGA.event({
    category: CATEGORIES.keywords,
    ...values,
  });
};

export const analyticsKeywordsAdd = (keyword: string) => {
  sendKeywordsEvent({
    action: 'Add',
    label: keyword,
  });
};

export const analyticsKeywordsSearch = (keywords: string) => {
  sendKeywordsEvent({
    action: 'Search',
    label: keywords,
  });
};

export const analyticsKeywordsNoSearchResults = (keywords: string) => {
  sendKeywordsEvent({
    action: 'No Search Results',
    label: keywords,
  });
};

export const analyticsKeywordsSearchResults = (keywords: string, numberOfResults: number) => {
  sendKeywordsEvent({
    action: 'Search Results',
    label: keywords,
    value: numberOfResults,
    metric1: numberOfResults,
  });
};

export const analyticsKeywordsEditRemove = (keyword: string) => {
  sendKeywordsEvent({
    action: 'Edit Remove',
    label: keyword,
  });
};

export const analyticsKeywordsEditAdd = (keyword: string) => {
  sendKeywordsEvent({
    action: 'Edit Add',
    label: keyword,
  });
};

export const analyticsKeywordsEditClear = (keywords: Array<string>) => {
  sendKeywordsEvent({
    action: 'Edit Clear',
    label: keywords.join(', '),
    value: keywords.length,
  });
};

export const analyticsKeywordsEditUpdate = (keywords: Array<string>) => {
  sendKeywordsEvent({
    action: 'Edit Update',
    label: keywords.join(', '),
    value: keywords.length,
  });
};

export const analyticsKeywordsNewSearch = (keywords: Array<string>, numberOfEdits: number) => {
  sendKeywordsEvent({
    action: 'New Search',
    label: keywords.join(', '),
    value: numberOfEdits,
  });
};

export const analyticsKeywordsNewDownloadSearchTerm = (
  keywords: Array<string> | null,
  songID: string,
  micropartIndex: number | string | null
) => {
  if (keywords) {
    if (keywords.length) {
      const keywordString = keywords.join(', ');
      sendKeywordsEvent({
        action: 'Keyword for Download',
        label: songID,
        dimension14: keywordString,
        position: micropartIndex || '',
      });
    }
  }
};
