// @flow
import React, { useState } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { CenteredMediumContainer } from '../../../../../styles/layout';
import {
  useEmailSettingsPageCopy,
  useProfilePageCopy,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  getEmailSettingsPageHeading,
  getEmailSettingsPageInstructions,
  getEmailSettingsPageMusicUpdatesHeading,
  getEmailSettingsPageMusicUpdatesMessage,
  getEmailSettingsPageNewMusicHeading,
  getEmailSettingsPageNewMusicMessage,
} from '../../../../../cms/emailSettingsPage';
import { GeneralDescription } from '../../../../../styles/shared';
import {
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import { smallMediumTextCss, smallMonoCss } from '../../../../../styles/typography/typography';
import SquareCheckbox from '../../../../../components/forms/inputs/SquareCheckbox';
import { getProfilePageSaveButton } from '../../../../../cms/profilePage';
import Button from '../../../../../components/Button/Button';
import {
  useUserProfileContext,
  useUserProfileDetails,
} from '../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  getProfileEmailSettingsNewMusic,
  getProfileEmailSettingsUpdates,
} from '../../../../../api/firebase/user/profile';
import { useFirestoreUpdateUserProfileData } from '../../../../../api/firebase/user/user';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { updateMixpanelEmailPreferences } from '../../../../../analytics/mixpanel';
import { useUserId } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import PageComponent from '../../../../components/PageComponent/PageComponent';

const Preferences = styled('section')`
  margin-top: 47px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 35px;
  }
`;

const ButtonWrapper = styled('div')`
  margin-top: 63px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 35px;
  }
`;

const Preference = styled('div')`
  &:not(:first-child) {
    margin-top: 27px;
  }

  > label {
    display: flex;
  }
`;

const Description = styled(GeneralDescription)`
  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    margin-top: 10px;
  }
`;

const PreferenceInfo = styled('div')`
  flex: 1;

  h3 {
    ${smallMediumTextCss};
  }

  p {
    ${smallMonoCss};
    margin-top: 5px;
  }
`;

const InputWrapper = styled('div')`
  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding-top: 25px;
  }
`;

type Props = {
  uri: string,
};

const ProfileEmailSettingsView = ({ uri }: Props) => {
  const profile = useUserProfileDetails();
  const copy = useEmailSettingsPageCopy();
  const profileCopy = useProfilePageCopy();
  const [updateProfile] = useFirestoreUpdateUserProfileData();
  const { updateUserProfileData } = useUserProfileContext();
  const [updating, setUpdating] = useState(false);
  const [musicNewsChecked, setMusicNewsChecked] = useState(
    getProfileEmailSettingsNewMusic(profile)
  );
  const [evokeUpdatesChecked, setEvokeUpdatesChecked] = useState(
    getProfileEmailSettingsUpdates(profile)
  );
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const handleSave = () => {
    if (updating) return;
    setUpdating(true);
    const newValues = {
      emailSettings: {
        newmusic: musicNewsChecked,
        updates: evokeUpdatesChecked,
      },
    };
    updateProfile(newValues)
      .then(() => {
        updateUserProfileData(newValues);
        updateMixpanelEmailPreferences(mixpanel, moengage, userId, newValues);
        setUpdating(false);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when updating user profile');
        Sentry.captureException(error);
        console.error(error);
        setUpdating(false);
      });
  };

  return (
    <PageComponent path={uri}>
      <CenteredMediumContainer responsiveFullWidth>
        <header>
          <LargeHeading>{getEmailSettingsPageHeading(copy)}</LargeHeading>
          <Description>
            <p>{getEmailSettingsPageInstructions(copy)}</p>
          </Description>
        </header>
        <section>
          <Preferences>
            <Preference>
              <label>
                <PreferenceInfo>
                  <h3>{getEmailSettingsPageNewMusicHeading(copy)}</h3>
                  {parse(getEmailSettingsPageNewMusicMessage(copy))}
                </PreferenceInfo>
                <InputWrapper>
                  <SquareCheckbox
                    checked={musicNewsChecked}
                    onChange={event => {
                      setMusicNewsChecked(event.target.checked);
                    }}
                  />
                </InputWrapper>
              </label>
            </Preference>
            <Preference>
              <label>
                <PreferenceInfo>
                  <h3>{getEmailSettingsPageMusicUpdatesHeading(copy)}</h3>
                  {parse(getEmailSettingsPageMusicUpdatesMessage(copy))}
                </PreferenceInfo>
                <InputWrapper>
                  <SquareCheckbox
                    checked={evokeUpdatesChecked}
                    onChange={event => {
                      setEvokeUpdatesChecked(event.target.checked);
                    }}
                  />
                </InputWrapper>
              </label>
            </Preference>
          </Preferences>
          <ButtonWrapper>
            <Button onClick={handleSave} loading={updating}>
              {getProfilePageSaveButton(profileCopy)}
            </Button>
          </ButtonWrapper>
        </section>
      </CenteredMediumContainer>
    </PageComponent>
  );
};

export default ProfileEmailSettingsView;
