// @flow
import React, { useContext } from 'react';
import { get } from 'lodash';
import type { SiteHeaderMdl } from '../../../cms/header';
import type {
  SignUpModalGraphMdl,
  SignUpPageMdl,
  SignUpEnterprisePageMdl,
} from '../../../cms/signup';
import type { PricingPlanMdl } from '../../../cms/pricingPlan';
import type { FindMusicPageMdl } from '../../../cms/findMusic';
import type { MusicPageMdl } from '../../../cms/musicPage';
import type { MiscCopyMdl } from '../../../cms/miscCopy';
import type { ResultsPageMdl } from '../../../cms/resultsPage';
import type { SiteFooterMdl } from '../../../cms/footer';
import type { SignInPageMdl } from '../../../cms/signin';
import type { SubscriptionsPageMdl } from '../../../cms/subscriptionsPage';
import type { NoConnectionsModalGraphMdl } from '../../../cms/modals';
import type { PopupMessageGraphMdl } from '../../../cms/popupMessage';
import type { PaymentSummaryPageGraphMdl } from '../../../cms/paymentSummaryPage';
import type { ConnectPaypalGraphMdl } from '../../../cms/connectPaypal';
import type { EditKeywordsPageGraphMdl } from '../../../cms/editKeywordsPage';
import type { PrivacyPolicyPageGraphMdl } from '../../../cms/privacyPolicyPage';
import type { ResetPasswordModalGraphMdl } from '../../../cms/resetPasswordModal';
import type { EmailSettingsPageGraphMdl } from '../../../cms/emailSettingsPage';
import type { ProfilePageGraphMdl } from '../../../cms/profilePage';
import type { AdRevenuePageGraphMdl } from '../../../cms/adRevenuePage';
import type { BillingPageGraphMdl } from '../../../cms/billingPage';
import type { SiteMetaGraphMdl } from '../../../cms/siteMeta';
import type { TermsPageGraphMdl } from '../../../cms/termsPage';
import type { BusinessLicensePageGraphMdl } from '../../../cms/businessLicensePage';
import type { CreatorLicensePageGraphMdl } from '../../../cms/creatorLicensePage';
import type { CreatorProLicensePageGraphMdl } from '../../../cms/creatorProLicensePage';
import type { EnterpriseLicensePageGraphMdl } from '../../../cms/enterpriseLicensePage';
import type { SingleTrackAllMediaLicensePageGraphMdl } from '../../../cms/allMediaTrackLicensePage';
import type { SingleTrackLimitedMediaLicensePageGraphMdl } from '../../../cms/limitedMediaTrackLicensePage';
import type { ExclusiveRightsTrackLicensePageGraphMdl } from '../../../cms/exclusiveRightsTrackLicensePage';
import type { YoutubeConnectionErrorGraphMdl } from '../../../cms/errors';
import type { FooterBannerGraphMdl } from '../../../cms/footerBanner';
import type { ProfileMenuGraphMdl } from '../../../cms/profileMenu';
import type { SignInModalGraphMdl } from '../../../cms/signinModal';
import type { DowngradeSubscriptionGraphMdl } from '../../../cms/downgradeSubscriptionModal';
import type { ChangeIntervalSubscriptionGraphMdl } from '../../../cms/changeIntervalSubscriptionModal';
import type {
  CancelFreeSubscriptionModalGraphMdl,
  CancelSubscriptionModalGraphMdl,
} from '../../../cms/cancelSubscriptionModal';
import type { ConnectYouTubeModalGraphMdl } from '../../../cms/connectYouTube';
import type { EnterpriseContactModalGraphMdl } from '../../../cms/enterpriseContactModal';
import type { TrackGraphMdl } from '../../../cms/track';
import type { NotFoundGraphMdl } from '../../../cms/notFoundPage';
import type { ShareTrackModalGraphMdl } from '../../../cms/shareTrackModal';
import { useLocaleContext } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../../locales';
import { getPricingPlan } from '../../../cms/pricingPlan';
import { getPricingPagePlan } from '../../../cms/pricingPagePlan';
import { getArtistProfileId } from '../../../cms/artistProfile';
import type { SignInUpErrorsGraphMdl } from '../../../cms/signInUpErrors';
import type { ErrorScreensGraphMdl } from '../../../cms/errorScreens';
import type { ToastMessagesGraphMdl } from '../../../cms/toastMessages';
import type { NoResultsPageGraphMdl } from '../../../cms/noResultsPage';
import type { MicropartViewGraphMdl } from '../../../cms/micropartView';
import type { LeavePageModalGraphMdl } from '../../../cms/leavePageModal';
import type { SubscriptionRequiredModalGraphMdl } from '../../../cms/subscriptionRequiredModal';
import type { SortingRestrictedModalGraphMdl } from '../../../cms/sortingRestrictedModal';
import type { BookmarksPageGraphMdl } from '../../../cms/bookmarksPage';
import type { SearchBookmarksGraphMdl } from '../../../cms/searchBookmarks';
import type { DownloadHistoryPageGraphMdl } from '../../../cms/downloadHistoryPage';
import type { AllCouponsGraphMdl, CouponGraphMdl } from '../../../cms/coupon';
import type { DownloadCountMenuGraphMdl } from '../../../cms/downloadCountMenu';
import type { TrialCounterMenuGraphMdl } from '../../../cms/trialCounterMenu';
import type { ChangeFreeTrialSubscriptionModalGraphMdl } from '../../../cms/changeFreeTrialSubscriptionModal';
import type { AllowlistingStatusesGraphMdl } from '../../../cms/allowlistingStatuses';
import type { ReferralBannersGraphMdl } from '../../../cms/referralBanners';
import type { ChromeExtensionGraphMdl } from '../../../cms/chromeExtension';
import type { PricingPageGraphMdl } from '../../../cms/pricingPage';
import type { PricingPagePlanMdl } from '../../../cms/pricingPagePlan';
import type { KeyboardShortcutsGraphMdl } from '../../../cms/keyboardShortucts';
import type { CollectionsPageGraphMdl } from '../../../cms/collectionsPage';
import {
  getCuratedCollectionId,
  getCuratedCollectionIsCreatorCollection,
} from '../../../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../../../cms/curatedCollection';
import type { ArtistProfileGraphMdl } from '../../../cms/artistProfile';
import type { CustomOrderPageGraphMdl } from '../../../cms/customOrderPage';
import type { CustomTrackProductMdl } from '../../../cms/customTrackProduct';
import { getCustomTrackProduct } from '../../../cms/customTrackProduct';
import type { TagSearchModalModalGraphMdl } from '../../../cms/tagSearchModal';

export type AppData = {
  prismicSiteHeader: SiteHeaderMdl,
  jpPrismicSiteHeader: SiteHeaderMdl,
  cnPrismicSiteHeader: SiteHeaderMdl,
  prismicSignUpPage: SignUpPageMdl,
  jpPrismicSignUpPage: SignUpPageMdl,
  prismicSignUpEnterprisePage: SignUpEnterprisePageMdl,
  jpPrismicSignUpEnterprisePage: SignUpEnterprisePageMdl,
  prismicFindMusicPage: FindMusicPageMdl,
  jpPrismicFindMusicPage: FindMusicPageMdl,
  prismicMusicPage: MusicPageMdl,
  jpPrismicMusicPage: MusicPageMdl,
  prismicResultsPage: ResultsPageMdl,
  jpPrismicResultsPage: ResultsPageMdl,
  prismicMiscCopy: MiscCopyMdl,
  jpPrismicMiscCopy: MiscCopyMdl,
  cnPrismicMiscCopy: MiscCopyMdl,
  prismicSiteFooter: SiteFooterMdl,
  jpPrismicSiteFooter: SiteFooterMdl,
  cnPrismicSiteFooter: SiteFooterMdl,
  prismicSignInPage: SignInPageMdl,
  jpPrismicSignInPage: SignInPageMdl,
  prismicSubscriptionsPage: SubscriptionsPageMdl,
  jpPrismicSubscriptionsPage: SubscriptionsPageMdl,
  prismicNoConnectionsModal: NoConnectionsModalGraphMdl,
  jpPrismicNoConnectionsModal: NoConnectionsModalGraphMdl,
  allPrismicPricingPlan: {
    edges: Array<{
      node: PricingPlanMdl,
    }>,
  },
  jpAllPrismicPricingPlan: {
    edges: Array<{
      node: PricingPlanMdl,
    }>,
  },
  allPrismicPopUpMessage: {
    edges: Array<{
      node: PopupMessageGraphMdl,
    }>,
  },
  jpAllPrismicPopUpMessage: {
    edges: Array<{
      node: PopupMessageGraphMdl,
    }>,
  },
  allPrismicPricingPagePlan: {
    edges: Array<{
      node: PricingPagePlanMdl,
    }>,
  },
  jpAllPrismicPricingPagePlan: {
    edges: Array<{
      node: PricingPagePlanMdl,
    }>,
  },
  prismicPricingPage: PricingPageGraphMdl,
  jpPrismicPricingPage: PricingPageGraphMdl,
  prismicSignUpModal: SignUpModalGraphMdl,
  jpPrismicSignUpModal: SignUpModalGraphMdl,
  prismicPaymentSummaryPage: PaymentSummaryPageGraphMdl,
  jpPrismicPaymentSummaryPage: PaymentSummaryPageGraphMdl,
  prismicChromeExtension: ChromeExtensionGraphMdl,
  jpPrismicChromeExtension: ChromeExtensionGraphMdl,
  prismicConntectPaypalPage: ConnectPaypalGraphMdl,
  jpPrismicConntectPaypalPage: ConnectPaypalGraphMdl,
  prismicEditKeywordsPage: EditKeywordsPageGraphMdl,
  jpPrismicEditKeywordsPage: EditKeywordsPageGraphMdl,
  prismicPrivacyPolicyPage: PrivacyPolicyPageGraphMdl,
  jpPrismicPrivacyPolicyPage: PrivacyPolicyPageGraphMdl,
  prismicTermsOfServicePage: TermsPageGraphMdl,
  jpPrismicTermsOfServicePage: TermsPageGraphMdl,
  prismicBusinessSubscriptionMusicLicensePage: BusinessLicensePageGraphMdl,
  jpPrismicBusinessSubscriptionMusicLicensePage: BusinessLicensePageGraphMdl,
  prismicCreatorSubscriptionMusicLicensePage: CreatorLicensePageGraphMdl,
  jpPrismicCreatorSubscriptionMusicLicensePage: CreatorLicensePageGraphMdl,
  prismicCreatorProSubscriptionMusicLicensePage: CreatorProLicensePageGraphMdl,
  jpPrismicCreatorProSubscriptionMusicLicensePage: CreatorProLicensePageGraphMdl,
  prismicEnterpriseSubscriptionMusicLicensePage: EnterpriseLicensePageGraphMdl,
  jpPrismicEnterpriseSubscriptionMusicLicensePage: EnterpriseLicensePageGraphMdl,
  prismicCustomTrackLicensePage: SingleTrackLimitedMediaLicensePageGraphMdl,
  jpPrismicCustomTrackLicensePage: SingleTrackLimitedMediaLicensePageGraphMdl,
  prismicSingleTrackAllMediaLicensePage: SingleTrackAllMediaLicensePageGraphMdl,
  jpPrismicSingleTrackAllMediaLicensePage: SingleTrackAllMediaLicensePageGraphMdl,
  prismicExclusiveRightsTrackLicensePage: ExclusiveRightsTrackLicensePageGraphMdl,
  jpPrismicExclusiveRightsTrackLicensePage: ExclusiveRightsTrackLicensePageGraphMdl,
  prismicResetPasswordModal: ResetPasswordModalGraphMdl,
  jpPrismicResetPasswordModal: ResetPasswordModalGraphMdl,
  prismicEmailSettingsPage: EmailSettingsPageGraphMdl,
  jpPrismicEmailSettingsPage: EmailSettingsPageGraphMdl,
  prismicProfilePage: ProfilePageGraphMdl,
  jpPrismicProfilePage: ProfilePageGraphMdl,
  prismicAdRevenuePage: AdRevenuePageGraphMdl,
  jpPrismicAdRevenuePage: AdRevenuePageGraphMdl,
  prismicBillingPage: BillingPageGraphMdl,
  jpPrismicBillingPage: BillingPageGraphMdl,
  prismicSiteMeta: SiteMetaGraphMdl,
  jpPrismicSiteMeta: SiteMetaGraphMdl,
  prismicYoutubeConnectionErrorModal: YoutubeConnectionErrorGraphMdl,
  jpPrismicYoutubeConnectionErrorModal: YoutubeConnectionErrorGraphMdl,
  prismicFooterPromptBanner: FooterBannerGraphMdl,
  jpPrismicFooterPromptBanner: FooterBannerGraphMdl,
  prismicMenu: ProfileMenuGraphMdl,
  jpPrismicMenu: ProfileMenuGraphMdl,
  cnPrismicMenu: ProfileMenuGraphMdl,
  prismicSignInModal: SignInModalGraphMdl,
  jpPrismicSignInModal: SignInModalGraphMdl,
  prismicDowngradeSubscriptionModal: DowngradeSubscriptionGraphMdl,
  jpPrismicDowngradeSubscriptionModal: DowngradeSubscriptionGraphMdl,
  prismicIntervalChangeSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  jpPrismicIntervalChangeSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  prismicChangeIntervalSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  jpPrismicChangeIntervalSubscriptionModal: ChangeIntervalSubscriptionGraphMdl,
  prismicCancelSubscriptionModal: CancelSubscriptionModalGraphMdl,
  jpPrismicCancelSubscriptionModal: CancelSubscriptionModalGraphMdl,
  prismicCancelFreeSubscriptionModal: CancelFreeSubscriptionModalGraphMdl,
  jpPrismicCancelFreeSubscriptionModal: CancelFreeSubscriptionModalGraphMdl,
  prismicConnectYoutubeChannelModal: ConnectYouTubeModalGraphMdl,
  jpPrismicConnectYoutubeChannelModal: ConnectYouTubeModalGraphMdl,
  prismicEnterpriseContactModal: EnterpriseContactModalGraphMdl,
  jpPrismicEnterpriseContactModal: EnterpriseContactModalGraphMdl,
  prismicTrack: TrackGraphMdl,
  jpPrismicTrack: TrackGraphMdl,
  prismic404ErrorPage: NotFoundGraphMdl,
  jpPrismic404ErrorPage: NotFoundGraphMdl,
  prismicShareTrackModal: ShareTrackModalGraphMdl,
  jpPrismicShareTrackModal: ShareTrackModalGraphMdl,
  prismicSignInupErrors: SignInUpErrorsGraphMdl,
  jpPrismicSignInupErrors: SignInUpErrorsGraphMdl,
  prismicErrorScreens: ErrorScreensGraphMdl,
  jpPrismicErrorScreens: ErrorScreensGraphMdl,
  prismicToastMessage: ToastMessagesGraphMdl,
  jpPrismicToastMessage: ToastMessagesGraphMdl,
  prismicNoResultsPage: NoResultsPageGraphMdl,
  jpPrismicNoResultsPage: NoResultsPageGraphMdl,
  prismicMicropartView: MicropartViewGraphMdl,
  jpPrismicMicropartView: MicropartViewGraphMdl,
  prismicLeavePageModal: LeavePageModalGraphMdl,
  jpPrismicLeavePageModal: LeavePageModalGraphMdl,
  prismicSubscriptionRequiredModal: SubscriptionRequiredModalGraphMdl,
  jpPrismicSubscriptionRequiredModal: SubscriptionRequiredModalGraphMdl,
  prismicSortingRestrictedModal: SortingRestrictedModalGraphMdl,
  jpPrismicSortingRestrictedModal: SortingRestrictedModalGraphMdl,
  prismicTagSearchModal: TagSearchModalModalGraphMdl,
  jpPrismicTagSearchModal: TagSearchModalModalGraphMdl,
  prismicBookmarksPage: BookmarksPageGraphMdl,
  jpPrismicBookmarksPage: BookmarksPageGraphMdl,
  prismicSearchBookmarks: SearchBookmarksGraphMdl,
  jpPrismicSearchBookmarks: SearchBookmarksGraphMdl,
  prismicDownloadHistoryPage: DownloadHistoryPageGraphMdl,
  jpPrismicDownloadHistoryPage: DownloadHistoryPageGraphMdl,
  allPrismicDiscountCode: AllCouponsGraphMdl,
  jpAllPrismicDiscountCode: AllCouponsGraphMdl,
  prismicDownloadCountMenu: DownloadCountMenuGraphMdl,
  jpPrismicDownloadCountMenu: DownloadCountMenuGraphMdl,
  prismicTrialCounterMenu: TrialCounterMenuGraphMdl,
  jpPrismicTrialCounterMenu: TrialCounterMenuGraphMdl,
  prismicChangeFreeTrialSubscriptionModal: ChangeFreeTrialSubscriptionModalGraphMdl,
  jpPrismicChangeFreeTrialSubscriptionModal: ChangeFreeTrialSubscriptionModalGraphMdl,
  prismicWhitelistStatuses: AllowlistingStatusesGraphMdl,
  jpPrismicWhitelistStatuses: AllowlistingStatusesGraphMdl,
  prismicReferralBanners: ReferralBannersGraphMdl,
  jpPrismicReferralBanners: ReferralBannersGraphMdl,
  prismicKeyboardShortcuts: KeyboardShortcutsGraphMdl,
  jpPrismicKeyboardShortcuts: KeyboardShortcutsGraphMdl,
  prismicCollectionsPage: CollectionsPageGraphMdl,
  jpPrismicCollectionsPage: CollectionsPageGraphMdl,
  allPrismicCuratedCollection: {
    edges: Array<{
      node: CuratedCollectionGraphMdl,
    }>,
  },
  jpAllPrismicCuratedCollection: {
    edges: Array<{
      node: CuratedCollectionGraphMdl,
    }>,
  },
  allPrismicArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
  allPrismicPosArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
  jpAllPrismicArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
  jpAllPrismicPosArtistProfile: {
    edges: Array<{
      node: ArtistProfileGraphMdl,
    }>,
  },
  prismicCustomOrderPage: CustomOrderPageGraphMdl,
  jpPrismicCustomOrderPage: CustomOrderPageGraphMdl,
  allPrismicCustomTrackProduct: {
    edges: Array<{
      node: CustomTrackProductMdl,
    }>,
  },
  jpAllPrismicCustomTrackProduct: {
    edges: Array<{
      node: CustomTrackProductMdl,
    }>,
  },
};

export const CopyContext = React.createContext<AppData | null>(null);

export const useCopyContext = (): AppData => {
  const copy: AppData = (useContext(CopyContext): any);
  return copy;
};

export const useCoupons = (): AllCouponsGraphMdl => {
  const { locale } = useLocaleContext();
  const { allPrismicDiscountCode: enCopy, jpAllPrismicDiscountCode: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCouponsArray = (): Array<CouponGraphMdl> => {
  const coupons = useCoupons();
  const edges = get(coupons, 'edges', []);
  return edges
    .map(edge => {
      if (edge.node) {
        return edge.node;
      }
      return null;
    })
    .filter((coupon: CouponGraphMdl) => {
      return !!coupon;
    });
};

export const useDownloadHistoryPageCopy = (): DownloadHistoryPageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicDownloadHistoryPage: enCopy,
    jpPrismicDownloadHistoryPage: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSearchBookmarksCopy = (): SearchBookmarksGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicSearchBookmarks: enCopy, jpPrismicSearchBookmarks: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useBookmarksPageCopy = (): BookmarksPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicBookmarksPage: enCopy, jpPrismicBookmarksPage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSubscriptionRequiredModalCopy = (): SubscriptionRequiredModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicSubscriptionRequiredModal: enCopy,
    jpPrismicSubscriptionRequiredModal: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSortingRestrictedModalCopy = (): SortingRestrictedModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicSortingRestrictedModal: enCopy,
    jpPrismicSortingRestrictedModal: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useTagSearchModalCopy = (): TagSearchModalModalGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicTagSearchModal: enCopy, jpPrismicTagSearchModal: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useLeavePageModalCopy = (): LeavePageModalGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicLeavePageModal: enCopy, jpPrismicLeavePageModal: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useMicropartViewCopy = (): MicropartViewGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicMicropartView: enCopy, jpPrismicMicropartView: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useNoResultsPageCopy = (): NoResultsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicNoResultsPage: enCopy, jpPrismicNoResultsPage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useToastMessages = (): ToastMessagesGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicToastMessage: enCopy, jpPrismicToastMessage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useErrorScreens = (): ErrorScreensGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicErrorScreens: enCopy, jpPrismicErrorScreens: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSignInUpErrors = (): SignInUpErrorsGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicSignInupErrors: enCopy, jpPrismicSignInupErrors: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useShareTrackModalCopy = (): ShareTrackModalGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicShareTrackModal: enCopy, jpPrismicShareTrackModal: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useNotFoundPageCopy = (): NotFoundGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismic404ErrorPage: enCopy, jpPrismic404ErrorPage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useTrackCopy = (): TrackGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicTrack: enCopy, jpPrismicTrack: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useConnectYouTubeChannelModalCopy = (): ConnectYouTubeModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicConnectYoutubeChannelModal: enCopy,
    jpPrismicConnectYoutubeChannelModal: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCancelSubscriptionModalCopy = (): CancelSubscriptionModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicCancelSubscriptionModal: enCopy,
    jpPrismicCancelSubscriptionModal: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCancelFreeSubscriptionModalCopy = (): CancelFreeSubscriptionModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    prismicCancelFreeSubscriptionModal: enCopy,
    jpPrismicCancelFreeSubscriptionModal: jpCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useDowngradeSubscriptionModalCopy = (): DowngradeSubscriptionGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicDowngradeSubscriptionModal: jpCopy,
    prismicDowngradeSubscriptionModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useChangeIntervalSubscriptionModalCopy = (): ChangeIntervalSubscriptionGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicIntervalChangeSubscriptionModal: jpCopy,
    prismicIntervalChangeSubscriptionModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useChangeFreeTrialSubscriptionModalCopy = (): ChangeFreeTrialSubscriptionModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicChangeFreeTrialSubscriptionModal: jpCopy,
    prismicChangeFreeTrialSubscriptionModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSignInModalCopy = (): SignInModalGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSignInModal: jpCopy, prismicSignInModal: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useEnterpriseContactModalCopy = (): EnterpriseContactModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicEnterpriseContactModal: jpCopy,
    prismicEnterpriseContactModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useProfileMenuCopy = (): ProfileMenuGraphMdl => {
  const { locale } = useLocaleContext();
  // const { jpPrismicMenu: jpCopy, prismicMenu: enCopy } = useCopyContext();
  const { jpPrismicMenu: jpCopy, prismicMenu: enCopy, cnPrismicMenu: cnCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  if (locale === LOCALES.chinese.code) {
    if (cnCopy) {
      return cnCopy;
    }
  }
  return enCopy;
};

export const useFooterBannerCopy = (): FooterBannerGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicFooterPromptBanner: jpCopy,
    prismicFooterPromptBanner: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useYoutubeConnectionErrorCopy = (): YoutubeConnectionErrorGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicYoutubeConnectionErrorModal: jpCopy,
    prismicYoutubeConnectionErrorModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSiteMeta = (): SiteMetaGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSiteMeta: jpCopy, prismicSiteMeta: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useBillingPageCopy = (): BillingPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicBillingPage: jpCopy, prismicBillingPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useAdRevenuePageCopy = (): AdRevenuePageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicAdRevenuePage: jpCopy, prismicAdRevenuePage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useProfilePageCopy = (): ProfilePageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicProfilePage: jpCopy, prismicProfilePage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useEmailSettingsPageCopy = (): EmailSettingsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicEmailSettingsPage: jpCopy, prismicEmailSettingsPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useResetPasswordModalCopy = (): ResetPasswordModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicResetPasswordModal: jpCopy,
    prismicResetPasswordModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const usePrivacyPolicyPageCopy = (): PrivacyPolicyPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicPrivacyPolicyPage: jpCopy, prismicPrivacyPolicyPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useTermsPageCopy = (): TermsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicTermsOfServicePage: jpCopy,
    prismicTermsOfServicePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useBusinessLicensePageCopy = (): BusinessLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicBusinessSubscriptionMusicLicensePage: jpCopy,
    prismicBusinessSubscriptionMusicLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCreatorLicensePageCopy = (): CreatorLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicCreatorSubscriptionMusicLicensePage: jpCopy,
    prismicCreatorSubscriptionMusicLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCreatorProLicensePageCopy = (): CreatorProLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicCreatorProSubscriptionMusicLicensePage: jpCopy,
    prismicCreatorProSubscriptionMusicLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useEnterpriseLicensePageCopy = (): EnterpriseLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicEnterpriseSubscriptionMusicLicensePage: jpCopy,
    prismicEnterpriseSubscriptionMusicLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSingleTrackLimitedMediaLicensePageCopy = (): SingleTrackLimitedMediaLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicCustomTrackLicensePage: jpCopy,
    prismicCustomTrackLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSingleTrackSAllMediaLicensePageCopy = (): SingleTrackAllMediaLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicSingleTrackAllMediaLicensePage: jpCopy,
    prismicSingleTrackAllMediaLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useExclusiveRightsLicensePageCopy = (): ExclusiveRightsTrackLicensePageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicExclusiveRightsTrackLicensePage: jpCopy,
    prismicExclusiveRightsTrackLicensePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useEditKeywordsPageCopy = (): EditKeywordsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicEditKeywordsPage: jpCopy, prismicEditKeywordsPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useConnectPaypalCopy = (): ConnectPaypalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicConntectPaypalPage: jpCopy,
    prismicConntectPaypalPage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useChromeExtensionCopy = (): ChromeExtensionGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicChromeExtension: enCopy, jpPrismicChromeExtension: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const usePaymentSummary = (): PaymentSummaryPageGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicPaymentSummaryPage: jpCopy,
    prismicPaymentSummaryPage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSignUpModal = (): SignUpModalGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSignUpModal: jpCopy, prismicSignUpModal: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const usePopupMessages = (): Array<PopupMessageGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPopUpMessage, allPrismicPopUpMessage } = useCopyContext();
  let messages = allPrismicPopUpMessage;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPopUpMessage &&
      jpAllPrismicPopUpMessage.edges &&
      jpAllPrismicPopUpMessage.edges.length > 0
    ) {
      messages = jpAllPrismicPopUpMessage;
    }
  }
  return messages.edges.map(({ node }) => {
    return node;
  });
};

export const usePopupMessage = (slug: string): PopupMessageGraphMdl | typeof undefined => {
  const messages = usePopupMessages();
  return messages.find((message: PopupMessageGraphMdl) => {
    return message.uid === slug;
  });
};

export const useSiteHeader = (): SiteHeaderMdl => {
  const { locale } = useLocaleContext();
  // const { jpPrismicSiteHeader: jpCopy, prismicSiteHeader: enCopy } = useCopyContext();
  const {
    jpPrismicSiteHeader: jpCopy,
    prismicSiteHeader: enCopy,
    cnPrismicSiteHeader: cnCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  if (locale === LOCALES.chinese.code) {
    if (cnCopy) {
      return cnCopy;
    }
  }
  return enCopy;
};

export const useSiteFooter = (): SiteFooterMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicSiteFooter: jpCopy,
    prismicSiteFooter: enCopy,
    cnPrismicSiteFooter: cnCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  if (locale === LOCALES.chinese.code) {
    if (cnCopy) {
      return cnCopy;
    }
  }
  return enCopy;
};

export const useSignInPage = (): SignInPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSignInPage: jpCopy, prismicSignInPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSignUpPage = (): SignUpPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSignUpPage: jpCopy, prismicSignUpPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSignUpEnterprisePage = (): SignUpEnterprisePageMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicSignUpEnterprisePage: jpCopy,
    prismicSignUpEnterprisePage: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useResultsPage = (): ResultsPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicResultsPage: jpCopy, prismicResultsPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useFindMusicPage = (): FindMusicPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicFindMusicPage: jpCopy, prismicFindMusicPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useMusicPage = (): MusicPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicMusicPage: jpCopy, prismicMusicPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useSubscriptionsPage = (): SubscriptionsPageMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicSubscriptionsPage: jpCopy, prismicSubscriptionsPage: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useMiscCopy = (): MiscCopyMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicMiscCopy: jpCopy,
    prismicMiscCopy: enCopy,
    cnPrismicMiscCopy: cnCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  if (locale === LOCALES.chinese.code) {
    if (cnCopy) {
      return cnCopy;
    }
  }
  return enCopy;
};

export const useDownloadCountMenu = (): DownloadCountMenuGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicDownloadCountMenu: jpCopy, prismicDownloadCountMenu: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useTrialCounterMenu = (): TrialCounterMenuGraphMdl => {
  const { locale } = useLocaleContext();
  const { jpPrismicTrialCounterMenu: jpCopy, prismicTrialCounterMenu: enCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useNoConnectionsModalCopy = (): NoConnectionsModalGraphMdl => {
  const { locale } = useLocaleContext();
  const {
    jpPrismicNoConnectionsModal: jpCopy,
    prismicNoConnectionsModal: enCopy,
  } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const usePricingPlan = (uid: string): PricingPlanMdl | null => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPricingPlan, allPrismicPricingPlan } = useCopyContext();
  let plans = allPrismicPricingPlan;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPricingPlan &&
      jpAllPrismicPricingPlan.edges &&
      jpAllPrismicPricingPlan.edges.length > 0
    ) {
      plans = jpAllPrismicPricingPlan;
    }
  }
  const pricingPlans = plans.edges.map(({ node }) => {
    return node;
  });

  return getPricingPlan(pricingPlans, uid) ? getPricingPlan(pricingPlans, uid) : null;
};

export const usePricingPlans = (): Array<PricingPlanMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPricingPlan, allPrismicPricingPlan } = useCopyContext();
  let plans = allPrismicPricingPlan;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPricingPlan &&
      jpAllPrismicPricingPlan.edges &&
      jpAllPrismicPricingPlan.edges.length > 0
    ) {
      plans = jpAllPrismicPricingPlan;
    }
  }
  return plans.edges.map(({ node }) => {
    return node;
  });
};

export const usePricingPage = (): PricingPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicPricingPage: enCopy, jpPrismicPricingPage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const usePricingPagePlan = (uid: string): PricingPagePlanMdl | null => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPricingPagePlan, allPrismicPricingPagePlan } = useCopyContext();
  let plans = allPrismicPricingPagePlan;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPricingPagePlan &&
      jpAllPrismicPricingPagePlan.edges &&
      jpAllPrismicPricingPagePlan.edges.length > 0
    ) {
      plans = jpAllPrismicPricingPagePlan;
    }
  }
  const pricingPlans = plans.edges.map(({ node }) => {
    return node;
  });

  return getPricingPagePlan(pricingPlans, uid) ? getPricingPagePlan(pricingPlans, uid) : null;
};

export const usePricingPagePlans = (): Array<PricingPagePlanMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPricingPagePlan, allPrismicPricingPagePlan } = useCopyContext();
  let plans = allPrismicPricingPagePlan;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPricingPagePlan &&
      jpAllPrismicPricingPagePlan.edges &&
      jpAllPrismicPricingPagePlan.edges.length > 0
    ) {
      plans = jpAllPrismicPricingPagePlan;
    }
  }
  return plans.edges.map(({ node }) => {
    return node;
  });
};

export const useCustomOrderPage = (): CustomOrderPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicCustomOrderPage: enCopy, jpPrismicCustomOrderPage: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCustomTrackProduct = (uid: string): CustomTrackProductMdl => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCustomTrackProduct, allPrismicCustomTrackProduct } = useCopyContext();
  let plans = allPrismicCustomTrackProduct;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCustomTrackProduct &&
      jpAllPrismicCustomTrackProduct.edges &&
      jpAllPrismicCustomTrackProduct.edges.length > 0
    ) {
      plans = jpAllPrismicCustomTrackProduct;
    }
  }
  const customTrackProducts = plans.edges.map(({ node }) => {
    return node;
  });

  return getCustomTrackProduct(customTrackProducts, uid);
};

export const useCustomTrackProducts = (): Array<CustomTrackProductMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCustomTrackProduct, allPrismicCustomTrackProduct } = useCopyContext();
  let plans = allPrismicCustomTrackProduct;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCustomTrackProduct &&
      jpAllPrismicCustomTrackProduct.edges &&
      jpAllPrismicCustomTrackProduct.edges.length > 0
    ) {
      plans = jpAllPrismicCustomTrackProduct;
    }
  }
  return plans.edges.map(({ node }) => {
    return node;
  });
};

export const useReferralBanners = (): ReferralBannersGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicReferralBanners: enCopy, jpPrismicReferralBanners: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

type Props = {
  data: AppData,
  children: any,
};

export const useAllowlistStatusesCopy = (): AllowlistingStatusesGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicWhitelistStatuses: enCopy, jpPrismicWhitelistStatuses: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useKeyboardShortcutsCopy = (): KeyboardShortcutsGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicKeyboardShortcuts: enCopy, jpPrismicKeyboardShortcuts: jpCopy } = useCopyContext();
  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCollectionsPage = (): CollectionsPageGraphMdl => {
  const { locale } = useLocaleContext();
  const { prismicCollectionsPage: enCopy, jpPrismicCollectionsPage: jpCopy } = useCopyContext();

  if (locale === LOCALES.japanese.code) {
    if (jpCopy) {
      return jpCopy;
    }
  }
  return enCopy;
};

export const useCuratedCollection = (uid: string): Array<CuratedCollectionGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCuratedCollection, allPrismicCuratedCollection } = useCopyContext();
  let collections = allPrismicCuratedCollection;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCuratedCollection &&
      jpAllPrismicCuratedCollection.edges &&
      jpAllPrismicCuratedCollection.edges.length > 0
    ) {
      collections = jpAllPrismicCuratedCollection;
    }
  }
  const curatedCollections = collections.edges.map(({ node }) => {
    return node;
  });

  return curatedCollections.filter(collection => {
    return uid === getCuratedCollectionId(collection);
  });
};

export const useCuratedCollections = (): Array<CuratedCollectionGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicCuratedCollection, allPrismicCuratedCollection } = useCopyContext();
  let collections = allPrismicCuratedCollection;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicCuratedCollection &&
      jpAllPrismicCuratedCollection.edges &&
      jpAllPrismicCuratedCollection.edges.length > 0
    ) {
      collections = jpAllPrismicCuratedCollection;
    }
  }
  return collections.edges
    .map(({ node }) => {
      return node;
    })
    .filter(collection => {
      return (
        getCuratedCollectionIsCreatorCollection(collection) === true ||
        getCuratedCollectionIsCreatorCollection(collection) === null
      );
    });
};

export const useArtistProfile = (uid: string): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicArtistProfile, allPrismicArtistProfile } = useCopyContext();
  let profiles = allPrismicArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicArtistProfile &&
      jpAllPrismicArtistProfile.edges &&
      jpAllPrismicArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicArtistProfile;
    }
  }
  const artistProfiles = profiles.edges
    .map(({ node }) => {
      return node;
    })
    .filter(profile => {
      return uid === getArtistProfileId(profile);
    });

  return artistProfiles;
};

export const useArtistProfiles = (): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicArtistProfile, allPrismicArtistProfile } = useCopyContext();
  let profiles = allPrismicArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicArtistProfile &&
      jpAllPrismicArtistProfile.edges &&
      jpAllPrismicArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicArtistProfile;
    }
  }
  return profiles.edges.map(({ node }) => {
    return node;
  });
};

export const usePosArtistProfile = (uid: string): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPosArtistProfile, allPrismicPosArtistProfile } = useCopyContext();
  let profiles = allPrismicPosArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPosArtistProfile &&
      jpAllPrismicPosArtistProfile.edges &&
      jpAllPrismicPosArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicPosArtistProfile;
    }
  }
  const artistProfiles = profiles.edges
    .map(({ node }) => {
      return node;
    })
    .filter(profile => {
      return uid === getArtistProfileId(profile);
    });

  return artistProfiles;
};

export const usePosArtistProfiles = (): Array<ArtistProfileGraphMdl> => {
  const { locale } = useLocaleContext();
  const { jpAllPrismicPosArtistProfile, allPrismicPosArtistProfile } = useCopyContext();
  let profiles = allPrismicPosArtistProfile;
  if (locale === LOCALES.japanese.code) {
    if (
      jpAllPrismicPosArtistProfile &&
      jpAllPrismicPosArtistProfile.edges &&
      jpAllPrismicPosArtistProfile.edges.length > 0
    ) {
      profiles = jpAllPrismicPosArtistProfile;
    }
  }
  return profiles.edges.map(({ node }) => {
    return node;
  });
};

const CopyContextWrapper = ({ data, children }: Props) => (
  <CopyContext.Provider value={data}>{children}</CopyContext.Provider>
);

export default CopyContextWrapper;
