// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useSubscriptionsContext } from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useShowSignUp } from '../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useAuthContext, useUserId } from '../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { useDownloadContext } from '../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  analyticsMixpanelPlayMicropart,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../analytics/mixpanel';
import { useAudioPlayerContext } from '../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';

const Container = styled('div')`
  display: flex;
  height: 100%;
`;

const partClassName = 'part';

const activePartCss = css`
  background: rgba(240, 240, 235, 0.05);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

const activePartClassName = 'part--active';
const hiddenPartClassName = 'part--hidden';

const Part = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 3px;
  transition: background 200ms ease, box-shadow 200ms ease;
  cursor: pointer;

  &.${activePartClassName} {
    &:not(.${hiddenPartClassName}) {
      ${activePartCss};
    }
  }
`;

const PartLabelWrapper = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
`;

const hiddenClass = css`
  display: none;
`;

type Props = {
  duration: number,
  microparts: Array<[number, number]>,
  microPartIndex: number,
  onMicropartSelect: number => void,
  setHoveredMicroPart: number | (null => void),
  condensed: boolean,
  onDownloadMicropart: number => void,
  micropartsLabelDuration: boolean,
  micropartsHiglight: number | null,
  hideMicroparts: boolean,
  onLoopSwap: (state: boolean) => void,
};

const MicroParts = ({
  duration,
  microparts,
  microPartIndex,
  onMicropartSelect,
  setHoveredMicroPart,
  condensed,
  onDownloadMicropart,
  micropartsLabelDuration,
  micropartsHiglight,
  hideMicroparts,
  onLoopSwap,
}: Props) => {
  const isMicropartsView = micropartsHiglight || micropartsHiglight === 0;
  const { userRole } = useSubscriptionsContext();
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const {
    setAnalyticsSentMicropartPosition,
    setAnalyticsSentMicropartSongId,
  } = useAudioPlayerContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const userId = useUserId();

  const playMicropartAnalytics = () => {
    analyticsMixpanelPlayMicropart(
      mixpanel,
      moengage,
      microPartIndex,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
    setAnalyticsSentMicropartSongId(mixpanelSongDimensions['Song ID']);
    setAnalyticsSentMicropartPosition(microPartIndex);
  };

  return (
    <Container>
      {microparts.map((micropart, index) => {
        const [beginning, end] = micropart;
        const width = end - beginning;
        const active = isMicropartsView ? index === micropartsHiglight : index === microPartIndex;
        const inactive = isMicropartsView && !active;
        return (
          <Part
            className={cx(partClassName, {
              [activePartClassName]: active,
              [hiddenPartClassName]: hideMicroparts,
            })}
            onClick={() => {
              if (inactive) return;
              if (!isAuthenticated) {
                showSignUp();
                return;
              }
              onLoopSwap(true);
              onMicropartSelect(index);
              playMicropartAnalytics();
            }}
            key={index.toString()}
            style={{
              width: `${width * 100}%`,
            }}
          >
            <PartLabelWrapper
              className={cx({
                [hiddenClass]: condensed || inactive,
              })}
            />
          </Part>
        );
      })}
    </Container>
  );
};

export default MicroParts;
