// @flow
import React from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import {
  useMiscCopy,
  useNoConnectionsModalCopy,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import MessageModal from '../MessageModal/MessageModal';
import {
  getNoConnectionsModalBusinessOnlyMessage,
  getNoConnectionsModalHeader,
  getNoConnectionsModalMessage,
} from '../../../cms/modals';
import { getMiscCopyContinue, getMiscCopyStay } from '../../../cms/miscCopy';
import Button, {
  BUTTON_WIDTHS,
  DarkButton,
  lessVerticalPaddingButtonClass,
  shorterButtonClass,
} from '../../../components/Button/Button';
import { useDoesUserOnlyHaveBusinessSubscription } from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';

const OptionsList = styled('ul')`
  display: flex;

  li {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const MessageOptions = styled(OptionsList)`
  margin-top: 25px;
`;

type Props = {
  onClose: () => void,
  onContinue: () => void,
};

const NoConnectionsModal = ({ onClose, onContinue }: Props) => {
  const noConnectionsCopy = useNoConnectionsModalCopy();
  const miscCopy = useMiscCopy();
  const businessTime = useDoesUserOnlyHaveBusinessSubscription();
  return (
    <MessageModal onClose={onClose}>
      <h2>{getNoConnectionsModalHeader(noConnectionsCopy)}</h2>
      <div>
        {parse(
          businessTime
            ? getNoConnectionsModalBusinessOnlyMessage(noConnectionsCopy)
            : getNoConnectionsModalMessage(noConnectionsCopy)
        )}
      </div>
      <MessageOptions>
        <li>
          <DarkButton className={shorterButtonClass} width={BUTTON_WIDTHS.small} onClick={onClose}>
            {getMiscCopyStay(miscCopy)}
          </DarkButton>
        </li>
        <li>
          <Button
            className={shorterButtonClass}
            width={BUTTON_WIDTHS.small}
            onClick={() => {
              onClose();
              onContinue();
            }}
          >
            {getMiscCopyContinue(miscCopy)}
          </Button>
        </li>
      </MessageOptions>
    </MessageModal>
  );
};

export default NoConnectionsModal;
