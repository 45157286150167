// @flow
import React, { useEffect, useState, useContext } from 'react';
import loadjs from 'loadjs';

declare var Chargebee;

export type ChargebeeContextState = {
  chargebeeLoaded: boolean,
  chargebeeInstance: any,
  loadChargebee: () => {},
};

const ChargebeeContext = React.createContext<any>();

export const useChargebeeContext = () => {
  return useContext(ChargebeeContext);
};

type Props = {
  children: any,
};

const ChargebeeWrapper = ({ children }: Props) => {
  const [chargebeeLoaded, setChargebeeLoaded] = useState(false);
  const [chargebeeInstance, setChargebeeInstance] = useState();

  const loadChargebee = async () => {
    await loadjs(['https://js.chargebee.com/v2/chargebee.js'], { returnPromise: true }).then(
      async () => {
        console.log('loaded chargebee');
        setChargebeeLoaded(true);

        let cbInstance;
        try {
          cbInstance = await Chargebee.init({
            // iframe_only: true,
            site: process.env.GATSBY_CHARGEBEE_SITE_NAME,
          });
        } catch (error) {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when initializing chargebee');
          Sentry.captureException(error);
          console.error(`chargebee error`, error);
          return;
        }

        setChargebeeInstance(cbInstance);
      }
    );
  };

  useEffect(() => {
    loadChargebee();
  }, []);

  return (
    <ChargebeeContext.Provider
      value={{
        chargebeeLoaded,
        chargebeeInstance,
        loadChargebee,
      }}
    >
      {children}
    </ChargebeeContext.Provider>
  );
};

export default ChargebeeWrapper;
