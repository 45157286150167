// @flow
import React from 'react';
import { css } from 'emotion';
import Button, { BUTTON_WIDTHS } from '../../../../../components/Button/Button';
import {
  HeaderOptionsContainer,
  HeaderOptionsInstruction,
} from '../../../SignInScreen/components/SignInHeaderOptions/SignInHeaderOptions';
import {
  useMiscCopy,
  useSignUpPage,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getSignInButton, getSignInPrompt } from '../../../../../cms/signup';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import { getMiscCopySkipButton } from '../../../../../cms/miscCopy';

const signInButtonLayout = css`
  background-color: #4d4d4d;
`;

type Props = {
  path: string,
};

const SignUpHeaderOptions = ({ path }: Props) => {
  const miscCopy = useMiscCopy();
  const signUpPage = useSignUpPage();
  const navigate = useNavigate();
  if (`/${path}` === ROUTES.signup.signupPath) {
    const handleSignUp = () => {
      navigate(ROUTES.signin.navigatePath({}));
    };
    return (
      <HeaderOptionsContainer data-cy="signUpPageHeader">
        <HeaderOptionsInstruction>{getSignInPrompt(signUpPage)}</HeaderOptionsInstruction>
        <Button className={signInButtonLayout} onClick={handleSignUp} width={BUTTON_WIDTHS.compact}>
          {getSignInButton(signUpPage)}
        </Button>
      </HeaderOptionsContainer>
    );
  }
  if (`/${path}` === ROUTES.signupPaypal.signupPath) {
    const handleSkip = () => {
      navigate(ROUTES.signupCompleted.navigatePath({}));
    };
    return (
      <HeaderOptionsContainer>
        <Button width={BUTTON_WIDTHS.compact} onClick={handleSkip}>
          {getMiscCopySkipButton(miscCopy)}
        </Button>
      </HeaderOptionsContainer>
    );
  }
  return null;
};

export default SignUpHeaderOptions;
