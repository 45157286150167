// @flow
import React, { useState, useContext, useEffect } from 'react';
import { uniqBy } from 'lodash';
import { useGetUserDownloads } from '../DownloadHistory/DownloadHistory';
import type { DownloadHistoryWrapperMdl } from '../../../../../../../api/firebase/user/downloads';
import {
  getDownloadHistoryLocation,
  getDownloadHistoryTimestamp,
  getDownloadHistoryTrack,
  getDownloadMicropart,
} from '../../../../../../../api/firebase/user/downloads';
import { getDateString } from '../../../../../../../utils/date';
import {
  getSongID,
  isSongFullMix,
  isSongSfx,
  isSongStem,
} from '../../../../../../../api/algolia/song';
import type { NextGroupedSongData } from '../../../ProfileBookmarksView/components/BookmarksManager/BookmarksManager';
import type { GroupedSongs } from '../DownloadHistory/DownloadHistory';

export type DownloadHistoryManagerContextState = {
  numberOfVisibleGroups: number,
  setNumberOfVisibleGroups: number => void,
  groupedDownloads: Array<GroupedSongs>,
  remainingGroups: boolean,
  goToNextGroupedSong: (string, string) => NextGroupedSongData,
  getGroupedSongIndex: (songId: string, expandKey: string, groupedDownloads: any) => number,
  isNextSongPlayable: (string, string) => boolean,
  downloadsBusy: boolean,
  handleDownloadsTab: (songType: string) => void,
  viewingDownloads: string,
  fullMixAmount: number,
  sfxAmount: number,
  stemsAmount: number,
  loading: boolean,
};

const LOCATION_DOWNLOADS = 'downloads';

export const DownloadHistoryContext = React.createContext<any>();

export const useDownloadsManagerContext = (): DownloadHistoryManagerContextState => {
  return useContext(DownloadHistoryContext);
};

const isDownloadNotMicropart = (download: DownloadHistoryWrapperMdl): boolean => {
  return getDownloadMicropart(download) === null;
};

const getGroupedDownloads = (downloads: Array<DownloadHistoryWrapperMdl>): Array<GroupedSongs> => {
  const filteredDownloads = downloads.filter(download => {
    return (
      getDownloadHistoryLocation(download) !== LOCATION_DOWNLOADS &&
      isDownloadNotMicropart(download)
    );
  });

  const sortedDownloads = filteredDownloads.sort(
    (downloadA: DownloadHistoryWrapperMdl, downloadB: DownloadHistoryWrapperMdl) => {
      return getDownloadHistoryTimestamp(downloadB) - getDownloadHistoryTimestamp(downloadA);
    }
  );
  const dateOrder = [];
  let groups = {};
  sortedDownloads.forEach((download: DownloadHistoryWrapperMdl) => {
    const date = getDateString(getDownloadHistoryTimestamp(download));
    const keywords = [];
    const keywordsKey = keywords.join('::');
    const track = getDownloadHistoryTrack(download);
    if (groups[date]) {
      let group = groups[date];
      if (group[keywordsKey]) {
        group = {
          ...group,
          [keywordsKey]: {
            ...group[keywordsKey],
            tracks: group[keywordsKey].tracks.concat([track]),
          },
        };
        groups = {
          ...groups,
          [date]: group,
        };
      } else {
        group[keywordsKey] = {
          date: getDownloadHistoryTimestamp(download),
          keywords,
          tracks: [track],
        };
      }
    } else {
      groups[date] = {
        [keywordsKey]: {
          date: getDownloadHistoryTimestamp(download),
          keywords,
          tracks: [track],
        },
      };
      dateOrder.push(date);
    }
  });
  const finalGroups = [];
  dateOrder.forEach((dateKey: string) => {
    const groupCollection = groups[dateKey];
    Object.keys(groupCollection).forEach(groupKey => {
      const group = groupCollection[groupKey];
      finalGroups.push(group);
    });
  });
  return finalGroups.map((group: GroupedSongs) => {
    return {
      ...group,
      tracks: uniqBy(group.tracks, song => {
        return getSongID(song);
      }),
    };
  });
};

const getGroupedSong = (
  index: number,
  totalBookmarks: number,
  groupedBookmarks: Array<GroupedSongs>
): {
  nextSong: AlgoliaSongMdl | null | typeof undefined,
  nextExpandKey: string | null,
} => {
  if (index >= totalBookmarks) {
    return { nextSong: null, nextExpandKey: null };
  }

  let nextSong;
  let nextExpandKey = '';
  let groupedSongIndex = 0;
  groupedBookmarks.forEach(group => {
    const groupTracks = group.tracks;
    const currentDate = group.date;
    groupTracks.forEach(track => {
      if (groupedSongIndex === index) {
        nextSong = track;
        nextExpandKey = `${currentDate}::${getSongID(track)}`;
      }
      groupedSongIndex++;
    });
  });
  return { nextSong, nextExpandKey };
};

const getGroupedSongIndex = (
  songId: string,
  expandKey: string,
  groupedBookmarks: Array<GroupedSongs>
): number => {
  let currentSongIndex = 0;
  let groupedSongIndex = 0;

  if (!Array.isArray(groupedBookmarks)) {
    return 0;
  }

  groupedBookmarks.forEach(group => {
    const groupTracks = group.tracks;
    const currentDate = group.date;
    groupTracks.forEach(track => {
      const currentSongId = getSongID(track);
      const currentExpandKey = `${currentDate}::${currentSongId}`;
      if (currentSongId === songId && currentExpandKey === expandKey) {
        currentSongIndex = groupedSongIndex;
      }
      groupedSongIndex++;
    });
  });

  return currentSongIndex;
};

const getGroupedTracksLength = (downloads: Array<DownloadHistoryWrapperMdl>): number => {
  const groupedDownloads = getGroupedDownloads(downloads);

  if (groupedDownloads.length < 1) return 0;

  if (groupedDownloads.length === 1) return groupedDownloads[0].tracks.length;

  return groupedDownloads
    .map(groupedDownload => groupedDownload.tracks.length)
    .reduce((a, b) => {
      return a + b;
    });
};

const handleNextSongPlayable = (
  songId: string,
  expandKey: string,
  groupedBookmarks: any,
  numberOfVisibleGroups: number
) => {
  const songIndex = getGroupedSongIndex(songId, expandKey, groupedBookmarks);
  let maxSongIndex = -1;

  groupedBookmarks.forEach((group, groupIndex) => {
    const groupTracks = group.tracks;
    if (groupIndex <= numberOfVisibleGroups - 1) {
      groupTracks.forEach(() => {
        maxSongIndex++;
      });
    }
  });

  if (songIndex >= maxSongIndex) {
    return false;
  }

  return true;
};

type Props = {
  children: any,
};

const DownloadHistoryManager = ({ children }: Props) => {
  const [numberOfVisibleGroups, setNumberOfVisibleGroups] = useState(10);
  const [downloads, downloadsBusy] = useGetUserDownloads();
  const [viewingDownloads, setViewingDownloads] = useState('fullMixes');
  const [visibleDownloads, setVisibleDownloads] = useState([]);
  const [fullMixDownloads, setFullMixDownloads] = useState([]);
  const [stemDownloads, setStemDownloads] = useState([]);
  const [sfxDownloads, setSfxDownloads] = useState([]);
  const [fullMixAmount, setFullMixAmount] = useState(0);
  const [stemsAmount, setStemsAmount] = useState(0);
  const [sfxAmount, setSfxAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const validDownloadHistory = downloads.filter(download => {
      return (
        getDownloadHistoryLocation(download) !== LOCATION_DOWNLOADS &&
        isDownloadNotMicropart(download)
      );
    });

    const retrievedFullMixDownloads = validDownloadHistory.filter(validDownload => {
      const downloadTrack = getDownloadHistoryTrack(validDownload);
      return downloadTrack ? isSongFullMix(downloadTrack) : false;
    });

    const retrievedStemDownloads = validDownloadHistory.filter(validDownload => {
      const downloadTrack = getDownloadHistoryTrack(validDownload);
      return downloadTrack ? isSongStem(downloadTrack) : false;
    });

    const retrievedSfxDownloads = validDownloadHistory.filter(validDownload => {
      const downloadTrack = getDownloadHistoryTrack(validDownload);
      return downloadTrack ? isSongSfx(downloadTrack) : false;
    });

    const fullMixQuantity = getGroupedTracksLength(retrievedFullMixDownloads);
    const stemQuantity = getGroupedTracksLength(retrievedStemDownloads);
    const sfxQuantity = getGroupedTracksLength(retrievedSfxDownloads);

    setFullMixDownloads(retrievedFullMixDownloads);
    setStemDownloads(retrievedStemDownloads);
    setSfxDownloads(retrievedSfxDownloads);
    setFullMixAmount(fullMixQuantity);
    setStemsAmount(stemQuantity);
    setSfxAmount(sfxQuantity);

    if (fullMixQuantity === 0 && viewingDownloads === 'fullMixes' && stemQuantity > 0) {
      setViewingDownloads('stems');
    }

    if (stemQuantity === 0 && viewingDownloads === 'stems' && fullMixQuantity > 0) {
      setViewingDownloads('fullMixes');
    }
  }, [downloads]);

  useEffect(() => {
    setLoading(true);
    if (viewingDownloads === 'fullMixes') {
      setVisibleDownloads(fullMixDownloads);
    }
    if (viewingDownloads === 'stems') {
      setVisibleDownloads(stemDownloads);
    }
    if (viewingDownloads === 'sfx') {
      setVisibleDownloads(sfxDownloads);
    }
  }, [viewingDownloads, fullMixDownloads, stemDownloads, sfxDownloads]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [visibleDownloads]);

  const groupedDownloads = getGroupedDownloads(visibleDownloads);
  const remainingGroups = groupedDownloads.length > numberOfVisibleGroups;

  const goToNextGroupedSong = (songId: string, expandKey: string): NextGroupedSongData => {
    const currentGroupedSongIndex = getGroupedSongIndex(songId, expandKey, groupedDownloads);
    const nextSongIndex = currentGroupedSongIndex + 1;
    const totalBookmarks = downloads.length;
    const { nextSong, nextExpandKey } = getGroupedSong(
      nextSongIndex,
      totalBookmarks,
      groupedDownloads
    );

    if (!nextSong) {
      return { nextGroupedSongId: null, nextExpandKey: null };
    }

    const nextGroupedSongId = getSongID(nextSong);
    return { nextGroupedSongId, nextExpandKey };
  };

  const isNextSongPlayable = (songId: string, expandKey: string): boolean => {
    return handleNextSongPlayable(songId, expandKey, groupedDownloads, numberOfVisibleGroups);
  };

  const handleDownloadsTab = (songType: string) => {
    setViewingDownloads(songType);
  };

  return (
    <DownloadHistoryContext.Provider
      value={{
        numberOfVisibleGroups,
        setNumberOfVisibleGroups,
        groupedDownloads,
        remainingGroups,
        goToNextGroupedSong,
        getGroupedSongIndex,
        isNextSongPlayable,
        downloadsBusy,
        handleDownloadsTab,
        viewingDownloads,
        fullMixAmount,
        stemsAmount,
        sfxAmount,
        loading,
      }}
    >
      {children}
    </DownloadHistoryContext.Provider>
  );
};

export default DownloadHistoryManager;
