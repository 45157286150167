// @flow
import React, { useEffect } from 'react';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../ClassicModalLayout/ClassicModalLayout';
import Button, {
  BUTTON_WIDTHS,
  extraVerticalPaddingButtonClass,
  PlainTextButton,
} from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import {
  useEnterpriseContactModalCopy,
  useMiscCopy,
  useSignUpModal,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getSignUpModalHeading, getSignUpModalMessage } from '../../../cms/signup';
import { getMiscCopySignIn, getMiscCopySignUp } from '../../../cms/miscCopy';
import { storeCurrentLocationAsAuthDestination } from '../../../auth/destination';
import { useModalsContext } from '../ModalsWrapper/ModalsWrapper';
import { planCodeToCurrentPlanKey } from '../../../utils/subscriptions';
import { useAuthContext } from '../../../auth/components/AuthWrapper/AuthWrapper';
import {
  getEnterpriseContactModalDescription,
  getEnterpriseContactModalHeading,
} from '../../../cms/enterpriseContactModal';

type Props = {
  onClose: () => void,
};

const EnterpriseContactModal = ({ onClose }: Props) => {
  const { setSignInOpen } = useModalsContext();
  const { signUpSourceData, setSignUpSourceData } = useAuthContext();
  const localePath = useLocalePath();
  const miscCopy = useMiscCopy();
  const copy = useEnterpriseContactModalCopy();
  const handleSignIn = () => {
    setSignInOpen(true);
    onClose();
  };
  const handleSignUp = () => {
    if (signUpSourceData.signUpSource !== 'Landing Page') {
      setSignUpSourceData({ signUpSource: 'Pricing', signUpCampaign: '' });
    }
    const chosenPlan = planCodeToCurrentPlanKey('enterprise1');
    storeCurrentLocationAsAuthDestination();
    onClose();
  };
  return (
    <ClassicModalLayout onClose={onClose}>
      <ClassicModalContentLayout
        heading={getEnterpriseContactModalHeading(copy)}
        description={getEnterpriseContactModalDescription(copy)}
        footer={
          <Button
            className={extraVerticalPaddingButtonClass}
            onClick={handleSignUp}
            to={ROUTES.signup.navigatePath({
              localePath,
              chosenPlan: 'enterprise',
              selectedInterval: 'monthly',
            })}
            mobileWidth={BUTTON_WIDTHS.full}
          >
            {getMiscCopySignUp(miscCopy)}
          </Button>
        }
      />
    </ClassicModalLayout>
  );
};

export default EnterpriseContactModal;
