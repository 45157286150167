// @flow
import {
  getMagicSearchQueryParams,
  getYouTubeSearchQueryParams,
  getSimilaritySearchQuery,
} from './screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';

import { getSearchQueryParams } from './screens/MusicScreen/views/SearchView/components/ResultsManager/ResultsManager';

export const PATHS = {
  mainApp: '/*',
  landing: '/',
  browse: '/browse',
  find: '/find',
  music: '/music/*',
  genres: '/music/genre',
  moods: '/music/mood',
  instruments: '/music/instruments',
  sfx: '/music/sfx',
  collections: '/music/collections',
  collection: '/music/collections/:collectionSlug',
  artists: '/music/artists',
  artist: '/music/artists/:artistSlug',
  posartist: '/music/posartists/:artistSlug',
  musicSearch: '/music/search',
  musicPack: '/music/pack/:shareSlug',
  similarSearch: '/music/similar/:songId',
  bookmarks: '/profile/bookmarks',
  bookmarksCollection: '/bookmarks/collections/:collectionSlug',
  bookmarksFilter: '/bookmarks/filter',
  results: '/find/results',
  signup: '/signup/*',
  signupSubscriptions: '/signup/subscriptions',
  signupPaypal: '/signup/paypal',
  signupEnterprise: '/signup/enterprise',
  signupEnterpriseSubscriptions: '/signup/enterprise-subscriptions',
  signupCompleted: '/signup/completed',
  signin: '/signin',
  callback: '/callback',
  error: '/error',
  directSong: '/direct/:shareSlug',
  errorCode: '/error/:code/*',
  profile: '/profile/*',
  subscriptions: '/profile/subscriptions',
  billing: '/profile/billing',
  revenue: '/profile/revenue',
  downloads: '/profile/downloads',
  email: '/profile/email',
  terms: '/terms',
  creatorLicense: '/license/creator',
  creatorProLicense: '/license/creator-pro',
  businessLicense: '/license/business',
  enterpriseLicense: '/license/enterprise',
  limitedMediaTrackLicense: '/license/limited-media-track',
  allMediaTrackLicense: '/license/all-media-track',
  exclusiveRightsTrackLicense: '/license/exclusive-right-track',
  privacy: '/privacy',
  embedSong: '/embed/:shareSlug',
  embedSongJapanese: '/ja/embed/:shareSlug',
  embedSongChinese: '/cn/embed/:shareSlug',
  referral: '/referral/:referralCode',
  chromeExtension: '/chromeExtension',
  pricing: '/pricing',
};

export const getLocalisedPath = (localePath: string, path: string): string =>
  `${localePath}${path}`;

type NavigatePathParams = {
  localePath?: string,
};

type NavigateSearchPathParam = {
  keywords?: Array<string>,
  magicKeywords?: Array<string>,
  ytKeywords?: Array<string>,
  channelId?: string,
  channelTitle?: string,
  videoId?: string,
  videoTitle?: string,
  similarityKeywords?: Array<string>,
  similarSong?: string,
  minBpm?: number,
  maxBpm?: number,
  section?: string,
};

type NavigateSubscriptionPathParam = {
  chosenPlan?: string,
  selectedInterval?: string,
};

export const ROUTES = {
  bookmarks: {
    path: PATHS.bookmarks,
    bookmarksPath: '/',
    navigatePath: ({
      localePath = '',
      keywords = [],
      magicKeywords = [],
      section = '',
    }: NavigatePathParams & NavigateSearchPathParam) => {
      const magickeywordsQuery =
        magicKeywords.length > 0 ? `&${getMagicSearchQueryParams(magicKeywords)}` : '';
      const sectionQuery = section ? `?viewing=${section}` : '';
      return getLocalisedPath(
        localePath,
        `${PATHS.bookmarks}${
          (keywords && keywords.length > 0) || (magicKeywords && magicKeywords.length > 0)
            ? `?${getSearchQueryParams(keywords)}${magickeywordsQuery}${sectionQuery}`
            : `${sectionQuery}`
        }`
      );
    },
    profilePath: 'bookmarks',
  },
  bookmarksCollection: {
    path: PATHS.bookmarksCollection,
    navigatePath: ({
      localePath = '',
      collectionSlug = '',
    }: NavigatePathParams & {
      collectionSlug: string,
    }) => getLocalisedPath(localePath, `/bookmarks/collections/${collectionSlug}`),
    bookmarksPath: '/collections/:collectionSlug',
  },
  bookmarksFilter: {
    path: PATHS.bookmarksFilter,
    navigatePath: ({
      localePath = '',
      keywords = [],
      magicKeywords = [],
    }: NavigatePathParams & NavigateSearchPathParam) => {
      const magickeywordsQuery =
        magicKeywords.length > 0 ? `&${getMagicSearchQueryParams(magicKeywords)}` : '';
      return getLocalisedPath(
        localePath,
        `${PATHS.bookmarksFilter}${
          (keywords && keywords.length > 0) || (magicKeywords && magicKeywords.length > 0)
            ? `?${getSearchQueryParams(keywords)}${magickeywordsQuery}`
            : ''
        }`
      );
    },
  },
  results: {
    path: PATHS.results,
    navigatePath: ({
      localePath = '',
      keywords = [],
      magicKeywords = [],
      ytKeywords = [],
      channelId = '',
      channelTitle = '',
      videoId = '',
      videoTitle = '',
      similarityKeywords = [],
      similarSong = '',
      minBpm = 0,
      maxBpm = 0,
    }: NavigatePathParams & NavigateSearchPathParam) => {
      const keywordsQuery = getSearchQueryParams(keywords);
      const magickeywordsQuery =
        magicKeywords.length > 0 ? `&${getMagicSearchQueryParams(magicKeywords)}` : '';

      const youtubeKeywordsQuery =
        ytKeywords.length > 0 ? `&${getYouTubeSearchQueryParams(ytKeywords)}` : '';
      const channelIdSearchQuery = channelId ? `&channelId=${channelId}` : '';
      const channelTitleSearchQuery = channelTitle ? `&channelTitle=${channelTitle}` : '';
      const videoIdSearchQuery = videoId ? `&videoId=${videoId}` : '';
      const videoTitleSearchQuery = videoTitle ? `&videoTitle=${videoTitle}` : '';
      const youtubeQuery = `${youtubeKeywordsQuery}${channelIdSearchQuery}${channelTitleSearchQuery}${videoIdSearchQuery}${videoTitleSearchQuery}`;

      const similaritySearchQuery =
        similarityKeywords.length > 0 ? `&${getSimilaritySearchQuery(similarityKeywords)}` : '';
      const bpmSearchQuery = minBpm && maxBpm ? `&sbpm=${minBpm}-${maxBpm}` : '';
      const similarSongQuery = similarSong ? `&similar=${similarSong}` : '';

      const similarityQuery = `${similaritySearchQuery}${bpmSearchQuery}${similarSongQuery}`;

      return getLocalisedPath(
        localePath,
        `${PATHS.results}${
          (keywords && keywords.length > 0) ||
          (magicKeywords && magicKeywords.length > 0) ||
          (ytKeywords && ytKeywords.length > 0) ||
          (similarityKeywords && similarityKeywords.length > 0)
            ? `?${keywordsQuery}${magickeywordsQuery}${youtubeQuery}${similarityQuery}`
            : ''
        }`
      );
    },
  },
  find: {
    path: PATHS.find,
    navigatePath: ({
      localePath = '',
      keywords = [],
      magicKeywords = [],
      ytKeywords = [],
      channelId = '',
      channelTitle = '',
      videoId = '',
      videoTitle = '',
    }: NavigatePathParams & NavigateSearchPathParam) => {
      const keywordsQuery = getSearchQueryParams(keywords);
      const magickeywordsQuery =
        magicKeywords.length > 0 ? `&${getMagicSearchQueryParams(magicKeywords)}` : '';

      const youtubeKeywordsQuery =
        ytKeywords.length > 0 ? `&${getYouTubeSearchQueryParams(ytKeywords)}` : '';
      const channelIdSearchQuery = channelId ? `&channelId=${channelId}` : '';
      const channelTitleSearchQuery = channelTitle ? `&channelTitle=${channelTitle}` : '';
      const videoIdSearchQuery = videoId ? `&videoId=${videoId}` : '';
      const videoTitleSearchQuery = videoTitle ? `&videoTitle=${videoTitle}` : '';
      const youtubeQuery = `${youtubeKeywordsQuery}${channelIdSearchQuery}${channelTitleSearchQuery}${videoIdSearchQuery}${videoTitleSearchQuery}`;

      return getLocalisedPath(
        localePath,
        `${PATHS.find}${
          (keywords && keywords.length > 0) ||
          (magicKeywords && magicKeywords.length > 0) ||
          (ytKeywords && ytKeywords.length > 0)
            ? `?${keywordsQuery}${magickeywordsQuery}${youtubeQuery}`
            : ''
        }`
      );
    },
  },
  music: {
    path: PATHS.music,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, '/music'),
    musicPath: '/',
  },
  genres: {
    path: PATHS.genres,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.genres),
    musicPath: '/genre',
  },
  moods: {
    path: PATHS.moods,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.moods),
    musicPath: '/mood',
  },
  instruments: {
    path: PATHS.instruments,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.instruments),
    musicPath: '/instruments',
  },
  sfx: {
    path: PATHS.sfx,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.sfx),
    musicPath: '/sfx',
  },
  collections: {
    path: PATHS.collections,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.collections),
    musicPath: '/collections',
  },
  collection: {
    path: PATHS.collection,
    navigatePath: ({
      localePath = '',
      collectionSlug = '',
    }: NavigatePathParams & {
      collectionSlug: string,
    }) => getLocalisedPath(localePath, `/music/collections/${collectionSlug}`),
    musicPath: '/collections/:collectionSlug',
  },
  artists: {
    path: PATHS.artists,
    navigatePath: ({ localePath = '' }: NavigatePathParams) => {
      return getLocalisedPath(localePath, PATHS.artists);
    },
    musicPath: '/artists',
  },
  artist: {
    path: PATHS.artist,
    navigatePath: ({
      localePath = '',
      artistSlug = '',
    }: NavigatePathParams & {
      artistSlug: string,
    }) => getLocalisedPath(localePath, `/music/artists/${artistSlug}`),
    musicPath: '/artists/:artistSlug',
  },
  posartist: {
    path: PATHS.posartist,
    navigatePath: ({
      localePath = '',
      artistSlug = '',
    }: NavigatePathParams & {
      artistSlug: string,
    }) => getLocalisedPath(localePath, `/music/posartists/${artistSlug}`),
    musicPath: '/posartists/:artistSlug',
  },
  musicSearch: {
    path: PATHS.musicSearch,
    navigatePath: ({
      localePath = '',
      keywords = [],
      magicKeywords = [],
      ytKeywords = [],
      channelId = '',
      channelTitle = '',
      videoId = '',
      videoTitle = '',
      section = '',
    }: NavigatePathParams & NavigateSearchPathParam) => {
      const keywordsQuery = getSearchQueryParams(keywords);
      const magickeywordsQuery =
        magicKeywords.length > 0 ? `&${getMagicSearchQueryParams(magicKeywords)}` : '';

      const youtubeKeywordsQuery =
        ytKeywords.length > 0 ? `&${getYouTubeSearchQueryParams(ytKeywords)}` : '';
      const channelIdSearchQuery = channelId ? `&channelId=${channelId}` : '';
      const channelTitleSearchQuery = channelTitle ? `&channelTitle=${channelTitle}` : '';
      const videoIdSearchQuery = videoId ? `&videoId=${videoId}` : '';
      const videoTitleSearchQuery = videoTitle ? `&videoTitle=${videoTitle}` : '';
      const youtubeQuery = `${youtubeKeywordsQuery}${channelIdSearchQuery}${channelTitleSearchQuery}${videoIdSearchQuery}${videoTitleSearchQuery}`;
      const sectionQuery = section ? `&viewing=${section}` : '';

      return getLocalisedPath(
        localePath,
        `${PATHS.musicSearch}${
          (keywords && keywords.length > 0) ||
          (magicKeywords && magicKeywords.length > 0) ||
          (ytKeywords && ytKeywords.length > 0)
            ? `?${keywordsQuery}${magickeywordsQuery}${youtubeQuery}${sectionQuery}`
            : ''
        }`
      );
    },
    musicPath: '/search',
  },
  musicPack: {
    path: PATHS.musicPack,
    navigatePath: ({
      localePath = '',
      shareSlug = '',
    }: NavigatePathParams & {
      shareSlug: string,
    }) => getLocalisedPath(localePath, `/music/pack/${shareSlug}`),
    musicPath: '/pack/:shareSlug',
  },
  similarSearch: {
    path: PATHS.similarSearch,
    navigatePath: ({
      localePath = '',
      songId = '',
    }: NavigatePathParams & {
      songId: string,
    }) => {
      return getLocalisedPath(localePath, `/music/similar/${songId}`);
    },
    musicPath: '/similar/:songId',
  },
  privacy: {
    path: PATHS.privacy,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.privacy),
  },
  terms: {
    path: PATHS.terms,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.terms),
  },
  creatorLicense: {
    path: PATHS.creatorLicense,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.creatorLicense),
  },
  creatorProLicense: {
    path: PATHS.creatorProLicense,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.creatorProLicense),
  },
  businessLicense: {
    path: PATHS.businessLicense,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.businessLicense),
  },
  enterpriseLicense: {
    path: PATHS.enterpriseLicense,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.enterpriseLicense),
  },
  profile: {
    path: PATHS.profile,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, '/profile'),
    profilePath: '/',
  },
  subscriptions: {
    path: PATHS.subscriptions,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.subscriptions),
    profilePath: '/subscriptions',
  },
  billing: {
    path: PATHS.billing,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.billing),
    profilePath: '/billing',
  },
  revenue: {
    path: PATHS.revenue,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.revenue),
    profilePath: '/revenue',
  },
  downloads: {
    path: PATHS.downloads,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.downloads),
    profilePath: '/downloads',
  },
  email: {
    path: PATHS.email,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.email),
    profilePath: '/email',
  },
  landing: {
    path: PATHS.landing,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.landing),
  },
  browse: {
    path: PATHS.browse,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.browse),
  },
  signup: {
    path: PATHS.signup,
    navigatePath: ({
      localePath = '',
      chosenPlan = '',
      selectedInterval = '',
    }: NavigatePathParams & NavigateSubscriptionPathParam) => {
      const subscriptionParam = chosenPlan ? `plan=${chosenPlan}` : '';
      const intervalParam = selectedInterval ? `&billing=${selectedInterval}` : '';
      const params =
        subscriptionParam || intervalParam ? `?${subscriptionParam}${intervalParam}` : '';
      const signUpPath = `${getLocalisedPath(localePath, '/signup')}${params}`;
      return signUpPath;
    },
    signupPath: '/',
  },
  signupSubscriptions: {
    path: PATHS.signupSubscriptions,
    navigatePath: ({
      localePath = '',
      chosenPlan = '',
      selectedInterval = '',
    }: NavigatePathParams & NavigateSubscriptionPathParam) => {
      const subscriptionParam = chosenPlan ? `plan=${chosenPlan}` : '';
      const intervalParam = selectedInterval ? `&billing=${selectedInterval}` : '';
      const params =
        subscriptionParam || intervalParam ? `?${subscriptionParam}${intervalParam}` : '';
      const signUpSubscriptionsPath = `${getLocalisedPath(
        localePath,
        PATHS.signupSubscriptions
      )}${params}`;
      return signUpSubscriptionsPath;
    },
    signupPath: '/subscriptions',
  },
  signupPaypal: {
    path: PATHS.signupPaypal,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.signupPaypal),
    signupPath: '/paypal',
  },
  signupEnterprise: {
    path: PATHS.signupEnterprise,
    navigatePath: ({
      localePath = '',
      chosenPlan = '',
      selectedInterval = '',
    }: NavigatePathParams & NavigateSubscriptionPathParam) => {
      const subscriptionParam = chosenPlan ? `plan=${chosenPlan}` : '';
      const intervalParam = selectedInterval ? `&billing=${selectedInterval}` : '';
      const params =
        subscriptionParam || intervalParam ? `?${subscriptionParam}${intervalParam}` : '';
      const signUpEnterprisePath = `${getLocalisedPath(
        localePath,
        PATHS.signupEnterprise
      )}${params}`;
      return signUpEnterprisePath;
    },
    signupPath: '/enterprise',
  },
  signupEnterpriseSubscriptions: {
    path: PATHS.signupEnterpriseSubscriptions,
    navigatePath: ({
      localePath = '',
      chosenPlan = '',
      selectedInterval = '',
    }: NavigatePathParams & NavigateSubscriptionPathParam) => {
      const subscriptionParam = chosenPlan ? `plan=${chosenPlan}` : '';
      const intervalParam = selectedInterval ? `&billing=${selectedInterval}` : '';
      const params =
        subscriptionParam || intervalParam ? `?${subscriptionParam}${intervalParam}` : '';
      const signUpEnterpriseSubscriptionsPath = `${getLocalisedPath(
        localePath,
        PATHS.signupEnterpriseSubscriptions
      )}${params}`;
      return signUpEnterpriseSubscriptionsPath;
    },
    signupPath: '/enterprise-subscriptions',
  },
  signupCompleted: {
    path: PATHS.signupCompleted,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.signupCompleted),
    signupPath: '/completed',
  },
  signin: {
    path: PATHS.signin,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.signin),
  },
  callback: {
    path: PATHS.callback,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.callback),
  },
  error: {
    path: PATHS.error,
    navigatePath: ({
      localePath = '',
      errorCode = '',
    }: NavigatePathParams & {
      errorCode: string,
    }) => getLocalisedPath(localePath, `/error${errorCode ? `/${errorCode}` : ``}`),
  },
  directSong: {
    path: PATHS.directSong,
    navigatePath: ({
      localePath = '',
      shareSlug = '',
    }: NavigatePathParams & {
      shareSlug: string,
    }) => getLocalisedPath(localePath, `/direct/${shareSlug}`),
  },
  embedSong: {
    path: PATHS.embedSong,
    navigatePath: ({
      localePath = '',
      shareSlug = '',
    }: NavigatePathParams & {
      shareSlug: string,
    }) => getLocalisedPath(localePath, `/embed/${shareSlug}`),
  },
  referral: {
    path: PATHS.referral,
    navigatePath: ({
      localePath = '',
      referralCode = '',
    }: NavigatePathParams & {
      referralCode?: string,
    }) => getLocalisedPath(localePath, `/referral/${referralCode}`),
  },
  pricing: {
    path: PATHS.pricing,
    navigatePath: ({ localePath = '' }: NavigatePathParams) =>
      getLocalisedPath(localePath, PATHS.pricing),
  },
};
