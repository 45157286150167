// @flow
import React from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import { css } from 'emotion';
import { LargeHeading } from '../../../styles/typography/components/Heading/Heading';
import GenericCopy from '../../../styles/typography/components/GenericCopy/GenericCopy';
import { spacing } from '../../../styles/config/spacing';
import { MediumContainer } from '../../../styles/layout';
import {
  useErrorScreens,
  useSiteHeader,
} from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getAuthErrorDescription,
  getAuthErrorHeading,
  getGenericErrorDescription,
  getGenericErrorHeading,
} from '../../../cms/errorScreens';
import Button from '../../../components/Button/Button';
import { useLocalePath } from '../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../routing/routes';
import { getHeaderSignInButton } from '../../../cms/header';

const Footer = styled('footer')`
  margin-top: ${spacing.huge}px;
`;

const descriptionClass = css`
  margin-top: ${spacing.large}px;
`;

type Props = {
  heading: string,
  description?: string,
  footer: any,
};

const ErrorInfo = ({ heading, description, footer }: Props) => (
  <MediumContainer>
    <LargeHeading mono>{heading}</LargeHeading>
    {description && <GenericCopy className={descriptionClass}>{parse(description)}</GenericCopy>}
    {footer && <Footer>{footer}</Footer>}
  </MediumContainer>
);

export default ErrorInfo;

export const GenericErrorInfo = () => {
  const copy = useErrorScreens();
  return (
    <ErrorInfo
      heading={getGenericErrorHeading(copy)}
      description={getGenericErrorDescription(copy)}
    />
  );
};

export const GenericAuthErrorInfo = () => {
  const localePath = useLocalePath();
  const copy = useErrorScreens();
  const siteHeader = useSiteHeader();
  return (
    <ErrorInfo
      heading={getAuthErrorHeading(copy)}
      description={getAuthErrorDescription(copy)}
      footer={
        <Button to={ROUTES.signin.navigatePath({ localePath })}>
          {getHeaderSignInButton(siteHeader)}
        </Button>
      }
    />
  );
};
