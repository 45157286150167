import { encodeURI, decode } from 'js-base64';

export const base64EncodeURLValue = (valueToEncode: String, shouldEncode: boolean): string => {
  if (shouldEncode) {
    const encodedBase64 = encodeURI(valueToEncode);
    return encodedBase64;
  }
  return valueToEncode;
};

export const base64DecodeURLValue = (valueToDecode: String, shouldDecode: boolean): string => {
  if (shouldDecode) {
    try {
      const decodedBase64 = decode(valueToDecode);
      return decodedBase64;
    } catch (err) {
      return valueToDecode;
    }
  }
  return valueToDecode;
};
