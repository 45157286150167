// @flow

import styled from 'react-emotion';
import { css } from 'emotion';
import { LANDSCAPE_DEVICE, MEDIA_BREAKPOINTS, SMALL_DEVICE_BREAKPOINT } from './responsive';

const mobilePadding = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-left: 20px;
    padding-right: 20px;

    ${LANDSCAPE_DEVICE} {
      max-width: none;
    }
  }
`;

const responsiveFullWidth = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: unset;
  }
`;

export const MediumContainer = styled('div')`
  width: 100%;
  max-width: 500px;
  ${props => (props.responsive ? mobilePadding : '')};
  ${props => (props.responsiveFullWidth ? responsiveFullWidth : '')};
`;

export const CenteredMediumContainer = styled(MediumContainer)`
  margin: 0 auto;
`;

export const LargeContainer = styled(MediumContainer)`
  max-width: 800px;
`;

export const CenteredLargeContainer = styled(LargeContainer)`
  margin: 0 auto;
`;
