import React from 'react';
import styled from 'react-emotion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { css, cx } from 'emotion';
import {
  getSongArtist,
  getSongArtistSlug,
  getSongName,
  getSongShareSlug,
  getSongSubtitle,
} from '../../../../../api/algolia/song';
import { songPlayingSelector } from '../../../../../store/globalPlayer';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../../../store/mixpanelAnalytics';
import { useNavigate } from '../../../../../routing/hooks';
import { ROUTES } from '../../../../../routing/routes';
import { useDownloadContext } from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import ArtistName from '../../../FullDisplaySongCard/components/ArtistName/ArtistName';

const SongInfo = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  ${SMALL_DEVICE_BREAKPOINT} {
    align-items: flex-start;
    width: 100%;
  }
`;

export const SongTitle = styled('h4')`
  height: 19px;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0f0f14;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.43px;
  line-height: 19px;
  margin-bottom: 3px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
  }
`;

const SongSubtitle = styled('h6')`
  height: 13px;
  width: 250px;
  color: rgba(15, 15, 20, 0.6);
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 13px;
  text-transform: uppercase;

  ${MOBILE_DEVICE_BREAKPOINT} {
    width: 100%;
  }

  svg {
    top: -7px;
    right: -22px;
  }
`;

const MixTypeLabel = styled('span')`
  &.artistNameSpacing {
    margin-left: 5px;
  }
`;

const disabledSongName = css`
  &:hover {
    text-decoration: none;
    cursor: default;
  }
`;

const GlobalSongInfo = () => {
  const songPlaying = useRecoilValue(songPlayingSelector);

  const {
    location = '',
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();

  const navigate = useNavigate();
  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const shareSlug = getSongShareSlug(songPlaying);
  const isPackPage = (() => {
    if (window) {
      return window.location.href.includes('/pack');
    }
    return false;
  })();

  const artistName = getSongArtist(songPlaying);
  const artistslug = getSongArtistSlug(songPlaying);

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    if (isPackPage) return;
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));
  };

  return (
    <SongInfo>
      <SongTitle onClick={handleNavigatePack} className={cx({ [disabledSongName]: isPackPage })}>
        {getSongName(songPlaying)}
      </SongTitle>
      <SongSubtitle>
        <ArtistName name={artistName} slug={artistslug} />
        <MixTypeLabel className={artistName ? 'artistNameSpacing' : ''}>
          {getSongSubtitle(songPlaying, null, 0, true)}
        </MixTypeLabel>
      </SongSubtitle>
    </SongInfo>
  );
};

export default GlobalSongInfo;
