// @flow

import { graphql } from 'gatsby';
import { get } from 'lodash';

export type NoConnectionsModalGraphMdl = {
  data: {
    header: {
      text: string,
    },
    message: {
      text: string,
    },
    business_only_message: {
      html: string,
    },
  },
};

export const getNoConnectionsModalHeader = (copy: NoConnectionsModalGraphMdl): string => {
  return get(copy, 'data.header.text', '') || '';
};

export const getNoConnectionsModalMessage = (copy: NoConnectionsModalGraphMdl): string => {
  return get(copy, 'data.message.text', '') || '';
};

export const getNoConnectionsModalBusinessOnlyMessage = (
  copy: NoConnectionsModalGraphMdl
): string => {
  return get(copy, 'data.business_only_message.html', '') || '';
};

export const query = graphql`
  fragment NoConnectionsModal on PrismicNoConnectionsModal {
    data {
      header {
        text
      }
      message {
        text
      }
      business_only_message {
        html
      }
    }
  }
`;
