// @flow
import React from 'react';
import BrowseLayout from '../../components/BrowseLayout/BrowseLayout';
import FeaturedMoods from '../BrowseView/components/FeaturedMoods';
import TagList from '../GenresView/components/TagList/TagList';

type Props = {
  path: string,
};

const MoodsView = ({ path }: Props) => {
  return (
    <BrowseLayout path={path}>
      <FeaturedMoods viewAll oversizedTile page="moodsPage" rows={2} />
      <TagList tagCategory="mood" />
    </BrowseLayout>
  );
};

export default MoodsView;
