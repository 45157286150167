// @flow
import React from 'react';
import { getSongID } from '../../../../../../../../api/algolia/song';
import {
  useBookmark,
  useRemoveBookmark,
} from '../../../../../../../../api/firebase/user/bookmarks';
import {
  getTrackBookmarkAction,
  getTrackUnbookmarkAction,
} from '../../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useIsSongBookmarked } from '../../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import InlineButton from './InlineButton';
import BookmarkActiveIcon from '../../../../../../../../assets/inline-assets/bookmark-active-icon.svg';
import BookmarkIcon from '../../../../../../../../assets/inline-assets/bookmark-icon.svg';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { USER_PERMISSIONS } from '../../../../../../../../user/subscriptions/data';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';

const BookmarkInlineButton = () => {
  const song = useSong();
  const { hasSubscription, userRole } = useSubscriptionsContext();
  const canBookmarkSongs = hasSubscription && USER_PERMISSIONS[userRole].canBookmarkSongs;
  const [bookmark, bookmarkBusy] = useBookmark(song);
  const [removeBookmark, removeBookmarkBusy] = useRemoveBookmark(song);
  const isBookmarked = useIsSongBookmarked(getSongID(song));
  const trackCopy = useTrackCopy();
  const { showSubscriptionRequired } = useModalsContext();

  const busy = bookmarkBusy || removeBookmarkBusy;
  const icon = isBookmarked ? <BookmarkActiveIcon /> : <BookmarkIcon />;

  const label = isBookmarked
    ? getTrackUnbookmarkAction(trackCopy)
    : getTrackBookmarkAction(trackCopy);

  const action = event => {
    if (busy) return;
    event.preventDefault();
    event.stopPropagation();

    // サブスク無しの場合はサブスク登録のポップアップ表示。
    if (!canBookmarkSongs) {
      showSubscriptionRequired();
      return;
    }

    if (isBookmarked) {
      removeBookmark();
    } else {
      bookmark();
    }
  };

  return <InlineButton label={label} icon={icon} action={action} />;
};

export default BookmarkInlineButton;
