// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type CouponGraphMdl = {
  data: {
    discount_code: {
      text: string,
    },
    discount_message: {
      text: string,
    },
    discount_ends_message: {
      text: string,
    },
    hide_message_when_plan_cancelled: 'true' | 'false',
  },
};

export const getCouponDiscountCode = (coupon: CouponGraphMdl): string => {
  return get(coupon, 'data.discount_code.text', '') || '';
};

export const getCouponDiscountMessage = (coupon: CouponGraphMdl): string => {
  return get(coupon, 'data.discount_message.text', '') || '';
};

export const getCouponHideMessageWhenPlanCancelledOrDowngraded = (
  coupon: CouponGraphMdl
): boolean => {
  const value = get(coupon, 'data.hide_message_when_plan_cancelled', 'false') || '';
  // eslint-disable-next-line eqeqeq
  return value == 'true';
};

export const getCouponDiscountEndsMessage = (coupon: CouponGraphMdl, timestamp: string): string => {
  const message = get(coupon, 'data.discount_ends_message.text', '') || '';
  return message.replace('$DATE$', timestamp);
};

export type AllCouponsGraphMdl = {
  edges: Array<{
    node: CouponGraphMdl,
  }>,
};

export const getCoupon = (
  coupons: Array<CouponGraphMdl>,
  couponId: string
): CouponGraphMdl | null => {
  return coupons.find(coupon => {
    return getCouponDiscountCode(coupon).toLowerCase() === couponId.toLowerCase();
  });
};

export const query = graphql`
  fragment Coupon on PrismicDiscountCode {
    data {
      discount_code {
        text
      }
      discount_message {
        text
      }
      discount_ends_message {
        text
      }
      hide_message_when_plan_cancelled
    }
  }
`;
