// @flow
import React, { useEffect, useState, useContext } from 'react';
import ls from 'local-storage';
import {
  ArrayParam,
  useQueryParam,
  stringify,
  encodeQueryParams,
  StringParam,
} from 'use-query-params';
import { get } from 'lodash';
import { useRecoilValue, useRecoilState } from 'recoil';
import { VISIBILITY_FILTERS } from '../ResultsAside/components/ResultsFilter/filters';
import {
  searchAlgolia,
  searchSimilarAlgolia,
  getFilterQuery,
} from '../../../../../../../api/algolia/search';
import { useLocale } from '../../../../../../components/LocaleWrapper/LocaleWrapper';
import type {
  AlgoliaSearchResults,
  AlgoliaFacetResults,
  AlgoliaFacetStats,
  AppliedAlgoliaFilters,
} from '../../../../../../../api/algolia/search';
import {
  getSongID,
  isSongFullMix,
  getSongDateUpdated,
  getSongShareSlug,
  isSongQueryFullMatch,
  isSongStem,
} from '../../../../../../../api/algolia/song';
import type { AlgoliaSongMdl } from '../../../../../../../api/algolia/song';
import { getGroupedBooleanFilters } from '../../../../../../../api/algolia/data';
import {
  analyticsKeywordsNoSearchResults,
  analyticsKeywordsSearchResults,
  analyticsSongsSwitch,
  getSongAnalyticsDimensions,
} from '../../../../../../../analytics/events';
import DownloadContextWrapper from '../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useSongSlugQuery } from '../../../../../../../song/components/AudioPlayback/AudioPlayback';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  analyticsMixpanelApplyFilter,
  analyticsMixpanelRemoveFilter,
  analyticsMixpanelSearchKeywords,
  analyticsMixpanelInexactSearch,
  getMixpanelRangeValue,
  getMixpanelPartsBreakdown,
  getMixpanelCombinedKeywordsList,
} from '../../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { FILTER_MENU_TYPES, FILTER_TYPES } from '../ResultsAside/components/ResultsFilters/data';
import { getSortingMethod, RESULTS_SORTING_METHODS } from '../data';
import { useUserHiddenTracks } from '../../../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import { isGroupApplied } from '../ResultsAside/components/ResultsFilters/components/FilterBooleanMenu/FilterBooleanMenu';
import type { HiddenTracks } from '../../../../../../../api/firebase/user/hiddenTracks';
import {
  getInitialFiltersApplied,
  getFilterQueryParams,
  queryParamsToRangeFilters,
} from '../../../../../../../utils/filterParams';
import { useUpdateProfileKeywordCount } from '../../../../../../../api/firebase/user/keywordCount';
import {
  getVisibleResultsKeywords,
  getAlgoliaQueryKeywords,
} from '../../../../../../../api/algolia/magicKeywords';
import { USER_PERMISSIONS } from '../../../../../../../user/subscriptions/data';
import { useModalsContext } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import {
  searchEditModeAtom,
  searchTriggerAtom,
} from '../../../../../../../store/mixpanelAnalytics';
import { useSfxFeatureQuery, useSfxTypeQuery } from '../../../../../MusicScreen/views/SearchView/components/ResultsManager/ResultsManager';

export const getSearchQueryParams = (keywords: Array<string>): string => {
  const encodedQuery = encodeQueryParams({ q: ArrayParam }, { q: keywords });
  return stringify(encodedQuery);
};

export const getMagicSearchQueryParams = (magicKeywords: Array<string>): string => {
  const encodedMagicQuery = encodeQueryParams({ mq: ArrayParam }, { mq: magicKeywords });
  return stringify(encodedMagicQuery);
};

export const getSimilaritySearchQuery = (similarityKeywords: Array<string>): string => {
  const encodedSmilarityQuery = encodeQueryParams({ sq: ArrayParam }, { sq: similarityKeywords });
  return stringify(encodedSmilarityQuery);
};

export const getYouTubeSearchQueryParams = (youtubeKeywords: Array<string>): string => {
  const encodedYouTubeQuery = encodeQueryParams({ ytq: ArrayParam }, { ytq: youtubeKeywords });
  return stringify(encodedYouTubeQuery);
};

export const useSearchQuery = () => {
  return useQueryParam('q', ArrayParam);
};

export const useMagicSearchQuery = () => {
  return useQueryParam('mq', ArrayParam);
};

export const useSimilaritySearchQuery = () => {
  return useQueryParam('sq', ArrayParam);
};

export const useSimilarityBpmQuery = () => {
  return useQueryParam('sbpm', StringParam);
};

export const useSimilaritySongQuery = () => {
  return useQueryParam('similar', StringParam);
};

export const useYouTubeSearchQuery = () => {
  return useQueryParam('ytq', ArrayParam);
};

export const useYouTubeVideoQuery = () => {
  return useQueryParam('videoTitle', ArrayParam);
};

export const useYouTubeVideoIdQuery = () => {
  return useQueryParam('videoId', ArrayParam);
};

export const useYouTubeChannelQuery = () => {
  return useQueryParam('channelTitle', ArrayParam);
};

export const useYouTubeChannelIdQuery = () => {
  return useQueryParam('channelId', ArrayParam);
};

export const useShuffleQuery = () => {
  return useQueryParam('shuffleRelevant', StringParam);
};

const getQueryString = (query: string): string => {
  return query.replace(/,/g, ' ');
};

export const useBpmQuery = () => {
  return useQueryParam('bpm', StringParam);
};

export const useEditStyleQuery = () => {
  return useQueryParam('edit_style', ArrayParam);
};

export const useEnergyQuery = () => {
  return useQueryParam('energy', ArrayParam);
};

export const useGenreQuery = () => {
  return useQueryParam('genre', ArrayParam);
};

export const useInstrumentsQuery = () => {
  return useQueryParam('instruments', ArrayParam);
};

export const useLengthQuery = () => {
  return useQueryParam('length', StringParam);
};

export const useLocationQuery = () => {
  return useQueryParam('location', ArrayParam);
};

export const useMoodQuery = () => {
  return useQueryParam('mood', ArrayParam);
};

export const useMovementQuery = () => {
  return useQueryParam('movement', ArrayParam);
};

export const useVideoThemeQuery = () => {
  return useQueryParam('video_theme', ArrayParam);
};

export const useSortingQuery = () => {
  return useQueryParam('sorting', StringParam);
};

const getFilteredSongsFullMix = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  return songs.filter((song: AlgoliaSongMdl) => {
    return isSongFullMix(song);
  });
};

const getFilteredSongsParts = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  return songs.filter((song: AlgoliaSongMdl) => {
    return isSongStem(song);
  });
};

const getFilteredSongs = (
  showFilter: string,
  songsFullMixes: Array<AlgoliaSongMdl>,
  songsParts: Array<AlgoliaSongMdl>,
  songsAll: Array<AlgoliaSongMdl>
): Array<AlgoliaSongMdl> => {
  /*
  if (showFilter === VISIBILITY_FILTERS.fullMixes) {
    return songsFullMixes;
  }
  if (showFilter === VISIBILITY_FILTERS.parts) {
    return songsParts;
  }   */
  return songsAll;
};

export const getSelectedSongIndex = (songID: string, songs: Array<AlgoliaSongMdl>): number => {
  if (!songID) return 0;
  if (!songs || songs.length === 0) return 0;
  const songIndex = songs.findIndex((song: AlgoliaSongMdl) => {
    return song.objectID === songID;
  });
  return songIndex;
};

const getNextSongID = (selectedSongID: string, songs: Array<AlgoliaSongMdl>): string => {
  const selectedSongIndex = getSelectedSongIndex(selectedSongID, songs);
  if (selectedSongIndex < songs.length - 1) {
    return getSongID(songs[selectedSongIndex + 1]);
  }
  console.error(`Unable to get next song ID`);
  return selectedSongID;
};

const getPreviousSongID = (selectedSongID: string, songs: Array<AlgoliaSongMdl>): string => {
  const selectedSongIndex = getSelectedSongIndex(selectedSongID, songs);
  if (selectedSongIndex > 0) {
    return getSongID(songs[selectedSongIndex - 1]);
  }
  console.error(`Unable to get previous song ID`);
  return selectedSongID;
};

const findNextFullMixSong = (
  index: number,
  songs: Array<AlgoliaSongMdl>,
  filteredSongs: Array<AlgoliaSongMdl>
): string => {
  const remainingSongs = songs.slice(index, songs.length);
  const fullMixSong = remainingSongs.find(song => {
    return isSongFullMix(song);
  });
  if (fullMixSong) {
    return getSongID(fullMixSong);
  }
  if (filteredSongs.length > 0) {
    return getSongID(filteredSongs[0]);
  }
  return '';
};

const findNextPartsSong = (
  index: number,
  songs: Array<AlgoliaSongMdl>,
  filteredSongs: Array<AlgoliaSongMdl>
): string => {
  const remainingSongs = songs.slice(index, songs.length);
  const partsSong = remainingSongs.find(song => {
    return isSongStem(song);
  });
  if (partsSong) {
    return getSongID(partsSong);
  }
  if (filteredSongs.length > 0) {
    return getSongID(filteredSongs[0]);
  }
  return '';
};

const sendResetFiltersMixpanelEvents = (
  mixpanel,
  moengage,
  currentFilters,
  filterKey: string,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  userRole: string,
  locale: string
) => {
  if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterSelect) {
    const includedFilters = currentFilters[filterKey].included;
    const excludedFilters = currentFilters[filterKey].excluded;

    includedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Included',
        userRole
      );
    });

    excludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Excluded',
        userRole
      );
    });
  } else if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterRange) {
    const mixpanelValue = getMixpanelRangeValue(
      filterKey,
      currentFilters[filterKey].min,
      currentFilters[filterKey].max
    );
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      mixpanelValue,
      keywords,
      magicKeywords,
      youtubeKeywords,
      'Included',
      userRole
    );
  } else if (FILTER_MENU_TYPES[filterKey] === FILTER_TYPES.filterBoolean) {
    const groupedFiltersList = getGroupedBooleanFilters(locale, filterKey);
    const groupLabel = Object.keys(groupedFiltersList)[0];
    const groupedValues = groupedFiltersList[groupLabel];
    const includedFilters = currentFilters[filterKey].included;
    const excludedFilters = currentFilters[filterKey].excluded;

    const isGroupIncluded = isGroupApplied(includedFilters, groupedValues);
    const isGroupExcluded = isGroupApplied(excludedFilters, groupedValues);

    const groupedIncludedFilters = isGroupIncluded
      ? includedFilters.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
      : includedFilters;
    const groupedExcludedFilters = isGroupExcluded
      ? excludedFilters.filter(filter => !groupedValues.includes(filter)).concat([groupLabel])
      : excludedFilters;

    groupedIncludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Included',
        userRole
      );
    });

    groupedExcludedFilters.forEach(filterRemoved => {
      analyticsMixpanelRemoveFilter(
        mixpanel,
        moengage,
        filterKey,
        filterRemoved,
        keywords,
        magicKeywords,
        youtubeKeywords,
        'Excluded',
        userRole
      );
    });
  }
};

const sendClearFiltersMixpanelEvents = (
  mixpanel: any,
  moengage,
  appliedFilters: any,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  youtubeKeywords: Array<string>,
  userRole: string,
  locale: string
) => {
  Object.keys(appliedFilters).forEach(currentFilter => {
    sendResetFiltersMixpanelEvents(
      mixpanel,
      moengage,
      appliedFilters,
      currentFilter,
      keywords,
      magicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
  });
};

const sortByNewest = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedSongs = songs.slice().sort((songA, songB) => {
    const dateA = new Date(getSongDateUpdated(songA) * 1000);
    const dateB = new Date(getSongDateUpdated(songB) * 1000);
    return dateB - dateA;
  });
  return sortedSongs;
};

const sortByOldest = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedByNewest = sortByNewest(songs);
  const sortedByOldest = sortedByNewest.slice().reverse();
  return sortedByOldest;
};

const sortByPopularity = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const sortedSongs = songs.slice().sort((songA, songB) => {
    const popularityA = get(songA, 'popularity', 0);
    const popularityB = get(songB, 'popularity', 0);
    return popularityB - popularityA;
  });
  return sortedSongs;
};

const sortByPopularityAscending = (songs: Array<AlgoliaSongMdl>): Array<AlgoliaSongMdl> => {
  const songsByDesecendingPopularity = sortByPopularity(songs);
  return [].concat(songsByDesecendingPopularity).reverse();
};

const sortByMethod = (songs: Array<AlgoliaSongMdl>, method: string): Array<AlgoliaSongMdl> => {
  if (method === RESULTS_SORTING_METHODS.newest) {
    return sortByNewest(songs);
  }
  if (method === RESULTS_SORTING_METHODS.oldest) {
    return sortByOldest(songs);
  }
  if (method === RESULTS_SORTING_METHODS.popularity) {
    return sortByPopularity(songs);
  }
  if (method === RESULTS_SORTING_METHODS.descending_popularity) {
    return sortByPopularityAscending(songs);
  }
  return sortByNewest(songs);
};

const getHiddenCountMap = (hiddenSongs: HiddenTracks) => {
  const hiddenCountMap = {};
  const hiddenSongIds = Object.keys(hiddenSongs);

  hiddenSongIds.forEach(hiddenSongId => {
    const hiddenSongDetails = hiddenSongs[hiddenSongId];
    const detailsCategories = Object.keys(hiddenSongDetails);
    detailsCategories.forEach(category => {
      if (Array.isArray(hiddenSongDetails[category])) {
        const tagsList = hiddenSongDetails[category];
        tagsList.forEach(tag => {
          if (hiddenCountMap[`${category}.${tag}`]) {
            hiddenCountMap[`${category}.${tag}`] = hiddenCountMap[`${category}.${tag}`] + 1;
          } else {
            hiddenCountMap[`${category}.${tag}`] = 1;
          }
        });
      } else {
        hiddenCountMap[`${category}.${hiddenSongDetails[category]}`] = 1;
      }
    });
  });

  return hiddenCountMap;
};

export const getCountAfterHiddenSongs = (filterKey, filterLabel, hiddenTracksMap): number => {
  let categoryName = '';
  const filterType = FILTER_MENU_TYPES[filterKey];

  if (filterType === FILTER_TYPES.filterSelect) {
    const separatedFilterKey = filterKey.split('.');
    // eslint-disable-next-line prefer-destructuring
    categoryName = separatedFilterKey[2];
  } else {
    categoryName = filterKey;
  }
  return get(hiddenTracksMap, `${categoryName}.${filterLabel}`, 0);
};

export type ResultsManagerContextState = {
  loading: boolean,
  loaded: boolean,
  noResults: boolean,
  songs: Array<AlgoliaSongMdl>,
  resultFacets: AlgoliaFacetResults,
  resultFacetStats: AlgoliaFacetStats,
  appliedFilters: AppliedAlgoliaFilters,
  completeResultFacets: any,
  handleAddSongsFilter: (string, string, string, boolean) => void,
  handleRemoveSongsFilter: (string, string, string) => void,
  swapSongsFilter: (string, string, string, string, boolean) => void,
  addRangeSongsFilter: (string, number, number) => void,
  resetSongsFilter: string => void,
  addFilterGroup: (string, Array<string>, string) => void,
  removeFilterGroup: (string, Array<string>, string) => void,
  swapFilterGroup: (string, Array<string>, string, string, string) => void,
  handleClearSongFilters: string => void,
  currentSongData: AlgoliaSongMdl | null,
  setCurrentSongData: AlgoliaSongMdl => void,
  filteredSongs: Array<AlgoliaSongMdl>,
  filteredSongsCount: number,
  songsFilter: string,
  setSongsFilter: string => void,
  keywords: Array<string>,
  magicKeywords: Array<string>,
  mergedUIKeywords: Array<string>,
  mergedQueryKeywords: Array<string>,
  selectedSongIndex: number,
  goToSongAtIndex: number => void,
  nextEnabled: boolean,
  previousEnabled: boolean,
  goToNextSong: () => void,
  goToPreviousSong: () => void,
  goToSongById: string => void,
  resultsError: any,
  songsAll: Array<AlgoliaSongMdl>,
  songsFullMixes: Array<AlgoliaSongMdl>,
  songsParts: Array<AlgoliaSongMdl>,
  currentIndexSong: number,
  nextMobileSongId: string => string,
  mobileResultsNumberVisibleSongs: number,
  setMobileResultsNumberVisibleSongs: number => void,
  isNextMobileSongPlayable: string => boolean,
  sortingMethod: string,
  songNavigationPermitted: boolean,
  handleSort: string => void,
  showHiddenSongs: boolean,
  handleShowHiddenSongs: boolean => void,
  hiddenTracksCount: number,
  hiddenTracksMap: { [string]: number },
  setQuery: (Array<string>) => void,
  setMagicQuery: (Array<string>) => void,
  magicQuery: Array<string>,
  youtubeQuery: Array<string>,
  youtubeVideoTitle: string,
  youtubeVideoId: string,
  youtubeChannelTitle: string,
  youtubeChannelId: string,
  handleRemoveYouTubeKeyword: () => void,
  similarityQuery: Array<string>,
  similarSongQuery: string,
  similarityBpmQuery: string,
  handleSimilarSongSearch: (Array<string>, string, number, number) => void,
};

export const ResultsManagerContext = React.createContext<any>();

export const useResultsManagerContext = (): ResultsManagerContextState => {
  return useContext(ResultsManagerContext);
};

export const useResultsSongs = (): Array<AlgoliaSongMdl> => {
  return useResultsManagerContext().filteredSongs;
};

export const useResultsSelectedSong = (): AlgoliaSongMdl | null => {
  const { selectedSongIndex, filteredSongs } = useResultsManagerContext();
  if (filteredSongs && filteredSongs[selectedSongIndex]) {
    return filteredSongs[selectedSongIndex];
  }

  if (filteredSongs) {
    return filteredSongs[0];
  }
  return null;
};

type Props = {
  children: any,
};

const ResultsManager = ({ children }: Props) => {
  const locale = useLocale();
  const [selectedSongID, setSelectedSongID] = useState('');
  const [songs, setSongs] = useState([]);
  const [songsAll, setSongsAll] = useState([]);
  const [songsFullMixes, setSongsFullMix] = useState([]);
  const [songsParts, setSongsParts] = useState([]);
  const [songsFilter, setSongsFilter] = useState(VISIBILITY_FILTERS.all);
  const [resultFacets, setResultFacets] = useState();
  const [resultFacetStats, setResultFacetStats] = useState();
  const { userRole } = useSubscriptionsContext();
  const [completeResultFacets, setCompleteResultFacets] = useState({
    keywords: '',
    facets: {},
    facetStats: {},
    userRole,
  });
  // get and apply filters from URL parameters
  const [bpmParams, setBpmParams] = useBpmQuery();
  const [editStyleParams, setEditStyleParams] = useEditStyleQuery();
  const [energyParams, setEnergyParams] = useEnergyQuery();
  const [genreParams, setGenreParams] = useGenreQuery();
  const [instrumentParams, setInstrumentsParams] = useInstrumentsQuery();
  const [lengthParams, setLengthParams] = useLengthQuery();
  const [locationParams, setLocationParams] = useLocationQuery();
  const [moodParams, setMoodParams] = useMoodQuery();
  const [movementParams, setMovementParams] = useMovementQuery();
  const [videoThemeParams, setVideoThemeParams] = useVideoThemeQuery();
  const [sfxTypeParams, setSfxTypeParams] = useSfxTypeQuery();
  const [sfxFeatureParams, setSfxFeatureParams] = useSfxFeatureQuery();
  const initialFiltersApplied = getInitialFiltersApplied(
    bpmParams,
    editStyleParams,
    energyParams,
    genreParams,
    instrumentParams,
    lengthParams,
    locationParams,
    moodParams,
    movementParams,
    videoThemeParams,
    sfxTypeParams,
    sfxFeatureParams,
  );

  const [appliedFilters, setAppliedFilters] = useState(initialFiltersApplied);
  const [currentSongData, setCurrentSongData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [resultsError, setResultsError] = useState();
  const [keywords, setKeywords] = useState([]);
  const [magicKeywords, setMagicKeywords] = useState([]);
  const [similarityKeywords, setSimilarityKeywords] = useState([]);
  const [youtubeKeywords, setYouTubeKeywords] = useState([]);
  const [query, setQuery] = useSearchQuery();
  const [magicQuery, setMagicQuery] = useMagicSearchQuery();
  const [similarityQuery, setSimilarityQuery] = useSimilaritySearchQuery();
  const [similarityBpmQuery, setSimilarityBpmQuery] = useSimilarityBpmQuery();
  const [similarSongQuery, setSimilarSongQuery] = useSimilaritySongQuery();
  const [youtubeQuery, setYouTubeQuery] = useYouTubeSearchQuery();
  const [youtubeVideoTitle, setYouTubeVideoTitle] = useYouTubeVideoQuery();
  const [youtubeVideoId, setYouTubeVideoId] = useYouTubeVideoIdQuery();
  const [youtubeChannelTitle, setYouTubeChannelTitle] = useYouTubeChannelQuery();
  const [youtubeChannelId, setYouTubeChannelId] = useYouTubeChannelIdQuery();
  const [mergedQueryKeywords, setMergedQueryKeywords] = useState([]);
  const [mergedUIKeywords, setMergedUIKeywords] = useState([]);
  const mixpanelMagicKeywords = mergedUIKeywords.filter(
    UIKeyword => !mergedQueryKeywords.includes(UIKeyword)
  );
  const [shuffleOn, setShuffleOn] = useShuffleQuery();
  const [viewingDetails, setViewingDetails] = useState(true);
  const [shareSlug, setShareSlug] = useSongSlugQuery();
  const [shareSongLocation, setShareSongLocation] = useState('');
  const [currentIndexSong, setCurrentIndexSong] = useState(null);
  const [mobileResultsNumberVisibleSongs, setMobileResultsNumberVisibleSongs] = useState(3);
  const [sortingMethod, setSortingMethod] = useSortingQuery();
  const [previousSorting, setPreviousSorting] = useState();
  const [showHiddenSongs, setShowHiddenSongs] = useState(false);
  const userHiddenSongs = useUserHiddenTracks();
  const [hiddenTracksCount, setHiddenTracksCount] = useState(0);
  const [hiddenTracksMap, setHiddenTracksMap] = useState({});
  const [initialSearch, setInitialSearch] = useState(false);
  const [songNavigationPermitted, setSongNavigationPermitted] = useState(true);
  const [resultsSearchTriggerLocation, setResultsSearchTriggerLocation] = useRecoilState(
    searchTriggerAtom
  );
  const resultsSearchIsEditMode = useRecoilValue(searchEditModeAtom);
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const userID = useUserId();
  const { showSortingRestrictedModal } = useModalsContext();

  const mixpanelSearchKeywords = getMixpanelCombinedKeywordsList(
    keywords,
    magicKeywords,
    youtubeKeywords
  );
  const [handleUpdateProfileKeywordCount] = useUpdateProfileKeywordCount(mixpanelSearchKeywords);

  const filteredSongs = getFilteredSongs(songsFilter, songsFullMixes, songsParts, songsAll);
  const filteredSongsCount = filteredSongs.length;

  const selectedSongIndex = getSelectedSongIndex(selectedSongID, filteredSongs);

  const handleChangeSong = (id: string) => {
    setSelectedSongID(id);
  };

  const noResults =
    loaded &&
    (((query ? query.length === 0 : false) &&
      (magicQuery ? magicQuery.length === 0 : false) &&
      (youtubeQuery ? youtubeQuery.length === 0 : false) &&
      (similarityQuery ? youtubeQuery.length === 0 : false)) ||
      filteredSongs.length === 0);

  const nextEnabled =
    filteredSongs && filteredSongs.length > 0 && selectedSongIndex < filteredSongs.length - 1;
  const previousEnabled = filteredSongs && filteredSongs.length > 0 && selectedSongIndex > 0;

  const goToSongAtIndex = (index: number) => {
    if (!songNavigationPermitted) {
      showSortingRestrictedModal();
      return;
    }
    if (filteredSongs && filteredSongs[index]) {
      setCurrentIndexSong(index);
      handleChangeSong(getSongID(filteredSongs[index]));
    }
  };

  const goToNextSong = () => {
    if (!songNavigationPermitted) {
      showSortingRestrictedModal();
      return;
    }
    goToSongAtIndex(currentIndexSong + 1);
    const selectedSong = filteredSongs[currentIndexSong];
    const dimensions = getSongAnalyticsDimensions(selectedSong);
    analyticsSongsSwitch(dimensions, 'Next');
  };

  const goToPreviousSong = () => {
    if (!songNavigationPermitted) {
      showSortingRestrictedModal();
      return;
    }
    goToSongAtIndex(currentIndexSong - 1);
    const selectedSong = filteredSongs[currentIndexSong];
    const dimensions = getSongAnalyticsDimensions(selectedSong);
    analyticsSongsSwitch(dimensions, 'Previous');
  };

  const nextMobileSongId = (songId: string) => {
    const nextSongId = getNextSongID(songId, filteredSongs);
    return nextSongId;
  };

  const handleSetSongsFilter = (filter: string) => {
    const song = filteredSongs[selectedSongIndex];
    if (song) {
      if (filter === VISIBILITY_FILTERS.fullMixes) {
        if (isSongStem(song)) {
          const nextSongID = findNextFullMixSong(
            selectedSongIndex,
            filteredSongs,
            getFilteredSongs(filter, songsFullMixes, songsParts, songsAll)
          );
          if (nextSongID) {
            handleChangeSong(nextSongID);
          }
        }
      } else if (filter === VISIBILITY_FILTERS.parts) {
        if (isSongFullMix(song)) {
          const nextSongID = findNextPartsSong(
            selectedSongIndex,
            filteredSongs,
            getFilteredSongs(filter, songsFullMixes, songsParts, songsAll)
          );
          if (nextSongID) {
            handleChangeSong(nextSongID);
          }
        }
      }
    } else {
      console.error(`no song selected`);
    }
    setSongsFilter(filter);
  };

  const getSongSafeFilter = (songID: string) => {
    if (filteredSongs.map(song => getSongID(song)).includes(songID)) {
      return songsFilter;
    }

    return VISIBILITY_FILTERS.all;
  };

  const goToSongById = (id: string) => {
    const index = songs.findIndex(song => {
      return song.objectID === id.toString();
    });
    if ((index || index > -1) && songs && songs[index]) {
      const song = songs[index];
      const songID = getSongID(song);
      handleSetSongsFilter(getSongSafeFilter(songID));
      handleChangeSong(songID);
    }
  };

  const shuffleSubGroup = (songsSubGroup: Array<AlgoliaSongMdl>) => {
    for (let i = songsSubGroup.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = songsSubGroup[i];
      songsSubGroup[i] = songsSubGroup[j];
      songsSubGroup[j] = temp;
    }
    return songsSubGroup;
  };

  /*
  const prependQuerySong = (sortedSongs: Array<AlgoliaSongMdl>, songSlug: string) => {
    let songs_copy = sortedSongs;
    console.log('song results before', songs_copy);

    const querySongIndex = songs_copy.findIndex((song) => {
      return songSlug === song.shareSlug;
    });

    console.log(querySongIndex, songSlug);

     const new_songs = songs_copy.splice(querySongIndex, 1);

    console.log('song results after', new_songs);

    return sortedSongs;
  }
  */

  const getShuffledSongs = (songsToShuffle: Array<AlgoliaSongMdl>) => {
    if (shuffleOn !== 'on') {
      return songsToShuffle;
    }
    const groupedSongs = [];
    let currentGroup = [];

    songsToShuffle.forEach((song, index) => {
      currentGroup.push(song);

      if (currentGroup.length === 5 || index + 1 === songsToShuffle.length) {
        groupedSongs.push(currentGroup);
        currentGroup = [];
      }
    });

    let shuffledSongs = [];
    groupedSongs.forEach(subGroup => {
      const shuffledSubGroup = shuffleSubGroup(subGroup);
      shuffledSongs = shuffledSongs.concat(shuffledSubGroup);
    });

    const songResults = [].concat(...groupedSongs);
    return songResults;
  };

  const isNextMobileSongPlayable = (songID: string) => {
    const mobileSongIndex = getSelectedSongIndex(songID, filteredSongs);

    if (mobileSongIndex >= mobileResultsNumberVisibleSongs - 1) {
      return false;
    }
    return true;
  };

  const handleSwapMobileSongs = (songID: string) => {
    const songIndex = getSelectedSongIndex(songID, filteredSongs);
    setCurrentIndexSong(songIndex);
    handleChangeSong(songID);
  };

  const handleAddSongsFilter = (
    filterKey: string,
    filterLabel: string,
    type: string,
    isSuggestion: boolean,
    searchTerm: string
  ) => {
    const appliedFilter = get(appliedFilters, filterKey, null);

    if (!appliedFilter) {
      const filterKind = { included: [], excluded: [] };
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...filterKind,
          [type]: [filterLabel],
        },
      };
      setResultsSearchTriggerLocation('filters');
      setAppliedFilters(updatedAppliedFilters);
    } else {
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...appliedFilter,
          [type]: appliedFilter[type].concat([filterLabel]),
        },
      };
      setResultsSearchTriggerLocation('filters');
      setAppliedFilters(updatedAppliedFilters);
    }
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      isSuggestion,
      searchTerm,
      userRole,
      userID
    );
  };

  const handleRemoveSongsFilter = (filterKey: string, filterLabel: string, type: string) => {
    const filtersToChange = appliedFilters[filterKey][type];
    const updatedFilterValues = filtersToChange.filter(currentFilter => {
      return currentFilter !== filterLabel;
    });
    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [type]: updatedFilterValues,
      },
    };

    if (
      updatedAppliedFilters[filterKey].included.length <= 0 &&
      updatedAppliedFilters[filterKey].excluded.length <= 0
    ) {
      delete updatedAppliedFilters[filterKey];
    }
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      magicKeywords,
      youtubeKeywords,
      type,
      userRole
    );
  };

  const swapSongsFilter = (
    filterKey: string,
    filterLabel: string,
    removeType: string,
    addType: string,
    isSuggestion: boolean,
    searchTerm: string
  ) => {
    const filtersToChange = appliedFilters[filterKey];
    const filtersWithRemovedFilter = filtersToChange[removeType].filter(currentFilter => {
      return currentFilter !== filterLabel;
    });
    const filtersWithAddedFilter = filtersToChange[addType].concat([filterLabel]);

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        [removeType]: filtersWithRemovedFilter,
        [addType]: filtersWithAddedFilter,
      },
    };
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      addType,
      isSuggestion,
      searchTerm,
      userRole,
      userID
    );
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      removeType,
      userRole
    );
  };

  const addRangeSongsFilter = (filterKey: string, max: number, min: number) => {
    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        min,
        max,
      },
    };
    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    const mixpanelRangeValue = getMixpanelRangeValue(filterKey, min, max);
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      mixpanelRangeValue,
      min,
      max,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      'Included',
      false,
      '',
      userRole,
      userID
    );
  };

  const addFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    type: string
  ) => {
    const appliedFilter = get(appliedFilters, filterKey, null);

    if (!appliedFilter) {
      const filterKind = { included: [], excluded: [] };
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...filterKind,
          [type]: filterValues,
        },
      };
      setResultsSearchTriggerLocation('filters');
      setAppliedFilters(updatedAppliedFilters);
    } else {
      filterValues.forEach(filterValue => {
        if (!appliedFilter[type].includes(filterValue)) {
          appliedFilter[type].push(filterValue);
        }
      });
      const updatedAppliedFilters = {
        ...appliedFilters,
        [filterKey]: {
          ...appliedFilter,
          [type]: appliedFilter[type],
        },
      };

      setResultsSearchTriggerLocation('filters');
      setAppliedFilters(updatedAppliedFilters);
    }

    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      false,
      '',
      userRole,
      userID
    );
  };

  const removeFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    type: string
  ) => {
    const filtersToChange = appliedFilters[filterKey][type];
    const updatedFilterValues = filtersToChange.filter(currentFilter => {
      return !filterValues.includes(currentFilter);
    });

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [type]: updatedFilterValues,
      },
    };

    if (
      updatedAppliedFilters[filterKey].included.length <= 0 &&
      updatedAppliedFilters[filterKey].excluded.length <= 0
    ) {
      delete updatedAppliedFilters[filterKey];
    }

    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);

    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      type,
      userRole
    );
  };

  const swapFilterGroup = (
    filterKey: string,
    filterValues: Array<string>,
    filterLabel: string,
    removeType: string,
    addType: string
  ) => {
    const filtersToChange = appliedFilters[filterKey];

    const updatedAddedValues = filtersToChange[addType].concat(filterValues);
    const updatedRemovedValues = filtersToChange[removeType].filter(currentFilter => {
      return !filterValues.includes(currentFilter);
    });

    const updatedAppliedFilters = {
      ...appliedFilters,
      [filterKey]: {
        ...appliedFilters[filterKey],
        [addType]: updatedAddedValues,
        [removeType]: updatedRemovedValues,
      },
    };

    setResultsSearchTriggerLocation('filters');
    setAppliedFilters(updatedAppliedFilters);
    analyticsMixpanelRemoveFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      removeType,
      userRole
    );
    analyticsMixpanelApplyFilter(
      mixpanel,
      moengage,
      filterKey,
      filterLabel,
      null,
      null,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      addType,
      false,
      '',
      userRole,
      userID
    );
  };

  const resetSongsFilter = (filterKey: string) => {
    const updatedAppliedFilters = {
      ...appliedFilters,
    };
    setResultsSearchTriggerLocation('filters');
    sendResetFiltersMixpanelEvents(
      mixpanel,
      moengage,
      updatedAppliedFilters,
      filterKey,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
    delete updatedAppliedFilters[filterKey];
    setAppliedFilters(updatedAppliedFilters);
  };

  const handleClearSongFilters = (origin: string) => {
    if (origin) {
      setResultsSearchTriggerLocation(origin);
    } else {
      setResultsSearchTriggerLocation('filters');
    }
    sendClearFiltersMixpanelEvents(
      mixpanel,
      moengage,
      appliedFilters,
      mixpanelSearchKeywords,
      mixpanelMagicKeywords,
      youtubeKeywords,
      userRole,
      locale
    );
    setAppliedFilters({});
  };

  const handleSort = event => {
    setSortingMethod(event.target.value);
  };

  const handleShowHiddenSongs = (hiddenState: boolean) => {
    setShowHiddenSongs(hiddenState);
  };

  const handleRemoveYouTubeKeyword = () => {
    setYouTubeQuery([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');
  };

  const handleSimilarSongSearch = (
    similarityData: Array<string>,
    similaritySong: string,
    minBpm: number,
    maxBpm: number
  ) => {
    setQuery([]);
    setMagicQuery([]);
    setYouTubeQuery([]);
    setYouTubeVideoTitle('');
    setYouTubeVideoId('');
    setYouTubeChannelTitle('');
    setYouTubeChannelId('');

    setSimilarityQuery(similarityData);
    setSimilarSongQuery(similaritySong);
    setSimilarityBpmQuery(`${minBpm}-${maxBpm}`);
  };

  const handleAlgoliaSearch = () => {
    if (mergedQueryKeywords ? mergedQueryKeywords.length === 0 : false) return;
    setLoading(true);

    const filterQuery = getFilterQuery(appliedFilters);
    searchAlgolia(mergedQueryKeywords.join(', '), locale, [], filterQuery)
      .then((response: AlgoliaSearchResults) => {
        analyticsKeywordsSearchResults(mergedQueryKeywords.join(', '), response.hits.length);
        const songSlug = shareSlug || '';

        const shuffledFullMixes = sortByMethod(
          getFilteredSongsFullMix(response.hits),
          getSortingMethod(sortingMethod)
        );
        const shuffledParts = sortByMethod(
          getFilteredSongsParts(response.hits),
          getSortingMethod(sortingMethod)
        );
        const shuffledAllSongs = sortByMethod(response.hits, getSortingMethod(sortingMethod));

        if (!sortingMethod) {
          setSortingMethod(getSortingMethod('newest'));
          setPreviousSorting(getSortingMethod('newest'));
        } else {
          setPreviousSorting(getSortingMethod(sortingMethod));
        }

        const hiddenExclusiveSongs = shuffledAllSongs.filter(shuffledSong => {
          return !Object.keys(userHiddenSongs).includes(getSongID(shuffledSong));
        });

        const slugSong = shuffledAllSongs.filter(shuffledSong => {
          return getSongShareSlug(shuffledSong) === songSlug;
        });
        const slugIndex =
          slugSong.length === 1
            ? getSelectedSongIndex(getSongID(slugSong[0]), shuffledAllSongs)
            : 0;

        if (mergedQueryKeywords.join(', ') !== completeResultFacets.keywords) {
          if (
            resultsSearchTriggerLocation === 'search' ||
            resultsSearchTriggerLocation === 'extension'
          ) {
            handleUpdateProfileKeywordCount();
          }
          setCompleteResultFacets({
            keywords: mergedQueryKeywords.join(', '),
            facets: response.facets,
            facetStats: response.facets_stats,
          });
        }
        const hiddenResultsCount = shuffledAllSongs.filter(song => {
          return Object.keys(userHiddenSongs).includes(getSongID(song));
        }).length;

        let hiddenTrackCountMap = {};

        if (!showHiddenSongs) {
          hiddenTrackCountMap = getHiddenCountMap(userHiddenSongs);
        }

        setResultFacets(response.facets);
        setResultFacetStats(response.facets_stats);
        setSongsFullMix(shuffledFullMixes);
        setSongsParts(shuffledParts);
        setSongsAll(showHiddenSongs ? shuffledAllSongs : hiddenExclusiveSongs);
        setSongs(shuffledAllSongs);
        setCurrentIndexSong(slugIndex);
        setSelectedSongID(getSongID(shuffledAllSongs[slugIndex]));
        setHiddenTracksCount(hiddenResultsCount);
        setHiddenTracksMap(hiddenTrackCountMap);

        if (response.hits.length === 0) {
          analyticsKeywordsNoSearchResults(mergedUIKeywords.join(', '));
          const partsCountBreakdown = getMixpanelPartsBreakdown(null);
          analyticsMixpanelSearchKeywords(
            mixpanel,
            moengage,
            mixpanelSearchKeywords,
            mixpanelMagicKeywords,
            youtubeKeywords,
            '',
            0,
            0,
            0,
            partsCountBreakdown,
            resultsSearchIsEditMode,
            resultsSearchTriggerLocation,
            appliedFilters,
            userRole,
            userID,
            locale
          );
        } else {
          const partsCountBreakdown = getMixpanelPartsBreakdown(shuffledParts);
          analyticsMixpanelSearchKeywords(
            mixpanel,
            moengage,
            mixpanelSearchKeywords,
            mixpanelMagicKeywords,
            youtubeKeywords,
            '',
            shuffledAllSongs.length,
            shuffledFullMixes.length,
            shuffledParts.length,
            partsCountBreakdown,
            resultsSearchIsEditMode,
            resultsSearchTriggerLocation,
            appliedFilters,
            userRole,
            userID,
            locale
          );
          ls.remove('EXTENSION_USED');

          const searchIsFullMatch = isSongQueryFullMatch(response.hits[0], mergedQueryKeywords);

          if (!searchIsFullMatch) {
            analyticsMixpanelInexactSearch(mixpanel, moengage, mergedQueryKeywords);
          }
        }
        setLoaded(true);
        setLoading(false);
      })
      .finally(() => {
        if (!initialSearch) {
          setInitialSearch(true);
        }
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when searching algolia');
        Sentry.captureException(error);
        console.error(error);
        setResultsError(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  const handleAlgoliaSimilarSearch = () => {
    if (similarityKeywords ? similarityKeywords.length === 0 : false) return;
    setLoading(true);
    const { max, min } = queryParamsToRangeFilters(similarityBpmQuery);
    const bpmFilterQuery = `bpm: ${min} TO ${max}`;
    const songIdFilterQuery = `NOT objectID: ${similarSongQuery}`;

    searchSimilarAlgolia(
      similarityKeywords.join(', '),
      locale,
      [],
      `${bpmFilterQuery} AND ${songIdFilterQuery}`
    )
      .then((response: AlgoliaSearchResults) => {
        const songSlug = shareSlug || '';
        const fullMixResults = getFilteredSongsFullMix(response.hits);
        const partsResults = getFilteredSongsParts(response.hits);
        const allResults = response.hits;

        const hiddenExclusiveSongs = allResults.filter(shuffledSong => {
          return !Object.keys(userHiddenSongs).includes(getSongID(shuffledSong));
        });
        const slugSong = allResults.filter(shuffledSong => {
          return getSongShareSlug(shuffledSong) === songSlug;
        });

        const slugIndex =
          slugSong.length === 1 ? getSelectedSongIndex(getSongID(slugSong[0]), allResults) : 0;

        const hiddenResultsCount = allResults.filter(song => {
          return Object.keys(userHiddenSongs).includes(getSongID(song));
        }).length;

        let hiddenTrackCountMap = {};

        if (!showHiddenSongs) {
          hiddenTrackCountMap = getHiddenCountMap(userHiddenSongs);
        }
        const partsCountBreakdown = getMixpanelPartsBreakdown(partsResults);

        analyticsMixpanelSearchKeywords(
          mixpanel,
          moengage,
          similarityKeywords,
          mixpanelMagicKeywords,
          youtubeKeywords,
          similarSongQuery,
          allResults.length,
          fullMixResults.length,
          partsResults.length,
          partsCountBreakdown,
          resultsSearchIsEditMode,
          resultsSearchTriggerLocation,
          { bpm: { max, min } },
          userRole,
          userID,
          locale
        );

        setResultFacets(response.facets);
        setResultFacetStats(response.facets_stats);
        setSongsFullMix(fullMixResults);
        setSongsParts(partsResults);
        setSongsAll(showHiddenSongs ? allResults : hiddenExclusiveSongs);
        setSongs(allResults);
        setCurrentIndexSong(slugIndex);
        setSelectedSongID(getSongID(allResults[slugIndex]));
        setHiddenTracksCount(hiddenResultsCount);
        setHiddenTracksMap(hiddenTrackCountMap);
        setSortingMethod('');

        setLoaded(true);
        setLoading(false);
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when searching algolia for similar songs');
        Sentry.captureException(error);
        console.error(error);
        setResultsError(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (similarityQuery ? similarityQuery.length > 0 : false) {
      handleAlgoliaSimilarSearch();
    } else {
      handleAlgoliaSearch();
    }
  }, [
    mergedQueryKeywords ? mergedQueryKeywords.join(',') : '',
    similarityKeywords ? similarityKeywords.join(',') : '',
    appliedFilters,
  ]);

  useEffect(() => {
    let queryKeywords = [];
    let queryMagicKeywords = [];
    let queryYouTubeKeywords = [];
    let newMergedUIkeywords = [];
    let newMergedQueryKeywords = [];

    if (query && query.length > 0) {
      queryKeywords = query;
    }

    if (magicQuery && magicQuery.length > 0) {
      queryMagicKeywords = magicQuery;
    }

    if (youtubeQuery && youtubeQuery.length > 0) {
      queryYouTubeKeywords = youtubeQuery;
    }

    if (
      (!query || query.length === 0) &&
      (!magicQuery || magicQuery.length === 0) &&
      (!youtubeQuery || youtubeQuery.length === 0)
    ) {
      setKeywords([]);
      setMagicKeywords([]);
      setYouTubeKeywords([]);
      setMergedUIKeywords([]);
      setMergedQueryKeywords([]);

      if (!similarityQuery || similarityQuery.length === 0) {
        setLoading(false);
        setLoaded(true);
      }
    } else {
      newMergedUIkeywords = getVisibleResultsKeywords(
        queryKeywords || [],
        queryMagicKeywords || []
      );
      newMergedQueryKeywords = getAlgoliaQueryKeywords(
        queryKeywords || [],
        queryMagicKeywords || [],
        queryYouTubeKeywords || []
      ).sort();
      setMergedUIKeywords(newMergedUIkeywords);
      setMergedQueryKeywords(newMergedQueryKeywords);
      setKeywords(queryKeywords);
      setMagicKeywords(queryMagicKeywords);
      setYouTubeKeywords(queryYouTubeKeywords);
    }
  }, [
    query ? query.join(',') : '',
    magicQuery ? magicQuery.join(',') : '',
    youtubeQuery ? youtubeQuery.join(',') : '',
  ]);

  useEffect(() => {
    let querySimilarityKeywords = [];

    if (similarityQuery && similarityQuery.length > 0) {
      querySimilarityKeywords = similarityQuery;
    }

    if (!similarityQuery || similarityQuery.length === 0) {
      setSimilarityKeywords([]);
      if (
        (!query || query.length === 0) &&
        (!magicQuery || magicQuery.length === 0) &&
        (!youtubeQuery || youtubeQuery.length === 0)
      ) {
        setLoading(false);
        setLoaded(true);
      }
    } else {
      setSimilarityKeywords(querySimilarityKeywords);
    }
  }, [similarityQuery ? similarityQuery.join(',') : '']);

  useEffect(() => {
    let visibleSongs;
    let shuffledFullMixes = [];
    let shuffledParts = [];
    let shuffledAllSongs = [];
    let hiddenTrackCountMap = {};
    let newIndex = currentIndexSong;
    let newSelectedSongId = selectedSongID;

    if (showHiddenSongs) {
      visibleSongs = songs;
    } else {
      visibleSongs = songs.filter(song => {
        return !Object.keys(userHiddenSongs).includes(getSongID(song));
      });
    }

    if (sortingMethod === RESULTS_SORTING_METHODS.oldest) {
      shuffledFullMixes = sortByOldest(getFilteredSongsFullMix(visibleSongs));
      shuffledParts = sortByOldest(getFilteredSongsParts(visibleSongs));
      shuffledAllSongs = sortByOldest(visibleSongs);
    } else if (sortingMethod === RESULTS_SORTING_METHODS.popularity) {
      shuffledFullMixes = getShuffledSongs(sortByPopularity(getFilteredSongsFullMix(visibleSongs)));
      shuffledParts = getShuffledSongs(sortByPopularity(getFilteredSongsParts(visibleSongs)));
      shuffledAllSongs = getShuffledSongs(sortByPopularity(visibleSongs));
    } else if (sortingMethod === RESULTS_SORTING_METHODS.popularity_ascending) {
      shuffledFullMixes = getShuffledSongs(
        sortByPopularityAscending(getFilteredSongsFullMix(visibleSongs))
      );
      shuffledParts = getShuffledSongs(
        sortByPopularityAscending(getFilteredSongsParts(visibleSongs))
      );
      shuffledAllSongs = getShuffledSongs(sortByPopularityAscending(visibleSongs));
    } else {
      shuffledFullMixes = sortByNewest(getFilteredSongsFullMix(visibleSongs));
      shuffledParts = sortByNewest(getFilteredSongsParts(visibleSongs));
      shuffledAllSongs = sortByNewest(visibleSongs);
    }

    if (sortingMethod !== previousSorting) {
      newIndex = 0;
      newSelectedSongId = getSongID(shuffledAllSongs[0]);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!showHiddenSongs) {
        newIndex = getSelectedSongIndex(selectedSongID, shuffledAllSongs);
      } else {
        const currentSongHidden = Object.keys(userHiddenSongs).includes(selectedSongID);
        if (currentSongHidden) {
          newIndex = 0;
          newSelectedSongId = getSongID(shuffledAllSongs[0]);
        } else {
          newIndex = getSelectedSongIndex(selectedSongID, shuffledAllSongs);
        }
      }
    }

    if (!showHiddenSongs) {
      hiddenTrackCountMap = getHiddenCountMap(userHiddenSongs);
    }

    setSongsFullMix(shuffledFullMixes);
    setSongsParts(shuffledParts);
    setSongsAll(shuffledAllSongs);
    setCurrentIndexSong(newIndex);
    setSelectedSongID(newSelectedSongId);
    setHiddenTracksMap(hiddenTrackCountMap);
    setSortingMethod(getSortingMethod(sortingMethod));
    setPreviousSorting(getSortingMethod(sortingMethod));
  }, [sortingMethod, showHiddenSongs, initialSearch]);

  useEffect(() => {
    if (Object.keys(userHiddenSongs).length === 0 && initialSearch) {
      return;
    }
    let visibleSongs = [];
    let shuffledFullMixes = [];
    let shuffledParts = [];
    let shuffledAllSongs = [];
    const hiddenResultsCount = songs.filter(song => {
      return Object.keys(userHiddenSongs).includes(getSongID(song));
    }).length;

    if (showHiddenSongs) {
      visibleSongs = songsAll;
    } else {
      visibleSongs = songsAll.filter(song => {
        return !Object.keys(userHiddenSongs).includes(getSongID(song));
      });
    }

    shuffledFullMixes = getShuffledSongs(getFilteredSongsFullMix(visibleSongs));
    shuffledParts = getShuffledSongs(getFilteredSongsParts(visibleSongs));
    shuffledAllSongs = getShuffledSongs(visibleSongs);

    setSongsFullMix(shuffledFullMixes);
    setSongsParts(shuffledParts);
    setSongsAll(shuffledAllSongs);
    setHiddenTracksCount(hiddenResultsCount);
  }, [userHiddenSongs, initialSearch]);

  useEffect(() => {
    let newIndex = currentIndexSong;
    let newSelectedSongId = selectedSongID;
    let visibleSongs = [];

    if (showHiddenSongs) {
      visibleSongs = songsAll;
    } else {
      visibleSongs = songsAll.filter(song => {
        return !Object.keys(userHiddenSongs).includes(getSongID(song));
      });
    }
    newIndex =
      getSelectedSongIndex(selectedSongID, songsAll) >= visibleSongs.length
        ? visibleSongs.length - 1
        : getSelectedSongIndex(selectedSongID, songsAll);
    newSelectedSongId = getSongID(visibleSongs[newIndex]);

    setCurrentIndexSong(newIndex);
    setSelectedSongID(newSelectedSongId);
  }, [userHiddenSongs]);

  useEffect(() => {
    const {
      bpmFilterParams,
      editStyleFilterParams,
      energyFilterParams,
      genreFilterParams,
      instrumentsFilterParams,
      lengthFilterParams,
      locationFilterParams,
      moodFilterParams,
      movementFilterParams,
      videoThemeFilterParams,
      sfxTypeFilterParams,
      sfxFeatureFilterParams,
    } = getFilterQueryParams(appliedFilters);

    setBpmParams(bpmFilterParams);
    setEditStyleParams(editStyleFilterParams);
    setEnergyParams(energyFilterParams);
    setGenreParams(genreFilterParams);
    setInstrumentsParams(instrumentsFilterParams);
    setLengthParams(lengthFilterParams);
    setLocationParams(locationFilterParams);
    setMoodParams(moodFilterParams);
    setMovementParams(movementFilterParams);
    setVideoThemeParams(videoThemeFilterParams);
    setSfxTypeParams(sfxTypeFilterParams);
    setSfxFeatureParams(sfxFeatureFilterParams);
  }, [appliedFilters]);

  useEffect(() => {
    if (similarSongQuery) {
      setSongNavigationPermitted(USER_PERMISSIONS[userRole].similarSearch);
      return;
    }
    const currentSorting = sortingMethod || RESULTS_SORTING_METHODS.newest;
    setSongNavigationPermitted(
      USER_PERMISSIONS[userRole].sortingPermissions.includes(currentSorting)
    );
  }, [userRole, similarSongQuery]);

  return (
    <ResultsManagerContext.Provider
      value={{
        keywords,
        magicKeywords,
        mergedUIKeywords,
        mergedQueryKeywords,
        loading,
        loaded,
        noResults,
        songs,
        resultFacets,
        resultFacetStats,
        completeResultFacets,
        appliedFilters,
        handleAddSongsFilter,
        handleRemoveSongsFilter,
        swapSongsFilter,
        addRangeSongsFilter,
        resetSongsFilter,
        addFilterGroup,
        removeFilterGroup,
        swapFilterGroup,
        handleClearSongFilters,
        currentSongData,
        setCurrentSongData,
        filteredSongs,
        filteredSongsCount,
        songsFilter,
        setSongsFilter: handleSetSongsFilter,
        selectedSongIndex,
        goToSongAtIndex,
        nextEnabled,
        previousEnabled,
        goToNextSong,
        goToPreviousSong,
        setQuery,
        magicQuery,
        setMagicQuery,
        youtubeQuery,
        handleRemoveYouTubeKeyword,
        youtubeVideoTitle,
        youtubeVideoId,
        youtubeChannelTitle,
        youtubeChannelId,
        similarityQuery,
        similarSongQuery,
        similarityBpmQuery,
        handleSimilarSongSearch,
        goToSongById,
        resultsError,
        viewingDetails,
        setViewingDetails,
        songsAll,
        songsFullMixes,
        songsParts,
        currentIndexSong,
        selectedSongID,
        nextMobileSongId,
        mobileResultsNumberVisibleSongs,
        setMobileResultsNumberVisibleSongs,
        isNextMobileSongPlayable,
        handleSwapMobileSongs,
        sortingMethod,
        songNavigationPermitted,
        handleSort,
        showHiddenSongs,
        handleShowHiddenSongs,
        hiddenTracksCount,
        hiddenTracksMap,
      }}
    >
      <DownloadContextWrapper
        keywords={mergedUIKeywords}
        mixpanelKeywords={mixpanelSearchKeywords}
        magicKeywords={mergedUIKeywords.filter(
          UIKeyword => !mergedQueryKeywords.includes(UIKeyword)
        )}
        youtubeKeywords={youtubeKeywords}
        youtubeVideoTitle={youtubeVideoTitle}
        location="search"
      >
        {children}
      </DownloadContextWrapper>
    </ResultsManagerContext.Provider>
  );
};

export default ResultsManager;
