// @flow

import { get } from 'lodash';

export type YouTubeAPIChannelMdl = {
  id: string,
  kind: 'youtube#channel',
  snippet: {
    title: string,
    description: string,
    thumbnails: {
      default: {
        url: string,
      },
    },
  },
  statistics: {
    subscriberCount: number,
    videoCount: number,
  },
};

export const getYouTubeChannelID = (channel: YouTubeAPIChannelMdl): string => {
  return get(channel, 'id', '');
};

export const getYouTubeChannelName = (channel: YouTubeAPIChannelMdl): string => {
  return get(channel, 'snippet.title', '');
};

export const getYouTubeChannelImage = (channel: YouTubeAPIChannelMdl): string => {
  return get(channel, 'snippet.thumbnails.default.url', '');
};

export const getYouTubeChannelSubscriberCount = (channel: YouTubeAPIChannelMdl): string => {
  return get(channel, 'statistics.subscriberCount', '');
};

export const getYouTubeChannelVideoCount = (channel: YouTubeAPIChannelMdl): string => {
  return get(channel, 'statistics.videoCount', '');
};
