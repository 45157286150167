// @flow
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import { getSongID, getSongShareUrl } from '../../../../../api/algolia/song';
import { useLocalePath } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { songPlayingSelector, songProgressAtom } from '../../../../../store/globalPlayer';

export type ShareContextState = {
  songID: string,
  shareURL: string,
  song: AlgoliaSongMdl,
  getAudioProgress: () => number,
};

export const DisplaySongShareContext = React.createContext({
  songID: '',
  shareURL: '',
  song: null,
  getAudioProgress: () => 0,
});

type Props = {
  children: any,
  song: AlgoliaSongMdl,
};

const DisplaySongShareContextWrapper = ({ children, song }: Props) => {
  const localePath = useLocalePath();
  const songProgress = useRecoilValue(songProgressAtom);
  const songPlaying = useRecoilValue(songPlayingSelector);

  const getAudioProgress = (): number => {
    const isSongPlaying = getSongID(song) === getSongID(songPlaying);
    return isSongPlaying ? songProgress : 0;
  };

  return (
    <DisplaySongShareContext.Provider
      value={{
        song,
        songID: getSongID(song),
        shareURL: getSongShareUrl(song, localePath),
        getAudioProgress,
      }}
    >
      {children}
    </DisplaySongShareContext.Provider>
  );
};

export default DisplaySongShareContextWrapper;

export const useDisplaySongShareContext = (): ShareContextState => {
  return useContext(DisplaySongShareContext);
};

export const useDisplayShareSongID = (): string => {
  return useDisplaySongShareContext().songID;
};

export const useDisplaySongShareSongShareURL = (): string => {
  return useDisplaySongShareContext().shareURL;
};
