// @flow
import React, { useState, useEffect } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { darkColor } from '../../../styles/config/colors';
import ExpandableGroupedTagHeader from './components/ExpandableGroupedTagHeader';
import GroupedTagSections from './components/GroupedTagSections';
import { MOBILE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../styles/responsive';
import { isSmallDeviceSize } from '../../../utils/device';
import FiltersModal from './components/FiltersModal';
import type { SongTagFiltersMdl } from '../../../utils/tagFilters';

const StyledContainer = styled('div')`
  max-width: 800px;
  margin: 40px auto;
  border: 1px solid ${transparentize(0.9, darkColor)};
  border-radius: 5px;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 15px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin: 40px 15px 20px 15px;
  }
`;

type Props = {
  slug: string,
  facetsMap: SongTagFiltersMdl,
  handleFilterToggle: (isSelected: boolean, filterKey: string, filterValue: string) => void,
  headerTitle: string,
  headerActiveTags: Array<any>,
  headerActiveButton: any,
};

const ExpandableGroupedTagLayout = ({
  slug,
  facetsMap,
  handleFilterToggle,
  headerTitle,
  headerActiveTags,
  headerActiveButton,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const hasFacets = facetsMap.length > 0;

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [slug]);

  return (
    <StyledContainer>
      <ExpandableGroupedTagHeader
        isExpanded={expanded}
        title={headerTitle}
        handleExpand={handleToggleExpand}
        headerActiveTags={headerActiveTags}
        headerActiveButton={headerActiveButton}
      />
      {hasFacets && !isSmallDeviceSize() && (
        <GroupedTagSections
          isExpanded={expanded}
          facetsMap={facetsMap}
          handleFilterToggle={handleFilterToggle}
        />
      )}
      {hasFacets && isSmallDeviceSize() && expanded && (
        <FiltersModal
          onClose={handleToggleExpand}
          facetsMap={facetsMap}
          handleFilterToggle={handleFilterToggle}
        />
      )}
    </StyledContainer>
  );
};

export default ExpandableGroupedTagLayout;
