// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type CancelSubscriptionModalGraphMdl = {
  data: {
    modal_title: {
      text: string,
    },
    page_instruction: {
      html: string,
    },
  },
};

export const getCancelSubscriptionModalTitle = (copy: CancelSubscriptionModalGraphMdl): string => {
  return get(copy, 'data.modal_title.text', '') || '';
};

export const getCancelSubscriptionModalPageInstructions = (
  copy: CancelSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.page_instruction.html', '') || '';
};

export type CancelFreeSubscriptionModalGraphMdl = {
  data: {
    header: {
      text: string,
    },
    instruction: {
      html: string,
    },
  },
};

export const getCancelFreeSubscriptionModalTitle = (
  copy: CancelFreeSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.header.text', '');
};

export const getCancelFreeSubscriptionModalInstructions = (
  copy: CancelFreeSubscriptionModalGraphMdl
): string => {
  return get(copy, 'data.instruction.html', '');
};

export const query = graphql`
  fragment CancelSubscriptionModal on PrismicCancelSubscriptionModal {
    data {
      modal_title {
        text
      }
      page_instruction {
        html
      }
    }
  }
  fragment CancelFreeSubscriptionModal on PrismicCancelFreeSubscriptionModal {
    data {
      header {
        text
      }
      instruction {
        html
      }
    }
  }
`;
