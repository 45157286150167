// @flow
import '@fontsource/roboto';
import '@fontsource/roboto-mono';
import { injectGlobal } from 'emotion';
import reset from './reset';
import { bodyTextCss } from './typography/typography';
import { darkColor } from './config/colors';

export default injectGlobal`
    ${reset};
    
    * {
        box-sizing: border-box;
    }

    html {
        background-color: #fff;
    }
    
    body {
        ${bodyTextCss};
        background-color: #fff;
        color: ${darkColor};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    a {
        color: inherit;
    }
    
    strong {
        font-weight: 700;
    }
    
    
    
`;
