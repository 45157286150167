// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { isSearchIndexSwitcherEnabled } from '../utils/env';

export type FindMusicPageMdl = {
  data: {
    title: {
      text: string,
    },
    instruction: {
      html: string,
    },
    advanced_syntax_hint: {
      text: string,
    },
    instruction_advanced_syntax_exclude: {
      text: String,
    },
    search_placeholder: {
      text: string,
    },
    search_button: {
      text: string,
    },
    search_instruction: {
      text: string,
    },
  },
};

export const getFindMusicSearchInstruction = (page: FindMusicPageMdl): string => {
  return get(page, 'data.search_instruction.text', '') || '';
};

export const getFindMusicTitle = (page: FindMusicPageMdl): string => {
  return get(page, 'data.title.text', '') || '';
};

export const getFindMusicInstruction = (page: FindMusicPageMdl): string => {
  return get(page, 'data.instruction.html', '') || '';
};

export const getFindMusicAdvancedSyntaxHint = (page: FindMusicPageMdl): string => {
  return get(page, 'data.advanced_syntax_hint.text', '') || '';
};

export const getFindMusicInstructionAdvancedSyntaxExclude = (page: FindMusicPageMdl): string => {
  return get(page, 'data.instruction_advanced_syntax_exclude.text', '') || '';
};

export const getFindMusicSearchPlaceholder = (page: FindMusicPageMdl): string => {
  return get(page, 'data.search_placeholder.text', '') || '';
};

export const getFindMusicSearchButton = (page: FindMusicPageMdl): string => {
  const findMusicSearchButtonText = get(page, 'data.search_button.text', '') || '';
  if (isSearchIndexSwitcherEnabled() && window.sessionStorage.getItem('stgidx')) {
    return findMusicSearchButtonText + '(stg)'
  }
  return findMusicSearchButtonText
};

export const query = graphql`
  fragment FindMusicPage on PrismicFindMusicPage {
    data {
      title {
        text
      }
      instruction {
        html
      }
      advanced_syntax_hint {
        text
      }
      instruction_advanced_syntax_exclude {
        text
      }
      search_button {
        text
      }
      search_placeholder {
        text
      }
      search_instruction {
        text
      }
    }
  }
`;
