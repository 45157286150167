// @flow

import { SONG_TYPES } from '../../song/components/FullSongCard/components/SongType/SongType';
import { searchAlgolia } from './search';
import type { AlgoliaSongMdl } from './song';
import { getSongFamily } from './song';
import { mapOrder } from '../../utils/array';

export const DIRECT_SONGS_ORDER = [
  SONG_TYPES.fullMix,
  SONG_TYPES.karaokePart,
  SONG_TYPES.dnbPart,
  SONG_TYPES.melodyPart,
  SONG_TYPES.harmonyPart,
  SONG_TYPES.bassPart,
  SONG_TYPES.rhythmPart,
];

export const fetchDirectSongAndFamily = (
  locale: string,
  shareSlug: string
): Promise<Array<AlgoliaSongMdl>> => {
  return searchAlgolia('', locale, [`shareSlug:${shareSlug}`]).then(response => {
    if (response.hits.length === 0) {
      return Promise.reject();
    }
    const song: AlgoliaSongMdl = response.hits[0];
    const family = getSongFamily(song);
    return searchAlgolia('', locale, [`family:${family}`]).then(familyResponse => {
      return mapOrder(familyResponse.hits, DIRECT_SONGS_ORDER, 'type');
    });
  });
};
