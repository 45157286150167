import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { useRecoilValue } from 'recoil';
import { useGlobalAudioPlaybackContext } from '../GlobalAudioPlayback/GlobalAudioPlayback';
import TrackNavIcon from '../../../../../assets/inline-assets/track-nav-icon.svg';
import { useGlobalPlayerContext } from '../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { SMALL_DESKTOP_BREAKPOINT } from '../../../../../styles/responsive';
import {
  nextSongEnabledSelector,
  previousSongEnabledSelector,
  songPlayingSelector,
} from '../../../../../store/globalPlayer';
import { PlayPauseButtonControl } from '../../../FullDisplaySongCard/components/PlayPauseButton/PlayPauseButton';
import { getSongArtwork } from '../../../../../api/algolia/song';

const Controls = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 20px 0px 10px;
  transform: scale(0.8);

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin: 0px;
  }
`;

const Control = styled('div')`
  margin: 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const navTrackIconClas = css`
  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.8;

    &::before {
      //transform: scale(0.95);
      transition-timing-function: ease-out;
    }
  }
`;

const previousTrackIconClass = css`
  transform: rotate(180deg);
`;

const disabledTrackIconClass = css`
  opacity: 0.5;
`;

const GlobalPlaybackControls = () => {
  const { playing, onPlayToggle, tryingToPlay, loadError } = useGlobalAudioPlaybackContext();
  const songPlaying = useRecoilValue(songPlayingSelector);
  const nextSongEnabled = useRecoilValue(nextSongEnabledSelector);
  const previousSongEnabled = useRecoilValue(previousSongEnabledSelector);
  const { nextSong, previousSong } = useGlobalPlayerContext();
  const songArtwork = getSongArtwork(songPlaying);

  const handleOnPlayToggle = () => {
    onPlayToggle();
  };

  const handleNextSongNav = () => {
    if (!nextSongEnabled) return;
    nextSong();
  };

  const handlePreviousSongNav = () => {
    if (!previousSongEnabled) return;
    previousSong();
  };

  return (
    <Controls>
      <Control
        className={cx(navTrackIconClas, {
          [previousTrackIconClass]: true,
          [disabledTrackIconClass]: !previousSongEnabled,
        })}
        onClick={handlePreviousSongNav}
      >
        <TrackNavIcon />
      </Control>
      <Control>
        <PlayPauseButtonControl
          playing={playing}
          onClick={handleOnPlayToggle}
          tryingToPlay={tryingToPlay}
          cantPlay={loadError}
          backgroundImage={songArtwork}
        />
      </Control>
      <Control
        className={cx(navTrackIconClas, {
          [disabledTrackIconClass]: !nextSongEnabled,
        })}
        onClick={handleNextSongNav}
      >
        <TrackNavIcon />
      </Control>
    </Controls>
  );
};

export default GlobalPlaybackControls;
