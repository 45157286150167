// @flow
import React from 'react';
import styled from 'react-emotion';
import GlobalPlaybackControls from '../GlobalPlaybackControls/GlobalPlaybackControls';
import GlobalAudioBars from '../GlobalAudioBars/GlobalAudioBars';
import GlobalPlayerActions from '../GlobalPlayerActions/GlobalPlayerActions';
import GlobalSongInfo from '../GlobalSongInfo/GlobalSongInfo';
import { MEDIA_BREAKPOINTS, SMALL_DESKTOP_BREAKPOINT } from '../../../../../styles/responsive';

const DockedPlayerWrapper = styled('div')`
  position: sticky;
  bottom: 0;
  z-index: 200;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DockedPlayer = styled('div')`
  width: 100%;
  max-width: 1800px;
  margin: 0px 10px 15px 10px;
  border-radius: 5px;
  background-color: #dcdad8;
  display: flex;
  flex-direction: column;
`;

const PlayerSongCard = styled('div')`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlaybackControls = styled('div')`
  width: 35%;
  display: flex;
  flex-direction: row;

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 45%;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 50%;
  }
`;

const AudioBarsContainer = styled('div')`
  width: 30%;
  height: 80%;

  ${MEDIA_BREAKPOINTS.maxRange1260} {
    width: 25%;
  }

  ${MEDIA_BREAKPOINTS.maxRange1180} {
    width: 25%;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    width: 20%;
  }
`;

const DesktopGlobalSongPlayer = () => {
  return (
    <DockedPlayerWrapper>
      <DockedPlayer>
        <PlayerSongCard>
          <PlaybackControls>
            <GlobalPlaybackControls />
            <GlobalSongInfo />
          </PlaybackControls>
          <AudioBarsContainer>
            <GlobalAudioBars condensed />
          </AudioBarsContainer>
          <GlobalPlayerActions />
        </PlayerSongCard>
      </DockedPlayer>
    </DockedPlayerWrapper>
  );
};

export default DesktopGlobalSongPlayer;
