// @flow

import ls from 'local-storage';
import { webAuth } from './auth0';
import { useGenericApiState } from '../hooks';

export const useLoginViaAuth0 = () => {
  const [busy, setBusy, loginError, setError] = useGenericApiState();

  const login = (email: string, password: string) => {
    if (busy) {
      return Promise.reject();
    }
    setBusy(true);
    return new Promise((accept, reject) => {
      webAuth.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password,
        },
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            accept(response);
          }
          setBusy(false);
        }
      );
    });
  };

  return [login, busy, loginError];
};

export const signupViaAuth0 = (
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  locale: string
): Promise<any> => {
  return new Promise((accept, reject) => {
    webAuth.signup(
      {
        connection: 'Username-Password-Authentication',
        email: username,
        password,
        user_metadata: {
          first_name: firstName,
          last_name: lastName,
          lang: locale,
        },
      },
      error => {
        if (error) {
          reject(error);
        } else {
          ls.set('AUTH_FROM_SIGNUP', true);
          ls.set('ALLOW_SIGNUP_SUBSCRIPTIONS_VIEW', true);
          accept();
        }
      }
    );
  });
};

export const useSignUpViaAuth0 = () => {
  const [busy, setBusy] = useGenericApiState();
  const signUp = (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    locale: string
  ) => {
    if (busy) return Promise.reject();
    setBusy(true);
    return signupViaAuth0(username, password, firstName, lastName, locale).finally(() => {
      setBusy(false);
    });
  };
  return [signUp, busy];
};

// todo - track timing and wrap with more sophisticated error handling
export const useResetPassword = () => {
  const [busy, setBusy, error, setError] = useGenericApiState();
  const resetEmail = (email: string): Promise<any> => {
    if (busy) return Promise.reject();
    setBusy(true);

    return new Promise((accept, reject) => {
      webAuth.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, resp) => {
          if (err) {
            reject(err);
            setError(err);
          } else {
            accept(resp);
          }
          setBusy(false);
        }
      );
    });
  };
  return [resetEmail, busy, error];
};
