// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type ToastMessagesGraphMdl = {
  data: {
    saved_changes: {
      text: string,
    },
    download_count_updated: {
      text: string,
    },
    no_downloads_remaining: {
      html: string,
    },
    single_download_remaining: {
      text: string,
    },
    multiple_downloads_remaining: {
      text: string,
    },
    keyboard_shortcut_restricted: {
      text: string,
    },
    enterprise_confirmation_toast: {
      html: string,
    },
  },
};

export const getToastSavedChanges = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.saved_changes.text', '') || '';
};

export const getToastDownloadCountUpdated = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.download_count_updated.text', '') || '';
};

export const getToastNoDownloadsRemaining = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.no_downloads_remaining.html', '') || '';
};

export const getToastSingleDownloadRemaining = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.single_download_remaining.text', '') || '';
};

export const getToastMultipleDownloadsRemaining = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.multiple_downloads_remaining.text', '') || '';
};

export const getToastKeyboardShortcutRestricted = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.keyboard_shortcut_restricted_toast.text', '') || '';
};

export const getToastEnterpriseConfirmation = (copy: ToastMessagesGraphMdl): string => {
  return get(copy, 'data.enterprise_confirmation_toast.html', '') || '';
};

export const query = graphql`
  fragment ToastMessages on PrismicToastMessage {
    data {
      saved_changes {
        text
      }
      download_count_updated {
        text
      }
      no_downloads_remaining {
        html
      }
      single_download_remaining {
        text
      }
      multiple_downloads_remaining {
        text
      }
      keyboard_shortcut_restricted_toast {
        text
      }
      enterprise_confirmation_toast {
        html
      }
    }
  }
`;
