// @flow
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import styled from 'react-emotion';
import ls from 'local-storage';
import AuthRequiredWrapper from '../../../../../auth/components/AuthRequiredWrapper/AuthRequiredWrapper';
import { LargeHeading } from '../../../../../styles/typography/components/Heading/Heading';
import {
  useMiscCopy,
  usePricingPlan,
  useSignUpEnterprisePage,
} from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingHeading2, getPricingInstruction2 } from '../../../../../cms/signup';
import { GeneralDescription } from '../../../../../styles/shared';
import SubscriptionsHandler from '../../../../../user/subscriptions/components/SubscriptionsHandler/SubscriptionsHandler';
import Button from '../../../../../components/Button/Button';
import { getMiscCopyContinue } from '../../../../../cms/miscCopy';
import {
  useSubscriptionsContext,
  useUserHasSubscriptions,
  useFreeTrialSubscriptions,
} from '../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  SMALL_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../../styles/responsive';
import NoConnectionsModal from '../../../../../modals/components/NoConnectionsModal/NoConnectionsModal';
import { useNavigate } from '../../../../hooks';
import { ROUTES } from '../../../../routes';
import PageComponent from '../../../../components/PageComponent/PageComponent';
import {
  usePlanQuery,
  useBillingQuery,
} from '../SignUpEnterpriseFormView/SignUpEnterpriseFormView';
import {
  isBusinessSubscription,
  isCreatorProSubscription,
  isCreatorSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
  planKeyToPlanCode,
} from '../../../../../utils/subscriptions';
import { useChargebeeContext } from '../../../../../user/subscriptions/components/ChargebeeWrapper/ChargebeeWrapper';
import LoadingDisplay from '../../../../../components/LoadingDisplay/LoadingDisplay';
import { useLocalePath } from '../../../../components/LocaleWrapper/LocaleWrapper';
import { useAuthContext } from '../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useUserProfileDetails } from '../../../../../user/components/UserProfileWrapper/UserProfileWrapper';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../../api/firebase/user/profile';
import {
  getPricingPlanFormLink,
  getPricingPlanAvailableIntervals,
} from '../../../../../cms/pricingPlan';
import {
  analyticsMixpanelEnterpriseContactRequest,
  userRoleToMixpanelRole,
} from '../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../analytics/components/MixpanelWrapper';
import { BILLING_CYCLES } from '../../../../../user/subscriptions/data';

export const SubscriptionsSection = styled('section')`
  margin-top: 75px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 42px;
  }
`;

const ContinueWrapper = styled('div')`
  margin-top: 51px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 41px;
  }
`;

const LoadingContainer = styled('div')`
  margin-top: 25%;

  ${MOBILE_DEVICE_BREAKPOINT} {
    margin-top: 50%;
  }
`;

type Props = {
  uri: string,
};

const SignUpEnterpriseSubscriptionsView = ({ uri }: Props) => {
  const [showConnectionWarning, setShowConnectionWarning] = useState(false);
  const navigate = useNavigate();
  const localePath = useLocalePath();
  const signUpEnterprisePage = useSignUpEnterprisePage();
  const miscCopy = useMiscCopy();
  const hasSubscriptions = useUserHasSubscriptions();
  const {
    subscriptions,
    isAtLeastOneSocialAccountConnected,
    addNewSubscription,
    selectPlan,
    setBillingCycle,
    addEnterprisePlaceholderSubscription,
    userRole,
  } = useSubscriptionsContext();
  const { userID } = useAuthContext();
  const profile = useUserProfileDetails();
  const [preAttachedPlan, setPreAttachedPlan] = usePlanQuery();
  const planCopy = usePricingPlan('enterprise1');
  const personalPlanCopy = usePricingPlan('pro1');
  const businessPlanCopy = usePricingPlan('business1');
  const [preAttachedBilling, setPreAttachedBilling] = useBillingQuery();
  const { chargebeeLoaded, chargebeeInstance } = useChargebeeContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const [busy, setBusy] = useState(false);

  const handleEnterpriseClick = async () => {
    try {
      const channelId = await addEnterprisePlaceholderSubscription();
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = getPricingPlanFormLink(planCopy);
      const userTier = userRoleToMixpanelRole(userRole);
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        analyticsMixpanelEnterpriseContactRequest(
          mixpanel,
          moengage,
          email,
          firstName,
          lastName,
          'Sign Up Flow',
          userTier
        );
        setTimeout(() => {
          window.location = completeFormLink;
        }, 300);
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    if (!ls.get('ALLOW_SIGNUP_SUBSCRIPTIONS_VIEW')) {
      navigate(ROUTES.subscriptions.navigatePath({ localePath }));
      return;
    }
    ls.remove('ALLOW_SIGNUP_SUBSCRIPTIONS_VIEW');
  }, []);

  useEffect(() => {
    const planCode = preAttachedPlan ? planKeyToPlanCode(preAttachedPlan) : '';
    if (isCreatorSubscription(planCode)) {
      setBillingCycle(preAttachedBilling);
      selectPlan(planCode);
      setPreAttachedPlan('');
      setPreAttachedBilling('');
    }
    if (isEnterpriseSubscription(planCode)) {
      setTimeout(() => {
        handleEnterpriseClick();
      }, 1000);
    }
  }, [preAttachedPlan, preAttachedBilling]);

  useEffect(() => {
    const openPlanCheckout = async () => {
      if (busy) return;
      setBusy(true);
      if (preAttachedPlan && preAttachedBilling && chargebeeLoaded && chargebeeInstance) {
        const planCode = planKeyToPlanCode(preAttachedPlan);

        if (isCreatorProSubscription(planCode) || isBusinessSubscription(planCode)) {
          const preAttachedPlanCopy = isBusinessSubscription(planCode)
            ? businessPlanCopy
            : personalPlanCopy;
          const billingCycleRequested = Object.keys(BILLING_CYCLES).filter(billingCycle => {
            return BILLING_CYCLES[billingCycle].cycle === preAttachedBilling;
          })[0];
          const isValidBilling = getPricingPlanAvailableIntervals(preAttachedPlanCopy).includes(
            billingCycleRequested
          );

          if (!isValidBilling) {
            setPreAttachedPlan('');
            setPreAttachedBilling('');
            setBusy(false);
            return;
          }

          addNewSubscription(planCode, preAttachedBilling)
            .then(() => {
              setPreAttachedPlan('');
              setPreAttachedBilling('');
            })
            .catch(error => {
              setBillingCycle(preAttachedBilling);
              selectPlan(planCode);
              // $FlowFixMe: removes type checking for Sentry as provisional solution
              Sentry.captureMessage(
                'Something went wrong automatically opening Chargbee checkout after sign up from pricing page'
              );
              Sentry.captureException(error);
              setPreAttachedPlan('');
              setPreAttachedBilling('');
              setBusy(false);
            });
        }
      }
      setBusy(false);
    };
    openPlanCheckout();
  }, [chargebeeLoaded, chargebeeInstance]);

  const nextStep = () => {
    navigate(ROUTES.signupCompleted.navigatePath({}));
  };

  const handleContinue = () => {
    nextStep();
  };

  const hideConnectionWarning = () => {
    setShowConnectionWarning(false);
  };

  return (
    <PageComponent path={uri}>
      <AuthRequiredWrapper>
        {!preAttachedPlan && !preAttachedBilling && (
          <div>
            <header>
              <LargeHeading>{getPricingHeading2(signUpEnterprisePage)}</LargeHeading>
              <GeneralDescription>
                {parse(getPricingInstruction2(signUpEnterprisePage))}
              </GeneralDescription>
            </header>
            <SubscriptionsSection>
              <SubscriptionsHandler />
              <ContinueWrapper>
                <Button disabled={!hasSubscriptions} onClick={handleContinue}>
                  {getMiscCopyContinue(miscCopy)}
                </Button>
              </ContinueWrapper>
            </SubscriptionsSection>
          </div>
        )}
        {preAttachedPlan && preAttachedBilling && (
          <LoadingContainer>
            <LoadingDisplay />
          </LoadingContainer>
        )}
        {showConnectionWarning && (
          <NoConnectionsModal onClose={hideConnectionWarning} onContinue={nextStep} />
        )}
      </AuthRequiredWrapper>
    </PageComponent>
  );
};

export default SignUpEnterpriseSubscriptionsView;
