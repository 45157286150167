// @flow
import React from 'react';
import { useRecoilValue } from 'recoil';
import PlaylistIcon from '../../../../../../../../assets/inline-assets/playlist-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { getFindMusicSearchButton } from '../../../../../../../../cms/findMusic';
import { getBookmarksNav } from '../../../../../../../../cms/header';
import { getMiscCopyPack } from '../../../../../../../../cms/miscCopy';
import { getProfileMenuDownloadHistory } from '../../../../../../../../cms/profileMenu';
import { getResultsPageSimilarSongsHeading } from '../../../../../../../../cms/resultsPage';
import {
  useFindMusicPage,
  useMiscCopy,
  useProfileMenuCopy,
  useResultsPage,
  useSiteHeader,
} from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { capitalize } from '../../../../../../../../utils/text';
import InlineButton from './InlineButton';
import {
  playbackListTypeAtom,
  playbackListSlugAtom,
  playbackListNameAtom,
} from '../../../../../../../../store/globalPlayer';

type Props = {
  hideTooltip: () => void,
};

const BrowseLinkInlineButton = ({ hideTooltip }: Props) => {
  const findPage = useFindMusicPage();
  const resultsPage = useResultsPage();
  const siteHeader = useSiteHeader();
  const profileMenu = useProfileMenuCopy();
  const miscCopy = useMiscCopy();
  const playbackListType = useRecoilValue(playbackListTypeAtom);
  const playbackListName = useRecoilValue(playbackListNameAtom);
  const playbackListSlug = useRecoilValue(playbackListSlugAtom);

  const { navigateToPlaybackList } = useGlobalPlayerContext();

  const linkLabel = (() => {
    if (playbackListType === 'search') {
      return getFindMusicSearchButton(findPage);
    }

    if (playbackListType === 'similar') {
      return getResultsPageSimilarSongsHeading(resultsPage);
    }

    if (playbackListType === 'collection' || playbackListType === 'artist') {
      return playbackListName;
    }

    if (playbackListType === 'bookmarks') {
      return getBookmarksNav(siteHeader);
    }

    if (playbackListType === 'downloads') {
      return getProfileMenuDownloadHistory(profileMenu);
    }

    if (playbackListType === 'pack') {
      return `♪ ${getMiscCopyPack(miscCopy)}`;
    }

    return capitalize(playbackListSlug);
  })();

  const handleBrowseNav = event => {
    if (playbackListType === 'bookmarks' || playbackListType === 'downloads') {
      hideTooltip();
    }
    navigateToPlaybackList();
    hideTooltip();
  };

  return <InlineButton label={linkLabel} icon={<PlaylistIcon />} action={handleBrowseNav} />;
};

export default BrowseLinkInlineButton;
