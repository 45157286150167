// @flow
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { wideModalCss } from '../../../../modals/components/Modal/Modal';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  useMiscCopy,
  usePricingPlan,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getAddSubscriptionModalTitle,
  getChangeSubscriptionModalTitle,
  getDowngradeSubscriptionModalTitle,
  getUpgradeSubscriptionModalTitle,
  getSubscriptionsPagePromotionalDefaultInterval,
} from '../../../../cms/subscriptionsPage';
import { darkColor } from '../../../../styles/config/colors';
import {
  SMALL_DEVICE_BREAKPOINT,
  LANDSCAPE_DEVICE,
  MOBILE_DEVICE_BREAKPOINT,
  MEDIA_BREAKPOINTS,
} from '../../../../styles/responsive';
import { buttonResetCss } from '../../../../components/Button/Button';
import { bodyTextCss, smallMediumPlusTextCss } from '../../../../styles/typography/typography';
import {
  getPricingPlanDescription,
  getPricingPlanExclusions,
  getPricingPlanFormLink,
} from '../../../../cms/pricingPlan';
import { getMiscCopyAdd, getMiscCopyGetInContact } from '../../../../cms/miscCopy';
import { SUBSCRIPTIONS, BILLING_CYCLES } from '../../data';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getIntervalCycle,
  getNextAvailableInterval,
  getSeatInterval,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { ACTIONS } from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useUserProfileDetails } from '../../../components/UserProfileWrapper/UserProfileWrapper';
import {
  isCreatorSubscription,
  isCreatorProSubscription,
  isBusinessSubscription,
  isEnterpriseSubscription,
} from '../../../../utils/subscriptions';
import {
  getProfileEmail,
  getProfileFirstName,
  getProfileLastName,
} from '../../../../api/firebase/user/profile';
import {
  analyticsMixpanelEnterpriseContactRequest,
  userRoleToMixpanelRole,
} from '../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import {
  businessPlanCss,
  enterprisePlanCss,
  guestPlanCss,
  personalPlanCss,
} from '../PlanPreview/PlanPreview';
import { useTranslation } from 'react-i18next';
import PaymentMethodRadioButton from '../../../../components/RadioButton/components/PaymentMethodRadioButton';
import PricingSectionCard from './components/PricingSectionCard';
import MobilePricing from './components/MobilePricing';

const centerMobileModalHeader = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    & h2 {
      padding-top: 10px;
    }
  }
`;

const Nav = styled('nav')`
  margin: 0px 0px 30px 0px;

  ul {
    line-height: 1;
    display: flex;
    justify-content: flex-start;
  }
  li {
    text-align: left;
    margin-right: 20px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    border-top: 1px solid ${transparentize(0.95, darkColor)};
    border-bottom: 1px solid ${transparentize(0.95, darkColor)};
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 5px;

    & ul {
      justify-content: center;

      & li {
        flex: 1;
        margin-right: 0px;
      }
    }
  }
`;

const NavButton = styled('button')`
  ${buttonResetCss};
  ${smallMediumPlusTextCss};
  display: block;
  width: 100%;
  font-size: ${props => (props.locale === 'ja' ? '16px' : '30px')};
  color: ${props => (props.selected ? darkColor : transparentize(0.6, darkColor))};
  font-weight: 500;
  text-align: left;

  &:focus,
  &:hover {
    color: ${darkColor};
    outline: none;

    ${SMALL_DEVICE_BREAKPOINT} {
      text-decoration: none;
    }
  }
  &:active {
    color: ${transparentize(0.2, darkColor)};
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 16px;
    letter-spacing: 0.38px;
    padding: 6px 5px;
    text-decoration: none;
    text-align: center;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const BodyContent = styled('section')`
  display: flex;
`;

const InfoSection = styled('section')`
  flex: 1;
  margin-right: 30px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-right: 0px;
  }
`;

const InfoDescription = styled('div')`
  ${bodyTextCss};

  ul {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;

    li {
      margin-top: 10px;
      padding-left: 20px;
      position: relative;
      &::before {
        content: '•';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const FeaturesList = styled('div')`
  ${bodyTextCss};

  ul {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;
    list-style: none;

    li {
      margin-top: 10px;
      padding-left: 20px;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const InclusionsList = styled(FeaturesList)`
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
  }

  ul {
    li {
      &::before {
        content: '•';
      }
    }
  }
`;

const ExclusionsList = styled(FeaturesList)`
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 10px;
  }

  ul {
    margin-top: 0px;
    opacity: 0.5;

    li {
      &:first-of-type {
        margin-top: 10px;
      }
      &::before {
        font-weight: bold;
        content: 'x';
        top: -1px;
      }
    }
  }
`;

const PricingSection = styled('section')`
  width: 100%;
  max-width: 330px;

  ${MEDIA_BREAKPOINTS.min1180} {
    .radio-button-group {
      margin: 10px auto 10px auto;
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;

    .radio-button-group {
      padding-left: 15px;
    }
  }
`;

const landscapeSubscriptionModal = css`
  ${wideModalCss};

  .classic-modal-body {
    padding: 15px 30px 20px 30px;
  }

  svg g {
    stroke: #fff;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    ${LANDSCAPE_DEVICE} {
      width: 100%;
      max-width: none;
    }
  }
`;

type Props = {
  disableAction?: boolean,
  busy: boolean,
  selectedPlan: string,
  selectPlan: (planKey: string) => void,
  onClose: () => void,
  actionText: string,
  action: () => void,
  changingSubscription?: UserSubscriptionMdl | false,
  subscriptionInterval?: string | null,
  subscriptionPlanKey?: string,
  subscriptionModalType: string,
};

export const SubscriptionModal = ({
  disableAction = false,
  busy,
  selectedPlan,
  selectPlan,
  onClose,
  actionText,
  action,
  changingSubscription,
  subscriptionInterval,
  subscriptionPlanKey,
  subscriptionModalType,
}: Props) => {
  const subscriptionsPage = useSubscriptionsPage();
  const [selectOption, setSelectOption] = useState('oneTimePayment');
  const planCopy = usePricingPlan(selectedPlan);
  const { billingCycle, setBillingCycle, selectedPlanNumber } = useSubscriptionsContext();
  const [visiblePlans, setVisiblePlans] = useState([]);
  const locale = useLocale();
  const { t } = useTranslation();

  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const handlePlanIntervalChoices = () => {
    const currentPlan = subscriptionPlanKey;
    let currentInterval = '';
    if (changingSubscription) {
      currentInterval = getSeatInterval(changingSubscription);
    }
    const newPlan = selectedPlan;

    if (currentPlan !== newPlan) {
      if (currentInterval === defaultInterval) {
        setBillingCycle(getNextAvailableInterval(BILLING_CYCLES, defaultInterval));
      }
    }
  };

  useEffect(() => {
    if (!billingCycle) {
      setBillingCycle(getIntervalCycle(BILLING_CYCLES, defaultInterval));
    }
    selectPlan(selectedPlan);
  }, []);

  const filteredPricingPlans = (): Array<any> => {
    const plans = [
      SUBSCRIPTIONS.creatorPro.key,
      SUBSCRIPTIONS.business.key,
      SUBSCRIPTIONS.enterprise.key,
    ];

    if (changingSubscription) {
      const filteredPlans = plans.filter(plan => {
        return (
          !SUBSCRIPTIONS.creator.plan_codes.includes(plan) &&
          !SUBSCRIPTIONS.enterprise.plan_codes.includes(plan)
        );
      });
      return filteredPlans;
    }
    return plans;
  };

  const getModalTitle = (
    subscriptionModalType: string,
    selectedPlanNumber: string | number
  ): string => {
    let title = '';
    if (subscriptionModalType === ACTIONS.add) {
      title = getAddSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.change) {
      title = getChangeSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.downgrade) {
      title = getDowngradeSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.upgrade) {
      title = getUpgradeSubscriptionModalTitle(subscriptionsPage);
    }

    const planNumber = selectedPlanNumber ? selectedPlanNumber : '';

    return title.replace('$NUMBER$', planNumber);
  };

  useEffect(() => {
    const filteredPlans = filteredPricingPlans();
    setVisiblePlans(filteredPlans);
  }, []);

  const handleClose = () => {
    if (busy) return;
    onClose();
  };

  const handleOptionChange = event => {
    setSelectOption(event.target.value);
  };

  const getPlanCode = (planKey: string) => {
    if (isCreatorProSubscription(planKey)) {
      return t('general.planCode.loyalMember', '');
    }

    if (isBusinessSubscription(planKey)) {
      return t('general.planCode.loyalMemberPlus', '');
    }

    if (isEnterpriseSubscription(planKey)) {
      return t('general.planCode.enterprise', '');
    }
  };

  return (
    <ClassicModalLayout
      bannerClassName={cx({
        [guestPlanCss]: isCreatorSubscription(selectedPlan),
        [personalPlanCss]: isCreatorProSubscription(selectedPlan),
        [businessPlanCss]: isBusinessSubscription(selectedPlan),
        [enterprisePlanCss]: isEnterpriseSubscription(selectedPlan),
      })}
      className={landscapeSubscriptionModal}
      onClose={handleClose}
      label={getModalTitle(subscriptionModalType, selectedPlanNumber)}
      fullSized={true}
    >
      <ClassicModalContentLayout className={centerMobileModalHeader}>
        <Nav>
          <ul>
            {visiblePlans.map(planKey => {
              return (
                <li key={planKey}>
                  <NavButton
                    locale={locale}
                    selected={selectedPlan === planKey}
                    onClick={() => {
                      if (busy) return;
                      if (planKey === selectedPlan) return;
                      setBillingCycle(defaultInterval);
                      selectPlan(planKey);
                      handlePlanIntervalChoices();
                    }}
                  >
                    {getPlanCode(planKey)}
                  </NavButton>
                </li>
              );
            })}
          </ul>
        </Nav>
        <MobilePricing
          selectOption={selectOption}
          selectedPlan={selectedPlan}
          changingSubscription={changingSubscription}
          handleOptionChange={handleOptionChange}
          subscriptionPlanKey={subscriptionPlanKey}
          subscriptionInterval={subscriptionInterval}
          action={action}
          subscriptionModalType={subscriptionModalType}
        />
        <BodyContent>
          <InfoSection>
            <InfoDescription>
              <InclusionsList>{parse(getPricingPlanDescription(planCopy))}</InclusionsList>
              <ExclusionsList>{parse(getPricingPlanExclusions(planCopy))}</ExclusionsList>
            </InfoDescription>
          </InfoSection>
          <PricingSection>
            {!isEnterpriseSubscription(selectedPlan) && (
              <PaymentMethodRadioButton
                selectOption={selectOption}
                handleOptionChange={handleOptionChange}
              />
            )}
            <PricingSectionCard
              selectedPlan={selectedPlan}
              disableAction={disableAction}
              busy={busy}
              action={action}
              changingSubscription={changingSubscription}
              selectOption={selectOption}
              subscriptionModalType={subscriptionModalType}
            />
          </PricingSection>
        </BodyContent>
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

const AddSubscriptionModal = ({ onClose, ...props }: Props) => {
  const { selectedPlan } = props;
  const { billingCycle, setBillingCycle, addNewSubscription, userRole } = useSubscriptionsContext();
  const [busy, setBusy] = useState(false);
  const miscCopy = useMiscCopy();
  const profile = useUserProfileDetails();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const subscriptionsPage = useSubscriptionsPage();
  const planCopy = usePricingPlan(selectedPlan);
  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const actionText = isEnterpriseSubscription(selectedPlan)
    ? getMiscCopyGetInContact(miscCopy)
    : getMiscCopyAdd(miscCopy);

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleAddSubscription = () => {
    if (busy) return;
    setBusy(true);
    addNewSubscription(selectedPlan, billingCycle)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when adding subscription');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const handleEnterpriseClick = async () => {
    setBusy(true);
    try {
      const firstName = getProfileFirstName(profile);
      const lastName = getProfileLastName(profile);
      const email = getProfileEmail(profile);
      const baseFormLink = getPricingPlanFormLink(planCopy);
      const userTier = userRoleToMixpanelRole(userRole);
      // エンタープライズ(ベーシックではない方)のお問い合わせはGoogle Formを使用。氏名とEメールは自動入力されるように対応。
      const completeFormLink = `${baseFormLink}?usp=pp_url&entry.153525598=${firstName}&entry.471619541=${lastName}&entry.892833479=${email}`;

      if (window) {
        analyticsMixpanelEnterpriseContactRequest(
          mixpanel,
          moengage,
          email,
          firstName,
          lastName,
          'Subscription Modal',
          userTier
        );
        setTimeout(() => {
          window.location = completeFormLink;
        }, 300);
      }
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage(
        'Something went wrong when creating placeholder Enterprise and redirecting user to Formcrafts'
      );
      Sentry.captureException(error);
      console.log(error);
      setBusy(false);
      return;
    }
  };

  const action = () => {
    if (isEnterpriseSubscription(selectedPlan)) {
      handleEnterpriseClick();
    } else {
      handleAddSubscription();
    }
  };

  return (
    <SubscriptionModal
      {...props}
      onClose={handleClose}
      busy={busy}
      action={action}
      actionText={actionText}
      changingSubscription={false}
      subscriptionModalType={ACTIONS.add}
    />
  );
};

export default AddSubscriptionModal;
