// @flow
import React, { useState } from 'react';
import SongActionButton from '../SongActionButton/SongActionButton';
import { getTrackShareAction } from '../../../../../../../cms/track';
import { useTrackCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import ShareIcon from '../../../../../../../assets/inline-assets/share-icon.svg';
import { isMobile } from '../../../../../../../utils/device';
import ShareSongModal from '../../../../../../../modals/components/ShareSongModal/ShareSongModal';
import { useDisplaySongShareSongShareURL } from '../../../../../FullDisplaySongCard/components/DisplaySongShareContext/DisplaySongShareContext';
import { useShareSongShareURL } from '../../../../../ShareContextWrapper/ShareContextWrapper';

type Props = {
  condensed?: boolean,
  playerType: string,
};

const ShareButton = ({ condensed = false, playerType }: Props) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const shareURL = useDisplaySongShareSongShareURL() || useShareSongShareURL();

  const handleOpen = () => {
    if (isMobile() && navigator.share) {
      navigator
        .share({
          title: 'evokemusic.ai',
          url: shareURL,
        })
        .then(() => {
          console.log('shared...');
        })
        .catch(error => {
          // $FlowFixMe: removes type checking for Sentry as provisional solution
          Sentry.captureMessage('Something went wrong when sharing via navigator.share');
          Sentry.captureException(error);
          console.error(error);
        });
    } else {
      setShareModalOpen(true);
    }
  };
  const handleClose = () => {
    setShareModalOpen(false);
  };

  const trackCopy = useTrackCopy();
  return (
    <React.Fragment>
      <SongActionButton
        icon={<ShareIcon />}
        label={getTrackShareAction(trackCopy)}
        onClick={handleOpen}
        condensed={condensed}
      />
      {shareModalOpen && <ShareSongModal onClose={handleClose} />}
    </React.Fragment>
  );
};

export default ShareButton;
