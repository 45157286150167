// @flow
import { get } from 'lodash';
import { getFirestoreUserData, getUserProfile, getFirestoreUserRef } from './user';
import { getProfileKeywordCount } from './profile';
import { useAuthContext, useUserId } from '../../../auth/components/AuthWrapper/AuthWrapper';
import { useAnalyticsMixpanelContext } from '../../../analytics/components/MixpanelWrapper';
import { updateMixpanelTopKeywordsProfileData } from '../../../analytics/mixpanel';

export type KeywordCount = {
  [string]: number,
};

const getMostUsedKeywords = (keywordCount: KeywordCount): Array<string> => {
  const sortedCount = [];
  Object.keys(keywordCount).forEach(keyword => {
    sortedCount.push([keyword, keywordCount[keyword]]);
  });

  sortedCount.sort((a, b) => {
    return b[1] - a[1];
  });

  const topKeywordCount = sortedCount.slice(0, 10);

  const topKeywords = topKeywordCount.map(countData => {
    return countData[0];
  });

  return topKeywords;
};

export const updateProfileKeywordCount = (
  keywords: Array<string>,
  currentKeywordCount: KeywordCount,
  userId: string,
  mixpanel: any,
  moengage: any
) => {
  const userRef = getFirestoreUserRef(userId);

  const updatedCount = {};

  keywords.forEach(keyword => {
    updatedCount[keyword] = get(currentKeywordCount, keyword, 0) + 1;
  });

  const mergedKeywordCount = {
    ...currentKeywordCount,
    ...updatedCount,
  };

  const mostUsedKeywords = getMostUsedKeywords(mergedKeywordCount);
  updateMixpanelTopKeywordsProfileData(mixpanel, moengage, mostUsedKeywords, userId);

  return userRef.update({
    [`profile.keywordCount`]: mergedKeywordCount,
  });
};

export const useUpdateProfileKeywordCount = (kewyords: Array<string>) => {
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const handleUpdateProfileKeywordCount = () => {
    if (!isAuthenticated) {
      return Promise.resolve();
    }

    return getFirestoreUserData(userId).then(user => {
      const userProfile = getUserProfile(user);
      const currentKeywordCount = getProfileKeywordCount(userProfile);
      return updateProfileKeywordCount(kewyords, currentKeywordCount, userId, mixpanel, moengage);
    });
  };

  return [handleUpdateProfileKeywordCount];
};

export const useWatchProfileKeywordCount = () => {
  /*
  const { isAuthenticated } = useAuthContext();
  const userId = useUserId();
  const [loaded, setLoaded] = useState(false);
  const [hiddenTracks, setHiddenTracks] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return () => {};
    }
    const unsubscribe = getFirestoreUserRef(userId).onSnapshot(data => {
      if (data.exists) {
        const user: FBUserMdl = data.data();
        const profile = getUserProfile(user);
        const updatedHiddenTracks = getProfileHiddenTracks(profile);
        setHiddenTracks(updatedHiddenTracks);
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, userId]);

  return [hiddenTracks, loaded];
  */
};

export const fetchProfileKeywordsUsed = (uid: string): Promise<number> => {
  /*
  const ref = getFirestoreUserRef(uid);
  return ref.get().then(querySnapshot => {
    const downloadCount = querySnapshot.docs.length;
    return downloadCount;
  });
  */
};
