// @flow
import { navigate as reachNavigate } from '@reach/router';
import { getLocalisedPath } from './routes';
import { useLocalePath } from './components/LocaleWrapper/LocaleWrapper';

export const useNavigate = (): ((path: string) => Promise<any>) => {
  const localePath = useLocalePath();

  const navigate = (path: string) => {
    return reachNavigate(getLocalisedPath(localePath, path));
  };

  return navigate;
};
