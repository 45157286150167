// @flow
import React, { useContext, useState } from 'react';

export type MicroPartsContextState = {
  viewingMicroparts: boolean,
  setViewingMicroparts: boolean => void,
};

export const MicroPartsContext = React.createContext();

export const useMicroPartsContext = (): MicroPartsContextState => useContext(MicroPartsContext);

type Props = {
  children: any,
};

const MicroPartsWrapper = ({ children }: Props) => {
  const [viewingMicroparts, setViewingMicroparts] = useState(false);
  return (
    <MicroPartsContext.Provider
      value={{
        viewingMicroparts,
        setViewingMicroparts,
      }}
    >
      {children}
    </MicroPartsContext.Provider>
  );
};

export default MicroPartsWrapper;
