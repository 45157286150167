// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';
import type { CuratedCollectionGraphMdl } from './curatedCollection';

export type FeaturedGenreMdl = {
  genre: {
    document: {
      data: {
        title: {
          text: string,
        },
      },
    },
  },
};

export type FeaturedThemeContentMdl = {
  data: {
    theme_title: {
      text: string,
    },
    theme_content: Array<{
      theme_item: {
        document: Array<CuratedCollectionGraphMdl>,
      },
    }>,
  },
};

export type FeaturedThemeMdl = {
  featured_theme: {
    document: Array<FeaturedThemeContentMdl>,
  },
};

export type FeaturedMoodMdl = {
  mood: {
    document: {
      data: {
        title: {
          text: string,
        },
      },
    },
  },
};

export type FeaturedInstrumentMdl = {
  instrument: {
    document: {
      data: {
        title: {
          text: string,
        },
      },
    },
  },
};

export type FeaturedSoundEffectMdl = {
  sound_effect: {
    document: {
      data: {
        title: {
          text: string,
        },
      },
    },
  },
};

export type MusicPageMdl = {
  data: {
    featured_collections: {
      featured_collection: {
        document: CuratedCollectionGraphMdl,
      },
      featured_image: {
        url: string,
        alt: String,
      },
      featured_mobile_image: {
        url: string,
        alt: String,
      },
      creator_site_collection: boolean,
    },
    new_collections_title: {
      text: string,
    },
    recently_viewed_title: {
      text: string,
    },
    expanded_featured_collections: {
      expanded_collection: {
        document: CuratedCollectionGraphMdl,
      },
    },
    featured_themes_title: {
      text: string,
    },
    featured_themes: Array<FeaturedThemeMdl>,
    featured_genres_title: {
      text: string,
    },
    featured_genres: Array<FeaturedGenreMdl>,
    featured_moods_title: {
      text: string,
    },
    featured_moods: Array<FeaturedMoodMdl>,
    featured_instruments_title: {
      text: string,
    },
    featured_instruments: Array<FeaturedInstrumentMdl>,
    featured_sound_effects_title: {
      text: string,
    },
    featured_sound_effects: Array<FeaturedSoundEffectMdl>,
    all_tags_list_heading: {
      text: string,
    },
  },
};

export const getMusicPageFeaturedCollections = (
  page: MusicPageMdl
): Array<CuratedCollectionGraphMdl> => {
  const featuredCollections = get(page, 'data.featured_collections', []) || [];
  return featuredCollections
    .filter(featuredCollection => {
      return (
        featuredCollection.creator_site_collection ||
        featuredCollection.creator_site_collection === null
      );
    })
    .map(collection => {
      return {
        ...collection.featured_collection.document[0],
        featured_image: collection.featured_image,
        featured_mobile_image: collection.featured_mobile_image,
      };
    });
};

export const getMusicPageFeaturedArtists = (
  page: MusicPageMdl
): Array<CuratedCollectionGraphMdl> => {
  const featuredArtists = get(page, 'data.featured_artists', []) || [];
  return featuredArtists
    .filter(collection => collection.artist && collection.show)
    .map(filteredCollection => {
      return filteredCollection.artist.document[0];
    });
};

export const getMusicPageFeaturedCollectionMainImage = (featuredCollection: any): string => {
  return get(featuredCollection, 'featured_image.url', '') || '';
};

export const getMusicPageFeaturedCollectionMobileImage = (featuredCollection: any): string => {
  return get(featuredCollection, 'featured_mobile_image.url', '') || '';
};

export const getMusicPageNewCollectionsTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.new_collections_title.text', '') || '';
};

export const getMusicPageRecentlyViewedTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.recently_viewed_title.text', '') || '';
};

export const getMusicPageExpandedFeaturedCollections = (
  page: MusicPageMdl
): Array<CuratedCollectionGraphMdl> => {
  const expandedFeaturedCollections = get(page, 'data.expanded_featured_collections', []) || [];

  return expandedFeaturedCollections.map(collection => collection.expanded_collection.document[0]);
};

export const getMusicPageFeaturedThemesSectionTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.featured_themes_title.text', '') || '';
};

export const getMusicPageFeaturedThemes = (page: MusicPageMdl): Array<FeaturedThemeContentMdl> => {
  const featuredThemes = get(page, 'data.featured_themes', []) || [];
  return featuredThemes
    .filter(featuredTheme => {
      return featuredTheme.creator_site_theme || featuredTheme.creator_site_theme === null;
    })
    .map(theme => {
      return theme.featured_theme.document[0];
    });
};

export const getMusicPageFeaturedGenresSectionTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.featured_genres_title.text', '') || '';
};

export const getMusicPageFeaturedGenres = (page: MusicPageMdl): Array<FeaturedGenreMdl> => {
  return get(page, 'data.featured_genres', '') || '';
};

export const getMusicPageFeaturedGenreTag = (genre: FeaturedGenreMdl): string => {
  return get(genre, 'genre.document[0].data.title.text', '') || '';
};

export const getMusicPageFeaturedGenreImage = (genre: FeaturedGenreMdl): string => {
  return get(genre, 'genre.document[0].data.image.url', '') || '';
};

export const getMusicPageFeaturedMoodsSectionTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.featured_moods_title.text', '') || '';
};

export const getMusicPageFeaturedMoods = (page: MusicPageMdl): Array<FeaturedMoodMdl> => {
  return get(page, 'data.featured_moods', '') || '';
};

export const getMusicPageFeaturedMoodTag = (mood: FeaturedMoodMdl): string => {
  return get(mood, 'mood.document[0].data.title.text', '') || '';
};

export const getMusicPageFeaturedMoodImage = (mood: FeaturedMoodMdl): string => {
  return get(mood, 'mood.document[0].data.image.url', '') || '';
};

export const getMusicPageFeaturedInstrumentsSectionTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.featured_instruments_title.text', '') || '';
};

export const getMusicPageFeaturedInstruments = (
  page: MusicPageMdl
): Array<FeaturedInstrumentMdl> => {
  return get(page, 'data.featured_instruments', '') || '';
};

export const getMusicPageFeaturedInstrumentTag = (instrument: FeaturedInstrumentMdl): string => {
  return get(instrument, 'instrument.document[0].data.title.text', '') || '';
};

export const getMusicPageFeaturedInstrumentImage = (instrument: FeaturedInstrumentMdl): string => {
  return get(instrument, 'instrument.document[0].data.image.url', '') || '';
};

export const getMusicPageFeaturedSfxSectionTitle = (page: MusicPageMdl): string => {
  return get(page, 'data.featured_sound_effects_title.text', '') || '';
};

export const getMusicPageFeaturedSfx = (page: MusicPageMdl): Array<FeaturedSoundEffectMdl> => {
  return get(page, 'data.featured_sound_effects', '') || '';
};

export const getMusicPageFeaturedSfxTag = (instrument: FeaturedInstrumentMdl): string => {
  return get(instrument, 'sound_effect.document[0].data.title.text', '') || '';
};

export const getMusicPageFeaturedSfxImage = (instrument: FeaturedInstrumentMdl): string => {
  return get(instrument, 'sound_effect.document[0].data.image.url', '') || '';
};

export const getMusicPageAllTagsListHeading = (page: MusicPageMdl): string => {
  return get(page, 'data.all_tags_list_heading.text', '') || '';
};

export const getFeaturedTag = (tag: any, tagType: string): string => {
  if (tagType === 'genre') {
    return getMusicPageFeaturedGenreTag(tag);
  }

  if (tagType === 'mood') {
    return getMusicPageFeaturedMoodTag(tag);
  }

  if (tagType === 'instruments') {
    return getMusicPageFeaturedInstrumentTag(tag);
  }

  if (tagType === 'type') {
    return getMusicPageFeaturedSfxTag(tag);
  }

  return '';
};

export const getFeaturedTagImage = (tag: any, tagType: string): string => {
  if (tagType === 'genre') {
    return getMusicPageFeaturedGenreImage(tag);
  }

  if (tagType === 'mood') {
    return getMusicPageFeaturedMoodImage(tag);
  }

  if (tagType === 'instruments') {
    return getMusicPageFeaturedInstrumentImage(tag);
  }

  if (tagType === 'type') {
    return getMusicPageFeaturedSfxImage(tag);
  }

  return '';
};

export const query = graphql`
  fragment MusicPage on PrismicMusicPage {
    data {
      featured_collections {
        featured_image {
          url
          alt
        }
        featured_mobile_image {
          url
          alt
        }
        featured_collection {
          document {
            uid
            type
            data {
              collection_title {
                text
              }
              collection_image {
                url
                alt
              }
              collection_songs {
                text
              }
            }
          }
        }
        creator_site_collection
      }
      featured_artists {
        show
        artist {
          document {
            data {
              name {
                text
              }
              cover_art {
                url
              }
              thumbnail {
                url
              }
              description {
                text
              }
            }
            uid
            type
          }
        }
      }
      new_collections_title {
        text
      }
      expanded_featured_collections {
        expanded_collection {
          document {
            uid
            data {
              collection_title {
                text
              }
              collection_songs {
                text
              }
              creator_site_collection
            }
          }
        }
      }
      featured_themes_title {
        text
      }
      featured_themes {
        featured_theme {
          document {
            data {
              theme_title {
                text
              }
              theme_content {
                theme_item {
                  document {
                    uid
                    data {
                      collection_title {
                        text
                      }
                      collection_image {
                        url
                        alt
                      }
                      collection_songs {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        creator_site_theme
      }
      featured_genres_title {
        text
      }
      featured_genres {
        genre {
          document {
            data {
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
      featured_moods_title {
        text
      }
      featured_moods {
        mood {
          document {
            data {
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
      featured_instruments_title {
        text
      }
      featured_instruments {
        instrument {
          document {
            data {
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
      featured_sound_effects_title {
        text
      }
      featured_sound_effects {
        sound_effect {
          document {
            data {
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
      all_tags_list_heading {
        text
      }
      recently_viewed_title {
        text
      }
    }
  }
`;
