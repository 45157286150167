// @flow
import React from 'react';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { css, keyframes } from 'emotion';
import styled from 'react-emotion';

const toastContainerClass = css`
  z-index: 9999;

  & div {
    display: none;
  }

  & div:last-of-type {
    display: flex;
  }
`;

export const MyCustomToastContainer = props => (
  <DefaultToastContainer {...props} className={toastContainerClass} />
);

const toastKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  8% {
    opacity: 1;
    transform: translateY(-5px);
  }
  92% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
`;

const Toast = styled('div')`
  animation: ${toastKeyframes} 8500ms 1;
`;

const MyCustomToast = ({ children }: { children: any }) => <Toast>{children}</Toast>;

type Props = {
  children: any,
};

const ToastWrapper = ({ children }: Props) => (
  <ToastProvider
    autoDismissTimeout={8000}
    components={{ Toast: MyCustomToast, ToastContainer: MyCustomToastContainer }}
    placement="bottom-center"
  >
    {children}
  </ToastProvider>
);

export default ToastWrapper;
