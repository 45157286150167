// @flow
import React, { useEffect } from 'react';
import ErrorInfo from '../../../ErrorInfo/ErrorInfo';
import { useNotFoundPageCopy } from '../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getNotFoundHeading,
  getNotFoundHomeButton,
  getNotFoundMessage,
} from '../../../../../cms/notFoundPage';
import Button from '../../../../../components/Button/Button';
import { ErrorLayout } from '../GenericError/GenericError';
import { useLocalePath } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { ROUTES } from '../../../../../routing/routes';

type Props = {};

const NotFoundError = () => {
  const notFoundCopy = useNotFoundPageCopy();
  const localePath = useLocalePath();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = ROUTES.landing.navigatePath({ localePath });
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorLayout>
      <ErrorInfo
        heading={getNotFoundHeading(notFoundCopy)}
        description={getNotFoundMessage(notFoundCopy)}
        footer={
          <Button href={ROUTES.landing.navigatePath({ localePath })}>
            {getNotFoundHomeButton(notFoundCopy)}
          </Button>
        }
      />
    </ErrorLayout>
  );
};

export default NotFoundError;
