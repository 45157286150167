// @flow
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES } from '../../../locales';
import ModalsWrapper from '../../../modals/components/ModalsWrapper/ModalsWrapper';
import ErrorPopupsWrapper from '../../../errors/components/ErrorPopupsWrapper/ErrorPopupsWrapper';
import SubscriptionsContextWrapper from '../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import AuthRenewWrapper from '../../../auth/components/AuthRenewWrapper/AuthRenewWrapper';
import KeyboardShortcutWrapper from '../../../components/KeyboardShortcutWrapper/KeyboardShortcutWrapper';
import GlobalPlayerWrapper from '../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import DownloadedSongsManager from '../../../components/DownloadedSongsContextWrapper/DownloadedSongsContextWrapper';
import MusicSearchBarWrapper from '../../../components/SiteHeader/components/SiteNav/components/MusicSearchBarWrapper/MusicSearchBarWrapper';

export type LocaleContextState = {
  locale: string,
  localePath: string,
};

const LocaleContext = React.createContext<LocaleContextState>({
  locale: LOCALES.english.code,
  localePath: '',
});

export const useLocaleContext = (): LocaleContextState => {
  return useContext(LocaleContext);
};

export const useLocalePath = (): string => {
  const { localePath } = useLocaleContext();
  return localePath;
};

export const useLocale = (): string => {
  const { locale } = useLocaleContext();
  return locale;
};

type Props = {
  children: any,
  locale: string,
  localePath: string,
};

const LocaleWrapper = ({ children, locale, localePath }: Props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (locale === LOCALES.chinese.code) {
      i18n.changeLanguage('cn');
    } else if (locale === LOCALES.english.code) {
      i18n.changeLanguage('en');
    } else if (locale === LOCALES.japanese.code) {
      i18n.changeLanguage('ja');
    }
  }, [locale, i18n]);

  useEffect(() => {
    try {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.configureScope(scope => {
        scope.setTag('page_locale', locale);
      });
    } catch (error) {
      console.error(error);
    }
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        localePath,
      }}
    >
      <AuthRenewWrapper>
        <ErrorPopupsWrapper>
          <KeyboardShortcutWrapper>
            <SubscriptionsContextWrapper>
              <ModalsWrapper>
                <DownloadedSongsManager>
                  <MusicSearchBarWrapper>
                    <GlobalPlayerWrapper>{children}</GlobalPlayerWrapper>
                  </MusicSearchBarWrapper>
                </DownloadedSongsManager>
              </ModalsWrapper>
            </SubscriptionsContextWrapper>
          </KeyboardShortcutWrapper>
        </ErrorPopupsWrapper>
      </AuthRenewWrapper>
    </LocaleContext.Provider>
  );
};

export default LocaleWrapper;
