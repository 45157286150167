// @flow
import { get, uniq } from 'lodash';
import { ROUTES } from '../../routing/routes';
import { formatSeconds } from '../../utils/time';
import { getEnvShareUrl } from '../../utils/env';

export type HistogramDataMdl = Array<[number, number]>;

export type SongMicropartMdl = {
  download: string,
  duration: number,
  end: number,
  position: number,
  shareSlug: string,
  start: number,
};

export type AlgoliaSongKeywordsMdl = {
  mood: Array<string>,
  'video-theme': Array<string>,
  'edit-style': Array<string>,
  movement: Array<string>,
  location: Array<string>,
  energy: Array<string>,
  genre: Array<string>,
  instruments: Array<string>,
  material: Array<string>,
  type: Array<string>,
  feature: Array<string>,
};

export type AlgoliaSongMdl = {
  shareSlug: string,
  family: string,
  dateUpdated: number,
  details: {
    tags: AlgoliaSongKeywordsMdl,
  },
  isPart: boolean,
  mixType: string,
  type: 'full_mix' | string,
  genre: Array<string>,
  bpm: string,
  audio: {
    download: string,
    duration: number,
    stream: string,
    histogram: HistogramDataMdl,
    downloadMidi: string,
  },
  objectID: string,
  microparts: Array<SongMicropartMdl>,
  popularity: number,
  keywords: {
    manual: AlgoliaSongKeywordsMdl,
  },
  songArtwork?: string,
  artistName?: string,
  artistSlug?: string,
  trackTitle?: string,
  _rankingInfo: {
    nbExactWords: number,
  },
};

export type AlgoliaSongSimlarBpmMdl = {
  minBpm: number,
  maxBpm: number,
};

export const getSongDownloadUrl = (song: AlgoliaSongMdl): string => {
  return get(song, 'audio.download', '');
};

export const getDownloadMIDIUrl = (song: AlgoliaSongMdl): string => {
  return get(song, 'audio.downloadMidi', '');
};

export const isSongFullMix = (song: AlgoliaSongMdl): boolean => {
  return getSongType(song) === 'full_mix';
};

export const isSongStem = (song: AlgoliaSongMdl): boolean => {
  return song.isPart;
};

export const isSongSfx = (song: AlgoliaSongMdl): boolean => {
  return getSongType(song) === 'sfx';
};

export const getSongHistogram = (song: AlgoliaSongMdl): HistogramDataMdl => {
  const waveform = get(song, 'audio.waveform', []);
  if (typeof waveform === 'string') {
    return JSON.parse(waveform);
  }
  return waveform;
};

export const getSongDuration = (song: AlgoliaSongMdl): number => {
  const duration = get(song, 'audio.duration', 0);
  return duration / 1000;
};

export const getSongDurationFormatted = (song: AlgoliaSongMdl): string => {
  return formatSeconds(getSongDuration(song));
};

export const getSongFamily = (song: AlgoliaSongMdl): string => {
  return get(song, 'family', '');
};

export const getSongBPM = (song: AlgoliaSongMdl): string => {
  return get(song, 'bpm', '--');
};

export const getSongDetails = (song: AlgoliaSongMdl): AlgoliaSongKeywordsMdl => {
  return get(song, 'details.tags', {});
};

export const getSongGenres = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.genre', []);
};

export const getSongMood = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.mood', []);
};

export const getSongEnergy = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.energy', []);
};

export const getSongLocation = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.location', []);
};

export const getSongMovement = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.movement', []);
};

export const getSongVideoTheme = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.video-theme', []);
};

export const getSongEditStyle = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.edit-style', []);
};

export const getSongInstruments = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.instruments', []);
};

export const getSongTypeTags = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.type', []);
};

export const getSongMaterial = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.material', []);
};

export const getSongFeature = (song: AlgoliaSongMdl): Array<string> => {
  return get(song, 'details.tags.feature', []);
};

export const getSongID = (song: AlgoliaSongMdl): string => {
  return get(song, 'objectID');
};

export const getSongType = (song: AlgoliaSongMdl): string => {
  return get(song, 'type', '');
};

export const getSongMixType = (song: AlgoliaSongMdl): string => {
  return get(song, 'mixType', '');
};

export const getSongDateUpdated = (song: AlgoliaSongMdl): number => {
  return get(song, 'dateUpdated', 0);
};

export const getSongPopularity = (song: AlgoliaSongMdl): number => {
  return get(song, 'popularity', 0);
};

export const getSongArtwork = (song: AlgoliaSongMdl): string => {
  return get(song, 'songArtwork', '') || '';
};

export const getSongArtist = (song: AlgoliaSongMdl): string => {
  return get(song, 'artistName', '') || '';
};

export const getSongTrackTitle = (song: AlgoliaSongMdl): string => {
  return get(song, 'trackTitle', '') || '';
};

export const getSongArtistSlug = (song: AlgoliaSongMdl): string => {
  return get(song, 'artistSlug', '') || '';
};

export const getSongKeywords = (song: AlgoliaSongMdl): AlgoliaSongKeywordsMdl => {
  return get(song, 'keywords.manual', {});
};

export const getSongExactRankingWords = (song: AlgoliaSongMdl): number => {
  return get(song, '_rankingInfo.nbExactWords', 0);
};

export const isSongQueryFullMatch = (
  song: AlgoliaSongMdl,
  queryKeywords: Array<string>
): boolean => {
  const exactQueryWords = getSongExactRankingWords(song);
  return queryKeywords.length === exactQueryWords;
};

export const getSongStreamUrl = (song: AlgoliaSongMdl): string => {
  // return doodoo;
  let url = get(song, 'audio.stream');
  if (process.env.GATSBY_REPLACE_API_URL) {
    url = url.replace('https://api.evokemusic.ai', 'https://staging-api.evokemusic.ai');
  }
  return url;
};

export const getSongShareSlug = (song: AlgoliaSongMdl): string => {
  return get(song, 'shareSlug');
};

export const getSongShareUrlFromShareSlug = (shareSlug: string, localePath: string): string => {
  const path = ROUTES.musicPack.navigatePath({ shareSlug, localePath });
  return `${getEnvShareUrl()}${path}`;
};

export const getSongShareUrl = (song: AlgoliaSongMdl, localePath: string): string => {
  const shareSlug = getSongShareSlug(song);
  return getSongShareUrlFromShareSlug(shareSlug, localePath);
};

export const getSongMicroparts = (song: AlgoliaSongMdl): Array<SongMicropartMdl> => {
  return get(song, 'microparts', []) || [];
};

export const getParsedMicroparts = (song: AlgoliaSongMdl): Array<[number, number]> => {
  const microparts: Array<SongMicropartMdl> = getSongMicroparts(song);

  const duration = getSongDuration(song);

  return microparts.map(({ end, start }) => {
    const startRatio = start / 1000 / duration;
    const endRatio = end / 1000 / duration;
    return [startRatio, endRatio];
  });
};

export const getMicropartDownloadURLs = (song: AlgoliaSongMdl): Array<string> => {
  const microparts: Array<SongMicropartMdl> = getSongMicroparts(song);
  return microparts.map(({ download }) => download);
};

export const getSongMicropartDownloadURL = (song: AlgoliaSongMdl, index: number): string => {
  const urls = getMicropartDownloadURLs(song);
  return urls[index];
};

export const getMicropartShareSlug = (micropart: SongMicropartMdl): string => {
  return get(micropart, 'shareSlug', '');
};

export const getMicropartPosition = (micropart: SongMicropartMdl): number => {
  return get(micropart, 'position', 0);
};

export const getSongMicropartShareSlug = (song: AlgoliaSongMdl, index: number): string => {
  const microparts = getSongMicroparts(song);
  const micropart = microparts[index];
  return micropart ? getMicropartShareSlug(micropart) : '';
};

export const getSongMicropartPosition = (song: AlgoliaSongMdl, index: number): number => {
  const microparts = getSongMicroparts(song);
  const micropart = microparts[index];
  return micropart ? getMicropartPosition(micropart) : 0;
};

export const getKeywordsFromSongDetails = (song: AlgoliaSongMdl): Array<string> => {
  const songDetails = getSongDetails(song);
  let songKeywords = [];

  Object.keys(songDetails)
    .filter(category => category !== 'instruments')
    .forEach(filteredCategory => {
      songKeywords = songKeywords.concat(songDetails[filteredCategory]);
    });

  const uniqueSongKeywords = uniq(songKeywords);

  return uniqueSongKeywords;
};

export const getSimilarBpmRange = (song: AlgoliaSongMdl): AlgoliaSongSimlarBpmMdl => {
  const bpm = Number(getSongBPM(song));
  const minBpm = Math.max(bpm - 5, 0);
  const maxBpm = bpm + 5;

  return { minBpm, maxBpm };
};

export const getSongName = (song: AlgoliaSongMdl, includePart: boolean = true) => {
  const trackTitle = getSongTrackTitle(song);

  if (trackTitle) {
    return isSongSfx(song) ? trackTitle.toLowerCase() : trackTitle.toUpperCase();
  }

  const nameParts = [];
  const bpm = getSongBPM(song);
  const firstGenre = getSongGenres(song)[0] || '';
  const firstMood = getSongMood(song)[0] || '';
  const firstMovement = getSongMovement(song)[0] || '';
  const firstInstrument = getSongInstruments(song)[0] || '';
  const part = isSongFullMix(song) ? '' : getSongMixType(song).toLocaleLowerCase();

  nameParts.push(bpm);
  nameParts.push(firstGenre);
  nameParts.push(firstMood);
  nameParts.push(firstMovement);
  nameParts.push(firstInstrument);

  if (part && includePart) {
    nameParts.push(part);
  }

  const songName = nameParts
    .filter(np => np)
    .join('_')
    .replace(/ /g, '_')
    .replace(/_-_/g, '_');

  return songName;
};

export const getSongSubtitle = (
  song: AlgoliaSongMdl,
  playing: boolean | null,
  songProgress: number,
  showAllMixes: boolean = false
) => {
  const mixTypeLabel = showAllMixes
    ? getSongMixType(song).replace('and', '&')
    : isSongStem(song)
    ? getSongMixType(song).replace('and', '&')
    : '';
  const timeLabel = playing
    ? `${formatSeconds(songProgress)}`
    : `${getSongDurationFormatted(song)}`;
  return playing !== null ? `${mixTypeLabel} ${timeLabel}` : mixTypeLabel;
};
