// @flow

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { SUBSCRIPTIONS, BILLING_CYCLES } from '../user/subscriptions/data';
import {
  isCreatorSubscription,
  isBusinessSubscription,
  isCreatorProSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../utils/subscriptions';

export type PricingPagePlanMdl = {
  uid: string,
  data: {
    name: {
      text: string,
    },
    brief_description: {
      text: string,
    },
    plan_inclusions: {
      html: string,
    },
    plan_exclusions: {
      html: string,
    },
    monthly_price: {
      text: string,
    },
    monthly_label: {
      text: string,
    },
    quarterly_price: {
      text: string,
    },
    quarterly_label: {
      text: string,
    },
    biannually_price: {
      text: string,
    },
    biannually_label: {
      text: string,
    },
    annually_price: {
      text: string,
    },
    annually_label: {
      text: string,
    },
    monthly_billing_subtext: {
      text: string,
    },
    plan_form_link: {
      text: string,
    },
    plan_audience: {
      html: string,
    },
    available_intervals: Array<{
      interval: string,
    }>,
  },
};

export const getPricingPagePlanName = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.name.text', '') || '';
};

export const getPricingPagePlanBriefDescription = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.brief_description.text', '') || '';
};

export const getPricingPagePlanInclusions = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.plan_inclusions.html', '') || '';
};

export const getPricingPagePlanExclusions = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.plan_exclusions.html', '') || '';
};

export const getPricingPagePlanMonthlyPrice = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.monthly_price.text', '') || '';
};

export const getPricingPagePlanMonthlyLabel = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.monthly_label.text', '') || '';
};

export const getPricingPagePlanQuarterlyPrice = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.quarterly_price.text', '') || '';
};

export const getPricingPagePlanQuarterlyLabel = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.quarterly_label.text', '') || '';
};

export const getPricingPagePlanBiannualPrice = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.biannually_price.text', '') || '';
};

export const getPricingPagePlanBiannualLabel = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.biannually_label.text', '') || '';
};

export const getPricingPagePlanAnnuallyPrice = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.annually_price.text', '') || '';
};

export const getPricingPagePlanAnnuallyLabel = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.annually_label.text', '') || '';
};

export const getPricingPagePlanMonthlyBillingSubtext = (
  plan: PricingPagePlanMdl | null
): string => {
  return get(plan, 'data.monthly_billing_subtext.text', '') || '';
};

export const getPricingPagePlan = (
  plans: Array<PricingPagePlanMdl>,
  uid: string
): PricingPagePlanMdl | null => {
  if (isCreatorSubscription(uid)) {
    const pricingPlan = plans.find(plan => {
      return plan.uid === SUBSCRIPTIONS.creator.key;
    });
    return pricingPlan || null;
  }

  if (isCreatorProSubscription(uid)) {
    const pricingPlan = plans.find(plan => {
      return plan.uid === SUBSCRIPTIONS.creatorPro.key;
    });
    return pricingPlan || null;
  }

  if (isBusinessSubscription(uid)) {
    const pricingPlan = plans.find(plan => {
      return plan.uid === SUBSCRIPTIONS.business.key;
    });
    return pricingPlan || null;
  }

  if (isEnterpriseSubscription(uid)) {
    const pricingPlan = plans.find(plan => {
      return plan.uid === SUBSCRIPTIONS.enterprise.key;
    });
    return pricingPlan || null;
  }

  if (isEnterpriseBasicSubscription(uid)) {
    const pricingPlan = plans.find(plan => {
      return plan.uid === SUBSCRIPTIONS.enterprise.key;
    });
    return pricingPlan || null;
  }

  const pricingPlan = plans.find(plan => {
    return plan.uid === SUBSCRIPTIONS.creatorPro.key;
  });
  return pricingPlan || null;
};

export const getPricingPagePlanIntervalPrice = (
  pricingPlan: PricingPagePlanMdl | null,
  interval: string
): string => {
  if (interval === BILLING_CYCLES.annually.cycle) {
    return getPricingPagePlanAnnualPrice(pricingPlan);
  }

  if (interval === BILLING_CYCLES.biannually.cycle) {
    return getPricingPagePlanBiannualPrice(pricingPlan);
  }

  if (interval === BILLING_CYCLES.quarterly.cycle) {
    return getPricingPagePlanQuarterlyPrice(pricingPlan);
  }

  return getPricingPagePlanMonthlyPrice(pricingPlan);
};

export const getPricingPagePlanIntervalLabel = (
  pricingPlan: PricingPagePlanMdl | null,
  interval: string
): string => {
  if (interval === BILLING_CYCLES.annually.cycle) {
    return getPricingPagePlanAnnualLabel(pricingPlan);
  }

  if (interval === BILLING_CYCLES.biannually.cycle) {
    return getPricingPagePlanBiannualLabel(pricingPlan);
  }

  if (interval === BILLING_CYCLES.quarterly.cycle) {
    return getPricingPagePlanQuarterlyLabel(pricingPlan);
  }

  return getPricingPagePlanMonthlyLabel(pricingPlan);
};

export const getPricingPagePlanFormLink = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.plan_form_link.text', '') || '';
};

export const getPricingPagePlanAudience = (plan: PricingPagePlanMdl | null): string => {
  return get(plan, 'data.plan_audience.html', '') || '';
};

export const getPricingPagePlanAvailableIntervals = (plan: PricingPagePlanMdl | null): string[] => {
  const planIntervals = get(plan, 'data.available_intervals', []) || [];
  return planIntervals.map(planInterval => planInterval.interval);
};

export const query = graphql`
  fragment PricingPagePlan on PrismicPricingPagePlan {
    uid
    data {
      name {
        text
      }
      brief_description {
        text
      }
      plan_inclusions {
        html
      }
      plan_exclusions {
        html
      }
      monthly_price {
        text
      }
      monthly_label {
        text
      }
      quarterly_price {
        text
      }
      quarterly_label {
        text
      }
      biannually_price {
        text
      }
      biannually_label {
        text
      }
      annually_price {
        text
      }
      annually_label {
        text
      }
      monthly_billing_subtext {
        text
      }
      plan_form_link {
        text
      }
      plan_audience {
        html
      }
      available_intervals {
        interval
      }
    }
  }
`;
