// @flow
import React, { useContext } from 'react';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import DownloadContextWrapper from '../../../../../components/DownloadContextWrapper/DownloadContextWrapper';

export type BrowseManagerContextState = {
  browsingSongs: Array<AlgoliaSongMdl>,
  songListType: string,
  songListTitle: string,
  songListSlug: string,
  queueRestricted: boolean,
  restrictionType: string,
};

export const BrowseSongsContext = React.createContext<any>();

export const useBrowseManagerContext = (): BrowseManagerContextState => {
  return useContext(BrowseSongsContext);
};

type Props = {
  children: any,
  songs: Array<AlgoliaSongMdl>,
  songListType: string,
  songListTitle: string,
  songListSlug: string,
  songlistKeywords?: Array<string>,
  queueRestricted?: boolean,
  restrictionType?: string,
  keywords?: Array<string>,
  mixpanelKeywords?: Array<string>,
  magicKeywords?: Array<string>,
  youtubeKeywords?: Array<string>,
  youtubeVideoTitle?: string,
  sectionOrigin?: string,
  relatedCollection?: string,
};

const BrowseSongsManager = ({
  children,
  songs,
  songListType,
  songListTitle,
  songListSlug,
  songlistKeywords = [],
  queueRestricted = false,
  restrictionType = '',
  keywords = [],
  mixpanelKeywords = [],
  magicKeywords = [],
  youtubeKeywords = [],
  youtubeVideoTitle = '',
  sectionOrigin = '',
  relatedCollection = '',
}: Props) => {
  const collectionSlug = songListType === 'collection' ? songListSlug : '';
  const artistSlug = songListType === 'artist' ? songListSlug : '';

  return (
    <BrowseSongsContext.Provider
      value={{
        browsingSongs: songs,
        songListTitle,
        songListType,
        songListSlug,
        songlistKeywords,
        queueRestricted,
        restrictionType,
      }}
    >
      <DownloadContextWrapper
        location={songListType}
        curatedCollection={collectionSlug}
        artistProfile={artistSlug}
        keywords={keywords}
        mixpanelKeywords={mixpanelKeywords}
        magicKeywords={magicKeywords}
        youtubeKeywords={youtubeKeywords}
        youtubeVideoTitle={youtubeVideoTitle}
        sectionOrigin={sectionOrigin}
        relatedCollection={relatedCollection}
      >
        {children}
      </DownloadContextWrapper>
    </BrowseSongsContext.Provider>
  );
};

export default BrowseSongsManager;
