// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useSetRecoilState } from 'recoil';
import PackIcon from '../../../../../../../../assets/inline-assets/pack-icon.svg';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getTrackPackAction } from '../../../../../../../../cms/track';
import { useSong } from '../../../../../../SongContextWrapper/SongContextWrapper';
import { useNavigate } from '../../../../../../../../routing/hooks';
import { ROUTES } from '../../../../../../../../routing/routes';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import { getPlayerKeyboardShortcuts } from '../../../../../../../../utils/keyboardShortcuts';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  directSearchKeywordsAtom,
  directSearchMagicKeywordsAtom,
  directSearchMixpanelKeywordsAtom,
  directSearchYouTubeKeywordsAtom,
  directTriggerAtom,
} from '../../../../../../../../store/mixpanelAnalytics';

const ViewPackInlineButton = () => {
  const trackCopy = useTrackCopy();
  const song = useSong();
  const shareSlug = getSongShareSlug(song);
  const navigate = useNavigate();

  const setDirectSearchTriggerLocation = useSetRecoilState(directTriggerAtom);
  const setDirectSearchKeywords = useSetRecoilState(directSearchKeywordsAtom);
  const setDirectSearchMixpanelKeywords = useSetRecoilState(directSearchMixpanelKeywordsAtom);
  const setDirectSearchMagicKeywords = useSetRecoilState(directSearchMagicKeywordsAtom);
  const setDirectSearchYouTubeKeywords = useSetRecoilState(directSearchYouTubeKeywordsAtom);

  const {
    location,
    keywords,
    mixpanelKeywords,
    magicKeywords,
    youtubeKeywords,
  } = useDownloadContext();

  const setPackAnalytics = () => {
    setDirectSearchKeywords(keywords);
    setDirectSearchMixpanelKeywords(mixpanelKeywords);
    setDirectSearchMagicKeywords(magicKeywords);
    setDirectSearchYouTubeKeywords(youtubeKeywords);
    setDirectSearchTriggerLocation(location);
  };

  const handleNavigatePack = () => {
    setPackAnalytics();
    navigate(ROUTES.musicPack.navigatePath({ shareSlug }));
  };

  const keyboardShortcutHandlers = (() => {
    if (true) {
      return {
        view_pack: handleNavigatePack,
      };
    }
    return {
      view_pack: handleNavigatePack,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <InlineButton
        label={getTrackPackAction(trackCopy)}
        icon={<PackIcon />}
        action={handleNavigatePack}
      />
    </>
  );
};

export default ViewPackInlineButton;
