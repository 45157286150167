// @flow
import { get } from 'lodash';
import { base64EncodeURLValue, base64DecodeURLValue } from '../../utils/encode';

export type AlgoliaKeyword = {
  objectID?: string,
  keyword: string,
  keywordType: string,
  queryReplacement?: Array<string>,
};

export const getAlgoliaKeyword = (indexKeyword: AlgoliaKeyword | null): string => {
  if (!indexKeyword) {
    return '';
  }
  return get(indexKeyword, 'keyword', '');
};

export const getAlgoliaKeywordType = (indexKeyword: AlgoliaKeyword): string => {
  return get(indexKeyword, 'keywordType', '');
};

export const getAlgoliaKeywordParent = (indexKeyword: AlgoliaKeyword): string => {
  return get(indexKeyword, 'parent', '');
};

export const getAlgoliaReplacementKeywords = (
  indexKeyword: AlgoliaKeyword | null
): Array<string> => {
  if (!indexKeyword) {
    return [];
  }
  return get(indexKeyword, 'queryReplacement', []);
};

export const isReplacableKeyword = (indexKeyword: AlgoliaKeyword | null): boolean => {
  if (!indexKeyword) {
    return false;
  }
  const keywordType = getAlgoliaKeywordType(indexKeyword);

  if (keywordType === 'queryReplacement') {
    return true;
  }

  return false;
};

export const getKeywordObjectToAdd = (
  indexKeywords?: Array<AlgoliaKeyword>,
  keywordAdded: string
): AlgoliaKeyword | null => {
  if (!indexKeywords || indexKeywords.length === 0) {
    return null;
  }

  const keywordObjectToAdd = indexKeywords.find(indexKeyword => {
    return (
      getAlgoliaKeyword(indexKeyword) === keywordAdded ||
      getAlgoliaKeyword(indexKeyword) === keywordAdded.toLocaleLowerCase()
    );
  });

  if (!keywordObjectToAdd) {
    return null;
  }

  return keywordObjectToAdd;
};

export const getOppositeSyntax = (keyword: string) => {
  if (keyword[0] === '-') {
    return keyword.slice(1);
  }
  return '-'.concat(keyword);
};

export const getInverseKeywordObjectToAdd = (
  indexKeywords?: Array<AlgoliaKeyword>,
  keywordAdded: string
): AlgoliaKeyword | null => {
  if (!indexKeywords || indexKeywords.length === 0) {
    return null;
  }

  const keywordObjectToAdd = indexKeywords.find(indexKeyword => {
    return getAlgoliaKeyword(indexKeyword) === keywordAdded;
  });

  if (!keywordObjectToAdd) {
    return null;
  }

  const invertedAlgoliaKeyword = {
    ...keywordObjectToAdd,
    keyword: getOppositeSyntax(getAlgoliaKeyword(keywordObjectToAdd)),
    queryReplacement: getAlgoliaReplacementKeywords(keywordObjectToAdd).map(replacementKeyword =>
      getOppositeSyntax(replacementKeyword)
    ),
  };

  return invertedAlgoliaKeyword;
};

export const getMergedQueryKeywordValues = (
  keywords: Array<string>,
  magicKeywords: Array<AlgoliaKeyword>
): Array<string> => {
  let magicKeywordReplacements = [];

  magicKeywords.forEach(magicKeyword => {
    magicKeywordReplacements = magicKeywordReplacements.concat(
      getAlgoliaReplacementKeywords(magicKeyword)
    );
  });

  const mergedQueryKeywords = magicKeywordReplacements.concat(keywords);
  const uniqueQueryKeywordsSet = new Set(mergedQueryKeywords);
  const uniqueMergedQueryKeywords = [...uniqueQueryKeywordsSet];
  return uniqueMergedQueryKeywords;
};

export const getMergedVisibleKeywordValues = (
  keywords: Array<string>,
  magicKeywords: Array<AlgoliaKeyword>
): Array<string> => {
  let magicKeywordValues = [];

  magicKeywords.forEach(magicKeyword => {
    magicKeywordValues = magicKeywordValues.concat(getAlgoliaKeyword(magicKeyword));
  });

  const mergedVisibleKeywords = magicKeywordValues.concat(keywords);
  return mergedVisibleKeywords;
};

export const getMagicKeywordQueryValues = (magicKeywords: Array<AlgoliaKeyword>): Array<string> => {
  let magicKeywordQuery = [];

  magicKeywords.forEach(magicKeyword => {
    const replacementKeywords = getAlgoliaReplacementKeywords(magicKeyword);
    const keyword = getAlgoliaKeyword(magicKeyword);
    const replacementKeywordQueries = replacementKeywords.map(replacementKeyword => {
      return `${base64EncodeURLValue(replacementKeyword, true)}~${keyword}`;
    });
    magicKeywordQuery = magicKeywordQuery.concat([...replacementKeywordQueries]);
  });
  return magicKeywordQuery;
};

export const getMagicQueryKeyword = (magicKeywordQuery: string): string => {
  const [, keyword] = magicKeywordQuery.split('~');
  return keyword;
};

export const getMagicQueryReplacementKeyword = (magicKeywordQuery: string): string => {
  const [replacementKeyword] = magicKeywordQuery.split('~');
  return base64DecodeURLValue(replacementKeyword, true);
};

export const getVisibleResultsKeywords = (
  keywordsQuery: Array<string>,
  magicKeywordsQuery: Array<string>
): Array<string> => {
  let visibleResultsKeywords = [];
  const visibleMagicKeywordValues = magicKeywordsQuery.map(magicQuery => {
    return getMagicQueryKeyword(magicQuery);
  });
  visibleResultsKeywords = [].concat(keywordsQuery).concat(visibleMagicKeywordValues);
  const uniqueVisibleKeywordsSet = new Set(visibleResultsKeywords);
  const uniqueVisibleKeywords = [...uniqueVisibleKeywordsSet];
  return uniqueVisibleKeywords;
};

export const getAlgoliaQueryKeywords = (
  keywordsQuery: Array<string>,
  magicKeywordsQuery: Array<string>,
  youtubeKeywordsQuery: Array<string>
): Array<string> => {
  let algoliaQueryKeywords = [];

  if (keywordsQuery.length > 0) {
    algoliaQueryKeywords = algoliaQueryKeywords.concat(keywordsQuery);
  }

  if (magicKeywordsQuery.length > 0) {
    const magicQueryKeywords = magicKeywordsQuery
      .map(magicQuery => {
        return getMagicQueryReplacementKeyword(magicQuery);
      })
      .filter(magicQuery => {
        const oppositeSyntaxKeyword = getOppositeSyntax(magicQuery);
        return !algoliaQueryKeywords.includes(oppositeSyntaxKeyword);
      });
    algoliaQueryKeywords = algoliaQueryKeywords.concat(magicQueryKeywords);
  }

  if (youtubeKeywordsQuery.length > 0) {
    const decodedYouTubeKeywords = youtubeKeywordsQuery.map(keyword => {
      return base64DecodeURLValue(keyword, true);
    });
    const ytQueryKeywords = decodedYouTubeKeywords.filter(magicQuery => {
      const oppositeSyntaxKeyword = getOppositeSyntax(magicQuery);
      return !algoliaQueryKeywords.includes(oppositeSyntaxKeyword);
    });
    algoliaQueryKeywords = algoliaQueryKeywords.concat(ytQueryKeywords);
  }
  const uniqueAlgoliaQueryKeywordsSet = new Set(algoliaQueryKeywords);
  const uniqueAlgoliaQueryKeywords = [...uniqueAlgoliaQueryKeywordsSet];
  return uniqueAlgoliaQueryKeywords;
};

export const convertMagicQueryToAlgoliaKeywords = (
  magicKeywordQueryValues: Array<string>
): Array<AlgoliaKeyword> => {
  const magicKeywords = [];

  magicKeywordQueryValues.forEach(queryValue => {
    const extractedMagicKeyword = getMagicQueryKeyword(queryValue);
    if (!magicKeywords.includes(extractedMagicKeyword)) {
      magicKeywords.push(extractedMagicKeyword);
    }
  });

  const mappedAlgoliaKeywords = magicKeywords.map(magicKeyword => {
    return {
      keyword: magicKeyword,
      keywordType: 'queryReplacement',
      queryReplacement: magicKeywordQueryValues
        .filter(queryValue => {
          const extractedMagicKeyword = getMagicQueryKeyword(queryValue);
          return extractedMagicKeyword === magicKeyword;
        })
        .map(filtredQueryValue => {
          return getMagicQueryReplacementKeyword(filtredQueryValue);
        }),
    };
  });

  return mappedAlgoliaKeywords;
};
