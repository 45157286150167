// @flow
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import Modal, { ModalContainer, slimModalCss, wideModalOverlayCss } from '../Modal/Modal';
import KeyboardLeftArrow from '../../../assets/inline-assets/keyboard-left-arrow.svg';
import KeyboardRightArrow from '../../../assets/inline-assets/keyboard-right-arrow.svg';
import { contentHeadingClass } from '../ClassicModalLayout/ClassicModalLayout';
import { LargeHeading } from '../../../styles/typography/components/Heading/Heading';
import {
  getKeyboardShortcutsModalTitle,
  getKeyboardShortcutsList,
  getKeyboardShortcutTitle,
  getKeyboardShortcutKeys,
  getKeyboardShortcutIcon,
} from '../../../cms/keyboardShortucts';
import { useKeyboardShortcutsCopy } from '../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT } from '../../../styles/responsive';
import { lightColor, darkColor } from '../../../styles/config/colors';

type Props = {
  onClose: () => void,
};

const Body = styled('div')`
  padding: 50px 0px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 20px 0px;
  }
`;

const ShortcutsList = styled('div')`
  max-height: 350px;
  overflow-y: scroll;
  margin-top: 40px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: grey;
  }
`;

const KeyboardShortcut = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px 50px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 15px 20px;
  }

  &:hover {
    background-color: rgba(240, 240, 235, 0.05);

    & span {
      background-color: ${lightColor};
      color: ${darkColor};

      & svg path {
        stroke: ${darkColor};
        fill: ${darkColor};
      }
    }
  }
`;

const KeyboardShortcutTitle = styled('div')`
  width: 70%;
  color: ${darkColor};
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 25px;
`;

const KeyboardShortcutKeysWrapper = styled('div')`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;

const KeyboardShortcutKey = styled('span')`
  border-radius: 3px;
  background-color: rgba(15, 15, 20, 0.1);
  color: ${darkColor};
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 5px 10px;
  margin-right: 5px;
`;

const KeyboardShortcutIcon = styled('span')`
  border-radius: 3px;
  background-color: rgba(15, 15, 20, 0.1);
  color: ${darkColor};
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 5px 10px;
  margin-right: 5px;

  svg path {
    fill: ${darkColor};
  }
`;

const ModalTitle = styled(LargeHeading)`
  padding-right: 50px;
`;

const contentFullWidthHeadingClass = css`
  ${contentHeadingClass};
  padding-left: 50px;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding-left: 20px;
  }
`;

export const noBackgroundWideOverlayCss = css`
  ${wideModalOverlayCss};
  background-color: unset;
`;

const KeyboardShortcutsModal = ({ onClose }: Props) => {
  const keyboardShortuctsCopy = useKeyboardShortcutsCopy();
  const shortcutList = getKeyboardShortcutsList(keyboardShortuctsCopy);
  return (
    <Modal className={slimModalCss} overlayClassName={noBackgroundWideOverlayCss} onClose={onClose}>
      <ModalContainer>
        <Body>
          <ModalTitle className={contentFullWidthHeadingClass} responsive>
            {getKeyboardShortcutsModalTitle(keyboardShortuctsCopy)}
          </ModalTitle>
          <ShortcutsList>
            {shortcutList.map((shortcut, key) => {
              const shortcutHasIcon = !!getKeyboardShortcutIcon(shortcut);
              return (
                <KeyboardShortcut key={key}>
                  <KeyboardShortcutTitle>
                    {getKeyboardShortcutTitle(shortcut)}
                  </KeyboardShortcutTitle>
                  <KeyboardShortcutKeysWrapper>
                    {shortcutHasIcon ? (
                      <KeyboardShortcutIcon>
                        {getKeyboardShortcutIcon(shortcut) === 'keyboardLeftArrow' ? (
                          <KeyboardLeftArrow />
                        ) : (
                          <KeyboardRightArrow />
                        )}
                      </KeyboardShortcutIcon>
                    ) : (
                      getKeyboardShortcutKeys(shortcut).map(shortcutKey => {
                        return <KeyboardShortcutKey>{shortcutKey}</KeyboardShortcutKey>;
                      })
                    )}
                  </KeyboardShortcutKeysWrapper>
                </KeyboardShortcut>
              );
            })}
          </ShortcutsList>
        </Body>
      </ModalContainer>
    </Modal>
  );
};

export default KeyboardShortcutsModal;
