// @flow
import React from 'react';
import PageIntroSection from '../../../../../../layouts/components/PageIntroSection/PageIntroSection';
import { useTranslation } from 'react-i18next';

const PricingHeader = () => {
  const { t } = useTranslation();

  return (
    <PageIntroSection
      heading={t('pricing.header.content1', '')}
      headerContent={t('pricing.header.content2', '')}
    />
  );
};

export default PricingHeader;
