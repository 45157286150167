// @flow
import React from 'react';
import styled from 'react-emotion';
import parse from 'html-react-parser';
import { monoFontCss, smallPlainTextCss } from '../../../../../../../styles/typography/typography';
import {
  hideOnSmallDeviceCss,
  ShowOnSmallDevice,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../styles/responsive';
import { styledListCss } from '../../../../../../../styles/shared';

const Card = styled('div')`
  background: rgba(240, 240, 235, 0.05);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 24px 30px 25px 30px;
  display: flex;

  ${SMALL_DEVICE_BREAKPOINT} {
    flex-wrap: wrap;
  }
`;

const Info = styled('div')`
  width: 200px;
`;

const Title = styled('h4')`
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.85px;
  line-height: 25px;
  margin-bottom: 17px;
`;

const Description = styled('div')`
  ${smallPlainTextCss};

  ul {
    ${styledListCss};
    padding-left: 10px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    li {
      padding-left: 26px;
    }
  }
`;

const Side = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const IconContainer = styled('div')`
  margin-top: 5px;
  svg {
    display: block;
  }
`;

const ContactMessage = styled('div')`
  ${monoFontCss};
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: right;
  margin-top: 26px;
`;

const SmallDeviceMessageWrapper = styled(ShowOnSmallDevice)`
  width: 100%;
`;

type Props = {
  title: string,
  description: string,
  contact: string,
  icon: any,
};

const FeatureCard = ({ title, description, contact, icon }: Props) => (
  <Card>
    <Info>
      <Title>{title}</Title>
      <Description>{parse(description)}</Description>
    </Info>
    <Side>
      <IconContainer>{icon}</IconContainer>
      <ContactMessage className={hideOnSmallDeviceCss}>{parse(contact)}</ContactMessage>
    </Side>
    <SmallDeviceMessageWrapper>
      <ContactMessage>{parse(contact)}</ContactMessage>
    </SmallDeviceMessageWrapper>
  </Card>
);

export default FeatureCard;
