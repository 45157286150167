import { useMemo } from 'react';

import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';
import seedrandom from 'seedrandom';
import { getHashCode } from './numbers';

//

const palettes = {
  green: [
    '#34A299',
    '#A6C6B3',
    '#356D71',
    '#43CC9E',
    '#DCE7B4',
    '#7BBB98',
    '#7FC7A6',
    '#94AF6A',
    '#385A49',
    '#87D292',
  ],
  yellow: [
    '#EDBB5C',
    '#E69A46',
    '#F9C644',
    '#BE9137',
    '#FBE061',
    '#FCE8A4',
    '#E57049',
    '#D62D15',
    '#F55D2A',
    '#F8AD7F',
  ],
  red: [
    '#D7396E',
    '#B93B6A',
    '#F67F8E',
    '#612023',
    '#A41A30',
    '#C94A52',
    '#CD7869',
    '#98343D',
    '#873F4A',
    '#F55B9F',
  ],
  purple: [
    '#483C5B',
    '#7A607B',
    '#BC99A4',
    '#7D77A4',
    '#E5A1A4',
    '#784FB7',
    '#76429B',
    '#9581D1',
    '#C86499',
    '#B557D7',
  ],
  blue: [
    '#379ED9',
    '#465E87',
    '#6376B5',
    '#2260BC',
    '#263B6E',
    '#DBE9F3',
    '#79B7DE',
    '#5680AA',
    '#3D54A3',
    '#AABCC9',
  ],
};

// Get three different colours

const getColoursfromPalette = (seedNumber, palette) => {
  // start with no previous color and no tracks
  let previousColor = '';
  const trackColors = [];
  let currentIterator = 0;
  // While there are not 3 colors on the list

  while (trackColors.length < 3) {
    // Random new color
    currentIterator += 1;

    const uniqueColor = seedrandom(seedNumber + currentIterator * seedNumber);
    const randomColor = Math.floor(uniqueColor() * palette.length);
    const currentColor = palette[randomColor];

    // Check if the new color is the same as the previous one on the list
    if (currentColor !== previousColor) {
      trackColors.push(currentColor);
      previousColor = currentColor;
    }
  }

  return trackColors;
};

const createGradient = (selectedColors, steps) => {
  // colors are converted to RGB for tiny gradient
  const firstColor = tinycolor(`${selectedColors[0]}`).toRgb();
  const secondColor = tinycolor(`${selectedColors[1]}`).toRgb();
  const thirdColor = tinycolor(`${selectedColors[2]}`).toRgb();

  const colors = [firstColor, secondColor, thirdColor];

  const numberOfStops = steps > 3 ? 3 : 2;
  const numberOfSteps = steps >= numberOfStops ? steps : numberOfStops;

  const stops = Array.from({ length: numberOfStops }).map((stop, index) => ({
    color: colors[index],
    pos: index / (numberOfStops - 1),
  }));

  const gradient = tinygradient(stops);

  // maps out the steps of the gradient and creates the array of colors needed
  const steppedGradient = tinygradient(gradient.rgb(numberOfSteps));

  return steppedGradient;

  // This creates the CSS string for the component, if the array is preferred then this line can be ommitted.
  // const stringGradient = steppedGradient.css();

  // return stringGradient;
};

// Get random palette using a random key

const generateGradient = (seedNumber, steps) => {
  // default if no seedNumber or steps are passed in

  if (!seedNumber || !steps) {
    const defaultColors = ['#34A299', '#43CC9E', '#94AF6A'];

    return createGradient(defaultColors, steps || 2);
  }

  const uniquePalette = seedrandom(seedNumber);
  const paletteList = Object.keys(palettes);
  const palettePosition = Math.floor(uniquePalette() * paletteList.length);
  const chosenPalette = paletteList[palettePosition];
  const paletteColors = palettes[chosenPalette];

  // Call to get 3 colors from palette

  const trackColors = getColoursfromPalette(seedNumber, paletteColors);

  // Call to create the gradient which takes the 3 colors and the amount of steps as arguments

  const gradient = createGradient(trackColors, steps);

  return gradient;
};

export default generateGradient;

export const useGradient = (id: string, steps: number): Array<string> => {
  const gradient = useMemo(() => {
    try {
      const gradientResult = generateGradient(getHashCode(id), steps);
      return gradientResult.stops.map(({ color }) => {
        return `#${color.toHex()}`;
      });
    } catch (error) {
      // $FlowFixMe: removes type checking for Sentry as provisional solution
      Sentry.captureMessage('Something went wrong when generating gradient');
      Sentry.captureException(error);
      return palettes.green;
    }
  }, [id, steps]);
  return gradient;
};
