// @flow
import * as React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import { lightColor } from '../../../../../../../../../styles/config/colors';
import CloseIcon from '../../../../../../../../../assets/inline-assets/close.svg';
import MinusIcon from '../../../../../../../../../assets/inline-assets/minus-icon.svg';
import PlusIcon from '../../../../../../../../../assets/inline-assets/plus-icon.svg';
import {
  SMALL_DEVICE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import { isMobileDeviceSize } from '../../../../../../../../../utils/device';
import { FILTER_MENU_TYPES, FILTER_TYPES } from '../ResultsFilters/data';
import {
  getAppliedFiltersExclusiveList,
  getAppliedFiltersInclusiveList,
} from '../../../../../../../../../api/algolia/data';
import { formatSeconds } from '../../../../../../../../../utils/time';

const FilterWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  .ResultsKeywords-button {
    border: 2px solid #25272c;
    margin-top: 2px;
    ${SMALL_DESKTOP_BREAKPOINT} {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

export const resultsKeywordsHeadingClass = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    justify-content: unset;
  }
`;
const Button = styled('div')`
  display: flex;
  font-size: 16px;
  margin: 0px 0px 7px 5px;
  text-align: center;
  color: white;
  border-radius: 2px;
  outline: none;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  background: rgba(240, 240, 235, 0.05);
  text-transform: capitalize;

  &:hover {
    background-color: ${transparentize(0.9, lightColor)};

    & .closeIcon {
      display: inline;
      top: -1px;
      transform: scale(0.5, 0.5) translateY(9px) translateX(-8px);
    }

    & .plusMinusIcon {
      display: none;
    }
  }
`;

const Icon = styled('div')`
  width: 10px;
  margin-right: 4px;

  & svg {
    position: relative;
  }

  &.plusIcon svg {
    top: -1px;
  }

  &.minusIcon svg {
    top: -4px;
  }

  & .closeIcon {
    display: none;
  }
`;

const IconCloseMobile = styled('div')`
  transform: scale(0.6, 0.6) translateY(10px) translateX(-5px);
  padding-left: 12px;
  width: 7px;
  height: 7px;
`;

type Props = {};

type InteractiveFilterProps = {
  facetKey: string,
  filterValue: string,
  filterMax: number,
  filterMin: number,
  isInclusive: boolean,
  filterType: string,
};

const getAppliedFiltersList = appliedFilters => {
  const appliedFiltersList = [];

  Object.keys(appliedFilters).forEach(appliedFilter => {
    if (
      FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterSelect ||
      FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterBoolean
    ) {
      const appliedExclusiveFilters = getAppliedFiltersExclusiveList(appliedFilters, appliedFilter);
      appliedExclusiveFilters.forEach(exclusiveFilter => {
        appliedFiltersList.push({
          facetKey: appliedFilter,
          filterValue: exclusiveFilter,
          filterMax: 0,
          filterMin: 0,
          isInclusive: false,
          filterType: FILTER_TYPES.filterSelect,
        });
      });

      const appliedInclusiveFilters = getAppliedFiltersInclusiveList(appliedFilters, appliedFilter);
      appliedInclusiveFilters.forEach(inclusiveFilter => {
        appliedFiltersList.push({
          facetKey: appliedFilter,
          filterValue: inclusiveFilter,
          filterMax: 0,
          filterMin: 0,
          isInclusive: true,
          filterType: FILTER_TYPES.filterSelect,
        });
      });
    }

    if (FILTER_MENU_TYPES[appliedFilter] === FILTER_TYPES.filterRange) {
      if (appliedFilters[appliedFilter].max && appliedFilters[appliedFilter].min) {
        appliedFiltersList.push({
          facetKey: appliedFilter,
          filterValue: `${appliedFilters[appliedFilter].max},${appliedFilters[appliedFilter].min}`,
          filterMax: appliedFilters[appliedFilter].max,
          filterMin: appliedFilters[appliedFilter].min,
          isInclusive: true,
          filterType: FILTER_TYPES.filterRange,
        });
      }
    }
  });

  return appliedFiltersList;
};

const formatRangeFilter = (filterKey: string, minValue: number, maxValue: number) => {
  if (filterKey === 'audio.duration') {
    return `${formatSeconds(minValue / 1000)}-${formatSeconds(maxValue / 1000)}`;
  }
  return `${minValue}-${maxValue} BPM`;
};

export const InteractiveFilter = ({
  facetKey,
  filterValue,
  filterMax,
  filterMin,
  isInclusive,
  filterType,
}: InteractiveFilterProps) => {
  const { resetSongsFilter, handleRemoveSongsFilter } = useResultsManagerContext();
  const handleFilterRemove = () => {
    if (filterType === FILTER_TYPES.filterSelect) {
      const addType = isInclusive ? 'included' : 'excluded';
      handleRemoveSongsFilter(facetKey, filterValue, addType);
    } else {
      resetSongsFilter(facetKey);
    }
  };

  return (
    <Button onClick={handleFilterRemove}>
      <Icon className={cx({ plusIcon: isInclusive, minusIcon: !isInclusive })}>
        <CloseIcon className="closeIcon" />
        {isInclusive ? (
          <PlusIcon className="plusMinusIcon" />
        ) : (
          <MinusIcon className="plusMinusIcon" />
        )}
      </Icon>
      {filterType === FILTER_TYPES.filterRange
        ? formatRangeFilter(facetKey, filterMin, filterMax)
        : filterValue}
      {isMobileDeviceSize() && (
        <IconCloseMobile>
          <CloseIcon />
        </IconCloseMobile>
      )}
    </Button>
  );
};
const AppliedFiltersList = () => {
  const { appliedFilters } = useResultsManagerContext();
  const appliedFiltersList = getAppliedFiltersList(appliedFilters);

  return (
    <>
      <FilterWrapper>
        {appliedFiltersList.map(appliedFilter => (
          <InteractiveFilter
            key={`${appliedFilter.facetKey}:${appliedFilter.filterValue}`}
            facetKey={appliedFilter.facetKey}
            filterValue={appliedFilter.filterValue}
            filterMax={appliedFilter.filterMax}
            filterMin={appliedFilter.filterMin}
            isInclusive={appliedFilter.isInclusive}
            filterType={appliedFilter.filterType}
          />
        ))}
      </FilterWrapper>
    </>
  );
};

export default AppliedFiltersList;
