// @flow
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { evokeBorderColor } from '../../../../../../../styles/config/colors';
import { monoNavCss, smallPlainTextCss } from '../../../../../../../styles/typography/typography';
import { useBillingPageCopy } from '../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getBillingHistoryHeading,
  getBillingPageActionLabel,
  getBillingPageDateLabel,
  getBillingPageDetailsLabel,
} from '../../../../../../../cms/billingPage';
import { MediumHeading } from '../../../../../../../styles/typography/components/Heading/Heading';
import { useFetchInvoices, useCustomOrderReceipts } from '../../../../../../../api/firebase/api';
import type { InvoiceMdl, InvoicesResponse } from '../../../../../../../api/firebase/user/payment';
import LoadingDisplay from '../../../../../../../components/LoadingDisplay/LoadingDisplay';
import { LoadingContainer } from '../PaymentMethod/PaymentMethod';
import Invoice from './components/Invoice/Invoice';

const Section = styled('section')`
  margin-top: 73px;
`;

const Table = styled('table')`
  margin-top: 31px;
  border-collapse: collapse;

  tr {
    border-bottom: 2px solid ${transparentize(0.3, evokeBorderColor)};
  }

  td {
    ${smallPlainTextCss};
    padding: 6px 0;

    &:last-child {
      text-decoration: underline;
    }
  }

  th {
    ${monoNavCss};
    text-transform: uppercase;
    text-align: left;
    padding-bottom: 5px;
  }

  th:first-child,
  td:first-child {
    min-width: 120px;
    white-space: nowrap;
    padding-right: 10px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 99%;
  }

  th:last-child,
  td:last-child {
    text-align: right;
    white-space: nowrap;
  }
`;

type Props = {
  onLoad: () => void,
};

const BillingHistory = ({ onLoad, ...props }: Props) => {
  const [fetchInvoices, fetchingInvoices] = useFetchInvoices();
  const [fetchCustomOrderReceipts] = useCustomOrderReceipts();
  const [invoices, setInvoices]: [InvoicesResponse, () => {}] = useState([]);
  const copy = useBillingPageCopy();
  useEffect(() => {
    fetchInvoices()
      .then(response => {
        return response;
      })
      .then(chargebeeInvoices => {
        fetchCustomOrderReceipts().then(customTrackInvoices => {
          const subscriptionInvoices = chargebeeInvoices.map(chargebeeInvoice => {
            chargebeeInvoice.type = 'subscription';
            return chargebeeInvoice;
          });
          const trackInvoices = customTrackInvoices.map(customTrackInvoice => {
            customTrackInvoice.type = 'custom_order';
            return customTrackInvoice;
          });
          const combinedInvoices = []
            .concat(trackInvoices)
            .concat(subscriptionInvoices)
            .sort((a, b) => {
              return b.paidDate - a.paidDate;
            });
          setInvoices(combinedInvoices);
          onLoad();
        });
      });
  }, []);
  return (
    <Section {...props}>
      <header>
        <MediumHeading>{getBillingHistoryHeading(copy)}</MediumHeading>
      </header>
      {fetchingInvoices ? (
        <LoadingContainer>
          <LoadingDisplay />
        </LoadingContainer>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{getBillingPageDateLabel(copy)}</th>
              <th>{getBillingPageActionLabel(copy)}</th>
              <th>{getBillingPageDetailsLabel(copy)}</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice: InvoiceMdl) => {
              return <Invoice invoice={invoice} key={invoice.id} />;
            })}
          </tbody>
        </Table>
      )}
    </Section>
  );
};

export default BillingHistory;
