// @flow
import { get } from 'lodash';
import { firestore } from '../firestore';

export type AllowlistChannelMdl = {
  associatedActiveChannels: Array<string>,
  associatedActiveSubscriptions: Array<string>,
  desiredState?: string,
  currentState?: string,
  isPendingAdrevRequest?: boolean,
  isPendingAdrevResponse?: boolean,
  tickets?: Array<string>,
  updatedAt?: Date,
  youtubeId: string,
  youtubeName: string,
};

export type AllowlistDataMdl = {
  mainChannelAllowlist: AllowlistChannelMdl,
  extraChannelsAllowlist: {
    [string]: AllowlistChannelMdl,
  },
};

export type AllowlistDisplayData = {
  youtubeId?: string,
  youtubeName?: string,
  desiredState?: string,
  currentState?: string,
  isPendingAdrevRequest?: boolean,
  isPendingAdrevResponse?: boolean,
  updatedAt?: string,
};

export const ALLOWLIST_STATUSES = {
  allowlisted: 'whitelisted',
  denied: 'denied',
  pending: 'pending',
  removed: 'removed',
};

const allowlistStatesRef = firestore.collection('whitelistStates');

export const getAllowListCurrentSate = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  return get(allowlistData, 'currentState', 'pending');
};

// 2022/03/29 - Amadeus have recently made the decision to blanket allow usage of all of their 
// content on youtube without claiming. This means it doesn't matter if channels are allowlisted
// or not, no claims will be made.
// They may change their minds in the future, so we will continue to maintain the status of new 
// channels on the backend (sitting in a pending status permanently). 
// In order to ensure that users are not impacted, we will tell them on the front-end that any 
// channels in either a pending status or allowlisted status is "allowlisted". Channels that have
// been blocked or removed will continue to be flagged as such.

export const getAllowListCurrentStateForceSuccessfulIfPending = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  const currentState = get(allowlistData, 'currentState', 'pending');
  if (currentState == ALLOWLIST_STATUSES.pending) {
    return ALLOWLIST_STATUSES.allowlisted;
  } else {
    return currentState;
  }
};

export const getAllowListDesiredSate = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  return get(allowlistData, 'desiredState', 'pending');
};

export const getAllowListAdRevRequestStatus = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): boolean => {
  return get(allowlistData, 'isPendingAdrevRequest', false);
};

export const getAllowListAdRevResponseStatus = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): boolean => {
  return get(allowlistData, 'isPendingAdrevResponse', false);
};

export const getAllowListUpdateDate = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  return get(allowlistData, 'updatedAt', '');
};

export const getAllowistYouTubeId = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  return get(allowlistData, 'youtubeId', '');
};

export const getAllowlistYouTubeName = (
  allowlistData: AllowlistDisplayData | AllowlistChannelMdl
): string => {
  return get(allowlistData, 'youtubeName', '');
};

export const getAllowlistDisplayData = (
  allowlistChannelData: AllowlistChannelMdl
): AllowlistDisplayData => {
  return {
    currentState: getAllowListCurrentSate(allowlistChannelData),
    desiredState: getAllowListDesiredSate(allowlistChannelData),
    isPendingAdrevRequest: getAllowListAdRevRequestStatus(allowlistChannelData),
    isPendingAdrevResponse: getAllowListAdRevResponseStatus(allowlistChannelData),
    updatedAt: getAllowListUpdateDate(allowlistChannelData),
    youtubeId: getAllowistYouTubeId(allowlistChannelData),
    youtubeName: getAllowlistYouTubeName(allowlistChannelData),
  };
};

export const fetchFirestoreAllowlist = async (
  mainYouTubeId: string,
  extraYouTubeIDs: Array<string>
): Promise<AllowlistDataMdl> => {
  const allowListData = { mainChannelAllowlist: {}, extraChannelsAllowlist: {} };
  const allYouTubeIds = extraYouTubeIDs.concat(mainYouTubeId);

  const allowlistPromises = allYouTubeIds.map(youtubeId => {
    return allowlistStatesRef
      .where('youtubeId', '==', youtubeId)
      .get()
      .then(querySnapshot => {
        let data = {};
        querySnapshot.forEach(doc => {
          data = doc.data();
        });

        return data;
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when fetching firestore white list');
        Sentry.captureException(error);
        console.error('firestoreallowlist', error);
        return Promise.reject(error);
      });
  });

  return Promise.all(allowlistPromises).then(response => {
    response.forEach(channelAllowlistData => {
      if (channelAllowlistData.youtubeId === mainYouTubeId) {
        allowListData.mainChannelAllowlist = getAllowlistDisplayData(channelAllowlistData);
      } else {
        allowListData.extraChannelsAllowlist = {
          ...allowListData.extraChannelsAllowlist,
          [channelAllowlistData.youtubeId]: getAllowlistDisplayData(channelAllowlistData),
        };
      }
    });
    return allowListData;
  });
};
