import React from 'react';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { transparentize } from 'polished';
import { darkColor, greyColor, lightColor } from '../../../../styles/config/colors';
import { bodyFontCss, buttonTextCss } from '../../../../styles/typography/typography';
import PlusIcon from '../../../../assets/inline-assets/plus-icon.svg';
import CloseIcon from '../../../../assets/inline-assets/close.svg';
import { capitalize } from '../../../../utils/text';
import { MOBILE_DEVICE_BREAKPOINT, SMALL_DEVICE_BREAKPOINT } from '../../../../styles/responsive';

const activeButtonStyle = css`
  background-color: ${darkColor};
  cursor: pointer;

  ${SMALL_DEVICE_BREAKPOINT} {
    background-color: ${darkColor};
  }

  p,
  span {
    color: ${lightColor};
  }

  svg {
    path {
      fill: ${lightColor};
      stroke: ${lightColor};
    }
  }
`;

const disabledButtonStyle = css`
  background-color: #dad8d3;
  cursor: default;
  border: 1px solid #dad8d3;

  ${SMALL_DEVICE_BREAKPOINT} {
    border: 1px solid ${transparentize(0.5, darkColor)};
  }

  p,
  span {
    opacity: 0.5;
    color: ${darkColor};
    line-height: 20px;
  }

  svg {
    opacity: 0.5;
    path {
      fill: ${darkColor};
      stroke: ${darkColor};
    }
  }

  &:hover {
    background-color: #dad8d3;
    cursor: default;

    p,
    span {
      color: ${darkColor};
    }

    svg {
      path {
        fill: ${darkColor};
        stroke: ${darkColor};
      }
    }
  }
`;

const plusIconClass = css`
  margin-right: 5px;
`;

const closeIconClass = css`
  margin-left: 5px;

  & svg {
    transform: scale(0.5);
    position: relative;
    top: 3px;

    & path {
      stroke-width: 3;
    }
  }
`;

const StyledFilterButton = styled('button')`
  border: 1px solid ${transparentize(0.9, darkColor)};
  border-radius: 2px;
  background-color: ${lightColor};
  color: ${darkColor};
  margin: 0px 8px 8px 0px;
  padding: 2px 8px 3px 8px;
  display: inline-flex;
  align-items: center;

  &:hover {
    @media (hover: hover) {
      ${activeButtonStyle}
    }
  }

  &:focus-visible {
    ${MOBILE_DEVICE_BREAKPOINT} {
      background-color: #dad8d3;

      p,
      span {
        color: ${darkColor};
      }

      svg {
        path {
          fill: ${darkColor};
          stroke: ${darkColor};
        }
      }
    }
  }

  & p {
    font-size: 15px;
  }

  & span {
    ${buttonTextCss}
    ${bodyFontCss}
    letter-spacing: 0;
    color: ${transparentize(0.6, darkColor)};
    margin-left: 5px;
    position: relative;
    top: 1px;
    font-weight: 400;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    background-color: #dad8d3;

    & p {
      font-size: 13px;
    }
  }
`;

type TagProps = {
  label: string,
  count: string,
  isSelected: boolean,
  isDisabled: boolean,
};

const TagFilter = ({ label, count, isSelected, isDisabled }: TagProps) => {
  return (
    <>
      {!isSelected && !isDisabled && (
        <figure className={plusIconClass}>
          <PlusIcon />
        </figure>
      )}
      <p>{`${capitalize(label)}`}</p>
      <span>{count}</span>
      {isSelected && (
        <figure className={closeIconClass}>
          <CloseIcon />
        </figure>
      )}
    </>
  );
};

type Props = {
  label: string,
  count: string,
  facetKey: string,
  isSelected?: boolean,
  isDisabled: boolean,
  handleFilterToggle: (isSelected: string, filterKey: string, filterValue: string) => void,
};

const TagButton = ({
  label,
  count,
  facetKey,
  isSelected = false,
  isDisabled = false,
  handleFilterToggle,
}: Props) => {
  const handleClick = ev => {
    ev.stopPropagation();
    if (isDisabled) return;
    handleFilterToggle(isSelected, facetKey, label);
  };
  return (
    <StyledFilterButton
      onClick={handleClick}
      className={cx({ [activeButtonStyle]: isSelected, [disabledButtonStyle]: isDisabled })}
    >
      <TagFilter label={label} count={count} isSelected={isSelected} isDisabled={isDisabled} />
    </StyledFilterButton>
  );
};

export default TagButton;
