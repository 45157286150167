// @flow
import React, { useEffect } from 'react';
import SearchIcon from '../../../../../../../../../assets/inline-assets/search-icon.svg';
import { getMiscCopyBackToBrowse } from '../../../../../../../../../cms/miscCopy';
import {
  getNoResultsSearchEmptySearchHeader,
  getNoResultsSearchEmptySearchMessage,
} from '../../../../../../../../../cms/noResultsPage';
import { useMusicSearchBarContext } from '../../../../../../../../../components/SiteHeader/components/SiteNav/components/MusicSearchBarWrapper/MusicSearchBarWrapper';
import {
  useMiscCopy,
  useNoResultsPageCopy,
} from '../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useResultsManagerContext } from '../../../ResultsManager/ResultsManager';
import SearchResultsMessageLayout from '../SearchResultsMessageLayout/SearchResultsMessageLayout';

const EmptySearch = () => {
  const copy = useNoResultsPageCopy();
  const miscCopy = useMiscCopy();
  const { handleOpenSearch } = useMusicSearchBarContext();
  const { handleClearSongFilters, appliedFilters } = useResultsManagerContext();

  useEffect(() => {
    handleOpenSearch();

    if (Object.keys(appliedFilters).length > 0) {
      handleClearSongFilters();
    }
  }, []);

  return (
    <SearchResultsMessageLayout
      title={getNoResultsSearchEmptySearchHeader(copy)}
      description={getNoResultsSearchEmptySearchMessage(copy)}
      icon={<SearchIcon />}
      buttonText={getMiscCopyBackToBrowse(miscCopy)}
      navigateBack
    />
  );
};

export default EmptySearch;
