// @flow
import React, { useContext } from 'react';
import type { AlgoliaSongMdl } from '../../../../../api/algolia/song';
import { getSongID, getSongShareUrl } from '../../../../../api/algolia/song';
import { useLocalePath } from '../../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { useGlobalAudioPlaybackContext } from '../GlobalAudioPlayback/GlobalAudioPlayback';

export type ShareContextState = {
  songID: string,
  shareURL: string,
  song: AlgoliaSongMdl,
  getAudioProgress: () => number,
};

export const GlobalShareContext = React.createContext({
  songID: '',
  shareURL: '',
  song: null,
  getAudioProgress: () => 0,
});

type Props = {
  children: any,
  song: AlgoliaSongMdl,
};

const GlobalShareContextWrapper = ({ children, song }: Props) => {
  const localePath = useLocalePath();
  const { getAudioProgress } = useGlobalAudioPlaybackContext();
  return (
    <GlobalShareContext.Provider
      value={{
        song,
        songID: getSongID(song),
        shareURL: getSongShareUrl(song, localePath),
        getAudioProgress,
      }}
    >
      {children}
    </GlobalShareContext.Provider>
  );
};

export default GlobalShareContextWrapper;

export const useGlobalShareContext = (): ShareContextState => {
  return useContext(GlobalShareContext);
};

export const useGlobalShareSongID = (): string => {
  return useGlobalShareContext().songID;
};

export const useGlobalShareSongShareURL = (): string => {
  return useGlobalShareContext().shareURL;
};
