// @flow
import {
  ChannelMdl,
  UserSubscriptionMdl,
  ChannelYouTubeMdl,
  getSubscriptionStatus,
} from '../../../../api/firebase/user/subscriptions';
import type { YouTubeAPIChannelMdl } from '../../../../api/firebase/user/channels';
import {
  getChannelId,
  getSubscriptionChannel,
  getSubscriptionChannelId,
  getSubscriptionSeat,
  getSubscriptionPlanKey,
  getPlanIntervalPrice,
  getChannelParentChannel,
  getChannelSubscriptionId,
} from '../../../../api/firebase/user/subscriptions';
import {
  getYouTubeChannelID,
  getYouTubeChannelImage,
  getYouTubeChannelName,
} from '../../../../api/firebase/user/channels';
import { SUBSCRIPTION_STATUSES } from '../../../../api/firebase/user/user';
import type { SubscriptionStatus } from '../../../../api/firebase/user/user';
import { SUBSCRIPTION_HINTS } from '../../data';

export const getUpdatedChannelWithNewYouTubeChannel = (
  channel: ChannelMdl,
  youTubeChannel: YouTubeAPIChannelMdl
): ChannelMdl => {
  return {
    ...channel,
    youtube: {
      icon: getYouTubeChannelImage(youTubeChannel),
      id: getYouTubeChannelID(youTubeChannel),
      link: '',
      name: getYouTubeChannelName(youTubeChannel),
    },
  };
};

export const getUpdatedChannelWithNewPrimaryYouTubeChannel = (
  channel: ChannelMdl,
  youTubeChannel: any
): ChannelMdl => {
  return {
    owner: channel.owner,
    active: channel.active,
    youtube: {
      icon: youTubeChannel.icon,
      id: youTubeChannel.id,
      link: youTubeChannel.link,
      name: youTubeChannel.name,
    },
  };
};

/*

export const getUpdatedChannelWithNewExtraYouTubeChannel = (
  channel: ChannelMdl,
  youTubeChannel: YouTubeAPIChannelMdl
): ChannelMdl => {
  const extraChannels = getChannelExtraYouTubeChannels(channel);
  const newExtraChannel = {
    icon: getYouTubeChannelImage(youTubeChannel),
    id: getYouTubeChannelID(youTubeChannel),
    link: '',
    name: getYouTubeChannelName(youTubeChannel),
  };

  extraChannels.push(newExtraChannel);
  
  return {
    ...channel,
    extraChannels: extraChannels,
  };
}
*/

export const getUpdatedSubscriptionsWithNewYouTubeChannel = (
  subscriptions: Array<UserSubscriptionMdl>,
  subscriptionKey: string,
  youTubeChannel: YouTubeAPIChannelMdl
): Array<UserSubscriptionMdl> => {
  return subscriptions.map(subscription => {
    if (getSubscriptionChannelId(subscription) === subscriptionKey) {
      const channel = getSubscriptionChannel(subscription);
      return {
        ...subscription,
        channel: getUpdatedChannelWithNewYouTubeChannel(channel, youTubeChannel),
      };
    }
    return subscription;
  });
};

export const getUpdatedSubscriptionsWithNewPrimaryYouTubeChannel = (
  subscriptions: Array<UserSubscriptionMdl>,
  subscriptionKey: string,
  youTubeChannel: any
): Array<UserSubscriptionMdl> => {
  return subscriptions.map(subscription => {
    if (getSubscriptionChannelId(subscription) === subscriptionKey) {
      const channel = getSubscriptionChannel(subscription);
      return {
        ...subscription,
        channel: getUpdatedChannelWithNewPrimaryYouTubeChannel(channel, youTubeChannel),
      };
    }
    return subscription;
  });
};

/*

export const getUpdatedSubscriptionsWithNewExtraYouTubeChannel = (
  subscriptions: Array<UserSubscriptionMdl>,
  subscriptionKey: string,
  youTubeChannel: YouTubeAPIChannelMdl
): Array<UserSubscriptionMdl> => {
  return subscriptions.map(subscription => {
    if (getSubscriptionChannelId(subscription) === subscriptionKey) {
      const channel = getSubscriptionChannel(subscription);
      return {
        ...subscription,
        channel: getUpdatedChannelWithNewExtraYouTubeChannel(channel, youTubeChannel),
      };
    }
    return subscription;
  });
};
*/

export const getUpdatedExtraYouTubeChannels = (
  extraYouTubeChannels: Array<any>,
  subscriptionKey: string,
  youTubeChannel: YouTubeAPIChannelMdl
): Array<ChannelYouTubeMdl> => {
  const currentExtraChannels = extraYouTubeChannels.map(extraChannel => {
    const data = {
      youtube: extraChannel.youtube,
      subscriptionHint: SUBSCRIPTION_HINTS.paid,
    };

    const channelId = getChannelId(extraChannel);

    if (channelId) {
      data.id = channelId;
    }
    /*
    if(subscriptionId) {
      data.subscriptionId = subscriptionId;
    }

    if(parentChannelId) {
      data.parentChannelId = parentChannelId;
    }
    */
    return data;
  });

  const newExtraChannel = {
    youtube: {
      icon: getYouTubeChannelImage(youTubeChannel),
      id: getYouTubeChannelID(youTubeChannel),
      link: '',
      name: getYouTubeChannelName(youTubeChannel),
    },
    subscriptionHint: SUBSCRIPTION_HINTS.paid,
  };

  currentExtraChannels.push(newExtraChannel);

  return currentExtraChannels;

  /*
  const updatingSubscription = subscriptions.filter(subscription => {
    return getSubscriptionChannelId(subscription) === subscriptionKey;
  });

  const channel = getSubscriptionChannel(updatingSubscription[0]);
  let extraYouTubeChannels = getChannelExtraYouTubeChannels(channel);
  extraYouTubeChannels = extraYouTubeChannels ? extraYouTubeChannels : [];

  const newExtraChannel = {
    youtube: {
      icon: getYouTubeChannelImage(youTubeChannel),
      id: getYouTubeChannelID(youTubeChannel),
      link: '',
      name: getYouTubeChannelName(youTubeChannel),
    }
  };

  extraYouTubeChannels.push(newExtraChannel);
  return extraYouTubeChannels;
  */
};

export const getUpdatedSubscriptionsWithRemovedYouTubeChannel = (
  subscriptions: Array<UserSubscriptionMdl>,
  subscriptionKey: string
): Array<UserSubscriptionMdl> => {
  return subscriptions.map(subscription => {
    if (getSubscriptionChannelId(subscription) === subscriptionKey) {
      const channel = getSubscriptionChannel(subscription);
      return {
        ...subscription,
        channel: {
          ...channel,
          youtube: null,
        },
      };
    }
    return subscription;
  });
};

export const getUpdatedExtraYouTubeChannelsWithRemovedChannel = (
  extraYouTubeChannels: Array<UserSubscriptionMdl>,
  subscriptionKey: string,
  youtubeSubscriptionId: string
): Array<ChannelYouTubeMdl> => {
  const filteredExtraYouTubeChannels = extraYouTubeChannels.filter(extraChannel => {
    return extraChannel.youtube.id !== youtubeSubscriptionId;
  });

  const formattedExtraChannels = filteredExtraYouTubeChannels.map(extraChannel => {
    const data = {
      youtube: extraChannel.youtube,
      subscriptionHint: SUBSCRIPTION_HINTS.paid,
    };

    const channelId = getChannelId(extraChannel);

    if (channelId) {
      data.id = channelId;
    }

    return data;
  });

  return formattedExtraChannels;

  /*
  const updatingSubscription =  subscriptions.filter( extra => {
    return subscription.key === subscriptionKey;
  });

  const channel = getSubscriptionChannel(updatingSubscription[0]);
  let extraYouTubeChannels = getChannelExtraYouTubeChannels(channel);

  const updatedExtraYouTubeChannelsWithRemovedChannel = extraYouTubeChannels.filter( extraChannel => {
    return extraChannel.id !== youtubeSubscriptionId;
  });

  return updatedExtraYouTubeChannelsWithRemovedChannel;
  */
};

export const getUpdatedExtraYouTubeChannelsWithSwappedChannel = (
  subscriptions: Array<UserSubscriptionMdl>,
  extraYouTubeChannels: Array<UserSubscriptionMdl>,
  subscriptionKey: string,
  extraChannelYouTubeId: string
): Array<ChannelYouTubeMdl> => {
  const subscription = subscriptions.filter(subscription => {
    return getSubscriptionChannelId(subscription) === subscriptionKey;
  });

  const primaryChannel = getSubscriptionChannel(subscription[0]);

  const updatedExtraChannels = extraYouTubeChannels.map(extraYouTubeChannel => {
    if (extraYouTubeChannel.youtube.id === extraChannelYouTubeId) {
      return {
        youtube: {
          icon: primaryChannel.youtube.icon,
          id: primaryChannel.youtube.id,
          link: '',
          name: primaryChannel.youtube.name,
        },
        subscriptionHint: SUBSCRIPTION_HINTS.paid,
        id: extraYouTubeChannel.id,
      };
    }

    return {
      youtube: {
        icon: extraYouTubeChannel.youtube.icon,
        id: extraYouTubeChannel.youtube.id,
        link: '',
        name: extraYouTubeChannel.youtube.name,
      },
      subscriptionHint: SUBSCRIPTION_HINTS.paid,
      id: extraYouTubeChannel.id,
    };
  });

  return updatedExtraChannels;
};

export const mergeUpdatedChannelsWithSubscriptions = (
  subscriptions: Array<UserSubscriptionMdl>,
  channels: Array<ChannelMdl>
): Array<UserSubscriptionMdl> => {
  return subscriptions.map(subscription => {
    const channelId = getSubscriptionChannelId(subscription);
    const channel = channels.find((updatedChannel: ChannelMdl) => {
      return channelId === updatedChannel.id;
    });
    return {
      ...subscription,
      channel: channel || null,
    };
  });
};

export const mergeUpdatedExtraChannelsWithSubscriptions = (
  subscriptions: Array<UserSubscriptionMdl>,
  extraChannels: Array<ChannelMdl>,
  updatingChannelId: string
): Array<UserSubscriptionMdl> => {
  const updatedExtraChannels = extraChannels.filter(extraChannel => {
    const parentChannelId = getChannelParentChannel(extraChannel);
    if (parentChannelId && parentChannelId === updatingChannelId) {
      return extraChannel;
    }
  });

  return subscriptions.map(subscription => {
    const channelId = getSubscriptionChannelId(subscription);
    if (channelId === updatingChannelId) {
      return {
        ...subscription,
        extraChannels: updatedExtraChannels,
      };
    }

    return subscription;
  });
};

export const generateNewSubscription = (
  planKey: string,
  channel: ChannelMdl
): UserSubscriptionMdl => {
  const channelId = getChannelId(channel);
  return {
    key: channelId,
    seat: {
      channelId,
      status: SUBSCRIPTION_STATUSES.active,
      plan: planKey,
      monthlyTotalCents: 0,
      validUntilSeconds: 0,
      nextBillingDateSeconds: 0,
    },
    channel,
  };
};

export const updateSubscriptionPlan = (
  subscription: UserSubscriptionMdl,
  planKey: string
): UserSubscriptionMdl => {
  const seat = getSubscriptionSeat(subscription);
  return {
    ...subscription,
    seat: {
      ...seat,
      plan: planKey,
    },
  };
};

export const updateSubscriptionStatus = (
  subscription: UserSubscriptionMdl,
  status: SubscriptionStatus
): UserSubscriptionMdl => {
  const seat = getSubscriptionSeat(subscription);
  return {
    ...subscription,
    seat: {
      ...seat,
      status,
    },
  };
};

export const updateSubscriptionPlanInterval = (
  subscription: UserSubscriptionMdl,
  interval: string,
  locale: string
): UserSubscriptionMdl => {
  const seat = getSubscriptionSeat(subscription);
  const planKey = getSubscriptionPlanKey(subscription);
  const monthlyTotalCents = getPlanIntervalPrice(planKey, interval, locale);
  return {
    ...subscription,
    seat: {
      ...seat,
      interval,
      monthlyTotalCents,
    },
  };
};

export const mergeSubscriptionsWithRemovedScheduledChange = (
  subscription: UserSubscriptionMdl,
  subscriptions: Array<UserSubscriptionMdl>
) => {
  const subscriptionKey = subscription.key;

  const updatedSubscriptions = subscriptions.map(sub => {
    const currentSubscriptionKey = sub.key;

    if (subscriptionKey === currentSubscriptionKey) {
      const newSubscriptionStatus =
        getSubscriptionStatus(subscription) === SUBSCRIPTION_STATUSES.trial_cancelled
          ? SUBSCRIPTION_STATUSES.trial
          : SUBSCRIPTION_STATUSES.active;
      return updateSubscriptionStatus(sub, newSubscriptionStatus);
    }

    return sub;
  });

  return updatedSubscriptions;
};
