// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SignInPageMdl = {
  data: {
    sign_up_prompt: {
      text: string,
    },
    sign_up_button: {
      text: string,
    },
    sign_in_header: {
      text: string,
    },
    sign_in_button: {
      text: string,
    },
    // forgot_password_prompt: {
    //   text: string,
    // },
    continue_with_facebook_button: {
      text: string,
    },
    continue_with_google_button: {
      text: string,
    },
    forgot_password_button: {
      text: string,
    },
    // forgot_password_description: {
    //   html: string,
    // },
    // forgot_password_submit_button: {
    //   text: string,
    // },
  },
};

export const getSignInForgotPasswordHeading = (page: SignInPageMdl): string => {
  return get(page, 'data.forgot_password_heading.text', '') || '';
};

export const getSignInForgotPasswordDescription = (page: SignInPageMdl): string => {
  return get(page, 'data.forgot_password_description.html', '') || '';
};

export const getSignInForgotPasswordSubmitButton = (page: SignInPageMdl): string => {
  return get(page, 'data.forgot_password_submit_button.text', '') || '';
};

export const getSignInPageSignUpPrompt = (page: SignInPageMdl): string => {
  return get(page, 'data.sign_up_prompt.text', '') || '';
};

export const getSignInPageSignUpButton = (page: SignInPageMdl): string => {
  return get(page, 'data.sign_up_button.text', '') || '';
};

export const getSignInPagePageHeading = (page: SignInPageMdl): string => {
  return get(page, 'data.sign_in_header.text', '') || '';
};

export const getSignInPageSignInButton = (page: SignInPageMdl): string => {
  return get(page, 'data.sign_in_button.text', '') || '';
};

export const getSignInPageForgotPasswordPrompt = (page: SignInPageMdl): string => {
  return get(page, 'data.forgot_password_button.text', '') || '';
};

export const getSignInPageContinueWithFacebookButton = (page: SignInPageMdl): string => {
  return get(page, 'data.continue_with_facebook_button.text', '') || '';
};

export const getSignInPageContinueWithGoogleButton = (page: SignInPageMdl): string => {
  return get(page, 'data.continue_with_google_button.text', '') || '';
};

export const query = graphql`
  fragment SignInPage on PrismicSignInPage {
    data {
      sign_up_prompt {
        text
      }
      sign_up_button {
        text
      }
      sign_in_header {
        text
      }
      sign_in_button {
        text
      }
      forgot_password_button {
        text
      }
      continue_with_facebook_button {
        text
      }
      continue_with_google_button {
        text
      }
    }
  }
`;
