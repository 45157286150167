// @flow
import React from 'react';
import styled from 'react-emotion';
import {
  usePricingPlans,
  useSignUpPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPlans } from '../../../../cms/signup';
import {
  getPricingPlan,
  getPricingPlanBriefDescription,
  getPricingPlanName,
  getPricingPlanSubtitle,
} from '../../../../cms/pricingPlan';
import PlanPreview from '../PlanPreview/PlanPreview';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { SUBSCRIPTIONS } from '../../data';

const Container = styled('section')`
  ul li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

type Props = {};

const SelectSubscriptions = props => {
  const signUpPage = useSignUpPage();
  const pricingPlans = usePricingPlans();
  const { selectPlan } = useSubscriptionsContext();
  const plans = [
    SUBSCRIPTIONS.creatorPro.key,
    SUBSCRIPTIONS.business.key,
    SUBSCRIPTIONS.enterprise.key,
  ];

  const subscriptionPricingPlans = getPricingPlans(signUpPage).filter(pricingPlan =>
    plans.includes(pricingPlan)
  );

  const viewSubscription = (planKey: string) => {
    let selectedPlan = SUBSCRIPTIONS.creatorPro.key;

    if (SUBSCRIPTIONS.business.plan_codes.includes(planKey)) {
      selectedPlan = SUBSCRIPTIONS.business.key;
    }

    if (SUBSCRIPTIONS.enterprise.plan_codes.includes(planKey)) {
      selectedPlan = SUBSCRIPTIONS.enterprise.key;
    }
    selectPlan(selectedPlan);
  };

  return (
    <Container {...props}>
      <ul>
        {subscriptionPricingPlans.map(uid => {
          const plan = getPricingPlan(pricingPlans, uid);
          return (
            <li key={uid}>
              <PlanPreview
                plan={uid}
                name={getPricingPlanName(plan)}
                price={getPricingPlanSubtitle(plan)}
                description={getPricingPlanBriefDescription(plan)}
                onClick={() => {
                  viewSubscription(uid);
                }}
              />
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default SelectSubscriptions;
