// @flow
import React, { useState } from 'react';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import onClickOutside from 'react-onclickoutside';
import { transparentize } from 'polished';
import {
  smallMediumTextCss,
  smallPlainTextMonoCss,
} from '../../../../styles/typography/typography';
import { lightColor } from '../../../../styles/config/colors';
import { CompactButton } from '../../../Button/Button';
import {
  useTrialCounterMenu,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getTrialCounterMenuTitle,
  getTrialCounterRemainingLessThanDayInfo,
  getTrialCounterRemainingDayInfo,
  getTrialCounterRemainingDaysInfo,
  getTrialCounterAllowlistingDateNotice,
  getTrialCounterTrialCancelledDateNotice,
  getTrialCounterEndTrialEarlyPrompt,
} from '../../../../cms/trialCounterMenu';
import { getSubscriptionsUndoCancellationButton } from '../../../../cms/subscriptionsPage';
import {
  useSubscriptionsContext,
  useFreeTrialSubscriptions,
} from '../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getSubscriptionChannelId,
  getSubscriptionPlanKey,
  getSeatInterval,
  getSubscriptionTrialValidUntilDate,
  getSubscriptionStatus,
  doesSubscriptionHaveYouTubeChannelConnected,
} from '../../../../api/firebase/user/subscriptions';
import { getDaysUntilDate } from '../../../../utils/date';
import { SUBSCRIPTION_STATUSES } from '../../../../api/firebase/user/user';
import SpinnerIcon from '../../../SpinnerIcon/SpinnerIcon';
import LoadingModal from '../../../LoadingModal/LoadingModal';
import { useAnalyticsMixpanelContext } from '../../../../analytics/components/MixpanelWrapper';
import { analyticsMixpanelAccountEndTrialClick } from '../../../../analytics/mixpanel';

const countLimitReached = 'countLimitReached';

const Container = styled('section')`
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 300px;
  overflow: hidden;
`;

const MenuTitle = styled('h6')`
  ${smallMediumTextCss};
  padding: 13px 15px 3px 15px;
`;

const RemainingDaysInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 19px;

  &:first-of-type {
    margin-bottom: 5px;
  }
`;

const CountInformation = styled('p')`
  width: 195px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 10px;

  & span {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CountNumberWrapper = styled('div')`
  width: 50px;
  height: 50px;
  background-color: ${transparentize(0.9, lightColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 2px;
  margin-left: 15px;
`;

const CountNumber = styled('h6')`
  ${smallPlainTextMonoCss};
  font-size: 28px;
  font-weight: 300;

  &.${countLimitReached} {
    opacity: 0.3;
  }
`;

const AllowlistingDateNotice = styled('div')`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 0px;
  width: 90%;
`;

const UpgradePromptWrapper = styled('div')`
  background-position: center;
`;

const UpgradePromptButtonWrapper = styled('div')`
  padding: 20px;
  display: flex;
  justify-content: center;

  & a {
    width: 100%;
  }
`;
const UpgradePromptButton = styled(CompactButton)`
  background-color: #4d4d4d;
  width: 100%;
  font-size: 10px;
  padding: 7px 0px;
`;

const spinnerClass = css`
  position: static;
`;

type Props = {
  onClose: () => void,
};

export const parseAllowlistingDate = (copy: string, date: string): string => {
  const allowlistinParagraph = copy.replace('$END_OF_TRIAL_DATE$', date);
  return allowlistinParagraph;
};

const TrialCounterMenu = ({ onClose }: Props) => {
  const [busy, setBusy] = useState(false);
  const { upgradeSubscription, removeScheduledChanges } = useSubscriptionsContext();
  const trialCounterMenuCopy = useTrialCounterMenu();
  const subscriptionsPage = useSubscriptionsPage();
  const freeTrialSubscription = useFreeTrialSubscriptions()[0];
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();

  const remainingTrialDayCount = getDaysUntilDate(
    get(freeTrialSubscription, 'seat.trialPlanValidUntilSeconds', 0) * 1000
  );

  const handleClick = () => {
    if (busy) return;
    setBusy(true);

    const subStatus = getSubscriptionStatus(freeTrialSubscription);
    // If the trial has been scheduled for cancellation, the button instead will be set to undo the cancellation
    if (subStatus === SUBSCRIPTION_STATUSES.trial_cancelled) {
      removeScheduledChanges(freeTrialSubscription.key).finally(() => {
        setBusy(false);
        onClose();
      });
      return;
    }

    const subscriptionPlanKey = getSubscriptionPlanKey(freeTrialSubscription);
    const isYouTubeConnected = doesSubscriptionHaveYouTubeChannelConnected(freeTrialSubscription);

    analyticsMixpanelAccountEndTrialClick(
      mixpanel,
      moengage,
      subscriptionPlanKey,
      isYouTubeConnected,
      remainingTrialDayCount
    );

    upgradeSubscription(
      getSubscriptionChannelId(freeTrialSubscription),
      getSubscriptionPlanKey(freeTrialSubscription),
      getSeatInterval(freeTrialSubscription)
    )
      .then(() => {
        setBusy(false);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage(
          'Something went wrong when converting subscription from free trial to paid subscripton'
        );
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const remainingDaysMessage = (() => {
    if (remainingTrialDayCount <= 0) {
      return parse(getTrialCounterRemainingLessThanDayInfo(trialCounterMenuCopy));
    }

    if (remainingTrialDayCount === 1) {
      return parse(getTrialCounterRemainingDayInfo(trialCounterMenuCopy));
    }

    return parse(
      getTrialCounterRemainingDaysInfo(trialCounterMenuCopy).replace(
        '$REMAINING_DAYS_ON_TRIAL$',
        remainingTrialDayCount.toString()
      )
    );
  })();

  TrialCounterMenu.handleClickOutside = () => {
    onClose();
  };
  return (
    <Container>
      <MenuTitle>{getTrialCounterMenuTitle(trialCounterMenuCopy)}</MenuTitle>
      <RemainingDaysInfo>
        <CountInformation>{remainingDaysMessage}</CountInformation>
        <CountNumberWrapper>
          <CountNumber
            className={cx({
              [countLimitReached]: false,
            })}
          >
            {remainingTrialDayCount >= 0 ? remainingTrialDayCount : 0}
          </CountNumber>
        </CountNumberWrapper>
      </RemainingDaysInfo>
      <RemainingDaysInfo>
        <AllowlistingDateNotice>
          {getSubscriptionStatus(freeTrialSubscription) === SUBSCRIPTION_STATUSES.trial
            ? parseAllowlistingDate(
                getTrialCounterAllowlistingDateNotice(trialCounterMenuCopy),
                getSubscriptionTrialValidUntilDate(freeTrialSubscription)
              )
            : parseAllowlistingDate(
                getTrialCounterTrialCancelledDateNotice(trialCounterMenuCopy),
                getSubscriptionTrialValidUntilDate(freeTrialSubscription)
              )}
        </AllowlistingDateNotice>
      </RemainingDaysInfo>
      <UpgradePromptWrapper>
        <UpgradePromptButtonWrapper>
          <UpgradePromptButton onClick={handleClick}>
            {busy && <SpinnerIcon inverse={false} containerClassName={spinnerClass} />}
            {!busy &&
              getSubscriptionStatus(freeTrialSubscription) === SUBSCRIPTION_STATUSES.trial &&
              getTrialCounterEndTrialEarlyPrompt(trialCounterMenuCopy)}
            {!busy &&
              getSubscriptionStatus(freeTrialSubscription) !== SUBSCRIPTION_STATUSES.trial &&
              getSubscriptionsUndoCancellationButton(subscriptionsPage)}
          </UpgradePromptButton>
        </UpgradePromptButtonWrapper>
      </UpgradePromptWrapper>
      {busy && <LoadingModal />}
    </Container>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => TrialCounterMenu.handleClickOutside,
};

export default onClickOutside(TrialCounterMenu, clickOutsideConfig);
