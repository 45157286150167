// @flow

import { css } from 'emotion';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { darkColor } from './config/colors';
import { plainTextCss } from './typography/typography';
import { buttonResetCss } from '../components/Button/Button';
import {
  LARGE_DESKTOP_BREAKPOINT,
  LARGE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from './responsive';

export const shadedContainerCss = css`
  background-color: ${transparentize(0.95, darkColor)};
  border-radius: 3px;
`;

export const shadedContainerShadowCss = css`
  box-shadow: 0 5px 15px 0 rgba(255, 255, 255, 0.1);
`;
export const GeneralDescription = styled('div')`
  ${plainTextCss};
  margin-top: 20px;

  a {
    &:focus {
      opacity: 0.8;
      outline: none;
    }
  }

  p {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;
export const placeholderCss = (styles: string): string => {
  return css`
    ::-webkit-input-placeholder {
      ${styles}
    }
    :-moz-placeholder {
      ${styles}
    }
    ::-moz-placeholder {
      ${styles}
    }
    :-ms-input-placeholder {
      ${styles}
    }
  `;
};
export const inputResetCss = css`
  font: inherit;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
`;

export const styledListCss = css`
  li {
    margin-top: 12px;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '•';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
`;

export const suggestionFocusedCss = css`
  background: rgba(15, 15, 20, 0.05);

  ${LARGE_DESKTOP_BREAKPOINT} {
    transform: translateX(0);
  }

  svg {
    opacity: 1;
  }
`;

export const simpleListItemCss = css`
  ${buttonResetCss};
  border-radius: 2px;
  padding: 0 9px 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${LARGE_DEVICE_BREAKPOINT} {
    transform: translateX(-20px);
    transition: all 250ms ease;
  }

  svg {
    display: block;
    margin-right: 5px;
    opacity: 0;
    transition: all 250ms ease;
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }

  span {
    display: inline-block;
    position: relative;
    top: 1px;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      ${suggestionFocusedCss};
      outline: none;
    }

    &:active {
      color: ${transparentize(0.2, darkColor)};
    }
  }
`;

export const simpleListItemHighlightedCss = css`
  ${suggestionFocusedCss};
`;

export const SimpleListItem = styled('button')`
  ${simpleListItemCss};
  text-transform: capitalize;

  ${SMALL_DEVICE_BREAKPOINT} {
    ${suggestionFocusedCss};
  }
`;
