// @flow

const oneMonth = 2629743;
const oneDay = 86400;
export const month = {
  monthly: oneMonth,
  quarterly: oneMonth * 3,
  biannual: oneMonth * 6 + oneDay,
  annual: oneMonth * 12 - oneDay,
};

export const compareDate = (arr: Array<number>): number => {
  return Math.max.apply(null, arr);
};

export const reduceTimeStampByinterval = (
  validUntilSeconds: number,
  interval: string
): number | null => {
  if (interval != null && validUntilSeconds != null) {
    return validUntilSeconds - month[interval];
  }
  return null;
};

export const getDateString = (timestamp: number | string): string => {
  const date = new Date(timestamp);
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  return dateString;
};

export const getYesterdayDate = (): Date => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

export const getDaysUntilDate = (timestamp: number): number => {
  const freeTrailTimeStamp = new Date(timestamp);
  const freeTrailEndDate = new Date(freeTrailTimeStamp.getTime());
  const today = new Date();
  const remainingDays = (freeTrailEndDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
  const remainingFullDays = Math.floor(remainingDays);
  return remainingFullDays;
};

export const getHistoryDateFormat = (timestamp: number | string): string => {
  const dateString = getDateString(timestamp);
  const formattedString = dateString.replace(/-/g, '.').substr(2);
  return formattedString;
};
