// @flow
import { get } from 'lodash';
import { graphql } from 'gatsby';

export type SignUpPageMdl = {
  data: {
    sign_in_prompt: {
      text: string,
    },
    sign_in_button: {
      text: string,
    },
    create_account_form_heading: {
      text: string,
    },
    account_inclusions_label: {
      text: string,
    },
    account_inclusions: {
      html: string,
    },
    first_name_label: {
      text: string,
    },
    last_name_label: {
      text: string,
    },
    email_address_label: {
      text: string,
    },
    password_label: {
      text: string,
    },
    terms_and_conditions_label: {
      html: string,
    },
    create_account_button: {
      text: string,
    },
    or_divider: {
      text: string,
    },
    continue_with_facebook_button: {
      text: string,
    },
    continue_with_google_button: {
      text: string,
    },
    pricing_heading: {
      text: string,
    },
    pricing_instruction: {
      text: string,
    },
    pricing_plans: {
      plan: {
        uid: string,
      },
    },
    account_whitelist_message: {
      text: string,
    },
    add_subscription: {
      text: string,
    },
    more_features_heading: {
      text: string,
    },
    more_features_description: {
      text: string,
    },
    feature_broadcast_title: {
      text: string,
    },
    feature_broadcast_description: {
      html: string,
    },
    feature_broadcast_contact: {
      html: string,
    },
    feature_agency_title: {
      text: string,
    },
    feature_agency_description: {
      html: string,
    },
    feature_agency_contact: {
      html: string,
    },
    confirm_heading: {
      text: string,
    },
    confirm_description: {
      text: string,
    },
    confirm_terms_agreement_label: {
      text: string,
    },
    add_subscription: {
      text: string,
    },
  },
};

export const getSignUpAccountInclusionsLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.account_inclusions_label.text', '') || '';
};

export const getSignUpAccountInclusions = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.account_inclusions.html', '') || '';
};

export const getAddSubscription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.add_subscription.text', '') || '';
};

export const getConnectYouTubePromptHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_prompt_heading.text', '') || '';
};

export const getConnectYouTubePromptDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_prompt_description.html', '') || '';
};

export const getPaymentDetailsHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.payment_details_heading.text', '') || '';
};

export const getPaymentDetailsDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.payment_details_description.html', '') || '';
};

export const getSignInPrompt = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.sign_in_prompt.text', '') || '';
};

export const getSignInButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.sign_in_button.text', '') || '';
};

export const getGoBackButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.go_back_button.text', '') || '';
};

export const getCreateAccountFormHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.create_account_form_heading.text', '') || '';
};

export const getFirstNameLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.first_name_label.text', '') || '';
};

export const getLastNameLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.last_name_label.text', '') || '';
};

export const getEmailLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.email_address_label.text', '') || '';
};

export const getPasswordLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.password_label.text', '') || '';
};

export const getTermsAndConditionsLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.terms_and_conditions_label.html', '') || '';
};

export const getCreateAccountButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.create_account_button.text', '') || '';
};

export const getContinueWithFacebookButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.continue_with_facebook_button.text', '') || '';
};

export const getContinueWithGoogleButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.continue_with_google_button.text', '') || '';
};

export const getOrDivider = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.or_divider.text', '') || '';
};

export const getPricingHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.pricing_heading.text', '') || '';
};

export const getPricingInstruction = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.pricing_instruction.text', '') || '';
};

export const getPricingPlans = (signUpPage: SignUpPageMdl): Array<string> => {
  const plans = get(signUpPage, 'data.pricing_plans', '') || '';
  return plans.map(({ plan }) => {
    return plan.uid;
  });
};

export const getMoreFeaturesHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.more_features_heading.text', '') || '';
};

export const getMoreFeaturesDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.more_features_description.text', '') || '';
};

export const getMoreFeatureBroadcastTitle = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_title.text', '') || '';
};

export const getMoreFeatureBroadcastDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_description.html', '') || '';
};

export const getMoreFeatureBroadcastContact = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_contact.html', '') || '';
};

export const getMoreFeatureAgencyTitle = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_agency_title.text', '') || '';
};

export const getMoreFeatureAgencyDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_agency_description.html', '') || '';
};

export const getMoreFeatureAgencyContact = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.feature_agency_contact.html', '') || '';
};

export const getConfirmHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.confirm_heading.text', '') || '';
};

export const getConfirmDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.confirm_description.text', '') || '';
};

export const getConfirmButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.confirm_button.text', '') || '';
};

export const getConfirmTermsAgreementLabel = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.confirm_terms_agreement_label.text', '') || '';
};

export const getConnectYoutubeHeading = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_heading.text', '') || '';
};

export const getConnectYoutubeDescription = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_description.text', '') || '';
};

export const getConnectYoutubeButton = (signUpPage: SignUpPageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_button.text', '') || '';
};

export type SignUpModalGraphMdl = {
  data: {
    sign_up_modal_header: {
      text: string,
    },
    sign_up_modal_message: {
      html: string,
    },
  },
};

export const getSignUpModalHeading = (copy: SignUpModalGraphMdl): string => {
  return get(copy, 'data.sign_up_modal_header.text', '') || '';
};

export const getSignUpModalMessage = (copy: SignUpModalGraphMdl): string => {
  return get(copy, 'data.sign_up_modal_message.html', '') || '';
};

export type SignUpEnterprisePageMdl = {
  data: {
    sign_in_prompt: {
      text: string,
    },
    sign_in_button: {
      text: string,
    },
    create_account_form_heading: {
      text: string,
    },
    account_inclusions_label: {
      text: string,
    },
    account_inclusions: {
      html: string,
    },
    first_name_label: {
      text: string,
    },
    last_name_label: {
      text: string,
    },
    email_address_label: {
      text: string,
    },
    password_label: {
      text: string,
    },
    terms_and_conditions_label: {
      html: string,
    },
    create_account_button: {
      text: string,
    },
    or_divider: {
      text: string,
    },
    continue_with_facebook_button: {
      text: string,
    },
    continue_with_google_button: {
      text: string,
    },
    pricing_heading: {
      text: string,
    },
    pricing_instruction: {
      text: string,
    },
    pricing_plans: {
      plan: {
        uid: string,
      },
    },
    account_whitelist_message: {
      text: string,
    },
    add_subscription: {
      text: string,
    },
    more_features_heading: {
      text: string,
    },
    more_features_description: {
      text: string,
    },
    feature_broadcast_title: {
      text: string,
    },
    feature_broadcast_description: {
      html: string,
    },
    feature_broadcast_contact: {
      html: string,
    },
    feature_agency_title: {
      text: string,
    },
    feature_agency_description: {
      html: string,
    },
    feature_agency_contact: {
      html: string,
    },
    confirm_heading: {
      text: string,
    },
    confirm_description: {
      text: string,
    },
    confirm_terms_agreement_label: {
      text: string,
    },
  },
};

export const getSignUpAccountInclusionsLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.account_inclusions_label.text', '') || '';
};

export const getSignUpAccountInclusions2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.account_inclusions.html', '') || '';
};

export const getAddSubscription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.add_subscription.text', '') || '';
};

export const getConnectYouTubePromptHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_prompt_heading.text', '') || '';
};

export const getConnectYouTubePromptDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_prompt_description.html', '') || '';
};

export const getPaymentDetailsHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.payment_details_heading.text', '') || '';
};

export const getPaymentDetailsDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.payment_details_description.html', '') || '';
};

export const getSignInPrompt2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.sign_in_prompt.text', '') || '';
};

export const getSignInButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.sign_in_button.text', '') || '';
};

export const getGoBackButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.go_back_button.text', '') || '';
};

export const getCreateAccountFormHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.create_account_form_heading.text', '') || '';
};

export const getFirstNameLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.first_name_label.text', '') || '';
};

export const getLastNameLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.last_name_label.text', '') || '';
};

export const getEmailLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.email_address_label.text', '') || '';
};

export const getPasswordLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.password_label.text', '') || '';
};

export const getTermsAndConditionsLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.terms_and_conditions_label.html', '') || '';
};

export const getCreateAccountButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.create_account_button.text', '') || '';
};

export const getContinueWithFacebookButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.continue_with_facebook_button.text', '') || '';
};

export const getContinueWithGoogleButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.continue_with_google_button.text', '') || '';
};

export const getOrDivider2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.or_divider.text', '') || '';
};

export const getPricingHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.pricing_heading.text', '') || '';
};

export const getPricingInstruction2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.pricing_instruction.text', '') || '';
};

export const getPricingPlans2 = (signUpPage: SignUpEnterprisePageMdl): Array<string> => {
  const plans = get(signUpPage, 'data.pricing_plans', '') || '';
  return plans.map(({ plan }) => {
    return plan.uid;
  });
};

export const getMoreFeaturesHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.more_features_heading.text', '') || '';
};

export const getMoreFeaturesDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.more_features_description.text', '') || '';
};

export const getMoreFeatureBroadcastTitle2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_title.text', '') || '';
};

export const getMoreFeatureBroadcastDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_description.html', '') || '';
};

export const getMoreFeatureBroadcastContact2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_broadcast_contact.html', '') || '';
};

export const getMoreFeatureAgencyTitle2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_agency_title.text', '') || '';
};

export const getMoreFeatureAgencyDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_agency_description.html', '') || '';
};

export const getMoreFeatureAgencyContact2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.feature_agency_contact.html', '') || '';
};

export const getConfirmHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.confirm_heading.text', '') || '';
};

export const getConfirmDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.confirm_description.text', '') || '';
};

export const getConfirmButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.confirm_button.text', '') || '';
};

export const getConfirmTermsAgreementLabel2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.confirm_terms_agreement_label.text', '') || '';
};

export const getConnectYoutubeHeading2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_heading.text', '') || '';
};

export const getConnectYoutubeDescription2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_description.text', '') || '';
};

export const getConnectYoutubeButton2 = (signUpPage: SignUpEnterprisePageMdl): string => {
  return get(signUpPage, 'data.connect_youtube_button.text', '') || '';
};

export const query = graphql`
  fragment SignUpModal on PrismicSignUpModal {
    data {
      sign_up_modal_header {
        text
      }
      sign_up_modal_message {
        html
      }
    }
  }
  fragment SignUpPage on PrismicSignUpPage {
    data {
      sign_in_prompt {
        text
      }
      sign_in_button {
        text
      }
      create_account_form_heading {
        text
      }
      account_inclusions_label {
        text
      }
      account_inclusions {
        html
      }
      first_name_label {
        text
      }
      last_name_label {
        text
      }
      email_address_label {
        text
      }
      password_label {
        text
      }
      terms_and_conditions_label {
        html
      }
      create_account_button {
        text
      }
      or_divider {
        text
      }
      continue_with_facebook_button {
        text
      }
      continue_with_google_button {
        text
      }
      pricing_heading {
        text
      }
      pricing_instruction {
        text
      }
      pricing_plans {
        plan {
          uid
        }
      }
      account_whitelist_message {
        text
      }
      add_subscription {
        text
      }
      more_features_heading {
        text
      }
      more_features_description {
        text
      }
      feature_broadcast_title {
        text
      }
      feature_broadcast_description {
        html
      }
      feature_broadcast_contact {
        html
      }
      feature_agency_title {
        text
      }
      feature_agency_description {
        html
      }
      feature_agency_contact {
        html
      }
      confirm_heading {
        text
      }
      confirm_description {
        text
      }
      confirm_terms_agreement_label {
        text
      }
      add_subscription {
        text
      }
    }
  }
  fragment SignUpEnterprisePage on PrismicSignUpEnterprisePage {
    data {
      sign_in_prompt {
        text
      }
      sign_in_button {
        text
      }
      create_account_form_heading {
        text
      }
      account_inclusions_label {
        text
      }
      account_inclusions {
        html
      }
      first_name_label {
        text
      }
      last_name_label {
        text
      }
      email_address_label {
        text
      }
      password_label {
        text
      }
      terms_and_conditions_label {
        html
      }
      create_account_button {
        text
      }
      or_divider {
        text
      }
      continue_with_facebook_button {
        text
      }
      continue_with_google_button {
        text
      }
      pricing_heading {
        text
      }
      pricing_instruction {
        text
      }
      pricing_plans {
        plan {
          uid
        }
      }
      account_whitelist_message {
        text
      }
      add_subscription {
        text
      }
      more_features_heading {
        text
      }
      more_features_description {
        text
      }
      feature_broadcast_title {
        text
      }
      feature_broadcast_description {
        html
      }
      feature_broadcast_contact {
        html
      }
      feature_agency_title {
        text
      }
      feature_agency_description {
        html
      }
      feature_agency_contact {
        html
      }
      confirm_heading {
        text
      }
      confirm_description {
        text
      }
      confirm_terms_agreement_label {
        text
      }
    }
  }
`;
