// @flow
import React from 'react';
import styled from 'react-emotion';
import InternalFilterMenuOption from '../InternalFilterMenuOption/InternalFilterMenuOption';
import {
  Body,
  OptionsContainer,
  FilteringSubTitle,
  FilterOptions,
} from '../FilterSelectMenu/FilterSelectMenu';
import { useResultsManagerContext } from '../../../../../ResultsManager/ResultsManager';
import {
  getResultsPageShowHiddenTracksFilterLabel,
  getInternalFilterLabel,
} from '../../../../../../../../../../../cms/resultsPage';
import { useResultsPage } from '../../../../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';

type Props = {};

const InternalFilterBody = styled(Body)`
  height: 150px;
  width: 300px;
`;

const HiddenFilterMenu = () => {
  const resultsPage = useResultsPage();
  const { showHiddenSongs, handleShowHiddenSongs, hiddenTracksCount } = useResultsManagerContext();
  const handleHiddenSongsFilter = () => {
    handleShowHiddenSongs(!showHiddenSongs);
  };

  return (
    <InternalFilterBody>
      <OptionsContainer>
        <FilteringSubTitle>{getInternalFilterLabel('hidden', resultsPage)}</FilteringSubTitle>
        <FilterOptions>
          <InternalFilterMenuOption
            filterKey="hidden"
            filterLabel={getResultsPageShowHiddenTracksFilterLabel(resultsPage)}
            filterCount={hiddenTracksCount}
            isIncluded={showHiddenSongs}
            handleFilter={handleHiddenSongsFilter}
          />
        </FilterOptions>
      </OptionsContainer>
    </InternalFilterBody>
  );
};

export default HiddenFilterMenu;
