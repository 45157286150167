// @flow

import { get } from 'lodash';
import {
  getArtistProfileCoverArt,
  getArtistProfileDocumentType,
  getArtistProfileId,
  getArtistProfileName,
  getArtistProfileThumbnail,
} from '../cms/artistProfile';
import type { ArtistProfileGraphMdl } from '../cms/artistProfile';
import {
  getCuratedCollectionDocumentType,
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../cms/curatedCollection';
import type { CuratedCollectionGraphMdl } from '../cms/curatedCollection';
import {
  getMusicPageFeaturedCollectionMainImage,
  getMusicPageFeaturedCollectionMobileImage,
} from '../cms/musicPage';

export const FEATURED_CONTENT_TYPES = {
  collection: 'curated_collection',
  artist: 'artist_profile',
};

export type CarouselItemMdl = {
  id: string,
  prismicType: string,
  listType: string,
  title: string,
  songs: Array<string>,
  mainImage: string,
  featuredImage: string,
  featuredMobileImage: string,
};

const getFeaturedCollectionData = (collection: CuratedCollectionGraphMdl): CarouselItemMdl => {
  const id = getCuratedCollectionId(collection);
  const prismicType = getCuratedCollectionDocumentType(collection);
  const listType = 'collection';
  const title = getCuratedCollectionTitle(collection);
  const songs = getCuratedCollectionSongs(collection);
  const mainImage = getCuratedCollectionImage(collection);
  const featuredImage = getMusicPageFeaturedCollectionMainImage(collection);
  const featuredMobileImage = getMusicPageFeaturedCollectionMobileImage(collection);

  return {
    id,
    prismicType,
    listType,
    title,
    songs,
    mainImage,
    featuredImage,
    featuredMobileImage,
  };
};

const getFetauredArtistData = (artist: ArtistProfileGraphMdl): CarouselItemMdl => {
  const id = getArtistProfileId(artist);
  const prismicType = getArtistProfileDocumentType(artist);
  const listType = 'artist';
  const title = getArtistProfileName(artist);
  const songs = [];
  const mainImage = getArtistProfileThumbnail(artist);
  const featuredImage = getArtistProfileCoverArt(artist);
  const featuredMobileImage = getArtistProfileCoverArt(artist);

  return {
    id,
    prismicType,
    listType,
    title,
    songs,
    mainImage,
    featuredImage,
    featuredMobileImage,
  };
};

export const getFeaturedDataContent = (featuredItem: any) => {
  const featuredType = get(featuredItem, 'type', '') || '';

  return featuredType === FEATURED_CONTENT_TYPES.collection
    ? getFeaturedCollectionData(featuredItem)
    : getFetauredArtistData(featuredItem);
};
