// @flow
import { isMobile } from './device';
import { getAlgoliaKeyword, getAlgoliaKeywordParent } from '../api/algolia/magicKeywords';

// Gets the number of results returned by Algolia based on user Device

export const getAlgoliaMaxSearchResults = (): number => {
  if (isMobile()) {
    return 200;
  }
  return 1000;
};

export const createSongIDFilterQuery = (songIDs: Array<string>) => {
  let searchFilter = '';
  const filteredSongIDs = songIDs.filter(songID => {
    return songID !== '' && songID !== null && songID !== undefined;
  });

  filteredSongIDs.forEach((songID, index) => {
    searchFilter += `objectID:${songID}`;
    if (index < filteredSongIDs.length - 1) {
      searchFilter += ` OR `;
    }
  });

  return searchFilter;
};

export const groupAlgoliaTags = data => {
  const groupedTags = {};

  data.forEach(dataEntry => {
    const parent = getAlgoliaKeywordParent(dataEntry);
    if (parent) {
      const groupValues = groupedTags[parent];
      if (groupValues) {
        groupedTags[parent] = groupedTags[parent].concat([getAlgoliaKeyword(dataEntry)]);
      } else {
        groupedTags[parent] = [getAlgoliaKeyword(dataEntry)];
      }
    }
  });
  return groupedTags;
};

export const getGroupColumns = (data: any, columnMax: any) => {
  const groupColumns = [];
  let currentColumnLength = 0;
  let currentColumn = [];

  Object.keys(data)
    .sort()
    .forEach(groupKey => {
      const group = data[groupKey];
      currentColumn = currentColumn.concat([{ [groupKey]: group }]);
      currentColumnLength += group.length;

      if (currentColumnLength >= columnMax) {
        groupColumns.push(currentColumn);
        currentColumn = [];
        currentColumnLength = 0;
      }
    });
  groupColumns.push(currentColumn);
  return groupColumns;
};
