// @flow
import React, { useState } from 'react';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  useChangeIntervalSubscriptionModalCopy,
  useMiscCopy,
  usePricingPlans,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getChangeIntervalSubscriptionModalPageInstruction,
  getChangeIntervalSubscriptionModalTitle,
} from '../../../../cms/changeIntervalSubscriptionModal';
import { getPricingPlan, getPricingPlanName } from '../../../../cms/pricingPlan';
import {
  getSubscriptionChannelId,
  getSubscriptionNextBillingDate,
  getSubscriptionPlanKey,
  getSeatInterval,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { SubscriptionModalFooter } from '../CancelSubscriptionModal/CancelSubscriptionModal';
import Button, { DarkButton } from '../../../../components/Button/Button';
import {
  getMiscCopyCancelButton,
  getMiscCopyConfirmButton,
  getMiscCopyMonthlyButton,
  getMiscCopyQuarterlyButton,
  getMiscCopyBiannuallyButton,
  getMiscCopyAnnuallyButton,
} from '../../../../cms/miscCopy';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import { getSubscriptionsPagePromotionalDefaultInterval } from '../../../../cms/subscriptionsPage';

type Props = {
  subscription: UserSubscriptionMdl,
  newPlanInterval: string,
  onClose: () => void,
};

const ChangeIntervalSubscriptionModal = ({ subscription, newPlanInterval, onClose }: Props) => {
  const [busy, setBusy] = useState(false);
  const { updateSubscriptionInterval, billingCycle, setBillingCycle } = useSubscriptionsContext();
  const locale = useLocale();
  const copy = useChangeIntervalSubscriptionModalCopy();
  const miscCopy = useMiscCopy();
  const pricingPlans = usePricingPlans();
  const planName = getPricingPlanName(
    getPricingPlan(pricingPlans, getSubscriptionPlanKey(subscription))
  );
  const interval1 = getSeatInterval(subscription);
  const interval2 = newPlanInterval;

  const subscriptionsPage = useSubscriptionsPage();
  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const getLocalisedInterval = (interval: string): string => {
    if (locale === 'ja') {
      if (interval === 'annual') {
        return getMiscCopyAnnuallyButton(miscCopy);
      }
      if (interval === 'biannual') {
        return getMiscCopyBiannuallyButton(miscCopy);
      }
      if (interval === 'quarterly') {
        return getMiscCopyQuarterlyButton(miscCopy);
      }
      return getMiscCopyMonthlyButton(miscCopy);
    }
    return interval;
  };
  const date = getSubscriptionNextBillingDate(subscription);

  const title = getChangeIntervalSubscriptionModalTitle(copy).replace('$PLAN$', planName);
  const description = getChangeIntervalSubscriptionModalPageInstruction(copy)
    .replace('$PLAN1$', planName)
    .replace('$INTERVAL1$', getLocalisedInterval(interval1))
    .replace('$PLAN2$', planName)
    .replace('$INTERVAL2$', getLocalisedInterval(interval2))
    .replace('$DATE$', date);

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleConfirm = () => {
    if (busy) return;
    setBusy(true);
    updateSubscriptionInterval(
      getSubscriptionChannelId(subscription),
      subscription.seat.plan,
      billingCycle
    )
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when changing subscription interval');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  return (
    <ClassicModalLayout label={title} onClose={handleClose} fullSized={true}>
      <ClassicModalContentLayout
        heading={title}
        description={description}
        footer={
          <SubscriptionModalFooter>
            <DarkButton onClick={handleClose}>{getMiscCopyCancelButton(miscCopy)}</DarkButton>
            <Button loading={busy} onClick={handleConfirm}>
              {getMiscCopyConfirmButton(miscCopy)}
            </Button>
          </SubscriptionModalFooter>
        }
      />
    </ClassicModalLayout>
  );
};

export default ChangeIntervalSubscriptionModal;
