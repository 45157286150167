// @flow
import React, { useEffect } from 'react';
import noScroll from 'no-scroll';
import ReactModal from 'react-modal';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import {
  LANDSCAPE_DEVICE,
  MEDIA_BREAKPOINTS,
  MOBILE_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT,
  SMALL_DESKTOP_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../styles/responsive';
import { evokeBorderColor, modalBackGroundColor } from '../../../styles/config/colors';

ReactModal.setAppElement('#___gatsby');

export const baseModalCss = css`
  max-height: 100vh;
  max-height: -webkit-fill-available;
`;

export const baseModalOverlayCss = css`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const slimModalCss = css`
  ${baseModalCss};
  width: 100%;
  max-width: 500px;

  ${SMALL_DEVICE_BREAKPOINT} {
    ${LANDSCAPE_DEVICE} {
      max-width: 335px;
    }
  }
`;

export const slimModalOverlayCss = css`
  ${baseModalOverlayCss};
  ${SMALL_DEVICE_BREAKPOINT} {
    padding-left: 60px;
    padding-right: 60px;

    ${LANDSCAPE_DEVICE} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  ${MEDIA_BREAKPOINTS.max600} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const fullSizeModalCss = css`
  ${baseModalCss};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const fullSizeModalOverlayCss = css`
  ${baseModalOverlayCss};
  padding: 0;
`;

export const wideModalCss = css`
  ${baseModalCss};
  width: 100%;
  max-width: 650px;

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: none;
    ${LANDSCAPE_DEVICE} {
      max-width: 335px;
    }
  }
`;

export const wideModalCssPos = css`
  ${baseModalCss};
  width: 100%;
  max-width: 800px;

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: none;
    ${LANDSCAPE_DEVICE} {
      max-width: 335px;
    }
  }
`;

export const wideModalOverlayCss = css`
  ${baseModalOverlayCss};
  ${slimModalOverlayCss};
  overflow-y: no-scroll;
  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 0px;
  }

  ${MOBILE_DEVICE_BREAKPOINT} {
    display: flex;
    &.notFullSized {
      padding: 20px;
    }
  }
`;

export const notiModalCss = css`
  ${baseModalCss};
  width: 100%;
  max-width: 460px;

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: none;
    ${LANDSCAPE_DEVICE} {
      max-width: 335px;
    }
  }
`;

export const notiModalOverlayCss = css`
  ${baseModalOverlayCss};
  ${slimModalOverlayCss};

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 10px 60px;
  }

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 10px 20px;
  }
`;

export const topAlignedModalCss = css`
  ${baseModalOverlayCss};
  ${slimModalOverlayCss};

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 10px 60px;
    padding-top: 56px;
  }

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 10px 20px;
    padding-top: 56px;
  }

  align-items: flex-start;
`;

export const filterMenuModal = css`
  ${baseModalCss};
  width: 100%;
  max-width: 300px;
  max-height: 610px;
  position: relative;
  right: 200px;
  left: 38%;
  top: -20px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dad8d3;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    left: 30%;
    top: -30px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    max-width: none;
    ${LANDSCAPE_DEVICE} {
      max-width: 335px;
    }
  }
`;

export const filterMenuModalSelect = css`
  ${filterMenuModal}
  max-width: 370px;
`;

export const hiddenFilterMenuModal = css`
  ${filterMenuModal}
  left: 60%;
  top: -210px;

  ${SMALL_DESKTOP_BREAKPOINT} {
    left: 55%;
    top: -210px;
  }
`;

export const filterMenuModalOverlayCss = css`
  ${baseModalOverlayCss};
  ${slimModalOverlayCss};
  justify-content: flex-start;

  ${SMALL_DEVICE_BREAKPOINT} {
    padding: 10px 60px;
  }

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    padding: 10px 20px;
  }
`;

export const ModalContainer = styled('div')`
  background-color: ${modalBackGroundColor};
  border: solid ${evokeBorderColor};
  box-shadow: 0 10px 30px 0 rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;

  ${MOBILE_DEVICE_OR_LANDSCAPE_BREAKPOINT} {
    min-height: ${props => (props.fullSized ? '100%' : '')};
    max-height: ${props => (props.fullSized ? '100%' : '')};
    min-height: ${props => (props.fullSized ? '-webkit-fill-available' : '')};
    max-height: ${props => (props.fullSized ? '-webkit-fill-available' : '')};
    border-radius: ${props => (props.fullSized ? '0px' : '5px')};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const overlayClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 500;
`;

const contentClass = css`
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 100%;
  outline: currentcolor none medium;
`;

export type ModalProps = {
  children: any,
  onClose: () => void,
  className?: string,
  overlayClassName?: string,
  label?: string,
  fullSized?: boolean,
  shouldReturnFocusAfterClose?: boolean,
};

const Modal = ({
  children,
  onClose,
  className = '',
  overlayClassName = '',
  label = '',
  fullSized,
  shouldReturnFocusAfterClose = true,
}: ModalProps) => {
  useEffect(() => {
    noScroll.on();
    return () => noScroll.off();
  }, []);
  return (
    <ReactModal
      isOpen
      onRequestClose={onClose}
      contentLabel={label}
      className={cx(contentClass, className)}
      overlayClassName={cx(overlayClass, overlayClassName, { ['notFullSized']: !fullSized })}
      fullSized={fullSized}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
