// @flow
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { transparentize } from 'polished';
import { buttonResetCss } from '../../../../../../../components/Button/Button';
import { darkColor, lightColor } from '../../../../../../../styles/config/colors';
import PlayIcon from '../../../../../../../assets/inline-assets/play-icon.svg';
import PauseIcon from '../../../../../../../assets/inline-assets/pause-icon.svg';
import { SMALL_DEVICE_BREAKPOINT } from '../../../../../../../styles/responsive';
import { useAudioPlaybackContext } from '../../../../../AudioPlayback/AudioPlayback';
import SpinnerIcon from '../../../../../../../components/SpinnerIcon/SpinnerIcon';
import {
  analyticsMixpanelPlayTrack,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../../analytics/components/MixpanelWrapper';
import { useSubscriptionsContext } from '../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { useDownloadContext } from '../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import {
  useAuthContext,
  useUserId,
} from '../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useAudioPlayerContext } from '../../../../../../../audio/components/AudioPlayerWrapper/AudioPlayerWrapper';
import { useShowSignUp } from '../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';

const Button = styled('button')`
  ${buttonResetCss};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;

  ${SMALL_DEVICE_BREAKPOINT} {
    width: 40px;
    height: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${darkColor};
    border-radius: 50%;
    transition: all 200ms ease-in;
  }

  &:active {
    opacity: 0.8;

    &::before {
      transition-timing-function: ease-out;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
  }
`;

const ImageButtonLayout = styled('div')`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
`;

const ImageButton = styled(Button)`
  &::before {
    border: none;
    border-radius: 2px;
  }
`;

const cantPlayClass = css`
  opacity: 0.33;
`;

const playClass = css`
  position: relative;
  left: 2px;

  ${SMALL_DEVICE_BREAKPOINT} {
    left: 2px;
  }
`;

const noOutlineClass = css`
  &::before {
    border: none;
  }
`;

const overlayShadow = css`
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.5, lightColor)};

  &::before {
    border-radius: 2px;
    border: none;
  }
`;

type RawPlayPauseButtonProps = {
  playing: boolean,
  onClick: () => void,
  cantPlay?: boolean,
  tryingToPlay?: boolean,
  noOutline?: boolean,
};

export const RawPlayPauseButton = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  onClick,
  noOutline = false,
}: RawPlayPauseButtonProps) => {
  // eslint-disable-next-line no-nested-ternary
  const icon = tryingToPlay ? (
    <SpinnerIcon inverse />
  ) : playing ? (
    <PauseIcon />
  ) : (
    <PlayIcon className={playClass} />
  );
  return (
    <Button
      className={cx({
        [cantPlayClass]: cantPlay,
        [noOutlineClass]: noOutline,
      })}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

export const ImagePlayPauseButton = ({
  playing,
  tryingToPlay = false,
  cantPlay = false,
  backgroundImage,
  onClick,
}: RawPlayPauseButtonProps) => {
  // eslint-disable-next-line no-nested-ternary
  const icon = tryingToPlay ? (
    <SpinnerIcon inverse />
  ) : playing ? (
    <PauseIcon />
  ) : (
    <PlayIcon className={playClass} />
  );
  return (
    <ImageButtonLayout
      className={cx({
        [cantPlayClass]: cantPlay,
      })}
      onClick={onClick}
      backgroundImage={backgroundImage}
    >
      <div className={overlayShadow}>
        <ImageButton>{icon}</ImageButton>
      </div>
    </ImageButtonLayout>
  );
};

type Props = {
  backgroundImage?: string,
};

const PlayPauseButton = ({ backgroundImage = '' }: Props) => {
  const { isAuthenticated } = useAuthContext();
  const showSignUp = useShowSignUp();

  const {
    playing,
    onPlayToggle,
    tryingToPlay,
    loadError,
    viewingMicroparts,
  } = useAudioPlaybackContext();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext()
    ? useAnalyticsMixpanelContext()
    : { mixpanel: null, moengage: null };
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const { userRole } = useSubscriptionsContext();
  const downloadContext = useDownloadContext();
  const userId = useUserId();
  const { analyticsSentSongId, setAnalyticsSentSongId } = useAudioPlayerContext();

  const handleOnPlayToggle = () => {
    if (!isAuthenticated) {
      showSignUp();
      return;
    }

    if (mixpanel && moengage) {
      if (!playing && !viewingMicroparts) {
        if (analyticsSentSongId !== mixpanelSongDimensions['Song ID']) {
          analyticsMixpanelPlayTrack(
            mixpanel,
            moengage,
            mixpanelSongDimensions,
            downloadContext,
            userRole,
            userId
          );
          setAnalyticsSentSongId(mixpanelSongDimensions['Song ID']);
        }
      }
    }

    onPlayToggle();
  };

  return (
    <>
      {backgroundImage ? (
        <ImagePlayPauseButton
          playing={playing}
          onClick={handleOnPlayToggle}
          tryingToPlay={tryingToPlay}
          backgroundImage={backgroundImage}
          cantPlay={loadError}
        />
      ) : (
        <RawPlayPauseButton
          playing={playing}
          onClick={handleOnPlayToggle}
          tryingToPlay={tryingToPlay}
          cantPlay={loadError}
        />
      )}
    </>
  );
};

export default PlayPauseButton;
