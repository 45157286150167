// @flow
import React from 'react';
import { useNavigate } from '../../../../../hooks';
import { useGlobalPlayerContext } from '../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { ROUTES } from '../../../../../routes';
import {
  useCuratedCollections,
  useMusicPage,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getCuratedCollectionId,
  getCuratedCollectionImage,
  getCuratedCollectionSongs,
  getCuratedCollectionTitle,
} from '../../../../../../cms/curatedCollection';
import PreviewTile from './PreviewTile';
import type { CuratedCollectionGraphMdl } from '../../../../../../cms/curatedCollection';
import PreviewTileRow from './PreviewTileRow';
import { getMusicPageRecentlyViewedTitle } from '../../../../../../cms/musicPage';
import { analyticsMixpanelPreviewSongs } from '../../../../../../analytics/mixpanel';
import { useAnalyticsMixpanelContext } from '../../../../../../analytics/components/MixpanelWrapper';
import { useUserId } from '../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { useWatchUserRecentCollections } from '../../../../../../api/firebase/user/recentCollections';

const RecentUserCollections = () => {
  const navigate = useNavigate();
  const {
    changeSongIDs,
    updateGlobalPlayerPlaybackDetails,
    handleUpdateGlobalAnalyticsData,
  } = useGlobalPlayerContext();
  const musicPage = useMusicPage();
  const { mixpanel, moengage, handleUpdateCollectionAnalytics } = useAnalyticsMixpanelContext();
  const userId = useUserId();

  const curatedCollections = useCuratedCollections();
  const [recentCollections] = useWatchUserRecentCollections();

  const recentUserCollections = recentCollections.map(recentCollectionId => {
    const recentCollection = curatedCollections.find(curatedCollection => {
      return getCuratedCollectionId(curatedCollection) === recentCollectionId;
    });
    return recentCollection;
  });

  const gridRows = recentUserCollections.length > 7 ? 2 : 1;

  const navigateCollection = (collectionSlug: string) => {
    handleUpdateCollectionAnalytics([], [], [], [], '', 'recentCollections', '');
    navigate(ROUTES.collection.navigatePath({ collectionSlug }));
  };

  const handlePreviewCollection = (collection: CuratedCollectionGraphMdl) => {
    const songs = getCuratedCollectionSongs(collection);
    if (songs.length < 1) return;

    const collectionTitle = getCuratedCollectionTitle(collection);
    const collectionSlug = getCuratedCollectionId(collection);
    handleUpdateGlobalAnalyticsData([], [], [], '', 'recentCollections', '', '');
    changeSongIDs(songs);
    updateGlobalPlayerPlaybackDetails('collection', collectionTitle, collectionSlug);
  };

  return (
    <>
      {recentUserCollections.length > 0 && (
        <PreviewTileRow
          title={getMusicPageRecentlyViewedTitle(musicPage)}
          viewAllLink={ROUTES.collections.navigatePath({})}
          showAllTiles={false}
          rows={gridRows}
        >
          {recentUserCollections.map(collection => {
            const slug = getCuratedCollectionId(collection);
            return (
              <PreviewTile
                key={slug}
                title={getCuratedCollectionTitle(collection)}
                slug={slug}
                bgImage={getCuratedCollectionImage(collection)}
                listType="collection"
                previewTile={() => {
                  analyticsMixpanelPreviewSongs(
                    mixpanel,
                    moengage,
                    'Collection',
                    slug,
                    'recentCollections',
                    userId
                  );
                  handlePreviewCollection(collection);
                }}
                navigateTile={() => {
                  navigateCollection(slug);
                }}
              />
            );
          })}
        </PreviewTileRow>
      )}
    </>
  );
};

export default RecentUserCollections;
