// @flow
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useAnalyticsMixpanelContext } from '../../../../../../../../analytics/components/MixpanelWrapper';
import {
  analyticsMixpanelViewMicroparts,
  useMixpanelSongAnalyticsDimensions,
} from '../../../../../../../../analytics/mixpanel';
import { getSongShareSlug } from '../../../../../../../../api/algolia/song';
import MicropartsIcon from '../../../../../../../../assets/inline-assets/microparts-icon.svg';
import { useGlobalPlayerContext } from '../../../../../../../../audio/components/GlobalPlayerWrapper/GlobalPlayerWrapper';
import { useUserId } from '../../../../../../../../auth/components/AuthWrapper/AuthWrapper';
import { getTrackViewLoopsAction } from '../../../../../../../../cms/track';
import { useDownloadContext } from '../../../../../../../../components/DownloadContextWrapper/DownloadContextWrapper';
import { useTrackCopy } from '../../../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { useModalsContext } from '../../../../../../../../modals/components/ModalsWrapper/ModalsWrapper';
import { useKeyboardShortcutRestricted } from '../../../../../../../../toasts/hooks';
import { useSubscriptionsContext } from '../../../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import { getPlayerKeyboardShortcuts } from '../../../../../../../../utils/keyboardShortcuts';
import InlineButton from '../../../../../../FullDisplaySongCard/components/SongActions/components/ActionMenuButton/components/InlineButton';
import { useSongContext } from '../../../../../../SongContextWrapper/SongContextWrapper';

const ViewLoopsInlineButton = () => {
  const trackCopy = useTrackCopy();
  const { song } = useSongContext();
  const { showLoopsModal } = useModalsContext();
  const { pauseGlobalPlayback } = useGlobalPlayerContext();
  const restrictedShortcutToast = useKeyboardShortcutRestricted();
  const { mixpanel, moengage } = useAnalyticsMixpanelContext();
  const mixpanelSongDimensions = useMixpanelSongAnalyticsDimensions();
  const downloadContext = useDownloadContext();
  const { userRole } = useSubscriptionsContext();
  const userId = useUserId();

  const handleOpenLoopsModal = () => {
    const songFamily = getSongShareSlug(song);
    showLoopsModal(songFamily, downloadContext, () => pauseGlobalPlayback());
    analyticsMixpanelViewMicroparts(
      mixpanel,
      moengage,
      mixpanelSongDimensions,
      downloadContext,
      userRole,
      userId
    );
  };

  const keyboardShortcutHandlers = (() => {
    if (true) {
      return {
        toggle_microparts: handleOpenLoopsModal,
      };
    }
    return {
      toggle_microparts: restrictedShortcutToast,
    };
  })();

  return (
    <>
      <GlobalHotKeys
        keyMap={getPlayerKeyboardShortcuts('results')}
        handlers={keyboardShortcutHandlers}
        allowChanges
      />
      <InlineButton
        label={getTrackViewLoopsAction(trackCopy)}
        icon={<MicropartsIcon />}
        action={handleOpenLoopsModal}
      />
    </>
  );
};

export default ViewLoopsInlineButton;
