// @flow
import React, { useEffect, useContext, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import ls from 'local-storage';
import { useSetRecoilState } from 'recoil';
import Moengage from 'moengage';
import { useQueryParamUtmCampaign } from '../../routing/components/PageComponent/PageComponent';
import {
  colelctionKeywordsSearchedAtom,
  colelctionMixpanelKeywordsSearchedAtom,
  collectionMagicKeywordsSearchedAtom,
  collectionSectionOriginAtom,
  collectionYoutubeKeywordsSearchedAtom,
  collectionYoutubeVideoTitleSearchedAtom,
  conversionSourceAtom,
  relatedCollectionSearchedAtom,
  searchTriggerAtom,
} from '../../store/mixpanelAnalytics';

export type AnalyticsMixpanelContextState = {
  mixpanel: any,
  moengage: any,
  handleUpdateCollectionAnalytics: (
    keywords: Array<string>,
    mixpanelKeywords: Array<string>,
    magicKeywords: Array<string>,
    youtubeKeywords: Array<string>,
    youtubeVideoTitle: string,
    sectionOrigin: string,
    relatedCollection: string
  ) => void,
  handleResetCollectionAnalytics: () => void,
};

type Props = {
  children: any,
};

export const MixpanelContext = React.createContext();

export const useAnalyticsMixpanelContext = (): AnalyticsMixpanelContextState =>
  useContext(MixpanelContext);

const MixpanelWrapper = ({ children }: Props) => {
  const { GATSBY_MIXPANEL_TOKEN } = process.env;
  mixpanel.init(GATSBY_MIXPANEL_TOKEN);

  const moengage = useMemo(() => {
    const moEngageAppId = process.env.GATSBY_MOENGAGE_APP_ID || '';
    const moEngageLogs = process.env.GATSBY_MOENGAGE_DEBUG_LOGS || '';

    const mo = new Moengage({
      appID: `${moEngageAppId}`,
      debugMode: `${moEngageLogs}`,
      swPath: './sw.js',
    });

    return mo;
  }, []);

  const [campaign] = useQueryParamUtmCampaign();
  const searchOrigin = campaign || 'results';

  const setSearchTriggerLocation = useSetRecoilState(searchTriggerAtom);

  useEffect(() => {
    setSearchTriggerLocation(searchOrigin);
  }, []);

  const setConversionSource = useSetRecoilState(conversionSourceAtom);

  // browse
  const setCollectionKeywordsSearched = useSetRecoilState(colelctionKeywordsSearchedAtom);
  const setColelctionMixpanelKeywordsSearched = useSetRecoilState(
    colelctionMixpanelKeywordsSearchedAtom
  );
  const setCollectionMagicKeywordSearched = useSetRecoilState(collectionMagicKeywordsSearchedAtom);
  const setCollectionYoutubeKeywordsSearched = useSetRecoilState(
    collectionYoutubeKeywordsSearchedAtom
  );
  const setCollectionyoutubeVideoTitleSearched = useSetRecoilState(
    collectionYoutubeVideoTitleSearchedAtom
  );
  const setCollectionSectionOrigin = useSetRecoilState(collectionSectionOriginAtom);
  const setRelatedCollectionSearched = useSetRecoilState(relatedCollectionSearchedAtom);

  const handleUpdateCollectionAnalytics = (
    keywords = [],
    mixpanelKeywords = [],
    magicKeywords = [],
    youtubeKeywords = [],
    youtubeVideoTitle = '',
    sectionOrigin = '',
    relatedCollection = ''
  ) => {
    setCollectionKeywordsSearched(keywords);
    setColelctionMixpanelKeywordsSearched(mixpanelKeywords);
    setCollectionMagicKeywordSearched(magicKeywords);
    setCollectionYoutubeKeywordsSearched(youtubeKeywords);
    setCollectionyoutubeVideoTitleSearched(youtubeVideoTitle);
    setCollectionSectionOrigin(sectionOrigin);
    setRelatedCollectionSearched(relatedCollection);
  };

  const handleResetCollectionAnalytics = () => {
    setCollectionKeywordsSearched([]);
    setColelctionMixpanelKeywordsSearched([]);
    setCollectionMagicKeywordSearched([]);
    setCollectionYoutubeKeywordsSearched([]);
    setCollectionyoutubeVideoTitleSearched('');
    setCollectionSectionOrigin('');
    setRelatedCollectionSearched('');
  };

  useEffect(() => {
    const trackedConversionSource = ls.get('CONVERSION_SOURCE');

    if (trackedConversionSource) {
      setConversionSource(trackedConversionSource);
      ls.remove('CONVERSION_SOURCE');
    }
  }, []);

  return (
    <MixpanelContext.Provider
      value={{
        mixpanel,
        moengage,
        handleUpdateCollectionAnalytics,
        handleResetCollectionAnalytics,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

export default MixpanelWrapper;
