// @flow
import React, { useEffect } from 'react';
import MicroPartsWrapper from '../MicroPartsWrapper/MicroPartsWrapper';
import type { AlgoliaSongMdl } from '../../../api/algolia/song';
import SongContextWrapper from '../SongContextWrapper/SongContextWrapper';
import AudioPlayback from '../AudioPlayback/AudioPlayback';
import ShareContextWrapper from '../ShareContextWrapper/ShareContextWrapper';
import { PLAYER_TYPES } from '../../data';
import { useResultsManagerContext } from '../../../routing/screens/ResultsScreen/components/ResultsView/components/ResultsManager/ResultsManager';

type Props = {
  children: any,
  song: AlgoliaSongMdl,
  autoplay?: boolean,
  autoload?: boolean,
  mainAudioCard: boolean,
  playerType: string,
  expandKey?: string,
  pauseGlobalPlayback?: () => void,
};

const AudioCardWrapper = ({
  children,
  song,
  autoload = false,
  autoplay = false,
  mainAudioCard,
  playerType,
  expandKey = '',
  pauseGlobalPlayback = () => {},
}: Props) => {
  const { setCurrentSongData } =
    playerType === PLAYER_TYPES.mainResults || playerType === PLAYER_TYPES.mobileResults
      ? useResultsManagerContext()
      : () => {};

  useEffect(() => {
    if (
      (playerType === PLAYER_TYPES.mainResults || playerType === PLAYER_TYPES.mobileResults) &&
      song
    ) {
      setCurrentSongData(song);
    }
  }, [song]);
  return (
    <SongContextWrapper song={song}>
      <MicroPartsWrapper>
        <AudioPlayback
          song={song}
          autoload={autoload}
          autoplay={autoplay}
          mainAudioCard={mainAudioCard}
          playerType={playerType}
          expandKey={expandKey}
          modalGlobalPause={() => {
            pauseGlobalPlayback();
          }}
        >
          <ShareContextWrapper song={song}>{children}</ShareContextWrapper>
        </AudioPlayback>
      </MicroPartsWrapper>
    </SongContextWrapper>
  );
};

export default AudioCardWrapper;
