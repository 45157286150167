// @flow

export const spacing = {
  small: 15,
  medium: 30,
  large: 41,
  largePlus: 45,
  huge: 75,
  jumbo: 201,
};
