// @flow

export const darkColor = '#1B1D22';
export const lightColor = '#F0F0EB';
export const greyColor = '#25272C';
export const brightColor = '#f6c992';
export const facebookColor = '#3f63ac';
export const twitterColor = '#1DA1F1';
export const tumblrColor = '#36465D';
export const evokeBorderColor = '#dcdad8';
export const modalBackGroundColor = '#ffffff';

export const guestBgColor = '#4d4d4d';
export const personalBgColor = '#171c61';
export const businessBgColor = '#009fdf';
export const enterpriseBgColor = '#65d3ff';

export const creatorBgColor = '#4a9495';
export const creatorProBgColor = '#483c5b';
export const referralBgColor = '#cfc3b2';
