// @flow
import { useState } from 'react';
import { AuthenticationError } from '../errors/errors';
import { useNavigate } from '../routing/hooks';
import { ROUTES } from '../routing/routes';
import { ERROR_PATH_CODES } from '../errors/components/ErrorCodeHandler/ErrorCodeHandler';
import { useAuthContext } from '../auth/components/AuthWrapper/AuthWrapper';

export const useGenericApiState = () => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { tokenRenewFailedLogout } = useAuthContext();
  const apiErrorHandler = async (apiError: any) => {
    // $FlowFixMe: removes type checking for Sentry as provisional solution
    Sentry.captureException(apiError);
    console.error(apiError);
    if (apiError instanceof AuthenticationError) {
      console.log('This was an instance of authentication error');
      await tokenRenewFailedLogout();
      return Promise.reject(apiError);
    }
    return Promise.reject(apiError);
  };
  return [busy, setBusy, error, setError, apiErrorHandler];
};
