// @flow
import React from 'react';
import styled from 'react-emotion';
import { transparentize } from 'polished';
import { darkColor } from '../../../../../../../../../styles/config/colors';
import {
  MOBILE_DEVICE_BREAKPOINT,
  SMALL_DEVICE_BREAKPOINT,
} from '../../../../../../../../../styles/responsive';
import Button from '../../../../../../../../../components/Button/Button';
import { ROUTES } from '../../../../../../../../routes';
import { useNavigate } from '../../../../../../../../hooks';

type Props = {
  title?: string,
  description?: string,
  icon?: any,
  navigateBack?: boolean,
  buttonText?: string,
};

const Container = styled('div')`
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MOBILE_DEVICE_BREAKPOINT} {
    min-height: 45vh;
    padding: 0px 30px;
  }
`;

const IconWrapper = styled('div')`
  margin: 5px 0px;

  & svg {
    transform: scale(4);

    & g {
      stroke: black;
      stroke-width: 1;
  }
`;

const Title = styled('h3')`
  margin: 20px 0px;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 35px;
  text-align: center;
`;

const Description = styled('p')`
  margin: 20px 0px;
  color: ${transparentize(0.2, darkColor)};
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
  max-width: 500px;
`;

const BackLink = styled(Button)`
  display: none;
  margin-top: 70px;

  ${SMALL_DEVICE_BREAKPOINT} {
    display: block;
  }
`;

const SearchResultsMessageLayout = ({
  title,
  description,
  icon,
  navigateBack = false,
  buttonText = '',
}: Props) => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(ROUTES.music.navigatePath({}));
  };

  return (
    <Container>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {navigateBack && <BackLink onClick={handleNavigateBack}>{buttonText}</BackLink>}
    </Container>
  );
};

export default SearchResultsMessageLayout;
