export const PLAYER_TYPES = {
  direct: 'direct',
  embed: 'embed',
  mainResults: 'mainResults',
  mobileResults: 'mobileResults',
  download_bookmark: 'downloadBookmark',
  collections: 'collections',
  loops_main: 'loopsModal',
  loops_loop: 'loopsLoop',
  sfx: 'sfx',
};
